/* eslint-disable no-nested-ternary */
import isEmpty from '@Utils/isEmpty';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import { FlexColumn } from '@Components/common/Layouts';
import ListCard from './ListCard/ListCard';
import ListsSk from './ListsSk/ListsSk';

const UpdateLists = ({ updateLists, isLoading: listLoading }: any) => {
  return (
    <div className="update-list-container naxatw-w-full">
      {listLoading ? (
        <ListsSk />
      ) : isEmpty(updateLists) ? (
        <NoChartDataComponent
          message="No Updates Available"
          className="naxatw-mb-10 naxatw-mt-5"
        />
      ) : (
        <FlexColumn className="update-list-inner scrollbar naxatw-gap-[10px] naxatw-overflow-y-auto naxatw-pb-4 lg:naxatw-h-[calc(100vh-147px)]">
          {updateLists?.map((list: Record<string, any>, index: number) => (
            <ListCard {...list} index={index} key={list.id} />
          ))}
        </FlexColumn>
      )}
    </div>
  );
};

export default UpdateLists;
