// import { useRef } from 'react';
// import { useQuery } from '@tanstack/react-query';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import { useSpring, animated } from '@react-spring/web';
// import Slider, { LazyLoadTypes } from 'react-slick';
import {
  getTopFiveSectorsByComponent,
  getTopFiveSectorsByProgramme,
  // getProgrammesByBudgetAllocated,
  getTopPartnerByBudget,
  getTopComponentProgram,
  getGovernmentStakeHoldersDataPro,
  // getChartDescription,
  getBudgetExpenditureTypeChart,
  getProjectChartCountByGroup,
  getProjectChartCountByCampaignGoal,
  getAgreementModalityTableData,
} from '@Services/mainDashboard';
import { useTypedSelector } from '@Store/hooks';
import {
  setSectorsSelectedTab,
  // setGovernmentStakeHoldersSelectedTab,
  setBudgetExpenditureSelectedTab,
  setStakeholderByProgramProjectSelectedTab,
  setProjectCampaignGoalSelectedTab,
  setAgreementModalitySelectedTab,
} from '@Store/actions/mainDashboardSwitchtab';
import {
  viewByOptionsSectorsCard,
  // governmentStakeHoldersCardHeader,
  budgetExpenditureChartHeader,
  stakeHolderProgrammeProjectCardHeader,
  projectCampaignGoals,
  mapBudgetExpenditureColors,
  budgetChartColors,
  agreementModalityChartHeader,
} from '@Constants/mainDashboard';
// import ChartButton from './ChartButton';
// import Chart from './Chart';
import {
  getAgreementModality,
  getStakeholderChartDataByProgramProject,
} from '@Services/provincialProfile';
import { stakholderByProrgammeProjectFills } from '@Components/common/Charts/constants';
import { useDispatch } from 'react-redux';
import { setChartStates } from '@Store/actions/mainDashboard';
import ChartLegend from '@Components/common/Charts/ChartLegend';
import ChartContainer from './ChartContainer';
import NumberOfStakeholderProjectsTable from './ChartNestedContent/StakeholderProject';

// interface ISettingsProps {
//   slidesToShow: number;
//   slidesToScroll: number;
//   lazyLoad: LazyLoadTypes | undefined;
//   speed: number;
//   infinite: boolean;
//   autoplay?: boolean;
//   cssEase: string;
//   autoplaySpeed: number;
//   afterChange?: any;
//   rtl?: boolean;
// }

const ChartSection = () => {
  // const sliderRef = useRef<Slider>(null);

  const selectedTabOfCharts = useTypedSelector(
    state => state.setMainDashboardChartTabs.selectedTab,
  );

  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const projectFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const provinceFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.province,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );

  const subMarkersParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );

  const statusFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );

  const nestedChartId = useTypedSelector(
    state => state.mainDashboard.charts.nestedCharts.nestedChartId,
  );

  // const sliderSettings: ISettingsProps = {
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   lazyLoad: 'ondemand',
  //   infinite: true,
  //   speed: 500,
  //   autoplaySpeed: 2000,
  //   cssEase: 'linear',
  // };
  const filterCount = useTypedSelector(
    state => state?.mainDashboard?.filterCount,
  );

  const dispatch = useDispatch();

  const campaignGoalSelectedTab = selectedTabOfCharts.projectCampaignGoal;
  const stakeholderProjectSelectedTab =
    selectedTabOfCharts.stakeholderByProgramProject;

  // const { data: chartDescriptionData } = useQuery({
  //   queryKey: [
  //     'chart-description',
  //     programmeFilterParams,
  //     projectFilterParams,
  //     firstTierPartnerParams,
  //     sectorGroupParams,
  //     markerGroupParams,
  //     subMarkersParams,
  //     startDate,
  //     endDate,
  //     statusFilterParams,
  //   ],
  //   queryFn: async () =>
  //     getChartDescription({
  //       program: programmeFilterParams?.join(','),
  //       project: projectFilterParams?.join(','),
  //       first_tier_partner: firstTierPartnerParams?.join(','),
  //       sector: sectorGroupParams?.join(','),
  //       marker: markerGroupParams?.join(','),
  //       marker_category: subMarkersParams?.join(','),
  //       start_date: startDate,
  //       end_date: endDate,
  //       status: statusFilterParams,
  //     }),
  //   select: (res: Record<string, any>) => res.data,
  // });

  const hasStartAndEndDate = !!(startDate && endDate);

  const commonReqParams = {
    program: programmeFilterParams?.join(','),
    project: projectFilterParams?.join(','),
    first_tier_partner: firstTierPartnerParams?.join(','),
    province: provinceFilterParams?.join(','),
    ...(hasStartAndEndDate
      ? {
          start_date: startDate,
          end_date: endDate,
        }
      : {}),
  };

  const mainDashboardCharts = [
    {
      title: 'Portfolio Summary',
      chartType: 'summary',
    },
    {
      id:
        selectedTabOfCharts.sectors === 'Project'
          ? 'top-five-sector-trend-component'
          : 'top-five-sector-trend-programme',
      querykey: selectedTabOfCharts.sectors,
      title: `Top ${filterCount ? '' : '5'} Sectors by Number of ${selectedTabOfCharts.sectors === 'Project' ? 'Projects' : 'Programmes'}`,
      chartType: 'treeMap',
      queryFn:
        selectedTabOfCharts.sectors === 'Project'
          ? getTopFiveSectorsByComponent
          : getTopFiveSectorsByProgramme,
      params: {
        ...commonReqParams,
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        status: statusFilterParams,
      },
      switchTabData: {
        switchTabOptions: viewByOptionsSectorsCard,
        dispatchAction: setSectorsSelectedTab,
        activeLabel: selectedTabOfCharts.sectors,
      },
      descriptionKey:
        selectedTabOfCharts.sectors === 'Project'
          ? 'top_sectors_project'
          : 'top_sectors_program',
      xLabelClassname: '!naxatw-absolute !naxatw-bottom-[10%]',
    },
    // stakeholders by projec chart data
    {
      id: `stakeholder-project-${stakeholderProjectSelectedTab.toLowerCase()}-chart`,
      queryFn:
        stakeholderProjectSelectedTab === 'All'
          ? getStakeholderChartDataByProgramProject
          : getGovernmentStakeHoldersDataPro,
      title: 'Number of Stakeholders by Project',
      chartType: 'bar',
      xLabel: `Type of ${stakeholderProjectSelectedTab !== 'All' ? 'Government' : ''} Stakeholder`,
      yLabel:
        stakeholderProjectSelectedTab === 'All'
          ? 'No. of Projects'
          : 'No. of Projects',
      queryKey: [stakeholderProjectSelectedTab],
      params: {
        ...commonReqParams,
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        status: statusFilterParams,
      },
      switchTabData: {
        switchTabOptions: stakeHolderProgrammeProjectCardHeader,
        dispatchAction: setStakeholderByProgramProjectSelectedTab,
        activeLabel: selectedTabOfCharts.stakeholderByProgramProject,
      },
      // xLabelClassname: '!naxatw-absolute !naxatw-bottom-[8%]',
      yLabelWrapperClassNames: '!naxatw-left-[-14px]',
      barSize: stakeholderProjectSelectedTab === 'All' ? 40 : 80,
      fill:
        stakeholderProjectSelectedTab === 'All'
          ? stakholderByProrgammeProjectFills
          : [stakholderByProrgammeProjectFills[1]],
      hoverTitle:
        stakeholderProjectSelectedTab === 'All'
          ? ['National', 'Subnational']
          : 'Project',
      wrapXAxis: true,
      // height: '400px',
      legendLabels: stakeholderProjectSelectedTab === 'All' && [
        'National',
        'Sub - National',
      ],
      legend: stakeholderProjectSelectedTab === 'All' && ChartLegend,
      nestedChartContent: stakeholderProjectSelectedTab === 'Government' && (
        <NumberOfStakeholderProjectsTable />
      ),

      // click handler for chart
      onChartClick(option: Record<string, any>) {
        if (stakeholderProjectSelectedTab === 'Government') {
          const value =
            option.activePayload[0].payload || option.activePayload[1].payload;
          dispatch(setChartStates({ key: 'chartDetails', value }));
          dispatch(setChartStates({ key: 'showLegendDetails', value: true }));
        }
      },
    },
    {
      xLabelClassname: '!naxatw-absolute !naxatw-bottom-[5%]',
      id: 'programme-vs-projects',
      title: `Top 5 Programmes by Budget Allocated`,
      chartType: 'donut',
      queryFn: getTopComponentProgram,
      xLabel: 'Programmes',
      descriptionKey: 'top_project_program',
      params: {
        ...commonReqParams,
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        status: statusFilterParams,
        ...(nestedChartId ? { program_id: nestedChartId } : {}),
      },
      hasDetails: !nestedChartId,
      isNestedChart: true,
      showPound: true,
      fill: budgetChartColors,
    },

    {
      id: 'top-partners-by-budget-allocation',
      name: 'top-partners-by-budget-allocation',
      title: `Top ${filterCount ? '' : '5'} Implementing Partners by Budget Allocated`,
      subTitle: 'Highlighting the Partners with the Largest Budget Allocations',
      chartType: 'verticalBar',
      queryFn: getTopPartnerByBudget,
      yLabel: 'Partners',
      xLabel: 'Budget',
      descriptionKey: 'top_partner_budget',
      params: {
        ...commonReqParams,
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        status: statusFilterParams,
      },
      xLabelClassname: '!naxatw-absolute !naxatw-bottom-[8%]',
      yLabelWrapperClassNames: '!naxatw-left-[10px]',
    },
    {
      id: 'expenditure-budget-overview',
      title: `Overview of  ${selectedTabOfCharts.budgetExpenditureType === 'Expenditure Type' ? 'Expenditure' : 'Budget'} Types`,
      sidebarTitle: 'Overview of Expenditure Types',
      queryFn: getBudgetExpenditureTypeChart,
      chartType: 'donut',
      params: {
        ...commonReqParams,
        by: `${selectedTabOfCharts.budgetExpenditureType === 'Expenditure Type' ? 'expenditure_type' : 'budget_type'}`,
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        status: statusFilterParams,
      },
      switchTabData: {
        switchTabOptions: budgetExpenditureChartHeader,
        dispatchAction: setBudgetExpenditureSelectedTab,
        activeLabel: selectedTabOfCharts.budgetExpenditureType,
      },
      descriptionKey:
        selectedTabOfCharts.budgetExpenditureType === 'Expenditure Type'
          ? 'total_expenditure'
          : 'total_budget',
      chartId: 'budget-expenditure-type',
      showPound: true,
      fill: Object.values(mapBudgetExpenditureColors),
    },
    {
      id: 'project-group-campaign-goal',
      queryFn:
        campaignGoalSelectedTab === 'Groups'
          ? getProjectChartCountByGroup
          : getProjectChartCountByCampaignGoal,

      title: `Number of Project by ${campaignGoalSelectedTab}`,
      sidebarTitle: 'Number of Project by Groups/Campaign Goals',
      chartType: 'bar',
      xLabel: campaignGoalSelectedTab,
      yLabel: 'No. of Projects',
      queryKey: campaignGoalSelectedTab,
      params: {
        ...commonReqParams,
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        status: statusFilterParams,
      },
      switchTabData: {
        switchTabOptions: projectCampaignGoals,
        dispatchAction: setProjectCampaignGoalSelectedTab,
        activeLabel: selectedTabOfCharts.projectCampaignGoal,
      },
      xLabelClassname: '!naxatw-absolute !naxatw-bottom-[8%]',
      barSize: 80,
      fill: ['#41588F'],
      hoverTitle: 'Project',
      wrapXAxis: true,
    },
    {
      id: `project-agreement-modality-${selectedTabOfCharts.agreementModality}`,
      queryFn:
        selectedTabOfCharts.agreementModality === 'Chart'
          ? getAgreementModality
          : getAgreementModalityTableData,
      title: `Number of Project by Agreement Modality`,
      chartType: 'donut',
      queryKey: selectedTabOfCharts.agreementModality,
      params: {
        ...commonReqParams,
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        status: statusFilterParams,
      },
      fill: Object.values(mapBudgetExpenditureColors),
      showPound: false,
      switchTabData: {
        switchTabOptions: agreementModalityChartHeader,
        dispatchAction: setAgreementModalitySelectedTab,
        activeLabel: selectedTabOfCharts.agreementModality,
      },
      hasTableData: selectedTabOfCharts.agreementModality !== 'Chart',
    },
  ];

  // const springs = useSpring({
  //   from: { y: 100 },
  //   to: { y: 0 },
  // });

  // console.log(chartDescriptionData, 'add');

  // const sliderBtnStyle =
  //   'naxatw-absolute naxatw-top-2/4 naxatw-z-40 naxatw-col-span-1 -naxatw-translate-y-2/4 naxatw-duration-300 hover:naxatw-bg-grey-100 md:naxatw-relative md:naxatw-translate-y-0 md:naxatw-top-0';
  return (
    // <animated.div
    //   style={{ ...springs }}
    //   className="chart-section naxatw-relative naxatw-mx-auto naxatw-h-full naxatw-grid-cols-12 naxatw-gap-3 md:naxatw-grid"
    // >
    //   <ChartButton
    //     icon="west"
    //     className={`${sliderBtnStyle} naxatw-left-0`}
    //     handleClick={() => sliderRef?.current?.slickPrev()}
    //   />

    //   <div className="slider-container naxatw-relative naxatw-z-10 naxatw-col-span-10 naxatw-h-full naxatw-w-full">
    //     <Slider {...sliderSettings} ref={sliderRef} className="naxatw-h-full">
    //       {mainDashboardCharts?.map((chart: Record<string, any>) => {
    //         console.log(chart, 'data', chartDescriptionData);

    //         return (
    //           <Chart
    //             chart={chart}
    //             key={chart.id}
    //             chartDescriptionData={chartDescriptionData}
    //           />
    //         );
    //       })}
    //     </Slider>
    //   </div>

    //   <ChartButton
    //     icon="east"
    //     className={`${sliderBtnStyle} naxatw-right-0 naxatw-justify-self-end`}
    //     handleClick={() => sliderRef?.current?.slickNext()}
    //   />
    // </animated.div>
    <ChartContainer chartList={mainDashboardCharts} />
  );
};

export default ChartSection;
