import hasErrorBoundary from '@Components/common/hasErrorBoundary';

const EvidenceAgendaCard = ({
  label,
  value,
}: {
  label: string;
  value: number;
}) => {
  return (
    <div className="evidence-card  naxatw-rounded-xl naxatw-border-primary-200 naxatw-bg-white naxatw-px-4 naxatw-py-3 naxatw-shadow-sm">
      <div className="naxatw-flex naxatw-items-center naxatw-gap-3">
        <p className="naxatw-text-[0.875rem] naxatw-text-matt-100">
          {label || '-'}
        </p>
        <p className="naxatw-text-base naxatw-font-medium naxatw-leading-normal naxatw-text-primary-800">
          {value || '-'}
        </p>
      </div>
    </div>
  );
};

export default hasErrorBoundary(EvidenceAgendaCard);
