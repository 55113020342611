import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
// import { mainDashboardCardData } from './../constants/index';

import common from './slices/common';
import loader from './slices/loader';
import mainDashboard from './slices/mainDashboard';
import rootSaga from './sagas';
import knowledgeRepository from './slices/knowledgeRepository';
import portfolioProfiles from './slices/portfolioProfiles';
import provincialProfile from './slices/provincialProfile';
import exploreByIndicators from './slices/exploreByIndicators';
import setMainDashboardChartTabs from './slices/mainDashboardSwitchTab';
import setPortfolioResultsSwitchTab from './slices/portfolioResultsSwitchTab';
import programmeDetails from './slices/programmeDetails';
import componentFormValidationSlice from './slices/componentFormValidation';
import adminPdfSlice from './slices/adminPdfSlice';
import curtain from './slices/curtain';
import databankforms from './slices/databankforms';
import acitivityLogsSlice from './slices/activityLogs';
import tutorialsSlice from './slices/tutorials';
import UpdateSlice from './slices/update';
import pdfExportSlice from './slices/pdfExportSlice';
import programme from './slices/programme';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const store = configureStore({
  reducer: {
    common,
    loader,
    mainDashboard,
    // components,
    portfolioProfiles,
    provincialProfile,
    knowledgeRepository,
    exploreByIndicators,
    setMainDashboardChartTabs,
    setPortfolioResultsSwitchTab,
    programmeDetails,
    componentFormValidationSlice,
    adminPdfSlice,
    curtain,
    databankforms,
    acitivityLogsSlice,
    tutorialsSlice,
    UpdateSlice,
    pdfExportSlice,
    programme,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(
      middleware,
    ),
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
