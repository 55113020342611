// import Icon from '@Components/common/Icon';
// import { useTypedSelector } from '@Store/hooks';
import WorkingArea from '@Components/ProvincialProfile/ExportPdf/PortfolioSummary/WorkingArea';
import { useTypedSelector } from '@Store/hooks';
import { cn } from '@Utils/index';

const WorkingAreas = ({
  className,
  isExport = false,
  overallCount,
}: {
  className?: string;
  isExport?: boolean;
  overallCount?: any;
}) => {
  // const isOverallCountLoading = useTypedSelector(
  //   state => state.mainDashboard.overallCount.isOverallCountLoading,
  // );

  // const overallCount = useTypedSelector(
  //   state => state.mainDashboard.overallCount,
  // );

  const showFilterColumn = useTypedSelector(
    state => state.mainDashboard.showFilterColumn,
  );

  const {
    province_count: provinceCount,
    municipality_count: munCount,
    district_count: districtCount,
  } = overallCount || {};

  return (
    // <div
    //   className={clsx(
    //     'working-areas-container naxatw-rounded-xl naxatw-border naxatw-border-primary-200 naxatw-bg-white naxatw-p-6 naxatw-shadow-xs',
    //     className,
    //   )}
    // >
    //   <div className="working-area-card-container naxatw-grid naxatw-grid-cols-4 naxatw-gap-2">
    //     <div className="working-areas-inner naxatw-self-center naxatw-justify-self-start">
    //       <Icon
    //         name="place"
    //         className="naxatw-mb-3 naxatw-rounded-full naxatw-bg-[#F4F7FE] naxatw-p-3 naxatw-text-calm-teal"
    //       />
    //       <p className="naxatw-text-center naxatw-text-[0.875rem] naxatw-font-medium naxatw-leading-5 naxatw-text-primary-700">
    //         Working Areas
    //       </p>
    //     </div>
    //     <WorkingAreaCard
    //       title="Province"
    //       count={provinceCount}
    //       isLoading={isOverallCountLoading}
    //     />
    //     <WorkingAreaCard
    //       title="District"
    //       count={districtCount}
    //       isLoading={isOverallCountLoading}
    //     />
    //     <WorkingAreaCard
    //       title="Municipalities"
    //       count={munCount}
    //       isLoading={isOverallCountLoading}
    //     />
    //   </div>
    // </div>

    <div className={cn('', className)}>
      <WorkingArea
        districtCount={districtCount}
        provinceCount={provinceCount}
        municipalityCount={munCount}
        isExport={isExport}
        iconClassName={`${showFilterColumn ? '!naxatw-p-2' : '!naxatw-p-3'}`}
        className={`${showFilterColumn ? '!naxatw-p-3' : '!naxatw-p-4'}`}
        textCountWrapperStyles={`${showFilterColumn ? '!naxatw-gap-5' : '!naxatw-gap-8'} naxatw-justify-center naxatw-flex-1`}
      />
    </div>
  );
};

export default WorkingAreas;
