import { z } from 'zod';

export const contactValidationSchema = z
  .object({
    name: z
      .string()
      .trim()
      .min(1, { message: 'Name is required.' })
      .max(250, { message: 'Name cannot exceed 250 characters.' })
      .regex(/^(?![0-9]*$)(?![^a-zA-Z0-9]*$)[a-zA-Z0-9 ]+$/, {
        message: 'Name cannot be only numbers or special characters.',
      }),
    email: z.string().email().optional().or(z.literal('')),
    office: z
      .string()
      .trim()
      .max(250, 'Office cannot exceed 250 characters.')
      .optional(),
    designation: z
      .string()
      .trim()
      .max(250, 'Designation cannot exceed 250 characters.')
      .optional(),
    phone: z
      .string()
      .regex(/^\d{10}$/, {
        message: 'Phone number must contain exactly 10 digits.',
      })
      .optional()
      .or(z.literal('')),
    federal_type: z.string().min(1, { message: 'Type is required.' }),
    province: z.number().nullable().optional(),
  })
  .refine(
    data => {
      if (data.federal_type === 'Province') {
        return data.province;
      }
      return true;
    },
    {
      message: 'Province is Required',
      path: ['province'],
    },
  );

export type contactTriviaSchemaProps = z.infer<
  typeof contactValidationSchema & {
    [key: string]: any;
  }
>;
