import { api, authenticated } from '.';

// user-management
export const getUserList = (params: Record<string, any>) => {
  return authenticated(api).get('/user/user-profile/', { params });
};

export const getUserById = (id: string) => {
  return authenticated(api).get(`/user/user-profile/?id=${id}`);
};

export const signUpUser = (payload: Record<string, any>) => {
  return authenticated(api).post(`/user/sign-up/`, payload);
};
export const signUpUserFromAdmin = (payload: Record<string, any>) => {
  return authenticated(api).post(`/user/user-register-by-admin/`, payload);
};

export const postUser = (payload: Record<string, any>) => {
  return authenticated(api).post(`/user/user-profile/`, payload);
};

export const patchUser = (payload: Record<string, any>) => {
  return authenticated(api).post('/user/edit-user/', payload);
};

export const deleteUser = (id: string) => {
  return authenticated(api).delete(`/user/user-profile/${id}/`);
};

export const getUserGroups = () => {
  return authenticated(api).get('/user/list_user_groups/');
};

export const postUserProgramme = (payload: Record<string, any>) => {
  return authenticated(api).post('/user/user-program/', payload, {
    headers: { 'Content-Type': 'application/json' },
  });
};

export const postUserProject = (payload: Record<string, any>) => {
  return authenticated(api).post('/user/user-project/', payload, {
    headers: { 'Content-Type': 'application/json' },
  });
};

export const deleteUserProgramme = (id: any) => {
  return authenticated(api).delete(`/user/user-program/${id}/`);
};

export const deleteUserProject = (id: any) => {
  return authenticated(api).delete(`/user/user-project/${id}/`);
};

// role-management
// dropdowns
// get permisiion list
export const getPermissionsList = () => {
  return authenticated(api).get('/user/permission-list/');
};

// post user role
export const postUserRole = (payload: Record<string, any>) => {
  return authenticated(api).post('/user/create_user_group/', payload);
};

// post user permissions
export const postUserPermissions = (payload: Record<string, any>) => {
  return authenticated(api).post('/user/modify_project_permission/', payload);
};

// list user group
export const getUsersGroups = (params?: Record<string, any>) => {
  return authenticated(api).get(`/user/list_user_groups/`, { params });
};

// delete user group
export const deleteUsersGroups = (payload: any) => {
  return authenticated(api).post(`/user/delete_user_group/`, payload);
};

// get individual user group permission
export const getIndividualUserGroupPermission = (userId: any) => {
  return authenticated(api).get(`/user/permission-list/?group_id=${userId}`);
};

// get individual user  group role
export const getIndividualUserGroupRole = (userId: any) => {
  return authenticated(api).get(`/user/list_user_groups/?id=${userId}`);
};

// change user password

export const changeUserPassword = (payload: Record<string, any>) => {
  return authenticated(api).post('/user/reset-password-by-admin/', payload, {
    headers: { 'Content-Type': 'application/json' },
  });
};
