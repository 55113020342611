import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { downloadTableData } from '@Services/provincialProfile';
import { useTypedSelector } from '@Store/hooks';
import ExternalFilterButton from '@Components/ExploreByProgramme/ExternalFilterButton';
import { clearFilterState } from '@Store/actions/programme';
import { useParams, useSearchParams } from 'react-router-dom';
import ChartCardHeader from './Header';
import ProgrammeDetailsTable from './Table/ProgrammeDetailsTable';

const ProgrammeDetails = () => {
  const { provinceId } = useParams();
  const dispatch = useDispatch();
  const [filterParams, setFilterParams] = useSearchParams();

  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);
  // download table
  const { refetch } = useQuery({
    queryKey: ['programme-details-table-data', filterBy],
    queryFn: async () =>
      downloadTableData({
        is_export: true,
        status: filterBy,
        province: provinceId,
      }),
    enabled: false,
    onSuccess: response => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(response.data);
      a.download = `Programme-Details.csv`;
      a.click();
    },
  });

  const isReportFilterActive = filterParams.get('filter') === 'true';

  // clear state to prevent the value persisting issue
  useEffect(
    () => () => {
      dispatch(clearFilterState({}));
    },
    [dispatch],
  );

  return (
    <>
      <div className="naxatw-w-full naxatw-overflow-hidden naxatw-rounded-xl naxatw-border naxatw-border-[#EAECF0] naxatw-bg-white">
        <div className="naxatw-flex naxatw-flex-col">
          <div className="naxatw-flex naxatw-items-center">
            <div className="naxatw-flex-grow">
              <ChartCardHeader
                title="Programme Details"
                needFullScreen={false}
                iconClick={() => refetch()}
                className="!naxatw-pr-4"
              />
            </div>
            <span className="naxatw-pr-5">
              <ExternalFilterButton
                isActive={isReportFilterActive}
                filterCount={Object.keys({})?.length}
                iconClassName="!naxatw-text-[1rem]"
                side="right"
                wrapperClassName="!naxatw-p-[0.42rem]"
                onFilterButtonClick={() => {
                  setFilterParams({ filter: String(!isReportFilterActive) });
                  if (isReportFilterActive) {
                    dispatch(clearFilterState({}));
                  }
                }}
              />
            </span>
          </div>

          <ProgrammeDetailsTable />
        </div>
      </div>
    </>
  );
};

export default ProgrammeDetails;
