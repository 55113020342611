import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Icon from '@Components/common/Icon';
import { cn } from '@Utils/index';
import { useDispatch } from 'react-redux';
import { deleteIndividualList } from '@Store/actions/pdfExport';
import ExportRoundedContainer from '../ExportRoundedContainer';
import CardInfo from '../CardInfo';
import ExportBtn from '../ExportHeader/ExportBtn';

interface IGoalsInterfaceProps {
  iconName: string;
  title: string;
  className?: string;
  dataLists: Record<string, any>[];
  exportLists?: any;
  exportTitles?: Record<string, any>;
}

const CommonCampaignGoals = ({
  iconName,
  title,
  className,
  dataLists,
  exportLists,
  exportTitles,
}: IGoalsInterfaceProps) => {
  const dispatch = useDispatch();

  return (
    <ExportRoundedContainer className={cn('', className)}>
      <div className="header naxatw-flex naxatw-items-center naxatw-gap-1 naxatw-px-3 naxatw-py-2">
        <Icon
          name={iconName}
          className="!naxatw-text-[14px] !naxatw-text-royal-navy"
        />
        <p className="fs-sm-bold naxatw-text-royal-navy">{title}</p>
      </div>
      <div className="body-content naxatw-grid naxatw-grid-cols-3 naxatw-gap-2 naxatw-px-3 naxatw-py-2">
        {dataLists?.map(list => {
          const isDataIncludes =
            Array.isArray(exportLists) && exportLists.includes(list.id);

          if (!isDataIncludes) return null;

          return (
            <div key={list.id} className="naxatw-group naxatw-relative ">
              <CardInfo
                label={list.label}
                value={list.value}
                labelClassNames="!naxatw-text-royal-navy"
              />

              <ExportBtn
                size="sm"
                className="naxatw-right-0 naxatw-top-[8px]"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: exportTitles?.page,
                      section: exportTitles?.section,
                      key: 'datalists',
                      value: list.id,
                    }),
                  )
                }
              />
            </div>
          );
        })}
      </div>
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(CommonCampaignGoals);
