import { api, authenticated } from '.';

export const getContacts = (params: Record<string, any>) => {
  return authenticated(api).get('/contact/', { params });
};

export const getContactById = (id: number) => {
  return authenticated(api).get(`/contact/${id}/`);
};

export const postContact = (payload: Record<string, any>) => {
  return authenticated(api).post('/contact/', payload);
};

export const patchContact = (payload: Record<string, any>, id: number) => {
  return authenticated(api).patch(`/contact/${id}/`, payload);
};

export const delContact = (id: number) => {
  return authenticated(api).delete(`/contact/${id}/`);
};
