/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import ExportLoader from '@Components/common/CommonExport/ExportLoader';
import ExportLogo from '@Components/common/CommonExport/ExportLogo';
import ExportSidebar from '@Components/common/CommonExport/ExportSidebar';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import usePrint from '@Hooks/usePrint';
import { getMunicipalityListById } from '@Services/portfolioProfile';
import { deletePdfSection, resetOverallPage } from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getProvincialCountData } from '@Services/provincialProfile';
import { getKeysByFilter } from '@Constants/PdfContent';
import ExportDescription from '@Components/common/CommonExport/Description';
import { municipalSummary } from '@Services/goals';
import ReportSummary from './ReportSummary';
import ReportPopulation from './ReportPopulation';
import ReportContextual from './ReportContextual';
import ReportPartner from './ReportPartner';
import ReportSector from './ReportSector';
import PdfProgramme from '../ExportPdf/PdfProgramme';
import PalikaImage from './PalikaImage';

const ReportMuniciplaity = () => {
  const { provinceId, municipalityId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { imgData } = useLocation().state;

  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);

  const { data: municipalityData, isLoading: isMunDataLoading } = useQuery({
    queryKey: ['getMunicipality-report', municipalityId],
    queryFn: () => getMunicipalityListById(Number(municipalityId)),
    enabled: !!municipalityId,
    select: (res: { data?: any }) => res?.data ?? [],
  });

  const { pdfContentRef, reactToPrintFn } = usePrint({
    title: municipalityData?.name || '-',
  });

  const showSummary = useTypedSelector(
    state => state.pdfExportSlice.palikaDetails.summary.showSection,
  );

  const showDesc = useTypedSelector(
    state => state.pdfExportSlice.palikaDetails.description.showSection,
  );

  const showPopulation = useTypedSelector(
    state => state.pdfExportSlice.palikaDetails.population.showSection,
  );

  const showContextual = useTypedSelector(
    state => state.pdfExportSlice.palikaDetails.contextual.showSection,
  );

  const showSectorChart = useTypedSelector(
    state => state.pdfExportSlice.palikaDetails.sectorChart.showSection,
  );

  const showPartnerChart = useTypedSelector(
    state => state.pdfExportSlice.palikaDetails.partnerChart.showSection,
  );

  const showProgrammeTable = useTypedSelector(
    state => state.pdfExportSlice.palikaDetails.programTable.showSection,
  );

  const showPalikaImage = useTypedSelector(
    state => state.pdfExportSlice.palikaDetails.palikaImage.showSection,
  );

  const { data: munCount, isLoading: munCountLoading } = useQuery({
    queryKey: ['mun-data-pdf', municipalityId, filterBy],
    queryFn: async () =>
      getProvincialCountData({ province: provinceId, status: filterBy }),
    enabled: !!municipalityId,
    select: res => {
      if (!res.data) return null;
      return res.data;
    },
  });

  const { data: municipalitySummary, isLoading: isMunSumLoading } = useQuery({
    queryKey: [
      'municipality-data-summary-data',
      municipalityData?.cbs_code,
      municipalityData,
    ],
    queryFn: () =>
      municipalSummary({ municipality_code: municipalityData?.cbs_code }),
    select: res => {
      if (!res?.data[0]) return [];
      return res.data[0];
    },
    enabled: !!municipalityData?.cbs_code,
  });

  const isOverallLoading =
    munCountLoading || isMunDataLoading || isMunSumLoading;

  const showResetButton = Object.entries(
    useTypedSelector(state => state.pdfExportSlice.palikaDetails),
  )?.some(([_, secValue]) => !secValue.showSection);

  // cleanup function
  useEffect(() => {
    return () => {
      dispatch(
        resetOverallPage({
          page: 'palikaDetails',
        }),
      );
    };
  }, []);

  return (
    <div className="export-pdf-container">
      {/* ************* SIDEBAR */}

      <ExportSidebar
        heading={
          municipalityData?.district__name && municipalityData?.name
            ? `${municipalityData.district__name} / ${municipalityData.name}`
            : '-'
        }
        onHandleBack={() =>
          navigate(
            `/explore-by/geography/${provinceId}?municipality=${municipalityId}`,
          )
        }
        reactToPrintFn={reactToPrintFn}
        onHandleReset={() =>
          dispatch(
            resetOverallPage({
              page: 'palikaDetails',
            }),
          )
        }
        showPageResetButton={showResetButton}
      />
      <div className="pdf-content-wrapper scrollbar naxatw-h-[calc(100vh-143px)] naxatw-overflow-y-auto">
        {/* ************ Main Pdf Content Starts **************** */}

        <div
          className="naxatw-relative naxatw-mx-auto naxatw-mb-16 naxatw-w-[210mm] naxatw-bg-white"
          ref={pdfContentRef}
          id="print-content"
        >
          {isOverallLoading ? (
            <ExportLoader />
          ) : (
            <div
              className="pdf-content-container naxatw-flex naxatw-flex-col naxatw-space-y-5 naxatw-px-5 naxatw-pb-5 !naxatw-text-[#000]"
              ref={pdfContentRef}
              id="print-content"
            >
              <ExportLogo />
              <div className="main-heading">
                <h5 className="naxatw-mb-2">{municipalityData?.name || '-'}</h5>
                <p className="fs-md-semiold naxatw-text-primary-700">
                  {`BEK’s Current Concentration of ${getKeysByFilter[filterBy]} Programme/Projects`}
                </p>
              </div>

              <div className="naxatw-flex naxatw-gap-2">
                {showSummary && (
                  <ReportSummary municipalityCount={munCount || []} />
                )}
                {showPalikaImage && <PalikaImage palikaImage={imgData} />}
              </div>

              {showDesc && (
                <ExportDescription
                  onHandleClose={() =>
                    dispatch(
                      deletePdfSection({
                        page: 'palikaDetails',
                        section: 'description',
                      }),
                    )
                  }
                />
              )}
              {showPopulation && (
                <ReportPopulation
                  municipalitySummary={municipalitySummary || []}
                />
              )}
              {showContextual && (
                <ReportContextual
                  municipalitySummary={municipalitySummary || []}
                  palikaName={municipalityData?.name || '-'}
                />
              )}
              {showPartnerChart && <ReportPartner />}
              {showSectorChart && <ReportSector />}
              {showProgrammeTable && (
                <PdfProgramme provinceCount={munCount || []} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default hasErrorBoundary(ReportMuniciplaity);
