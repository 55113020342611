/* eslint-disable no-underscore-dangle */
import { v4 as uuidv4 } from 'uuid';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Icon from '@Components/common/Icon';
import {
  mapGovernmentStakeHolderColors,
  mapGovernmentStakeHoldersTitle,
} from '@Constants/mainDashboard';
import { useTypedSelector } from '@Store/hooks';
import { FlexColumn } from '@Components/common/Layouts';
import isEmpty from '@Utils/isEmpty';

interface IPieChartLegendDetails {
  onClose: () => void;
  legendDetails: Record<string, any>;
  chartId?: string;
}

const PieChartLegendDetails = ({
  onClose,
  legendDetails,
  chartId,
}: IPieChartLegendDetails) => {
  const showFilterColumn = useTypedSelector(
    state => state?.mainDashboard?.showFilterColumn,
  );

  const isStakeHolderChart = chartId === 'government-stakeholders';

  return (
    <div className="naxatw-relative  naxatw-hidden naxatw-bg-white naxatw-pl-3  naxatw-pr-8 naxatw-pt-8 lg:naxatw-block">
      <div className="naxatw-flex naxatw-items-start naxatw-gap-4">
        <button
          type="button"
          className="naxatw-sticky naxatw-top-6 naxatw-text-xl naxatw-text-primary-600"
        >
          <Icon name="west" onClick={onClose} tabIndex={-1} />
        </button>
        <div className="deatils scrollbar naxatw-h-full naxatw-overflow-y-auto">
          {/* title */}
          <div className="details-title naxatw-mb-4 naxatw-flex naxatw-items-center naxatw-gap-2">
            <div className="naxatw-flex naxatw-flex-1 naxatw-items-center naxatw-gap-2">
              <div
                className="naxatw-h-[0.75rem] naxatw-w-[0.75rem] naxatw-rounded-[0.25rem]"
                style={{
                  backgroundColor:
                    (!isEmpty(mapGovernmentStakeHoldersTitle) &&
                      mapGovernmentStakeHolderColors[
                        isStakeHolderChart
                          ? legendDetails?.stakeholder_type
                          : legendDetails?._id
                      ]) ||
                    '',
                }}
              />
              <p className="naxatw-chart-text">
                {(!isEmpty(mapGovernmentStakeHoldersTitle) &&
                  mapGovernmentStakeHoldersTitle[
                    isStakeHolderChart
                      ? legendDetails?.stakeholder_type
                      : legendDetails?._id
                  ]) ||
                  '-'}
              </p>
            </div>
            <p className="naxatw-chart-text">{legendDetails?.value || '-'}</p>
          </div>

          {/* program project and stakeholder data */}
          <div className="details-body">
            <div
              className={`title naxatw-mb-3 naxatw-grid naxatw-grid-cols-2 naxatw-gap-4 ${showFilterColumn ? 'naxatw-justify-between' : ''}`}
            >
              {/* <p className="naxatw-chart-text">Province</p> */}
              <p
                className={`naxatw-chart-text naxatw-w-[14rem] ${showFilterColumn ? '' : 'naxatw-w-[14rem]'}`}
              >
                {legendDetails?.data_by_province[0]?.data[0]?.project_name
                  ? 'Project'
                  : 'Programme'}
              </p>
              <p className="naxatw-chart-text">Stakeholders</p>
            </div>

            {/* programme_name */}
            <FlexColumn>
              {legendDetails?.data_by_province?.map(
                (legendData: Record<string, any>) => {
                  return (
                    <div
                      className="naxatw-grid naxatw-grid-cols-2 naxatw-items-end  naxatw-gap-4 naxatw-border-b naxatw-border-b-[#E3E3E3] naxatw-py-1 last:naxatw-border-0"
                      key={uuidv4()}
                    >
                      <FlexColumn>
                        {legendDetails?.stakeholder_type ===
                        'Government Stakeholder' ? null : (
                          <p
                            className="naxatw-chart-text naxatw-mb-1 naxatw-line-clamp-1"
                            title={legendData?.province_name}
                          >
                            {legendData?.province_name
                              ? legendData?.province_name
                              : '-'}
                          </p>
                        )}
                        {legendData?.data?.map((projectData: any) => (
                          <p
                            className="naxatw-line-clamp-1 naxatw-text-[0.875rem] naxatw-font-medium naxatw-text-matt-100"
                            key={uuidv4()}
                            title={
                              projectData?.project_name ||
                              projectData?.program_name
                            }
                          >
                            {projectData?.project_name ||
                              projectData?.program_name}
                          </p>
                        ))}
                      </FlexColumn>
                      <p className="naxatw-text-[0.875rem] naxatw-font-medium naxatw-text-matt-100">
                        {legendData?.data?.map((stakeholderData: any) => (
                          <p
                            className="naxatw-line-clamp-1 naxatw-text-[0.875rem] naxatw-font-medium naxatw-text-matt-100"
                            key={uuidv4()}
                            title={stakeholderData?.stakeholder_name}
                          >
                            {stakeholderData?.stakeholder_name}
                          </p>
                        ))}
                      </p>
                    </div>
                  );
                },
              )}
            </FlexColumn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default hasErrorBoundary(PieChartLegendDetails);
