import CommonGroups from '@Components/common/CommonExport/CommonGroups';
import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import { getMarkers } from '@Services/program';
import {
  deletePdfSection,
  resetPdfSection,
  setOriginalDataLists,
} from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import isEmpty from '@Utils/isEmpty';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const PdfGroups = () => {
  const { programmeId, projectId } = useParams();
  const dispatch = useDispatch();

  const pageType = projectId ? 'projectDetails' : 'programmeDetails';

  const showResetButton = useTypedSelector(state =>
    projectId
      ? state.pdfExportSlice.projectDetails.groups.showResetButton
      : state.pdfExportSlice.programmeDetails.groups.showResetButton,
  );

  const dataLists = useTypedSelector(state =>
    projectId
      ? state.pdfExportSlice.projectDetails.groups.datalists
      : state.pdfExportSlice.programmeDetails.groups.datalists,
  );

  const { data: groupLists } = useQuery({
    queryKey: ['markers-pdf', programmeId, projectId],
    queryFn: () =>
      getMarkers({
        ...(projectId ? { project: projectId } : { program: programmeId }),
      }),
    enabled: projectId ? !!projectId : !!programmeId,
    select: res => {
      if (!res?.data) return null;
      const groupData = res?.data?.find(
        (group: Record<string, any>) =>
          group.marker_category === 'Group Marker',
      )?.marker_value;

      if (!groupData) return [];

      const finalGroupData = groupData?.map((group: Record<string, any>) => ({
        id: group,
        label: group,
      }));
      return finalGroupData;
    },

    onSuccess: response => {
      if (!response) return null;
      dispatch(
        setOriginalDataLists({
          page: pageType,
          section: 'groups',
          value: response,
        }),
      );
      return null;
    },
  });

  if (isEmpty(groupLists)) return null;

  return (
    <div className="pdf-groups-container naxatw-relative naxatw-h-full">
      <ExportHeader
        className="!naxatw-max-h-[34px]"
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: pageType,
              section: 'groups',
            }),
          )
        }
        onHandleReset={() => {
          dispatch(
            resetPdfSection({
              page: pageType,
              section: 'groups',
            }),
          );
        }}
        noTitleWithResetBtn={showResetButton}
        showResetButton={showResetButton}
      />
      <CommonGroups
        iconName="category"
        title="Groups"
        dataLists={groupLists || []}
        exportLists={dataLists || []}
        exportTitles={{
          page: pageType,
          section: 'groups',
        }}
      />
    </div>
  );
};

export default PdfGroups;
