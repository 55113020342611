import { useSpring, animated } from '@react-spring/web';
import { useDispatch } from 'react-redux';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { useTypedSelector } from '@Store/hooks';
import { setChartStates } from '@Store/actions/mainDashboard';
import PieChartLegendDetails from '../PieChartLegendDetails';
import PieChartLegendItem from '../PieChartLegendItem';

const PieChartLegend = ({
  data,
  chartId,
  showPound,
  fill,
}: {
  data: Record<string, any>;
  chartId?: string;
  showPound?: boolean;
  fill?: string[];
}) => {
  const dispatch = useDispatch();
  const showLegendDetails = useTypedSelector(
    state => state.mainDashboard.charts.showLegendDetails,
  );

  const chartDetails = useTypedSelector(
    state => state.mainDashboard.charts.chartDetails,
  );

  const detailsStyle = useSpring({
    opacity: showLegendDetails ? 1 : 0,
    config: { tension: 150, friction: 40 },
  });

  const legendItemsStyle = useSpring({
    opacity: showLegendDetails ? 0 : 1,
    config: { tension: 150, friction: 40 },
  });

  function getChartDataById(chartName: string | undefined) {
    if (!chartName) return [];
    switch (chartName) {
      case 'government-stakeholders':
        return data[0]?.stakeholders;
      default:
        return data;
    }
  }

  const pieChartData = getChartDataById(chartId);

  return (
    <div className="legend-pie-chart-inner naxatw-h-full">
      <div
        className={`naxatw-duration-4000 naxatw-mb-8  naxatw-grid naxatw-h-full  naxatw-transition-all naxatw-ease-out md:naxatw-mb-0 ${showLegendDetails ? 'naxatw-place-items-baseline' : 'naxatw-place-items-center'}`}
      >
        {showLegendDetails ? (
          <animated.div style={detailsStyle}>
            <PieChartLegendDetails
              onClose={() =>
                dispatch(
                  setChartStates({ key: 'showLegendDetails', value: false }),
                )
              }
              legendDetails={chartDetails}
              chartId={chartId}
            />
          </animated.div>
        ) : (
          <animated.div
            style={legendItemsStyle}
            className="legend-items naxatw-pb-8 lg:naxatw-pb-0"
          >
            {pieChartData?.map(
              (legendItem: Record<string, any>, idx: number) => (
                <PieChartLegendItem
                  key={legendItem.name}
                  data={legendItem}
                  chartId={chartId}
                  fill={fill ? fill[idx] : ''}
                  showPound={showPound}
                  className={
                    showLegendDetails
                      ? ''
                      : '!naxatw-pointer-events-none !naxatw-cursor-pointer lg:!naxatw-pointer-events-auto'
                  }
                  onClick={() => {
                    dispatch(
                      setChartStates({
                        key: 'chartDetails',
                        value: legendItem,
                      }),
                    );
                    dispatch(
                      setChartStates({ key: 'showLegendDetails', value: true }),
                    );
                  }}
                />
              ),
            )}
          </animated.div>
        )}
      </div>
    </div>
  );
};

export default hasErrorBoundary(PieChartLegend);
