import { FlexColumn } from '@Components/common/Layouts';
import { useTypedSelector } from '@Store/hooks';
import { cn } from '@Utils/index';
import { v4 as uuidv4 } from 'uuid';

type Stakeholder = {
  province_name: string;
  data: { project_name: string; stakeholder_name: string }[];
};

type Stakeholders = {
  data: Stakeholder[];
  name: string;
};
function NumberOfStakeholderProjectsTable() {
  const chartDetails = useTypedSelector<Stakeholders>(
    state => state.mainDashboard.charts.chartDetails,
  );

  return (
    <FlexColumn>
      <table>
        <thead className="naxatw-text-left">
          <tr className="naxatw-text-[.875rem] naxatw-text-grey-800 [&>th]:naxatw-pb-3">
            <th>Projects</th>
            <th>Stakeholders</th>
          </tr>
        </thead>
        <tbody>
          {chartDetails.data?.map(stakeholder => {
            const len = stakeholder.data?.length;
            return (
              <>
                <tr className="naxatw-border-b !naxatw-bg-white naxatw-px-3 naxatw-py-1 naxatw-text-secondary-500 naxatw-overline naxatw-no-underline">
                  <td colSpan={2} className="naxatw-pb-1">
                    {stakeholder.province_name}
                  </td>
                </tr>
                {stakeholder.data?.map((provinceData, idx) => (
                  <tr
                    key={uuidv4()}
                    className={cn(
                      'naxatw-px-3 naxatw-py-1 naxatw-pb-3 naxatw-text-xs naxatw-leading-4 naxatw-text-grey-800 [&>td]:naxatw-px-3 [&>td]:naxatw-py-1',
                      (idx + 1) % 2 === 0 && idx > 0
                        ? '!naxatw-bg-gray-100'
                        : '!naxatw-bg-white',
                      len === idx && 'naxatw-border-spacing-3',
                    )}
                  >
                    <td>{provinceData.project_name}</td>
                    <td>{provinceData.stakeholder_name}</td>
                  </tr>
                ))}
                <tr className="naxatw-h-3 !naxatw-bg-white" />
              </>
            );
          })}
        </tbody>
      </table>
    </FlexColumn>
  );
}

export default NumberOfStakeholderProjectsTable;
