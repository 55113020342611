/* eslint-disable import/prefer-default-export */
import { dataBankSlice } from '@Store/slices/databankforms';

export const {
  setFilterState,
  clearFilterState,
  setAreaFilterState,
  clearAreaFilterState,
  setDaterangeFilterState,
  clearDateRangeFilterState,
  setknowRepoState,
} = dataBankSlice.actions;
