import { useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTypedSelector } from '@Store/hooks';
import ChartRenderer from '@Components/common/Charts/ChartRenderer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
// import { viewByOptionsSectorsCard } from '@Constants/mainDashboard';
import {
  getTopFiveSectorsByComponent,
  getTopFiveSectorsByProgramme,
} from '@Services/mainDashboard';
// import { setSectorsSelectedTab } from '@Store/actions/provincialProfile';

function Sectors({ isExport = false }: { isExport?: boolean }) {
  const { provinceId } = useParams();
  const [searchParams] = useSearchParams();
  const municipalityCode = searchParams.get('municipality');
  const downloadComponentRef = useRef<any>(null);

  const sector = useTypedSelector(
    state => state.provincialProfile.chartSwitchTab.sector,
  );
  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);

  const chartData = {
    id: `top-sectors-by-budget-allocation-province-${provinceId}-${sector}`,
    queryFn:
      sector === 'Project'
        ? getTopFiveSectorsByComponent
        : getTopFiveSectorsByProgramme,
    params: {
      province: provinceId,
      status: filterBy,
      project_status: sector,
      municipality: municipalityCode,
    },
    title: `Top 5 Sectors by ${sector}`,
    hasDownloadBtn: true,
    chartType: 'bar',
    hasHeader: true,
    fill: ['#417EC9'],
    xLabel: 'Sectors',
    yLabel: `${sector}`,
    name: 'top-sectors-by-programme-project',
    height: isExport ? '12rem' : '18rem',
    // switchTabData: {
    //   switchTabOptions: viewByOptionsSectorsCard,
    //   dispatchAction: setSectorsSelectedTab,
    //   activeLabel: sector,
    // },
    hoverTitle: `${sector} Count`,
    showYAxisIntOnly: true,
    isExport,
    chartWrapperStyles: isExport ? '!naxatw-p-0' : '',
    xLabelClassname: isExport ? '' : '!naxatw-py-4 naxatw-font-bold',
    yLabelClassNames: isExport ? '' : 'custom-y-label !naxatw-font-bold',
    wrapXAxis: true,
    barSize: isExport ? 25 : 60,
  };

  return (
    <div
      ref={downloadComponentRef}
      className={`naxatw-h-full ${isExport ? 'naxatw-h-[12rem]' : 'naxatw-max-h-[28rem] naxatw-min-h-[28rem] naxatw-shadow-sm'} naxatw-overflow-hidden naxatw-rounded-xl naxatw-bg-white`}
    >
      <ChartRenderer data={chartData} />
    </div>
  );
}
export default hasErrorBoundary(Sectors);
