/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import ExportHeading from '@Components/common/CommonExport/ExportHeading';
import ExportLoader from '@Components/common/CommonExport/ExportLoader';
import ExportLogo from '@Components/common/CommonExport/ExportLogo';
import ExportSidebar from '@Components/common/CommonExport/ExportSidebar';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import usePrint from '@Hooks/usePrint';
import {
  getProgrammeDataByProgrammeId,
  getProvincialCountData,
} from '@Services/provincialProfile';
import { useTypedSelector } from '@Store/hooks';
import { resetOverallPage } from '@Store/actions/pdfExport';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import isEmpty from '@Utils/isEmpty';
import PdfStakeHolder from './PdfStakeholders';
import PdfSummary from './PdfSummary';
import PdfCampaignGoals from './PdfCampaignGoals';
import PdfGroups from './PdfGroups';
import PdfCharts from './PdfCharts';
import PdfSectors from './PdfSectors';
import PdfMarkers from './PdfMarkers';
import PdfProjectTable from './PdfProjectTable';
import PdfStakeholderChart from './PdfStakeholderChart';
import PdfWorkingAreas from './PdfWorkingAreas';
import PdfMap from './PdfMap';

const ExportProgrammePdf = () => {
  const { programmeId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showSummary = useTypedSelector(
    state => state.pdfExportSlice.programmeDetails.summary.showSection,
  );

  const showGoals = useTypedSelector(
    state => state.pdfExportSlice.programmeDetails.goals.showSection,
  );

  const showGroups = useTypedSelector(
    state => state.pdfExportSlice.programmeDetails.groups.showSection,
  );

  const showStatusChart = useTypedSelector(
    state => state.pdfExportSlice.programmeDetails.statusChart.showSection,
  );

  const showModalityChart = useTypedSelector(
    state => state.pdfExportSlice.programmeDetails.modalityChart.showSection,
  );

  const showSectors = useTypedSelector(
    state => state.pdfExportSlice.programmeDetails.sectors.showSection,
  );

  const showMarkers = useTypedSelector(
    state => state.pdfExportSlice.programmeDetails.markers.showSection,
  );

  const showProjectTable = useTypedSelector(
    state => state.pdfExportSlice.programmeDetails.projectTable.showSection,
  );

  const showNationalStakeholder = useTypedSelector(
    state =>
      state.pdfExportSlice.programmeDetails.nationalStakeHolder.showSection,
  );

  const showSubNationalStakeholder = useTypedSelector(
    state =>
      state.pdfExportSlice.programmeDetails.subNationalStakeHolder.showSection,
  );

  const showWorkingAreas = useTypedSelector(
    state => state.pdfExportSlice.programmeDetails.workingAreas.showSection,
  );

  const showStakeholderChart = useTypedSelector(
    state => state.pdfExportSlice.programmeDetails.stakeholderChart.showSection,
  );

  const showMap = useTypedSelector(
    state => state.pdfExportSlice.programmeDetails.map.showSection,
  );

  const groupLists = useTypedSelector(
    state => state.pdfExportSlice.programmeDetails.groups.originalLists,
  );

  const { data: programmeData, isLoading: isProgrammeDataLoading } = useQuery({
    queryKey: ['programmeList-pdf', programmeId],
    queryFn: async () =>
      getProgrammeDataByProgrammeId({ program: programmeId }),
    select: res => {
      if (!res?.data[0]) return null;
      return res.data[0];
    },
    enabled: !!programmeId,
  });

  const { pdfContentRef, reactToPrintFn } = usePrint({
    title: programmeData && `${programmeData?.program_name || '-'}`,
  });

  const { data: programmeCount, isLoading: isProgrammeCountLoading } = useQuery(
    {
      queryKey: ['province-data-pdf', programmeId],
      queryFn: async () => getProvincialCountData({ program: programmeId }),
      enabled: !!programmeId,
      select: res => {
        if (!res.data) return null;
        return res.data;
      },
    },
  );

  const overallIsLoading = isProgrammeCountLoading || isProgrammeDataLoading;

  const showResetButton = Object.entries(
    useTypedSelector(state => state.pdfExportSlice.programmeDetails),
  )?.some(([_, secValue]) => !secValue.showSection);

  // cleanup function
  useEffect(() => {
    return () => {
      dispatch(
        resetOverallPage({
          page: 'programmeDetails',
        }),
      );
    };
  }, []);

  return (
    <div className="programme-pdf-wrapper">
      <ExportSidebar
        heading={programmeData?.program_name || '-'}
        reactToPrintFn={reactToPrintFn}
        onHandleBack={() => navigate(`/explore-by/programmes/${programmeId}`)}
        onHandleReset={() =>
          dispatch(
            resetOverallPage({
              page: 'programmeDetails',
            }),
          )
        }
        showPageResetButton={showResetButton}
      />
      <div className="pdf-content-wrapper scrollbar naxatw-h-[calc(100vh-143px)] naxatw-overflow-y-auto">
        {/* ************ Main Pdf Content Starts **************** */}
        <div
          className="naxatw-relative naxatw-mx-auto naxatw-mb-16 naxatw-w-[210mm] naxatw-bg-white"
          ref={pdfContentRef}
          id="print-content"
        >
          {overallIsLoading ? (
            <ExportLoader />
          ) : (
            <div
              className="pdf-content-container naxatw-flex naxatw-flex-col naxatw-space-y-5 naxatw-px-5 naxatw-pb-5 !naxatw-text-[#000]"
              ref={pdfContentRef}
              id="print-content"
            >
              <ExportLogo />
              <ExportHeading headerTitle={programmeData?.program_name} />
              {showSummary && (
                <PdfSummary programmeCount={programmeCount || {}} />
              )}
              {(showGroups || showGoals) && (
                <div
                  className={`naxatw-grid  naxatw-gap-2 ${showGroups && !isEmpty(groupLists) && showGoals ? 'naxatw-grid-cols-2' : ''}`}
                >
                  {showGoals && <PdfCampaignGoals />}
                  {showGroups && <PdfGroups />}
                </div>
              )}
              {(showStatusChart || showModalityChart) && <PdfCharts />}
              {showSectors && <PdfSectors />}
              {showMarkers && <PdfMarkers />}
              {showProjectTable && <PdfProjectTable />}
              {showStakeholderChart && <PdfStakeholderChart />}
              {(showNationalStakeholder || showSubNationalStakeholder) && (
                <PdfStakeHolder />
              )}

              {showMap && <PdfMap />}
              {showWorkingAreas && <PdfWorkingAreas />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default hasErrorBoundary(ExportProgrammePdf);
