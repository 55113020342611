import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import AgreementModalityTable from '@Components/MainDashboard/ChartSection/ChartNestedContent/AgreementModalityTable';

interface ICustomDataProps {
  data: Record<string, any>[];
  chartTitle: string;
}

const CustomTableData = ({ data, chartTitle }: ICustomDataProps) => {
  const renderChartComponent = () => {
    switch (chartTitle) {
      case 'Number of Project by Agreement Modality':
        return <AgreementModalityTable tableData={data || []} />;
      default:
        return <p>Chart Title Required</p>;
    }
  };

  return (
    <div className="chart-data-table-wrapper naxatw-col-span-12">
      {renderChartComponent()}
    </div>
  );
};

export default hasErrorBoundary(CustomTableData);
