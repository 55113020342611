/* eslint-disable no-unused-vars */
import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { deletePdfSection } from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import isEmpty from '@Utils/isEmpty';
import { format, parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';

const FilterSection = ({
  title,
  category,
  filterIds,
}: {
  title: string;
  category: string;
  filterIds: number[];
}) => {
  const filterState = useTypedSelector(
    state => state.mainDashboard.filterState,
  );
  const section = filterState?.find(item => item.category === category);

  if (isEmpty(filterIds)) return null;

  return (
    <div className="filter-section">
      <p className="fs-sm-bold naxatw-mb-1 naxatw-pl-1">{title}</p>
      <div className="filter-container naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-2">
        {section?.subcategories?.map((filterItem: Record<string, any>) => {
          if (!filterIds.includes(filterItem.id)) return null;
          return (
            <div
              key={filterItem.id}
              className="fs-sm-medium naxatw-overflow-hidden naxatw-rounded-full naxatw-border naxatw-border-[#E3E4E3] naxatw-bg-[#F7F7F7] naxatw-px-2 naxatw-py-1"
            >
              {filterItem.subcategory}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const NestedFilterSection = ({
  title,
  category,
  filterIds,
  subFilterIds,
}: {
  title: string;
  category: string;
  filterIds: number[];
  subFilterIds: number[];
}) => {
  const filterState = useTypedSelector(
    state => state.mainDashboard.filterState,
  );
  const section = filterState?.find(item => item.category === category);

  if (isEmpty(filterIds)) return null;

  return (
    <div className="nested-filter-section">
      <p className="fs-sm-bold naxatw-mb-1 naxatw-pl-1">{title}</p>
      <div className="naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-x-2 naxatw-gap-y-2">
        {section?.subcategories?.map((filterItem: Record<string, any>) => {
          if (!filterIds.includes(filterItem.id)) return null;
          return (
            <div key={filterItem?.id}>
              <p className="fs-sm-medium naxatw-mb-1  naxatw-pl-1">
                {filterItem?.subcategory}
              </p>
              <div className="naxatw-flex naxatw-gap-2">
                {filterItem?.filterItems?.map(
                  (subItem: Record<string, any>) => {
                    if (!subFilterIds.includes(subItem.id)) return null;
                    return (
                      <div
                        key={subItem.id}
                        className="fs-sm-medium naxatw-overflow-hidden naxatw-rounded-full naxatw-border naxatw-border-[#E3E4E3] naxatw-bg-[#F7F7F7] naxatw-px-2 naxatw-py-1"
                      >
                        {subItem.filter}
                      </div>
                    );
                  },
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ReportFilters = () => {
  const dispatch = useDispatch();

  const programmeFilter = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const projectFilter = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const partnerFilter = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorFilter = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const markerFilter = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );

  const subMarkerFilter = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDateFilter = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDateFilter = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );
  const statusFilter = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );

  const provinceFilter = useTypedSelector(
    state => state.mainDashboard.filterParams.province,
  );

  return (
    <div className="report-filters">
      <ExportHeader
        headerTitle="Filter Applied"
        isStyleAbsolute={false}
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: 'bek',
              section: 'filters',
            }),
          )
        }
      />
      <ExportRoundedContainer className="naxatw-space-y-3 naxatw-px-3 naxatw-py-2">
        <div className="status-filters">
          <p className="fs-sm-bold naxatw-mb-1 naxatw-pl-1">Status</p>
          <p className="fs-sm-medium naxatw-w-fit naxatw-overflow-hidden naxatw-rounded-full naxatw-border naxatw-border-[#E3E4E3] naxatw-bg-[#F7F7F7]  naxatw-px-2 naxatw-py-1">
            {statusFilter || 'All'}
          </p>
        </div>

        <FilterSection
          title="Province"
          category="Province"
          filterIds={provinceFilter}
        />
        <NestedFilterSection
          title="Programme"
          category="Programme"
          filterIds={programmeFilter}
          subFilterIds={projectFilter}
        />
        <FilterSection
          title="First Tier Partner"
          category="First Tier Partner"
          filterIds={partnerFilter}
        />
        <FilterSection
          title="Sector"
          category="Sector"
          filterIds={sectorFilter}
        />

        <NestedFilterSection
          title="Marker / Group"
          category="Marker / Group"
          filterIds={subMarkerFilter}
          subFilterIds={markerFilter}
        />
        {(startDateFilter || endDateFilter) && (
          <div className="date-filter naxatw-flex naxatw-items-center naxatw-gap-6">
            {startDateFilter && (
              <div>
                <p className="fs-sm-bold naxatw-mb-1">Start Date</p>
                <p className="fs-sm-medium naxatw-mb-1">
                  {' '}
                  {format(parseISO(startDateFilter), 'MMMM dd, yyyy')}
                </p>
              </div>
            )}

            {endDateFilter && (
              <div>
                <p className="fs-sm-bold naxatw-mb-1">End Date</p>
                <p className="fs-sm-medium naxatw-mb-1">
                  {format(parseISO(endDateFilter), 'MMMM dd, yyyy')}
                </p>
              </div>
            )}
          </div>
        )}
      </ExportRoundedContainer>
    </div>
  );
};

export default hasErrorBoundary(ReportFilters);
