/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import dvsLogoNew from '@Assets/images/dvs-logo-new.svg';
import dvsLogoSmall from '@Assets/images/dvsLogoHalf.svg';
import { navbarData } from '@Constants/navbarData';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import NestedNavLink from '@Components/common/Navbar/NestedNavLink';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { checkUserProfile, checkUserProfilePermission } from '@Services/common';
// import Notification from '@Components/common/Navbar/Notification';
import AccountMenu from '@Components/common/Navbar/AccountMenu';
import { setCommonState } from '@Store/actions/common';
import useScrollPosition from '@Hooks/useScrollPosition';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@Store/hooks';
import tutorialHoverIcon from '@Assets/icons/tuthover.svg';
import Icon from '../Icon';

import tutorialIcon from '../../../assets/icons/tutorial.svg';
import ToolTipContainer from '../ToolTipContainer';

const Navbar = () => {
  const { pathname } = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const [checkUser, setCheckUser] = useState(false);
  const [isTutHovered, setIsTutHovered] = useState(false);
  const navigate = useNavigate();
  const splittedPathname = pathname?.split('/');
  const scrollPosition = useScrollPosition();
  const [width, setWidth] = useState(window.innerWidth);
  const token = localStorage.getItem('token');
  const dispatch = useDispatch();
  const userProfile = useTypedSelector(state => state?.common?.userProfile);

  // const isNewUpdateFetched = useTypedSelector(
  //   state => state.UpdateSlice.isNewUpdateFetched,
  // );
  const isNewUpdateFetched = false;

  const springs = useSpring({
    // opacity: burgerMenuOpen ? 1 : 0,
    transform: burgerMenuOpen ? 'translateX(0%)' : 'translateX(100%)',
    width: burgerMenuOpen ? '100%' : '0%',
    opacity: burgerMenuOpen ? 1 : 0,
    config: { tension: 150, friction: 20 },
  });

  // check login in user
  useQuery({
    queryKey: ['check-user-profiles'],
    queryFn: checkUserProfile,
    enabled: checkUser,
    onSuccess: res => {
      dispatch(setCommonState({ userProfile: res?.data }));
    },
    onError: (error: any) => {
      dispatch(setCommonState({ userProfileError: error?.message }));
    },
  });

  // check permission for logged in user
  useQuery({
    queryKey: ['get-group-id-permission'],
    queryFn: () => checkUserProfilePermission(userProfile?.group),
    enabled: !!userProfile?.group,
    onSuccess: res => {
      if (!res?.data) return;
      const userPermissionNames = res.data.map(
        (permisssion: Record<string, any>) => permisssion.name,
      );
      dispatch(setCommonState({ userGroupPermission: userPermissionNames }));
      dispatch(setCommonState({ isGroupPermissionSuccess: true }));
    },
  });

  useEffect(() => {
    if (!token) return;
    setCheckUser(true);
  }, [token]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const navClassname =
    'naxatw-relative naxatw-border-b-2 naxatw-border-transparent naxatw-p-3 naxatw-text-base naxatw-font-medium naxatw-text-matt-100 naxatw-duration-200';

  const circleIndicatorStyle =
    'naxatw-absolute naxatw-right-1 naxatw-top-3 naxatw-block naxatw-h-2 naxatw-w-2 naxatw-animate-pulse naxatw-rounded-full naxatw-bg-[#EC5B44]';

  return (
    <>
      <div
        className={`naxatw-flex ${
          scrollPosition > 0 ? 'naxatw-h-[63px]' : 'naxatw-py-3'
        } naxatw-border-[#D7D7D7]' naxatw-items-center naxatw-justify-between  naxatw-border-b  naxatw-px-6
        `}
      >
        {dropdownOpen && (
          <div className="naxatw-absolute naxatw-left-0 naxatw-top-0 naxatw-z-50 naxatw-h-screen naxatw-w-screen naxatw-bg-[#417EC9] naxatw-opacity-10" />
        )}
        <div
          tabIndex={0}
          role="button"
          className="logo-container naxatw-min-w-[9rem] naxatw-cursor-pointer"
          onClick={() => navigate('/')}
        >
          {scrollPosition > 0 ? (
            <img src={dvsLogoSmall} alt="logo" />
          ) : (
            <img src={dvsLogoNew} alt="logo" />
          )}
        </div>
        {width > 1120 && (
          <div className="naxatw-flex naxatw-items-center naxatw-gap-x-9">
            {navbarData.map((navbarItem): React.ReactNode | null => {
              if (
                (pathname.includes('admin') &&
                  navbarItem.name !== 'Admin Panel') ||
                (!pathname.includes('admin') && pathname !== '/')
              ) {
                return (
                  <div
                    key={navbarItem.id}
                    className="navbar-items naxatw-h-full"
                  >
                    {!navbarItem.children && navbarItem.link ? (
                      <NavLink
                        className={() =>
                          (splittedPathname?.length > 0 &&
                            navbarItem?.link?.split('/')?.[1] ===
                              splittedPathname[1]) ||
                          navbarItem.link?.includes(splittedPathname[1])
                            ? `${navClassname} naxatw-border-b-[#417EC9]`
                            : `${navClassname} hover:naxatw-text-[#417EC9]`
                        }
                        to={navbarItem.link}
                      >
                        {navbarItem.name}
                        {isNewUpdateFetched &&
                          navbarItem.link?.startsWith('/updates') && (
                            <div className="naxatw-absolute naxatw-right-1 naxatw-top-3 naxatw-block naxatw-h-2 naxatw-w-2 naxatw-animate-pulse naxatw-rounded-full naxatw-bg-[#EC5B44]" />
                          )}
                      </NavLink>
                    ) : (
                      <NestedNavLink
                        navbarItem={navbarItem}
                        setDropdownOpen={setDropdownOpen}
                      />
                    )}
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}
        {/* {pathname !== '/' && ( */}
        <FlexRow className="naxatw-items-center naxatw-gap-6">
          <FlexRow className="naxatw-items-center naxatw-gap-4">
            {/* ------------> update-icon */}
            <ToolTipContainer message="DVS Tutorial">
              <button
                type="button"
                onClick={() => navigate('/dvs-tutorial')}
                className="naxatw-h-full naxatw-w-full"
                onMouseEnter={() => setIsTutHovered(true)}
                onMouseLeave={() => setIsTutHovered(false)}
              >
                <img
                  src={isTutHovered ? tutorialHoverIcon : tutorialIcon}
                  height={24}
                  width={24}
                  alt="tutorial icon"
                />
              </button>
            </ToolTipContainer>

            <AccountMenu />
          </FlexRow>
          {width <= 1120 && (
            <Icon
              name="menu"
              className="naxatw-cursor-pointer"
              onClick={() => setBurgerMenuOpen(true)}
            />
          )}
        </FlexRow>
        {/* )} */}
      </div>
      <div
        className={`naxatw-absolute naxatw-right-0 naxatw-top-0 naxatw-z-[50] naxatw-h-screen naxatw-w-full naxatw-bg-[#2e2e2e4e] ${burgerMenuOpen ? 'naxatw-block' : 'naxatw-hidden'}`}
      />
      <animated.div
        style={{ ...springs }}
        className="naxatw-absolute naxatw-right-0 naxatw-top-0 naxatw-z-[100] naxatw-h-screen naxatw-w-[45%] naxatw-overflow-hidden naxatw-bg-white"
      >
        <FlexColumn>
          <FlexRow className="naxatw-items-center naxatw-justify-between naxatw-px-6 naxatw-py-4">
            <img src={dvsLogoNew} alt="logo" />

            <Icon name="close" onClick={() => setBurgerMenuOpen(false)} />
          </FlexRow>
          <div className="naxatw-h-[1px] naxatw-w-full naxatw-bg-primary-200" />
          <FlexColumn className="naxatw-p-3">
            {navbarData.map((navbarItem): React.ReactNode | null => {
              if (
                (pathname.includes('admin') &&
                  navbarItem.name !== 'Admin Panel') ||
                (!pathname.includes('admin') && pathname !== '/')
              ) {
                return (
                  <div
                    key={navbarItem.id}
                    className={`navbar-items naxatw-flex naxatw-h-[4rem] naxatw-w-full naxatw-items-center naxatw-rounded-lg naxatw-p-3 ${
                      splittedPathname?.length > 0 &&
                      navbarItem?.link?.split('/')?.[1] === splittedPathname[1]
                        ? ' naxatw-bg-secondary-100'
                        : 'naxatw-bg-transparent hover:naxatw-bg-secondary-100'
                    }`}
                  >
                    {!navbarItem.children && navbarItem.link ? (
                      <NavLink
                        onClick={() => setBurgerMenuOpen(false)}
                        className={`
                          ${
                            splittedPathname?.length > 0 &&
                            navbarItem?.link?.split('/')?.[1] ===
                              splittedPathname[1]
                              ? 'naxatw-text-primary-600'
                              : ' naxatw-text-matt-100'
                          }
                            naxatw-relative naxatw-w-full naxatw-p-3 naxatw-text-base naxatw-font-medium naxatw-tracking-[-0.5px] naxatw-duration-200
                        `}
                        to={navbarItem.link}
                      >
                        {navbarItem.name}
                        {isNewUpdateFetched &&
                          navbarItem.link?.startsWith('/updates') && (
                            <div
                              className={`${circleIndicatorStyle} naxatw-left-[4.6rem]`}
                            />
                          )}
                      </NavLink>
                    ) : (
                      <NestedNavLink
                        navbarItem={navbarItem}
                        setDropdownOpen={setDropdownOpen}
                      />
                    )}
                  </div>
                );
              }
              return null;
            })}
          </FlexColumn>
        </FlexColumn>
      </animated.div>
      {/* </div> */}
    </>
  );
};

export default Navbar;
