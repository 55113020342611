import { cn } from '@Utils/index';

interface IExportCloseProps {
  name?: string;
  className?: string;
  iconStyles?: string;
  size?: string;
  isStyleAbsolute?: boolean;
  onHandleClose?: () => void;
  btnStyles?: string;
}

function getFontSize(size: string = 'default') {
  switch (size) {
    case 'sm':
      return '!naxatw-text-[0.75rem]';
    case 'md':
      return '!naxatw-text-[0.875rem]';
    case 'lg':
      return '!naxatw-text-[1rem]';
    default:
      return '!naxatw-text-[1.25rem]';
  }
}

function getTooltipByName(name: string) {
  switch (name) {
    case 'restart_alt':
      return 'reset';
    default:
      return name;
  }
}

const ExportBtn = ({
  name = 'close',
  className,
  iconStyles,
  size = 'default',
  isStyleAbsolute = true,
  onHandleClose,
  btnStyles,
}: IExportCloseProps) => {
  return (
    <button
      type="button"
      onClick={onHandleClose}
      className={cn(
        `naxatw-group naxatw-flex naxatw-cursor-pointer naxatw-items-center naxatw-justify-center naxatw-rounded-[50%] naxatw-p-1 naxatw-transition-all naxatw-duration-150 naxatw-ease-in hover:naxatw-bg-primary-200 ${isStyleAbsolute ? `naxatw-absolute naxatw-right-[5px] naxatw-top-[5px] ${btnStyles}` : ''} naxatw-pointer-events-none naxatw-opacity-0 group-hover:naxatw-pointer-events-auto group-hover:naxatw-opacity-100 `,
        className,
      )}
      title={getTooltipByName(name)}
    >
      <span
        className={cn(
          `material-icons group-hover:naxatw-text-secondary-500 ${getFontSize(size)} `,
          iconStyles,
        )}
      >
        {name}
      </span>
    </button>
  );
};

export default ExportBtn;
