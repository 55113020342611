import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from '@Store/hooks';
import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportBtn from '@Components/common/CommonExport/ExportHeader/ExportBtn';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import {
  getWorkingAreaByProject,
  getWorkingAreasByProgram,
} from '@Services/provincialProfile';
import {
  deleteIndividualList,
  deletePdfSection,
  resetPdfSection,
  setOriginalDataLists,
} from '@Store/actions/pdfExport';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import isEmpty from '@Utils/isEmpty';

const WorkingAreaItem = ({ workArea }: { workArea: Record<string, any> }) => {
  return (
    <div
      className="work-area-item naxatw naxatw-whitespace-nowrap naxatw-rounded-3xl naxatw-border naxatw-border-r naxatw-border-pdf-border naxatw-border-r-grey-400 naxatw-px-3 naxatw-py-2"
      key={workArea?.province}
    >
      <p className="fs-sm-medium naxatw-whitespace-nowrap">
        {workArea?.province}
        {workArea?.district ? ` / ${workArea.district}` : ''}
        {workArea?.municiplaity ? ` / ${workArea.municiplaity}` : ''}
      </p>
    </div>
  );
};

const PdfWorkingAreas = () => {
  const { programmeId, projectId } = useParams();
  const dispatch = useDispatch();

  const pageType = projectId ? 'projectDetails' : 'programmeDetails';

  const showResetButton = useTypedSelector(state =>
    projectId
      ? state.pdfExportSlice.projectDetails.workingAreas.showResetButton
      : state.pdfExportSlice.programmeDetails.workingAreas.showResetButton,
  );

  const datalists: readonly string[] = useTypedSelector(
    state => state.pdfExportSlice.projectDetails.workingAreas.datalists,
  );

  const { data: areasData } = useQuery({
    queryKey: ['working-areas-project-pdf', projectId],
    queryFn: async () =>
      projectId
        ? getWorkingAreaByProject({ project: projectId })
        : getWorkingAreasByProgram({ program: programmeId }),
    enabled: projectId ? !!projectId : !!programmeId,
    select: res => {
      if (!res?.data) return [];
      const workingAreas = res.data?.[0]?.data;

      if (projectId) {
        const finalWorkingAreas = workingAreas.map(
          (area: Record<string, any>) => ({
            id: area.status,
            workingAreas: area.working_areas,
          }),
        );

        return finalWorkingAreas;
      }
      return workingAreas;
    },
    onSuccess: response => {
      if (!response) return;

      dispatch(
        setOriginalDataLists({
          page: pageType,
          section: 'workingAreas',
          value: response,
        }),
      );
    },
  });

  if (areasData && isEmpty(areasData)) return null;

  return (
    <div
      className="pdf-working-area-container"
      style={{
        pageBreakInside: 'avoid',
      }}
    >
      <ExportHeader
        headerTitle="Working areas"
        isStyleAbsolute={false}
        showResetButton={showResetButton}
        onHandleDelete={() => {
          dispatch(
            deletePdfSection({
              page: pageType,
              section: 'workingAreas',
            }),
          );
        }}
        onHandleReset={() => {
          dispatch(
            resetPdfSection({
              page: pageType,
              section: 'workingAreas',
            }),
          );
        }}
      />
      <ExportRoundedContainer className="pdf-areas-container naxatw-p-3">
        {projectId ? (
          <div className="areas-container ">
            {areasData?.map((area: Record<string, any>) => {
              if (!datalists.includes(area.id)) return null;

              return (
                <div
                  className="area-item-container naxatw-group naxatw-relative naxatw-mb-3 last:naxatw-mb-0"
                  key={area.id}
                >
                  <p className="fs-sm-medium naxatw-mb-2 naxatw-uppercase">
                    {area.id}
                  </p>
                  <div className="working-areas-container naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-2">
                    {area?.workingAreas?.map(
                      (workArea: Record<string, any>) => (
                        <WorkingAreaItem
                          workArea={workArea}
                          key={workArea.district}
                        />
                      ),
                    )}
                  </div>
                  <ExportBtn
                    size="sm"
                    btnStyles="!naxatw-top-0 !naxatw-right-0"
                    onHandleClose={() =>
                      dispatch(
                        deleteIndividualList({
                          page: pageType,
                          section: 'workingAreas',
                          key: 'datalists',
                          value: area.id,
                        }),
                      )
                    }
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="areas-container naxatw-flex naxatw-flex-wrap naxatw-gap-2">
            {areasData?.map((workArea: Record<string, any>) => (
              <WorkingAreaItem workArea={workArea} key={workArea.district} />
            ))}
          </div>
        )}
      </ExportRoundedContainer>
    </div>
  );
};

export default hasErrorBoundary(PdfWorkingAreas);
