/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useRef, useState } from 'react';
import Icon from '@Components/common/Icon';

interface ISelectProps {
  className?: string;
  options: Record<string, any>[];
  selectedOption?: string;
  placeholder?: string;
  onChange?: (selectedOption: string) => any;
  labelKey?: string;
  valueKey?: string;
  isPdfExport?: boolean;
}

export default function Select({
  className,
  options,
  selectedOption,
  onChange,
  placeholder = 'Select',
  labelKey = 'label',
  valueKey = 'value',
  isPdfExport,
}: ISelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(selectedOption);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleOptionClick = (value: string) => {
    setSelected(value);
    // @ts-ignore
    onChange(value);
  };

  const getPlaceholderText = () => {
    if (selected)
      // @ts-ignore
      return options.find(item => item[valueKey] === selected)?.[labelKey];
    return placeholder;
  };

  return (
    <div className="naxatw-relative ">
      <div
        ref={dropdownRef}
        className={`${className} group naxatw-flex naxatw-w-full ${isPdfExport ? '' : ' naxatw-pointer-events-auto  naxatw-h-11'} naxatw-cursor-pointer  naxatw-items-center
         naxatw-justify-between naxatw-border-b-2 naxatw-border-grey-300 naxatw-bg-white hover:naxatw-border-b-primary-400`}
        onClick={toggleDropdown}
      >
        <span
          className={`${
            !selected && 'naxatw-text-grey-400'
          }  naxatw-flex-1  ${isPdfExport ? 'fs-xs-medium naxatw-mb-1 !naxatw-text-black' : 'group-hover:naxatw-bg-grey-700 naxatw-px-1 naxatw-pl-3 naxatw-text-[0.7rem] naxatw-font-bold naxatw-text-grey-800'}`}
        >
          {getPlaceholderText()}
        </span>

        <span
          className={`${isPdfExport ? 'naxatw-mb-1' : 'naxatw-pr-2'} no-print-area naxatw-text-grey-800 group-hover:naxatw-bg-red-700 `}
        >
          <Icon
            name={isOpen ? 'expand_less' : 'expand_more'}
            className={`${isPdfExport ? '!naxatw-text-lg' : ''} group-hover:naxatw-text-primary-700 `}
          />
        </span>
      </div>

      {isOpen && (
        <ul
          className={`no-print-area scrollbar naxatw-absolute  naxatw-z-40 naxatw-flex
         naxatw-max-h-[150px] naxatw-w-full naxatw-flex-col naxatw-overflow-auto naxatw-border naxatw-bg-white naxatw-shadow-lg ${isPdfExport ? 'naxatw-top-[24px]' : 'naxatw-top-[44px]'}`}
        >
          {options?.length ? (
            // @ts-ignore
            options.map(option => (
              <li
                className={`hover:naxatw-bg-primary-50 naxatw-flex naxatw-cursor-pointer naxatw-list-none naxatw-items-start
                hover:naxatw-bg-grey-200 ${isPdfExport ? '' : 'naxatw-px-4 naxatw-py-2.5 naxatw-text-xs  naxatw-text-grey-800'}`}
                key={option[valueKey]}
                onClick={() => handleOptionClick(option[valueKey])}
              >
                <div
                  className={`${isPdfExport ? 'fs-xs-medium naxatw-p-2 naxatw-leading-normal' : ''}`}
                >
                  {option[labelKey]}
                </div>
              </li>
            ))
          ) : (
            <li className="naxatw-cursor-default naxatw-px-2 naxatw-text-sm naxatw-text-grey-800">
              No data
            </li>
          )}
        </ul>
      )}
    </div>
  );
}
