/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useDispatch } from 'react-redux';
import { FlexRow } from '@Components/common/Layouts';
import { viewByOptions } from '@Constants/mainDashboard';
import { setMapActiveViewBy } from '@Store/actions/mainDashboard';
import SwitchTab from '@Components/common/FormUI/SwitchTab';
import { setSelectedFeature } from '@Store/actions/provincialProfile';
import { useEffect } from 'react';

const MapHeader = ({ isPdfExport = false }: { isPdfExport?: boolean }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setMapActiveViewBy('province'));
    };
  }, [dispatch]);

  return (
    <FlexRow className="actions map-header naxatw-absolute naxatw-left-[5.25rem] naxatw-top-[1.31rem] naxatw-z-10 naxatw-w-fit naxatw-flex-wrap naxatw-items-center naxatw-justify-between naxatw-gap-3  ">
      {/* --------------- view by ---------------  */}
      <SwitchTab
        title="view by"
        options={viewByOptions}
        defaultBg
        activeLabel="province"
        onChange={label => {
          dispatch(setMapActiveViewBy(label));
          dispatch(setSelectedFeature(null));
        }}
        wrapperClassName="naxatw-rounded-lg naxatw-bg-[#F4F7FE] naxatw-pl-[0.5rem]"
        activeClassName="!naxatw-bg-[#833177] !naxatw-text-[#F4F7FE] !naxatw-leading-[1.25rem] !naxatw-font-medium"
        isPdfExport={isPdfExport}
      />
    </FlexRow>
  );
};

export default MapHeader;
