import { useSpring, animated } from '@react-spring/web';
import { useSearchParams } from 'react-router-dom';
import { FlexColumn } from '@Components/common/Layouts';
import ProgrammeDetailsHeader from './Header';
import StatusBar from './StatusBar';
import ComponentsTable from './ComponentsTable';
import CampaignGoals from './CampaignGoals';
import SectorsComponent from './Sectors';
import MapSection from './MapSection';
import Metrices from './Metrices';
import SegmentCard from './Cards/Segment';
import Markers from './Markers';
import ProjectBudgetChart from './Charts/ProjectBudget';
import WorkingAreasCount from './WorkingAreas/WorkingAreasCount';
import WorkingAreasList from './WorkingAreas/WorkingAreasList';
import ProjectStatus from './Charts/ProjectStatus';
import AgreementModality from './Charts/AgreementModality';
import StakeholderDetails from './Stakeholder';
import ProgrammeProjectDetailCard from './ProjectDetailsCard';
import StakeholderChart from './Charts/StakeholderChart';

const ProgrammeDetails = () => {
  const [searchParams] = useSearchParams();

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });
  const componentId = searchParams.get('componentId');

  return (
    <>
      <animated.div style={{ ...springs }} className="programme-details-page">
        <ProgrammeDetailsHeader />
        <div className=" naxatw-relative naxatw-flex naxatw-w-full naxatw-flex-col naxatw-gap-5 ">
          <div className="naxatw-mb-8 naxatw-flex naxatw-flex-col naxatw-gap-5">
            <Metrices />

            <div className="naxatw-grid naxatw-grid-cols-1 naxatw-gap-5 lg:naxatw-grid-cols-12">
              <FlexColumn
                className={`naxatw-gap-5 ${componentId ? 'lg:naxatw-col-span-5' : 'lg:naxatw-col-span-4'}`}
              >
                <SegmentCard title="Timeline" iconName="timeline">
                  <StatusBar />
                </SegmentCard>
                {componentId && <ProgrammeProjectDetailCard />}
                {!componentId && <WorkingAreasCount />}
                {!componentId && <Markers showGroupMakersOnly />}
              </FlexColumn>

              <FlexColumn
                className={`naxatw-gap-5 ${componentId ? 'naxatw-grid naxatw-grid-cols-2 lg:naxatw-col-span-7' : 'lg:naxatw-col-span-8'}`}
              >
                {!componentId && <ProjectBudgetChart />}
                {componentId && (
                  <>
                    <FlexColumn gap={5}>
                      <Markers showGroupMakersOnly />
                      <WorkingAreasCount />
                    </FlexColumn>
                    <CampaignGoals />
                  </>
                )}
              </FlexColumn>
            </div>

            {!componentId && (
              <div className="naxatw-grid naxatw-grid-cols-1 naxatw-gap-3 lg:naxatw-grid-cols-12">
                <div className="naxatw-col-span-12 sm:naxatw-col-span-6 lg:naxatw-col-span-3">
                  <CampaignGoals />
                </div>
                <div className="naxatw-col-span-12 sm:naxatw-col-span-6 lg:naxatw-col-span-3">
                  <ProjectStatus />
                </div>
                <div className="naxatw-col-span-12 sm:naxatw-col-span-6 lg:naxatw-col-span-6">
                  <AgreementModality />
                </div>
              </div>
            )}
            <SectorsComponent />
            <Markers />
            {!componentId && <ComponentsTable />}
            <StakeholderChart />
            <StakeholderDetails />
            <div>
              <WorkingAreasList groupByStatus={!!componentId} />
              <div className="naxatw-h-[35rem] naxatw-overflow-hidden naxatw-rounded-xl naxatw-border naxatw-border-gray-200 naxatw-bg-white naxatw-shadow-sm">
                <MapSection lat={28.3949} lon={84.124} zoom={6} />
              </div>
            </div>
          </div>
        </div>
      </animated.div>
    </>
  );
};

export default ProgrammeDetails;
