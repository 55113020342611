import clsx from 'clsx';
import LegendItem from '../LegendItem';
import DonutLegendItem from '../DonutLegendItem';
import { ILegendProps } from '../types';
import { removeKeyFromObject } from '../utils';
import PieChartLegend from './PieChartLegend';
import PieChartLegendItem from './PieChartLegendItem';

export default function ChartLegend<T>({
  data,
  type = 'bar',
  fills = ['#418FDE', '#FF671F'],
  chartId,
  showPound,
  hasDetails,
  isNestedChart,
  isRoundedLegend,
  wrapperStyles,
  labelStyles,
  countStyles,
  wrapperInnerStyles,
  legendWrapperStyles,
  legendLabels,
}: ILegendProps<T>) {
  const keys: string[] =
    legendLabels ||
    Object.keys(removeKeyFromObject(data[0], 'name' as keyof (typeof data)[0]));

  if (type === 'donut') {
    return (
      <div className={clsx('legend naxatw-w-full  ', wrapperStyles)}>
        <div className="legend-inner naxatw-flex naxatw-w-full naxatw-justify-center">
          <div
            className={clsx(
              `scrollbar naxatw-flex-1 naxatw-px-[4rem]  ${isRoundedLegend ? 'naxatw-gap-3' : 'naxatw-gap-4'} naxatw-flex naxatw-flex-col naxatw-justify-center `,
              wrapperInnerStyles,
            )}
          >
            {data.map((key: any, index: number) => (
              <DonutLegendItem
                key={key.name}
                id={key.id}
                color={fills[index] || '#0088F8'}
                name={key.name}
                value={key.value}
                percentage={key.percentage}
                showPound={showPound}
                isNestedChart={isNestedChart}
                isRoundedLegend={isRoundedLegend}
                labelStyles={labelStyles}
                countStyles={countStyles}
                legendWrapperStyles={legendWrapperStyles}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (type === 'pieChart') {
    return (
      <div className="legend-pie-chart-wrapper naxatw-col-span-12 naxatw-hidden  naxatw-w-full naxatw-justify-self-center md:naxatw-block">
        <div className="legend-pie-chart-container naxatw-h-full">
          {hasDetails ? (
            <PieChartLegend
              data={data}
              chartId={chartId}
              showPound={showPound}
              fill={fills}
            />
          ) : (
            <div className="naxatw-grid naxatw-h-full naxatw-place-items-center">
              <div className="!naxatw-mb-8 lg:!naxatw-mb-0">
                {data?.map((key: any, idx: number) => (
                  <PieChartLegendItem
                    key={key.name}
                    data={key}
                    chartId={chartId}
                    showPound={showPound}
                    fill={fills[idx]}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (type === 'bar') {
    return (
      <div className="naxatw-flex naxatw-flex-wrap naxatw-gap-4">
        {keys.map((key, index) => (
          <LegendItem key={key} color={fills[index] || '#0088F8'} name={key} />
        ))}
      </div>
    );
  }

  return (
    <div className="legend naxatw-col-span-11 naxatw-col-start-1 naxatw-col-end-13">
      <div className="naxatw-flex naxatw-w-full naxatw-justify-center ">
        <div className="cover naxatw-flex naxatw-h-full naxatw-max-h-[4rem] naxatw-gap-4 naxatw-overflow-y-auto">
          {keys.map((key, index) => (
            <LegendItem
              key={key}
              color={fills[index] || '#0088F8'}
              name={key}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
