import Metric from '@Components/common/Card/Metric';
import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportBtn from '@Components/common/CommonExport/ExportHeader/ExportBtn';
import { motion } from 'framer-motion';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import {
  deleteIndividualList,
  deletePdfSection,
  resetPdfSection,
} from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useDispatch } from 'react-redux';

const ReportSummary = ({
  municipalityCount,
}: {
  municipalityCount: Record<string, any>;
}) => {
  const dispatch = useDispatch();

  const showPalikaImage = useTypedSelector(
    state => state.pdfExportSlice.palikaDetails.palikaImage.showSection,
  );

  const showResetButton = useTypedSelector(
    state => state.pdfExportSlice.palikaDetails.summary.showResetButton,
  );

  const cardsData = useTypedSelector(
    state => state.pdfExportSlice.palikaDetails.summary.datalists,
  );

  const {
    first_tier_partner_count: partnerCount,
    sector_count: sectorCount,
    program_count: programmeCount,
    project_count: projectCount,
    marker_count: markerCount,
  } = municipalityCount || {};

  const isFirstRowVisible =
    cardsData.includes('programme') || cardsData.includes('project');

  return (
    <ExportRoundedContainer
      className={`pdf-details-summary  ${showPalikaImage ? 'naxatw-basis-[65%]' : 'naxatw-basis-full'}`}
    >
      <motion.div
        layout
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <ExportHeader
          headerTitle="Portfolio Summary"
          className="!naxatw-p-2"
          showResetButton={showResetButton}
          isStyleAbsolute={false}
          onHandleReset={() => {
            dispatch(
              resetPdfSection({
                page: 'palikaDetails',
                section: 'summary',
              }),
            );
          }}
          onHandleDelete={() => {
            dispatch(
              deletePdfSection({
                page: 'palikaDetails',
                section: 'summary',
              }),
            );
          }}
        />
        <div className="summary-content   naxatw-gap-2 naxatw-bg-primary-200 naxatw-px-3 naxatw-py-4">
          <div className="naxatw-flex naxatw-gap-2">
            {cardsData.includes('programme') ? (
              <motion.div
                className="naxatw-group naxatw-relative naxatw-flex-1"
                layout
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Metric
                  label="Programme"
                  iconName="list_alt"
                  metricCount={programmeCount}
                  className="!naxatw-p-2"
                  isExport
                />
                <ExportBtn
                  size="sm"
                  onHandleClose={() =>
                    dispatch(
                      deleteIndividualList({
                        page: 'palikaDetails',
                        section: 'summary',
                        key: 'datalists',
                        value: 'programme',
                      }),
                    )
                  }
                />
              </motion.div>
            ) : null}
            {cardsData.includes('project') ? (
              <motion.div
                className="naxatw-group naxatw-relative naxatw-flex-1"
                layout
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Metric
                  label="Project"
                  iconName="folder_open"
                  metricCount={projectCount}
                  className="!naxatw-p-2"
                  isExport
                />
                <ExportBtn
                  size="sm"
                  onHandleClose={() =>
                    dispatch(
                      deleteIndividualList({
                        page: 'palikaDetails',
                        section: 'summary',
                        key: 'datalists',
                        value: 'project',
                      }),
                    )
                  }
                />
              </motion.div>
            ) : null}
          </div>

          <div
            className={` naxatw-flex naxatw-gap-2 ${isFirstRowVisible ? 'naxatw-mt-2' : ''}`}
          >
            {cardsData.includes('partner') ? (
              <motion.div
                className="naxatw-group naxatw-relative naxatw-flex-1"
                layout
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Metric
                  label="First Tier Partner"
                  iconName="handshake"
                  metricCount={partnerCount}
                  className="!naxatw-p-2"
                  isExport
                />
                <ExportBtn
                  size="sm"
                  onHandleClose={() =>
                    dispatch(
                      deleteIndividualList({
                        page: 'palikaDetails',
                        section: 'summary',
                        key: 'datalists',
                        value: 'partner',
                      }),
                    )
                  }
                />
              </motion.div>
            ) : null}

            {cardsData.includes('markers') ? (
              <motion.div
                className="naxatw-group naxatw-relative naxatw-flex-1"
                layout
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Metric
                  label="Markers"
                  iconName="handshake"
                  metricCount={markerCount}
                  className="!naxatw-p-2"
                  isExport
                />
                <ExportBtn
                  size="sm"
                  onHandleClose={() =>
                    dispatch(
                      deleteIndividualList({
                        page: 'palikaDetails',
                        section: 'summary',
                        key: 'datalists',
                        value: 'markers',
                      }),
                    )
                  }
                />
              </motion.div>
            ) : null}

            {cardsData.includes('sectors') ? (
              <motion.div
                className="naxatw-group naxatw-relative naxatw-flex-1"
                layout
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Metric
                  label="Sectors"
                  iconName="handshake"
                  metricCount={sectorCount}
                  className="!naxatw-p-2"
                  isExport
                />
                <ExportBtn
                  size="sm"
                  onHandleClose={() =>
                    dispatch(
                      deleteIndividualList({
                        page: 'palikaDetails',
                        section: 'summary',
                        key: 'datalists',
                        value: 'sectors',
                      }),
                    )
                  }
                />
              </motion.div>
            ) : null}
          </div>
        </div>
      </motion.div>
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(ReportSummary);
