/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Icon from '@Components/common/Icon';
import { Button } from '@Components/RadixComponents/Button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@Components/RadixComponents/Popover';
import { cn, monthNames, getMonthId } from '@Utils/index';
import { CalendarIcon } from 'lucide-react';

interface IMonthYearDatePickerProps {
  date: string;
  onChange?: (date: string) => void;
  placeHolder?: string;
}

const MonthYearDatePicker = ({
  date,
  onChange,
  placeHolder = 'Select Month and Year',
}: IMonthYearDatePickerProps) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = monthNames[currentDate.getMonth()];
  const [isOpen, setIsOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState<any>(null);
  const [selectedMonth, setSelectedMonth] = useState<any>(null);
  const [view, setView] = useState<'months' | 'years'>('years');

  const initialDate =
    date ||
    `${currentYear}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;

  useEffect(() => {
    if (date) {
      const [year, month] = date.split('-');
      setSelectedYear(year);
      setSelectedMonth(monthNames[parseInt(month, 10) - 1]);
    }
  }, [date]);

  // set the value in on change
  useEffect(() => {
    if (!selectedMonth || !selectedYear) return;

    const formattedDateString = `${selectedYear}-${String(getMonthId[selectedMonth]).padStart(2, '0')}`;

    // Only call onChange if the selected date is different from the initial date
    // if (formattedDateString !== initialDate) {
    if (onChange) {
      onChange(formattedDateString);
      // }
    }
  }, [selectedMonth, selectedYear, onChange, initialDate]);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild onClick={() => setView('years')}>
        <Button
          variant="dropDown"
          size="drop-lg"
          className={cn(
            'naxatw-group naxatw-flex naxatw-w-full naxatw-items-center  naxatw-justify-between naxatw-gap-2  naxatw-duration-200 hover:naxatw-border-secondary-500',
          )}
        >
          <div className="naxatw-flex naxatw-items-center">
            <CalendarIcon className="naxatw-mr-2 naxatw-h-4 naxatw-w-4 naxatw-duration-200 group-hover:naxatw-text-secondary-500" />
            <span className="!naxatw-font-normal naxatw-text-matt-200">
              {selectedMonth && selectedYear
                ? `${selectedMonth}, ${selectedYear}`
                : placeHolder}
            </span>
          </div>
          <Icon
            name="expand_more"
            className={`${isOpen ? 'naxatw-rotate-180' : ''} naxatw-flex naxatw-h-6 naxatw-w-6 naxatw-text-matt-200 naxatw-transition-transform naxatw-duration-200  naxatw-ease-in group-hover:naxatw-text-secondary-500`}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        style={{ width: 'var(--radix-popover-trigger-width)' }}
        className="naxatw-bg-white"
      >
        <div className="naxatw-flex naxatw-cursor-pointer naxatw-items-center naxatw-justify-between naxatw-pb-2">
          <div
            onClick={() => setView(view === 'years' ? 'months' : 'years')}
            className="naxatw-text-lg naxatw-font-semibold"
          >
            <div className="naxatw-flex naxatw-items-center naxatw-gap-1">
              <p className="naxatw-label naxatw-text-matt-100">
                {selectedMonth || monthNames[currentDate.getMonth()]}
              </p>
              <p className="naxatw-label naxatw-text-matt-100">
                {selectedYear || currentDate.getFullYear()}
              </p>
              <div className="naxatw-grid naxatw-place-items-center hover:naxatw-rounded-[50%] hover:naxatw-bg-primary-200">
                <Icon
                  type="material-icons"
                  name="arrow_drop_down"
                  className={`${isOpen ? '' : 'naxatw-rotate-180'} naxatw-flex  naxatw-p-1 !naxatw-text-xl naxatw-text-matt-200 naxatw-transition-colors  naxatw-duration-200 naxatw-ease-in `}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="scrollbar naxatw-h-[172px] naxatw-overflow-y-auto">
          {view === 'months' ? (
            <div className="naxatw-grid naxatw-grid-cols-3 naxatw-gap-2 naxatw-pr-1">
              {Array.from({ length: 12 }, (_, i) => {
                const month = monthNames[i];
                return (
                  <div
                    key={i}
                    className={` ${month === currentMonth && !selectedMonth ? 'naxatw-bg-primary-200' : ''} ${month === selectedMonth ? ' naxatw-bg-secondary-500 naxatw-text-white hover:naxatw-bg-secondary-500' : ' hover:naxatw-bg-primary-200'}  naxatw-cursor-pointer naxatw-rounded-3xl naxatw-px-1 naxatw-py-2  naxatw-text-center naxatw-text-[14px] naxatw-text-matt-100 naxatw-transition-colors naxatw-duration-200 naxatw-ease-in `}
                    onClick={() => {
                      setSelectedMonth(month);
                      if (selectedYear === null) setView('years');
                      if (selectedYear) setIsOpen(false);
                    }}
                  >
                    {month}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="naxatw-grid naxatw-grid-cols-4 naxatw-gap-2 naxatw-pr-1">
              {/* {Array.from({ length: 21 }, (_, i) => 2020 + i).map(year => (
                <div
                  key={year}
                  className={`${year === currentYear && !selectedYear ? 'naxatw-bg-primary-200' : ''} ${Number(year) === Number(selectedYear) ? 'naxatw-bg-secondary-500 naxatw-text-white hover:naxatw-bg-secondary-500' : ' hover:naxatw-bg-primary-200'} naxatw-cursor-pointer naxatw-rounded-3xl naxatw-p-1 naxatw-px-1 naxatw-py-2 naxatw-text-center naxatw-text-[14px] naxatw-transition-colors naxatw-duration-200 naxatw-ease-in `}
                  onClick={() => {
                    setSelectedYear(year);
                    setView('months');
                  }}
                >
                  {year}
                </div>
              ))} */}
              {Array.from(
                { length: currentYear - 2020 + 1 },
                (_, i) => 2020 + i,
              ).map(year => (
                <div
                  key={year}
                  className={`${year === currentYear && !selectedYear ? 'naxatw-bg-primary-200' : ''} 
                ${Number(year) === Number(selectedYear) ? 'naxatw-bg-secondary-500 naxatw-text-white hover:naxatw-bg-secondary-500' : 'hover:naxatw-bg-primary-200'} 
                naxatw-cursor-pointer naxatw-rounded-3xl naxatw-p-1 naxatw-px-1 naxatw-py-2 naxatw-text-center naxatw-text-[14px] naxatw-transition-colors naxatw-duration-200 naxatw-ease-in`}
                  onClick={() => {
                    setSelectedYear(year);
                    setView('months');
                  }}
                >
                  {year}
                </div>
              ))}
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default hasErrorBoundary(MonthYearDatePicker);
