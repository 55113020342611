import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { cn } from '@Utils/index';

interface ExportRoundedContainerProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const ExportRoundedContainer = ({
  children,
  className,
  style,
}: ExportRoundedContainerProps) => {
  return (
    <div
      className={cn(
        'pdf-rounded-container naxatw-overflow-hidden naxatw-rounded-lg naxatw-border naxatw-border-pdf-border',
        className,
      )}
      style={style}
    >
      {children}
    </div>
  );
};

export default hasErrorBoundary(ExportRoundedContainer);
