function ClampText({ text }: { text: string }) {
  return <div className="naxatw-line-clamp-2">{text || '-'}</div>;
}

// eslint-disable-next-line
export const contactsTableColumns = [
  {
    header: 'Name',
    accessorKey: 'name',
    cell({ row }: Record<string, any>) {
      return <ClampText text={row.original?.name} />;
    },
  },
  {
    header: 'OFFICE',
    accessorKey: 'office',
    cell({ row }: Record<string, any>) {
      return <ClampText text={row.original?.office} />;
    },
  },
  {
    header: 'DESIGNATION',
    accessorKey: 'designation',
    cell({ row }: Record<string, any>) {
      return <ClampText text={row.original?.designation} />;
    },
  },
  {
    header: 'CONTACT',
    accessorKey: 'phone',
    cell({ row }: Record<string, any>) {
      return <ClampText text={row.original?.phone} />;
    },
  },
  {
    header: 'EMAIL',
    accessorKey: 'email',
    cell({ row }: Record<string, any>) {
      return <ClampText text={row.original?.email} />;
    },
  },
  {
    header: 'REMARKS',
    accessorKey: 'remarks',
    cell({ row }: Record<string, any>) {
      return <ClampText text={row.original?.remarks} />;
    },
  },
];
