import CustomDonutChart from '@Components/common/Charts/DonutChart';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Skeleton from '@Components/RadixComponents/Skeleton';
import colors from '@Constants/stackedBarChartData';
import { getStakeholderChartDataByProvince } from '@Services/provincialProfile';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';

function StakeholderChart({ isExport = false }: { isExport?: boolean }) {
  const { provinceId } = useParams();
  const [searchParams] = useSearchParams();
  const municipalityCode = searchParams.get('municipality');

  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);

  const { data, isLoading } = useQuery<any, any, Array<any>, any>({
    queryKey: [
      'provincial-details',
      'stakeholder-chart-data',
      provinceId,
      municipalityCode,
      filterBy,
    ],
    queryFn: params =>
      getStakeholderChartDataByProvince(provinceId as string, params),
    params: {
      municipality: municipalityCode,
      status: filterBy,
    },
    select: res =>
      res.data?.[0]?.stakeholders?.map((stakeholder: Record<string, any>) => ({
        value: stakeholder?.count,
        name: stakeholder?.type,
      })) || [],
  });
  if (isLoading)
    return (
      <FlexRow className="naxatw-grid naxatw-h-full naxatw-min-h-[450px] naxatw-w-full naxatw-grid-cols-12 naxatw-items-end naxatw-justify-between">
        <FlexRow className="naxatw-col-span-7 naxatw-items-center naxatw-justify-end">
          <Skeleton className="naxatw-h-[24rem] naxatw-w-[24rem] naxatw-rounded-full" />
        </FlexRow>
        <FlexColumn className="naxatw-col-span-5 naxatw-justify-center naxatw-gap-5">
          <Skeleton className="naxatw-h-3 naxatw-w-24" />
          <Skeleton className="naxatw-h-3 naxatw-w-24" />
        </FlexColumn>
      </FlexRow>
    );

  return (
    <div
      className={`naxatw-grid ${isExport ? 'naxatw-grid-cols-[1.2fr_1fr] naxatw-items-center' : 'naxatw-grid-cols-12 naxatw-gap-y-8'} naxatw-w-full`}
    >
      {!isLoading && !data ? (
        <NoChartDataComponent />
      ) : (
        <>
          <div
            className={
              isExport
                ? 'naxatw-my-3'
                : 'naxatw-col-span-12 naxatw-my-2 naxatw-place-content-end naxatw-justify-end lg:naxatw-col-span-7'
            }
          >
            <CustomDonutChart
              data={data}
              minHeight={isExport ? 250 : 420}
              maxHeight={isExport ? 250 : 420}
              fills={colors}
              innerRadius={isExport ? 60 : 80}
            />
          </div>

          <div
            className={`${isExport ? '' : 'naxatw-col-span-12 naxatw-flex naxatw-items-center naxatw-justify-center lg:naxatw-col-span-4 lg:naxatw-justify-normal'}`}
          >
            <FlexColumn className="naxatw-w-full naxatw-max-w-[18rem] [&>*:not(:last-child)]:naxatw-border-b">
              {data?.map((record, idx) => (
                <div
                  className="naxatw-flex naxatw-w-full  naxatw-flex-1 naxatw-items-center naxatw-justify-between naxatw-gap-2 naxatw-py-2 "
                  key={record?.name}
                >
                  <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
                    <div
                      className="naxatw-h-3 naxatw-w-3 naxatw-rounded-[0.25rem]"
                      style={{
                        backgroundColor: colors[idx],
                      }}
                    />
                    <span
                      className={` naxatw-mr-6  ${isExport ? 'fs-xs-medium' : 'naxatw-body-sm naxatw-text-grey-800'}`}
                    >
                      {record?.name}
                    </span>
                  </div>
                  <span
                    className={` ${isExport ? 'fs-sm-bold' : 'naxatw-body-btn  naxatw-text-grey-800 md:naxatw-mb-0'}`}
                  >
                    {record?.value
                      ? Number(record?.value).toLocaleString()
                      : '-'}
                  </span>
                </div>
              ))}
            </FlexColumn>
          </div>
        </>
      )}
    </div>
  );
}

export default hasErrorBoundary(StakeholderChart);
