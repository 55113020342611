import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { deleteIndividualList } from '@Store/actions/pdfExport';
import { useDispatch } from 'react-redux';
import ExportBtn from '../../ExportHeader/ExportBtn';

interface ITableListsProps {
  exportTitles?: Record<string, any>;
  isExport?: boolean;
  dataLists: Record<string, any>;
  exportLists?: Record<string, any>;
}

const TableListTwo = ({
  dataLists,
  isExport = false,
  exportTitles,
  exportLists,
}: ITableListsProps) => {
  const dispatch = useDispatch();

  return (
    <>
      {dataLists.map((list: Record<string, any>) => {
        if (isExport) {
          const isDataIncludes =
            Array.isArray(exportLists) && exportLists.includes(list.id);

          if (!isDataIncludes) return null;
        }

        return (
          <div
            key={list.id}
            className="naxatw-group  naxatw-relative naxatw-mx-2 naxatw-py-2 even:naxatw-bg-primary-200"
            style={{
              pageBreakInside: 'avoid',
            }}
          >
            <div className="naxatw-grid naxatw-grid-cols-3 naxatw-gap-6">
              <p className="fs-sm-medium">{list.listOne}</p>
              <p className="fs-sm-medium">{list.listTwo}</p>
              <p className="fs-sm-medium">{list.listThree}</p>
            </div>

            {isExport ? (
              <ExportBtn
                size="sm"
                className="naxatw-right-0 naxatw-top-[8px]"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: exportTitles?.page,
                      section: exportTitles?.section,
                      key: 'datalists',
                      value: list.id,
                    }),
                  )
                }
              />
            ) : null}
          </div>
        );
      })}
    </>
  );
};

export default hasErrorBoundary(TableListTwo);
