import { ComponentPropsWithoutRef } from 'react';

type Props = ComponentPropsWithoutRef<'div'> & {
  isExport?: boolean;
};
export default function DefaultCard({
  className,
  children,
  isExport = false,
  ...restProps
}: Props) {
  return (
    <article
      className={`naxatw-rounded-lg naxatw-bg-white ${isExport ? 'naxatw-p-3' : 'naxatw-p-4'}  ${className}`}
      {...restProps}
    >
      {children}
    </article>
  );
}
