/* eslint-disable no-param-reassign */
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UpdateState {
  filterTerms: Record<string, any>;
  isNewUpdateFetched: boolean;
}

const initialState: UpdateState = {
  filterTerms: {
    updateTypeFilterTerm: '',
    fedTypeFilterTerm: 'All',
    provinceFilterTerm: '',
    start_date: '',
    end_date: '',
  },
  isNewUpdateFetched: false,
};

const setUpdateState: CaseReducer<
  UpdateState,
  PayloadAction<Partial<UpdateState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const setUpdateFilterState: CaseReducer<
  UpdateState,
  PayloadAction<Record<string, any>>
> = (state, action) => {
  return {
    ...state,
    filterTerms: {
      ...state.filterTerms,
      [action.payload.name]: action.payload.value,
    },
  };
};

const clearFilterState: CaseReducer<UpdateState> = state => {
  return {
    ...state,
    filterTerms: {
      updateTypeFilterTerm: '',
      provinceFilterTerm: '',
      start_date: '',
      end_date: '',
    },
  };
};

const UpdateSlice = createSlice({
  name: 'Update',
  initialState,
  reducers: {
    setUpdateState,
    setUpdateFilterState,
    clearFilterState,
  },
});

export { UpdateSlice };

export default UpdateSlice.reducer;
