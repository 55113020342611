import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTypedSelector } from '@Store/hooks';
import {
  downloadStakeholdersTableData,
  getStakeHoldersByProvinceId,
} from '@Services/provincialProfile';
import NestedDataTable from '@Components/ExploreByProgramme/NestedDataTable';
import SwitchTab from '@Components/common/FormUI/SwitchTab';
import ContentBox from '@Components/common/ContentBox';
import useSortingConfig from '@Hooks/useSortingConfig';
import type { ColumnSort } from '@tanstack/react-table';
import StakeholderChart from '../ChartSection/StakeholderChart';

const SwitchOptions = [
  { label: 'Table', value: 'table' },
  { label: 'Chart', value: 'chart' },
];

const columns = [
  {
    header: 'STAKEHOLDER TYPE',
    accessorKey: 'stakeholder_type',
  },
  {
    header: 'PROJECT',
    accessorKey: 'project_name',
    className: '!naxatw-w-[34.169rem]',
  },
  {
    header: 'STAKEHOLDER NAME',
    accessorKey: 'stakeholder_names',
  },
];

function StakeHoldersTable() {
  const { provinceId } = useParams();
  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);
  const [sorting, setSorting] = useState<ColumnSort>({ id: '', desc: false });
  const { sortBy, sortDir, sortOrderKey } = useSortingConfig(
    sorting.id,
    sorting.desc,
  );

  const { data, isLoading } = useQuery({
    queryKey: [
      'provincial-profile-stakholder',
      provinceId,
      sortBy,
      sortDir,
      filterBy,
    ],
    queryFn: () =>
      getStakeHoldersByProvinceId(provinceId as string, {
        status: filterBy,
        sort_by: sortBy,
        [sortOrderKey]: sortDir,
      }),
    select: res => res.data,
  });

  // download table
  const { refetch: handleDownload } = useQuery({
    queryKey: ['stakeholder-province-download', filterBy],
    queryFn: async () =>
      downloadStakeholdersTableData({
        province: provinceId,
        is_export: true,
        status: filterBy,
      }),
    enabled: false,
    onSuccess: response => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(response.data);
      a.download = `stakeholder-details.csv`;
      a.click();
    },
  });

  const [activeTab, setActiveTab] = useState('Table');

  return (
    <ContentBox
      title="Stakeholders Details"
      headingContent={
        <SwitchTab
          title="VIEW BY"
          activeValue={activeTab}
          activeLabel={activeTab}
          activeClassName="!naxatw-bg-[#833177] naxatw-text-white"
          options={SwitchOptions}
          onChange={label => {
            setActiveTab(label);
          }}
        />
      }
      headerClassName={
        activeTab === 'Chart' ? 'naxatw-border-b-transparent' : ''
      }
      onExport={activeTab === 'Table' ? handleDownload : undefined}
    >
      {activeTab === 'Table' ? (
        <div className="scrollbar naxatw-max-h-[496px] naxatw-overflow-auto">
          <NestedDataTable
            data={data}
            columns={columns}
            mainDataKey="stakeholder_type"
            nestingDataKey="projects"
            isLoading={isLoading}
            sortingState={sorting}
            onSorting={sort => setSorting(sort)}
            isProjectTable
          />
        </div>
      ) : (
        <div className="naxatw-flex naxatw-min-h-[50dvh] naxatw-flex-col naxatw-items-center naxatw-justify-center naxatw-p-6">
          <StakeholderChart />
        </div>
      )}
    </ContentBox>
  );
}

export default StakeHoldersTable;
