import { useQuery } from '@tanstack/react-query';
import { Controller, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import DropDown from '@Components/common/DropDown';
import { FormControl } from '@Components/common/FormUI';
import TiptapEditor from '@Components/common/FormUI/TipTapEditor';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn } from '@Components/common/Layouts';
import {
  getGeographicFocusOptions,
  getTeamMembership,
} from '@Services/knowledgeRepository';
import { prepareDataForDropdown } from '@Utils/index';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import GroupCheckBox from '@Components/common/FormUI/GroupCheckBox';
import { excludeAgendaFormName } from '@Constants/FormConstants/knowledgeRepoConstants';
import getCheckboxCheckedIds, {
  getCheckboxParsedValue,
} from '@Utils/getCheckedValues';

const DocumentDetails = () => {
  const { control } = useFormContext();

  //! ----------> team ownership dropdown
  const { data: teamOwnershipDropdown } = useQuery({
    queryKey: ['knowledgerepo-team-membership-options'],
    queryFn: () =>
      getTeamMembership({
        type: 'team_membership',
      }),
    onError: () => toast.error('Failed to fetch team membership options'),
    select: data => prepareDataForDropdown(data?.data || [], 'name'),
  });

  //! ----------> geographic focus dropdown
  const { data: agendasData } = useQuery({
    queryKey: ['geographic-options-dropdown'],
    queryFn: () => getGeographicFocusOptions(),
    select: response => {
      if (!response?.data) return null;
      const agendaCategories = response.data || [];

      const manipulatedAgendaCategories = agendaCategories.map(
        (cat: Record<string, any>) => {
          return {
            id: cat.id,
            name: cat.name,
            agendas: cat.options,
          };
        },
      );

      return manipulatedAgendaCategories;
    },
  });

  const geogrpahicFocusData = agendasData?.find(
    (cat: Record<string, any>) => cat.name === 'Geographic Focus',
  )?.agendas;

  const gesiData = agendasData?.filter(
    (cat: Record<string, any>) => cat.name === 'GESI',
  );

  const manipulatedAgendasData = agendasData?.filter(
    (cat: Record<string, any>) => !excludeAgendaFormName.includes(cat.name),
  );

  return (
    <FlexColumn className="dcocument-details naxatw-mb-5 naxatw-w-full">
      {/* ------------> Summary */}
      <FormControl className="naxatw-mt-5">
        <InputLabel label="Summary" className="naxatw-mb-1" />
        <Controller
          control={control}
          name="summary"
          render={({ field: { value, onChange } }) => {
            return <TiptapEditor bindvalue={value} onChange={onChange} />;
          }}
        />
      </FormControl>

      {/* ------------> Team Ownership */}

      <FormControl className="naxatw-mt-5">
        <InputLabel label="Team ownership" className="naxatw-mb-1" />
        <Controller
          control={control}
          name="team_membership"
          defaultValue=""
          render={({ field: { value, onChange } }) => (
            <DropDown
              placeholder="Team ownership"
              className="naxatw-w-full"
              options={teamOwnershipDropdown || []}
              value={value}
              choose="value"
              onChange={onChange}
            />
          )}
        />
      </FormControl>

      {/* ------------> Geographic  Focus  */}

      <FormControl className="naxatw-border-[##D0D5DD)] naxatw-mt-5 naxatw-rounded-lg naxatw-border naxatw-p-3">
        <InputLabel label="Geographic focus" className="naxatw-mb-2" />
        <Controller
          control={control}
          name="agenda_evidence_graphical_focus"
          defaultValue={{}}
          render={({ field }) => {
            const checkedItems = field.value;
            const myCheckedItems = geogrpahicFocusData?.reduce(
              (accumulator: any, focusData: any) => {
                if (checkedItems.includes(focusData.id)) {
                  return { ...accumulator, [`parent_${focusData.id}`]: true };
                }
                return { ...accumulator, [`parent_${focusData.id}`]: false };
              },
              {},
            );
            return (
              <GroupCheckBox
                checkedItems={myCheckedItems}
                onCheckboxChange={newCheckedItems => {
                  const selectedIds: number[] = [];
                  Object.entries(newCheckedItems).forEach(([key, value]) => {
                    if (key.startsWith('parent_') && value) {
                      selectedIds.push(Number(key.split('_')[1]));
                    }
                  });

                  field.onChange(selectedIds);
                }}
                groupData={geogrpahicFocusData || []}
                isParentOnly
              />
            );
          }}
        />
      </FormControl>

      {/* ------------> GESI  */}

      <p className="naxatw-mt-5 naxatw-text-base naxatw-font-medium naxatw-text-matt-100">
        Themes
      </p>

      <FormControl className="naxatw-mt-3 naxatw-w-full naxatw-gap-[0.5rem]">
        <Controller
          control={control}
          name="agenda_evidence_gesi"
          defaultValue={{}}
          render={({ field }) => {
            const checkboxValue = getCheckboxParsedValue(
              field.value,
              gesiData?.[0],
              'agendas',
            );

            return (
              <GroupCheckBox
                checkedItems={checkboxValue}
                onCheckboxChange={newCheckedItems => {
                  const { checkedChildIds } = getCheckboxCheckedIds(
                    // @ts-ignore
                    newCheckedItems || {},
                  );

                  field.onChange(checkedChildIds);
                }}
                groupData={gesiData || []}
                subGroupName="agendas"
                className="!naxatw-p-0"
                childrenWrapperClassName="naxatw-p-3 naxatw-mb-2 naxatw-grid md:naxatw-grid-cols-2 naxatw-grid-cols-1 naxatw-bg-primary-100 naxatw-rounded-lg naxatw-gap-1"
              />
            );
          }}
        />
      </FormControl>

      {/* ------------> Others  */}

      <FormControl className="naxatw-w-full">
        <Controller
          control={control}
          name="agenda_evidence"
          defaultValue={{}}
          render={({ field }) => {
            const checkedItems = field.value;
            const myCheckedItems = manipulatedAgendasData?.reduce(
              (accumulator: any, focusData: any) => {
                if (checkedItems.includes(focusData.id)) {
                  return { ...accumulator, [`parent_${focusData.id}`]: true };
                }
                return { ...accumulator, [`parent_${focusData.id}`]: false };
              },
              {},
            );

            return (
              <GroupCheckBox
                checkedItems={myCheckedItems}
                className="!naxatw-px-0"
                onCheckboxChange={newCheckedItems => {
                  const selectedIds: number[] = [];
                  Object.entries(newCheckedItems).forEach(([key, value]) => {
                    if (key.startsWith('parent_') && value) {
                      selectedIds.push(Number(key.split('_')[1]));
                    }
                  });
                  field.onChange(selectedIds);
                }}
                groupData={manipulatedAgendasData || []}
                isParentOnly
              />
            );
          }}
        />
      </FormControl>
    </FlexColumn>
  );
};

export default hasErrorBoundary(DocumentDetails);
