/* eslint-disable no-unused-vars */
import { v4 as uuidv4 } from 'uuid';
import {
  provincialProfileData as provincialProfileDataColumns,
  municipalityData as municipalityDataColumns,
} from '@Constants/exploreByGeography';
import useRouteParamQuery from '@Hooks/useRouteParamQuery';

const CharacteristicsColumn = ({
  municipalityCount,
}: {
  municipalityCount?: number;
}) => {
  // const [palika, district, ...characteristics] = CharacteristicsData;
  const getQueryParams = useRouteParamQuery();
  const munipalityCode = getQueryParams.get('municipality');

  // eslint-disable-next-line
  const columns = munipalityCode
    ? municipalityCount
      ? municipalityDataColumns?.slice(0, municipalityCount)
      : municipalityDataColumns
    : provincialProfileDataColumns;

  return (
    <>
      <div className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-gap-3">
        <div className="naxatw-h-[0.03125rem] naxatw-w-full naxatw-bg-[#C0C7DA]" />
        <p className=" naxatw-text-xs naxatw-font-medium naxatw-leading-normal naxatw-tracking-[-0.00875rem] naxatw-text-[#06367D] md:naxatw-text-sm">
          Characteristics
        </p>
        {columns.map(data => (
          <div key={uuidv4()}>
            <div className="naxatw-h-[0.03125rem] naxatw-w-full naxatw-bg-[#C0C7DA] " />
            <div className="naxatw-flex naxatw-h-[4.25rem] naxatw-items-center naxatw-gap-2">
              <p className="naxatw-text-sm md:naxatw-text-sm">
                {data.characteristic}
              </p>
            </div>
          </div>
        ))}
        <></>
      </div>
    </>
  );
};

export default CharacteristicsColumn;
