import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import TableLists from '@Components/common/CommonExport/TableLists';
import TableListTwo from '@Components/common/CommonExport/TableLists/TableListTwo';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { getProgrammeDetailsByProvince } from '@Services/provincialProfile';
import {
  deletePdfSection,
  resetPdfSection,
  setOriginalDataLists,
} from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import isEmpty from '@Utils/isEmpty';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const PdfProgramme = ({
  provinceCount,
}: {
  provinceCount: Record<string, any>;
}) => {
  const { provinceId, municipalityId } = useParams();
  const dispatch = useDispatch();
  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);
  const pageType = municipalityId ? 'palikaDetails' : 'provincialProfile';

  const showResetButton = useTypedSelector(state =>
    municipalityId
      ? state.pdfExportSlice.palikaDetails.programTable.showResetButton
      : state.pdfExportSlice.provincialProfile.programTable.showResetButton,
  );

  const dataLists = useTypedSelector(state =>
    municipalityId
      ? state.pdfExportSlice.palikaDetails.programTable.datalists
      : state.pdfExportSlice.provincialProfile.programTable.datalists,
  );

  const { data: programmmeData } = useQuery({
    queryKey: ['programme-details-table-data-pdf', provinceId, municipalityId],
    queryFn: async () =>
      getProgrammeDetailsByProvince({
        province: provinceId,
        status: filterBy,
        ...(municipalityId ? { municipality: municipalityId } : {}),
      }),
    select: res => {
      if (!res?.data) return null;
      const programData = res.data;

      const finalData = programData.flatMap(
        ({
          programs,
          data,
        }: {
          programs: string;
          data: Record<string, any>[];
        }) =>
          data.map(
            ({
              project_id: id,
              project_name: project,
              first_tier_partner: partner,
            }) => ({
              id,
              listOne: programs || '',
              listTwo: project || '',
              listThree: partner || '',
            }),
          ),
      );

      return finalData;
    },
    onSuccess: resposne => {
      dispatch(
        setOriginalDataLists({
          page: pageType,
          section: 'programTable',
          value: resposne,
        }),
      );
    },
  });

  const {
    program_count: programCount = '-',
    project_count: projectCount = '-',
    first_tier_partner_count: partnerCount = '-',
  } = provinceCount;

  if (isEmpty(programmmeData && programmmeData)) return null;

  return (
    <ExportRoundedContainer className=" report-table naxatw-overflow-hidden">
      <div className="pdf-programme-table-container">
        <ExportHeader
          headerTitle="Projects Details"
          subTitle={`${programCount} Programmes with ${projectCount} Projects Collaborating with ${partnerCount} First Tier Partners.`}
          className="!naxatw-border-b !naxatw-border-b-pdf-border  !naxatw-p-3"
          isStyleAbsolute={false}
          showResetButton={showResetButton}
          onHandleDelete={() =>
            dispatch(
              deletePdfSection({
                page: pageType,
                section: 'programTable',
              }),
            )
          }
          onHandleReset={() =>
            dispatch(
              resetPdfSection({
                page: pageType,
                section: 'programTable',
              }),
            )
          }
        />
        <TableLists
          dataLists={programmmeData?.slice(0, 5) || []}
          headerTitles={{
            firstColumn: 'Programme',
            secondColumn: 'Project',
            thirdColumn: 'Partner',
          }}
          isExport
          exportTitles={{
            page: pageType,
            section: 'programTable',
          }}
          exportLists={dataLists || []}
        />
      </div>
      {Array.isArray(programmmeData) && programmmeData?.length > 5 && (
        <TableListTwo
          dataLists={programmmeData.slice(5) || []}
          isExport
          exportTitles={{
            page: pageType,
            section: 'programTable',
          }}
          exportLists={dataLists || []}
        />
      )}
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(PdfProgramme);
