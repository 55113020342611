/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useInfiniteQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import useIntersectionObserver from '@Hooks/useIntersectionObserver';
import { useTypedSelector } from '@Store/hooks';
import { getPublicKnowledgeRepositoryEviData } from '@Services/publicKnowledgeRepository';
import Skeleton from '@Components/RadixComponents/Skeleton';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import isEmpty from '@Utils/isEmpty';
import { FlexColumn } from '@Components/common/Layouts';

import { cn } from '@Utils/index';
import KnowledgeCard from '../CardSection/KnowledgeCard';
import EvidenceSection from '../EvidenceSection';

const EvidenceAgendaCardSection = () => {
  const [isIntersecting, _, viewRef] = useIntersectionObserver();

  const searchText = useTypedSelector(
    state => state.knowledgeRepository.publicSearchText,
  );

  const programParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.program,
  );

  const projectParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.project,
  );

  const startDateParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.startDate,
  );

  const endDateParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.endDate,
  );

  const fileTypeParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.file_type,
  );

  const teamMemershipParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.teamOwnership,
  );

  const geographicFocusParams = useTypedSelector(
    state =>
      state.knowledgeRepository.evidenceAgendaFilterParams.geographicFocus,
  );

  const themeParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.themes,
  );

  const evidenceStatusParams = useTypedSelector(
    state =>
      state.knowledgeRepository.evidenceAgendaFilterParams.evidenceStatus,
  );

  const subCategoryParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.subCategory,
  );

  const {
    data: cardData,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    isError,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'public-knowledge-evi-repository-card-data',
      searchText,
      programParams,
      projectParams,
      startDateParams,
      endDateParams,
      fileTypeParams,
      teamMemershipParams,
      themeParams,
      geographicFocusParams,
      evidenceStatusParams,
      subCategoryParams,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await getPublicKnowledgeRepositoryEviData({
        page: pageParam,
        page_size: 5,
        search: searchText,

        program: programParams?.join(','),
        project: projectParams?.join(','),
        start_date: startDateParams,
        end_date: endDateParams,
        file_type: fileTypeParams?.join(','),
        team_membership: teamMemershipParams?.join(','),
        geographic_focus: geographicFocusParams?.join(','),
        theme: themeParams
          ?.map((theme: Record<string, any>) => theme.id)
          .join(','),
        evidence_agenda_status: evidenceStatusParams?.join(','),
        sub_category: subCategoryParams?.join(','),
      });
      return res?.data;
    },
    getNextPageParam: lastPage => {
      return lastPage?.next_page ?? undefined;
    },
    select: response => {
      return response?.pages.reduce((acc, page) => {
        return [...acc, ...(page.results || [])];
      }, []);
    },
  });

  if (isError) {
    toast.error('Something went wrong');
  }

  useEffect(() => {
    if (isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [isIntersecting, fetchNextPage, hasNextPage]);

  const knowledgeRepoData: any = cardData || [];

  return (
    <FlexColumn className="scrollbar naxatw-relative naxatw-overflow-y-auto naxatw-rounded-xl">
      <EvidenceSection />
      {isLoading ? (
        <div className="naxatw-flex naxatw-flex-col naxatw-gap-5 naxatw-bg-white naxatw-p-5">
          {Array.from({ length: 2 }).map(() => (
            <Skeleton key={uuid()} className="naxatw-h-[14rem] naxatw-w-full" />
          ))}
        </div>
      ) : (
        <div className="naxatw-h-full naxatw-bg-white">
          {isEmpty(knowledgeRepoData) ? (
            <div className="no-chart naxatw-flex naxatw-h-full naxatw-w-full naxatw-items-center naxatw-justify-center naxatw-py-5">
              <NoChartDataComponent messageStyles="!naxatw-text-base" />
            </div>
          ) : (
            <div className="repo-card-container">
              {knowledgeRepoData?.map(
                (repository: Record<string, any>, index: number) => (
                  <KnowledgeCard
                    key={repository?.id}
                    repository={repository}
                    className={cn(
                      'naxatw-bg-white',
                      index === knowledgeRepoData.length - 1
                        ? ''
                        : 'naxatw-border-b naxatw-border-b-[#D7D7D7]',
                    )}
                  />
                ),
              )}
              {/* view ref */}
              <div
                ref={viewRef}
                className="naxatw-pointer-events-none naxatw-bottom-0 naxatw-h-1 naxatw-w-full naxatw-bg-white"
              />
              {isFetchingNextPage && (
                <Skeleton className="naxatw-h-[14rem] naxatw-w-full" />
              )}
            </div>
          )}
        </div>
      )}
    </FlexColumn>
  );
};

export default hasErrorBoundary(EvidenceAgendaCardSection);
