import parse from 'html-react-parser';
import { format } from 'date-fns';
import ModalContainer from '@Components/common/FormUI/ModalContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Detail from '@Components/Updates/UpdateDetails/Detail/Detail';

const FullUdpateContent = ({
  title,
  data: updateData,
  onHandleClose,
}: {
  title?: string;
  data: Record<string, any>;
  onHandleClose: () => void;
}) => {
  const {
    date_created__date: createdDate,
    created_by__username: createdBy,
    summary,
  } = updateData;

  return (
    <ModalContainer
      title={title as string}
      className=" lg:naxatw-max-w-[50.625rem]"
      breadCrumbClasName="!naxatw-font-medium"
      onHandleClose={onHandleClose}
    >
      <div className="content-inner-container scrollbar naxatw-h-[70vh] naxatw-overflow-y-auto naxatw-p-[1.25rem]">
        <div className="content-header naxatw-mb-5 naxatw-flex naxatw-gap-5">
          <Detail
            icon="event"
            highlightText={
              createdDate
                ? format(
                    new Date(updateData?.date_created__date),
                    'MMMM dd, yyyy',
                  )
                : '-'
            }
            textStyle="!naxatw-text-base !naxatw-leading-normal !naxatw-tracking-[0.00625rem]"
            highlightTextStyles="!naxatw-font-medium  !naxatw-text-base !naxatw-leading-normal !naxatw-tracking-[0.00625rem]"
          />
          <Detail
            icon="person"
            highlightText={createdBy || '-'}
            textStyle="!naxatw-text-base  !naxatw-leading-normal !naxatw-tracking-[0.00625rem]"
            highlightTextStyles="!naxatw-font-medium  !naxatw-leading-normal !naxatw-tracking-[0.00625rem] !naxatw-text-base"
          />
        </div>
        {summary ? (
          <div className="content-summary  tiptap">{parse(summary)}</div>
        ) : null}
      </div>

      <div className="naxatw-pointer-events-none naxatw-h-7 naxatw-opacity-0" />
    </ModalContainer>
  );
};

export default hasErrorBoundary(FullUdpateContent);
