import { IDropDownData } from '@Constants/interface';
import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

export type knowledgeRepositoryState = {
  dropdownOptions: { [key: string]: IDropDownData[] };
  publicFilterOptions: Record<string, any>;
  publicSearchText: string;
  publicSelectedDate: Date | null;
  programmaticFilterState: null | Record<string, any>;
  contextualFilterState: null | Record<string, any>;
  evidenceAgendaFilterState: null | Record<string, any>;
  programmaticFilterParams: Record<string, any>;
  contextualFilterParams: Record<string, any>;
  evidenceAgendaFilterParams: Record<string, any>;
};

const initialState: knowledgeRepositoryState = {
  dropdownOptions: {
    release_type: [],
    associate_program_project: [],
    thematic_field: [],
    file_type: [],
  },
  publicFilterOptions: {
    file_type: null,
    release_type: null,
    thematic_field: null,
    associate_program_project: null,
  },
  publicSearchText: '',
  publicSelectedDate: null,
  programmaticFilterState: null,
  contextualFilterState: null,
  evidenceAgendaFilterState: null,
  programmaticFilterParams: {
    program: [],
    project: [],
    sector: [],
    startDate: '',
    endDate: '',
    file_type: [],
  },
  contextualFilterParams: {
    program: [],
    project: [],
    sector: [],
    startDate: '',
    endDate: '',
    file_type: [],
    province: [],
  },
  evidenceAgendaFilterParams: {
    program: [],
    project: [],
    file_type: [],
    startDate: '',
    endDate: '',
    themes: [],
    teamOwnership: [],
    geographicFocus: [],
    evidenceStatus: [],
    category: [],
    subCategory: [],
  },
};

const setDropdownOptionsList: CaseReducer<
  knowledgeRepositoryState,
  PayloadAction<Record<string, any>>
> = (state, action) => {
  const { key, value } = action.payload;
  return {
    ...state,
    dropdownOptions: {
      ...state.dropdownOptions,
      [key]: value,
    },
  };
};
const setPublicFilterOptions: CaseReducer<
  knowledgeRepositoryState,
  PayloadAction<Record<string, any>>
> = (state, action) => {
  return {
    ...state,
    publicFilterOptions: {
      ...state.publicFilterOptions,
      ...action.payload,
    },
  };
};

const setPublicSearchText: CaseReducer<
  knowledgeRepositoryState,
  PayloadAction<string>
> = (state, action) => {
  return {
    ...state,
    publicSearchText: action.payload,
  };
};

const setPublicSelectedDate: CaseReducer<
  knowledgeRepositoryState,
  PayloadAction<Date | null>
> = (state, action) => {
  return {
    ...state,
    publicSelectedDate: action.payload,
  };
};

const setKnowledgeLibraryStates: CaseReducer<
  knowledgeRepositoryState,
  PayloadAction<any>
> = (state, action) => {
  const { key, value } = action.payload;
  return {
    ...state,
    [key]: value,
  };
};

const postProgrammaticFilterParams: CaseReducer<
  knowledgeRepositoryState
> = state => {
  const programmeIds: number[] = [];
  const componentIds: number[] = [];
  const sectorGroupIds: number[] = [];
  const fileType: string[] = [];

  state.programmaticFilterState?.forEach((filterSta: Record<string, any>) => {
    const { category, subcategories } = filterSta;
    subcategories.forEach((categoryItem: Record<string, any>) => {
      if (categoryItem.checked) {
        switch (category) {
          case 'Programme':
            programmeIds.push(categoryItem.id);
            categoryItem.filterItems.forEach((compId: Record<string, any>) => {
              if (compId.checked) componentIds.push(compId.id);
            });
            break;
          case 'Thematic Field':
            sectorGroupIds.push(categoryItem.id);
            break;
          case 'File Type':
            fileType.push(categoryItem.id);
            break;
          default:
        }
      }
    });
  });
  return {
    ...state,
    programmaticFilterParams: {
      ...state.programmaticFilterParams,
      program: programmeIds,
      project: componentIds,
      sector: sectorGroupIds,
      file_type: fileType,
    },
  };
};

const postContextualFilterParams: CaseReducer<
  knowledgeRepositoryState
> = state => {
  const programmeIds: number[] = [];
  const componentIds: number[] = [];
  const sectorGroupIds: number[] = [];
  const fileType: string[] = [];
  const provinces: string[] = [];

  state.contextualFilterState?.forEach((filterSta: Record<string, any>) => {
    const { category, subcategories } = filterSta;
    subcategories.forEach((categoryItem: Record<string, any>) => {
      if (categoryItem.checked) {
        switch (category) {
          case 'Programme':
            programmeIds.push(categoryItem.id);
            categoryItem.filterItems.forEach((compId: Record<string, any>) => {
              if (compId.checked) componentIds.push(compId.id);
            });
            break;
          case 'Thematic Field':
            sectorGroupIds.push(categoryItem.id);
            break;
          case 'File Type':
            fileType.push(categoryItem.id);
            break;
          case 'Province':
            provinces.push(categoryItem.id);
            break;
          default:
        }
      }
    });
  });
  return {
    ...state,
    contextualFilterParams: {
      ...state.contextualFilterParams,
      program: programmeIds,
      project: componentIds,
      sector: sectorGroupIds,
      file_type: fileType,
      province: provinces,
    },
  };
};

const toggleknowledgeRepositoryFilterState: CaseReducer<
  knowledgeRepositoryState,
  PayloadAction<Record<string, any>>
> = (state, action) => {
  const { categoryId, subcategoryId, filterId, key } = action.payload;
  const filterKey = key as keyof knowledgeRepositoryState;
  const stateFilterKey: any = state[filterKey];

  return {
    ...state,
    [filterKey]: stateFilterKey?.map((category: Record<string, any>) => {
      if (category.id !== categoryId) return category;
      return {
        ...category,
        subcategories: category.subcategories
          ?.map((subcategory: Record<string, any>) => {
            if (subcategory.id !== subcategoryId) return subcategory;

            if (filterId) {
              const updatedFilterItems = subcategory.filterItems.map(
                (filterItem: Record<string, any>) => {
                  if (filterItem.id === filterId) {
                    return {
                      ...filterItem,
                      checked: !filterItem.checked,
                    };
                  }
                  return filterItem;
                },
              );

              const isAnyFilterItemChecked = updatedFilterItems.some(
                (filterItem: Record<string, any>) => filterItem.checked,
              );

              return {
                ...subcategory,
                filterItems: updatedFilterItems,
                checked: isAnyFilterItemChecked,
              };
            }

            return {
              ...subcategory,
              checked: !subcategory.checked,
              filterItems: subcategory.filterItems?.map(
                (filterItem: Record<string, any>) => {
                  if (subcategory.checked) {
                    return { ...filterItem, checked: false };
                  }
                  return { ...filterItem, checked: true };
                },
              ),
            };
          })
          .sort((a: Record<string, any>, b: Record<string, any>) => {
            return b.checked - a.checked;
          }),
      };
    }),
  };
};

const setKnowledgeLibraryDateRangeState: CaseReducer<
  knowledgeRepositoryState,
  PayloadAction<{
    key: keyof knowledgeRepositoryState;
    type: string;
    date?: string | Date;
  }>
> = (state, action) => {
  const { key, type } = action.payload;

  if (type === 'startDate') {
    const stateKey = state[key] as Record<string, any>;
    return {
      ...state,
      [key]: {
        ...stateKey,
        startDate: action?.payload?.date,
      },
    };
  }

  if (type === 'clear') {
    const stateKey = state[key] as Record<string, any>;
    return {
      ...state,
      [key]: {
        ...stateKey,
        startDate: '',
        endDate: '',
      },
    };
  }

  return {
    ...state,
    [key]: {
      ...(state[key] as Record<string, any>),
      endDate: action?.payload?.date,
    },
  };
};

const resetProgrammaticState: CaseReducer<knowledgeRepositoryState> = state => {
  const resetChecked = (data: any[]): any[] =>
    data?.map(category => ({
      ...category,
      checked: false,
      ...(category.subcategories && {
        subcategories: resetChecked(category.subcategories),
      }),
      ...(category.filterItems && {
        filterItems: resetChecked(category.filterItems),
      }),
    }));

  return {
    ...state,
    programmaticFilterState: resetChecked(state.programmaticFilterState as any),
    programmaticFilterParams: {
      program: [],
      project: [],
      sector: [],
      startDate: '',
      endDate: '',
      file_type: [],
    },
  };
};

const resetContextualFilterState: CaseReducer<
  knowledgeRepositoryState
> = state => {
  const resetChecked = (data: any[]): any[] =>
    data?.map(category => ({
      ...category,
      checked: false,
      ...(category.subcategories && {
        subcategories: resetChecked(category.subcategories),
      }),
      ...(category.filterItems && {
        filterItems: resetChecked(category.filterItems),
      }),
    }));

  return {
    ...state,
    contextualFilterState: resetChecked(state.contextualFilterState as any),
    contextualFilterParams: {
      program: [],
      project: [],
      sector: [],
      startDate: '',
      endDate: '',
      file_type: [],
      province: [],
    },
  };
};

const resetEvidenceAgendaFilterState: CaseReducer<
  knowledgeRepositoryState
> = state => {
  const resetChecked = (data: any[]): any[] =>
    data?.map(category => ({
      ...category,
      checked: false,
      ...(category.subcategories && {
        subcategories: resetChecked(category.subcategories),
      }),
      ...(category.filterItems && {
        filterItems: resetChecked(category.filterItems),
      }),
    }));

  return {
    ...state,
    evidenceAgendaFilterState: resetChecked(
      state.evidenceAgendaFilterState as any,
    ),
    evidenceAgendaFilterParams: {
      program: [],
      project: [],
      file_type: [],
      startDate: '',
      endDate: '',
      themes: [],
      teamOwnership: [],
      geographicFocus: [],
      evidenceStatus: [],
      category: [],
      subCategory: [],
    },
  };
};

const postEviAgendaFilterParams: CaseReducer<
  knowledgeRepositoryState
> = state => {
  const programmeIds: number[] = [];
  const componentIds: number[] = [];
  const fileType: string[] = [];
  const themes: {
    isGes: boolean;
    id: number | string;
    value: string | number;
  }[] = [];
  const geographicFocus: number[] = [];
  const teamOwnership: number[] = [];
  const evidenceStatus: string[] = [];
  const catId: number[] = [];
  const subCatID: number[] = [];

  state.evidenceAgendaFilterState?.forEach((filterSta: Record<string, any>) => {
    const { category, subcategories } = filterSta;

    subcategories.forEach((categoryItem: Record<string, any>) => {
      if (categoryItem.checked) {
        switch (category) {
          case 'Programme':
            programmeIds.push(categoryItem.id);
            categoryItem.filterItems.forEach((compId: Record<string, any>) => {
              if (compId.checked) componentIds.push(compId.id);
            });
            break;
          case 'File Type':
            fileType.push(categoryItem.id);
            break;

          // case 'Theme': {
          //   themes.push({
          //     id: categoryItem.id,
          //     value: categoryItem.id,
          //   });
          //   break;
          // }
          case 'Theme': {
            themes.push({
              isGes: categoryItem.isGes,
              id: categoryItem.id,
              value: categoryItem.value,
            });
            break;
          }

          case 'Geographic Focus': {
            geographicFocus.push(categoryItem.id);
            break;
          }

          case 'Team Ownership': {
            teamOwnership.push(categoryItem.id);
            break;
          }
          case 'Status': {
            evidenceStatus.push(categoryItem.id);
            break;
          }

          case 'Category': {
            catId.push(categoryItem.id);
            categoryItem.filterItems.forEach((compId: Record<string, any>) => {
              if (compId.checked) subCatID.push(compId.id);
            });
            break;
          }

          default:
        }
      }
    });
  });

  return {
    ...state,
    evidenceAgendaFilterParams: {
      ...state.evidenceAgendaFilterParams,
      program: programmeIds,
      project: componentIds,
      file_type: fileType,
      themes,
      geographicFocus,
      teamOwnership,
      evidenceStatus,
      category: catId,
      subCategory: subCatID,
    },
  };
};

const knowledgeRepositorySlice = createSlice({
  name: 'knowledgeRepository',
  initialState,
  reducers: {
    setDropdownOptionsList,
    setPublicFilterOptions,
    setPublicSearchText,
    setPublicSelectedDate,
    postProgrammaticFilterParams,
    setKnowledgeLibraryDateRangeState,
    resetProgrammaticState,
    setKnowledgeLibraryStates,
    postContextualFilterParams,
    toggleknowledgeRepositoryFilterState,
    resetContextualFilterState,
    postEviAgendaFilterParams,
    resetEvidenceAgendaFilterState,
  },
});

export { knowledgeRepositorySlice };

export default knowledgeRepositorySlice.reducer;
