import ProgrammeDetails from '@Components/ProgrammeDetails';

const ProgrammeDetailsPage = () => {
  return (
    <div className="naxatw-mx-auto naxatw-w-full naxatw-max-w-[90rem] naxatw-px-5 xl:naxatw-px-0">
      <ProgrammeDetails />
    </div>
  );
};

export default ProgrammeDetailsPage;
