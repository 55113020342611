/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import useCustomUpload from '@Hooks/useCustomUpload';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Icon from '@Components/common/Icon';
import Image from '@Components/RadixComponents/Image';
import { UseFormPropsType } from '@Constants/Types/type';
import { toast } from 'react-toastify';
import isEmpty from '@Utils/isEmpty';
import Input from '../Input';

type FileType = File & {
  lastModifiedDate: Date;
};
type UploadedFilesType = {
  id: string;
  previewURL: string;
  file: FileType | any;
}[];

type FileEvent = ChangeEvent<HTMLInputElement> & {
  target: EventTarget & { files: FileList };
};
interface IFileUploadProps extends UseFormPropsType {
  name: string;
  multiple?: boolean;
  fileAccept?: string;
  data?: [] | UploadedFilesType;
  placeholder?: string;
  onChange?: any;
  maxSize?: number;
}

function downloadBlob(blobURL: string, fileName: string) {
  const link = document.createElement('a');
  link.href = blobURL;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export default function FileUpload({
  name,
  register,
  setValue,
  multiple,
  fileAccept = 'image/*',
  data,
  placeholder,
  onChange,
  maxSize,
}: IFileUploadProps) {
  const [inputRef, onFileUpload] = useCustomUpload();
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFilesType>([]);

  const resetFileInput = useCallback(() => {
    if (inputRef.current?.value) {
      inputRef.current.value = '';
    }
  }, [inputRef]);

  const handleFileUpload = (event: FileEvent) => {
    const { files } = event.target;
    const fileSize = files[0].size / 1024 ** 2;

    if (maxSize && fileSize > maxSize) {
      toast.error(`File size should not exceed ${maxSize}MB`);
      resetFileInput();
      return;
    }
    const uploaded = Array.from(files)?.map(file => ({
      id: uuidv4(),
      previewURL: URL.createObjectURL(file),
      file,
    }));
    const uploadedFilesState = multiple
      ? [...uploadedFiles, ...uploaded]
      : uploaded;
    setUploadedFiles(uploadedFilesState);
    if (setValue) {
      setValue(name, uploadedFilesState, { shouldDirty: true });
    }

    onChange?.(uploadedFiles);
  };

  const handleDeleteFile = (
    id: string,
    fileToDelete: Record<string, string> | File,
  ) => {
    if (!multiple || fileToDelete instanceof File || typeof id === 'string') {
      const updatedData = uploadedFiles.filter(file => file?.id !== id);
      setUploadedFiles(updatedData);
      setValue(name, updatedData, { shouldDirty: true });
    } else {
      setValue('documentToDelete', id);
    }

    resetFileInput();
  };

  // handling data when editing
  useEffect(() => {
    if (
      Array.isArray(data) &&
      (typeof data[0]?.file === 'string' ||
        typeof data[0]?.file === 'object') &&
      typeof data[0]?.id === 'number'
    ) {
      const uploaded = data.map(url => {
        const fileName =
          typeof url.file === 'string'
            ? url.file.split('/')?.[7]?.split('?')?.[0] ||
              url.file.split('/')?.[6]?.split('?')?.[0] ||
              'document'
            : url.file?.name || '';

        return {
          id: url.id,
          previewURL: url.file,
          file: { name: fileName },
        };
      });

      setUploadedFiles(uploaded);
    }
  }, [data]);

  // handles data when file is upload
  useEffect(() => {
    if (
      Array.isArray(data) &&
      data.every(uploadedItem => uploadedItem?.file instanceof File)
    ) {
      setUploadedFiles(data as UploadedFilesType);
    }
  }, [data]);

  // register form element to useForm
  useEffect(() => {
    if (!register || !setValue) return;
    register(name);
  }, [register, name, setValue]);

  return (
    <FlexColumn gap={5}>
      <FlexColumn
        className="naxatw-cursor-pointer naxatw-items-center naxatw-justify-center naxatw-rounded-lg
          naxatw-border naxatw-border-dashed naxatw-border-[#E6E6E6] naxatw-bg-[#FAFAFA] naxatw-px-5 naxatw-py-3 naxatw-duration-200 hover:naxatw-border-secondary-500"
        onClick={onFileUpload}
      >
        <Icon
          name="cloud_upload"
          className="naxatw-text-3xl naxatw-text-[#14428B] "
        />
        <p className="naxatw-text-xs naxatw-font-medium naxatw-text-gray-500">
          {placeholder || 'Please upload picture (jpeg, png file format)'}
        </p>
        <Input
          type="file"
          className="naxatw-hidden"
          multiple={multiple}
          onChange={handleFileUpload}
          ref={inputRef}
          accept={fileAccept}
        />
      </FlexColumn>
      {multiple && (
        <FlexRow
          className="naxatw-group naxatw-w-full naxatw-items-center naxatw-justify-center naxatw-rounded-md naxatw-border naxatw-border-dashed naxatw-p-3 naxatw-duration-200 hover:naxatw-border-secondary-500 hover:naxatw-bg-[#FAFAFA]"
          role="button"
          tabIndex={0}
          onClick={onFileUpload}
        >
          <Input
            type="file"
            className="naxatw-hidden"
            multiple={multiple}
            accept={fileAccept}
            onChange={handleFileUpload}
            ref={inputRef}
          />
          <span className="naxatw-text-sm naxatw-font-medium naxatw-text-matt-200 group-hover:naxatw-text-matt-100">
            <span className="naxatw-text-base">+</span> Add More Documents
          </span>
        </FlexRow>
      )}

      {!isEmpty(uploadedFiles) ? (
        <FlexColumn gap={2}>
          {uploadedFiles.map(({ file, id, previewURL }) => (
            <FlexRow
              key={id}
              className="naxatw-w-[100%] naxatw-items-center naxatw-justify-between  naxatw-rounded-lg naxatw-px-4 naxatw-py-2"
            >
              <FlexRow
                gap={3}
                className="naxatw-w-[80%] naxatw-items-center naxatw-justify-start"
              >
                <div className="file-icon">
                  {fileAccept.includes('.pdf') ||
                  fileAccept === '*' ||
                  fileAccept === 'video/*' ? (
                    <div>
                      <Icon
                        name="description"
                        className="naxatw-rounded-full naxatw-bg-primary-100 naxatw-p-2 naxatw-text-primary-700"
                      />
                    </div>
                  ) : (
                    <div className="naxatw-size-8 naxatw-overflow-hidden naxatw-rounded-full naxatw-bg-primary-100">
                      <Image
                        styleClass="naxatw-h-full naxatw-w-full"
                        src={previewURL}
                        alt="image"
                        className="naxatw-h-full naxatw-w-full naxatw-object-cover"
                      />
                    </div>
                  )}
                </div>
                <FlexColumn className="naxatw-w-[85%]">
                  <h5 className="naxatw-overflow-hidden  naxatw-text-ellipsis naxatw-whitespace-nowrap  naxatw-text-sm !naxatw-leading-normal">
                    {file?.name}
                  </h5>
                  {file && file?.lastModified && (
                    <p className="naxatw-text-xs naxatw-text-gray-500">
                      Uploaded on{' '}
                      {format(new Date(file.lastModifiedDate), 'MMM dd yyyy')}
                    </p>
                  )}
                </FlexColumn>
              </FlexRow>
              <FlexRow gap={2} className="!naxatw-w-[20%]  naxatw-justify-end">
                <Icon
                  name="download"
                  className="naxatw-text-grey-400 naxatw-duration-200 hover:naxatw-text-secondary-500"
                  onClick={() => downloadBlob(previewURL, file?.name)}
                />
                <Icon
                  name="delete"
                  className="naxatw-text-grey-400 naxatw-duration-200 hover:naxatw-text-red-600"
                  onClick={() => handleDeleteFile(id, file)}
                />
              </FlexRow>
            </FlexRow>
          ))}
        </FlexColumn>
      ) : null}
    </FlexColumn>
  );
}
