import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import AdminHeader from '@Components/common/AdminHeader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ModalContainer from '@Components/common/FormUI/ModalContainer';
import SubCategoryForm from './SubCategoryForm';
import SubCategoryTable from './SubCategoryTable';

const SubCategory = () => {
  const navigate = useNavigate();
  const { subCatId } = useParams();
  const { pathname } = useLocation();
  const [toggleForm, setToggleForm] = useState<boolean>(false);

  useEffect(() => {
    if (pathname.includes('edit') || pathname.includes('add')) {
      setToggleForm(true);
    } else {
      setToggleForm(false);
    }
  }, [pathname]);
  return (
    <div className="sub-category-form naxatw-h-full  naxatw-space-y-6">
      <AdminHeader
        title="Agenda Sub Category"
        registerButtonText="Add Sub Category"
        onAddNewClick={() => navigate('/data-bank/sub-category/add')}
      />

      {toggleForm && (
        <ModalContainer
          title={`${subCatId ? 'Edit' : 'Add'} Sub Category`}
          onHandleClose={() => navigate('/data-bank/sub-category')}
        >
          <SubCategoryForm
            onClose={() => navigate('/data-bank/sub-category')}
          />
        </ModalContainer>
      )}

      <SubCategoryTable />
    </div>
  );
};

export default hasErrorBoundary(SubCategory);
