/* eslint-disable no-nested-ternary */
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  nepalDescriptionData,
  provincialProfileData as provincialProfileDataColumns,
  municipalityData as municipalityDataColumns,
} from '@Constants/exploreByGeography';
import { getProvincialSummary, municipalSummary } from '@Services/goals';
import { parseFormattedNumber } from '@Utils/index';
import Skeleton from '@Components/RadixComponents/Skeleton';
import {
  getMunicipalityListByCode,
  getProvinceData,
} from '@Services/portfolioProfile';
import useRouteParamQuery from '@Hooks/useRouteParamQuery';
import { Button } from '@Components/RadixComponents/Button';
import Portal from '@Components/common/Layouts/Portal';
import Modal from '@Components/common/Modal';
import { useState } from 'react';
import { FlexColumn } from '@Components/common/Layouts';
import DescriptionCard from './DescriptionCard';
import CharacteristicsColumn from './Characteristics';
import CountData from './CountData';
import ContactsModal from './ContactsModal';

function PopulationCount({ count }: { count: number }) {
  return (
    <div className="naxatw-flex naxatw-flex-col naxatw-items-center naxatw-gap-1 naxatw-py-3">
      <p className="naxatw-text-sm naxatw-uppercase naxatw-leading-normal">
        Total Population
      </p>
      <p className=" naxatw-text-[2.125rem] naxatw-font-medium naxatw-leading-normal naxatw-text-[#06367D]">
        {count || ''}
      </p>
      <p className="naxatw-text-xs naxatw-font-medium naxatw-leading-normal naxatw-tracking-[-0.015rem] naxatw-text-[#06367D]">
        {((parseFormattedNumber(count) / 29164578) * 100).toFixed(2)} % Share of
        National Population
      </p>
    </div>
  );
}

export default function Description({
  activeProvinceName,
}: {
  activeProvinceName: Record<string, any>;
}) {
  const [showModal, setShowModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const { provinceId } = useParams();

  const getQueryParams = useRouteParamQuery();
  const municipalityCode = getQueryParams.get('municipality');

  const { data: provinceData, isLoading: provinceDataIsLoading } = useQuery({
    queryKey: ['province-data-name', provinceId],
    queryFn: async () => getProvincialSummary(provinceId),
    select: (res: any) => res.data[0],
    enabled: !!provinceId,
  });

  const { data: municipalityData } = useQuery({
    queryKey: ['municipality-data', provinceId, municipalityCode],
    queryFn: async () => getMunicipalityListByCode(municipalityCode || ''),
    select: (res: any) => res.data,
    enabled: !!municipalityCode,
  });

  const { data: municipalitySummary } = useQuery({
    queryKey: [
      'municipality-data-summary-data',
      municipalityData?.cbs_code,
      municipalityData,
    ],
    queryFn: () =>
      municipalSummary({ municipality_code: municipalityData?.cbs_code }),
    select: res => {
      if (!res?.data[0]) return [];
      return res.data[0];
    },
    enabled: !!municipalityData?.cbs_code,
  });

  const { data: provinceName, isLoading: provinceNameIsLoading } = useQuery({
    queryKey: ['province-name', provinceId],
    queryFn: async () => getProvinceData(provinceId),
    select: (res: any) => res.data.name,
    enabled: !!provinceId,
  });

  function handleToggleModal() {
    setShowModal(prev => !prev);
  }

  return (
    <div className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-gap-6 naxatw-rounded-xl naxatw-border-[1px] naxatw-border-[#EAECF0] naxatw-bg-white naxatw-px-6 naxatw-pb-3">
      <div>
        {!municipalityCode ? (
          <p className="naxatw-py-3 naxatw-text-[18px] naxatw-font-bold naxatw-text-[#475467]">
            Description
          </p>
        ) : (
          <p className="naxatw-mb-3 naxatw-py-3 naxatw-text-[18px] naxatw-font-bold naxatw-text-[#475467]">
            Key Context Indicators
          </p>
        )}
        <div
          className={`naxatw-flex naxatw-flex-col ${municipalityCode ? 'naxatw-gap-1' : 'naxatw-gap-6'}`}
        >
          {!municipalityCode && (
            <div className="naxatw-border-t naxatw-py-3  naxatw-text-[#4A4A4A]">
              <DescriptionCard
                cardHeadersData={provinceData || []}
                isLoading={provinceDataIsLoading}
                activeProvinceName={activeProvinceName || {}}
              />
            </div>
          )}
          <div
            className={`naxatw-flex naxatw-flex-col naxatw-items-center naxatw-gap-1 naxatw-py-3 ${!municipalityCode ? 'naxatw-border-b' : ''}`}
          >
            <PopulationCount
              count={
                municipalityCode
                  ? municipalitySummary?.total_population
                    ? Number(
                        municipalitySummary.total_population,
                      ).toLocaleString()
                    : '-'
                  : provinceData?.total_population
                    ? provinceData.total_population
                    : '-'
              }
            />
          </div>
        </div>
      </div>
      {
        //     municipalityCode && (
        //   <div>
        //     {provinceDataIsLoading || provinceNameIsLoading ? (
        //       <div className="naxatw-flex naxatw-justify-between">
        //         <Skeleton className="naxatw-h-[45rem] naxatw-w-[7rem]" />
        //         <Skeleton className="naxatw-h-[45rem] naxatw-w-[7rem]" />
        //         <Skeleton className="naxatw-h-[45rem] naxatw-w-[7rem]" />
        //       </div>
        //     ) : (
        //       <div className="naxatw-flex naxatw-items-start naxatw-self-stretch">
        //         <CharacteristicsColumn municipalityCount={3} />
        //         <CountData
        //           data={
        //             municipalityCode
        //               ? municipalitySummary?.[0] || []
        //               : provinceData || []
        //           }
        //           title={` ${municipalityCode ? municipalityData?.name : provinceName}`}
        //           columns={
        //             municipalityCode
        //               ? municipalityDataColumns.slice(0, 3)
        //               : provincialProfileDataColumns
        //           }
        //         />
        //         <CountData
        //           data={nepalDescriptionData}
        //           title="Nepal"
        //           columns={
        //             municipalityCode
        //               ? municipalityDataColumns?.slice(0, 3)
        //               : provincialProfileDataColumns
        //           }
        //         />
        //       </div>
        //     )}
        //   </div>
        // )
      }

      {/* key-context-indicators */}
      <div className="contact-indicator-container naxatw-flex naxatw-items-center naxatw-gap-2">
        <Button
          className="naxatw-flex-1 naxatw-border !naxatw-border-secondary-500 naxatw-bg-transparent !naxatw-text-secondary-500 hover:!naxatw-text-white"
          onClick={() => handleToggleModal()}
        >
          Key Context Indicators
        </Button>
        {!municipalityCode && (
          <Button
            className="naxatw-font-semibold"
            variant="link"
            onClick={() => setShowContactModal(true)}
          >
            Key Contacts
          </Button>
        )}
      </div>

      {/* Key context Indicator Modal */}
      {showModal && (
        <Portal>
          <Modal
            onClose={() => handleToggleModal()}
            title="Key Context Indicators"
            titleClassName="naxatw-text-xl"
            className="naxatw-shadow [&>*:first-child]:!naxatw-shadow-lg"
            show={showModal}
          >
            <FlexColumn className="naxatw-mt-10 naxatw-gap-8">
              <PopulationCount count={provinceData?.total_population} />
              {provinceDataIsLoading || provinceNameIsLoading ? (
                <div className="naxatw-flex naxatw-justify-between naxatw-px-6">
                  <Skeleton className="naxatw-h-[45rem] naxatw-w-[7rem]" />
                  <Skeleton className="naxatw-h-[45rem] naxatw-w-[7rem]" />
                  <Skeleton className="naxatw-h-[45rem] naxatw-w-[7rem]" />
                </div>
              ) : (
                <div className="naxatw-flex naxatw-items-start naxatw-self-stretch naxatw-px-6">
                  <CharacteristicsColumn />
                  <CountData
                    data={
                      municipalityCode
                        ? municipalitySummary || []
                        : provinceData || []
                    }
                    title={` ${municipalityCode ? municipalityData?.name : provinceName}`}
                    columns={
                      municipalityCode
                        ? municipalityDataColumns
                        : provincialProfileDataColumns
                    }
                  />
                  <CountData
                    data={nepalDescriptionData}
                    title="Nepal"
                    columns={
                      municipalityCode
                        ? municipalityDataColumns
                        : provincialProfileDataColumns
                    }
                  />
                </div>
              )}
            </FlexColumn>
          </Modal>
        </Portal>
      )}

      {/* contacts Modal */}
      {showContactModal && (
        <ContactsModal onClose={() => setShowContactModal(false)} />
      )}
    </div>
  );
}
