/* eslint-disable import/prefer-default-export */
import { z } from 'zod';

export const accountSettingsPasswordValidation = z
  .object({
    oldPassword: z
      .string()
      .trim()
      .min(1, 'Old Password is  Required')
      .max(250, 'Old Password cannot be more than 250 characters')
      .regex(/A\s*z/, 'Password cannot contain spaces'),
    password: z
      .string()
      .min(8, 'Min 8 characters Password is Required')
      .max(250, 'Password cannot be more than 250 characters')
      .regex(/[a-zA-Z]/, 'At least one alphabet Required')
      .regex(/[0-9]/, 'At least one Number Required')
      .regex(
        /[!@#$%^&*(),.?":{}|<>]/,
        'At least one Special character required',
      )
      .regex(/^\S*$/, 'Password cannot contain spaces'),
    confirmPassword: z.string().min(1, 'Confirm Password is Required'),
  })
  .refine(data => data.password === data.confirmPassword, {
    message: 'Passwords must match',
    path: ['confirmPassword'],
  });
