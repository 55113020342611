/* eslint-disable no-nested-ternary */
import CustomDonutChart from '@Components/common/Charts/DonutChart';
import { cn } from '@Utils/index';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import ReportChartLegend from '@Components/common/CommonExport/ReportChartLegend';
import isEmpty from '@Utils/isEmpty';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import { useTypedSelector } from '@Store/hooks';

const projectStatusColors = ['#41588F', '#719ED7', '#B7E7E4'];

interface IProjectChartProps {
  className?: string;
  chartsData?: Record<string, any>[] | undefined;
  isExport?: boolean;
}

const ProjectChart = ({
  className,
  chartsData,
  isExport = false,
}: IProjectChartProps) => {
  // const { data: projectStatus, isLoading } = useQuery({
  //   queryKey: ['project-status-chart'],
  //   queryFn: () =>
  //     getProjectStatus(paramsId ? { province: Number(paramsId) } : {}),
  //   select: response => {
  //     if (!response?.data) return null;
  //     const proStatus = response.data.map(
  //       (status: Record<string, any>, index: number) => ({
  //         name: status.status,
  //         value: status.count,
  //         color: projectStatusColors[index],
  //       }),
  //     );

  //     return proStatus;
  //   },
  // });

  const showFilterColumn = useTypedSelector(
    state => state.mainDashboard.showFilterColumn,
  );

  return (
    // <CustomPieChart data={chartsData || []} />
    // <CustomCornerDonutChart
    //   headerContent={<ProjetChartHeader />}
    //   chartData={projectStatus || []}
    //   isChartLoading={isLoading}
    //   className={className}
    //   chartType="donut"
    // />

    <div
      className={cn(
        'project-chart naxatw-overflow-hidden naxatw-rounded-lg naxatw-border naxatw-border-primary-200 !naxatw-bg-white naxatw-p-3 ',
        className,
      )}
    >
      <p
        className={`naxatw-mb-3 naxatw-text-center ${isExport ? 'fs-sm-medium' : ' naxatw-text-[0.875rem] naxatw-font-medium naxatw-text-primary-700'}`}
      >
        No. of Projects in Priority Provinces
      </p>

      <div
        className={`${isExport ? '' : showFilterColumn ? 'naxatw-h-[calc(100%-3rem)]' : 'naxatw-h-[calc(100%-1.8rem)]'}`}
      >
        {isEmpty(chartsData as []) ? (
          <NoChartDataComponent messageStyles="naxatw-text-[0.875rem]" />
        ) : (
          <div className="naxatw-flex naxatw-h-full naxatw-flex-col naxatw-justify-center naxatw-gap-3">
            <div
              className={`naxatw-flex naxatw-items-center ${isExport ? '' : 'naxatw-h-[calc(100%-6rem)]'}`}
            >
              <CustomDonutChart
                data={chartsData || []}
                fills={projectStatusColors}
                maxHeight={isExport ? 134 : 200}
                minHeight={isExport ? 134 : 200}
                innerRadius={isExport ? 40 : 60}
              />
            </div>
            <div
              className={`${isExport ? 'naxatw-py-2' : 'naxatw-py-5'} chart-legend `}
            >
              <ReportChartLegend
                data={chartsData || []}
                fills={projectStatusColors}
                className="naxatw-mx-auto naxatw-w-[75%] "
                isExport={isExport}
                isRoundedLegend
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default hasErrorBoundary(ProjectChart);
