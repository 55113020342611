/* eslint-disable react/no-danger */
import { ReactNode, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import getFileExtension from '@Utils/getFileExtension';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import isEmpty from '@Utils/isEmpty';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import useSeeMoreButton from '@Hooks/useSeeMoreButton';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { cn, getFileNameFromURL } from '@Utils/index';
import Icon from '@Components/common/Icon';
import IconButton from '@Components/common/IconButton';
import StatusChips from '@Components/common/StatusChips';
import {
  checkIfEmpty,
  getDocumentIdApiByPathname,
  getFileLogo,
  mapKnowledgeRepositoryStatus,
} from '@Constants/knowledgeRepository';

import { useLocation } from 'react-router-dom';
import DocumentShareModal from '../ShareModal';

type TableCommonProps = {
  className?: string;
  children?: ReactNode;
};

function TRow({ children, className }: TableCommonProps) {
  return (
    <tr className={`!naxatw-bg-transparent ${className || ''}`}>{children}</tr>
  );
}

function TData({ children, className }: TableCommonProps) {
  return (
    <td
      className={cn(
        `!naxatw-bg-transparent naxatw-py-2 naxatw-align-baseline first:naxatw-w-[120px] first:naxatw-pr-2`,
        className,
      )}
    >
      {children}
    </td>
  );
}

const KnowledgeCard = ({
  repository,
  className,
}: {
  repository: Record<string, any>;
  className?: string;
}) => {
  const { pathname } = useLocation();
  const summaryRef = useRef(null);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [showDocGenerateLink, setShwDocGenerateLink] = useState(false);

  const {
    summary,
    tags,
    id,
    documents,
    programs,
    gesi,
    category,
    file_type: fileType,
    published_date: date,
    thematic_field: thematicField,
    document_link: dLink,
    created_by: createdBy,
    is_public: isPublic,
    team_membership: teamOwnership,
    geographic_focus: geographicFocus,
    evidence_agenda_status: evidenceAgendaStatus,
    sub_category: subCategory,
    evidence_agenda: evidenceAgenda,
    other_program_project: otherProgramProject,
    email,
    username,
    province,
  } = repository;

  // handle knowDoc Link fectch
  const { data: knoDocLink } = useQuery({
    queryKey: ['generate-document-link', documents],
    queryFn: () => {
      const knowRepoGenerateDocument = getDocumentIdApiByPathname(pathname);
      return knowRepoGenerateDocument({ id });
    },
    enabled: showDocGenerateLink && !isEmpty(documents),
    select: response => response?.data?.zip_url,
  });

  const allTags = tags?.split(',')?.filter((tag: string) => tag !== '');
  const { isOpen, setIsOpen, showSeeMoreBtn } = useSeeMoreButton(summaryRef);

  return (
    <>
      <div className={`naxatw-w-full naxatw-p-5 ${className}`}>
        <FlexRow className="naxatw-mb-3 naxatw-items-center naxatw-gap-4">
          <p className="naxatw-text-base naxatw-font-medium naxatw-capitalize naxatw-leading-5 naxatw-text-[#0B2E62]">
            {repository?.title || '-'}
          </p>

          {evidenceAgendaStatus ? (
            <StatusChips
              label={evidenceAgendaStatus}
              status={
                mapKnowledgeRepositoryStatus[evidenceAgendaStatus] || 'error'
              }
            />
          ) : null}
          <div
            className="share-content naxatw-flex naxatw-items-center naxatw-gap-1 naxatw-px-4 naxatw-py-2"
            onClick={() => {
              setIsShareModalOpen(true);
              setShwDocGenerateLink(true);
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <ToolTip
              message="Share this document"
              name="share"
              className="naxatw-text-[1.25rem] !naxatw-text-secondary-500"
            />
          </div>
        </FlexRow>
        <div className="naxatw-grid naxatw-grid-cols-1 naxatw-gap-12 lg:naxatw-grid-cols-[3fr_2fr]">
          {/* summary */}

          <div className="content-left naxatw-flex naxatw-flex-col naxatw-gap-6">
            {category && subCategory ? (
              <div className="cat-sub naxatw-text-matt-100">
                <p className="naxatw-mb-1 naxatw-text-[0.875rem]  naxatw-font-bold !naxatw-tracking-[-0.0175rem]">
                  {category}
                </p>
                <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.0175rem]">
                  {subCategory ? `(${subCategory})` : null}
                </p>
              </div>
            ) : null}

            {checkIfEmpty(summary) ? null : (
              <div className="summary">
                <div
                  className={`editor-content tiptap naxatw-text-matt-200 ${isOpen ? '' : 'naxatw-line-clamp-[4]'}`}
                  ref={summaryRef}
                  dangerouslySetInnerHTML={{
                    __html: summary,
                  }}
                />
                {showSeeMoreBtn && (
                  <button
                    type="button"
                    className="naxatw-text-[0.75rem] naxatw-font-bold naxatw-leading-[1.25rem] naxatw-text-[#41588F]"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    See {isOpen ? 'Less' : 'More'}
                  </button>
                )}
              </div>
            )}

            {/*  date and uploaded by */}
            <div className="date-uploaded-by-container naxatw-flex naxatw-items-center naxatw-gap-3">
              <div className="uploaded-by naxatw-flex naxatw-items-center naxatw-gap-2">
                <IconButton
                  name="person"
                  className="!naxatw-h-5 !naxatw-w-5"
                  iconClassName="naxatw-text-xl naxatw-text-matt-200"
                  type="material-icons"
                />
                <p className="naxatw-body-caption naxatw-capitalize naxatw-text-matt-100">
                  {createdBy}
                </p>
              </div>
              {/* date */}
              <div className="date naxatw-flex naxatw-items-center naxatw-gap-2">
                <IconButton
                  name="event"
                  className="!naxatw-h-5 !naxatw-w-5 naxatw-leading-5 naxatw-text-matt-200"
                  iconClassName="naxatw-text-xl naxatw-text-matt-200"
                  type="material-icons"
                />
                <p className="naxatw-body-caption naxatw-text-matt-100">
                  {' '}
                  {date ? format(parseISO(date), 'MMMM d, yyyy') : '-'}
                </p>
              </div>

              {isPublic && (
                <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
                  <IconButton
                    name="lock"
                    className="!naxatw-h-5 !naxatw-w-5 naxatw-text-xl naxatw-leading-5 naxatw-text-matt-200"
                    iconClassName="naxatw-text-xl naxatw-text-matt-200"
                    type="material-icons"
                  />
                  <p className="naxatw-body-caption naxatw-text-matt-100">
                    Internal
                  </p>
                </div>
              )}
            </div>

            <FlexColumn className="naxatw-gap-3">
              {/* download */}
              {isEmpty(documents) ? null : (
                <FlexColumn className="naxatw-flex naxatw-w-full naxatw-gap-2">
                  <span className="naxatw-text-xs naxatw-font-bold naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                    Download:
                  </span>
                  <div className="naxatw-grid naxatw-grid-cols-1 naxatw-gap-2 sm:naxatw-grid-cols-2">
                    {repository?.documents &&
                      repository?.documents.map((doc: Record<string, any>) => {
                        const docName = getFileNameFromURL(doc.file)
                          .split('?')
                          .shift();
                        return (
                          <a
                            href={doc.file}
                            download
                            target="_blank"
                            rel="noreferrer"
                            key={doc.id}
                          >
                            <div className="naxatw-flex naxatw-items-center naxatw-gap-1 naxatw-overflow-hidden naxatw-rounded-[0.25rem] naxatw-bg-white naxatw-p-2 naxatw-shadow-[-1px_-1px_2px_0px_rgba(16,24,40,0.08),1px_1px_2px_0px_rgba(16,24,40,0.08)] hover:naxatw-shadow-[-2px_-2px_4px_0px_rgba(16,24,40,0.08),2px_2px_4px_0px_rgba(16,24,40,0.08)]">
                              <img
                                src={
                                  getFileLogo(
                                    getFileExtension(doc?.file)?.split(
                                      '?',
                                    )[0] || '-',
                                  ) || '-'
                                }
                                alt="file"
                                className="naxatw-h-[1.125rem] naxatw-w-[1.125rem]"
                              />
                              <div className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-justify-between naxatw-gap-1 naxatw-overflow-hidden">
                                <span className="naxatw-min-w-0 naxatw-truncate naxatw-text-xs naxatw-font-medium">
                                  {docName}
                                </span>
                                <Icon
                                  name="download"
                                  className="naxatw-flex naxatw-rounded-full naxatw-p-1 naxatw-text-[1.125rem] !naxatw-font-normal !naxatw-text-matt-100 hover:naxatw-bg-green-100"
                                />
                              </div>
                            </div>
                          </a>
                        );
                      })}
                  </div>
                </FlexColumn>
              )}

              {/* document links */}
              {dLink && (
                <div className="naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-truncate">
                  <Icon
                    name="link"
                    className="naxatw-text-xl naxatw-text-matt-100"
                  />
                  <a
                    href={dLink}
                    className="naxatw-body-caption naxatw-min-w-0 naxatw-max-w-[32rem] naxatw-truncate naxatw-text-matt-100 naxatw-underline hover:naxatw-text-matt-200"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {dLink}
                  </a>
                </div>
              )}
            </FlexColumn>
          </div>
          <div className="content-right">
            <div
              className={`top-details ${
                !isEmpty(thematicField) ||
                programs?.[0]
                  ?.knowledge_repository_program__associate_program__name
                  ? 'naxatw-mb-6 '
                  : ''
              } naxatw-flex naxatw-flex-col naxatw-gap-2`}
            >
              <div className="bottom-details naxatw-flex naxatw-flex-col naxatw-gap-6">
                <table className="naxatw-w-full naxatw-border-collapse">
                  <tbody>
                    {fileType && (
                      <TRow>
                        <TData className="naxatw-body-caption naxatw-font-bold !naxatw-leading-normal !naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                          Type:
                        </TData>
                        <TData className="naxatw-text-xs naxatw-text-matt-200">
                          {fileType}
                        </TData>
                      </TRow>
                    )}
                    {/* Program Row */}
                    {programs?.length > 0 && (
                      <TRow>
                        <TData className="naxatw-body-caption !naxatw-align-top naxatw-font-bold !naxatw-leading-normal !naxatw-tracking-[-0.015rem]  naxatw-text-matt-100">
                          Programme:
                        </TData>
                        <TData className="!naxatw-align-top">
                          <div className="naxatw-flex naxatw-flex-wrap naxatw-gap-2 ">
                            {programs?.map(
                              (programmeDetails: Record<string, any>) => (
                                <FlexColumn key={programmeDetails?.id}>
                                  <p className="naxatw-body-caption naxatw-font-bold !naxatw-leading-normal naxatw-text-matt-200">
                                    {programmeDetails?.associate_program__name}
                                  </p>
                                  <FlexRow className="naxatw-flex-wrap naxatw-items-center naxatw-leading-[1.2]">
                                    {programmeDetails?.projects?.map(
                                      (project: any, index: number) => {
                                        return (
                                          <div
                                            key={project?.id}
                                            className="naxatw-flex naxatw-items-center"
                                          >
                                            <span className="naxatw-body-caption naxatw-mr-[0.5rem] naxatw-font-medium !naxatw-leading-normal !naxatw-tracking-[-0.015rem] naxatw-text-matt-200 last:naxatw-mr-0">
                                              {project?.name}
                                            </span>
                                            {programmeDetails?.projects
                                              ?.length > 0 &&
                                              index <
                                                (programmeDetails?.projects
                                                  ?.length || 0) -
                                                  1 && (
                                                <span className="naxatw-pr-[0.5rem] naxatw-text-secondary-300">
                                                  |
                                                </span>
                                              )}
                                          </div>
                                        );
                                      },
                                    )}
                                  </FlexRow>
                                </FlexColumn>
                              ),
                            )}
                          </div>
                        </TData>
                      </TRow>
                    )}
                    {/* other-programme  */}
                    {otherProgramProject ? (
                      <TRow>
                        <TData className="naxatw-body-caption naxatw-font-bold naxatw-text-matt-100">
                          Other Programme:
                        </TData>
                        <TData className="naxatw-body-caption naxatw-font-bold naxatw-text-matt-200">
                          {otherProgramProject}
                        </TData>
                      </TRow>
                    ) : null}
                    {}
                    {/* Sector Row */}
                    {thematicField?.length > 0 && (
                      <TRow className="!naxatw-align-top">
                        <TData className="naxatw-body-caption naxatw-font-bold naxatw-text-matt-100">
                          Thematic Field:
                        </TData>
                        <TData className="!naxatw-align-top">
                          {thematicField?.length > 0 && (
                            <ul className="">
                              {thematicField?.map(
                                (sectorItem: Record<string, any>) => (
                                  <li
                                    key={sectorItem.id}
                                    className="naxatw-flex naxatw-gap-2 naxatw-pb-1 naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-100 last:naxatw-pb-0"
                                  >
                                    <i className="naxatw-mt-[0.4rem] naxatw-inline-block naxatw-h-1 naxatw-w-1 naxatw-rounded-full naxatw-bg-matt-100" />
                                    <span>
                                      {sectorItem.thematic_field__name}
                                    </span>
                                  </li>
                                ),
                              )}
                            </ul>
                          )}
                        </TData>
                      </TRow>
                    )}
                    {teamOwnership && (
                      <TRow>
                        <TData className="naxatw-body-caption naxatw-font-bold naxatw-text-matt-100">
                          Team Ownership:
                        </TData>
                        <TData className="naxatw-body-caption naxatw-font-bold naxatw-text-matt-200">
                          {teamOwnership}
                        </TData>
                      </TRow>
                    )}

                    {!isEmpty(geographicFocus) && (
                      <TRow>
                        <TData className="naxatw-body-caption naxatw-font-bold naxatw-text-matt-100">
                          Geographic Focus:
                        </TData>
                        <TData>
                          <div className="tags-container naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-[10px]">
                            {geographicFocus.map(
                              (focus: {
                                geographic_focus__name: string;
                                id: number;
                              }) => {
                                return (
                                  <div key={id}>
                                    <span className="naxatw-body-caption naxatw-text-matt-200">
                                      {focus.geographic_focus__name}
                                    </span>
                                    <div className="naxatw-h-3 naxatw-w-[.5px] [&:not(&:last-child)]:naxatw-bg-gray-400" />
                                  </div>
                                );
                              },
                            )}
                          </div>
                        </TData>
                      </TRow>
                    )}
                    {(!isEmpty(gesi) || !isEmpty(evidenceAgenda)) && (
                      <TRow>
                        <TData className="naxatw-body-caption naxatw-font-bold naxatw-text-matt-100">
                          Theme:
                        </TData>
                        <TData className="naxatw-body-caption naxatw-font-bold naxatw-text-matt-100">
                          <FlexColumn className="naxatw-gap-1">
                            {gesi && gesi?.length > 0 && (
                              <FlexRow className="naxatw-flex-wrap naxatw-items-center">
                                - GESI (
                                {gesi?.map(
                                  (gs: {
                                    gesi_option__name: string;
                                    id: number;
                                  }) => (
                                    <div key={id}>
                                      <span className="naxatw-body-caption naxatw-mx-1 naxatw-font-medium naxatw-text-matt-200">
                                        {gs?.gesi_option__name}
                                      </span>
                                      <span className="naxatw-inline-block naxatw-h-3 naxatw-w-[.5px] [&:not(&:last-child)]:naxatw-bg-gray-400" />
                                    </div>
                                  ),
                                )}
                                )
                              </FlexRow>
                            )}
                            {evidenceAgenda &&
                              evidenceAgenda?.map(
                                (agenda: {
                                  agenda_evidence__name: string;
                                  id: number;
                                }) => (
                                  <div
                                    key={agenda?.id}
                                    className="naxatw-text-matt-200"
                                  >
                                    - {agenda?.agenda_evidence__name}
                                  </div>
                                ),
                              )}
                          </FlexColumn>
                        </TData>
                      </TRow>
                    )}
                    {/* Province */}
                    {province ? (
                      <TRow>
                        <TData className="naxatw-body-caption naxatw-font-bold !naxatw-leading-normal !naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                          Province:
                        </TData>
                        <TData className="naxatw-text-xs naxatw-text-matt-200">
                          {province?.name}
                        </TData>{' '}
                      </TRow>
                    ) : null}

                    {/* Tag Row */}
                    {!isEmpty(allTags) && (
                      <TRow>
                        <TData className="naxatw-body-caption naxatw-font-bold naxatw-text-matt-100">
                          Tags:
                        </TData>
                        <TData>
                          <div className="tags-container naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-1">
                            {allTags.map((tag: string) => {
                              return (
                                <div
                                  className="naxatw-flex naxatw-items-center naxatw-justify-center naxatw-rounded-[1.25rem] naxatw-border naxatw-border-secondary-400 naxatw-px-2 naxatw-py-1 naxatw-capitalize"
                                  key={tag}
                                >
                                  <p className="naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
                                    {tag}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </TData>
                      </TRow>
                    )}

                    {username && (
                      <TRow>
                        <TData className="naxatw-body-caption naxatw-font-bold naxatw-text-matt-100">
                          Contact To:
                        </TData>
                        <TData className="naxatw-text-xs naxatw-text-matt-200">
                          {username}
                        </TData>
                      </TRow>
                    )}

                    {email && (
                      <TRow>
                        <TData className="naxatw-body-caption naxatw-font-bold naxatw-text-matt-100">
                          Email:
                        </TData>
                        <TData className="naxatw-text-xs naxatw-text-matt-200">
                          {email}
                        </TData>
                      </TRow>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isShareModalOpen && (
        <DocumentShareModal
          handleClose={() => setIsShareModalOpen(false)}
          id={id}
          isShareModalOpen={isShareModalOpen}
          isDocumentLinkOnlyPreset={isEmpty(documents || [])}
          knowledgeRepDoc={knoDocLink}
        />
      )}
    </>
  );
};

export default hasErrorBoundary(KnowledgeCard);
