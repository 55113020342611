/* eslint-disable no-unused-vars */
import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportBtn from '@Components/common/CommonExport/ExportHeader/ExportBtn';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { generateReportListStakeholder } from '@Services/report';
import {
  deleteIndividualList,
  deletePdfSection,
  resetPdfSection,
  setOriginalDataLists,
} from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import { transformStakeholders } from '@Utils/index';
import isEmpty from '@Utils/isEmpty';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const PdfStakeHolders = () => {
  const { programmeId, projectId } = useParams();
  const dispatch = useDispatch();

  const pageType = projectId ? 'projectDetails' : 'programmeDetails';

  const showNational = useTypedSelector(state =>
    projectId
      ? state.pdfExportSlice.projectDetails.nationalStakeHolder.showSection
      : state.pdfExportSlice.programmeDetails.nationalStakeHolder.showSection,
  );

  const showSubNational = useTypedSelector(state =>
    projectId
      ? state.pdfExportSlice.projectDetails.subNationalStakeHolder.showSection
      : state.pdfExportSlice.programmeDetails.subNationalStakeHolder
          .showSection,
  );

  const showNationalResetBtn = useTypedSelector(state =>
    projectId
      ? state.pdfExportSlice.projectDetails.nationalStakeHolder.showResetButton
      : state.pdfExportSlice.programmeDetails.nationalStakeHolder
          .showResetButton,
  );

  const showSubNationalResetBtn = useTypedSelector(state =>
    projectId
      ? state.pdfExportSlice.projectDetails.subNationalStakeHolder
          .showResetButton
      : state.pdfExportSlice.programmeDetails.subNationalStakeHolder
          .showResetButton,
  );

  const dataNationals: readonly string[] = useTypedSelector(state =>
    projectId
      ? state.pdfExportSlice.projectDetails.nationalStakeHolder.datalists
      : state.pdfExportSlice.programmeDetails.nationalStakeHolder.datalists,
  );

  const dataSubNationals: readonly string[] = useTypedSelector(state =>
    projectId
      ? state.pdfExportSlice.projectDetails.subNationalStakeHolder.datalists
      : state.pdfExportSlice.programmeDetails.subNationalStakeHolder.datalists,
  );

  const { data: stakeholderData } = useQuery({
    queryKey: ['stakeolderData', programmeId, projectId],
    queryFn: async () =>
      generateReportListStakeholder(
        projectId ? { project: projectId } : { program: programmeId },
      ),
    enabled: projectId ? !!projectId : !!programmeId,
    select: res => {
      if (!res?.data) return [];
      const transformedData = transformStakeholders(res.data);
      return transformedData;
    },
    onSuccess: resposne => {
      if (isEmpty(resposne)) return;
      const nationalLevel = resposne.filter(
        levelItem => levelItem.level === 'National Level',
      );
      const subNationalLevel = resposne.filter(
        levelItem => levelItem.level === 'Sub-National Level',
      );

      const finalNationalLevel = nationalLevel[0]?.content?.map(
        (levelItem: Record<string, any>) => ({
          id: levelItem.category,
        }),
      );

      const finalSubNationalLevel = subNationalLevel[0]?.content?.map(
        (levelItem: Record<string, any>) => ({
          id: levelItem.category,
        }),
      );

      if (finalNationalLevel) {
        dispatch(
          setOriginalDataLists({
            page: pageType,
            section: 'nationalStakeHolder',
            value: finalNationalLevel,
          }),
        );
      }

      if (finalSubNationalLevel) {
        dispatch(
          setOriginalDataLists({
            page: pageType,
            section: 'subNationalStakeHolder',
            value: finalSubNationalLevel,
          }),
        );
      }
    },
  });

  if (stakeholderData && isEmpty(stakeholderData)) return null;

  const isStakeholderVisible =
    (dataNationals && !isEmpty(dataNationals) && showNational) ||
    (dataSubNationals && !isEmpty(dataSubNationals) && showSubNational);

  const isSubNationalStakeHolderVisisble =
    dataSubNationals && !isEmpty(dataSubNationals) && showSubNational;

  return (
    <div
      className={`pdf-stakeholders-container ${isStakeholderVisible ? '' : '!naxatw-m-0'} `}
      style={{
        pageBreakInside: 'avoid',
      }}
    >
      {isStakeholderVisible ? (
        <ExportHeader
          headerTitle="Stakeholders"
          isStyleAbsolute={false}
          disableCloseBtn
        />
      ) : null}

      {stakeholderData?.find(stake => stake.level === 'National Level') &&
        showNational && (
          <div
            className={`show-national naxatw-relative ${isSubNationalStakeHolderVisisble ? 'naxatw-mb-4' : ''} `}
          >
            <ExportHeader
              headerTitle="National"
              isStyleAbsolute={false}
              showResetButton={showNationalResetBtn}
              onHandleDelete={() =>
                dispatch(
                  deletePdfSection({
                    page: pageType,
                    section: 'nationalStakeHolder',
                  }),
                )
              }
              onHandleReset={() =>
                dispatch(
                  resetPdfSection({
                    page: pageType,
                    section: 'nationalStakeHolder',
                  }),
                )
              }
            />
            <ExportRoundedContainer className="naxatw-p-3">
              {stakeholderData
                ?.find(stake => stake.level === 'National Level')
                ?.content?.map((stakeholder: Record<string, any>) => {
                  if (!dataNationals.includes(stakeholder.category))
                    return null;
                  return (
                    <div
                      key={stakeholder.id}
                      className="naxatw-group naxatw-relative"
                    >
                      <p className="fs-sm-medium naxatw-mb-[0.15rem] naxatw-text-royal-navy">
                        {stakeholder.category}
                      </p>

                      <div className="stakeholders-names-conatiner naxatw-mb-2 naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-[0.35rem]">
                        {stakeholder?.items?.[0]?.names?.map((name: string) => (
                          <div
                            key={name}
                            className="fs-sm-medium naxatw-border-r naxatw-border-r-pdf-border naxatw-pr-[0.35rem] last:naxatw-border-r-0 last:naxatw-pr-0"
                          >
                            {name}
                          </div>
                        ))}
                      </div>
                      <ExportBtn
                        size="sm"
                        className="!naxatw-right-0 !naxatw-top-0"
                        onHandleClose={() =>
                          dispatch(
                            deleteIndividualList({
                              page: pageType,
                              section: 'nationalStakeHolder',
                              key: 'datalists',
                              value: stakeholder.category,
                            }),
                          )
                        }
                      />
                    </div>
                  );
                })}
            </ExportRoundedContainer>
          </div>
        )}

      {showSubNational &&
      stakeholderData?.find(stake => stake.level === 'Sub-National Level') ? (
        <div className="subnational naxatw-relative">
          <ExportHeader
            headerTitle="Sub National"
            isStyleAbsolute={false}
            showResetButton={showSubNationalResetBtn}
            onHandleDelete={() =>
              dispatch(
                deletePdfSection({
                  page: pageType,
                  section: 'subNationalStakeHolder',
                }),
              )
            }
            onHandleReset={() =>
              dispatch(
                resetPdfSection({
                  page: pageType,
                  section: 'subNationalStakeHolder',
                }),
              )
            }
          />
          <ExportRoundedContainer className="naxatw-p-3">
            {stakeholderData
              ?.find(stake => stake.level === 'Sub-National Level')
              ?.content?.map((stakeholder: Record<string, any>) => {
                if (!dataSubNationals.includes(stakeholder.category))
                  return null;
                return (
                  <div
                    key={stakeholder.category}
                    className="naxatw-group naxatw-relative naxatw-border-b naxatw-border-b-pdf-border naxatw-pb-2 naxatw-pt-2 first:naxatw-pt-0 last:naxatw-border-b-0"
                  >
                    <p className="fs-sm-medium naxatw-mb-[0.35rem] naxatw-text-royal-navy">
                      {' '}
                      {stakeholder.category}
                    </p>
                    <div className="stakeholders-names-conatiner naxatw-flex naxatw-flex-col naxatw-flex-wrap  naxatw-gap-1">
                      {stakeholder?.items?.map(
                        (stkItem: Record<string, any>) => {
                          return (
                            <div
                              key={stkItem.province}
                              className="fs-sm-medium naxatw-grid naxatw-grid-cols-[1fr_2fr] naxatw-items-center naxatw-gap-4"
                            >
                              <p className="fs-sm-semibold naxatw-text-royal-navy">
                                {stkItem.province}
                              </p>
                              <div className="stk-holder-names naxatw-flex naxatw-items-center ">
                                {stkItem?.names?.map((stkName: string) => (
                                  <p
                                    key={stkName}
                                    className="naxatw-border-r naxatw-border-r-pdf-border naxatw-pl-[0.625rem] naxatw-pr-[0.65rem] first:naxatw-pl-0 last:naxatw-border-r-0 last:naxatw-pr-[0.65rem]"
                                  >
                                    {stkName}
                                  </p>
                                ))}
                              </div>
                            </div>
                          );
                        },
                      )}
                    </div>
                    <ExportBtn
                      size="sm"
                      className="!naxatw-right-0"
                      onHandleClose={() =>
                        dispatch(
                          deleteIndividualList({
                            page: pageType,
                            section: 'subNationalStakeHolder',
                            key: 'datalists',
                            value: stakeholder.category,
                          }),
                        )
                      }
                    />
                  </div>
                );
              })}
          </ExportRoundedContainer>
        </div>
      ) : null}
    </div>
  );
};

export default hasErrorBoundary(PdfStakeHolders);
