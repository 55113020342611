/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useTypedSelector } from '@Store/hooks';
import Checkbox from '@Components/common/FormUI/CheckBox';
import Icon from '@Components/common/Icon';
import { ChevronDown } from 'lucide-react';
import {
  // getFilterList,
  getFilterProgramList,
  getFilterPartnerList,
  getFilterSectorList,
  getFilterMarkerList,
  getFilterProvinceList,
} from '@Services/mainDashboard';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@Components/RadixComponents/Accordion';
import isEmpty from '@Utils/isEmpty';
import {
  postFilterParams,
  setMainDashboardStates,
  toggleFilterState,
  resetFilterState,
  setDateRangeState,
  setFilterCount,
  setStatusFilterState,
} from '@Store/actions/mainDashboard';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { convertFilterData } from '@Utils/convertFilterData';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import MultipleDatePicker from '@Components/common/FormUI/NativeDatePicker/MultiDatePicker';
import SwitchTab from '@Components/common/FormUI/SwitchTab';
import {
  mappedStatusParams,
  statusOptions,
  categories,
  excludeFilterItems,
  filColHeight,
  excludeALLmappedStatusParams,
  activeStatusOptions,
} from '@Constants/filters';
import KnowLibSk from '@Components/KnowledgeRepository/Filters/KnowLibSk';
import FilterSearchCategory from './FilterSearchCategory';

const Filters = () => {
  const dispatch = useDispatch();

  const isInitialFilterStateFetch = useRef(true);
  // const [filteredOptionsData, setFilteredOptionsData] = useState<any>([]);
  const [searchFilterTerm, setSearchFilterTerm] = useState('');
  const [searchPartner, setSearchPartner] = useState('');
  const [seacrhSector, setSearchSector] = useState('');
  const [searchMarker, setSearchMarker] = useState('');
  const [searchProvince, setSearchProvince] = useState('');
  const [programmeFilterCount, setProgrammeFilterCount] = useState(0);
  const [partnerFilterCount, setPartnerFilterCount] = useState(0);
  const [sectorFilterCount, setSectorFilterCount] = useState(0);
  const [markerFilterCount, setMarkerFilterCount] = useState(0);
  const [provinceFilterCount, setProvinceFilterCount] = useState(0);

  const [activeAccordion, setActiveAccordion] = useState(1);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const mappedFilterCount: Record<string, any> = {
    Programme: programmeFilterCount,
    'First Tier Partner': partnerFilterCount,
    Sector: sectorFilterCount,
    Marker: markerFilterCount,
    Province: provinceFilterCount,
  };

  const filterState = useTypedSelector(
    state => state.mainDashboard.filterState,
  );

  const startDate = useTypedSelector(
    state => state?.mainDashboard?.filterParams?.startDate,
  );
  const endDate = useTypedSelector(
    state => state?.mainDashboard?.filterParams?.endDate,
  );

  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const projectFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const provinceFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.province,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDateParams = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDateParams = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );

  const statusFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );

  const [searchFilter, handleFilterChange] = useDebouncedInput({
    ms: 400,
    init: searchFilterTerm,
    onChange: debouncedEvent =>
      setSearchFilterTerm(debouncedEvent.target.value.trim()),
  });

  const [searchPartnerTerm, handlePartnerChange] = useDebouncedInput({
    ms: 400,
    init: searchPartner,
    onChange: debouncedEvent => setSearchPartner(debouncedEvent.target.value),
  });

  const [searchSectorTerm, handleSectorChange] = useDebouncedInput({
    ms: 400,
    init: seacrhSector,
    onChange: debouncedEvent => setSearchSector(debouncedEvent.target.value),
  });

  const [searchProvinceTerm, handleProvinceChange] = useDebouncedInput({
    ms: 400,
    init: searchProvince,
    onChange: debouncedEvent => setSearchProvince(debouncedEvent.target.value),
  });

  const [searchMarkerTerm, handleMarkerChange] = useDebouncedInput({
    ms: 400,
    init: searchMarker,
    onChange: debouncedEvent => setSearchMarker(debouncedEvent.target.value),
  });

  function updateFilterState(fetchedState: Record<string, any>[]) {
    return filterState?.map(currentCategory => {
      const fetchedCategory = fetchedState.find(
        category => category.id === currentCategory.id,
      );

      if (!fetchedCategory) {
        return currentCategory;
      }

      const currentSubcategoryMap: Record<string, any> = {};

      currentCategory.subcategories?.forEach((subcategory: { id: string }) => {
        currentSubcategoryMap[subcategory.id] = subcategory;
      });

      const updatedSubcategories = fetchedCategory.subcategories
        .map((fetchedSubcategory: Record<string, any>) => {
          const existingSubcategory =
            currentSubcategoryMap[fetchedSubcategory.id];

          if (existingSubcategory) {
            return {
              ...existingSubcategory,
              filterItems: fetchedSubcategory.filterItems?.map(
                (fetchedFilter: Record<string, any>) => {
                  const existingFilter = existingSubcategory?.filterItems.find(
                    (filter: Record<string, any>) =>
                      filter.id === fetchedFilter.id,
                  );
                  return (
                    existingFilter || {
                      ...fetchedFilter,
                      checked: !!existingSubcategory.checked,
                    }
                  );
                },
              ),
            };
          }
          return { ...fetchedSubcategory };
        })
        ?.sort((a: Record<string, any>, b: Record<string, any>) => {
          return b.checked - a.checked;
        });

      return {
        ...currentCategory,
        subcategories: updatedSubcategories,
      };
    });
  }

  // check single filter count
  const checkSingleFilter = useCallback(
    (category: string) => {
      const result = filterState?.find(
        filterItem => filterItem.category === category,
      );
      const checkedCount =
        result?.subcategories.filter((i: any) => i.checked).length || 0;
      switch (category) {
        case 'Programme':
          setProgrammeFilterCount(checkedCount);
          break;
        case 'First Tier Partner':
          setPartnerFilterCount(checkedCount);
          break;
        case 'Sector':
          setSectorFilterCount(checkedCount);
          break;
        case 'Marker':
          setMarkerFilterCount(checkedCount);
          break;
        case 'Province':
          setProvinceFilterCount(checkedCount);
          break;
        default:
          break;
      }
    },
    [filterState],
  );

  const filterDateRange =
    startDateParams && endDateParams
      ? `${startDateParams}-${endDateParams}`
      : '';

  // api calls
  // Programme
  const { data: programData, isSuccess: isProgramSuccess } = useQuery({
    queryKey: [
      'get-filter-program-list',
      searchFilter,
      firstTierPartnerParams,
      sectorGroupParams,
      provinceFilterParams,
      markerGroupParams,
      // startDateParams,
      // endDateParams,
      filterDateRange,
      statusFilterParams,
    ],
    queryFn: () =>
      getFilterProgramList({
        program: searchFilter.trim(),
        first_tier_partner_id: firstTierPartnerParams?.join(','),
        sector_id: sectorGroupParams?.join(','),
        province: provinceFilterParams?.join(','),
        marker_id: markerGroupParams?.join(','),
        start_date: startDateParams,
        end_date: endDateParams,
        status: statusFilterParams,
      }),
    select: res => res?.data,
  });

  // Partner
  const { data: partnerData, isSuccess: isPartnerSuccess } = useQuery({
    queryKey: [
      'get-filter-partner-list',
      searchPartnerTerm,
      programmeFilterParams,
      projectFilterParams,
      sectorGroupParams,
      provinceFilterParams,
      markerGroupParams,
      filterDateRange,
      statusFilterParams,
    ],
    queryFn: () =>
      getFilterPartnerList({
        first_tier_partner: searchPartnerTerm.trim(),
        program_id: programmeFilterParams?.join(','),
        project_id: activeAccordion === 2 ? '' : projectFilterParams?.join(','),
        sector_id: sectorGroupParams?.join(','),
        province: provinceFilterParams?.join(','),
        marker_id: markerGroupParams?.join(','),
        start_date: startDateParams,
        end_date: endDateParams,
        status: statusFilterParams,
      }),
    select: res => res?.data,
  });

  // Sector
  const { data: sectorData, isSuccess: isSectorSuccess } = useQuery({
    queryKey: [
      'get-filter-sector-list',
      searchSectorTerm,
      programmeFilterParams,
      projectFilterParams,
      firstTierPartnerParams,
      markerGroupParams,
      filterDateRange,
      statusFilterParams,
      provinceFilterParams,
    ],
    queryFn: () =>
      getFilterSectorList({
        sector: searchSectorTerm.trim(),
        program_id: programmeFilterParams?.join(','),
        project_id: activeAccordion === 3 ? '' : projectFilterParams?.join(','),
        first_tier_partner_id: firstTierPartnerParams?.join(','),
        marker_id: markerGroupParams?.join(','),
        province: provinceFilterParams?.join(','),
        start_date: startDateParams,
        end_date: endDateParams,
        status: statusFilterParams,
      }),
    select: res => res?.data,
  });

  // Province
  const { data: provinceFilterData, isSuccess: isProvinceSuccess } = useQuery({
    queryKey: [
      'get-filter-province-list',
      searchProvinceTerm,
      programmeFilterParams,
      projectFilterParams,
      firstTierPartnerParams,
      markerGroupParams,
      filterDateRange,
      statusFilterParams,
    ],
    queryFn: () =>
      getFilterProvinceList({
        province: searchProvinceTerm.trim(),
        program_id: programmeFilterParams?.join(','),
        project_id: activeAccordion === 3 ? '' : projectFilterParams?.join(','),
        first_tier_partner_id: firstTierPartnerParams?.join(','),
        sector_id: sectorGroupParams?.join(','),
        marker_id: markerGroupParams?.join(','),
        start_date: startDateParams,
        end_date: endDateParams,
        status: statusFilterParams,
      }),
    select: res => res?.data,
  });
  // province data

  // Marker
  const { data: markerData, isSuccess: isMarkerSuccess } = useQuery({
    queryKey: [
      'get-filter-marker-list',
      searchMarkerTerm,
      programmeFilterParams,
      projectFilterParams,
      firstTierPartnerParams,
      sectorGroupParams,
      filterDateRange,
      statusFilterParams,
      `province-${provinceFilterParams}`,
    ],
    queryFn: () =>
      getFilterMarkerList({
        marker: searchMarkerTerm.trim(),
        program_id: programmeFilterParams?.join(','),
        project_id: activeAccordion === 4 ? '' : projectFilterParams?.join(','),
        first_tier_partner_id: firstTierPartnerParams?.join(','),
        sector_id: sectorGroupParams?.join(','),
        province: provinceFilterParams?.join(','),
        start_date: startDateParams,
        end_date: endDateParams,
        status: statusFilterParams,
      }),
    select: res => res?.data,
  });

  useEffect(() => {
    if (
      isProgramSuccess &&
      isPartnerSuccess &&
      isSectorSuccess &&
      isMarkerSuccess &&
      isProvinceSuccess
    ) {
      const responseObject = {
        program: [...programData],
        first_tier_partner: [...partnerData],
        sector: [...sectorData],
        marker: [...markerData],
        province: [...provinceFilterData],
      };

      const filterData = convertFilterData(responseObject);
      if (isInitialFilterStateFetch.current) {
        dispatch(setMainDashboardStates({ filterState: filterData }));
        isInitialFilterStateFetch.current = false;
        return;
      }
      const updatedFilter = updateFilterState(filterData);
      dispatch(setMainDashboardStates({ filterState: updatedFilter }));
    }
  }, [
    isProgramSuccess,
    isPartnerSuccess,
    isSectorSuccess,
    isMarkerSuccess,
    isProvinceSuccess,
    programData,
    partnerData,
    sectorData,
    markerData,
    provinceFilterData,
  ]);

  useEffect(() => {
    categories.forEach(category => checkSingleFilter(category));
  }, [filterState, checkSingleFilter]);

  useEffect(() => {
    if (isEmpty(filterState as [])) return;
    dispatch(setFilterCount());
    dispatch(postFilterParams());
  }, [filterState, dispatch, endDate]);

  // will reset the start and end date because `Knowledge Library` date filter is also depend on it
  useEffect(() => {
    return () => {
      dispatch(setDateRangeState({ type: 'startDate', date: '' }));
      dispatch(setDateRangeState({ type: 'endDate', date: '' }));
    };
  }, [dispatch]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(resetFilterState());
  //     dispatch(setStatusFilterState('Ongoing'));
  //   };
  // }, []);

  const activeTab = statusFilterParams
    ? excludeALLmappedStatusParams[statusFilterParams] || 'On-Going'
    : 'All';

  return (
    <div className="filter-col naxatw-h-full naxatw-min-w-[15rem] naxatw-rounded-[0.8rem] naxatw-border-[1.124px] naxatw-border-solid naxatw-border-primary-200 naxatw-bg-white naxatw-shadow-xs">
      {/* ------------------ filter header  --------------------> */}
      <div className="filter-header naxatw-flex naxatw-items-center naxatw-justify-between naxatw-border-b naxatw-border-solid naxatw-border-b-[#E0E0E0] naxatw-px-3 naxatw-py-2">
        <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
          <p className="naxatw-text-base naxatw-font-medium naxatw-tracking-[0.00625rem] naxatw-text-gray-800">
            All Filters
          </p>
        </div>

        <button
          type="button"
          className="naxatw-group naxatw-flex naxatw-items-center naxatw-gap-1 naxatw-px-4 naxatw-py-2"
          onClick={() => {
            dispatch(resetFilterState());
            dispatch(setDateRangeState({ type: 'clear' }));
            setShowDatePicker(false);
          }}
        >
          <Icon
            name="restart_alt"
            className="naxatw-text-secondary-500 naxatw-duration-300 group-hover:naxatw-text-primary-700"
          />

          <span className="naxatw-mb-1 naxatw-text-[0.875rem] naxatw-font-medium naxatw-leading-[0] naxatw-text-secondary-500 naxatw-duration-300 group-hover:naxatw-text-primary-700">
            Clear All
          </span>
        </button>
      </div>
      {/* ------------------ filter body  --------------------> */}

      <div
        className="filter-body scrollbar naxatw-overflow-y-scroll naxatw-px-4 naxatw-py-3"
        style={{
          maxHeight: `calc(100vh - ${filColHeight}px)`,
        }}
      >
        <div className="naxatw-mb-4 naxatw-flex naxatw-items-center naxatw-justify-between naxatw-gap-4">
          {!showDatePicker && (
            <SwitchTab
              options={statusOptions || []}
              activeLabel={activeTab}
              activeValue={activeTab}
              wrapperClassName="!naxatw-cursor-pointer !naxatw-gap-0  !naxatw-items-start"
              defaultBg={false}
              defaultBehaviour={false}
              className="naxatw-text-sm naxatw-leading-5 naxatw-text-[#484848]"
              titleClassName="naxatw-text-base naxatw-font-medium naxatw-tracking-[0.00625rem] naxatw-text-matt-100"
              onChange={(label: string) => {
                dispatch(setStatusFilterState(mappedStatusParams[label]));
                dispatch(setFilterCount());
              }}
            />
          )}
          {!activeStatusOptions.includes(statusFilterParams) && (
            <MultipleDatePicker
              startDate={startDate}
              setStartDate={(date: Date) =>
                dispatch(setDateRangeState({ type: 'startDate', date }))
              }
              endDate={endDate}
              setEndDate={(date: Date) =>
                dispatch(setDateRangeState({ type: 'endDate', date }))
              }
              onClearDate={() => {
                dispatch(setDateRangeState({ type: 'clear' }));
                setShowDatePicker(false);
              }}
              dateIcon="date_range"
              datePickerColor="!naxatw-bg-[#833177] hover:!naxatw-bg-[#833177]"
              showOnIconClick={!showDatePicker}
              onShowIconClick={() => setShowDatePicker(prev => !prev)}
            />
          )}
        </div>

        {filterState ? (
          <Accordion type="single" defaultValue="item-1" collapsible>
            {filterState?.map((filterData: Record<string, any>) => {
              return (
                <AccordionItem
                  value={`item-${filterData?.id}`}
                  key={`${filterData?.id}-id`}
                  className="naxatw-border-b-[#E0E0E0] naxatw-py-3 first:naxatw-pt-0"
                >
                  <AccordionTrigger
                    className="naxatw-h-[1.5rem] !naxatw-py-0 hover:!naxatw-no-underline"
                    onClick={() => setActiveAccordion(filterData.id)}
                  >
                    <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
                      <p className="naxatw-text-base naxatw-font-medium naxatw-leading-[0] naxatw-tracking-[0.00625rem] naxatw-text-matt-100">
                        {filterData?.category}
                      </p>
                      {mappedFilterCount[filterData?.category] >= 1 && (
                        <div className="naxatw-flex naxatw-h-5 naxatw-w-5 naxatw-items-center naxatw-justify-center naxatw-rounded-[100px] naxatw-bg-[#F1F1F1] naxatw-p-[1px]">
                          <p className="naxatw-tooltip !naxatw-text-matt-100">
                            {mappedFilterCount[filterData?.category]}
                          </p>
                        </div>
                      )}
                    </div>

                    <ChevronDown
                      className="naxatw-h-4 naxatw-w-4 naxatw-shrink-0 naxatw-transition-transform naxatw-duration-200"
                      style={{
                        strokeWidth: '3px',
                      }}
                    />
                  </AccordionTrigger>
                  <AccordionContent className="acc-content">
                    {filterData?.category === 'Programme' && (
                      <FilterSearchCategory
                        category={`Search ${filterData?.category}`}
                        value={searchFilter}
                        onChange={handleFilterChange}
                      />
                    )}
                    {filterData?.category === 'First Tier Partner' && (
                      <FilterSearchCategory
                        category={`Search ${filterData?.category}`}
                        value={searchPartnerTerm}
                        onChange={handlePartnerChange}
                      />
                    )}
                    {filterData?.category === 'Sector' && (
                      <FilterSearchCategory
                        category={`Search ${filterData?.category}`}
                        value={searchSectorTerm}
                        onChange={handleSectorChange}
                      />
                    )}
                    {filterData?.category === 'Marker' && (
                      <FilterSearchCategory
                        category={`Search ${filterData?.category}`}
                        value={searchMarkerTerm}
                        onChange={handleMarkerChange}
                      />
                    )}

                    {filterData?.category === 'Province' && (
                      <FilterSearchCategory
                        category={`Search ${filterData?.category}`}
                        value={searchProvinceTerm}
                        onChange={handleProvinceChange}
                      />
                    )}

                    <div className="subcategories-container scrollbar naxatw-max-h-[170px] naxatw-overflow-y-auto">
                      {filterData?.subcategories ? (
                        isEmpty(filterData.subcategories) ? (
                          <p className="naxatw-pt-2 naxatw-text-center naxatw-text-xs naxatw-text-matt-100">
                            No Data Found
                          </p>
                        ) : (
                          <Accordion
                            type="single"
                            collapsible
                            className="naxatw-mr-1"
                          >
                            {filterData?.subcategories?.map(
                              (subCatData: Record<string, any>) => (
                                <AccordionItem
                                  value={`sub-item-${subCatData?.id}`}
                                  key={subCatData?.id}
                                  className="!naxatw-border-none naxatw-py-[6px] naxatw-pl-3 first:naxatw-mt-2"
                                >
                                  <AccordionTrigger className="naxatw-justify-between !naxatw-py-0 hover:!naxatw-no-underline">
                                    <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
                                      <Checkbox
                                        className="filter-checkbox"
                                        checked={subCatData?.checked}
                                        onChange={() => {
                                          dispatch(
                                            toggleFilterState({
                                              categoryId:
                                                subCatData?.categoryId,
                                              subcategoryId: subCatData?.id,
                                            }),
                                          );
                                        }}
                                      />
                                      <p
                                        className="naxatw-line-clamp-1 naxatw-text-[0.875rem] naxatw-font-normal naxatw-leading-5 naxatw-text-matt-100"
                                        title={subCatData?.subcategory}
                                      >
                                        {subCatData?.subcategory}
                                      </p>
                                    </div>
                                    {excludeFilterItems?.includes(
                                      filterData.category,
                                    ) ? null : (
                                      <ChevronDown
                                        className="naxatw-h-4 naxatw-w-4 naxatw-shrink-0 naxatw-transition-transform naxatw-duration-200"
                                        style={{
                                          strokeWidth: '2px',
                                        }}
                                      />
                                    )}
                                  </AccordionTrigger>

                                  {!isEmpty(subCatData?.filterItems) ? (
                                    <AccordionContent className="naxatw-mt-2 naxatw-gap-1">
                                      {subCatData?.filterItems?.map(
                                        (filterItem: Record<string, any>) => {
                                          return (
                                            <div
                                              key={filterItem?.filter}
                                              className="naxatw-mb-1 naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-pl-[1.625rem] last:naxatw-mb-0"
                                            >
                                              <Checkbox
                                                className="filter-checkbox"
                                                checked={filterItem?.checked}
                                                onChange={() => {
                                                  dispatch(
                                                    toggleFilterState({
                                                      categoryId:
                                                        subCatData?.categoryId,
                                                      subcategoryId:
                                                        subCatData?.id,
                                                      filterId: filterItem?.id,
                                                    }),
                                                  );
                                                }}
                                              />
                                              <p
                                                className="naxatw-line-clamp-1"
                                                title={filterItem?.filter}
                                              >
                                                {filterItem?.filter}
                                              </p>
                                            </div>
                                          );
                                        },
                                      )}
                                    </AccordionContent>
                                  ) : null}
                                </AccordionItem>
                              ),
                            )}
                          </Accordion>
                        )
                      ) : null}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              );
            })}
          </Accordion>
        ) : (
          <KnowLibSk noOfRows={5} />
        )}
      </div>
    </div>
  );
};

export default hasErrorBoundary(Filters);
