/* eslint-disable no-unused-vars */
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import convertToCurrencySystem from '@Utils/convertToCurrencySystem';
import { cn } from '@Utils/index';
import { Select } from '@Components/common/FormUI';
import { setLegendActiveFilter } from '@Store/actions/mainDashboard';
import { useTypedDispatch } from '@Store/hooks';
import getRangeValueWithColorCode from '@Utils/choropleth';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { useEffect } from 'react';

const LegendWithFilter = ({
  className,
  data,
  filterOptions,
  datakey = 'count',
  selectedOption,
  isPdfExport,
}: {
  className?: string;
  data: Array<Record<string, any>>;
  filterOptions?: Array<Record<string, any>>;
  datakey?: string;
  selectedOption?: string;
  isPdfExport?: boolean;
}) => {
  const legends = getRangeValueWithColorCode(data, 'count');
  const dispatch = useTypedDispatch();

  // useEffect(() => {
  //   return () => {
  //     dispatch(
  //       setLegendActiveFilter({
  //         key: 'activeFilterOption',
  //         value: 'program',
  //       }),
  //     );
  //   };
  // }, [dispatch]);

  return (
    <FlexColumn
      gap={3}
      className={cn(
        `naxatw-absolute naxatw-bottom-5 naxatw-left-6 naxatw-z-10 naxatw-rounded-lg naxatw-border naxatw-border-solid  naxatw-border-[#D7D7D7] naxatw-bg-white naxatw-px-3 naxatw-py-2 naxatw-shadow-[0px_2px_8px_0px_rgba(16,24,40,0.08)] ${isPdfExport ? 'naxatw-w-[145px]' : ' naxatw-w-[175px]'}`,
        className,
      )}
    >
      <Select
        isPdfExport={isPdfExport}
        options={filterOptions || []}
        placeholder="Select"
        className="naxatw-border-b-[1.5px] naxatw-border-[#989898] hover:naxatw-border-[#eaeaea]"
        selectedOption={selectedOption}
        onChange={value => {
          dispatch(
            setLegendActiveFilter({
              key: 'activeFilterOption',
              value,
            }),
          );
        }}
      />

      <FlexColumn>
        <FlexRow gap={2} className="naxatw-mb-0.5">
          <div
            className="naxatw-h-6 naxatw-w-6"
            style={{
              backgroundColor: `#FFFFFF`,
              outline: '1px solid #E6E6E6',
            }}
          />
          <div className="naxatw-flex naxatw-items-center naxatw-justify-start naxatw-gap-1">
            <p className="naxatw-text-[0.625rem] naxatw-font-medium naxatw-uppercase naxatw-leading-3 naxatw-tracking-[0.03125rem] naxatw-text-matt-200">
              0
            </p>
          </div>
        </FlexRow>
        {legends.map((legend: Record<string, any>) => (
          <FlexRow
            gap={2}
            className="naxatw-items-center"
            key={`${legend.min}-${legend.max}`}
          >
            <div
              className="naxatw-h-6 naxatw-w-6"
              style={{
                backgroundColor: `${legend.color}`,
                outline: '1px solid #E6E6E6',
              }}
            />
            {legend?.hasGreaterThan ? (
              <div className="naxatw-flex naxatw-items-center naxatw-justify-start naxatw-gap-1">
                <>
                  <p>&gt;=</p>
                  <p className="naxatw-text-[0.625rem] naxatw-font-medium naxatw-uppercase naxatw-leading-3 naxatw-tracking-[0.03125rem] naxatw-text-matt-200">
                    {convertToCurrencySystem(legend?.min)}
                  </p>
                </>
              </div>
            ) : (
              <div className="naxatw-flex naxatw-items-center naxatw-justify-start naxatw-gap-1">
                <>
                  <p className="naxatw-text-[0.625rem] naxatw-font-medium naxatw-uppercase naxatw-leading-3 naxatw-tracking-[0.03125rem] naxatw-text-matt-200">
                    {convertToCurrencySystem(legend?.min)}
                  </p>
                  <p>-</p>
                  <p className="naxatw-text-[0.625rem] naxatw-font-medium naxatw-uppercase naxatw-leading-3 naxatw-tracking-[0.03125rem] naxatw-text-matt-200">
                    {convertToCurrencySystem(legend?.max)}
                  </p>
                </>
              </div>
            )}
          </FlexRow>
        ))}
      </FlexColumn>
    </FlexColumn>
  );
};

export default hasErrorBoundary(LegendWithFilter);
