interface IIconButtonProps {
  name: string;
  className?: string;
  iconClassName?: string;
  onClick?: () => void;
  disabled?: boolean;
  type?: string;
  size?: string;
}

function getSize(size: string): number {
  switch (size) {
    case 'xs':
      return 1.5;
    case 'sm':
      return 2;
    case 'md':
      return 2.5;
    case 'lg':
      return 3;
    default:
      return 2.25;
  }
}

export default function IconButton({
  name,
  className,
  iconClassName,
  onClick,
  disabled,
  type = 'material-icons-outlined',
  size = 'md',
}: IIconButtonProps) {
  return (
    <button
      type="button"
      className={`naxatw-flex  naxatw-items-center
        naxatw-justify-center  ${className}`}
      onClick={onClick}
      disabled={disabled}
      style={{
        height: `${getSize(size)}rem`,
        width: `${getSize(size)}rem`,
      }}
    >
      <i
        className={`${type} ${
          disabled ? '!naxatw-text-[#CED5DF]' : ''
        } ${iconClassName}`}
      >
        {name}
      </i>
    </button>
  );
}
