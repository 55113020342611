import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import EvidenceAgendaCard from '@Components/KnowledgeRepository/EvidenceAgendaCardSection/EvidenceAgendaCard';
import {
  getEvidenceAgendaFocusList,
  getEvidenceAgendaThemesList,
} from '@Services/publicKnowledgeRepository';
import { useQuery } from '@tanstack/react-query';
import { useTypedSelector } from '@Store/hooks';
import isEmpty from '@Utils/isEmpty';
import EvidenceCardSk from './EvidenceCardSk';

function EvidenceCards() {
  const programParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.program,
  );

  const projectParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.project,
  );

  const startDateParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.startDate,
  );

  const endDateParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.endDate,
  );

  const fileTypeParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.file_type,
  );

  const teamMemershipParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.teamOwnership,
  );

  const geographicFocusParams = useTypedSelector(
    state =>
      state.knowledgeRepository.evidenceAgendaFilterParams.geographicFocus,
  );

  const themeParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.themes,
  );

  const evidenceStatusParams = useTypedSelector(
    state =>
      state.knowledgeRepository.evidenceAgendaFilterParams.evidenceStatus,
  );

  const subCategoryParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.subCategory,
  );

  const categoryParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.category,
  );

  const commonQueryKey = [
    programParams,
    projectParams,
    startDateParams,
    endDateParams,
    fileTypeParams,
    teamMemershipParams,
    themeParams,
    evidenceStatusParams,
    geographicFocusParams,
    subCategoryParams,
    categoryParams,
  ];

  const { data: themesList, isLoading } = useQuery({
    queryKey: ['evidenceAgendaThemesList', ...commonQueryKey],
    queryFn: () =>
      getEvidenceAgendaThemesList({
        program: programParams?.join(','),
        project: projectParams?.join(','),
        start_date: startDateParams,
        end_date: endDateParams,
        file_type: fileTypeParams?.join(','),
        team_membership: teamMemershipParams?.join(','),
        geographic_focus: geographicFocusParams?.join(','),
        theme: themeParams
          ?.map((theme: Record<string, any>) => theme.id)
          .join(','),
        evidence_agenda_status: evidenceStatusParams?.join(','),
        category: categoryParams?.join(','),
        sub_category: subCategoryParams?.join(','),
      }),
    select: res => {
      if (!res?.data) return [];
      const cardLists = res.data;
      const finalData = cardLists.map((cardItem: Record<string, any>) => ({
        label:
          cardItem?.gesi_evidence_agenda__gesi_option__agenda_evidence_category__name ||
          cardItem?.knowledge_library_agenda_evidence__agenda_evidence__name,
        count: cardItem?.count,
      }));
      return finalData;
    },
  });

  const { data: focusList, isLoading: isFocusLoading } = useQuery({
    queryKey: ['evidenceAgendaFocusList', ...commonQueryKey],
    queryFn: () =>
      getEvidenceAgendaFocusList({
        program: programParams?.join(','),
        project: projectParams?.join(','),
        start_date: startDateParams,
        end_date: endDateParams,
        file_type: fileTypeParams?.join(','),
        team_membership: teamMemershipParams?.join(','),
        geographic_focus: geographicFocusParams?.join(','),
        theme: themeParams
          ?.map((theme: Record<string, any>) => theme.id)
          .join(','),
        evidence_agenda_status: evidenceStatusParams?.join(','),
        category: categoryParams?.join(','),
        sub_category: subCategoryParams?.join(','),
      }),
    select: res => {
      if (!res?.data) return [];
      const cardLists = res.data;
      const finalData = cardLists.map((cardItem: Record<string, any>) => ({
        label:
          cardItem?.geographic_focus_evidence_agenda__geographic_focus__name,
        count: cardItem?.count,
      }));
      return finalData;
    },
  });

  return (
    <div className="evidence-agenda-section naxatw-mb-5 naxatw-grid naxatw-gap-5 lg:naxatw-grid-cols-[2.5fr_1fr]">
      <div className="themes-container ">
        {themesList && !isEmpty(themesList) ? (
          <p className="naxatw-mb-3 naxatw-text-base naxatw-font-medium naxatw-text-grey-800">
            Themes
          </p>
        ) : null}

        <div className="naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-2">
          {isLoading ? (
            <EvidenceCardSk />
          ) : (
            themesList?.map((theme: Record<string, any>) => (
              <EvidenceAgendaCard
                key={theme.label}
                label={theme.label}
                value={theme.count}
              />
            ))
          )}
        </div>
      </div>

      <div className="focus-container ">
        {focusList && !isEmpty(focusList) ? (
          <p className="naxatw-mb-3 naxatw-text-base naxatw-font-medium naxatw-text-grey-800">
            Geographic Focus
          </p>
        ) : null}

        <div className="naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-2">
          {isFocusLoading ? (
            <EvidenceCardSk noOfRows={1} />
          ) : (
            focusList?.map((focus: Record<string, any>) => (
              <EvidenceAgendaCard
                key={focus.label}
                label={focus.label}
                value={focus.count}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}
export default hasErrorBoundary(EvidenceCards);
