import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import isEmpty from '@Utils/isEmpty';

const AgreementModalityTable = ({
  tableData,
}: {
  tableData: Record<string, any>;
}) => {
  return (
    <div className="agreement-table-data scrollbar naxatw-overflow-y-auto naxatw-p-5 lg:naxatw-h-[calc(100%-47px)]">
      {isEmpty(tableData) ? (
        <NoChartDataComponent />
      ) : (
        <div className="table-content naxatw-grid naxatw-gap-5 lg:naxatw-grid-cols-2 2xl:naxatw-grid-cols-4">
          {tableData?.map((tableItem: Record<string, any>) => {
            return (
              <div
                className="table-item-container"
                key={tableItem.agreement_modality}
              >
                <p className="heading naxatw-mb-1 naxatw-border-b naxatw-border-b-[#E3E3E3] naxatw-pb-1 naxatw-text-[0.75rem] naxatw-font-bold naxatw-tracking-[-0.0175rem] naxatw-text-secondary-500">
                  {tableItem?.aggrement_modality}{' '}
                  {tableItem?.count ? (
                    <span>{`(${tableItem.count})`}</span>
                  ) : null}
                </p>
                <div>
                  {tableItem?.projects?.map((project: string) => (
                    <p
                      key={project}
                      className="naxatw-py-1 naxatw-text-[0.75rem] naxatw-leading-normal naxatw-text-[#484848] odd:naxatw-bg-primary-200"
                    >
                      {project}
                    </p>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default hasErrorBoundary(AgreementModalityTable);
