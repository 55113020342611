import ChartRenderer from '@Components/common/Charts/ChartRenderer';
import { setChartStates } from '@Store/actions/mainDashboard';
import { useTypedDispatch } from '@Store/hooks';
import { useState } from 'react';
import ChartDataSummary from '../ChartDataSummary';

interface IChartContainer {
  chartList: any[];
}

const active = 'naxatw-bg-[#1C66A4] naxatw-text-white';

const getIconByChart = (type: string) => {
  switch (type) {
    case 'treeMap':
      return 'dashboard';
    case 'bar':
      return 'bar_chart';
    case 'pieChart':
      return 'pie_chart';
    case 'verticalBar':
      return 'align_horizontal_left';
    case 'donut':
      return 'donut_large';
    case 'summary':
      return 'description';
    default:
      return 'align_horizontal_left';
  }
};

const ChartContainer = ({ chartList }: IChartContainer) => {
  const [onViewChartIndex, setOnViewChartIndex] = useState(0);
  const dispatch = useTypedDispatch();

  return (
    <div className="naxatw-flex naxatw-h-full naxatw-w-full naxatw-rounded-lg naxatw-border naxatw-bg-white">
      <div className="naxatw-h-full naxatw-w-[350px] naxatw-border-r naxatw-px-4">
        <h3 className="naxatw-mb-1 naxatw-py-5 naxatw-text-lg naxatw-font-bold naxatw-leading-5 naxatw-text-gray-500">
          Charts Overview
        </h3>
        <div className="naxatw-flex naxatw-flex-col naxatw-gap-2">
          {chartList?.map((chart, index: number) => (
            <button
              type="button"
              key={chart?.id}
              className={`naxatw-flex naxatw-cursor-pointer naxatw-items-center  naxatw-gap-2 naxatw-rounded-lg  naxatw-p-2 hover:naxatw-bg-[#1C66A4] hover:naxatw-text-white ${index === onViewChartIndex ? active : ''}`}
              onClick={() => {
                // close if any chart legend detail is opned
                dispatch(
                  setChartStates({ key: 'showLegendDetails', value: false }),
                );
                setOnViewChartIndex(index);
              }}
            >
              <span className="material-icons naxatw-text-xl">
                {getIconByChart(chart?.chartType)}
              </span>
              <div className="naxatw-text-left naxatw-text-xs naxatw-font-normal">
                {
                  // if we want chart title dynamic but don't want to reflect on sidebar
                  // use sidebar_title prop to preceding over title
                  chart?.sidebarTitle || chart?.title
                }
              </div>
            </button>
          ))}
        </div>
      </div>
      <div className="naxatw-w-full">
        {onViewChartIndex === 0 ? (
          <ChartDataSummary />
        ) : (
          chartList?.[onViewChartIndex] && (
            <ChartRenderer
              data={chartList?.[onViewChartIndex]}
              key={chartList?.[onViewChartIndex]?.id}
            />
          )
        )}
      </div>
    </div>
  );
};

export default ChartContainer;
