/* eslint-disable import/prefer-default-export */
import { FormFieldProps } from '@Constants/interface/FormInterface';

export const contactsFormFields: FormFieldProps[] = [
  {
    label: 'Name',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Name',
    id: 'name',
    name: 'name',
    required: true,
  },
  {
    label: 'Office',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Office',
    id: 'office',
    name: 'office',
  },
  {
    label: 'Designation',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Designation',
    id: 'designation',
    name: 'designation',
  },
  {
    label: 'Contact',
    inputType: 'number',
    type: 'input',
    placeholder: 'Enter Contact',
    id: 'phone',
    name: 'phone',
  },
  {
    label: 'Email',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Email',
    id: 'email',
    name: 'email',
  },
  {
    label: 'Type',
    inputType: 'select',
    type: 'select',
    placeholder: 'Choose type',
    id: 'federal_type',
    name: 'federal_type',
    choose: 'value',
    required: true,
  },
  {
    label: 'Province',
    inputType: 'select',
    type: 'select',
    placeholder: 'Choose province',
    id: 'province',
    name: 'province',
    required: true,
  },
  {
    label: 'Remarks',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter Remarks',
    id: 'remarks',
    name: 'remarks',
  },
];
