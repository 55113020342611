/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import ExportHeading from '@Components/common/CommonExport/ExportHeading';
import ExportLoader from '@Components/common/CommonExport/ExportLoader';
import ExportLogo from '@Components/common/CommonExport/ExportLogo';
import ExportSidebar from '@Components/common/CommonExport/ExportSidebar';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import usePrint from '@Hooks/usePrint';
import {
  getComponentDetailCount,
  getProvincialCountData,
} from '@Services/provincialProfile';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { resetOverallPage } from '@Store/actions/pdfExport';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import isEmpty from '@Utils/isEmpty';
import { getProgramProjectDetails } from '@Services/program';
import PdfCampaignGoals from '../ExportProgrammePdf/PdfCampaignGoals';
import PdfGroups from '../ExportProgrammePdf/PdfGroups';
import PdfSectors from '../ExportProgrammePdf/PdfSectors';
import PdfMarkers from '../ExportProgrammePdf/PdfMarkers';
import PdfProjectSummary from './PdfProjectSummary';
import PdfStakeholders from '../ExportProgrammePdf/PdfStakeholders';
import PdfWorkingAreas from '../ExportProgrammePdf/PdfWorkingAreas';
import PdfStakeholderChart from '../ExportProgrammePdf/PdfStakeholderChart';
import PdfMap from '../ExportProgrammePdf/PdfMap';

const ExportProjectPdf = () => {
  const { programmeId, projectId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const previousRoute = location.state?.from;

  const showSummary = useTypedSelector(
    state => state.pdfExportSlice.projectDetails.summary.showSection,
  );

  const showGoals = useTypedSelector(
    state => state.pdfExportSlice.projectDetails.goals.showSection,
  );

  const showGroups = useTypedSelector(
    state => state.pdfExportSlice.projectDetails.groups.showSection,
  );

  const showSectors = useTypedSelector(
    state => state.pdfExportSlice.projectDetails.sectors.showSection,
  );

  const showMarkers = useTypedSelector(
    state => state.pdfExportSlice.projectDetails.markers.showSection,
  );

  const showWorkingAreas = useTypedSelector(
    state => state.pdfExportSlice.projectDetails.workingAreas.showSection,
  );

  const showStakeHolderChart = useTypedSelector(
    state => state.pdfExportSlice.projectDetails.stakeholderChart.showSection,
  );

  const showMap = useTypedSelector(
    state => state.pdfExportSlice.projectDetails.map.showSection,
  );

  const { data: projectData, isLoading: isProjectDataLoading } = useQuery({
    queryKey: ['projectList-pdf', programmeId, projectId],
    queryFn: async () =>
      getComponentDetailCount({
        program: programmeId,
        project: projectId,
      }),
    enabled: !!projectId,
    select: res => {
      if (!res?.data) return [];
      return res.data[0];
    },
  });

  const { pdfContentRef, reactToPrintFn } = usePrint({
    title: projectData && `${projectData?.project_name || '-'}`,
  });

  const { data: projectCount, isLoading: isProjectCountLoading } = useQuery({
    queryKey: ['projectCount-pdf', programmeId, projectId],
    queryFn: async () =>
      getProvincialCountData({
        program: programmeId,
        project: projectId,
      }),
    select: res => {
      if (!res?.data) return [];
      return res.data;
    },
  });

  const { data: projectDetails, isLoading: isProjectDetailsLoading } = useQuery(
    {
      queryKey: ['project-project-details-report', projectId],
      queryFn: async () => getProgramProjectDetails({ project: projectId }),
      enabled: !!projectId,
      select: res => {
        if (!res?.data[0]) return [];
        return res.data[0];
      },
    },
  );

  const overallIsLoading =
    isProjectDataLoading || isProjectCountLoading || isProjectDetailsLoading;

  const showResetButton = Object.entries(
    useTypedSelector(state => state.pdfExportSlice.projectDetails),
  )?.some(([_, secValue]) => !secValue.showSection);

  // cleanup function
  useEffect(() => {
    return () => {
      dispatch(
        resetOverallPage({
          page: 'projectDetails',
        }),
      );
    };
  }, []);

  return (
    <div className="project-pdf-wrapper">
      <ExportSidebar
        heading={projectData?.project_name || '-'}
        reactToPrintFn={reactToPrintFn}
        onHandleBack={() =>
          navigate(
            previousRoute === 'reports-page'
              ? '/data-bank/report'
              : `/explore-by/programmes/${programmeId}/?componentId=${projectId}`,
          )
        }
        onHandleReset={() =>
          dispatch(
            resetOverallPage({
              page: 'projectDetails',
            }),
          )
        }
        showPageResetButton={showResetButton}
      />
      <div className="pdf-content-wrapper scrollbar naxatw-h-[calc(100vh-143px)] naxatw-overflow-y-auto">
        {/* ************ Main Pdf Content Starts **************** */}
        <div
          className="naxatw-relative naxatw-mx-auto naxatw-mb-16 naxatw-w-[210mm] naxatw-bg-white"
          id="print-content"
        >
          {overallIsLoading ? (
            <ExportLoader />
          ) : (
            <div
              className="pdf-content-container naxatw-flex naxatw-flex-col naxatw-space-y-5 naxatw-px-5 naxatw-pb-5 !naxatw-text-[#000]"
              ref={pdfContentRef}
              id="print-content"
            >
              <ExportLogo />
              <ExportHeading headerTitle={projectData?.project_name} />

              {showSummary && (
                <PdfProjectSummary
                  projectCount={projectCount || []}
                  projectDetailsData={projectDetails || []}
                />
              )}

              {(showGroups || showGoals) && (
                <div className="naxatw-grid naxatw-grid-flow-col naxatw-gap-2">
                  {showGoals && <PdfCampaignGoals />}
                  {showGroups && <PdfGroups />}
                </div>
              )}

              {showSectors && <PdfSectors />}
              {showMarkers && <PdfMarkers />}
              <PdfStakeholders />

              {showMap && <PdfMap />}

              {showStakeHolderChart && <PdfStakeholderChart />}
              {showWorkingAreas && <PdfWorkingAreas />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default hasErrorBoundary(ExportProjectPdf);
