import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { getMarkers } from '@Services/program';
import SegmentCard from '../Cards/Segment';
import MarkersCard from './card';

type Marker = {
  marker_category: string;
  marker_value: string[];
};

function Loading({
  height = '3rem',
  loadingCount,
}: {
  height?: string;
  loadingCount?: number;
}) {
  return (
    <>
      {Array(loadingCount || 4)
        .fill(0)
        .map((_, idx) => (
          <Skeleton
            // eslint-disable-next-line
            key={idx}
            className={`naxatw-h-[${height}] naxatw-w-full naxatw-rounded-lg`}
          />
        ))}
    </>
  );
}

function Markers({
  showGroupMakersOnly = false,
}: {
  showGroupMakersOnly?: boolean;
}) {
  const [searchParams] = useSearchParams();

  const { programmeId } = useParams();
  const componentId = searchParams.get('componentId');
  const { data, isLoading } = useQuery<
    any,
    any,
    { othersMarker: Marker[]; groupMarker: string[] },
    any
  >({
    queryKey: ['markers', programmeId, componentId],
    queryFn: () =>
      getMarkers({
        [componentId ? 'project' : 'program']: componentId || programmeId,
      }),
    select: res => {
      // split markers into two groups (group markers and other markers)
      const markers: {
        othersMarker: Marker[];
        groupMarker: Marker['marker_value'];
      } = { othersMarker: [], groupMarker: [] };

      return (
        res.data?.reduce((acc: typeof markers, marker: Marker) => {
          return marker?.marker_category?.toLowerCase() === 'group marker'
            ? {
                ...acc,
                groupMarker: acc.groupMarker.concat(marker?.marker_value || []),
              }
            : { ...acc, othersMarker: acc?.othersMarker?.concat(marker) };
        }, markers) || markers
      );
    },
  });

  return (
    <>
      {!showGroupMakersOnly && (
        <SegmentCard title="Markers" iconName="donut_small">
          {isLoading ? (
            <div className="naxatw-grid naxatw-grid-cols-2">
              <Loading />
            </div>
          ) : (
            <div className="naxatw-grid naxatw-w-full naxatw-grid-cols-1 naxatw-gap-3 sm:naxatw-grid-cols-2 md:naxatw-grid-cols-3 lg:naxatw-grid-cols-4">
              {data?.othersMarker.length === 0 ? (
                <div className="naxatw-col-span-4 naxatw-py-6">
                  <NoChartDataComponent />
                </div>
              ) : (
                data?.othersMarker?.map(marker => (
                  <MarkersCard
                    markers={marker?.marker_value}
                    markerName={marker?.marker_category}
                    key={uuidv4()}
                    className="naxatw-min-w-[30%]"
                  />
                ))
              )}
            </div>
          )}
        </SegmentCard>
      )}

      {showGroupMakersOnly && (
        <SegmentCard
          title="Groups"
          iconName="donut_small"
          containerClassName="naxatw-h-full naxatw-max-h-[14.3rem] naxatw-overflow-hidden naxatw-relative"
        >
          {isLoading ? (
            <div className="naxatw-flex naxatw-flex-wrap naxatw-gap-2">
              <Loading loadingCount={3} />
            </div>
          ) : (
            <div className="scrollbar naxatw-h-[calc(14.3rem-4rem)] naxatw-w-full naxatw-gap-2 naxatw-overflow-auto">
              {data?.groupMarker?.length === 0 ? (
                <NoChartDataComponent
                  messageStyles="naxatw-text-base"
                  className="[&>div>img]:naxatw-w-40"
                />
              ) : (
                data?.groupMarker?.map(marker => (
                  <div
                    className="naxatw-body-sm naxatw-flex naxatw-gap-2 naxatw-py-1 naxatw-text-matt-200"
                    key={uuidv4()}
                  >
                    -<span>{marker}</span>
                  </div>
                ))
              )}
            </div>
          )}
        </SegmentCard>
      )}
    </>
  );
}
export default hasErrorBoundary(Markers);
