import React from 'react';
import { DayPicker } from 'react-day-picker';
import { datePickerStyles } from '@Constants/datepicker';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

/**
 * This is a TypeScript React component that renders a calendar using DayPicker library with
 * customizable styling.
 * @param {any}  - - `selected`: the currently selected date(s)
 * @returns A React functional component named "Calendar" is being returned. It takes in several props
 * including "selected", "className", " ", "onSelect", and "props". It renders a DayPicker
 * component with various classNames and props passed down as well.
 */

function Calendar({
  selected,
  className,
  classNames,
  onSelect,
  disabledDays,
  customStyles,
  ...props
}: any) {
  return (
    <DayPicker
      mode="single"
      selected={selected}
      captionLayout="dropdown"
      fromYear={new Date().getFullYear() - 13}
      toYear={new Date().getFullYear() + 11}
      onSelect={onSelect}
      className="naxatw-w-full naxatw-rounded-lg  !naxatw-bg-white"
      classNames={{ ...datePickerStyles, ...classNames }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
