import CustomDonutChart from '@Components/common/Charts/DonutChart';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import ReportChartLegend from '@Components/common/CommonExport/ReportChartLegend';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { mapBudgetExpenditureColors } from '@Constants/mainDashboard';
import { getTopComponentProgram } from '@Services/mainDashboard';
import { deletePdfSection } from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import isEmpty from '@Utils/isEmpty';
import { useDispatch } from 'react-redux';

const ReportFiveProgramme = () => {
  const dispatch = useDispatch();
  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const projectFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );

  const subMarkersParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );

  const statusFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );

  const provinceFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.province,
  );

  const { data: chartData } = useQuery({
    queryKey: ['top-five-programme-budget-report'],
    queryFn: () =>
      getTopComponentProgram({
        program: programmeFilterParams?.join(','),
        project: projectFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        start_date: startDate,
        end_date: endDate,
        status: statusFilterParams,
        province: provinceFilterParams?.join(','),
      }),
    select: (res: any) => {
      if (!res?.data) return [];

      const response = res.data;
      if (isEmpty(response)) return [];

      return response.map((programme: Record<string, any>) => ({
        name: programme?.name,
        value: programme?.total_budget,
        percentage: programme?.percentage,
      }));
    },
  });

  return (
    <ExportRoundedContainer
      style={{
        pageBreakInside: 'avoid',
      }}
    >
      <ExportHeader
        headerTitle="Top 5 Programmes by Budget"
        isStyleAbsolute={false}
        className="!naxatw-border-b naxatw-border-b-pdf-border !naxatw-p-2"
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: 'bek',
              section: 'fiveProgrammes',
            }),
          )
        }
      />
      <div className="naxatw-p-3">
        {chartData && isEmpty(chartData) ? (
          <NoChartDataComponent
            isExport
            className="!naxatw-h-[280px]"
            iconClassName="naxatw-h-[50%]"
          />
        ) : (
          <div className="naxatw-grid naxatw-grid-cols-[1.5fr_1fr]">
            <CustomDonutChart
              data={chartData || []}
              showPound
              fills={Object.values(mapBudgetExpenditureColors)}
            />

            <ReportChartLegend
              data={chartData || []}
              fills={Object.values(mapBudgetExpenditureColors)}
              convertCurrency
              className=" !naxatw-content-center"
            />
          </div>
        )}
      </div>
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(ReportFiveProgramme);
