import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSpring, animated } from '@react-spring/web';
// import ProgrammeTable from '@Components/ExploreByProgramme/Table';
import ProgrammeTable from '@Components/ExploreByProgramme/Table/ProgrammeTable';

import { Button } from '@Components/RadixComponents/Button';
import { downloadTableData } from '@Services/provincialProfile';
import { useTypedSelector } from '@Store/hooks';
import Searchbar from '@Components/common/SearchBar';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import ToolTip from '@Components/RadixComponents/ToolTip';

export default function TableSection() {
  const [searchTerm, setSearchTerm] = useState('');

  // const showFilterColumn = useTypedSelector(
  //   state => state.mainDashboard.showFilterColumn,
  // );

  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const componentFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const provinceFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.province,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );

  const subMarkersParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );
  const statusFilterParmas = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  // download table
  const { refetch: downloadTableDetails } = useQuery({
    queryKey: ['programme-details-table-data'],
    queryFn: async () =>
      downloadTableData({
        is_export: true,
        program: programmeFilterParams?.join(','),
        project: componentFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        province: provinceFilterParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        start_date: startDate,
        end_date: endDate,
        status: statusFilterParmas,
      }),
    enabled: false,
    onSuccess: response => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(response.data);
      a.download = `tableData.csv`;
      a.click();
    },
  });

  const [searchProgramme, setSearchProgramme] = useDebouncedInput({
    ms: 600,
    init: searchTerm,
    onChange: debouncedEvent =>
      setSearchTerm(debouncedEvent.target.value.trim()),
  });

  return (
    <animated.div
      style={{ ...springs }}
      className="table-wrapper naxatw-h-full naxatw-rounded-[0.84275rem] naxatw-border-[1.124px] naxatw-border-solid naxatw-border-grey-200 naxatw-bg-white naxatw-shadow-xs"
    >
      <div className="table-header naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-border-b naxatw-border-b-[#EAECF0] naxatw-px-6 naxatw-py-[1.4rem]">
        <p className="naxatw-mb-2  naxatw-flex-1 naxatw-text-[1.5rem] naxatw-font-medium naxatw-leading-[1.75rem] naxatw-tracking-[-0.045rem] naxatw-text-[#0B2E62] md:naxatw-mb-0">
          Programme Details
        </p>

        <div className="search-bar-download-container naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-2  md:naxatw-flex-nowrap md:naxatw-gap-4">
          <Searchbar
            value={searchProgramme}
            onChange={setSearchProgramme}
            className="md:naxatw-w-[16.5rem]"
          />
          <Button
            variant="secondary"
            onClick={() => {
              downloadTableDetails();
            }}
            className="naxatw-group naxatw-relative naxatw-w-full"
          >
            <ToolTip
              name="download"
              message="Download Table Data"
              className="naxatw-absolute naxatw-inset-0 naxatw-flex naxatw-h-full naxatw-w-full naxatw-items-center naxatw-justify-center !naxatw-text-xl naxatw-text-matt-100 naxatw-duration-200 group-hover:naxatw-text-secondary-500"
              messageStyle="naxatw-font-normal"
            />
          </Button>
        </div>
      </div>

      <div className="table-content scrollbar naxatw-overflow-x-auto lg:naxatw-h-[calc(100%-5.875rem)]">
        <ProgrammeTable searchTerm={searchTerm} applyGlobalFilter />
      </div>
    </animated.div>
  );
}
