import { api, authenticated } from '.';

type Params = Record<string, any>;

const Path = '/federal-info-update/';

export function getFederalInfoUpdates(params?: Params) {
  return authenticated(api).get(Path, { params });
}

export function getFederalInfoUpdateById(id: string | number) {
  return authenticated(api).get(`${Path}${id}/`);
}

export function getChoiceTypes(params: Params) {
  return authenticated(api).get('/choice/', {
    params,
  });
}

export function postFederalInfoUpdate(data: Record<string, any>) {
  return authenticated(api).post(Path, data);
}

export function patchFederalInfoUpdate(
  slugId: number,
  data: Record<string, any>,
) {
  return authenticated(api).patch(`${Path}${slugId}/`, data);
}

export function deleteFederalInfoUpdateById(id: string) {
  return authenticated(api).delete(`${Path}${id}/`);
}

export function getUpdateList(params: Record<string, any>) {
  return authenticated(api).get('/federal-info-update/', { params });
}
export function getUpdateById(id: number) {
  return authenticated(api).get(`/federal-info-update/${id}/`);
}

export function shareUpdateInfo(payload: Record<string, any>) {
  return authenticated(api).post('/share-update/', payload);
}
