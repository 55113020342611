/* eslint-disable react/no-unstable-nested-components */
import { useParams } from 'react-router-dom';
import DataTable from '@Components/common/DataTable';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { getFederalMuncipalityDetailList } from '@Services/provincialProfile';
import { useTypedSelector } from '@Store/hooks';

function Table() {
  const { provinceId } = useParams();
  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);

  const columns = [
    {
      header: 'PROGRAMME',
      accessorKey: 'program_name',
    },
    {
      header: 'PALIKA',
      accessorKey: 'name',
    },
    {
      header: 'PROJECT',
      accessorKey: 'project_name',
    },
  ];

  return (
    <div className="naxatw-mt-20 naxatw-h-[calc(100%-6rem)] naxatw-px-5">
      <DataTable
        isPaginated={false}
        columns={columns}
        queryKey="federal-municipality-project"
        queryFn={getFederalMuncipalityDetailList}
        queryFnParams={{
          by: 'municipality',
          province: provinceId,
          status: filterBy,
        }}
        initialState={{}}
        searchInput=""
        className="naxatw-h-full naxatw-border-0 [&>table>thead>tr>th]:!naxatw-bg-white [&>table>thead]:!naxatw-shadow-xs"
      />
    </div>
  );
}

export default hasErrorBoundary(Table);
