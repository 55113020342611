import ExportButtons from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import MapSection from '@Components/ProvincialProfile/MapSection';
import { mapPdfMapCount } from '@Constants/PdfContent';
import { deletePdfSection } from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useDispatch } from 'react-redux';

const PdfMap = ({ provinceData }: { provinceData: Record<string, any> }) => {
  const dispatch = useDispatch();
  const activeFilterOption = useTypedSelector(
    state => state.mainDashboard.map.activeFilterOption,
  );

  return (
    <div className="pdf-map-container naxatw-relative">
      <ExportButtons
        headerTitle={`${mapPdfMapCount[activeFilterOption]} count by Palikas`}
        isStyleAbsolute={false}
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({ page: 'provincialProfile', section: 'map' }),
          )
        }
      />
      <ExportRoundedContainer>
        <MapSection
          lat={provinceData?.centroid_lat}
          lon={provinceData?.centroid_lon}
          isPdfExport
          className="!naxatw-h-[30rem]"
        />
      </ExportRoundedContainer>
    </div>
  );
};

export default hasErrorBoundary(PdfMap);
