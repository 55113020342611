/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { HtmlHTMLAttributes, ReactNode, useEffect, useRef } from 'react';
import { cn } from '@Utils/index';
import RoundedContainer from '@Components/common/RoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { useTypedSelector } from '@Store/hooks';
import {
  setChartStates,
  setNestedChartStates,
} from '@Store/actions/mainDashboard';
import isEmpty from '@Utils/isEmpty';
import IconButton from '@Components/common/IconButton';
import { useDispatch } from 'react-redux';
import { FlexColumn } from '@Components/common/Layouts';
import getChartOfType from '../utils/getChartOfType';
import getChartFillOfType from '../utils/getChartFillOfType';
import { IChartHeaderProps, ISwitchTabDataProps } from '../ChartHeader';
import { ChartTypes } from '../types';
import { ChartFills } from '../constants';
import NoChartDataComponent from '../NoChartDataComponent';
import { IResankyChartProps } from '../SankeyChart';
import NestedChartContentHeader from '../ChartHeader/NestedChartContentHeader';
import ChartInfoBanner from '../utils/ChartInfoBanner';
import CustomTableData from '../CustomTableData';

interface ICustomChartContainerProps<T>
  extends HtmlHTMLAttributes<HTMLDivElement>,
    Omit<IChartHeaderProps, 'downloadComponentRef'> {
  chartTitle: string;
  hasDownloadBtn?: boolean;
  header: (props: IChartHeaderProps) => JSX.Element;
  type: ChartTypes;
  scrollable?: boolean;
  fillWithType?: boolean;
  hasHeader?: boolean;
  hasFullScreen?: boolean;
  switchTabData?: ISwitchTabDataProps;
  sankeyChartData?: IResankyChartProps;
  // title?: string;
  height?: string;
  currencyConvert?: boolean;
  xLabelClassname?: string;
  barSize?: number;
  needLegend?: boolean;
  subTitle?: string;
  chartId?: string;
  showPound?: boolean;
  hasDetails?: boolean;
  hoverTitle?: string;
  yLabelClassNames?: string;
  yLabelWrapperClassNames?: string;
  showYAxisIntOnly?: boolean;
  wrapXAxis?: boolean;
  // exportMode?: boolean;
  isNestedChart?: boolean;
  isExport?: boolean;
  chartWrapperStyles?: string;
  nestedChartContent?: ReactNode;
  onChartClick?: (option: unknown) => void;
  legendLabels?: string[];
  hasTableData?: boolean;
}
const getClassNamesForChartItself = (chartType: string) => {
  switch (chartType) {
    case 'pieChart':
      return 'lg:naxatw-col-span-7';
    case 'donut':
      return 'xl:naxatw-col-span-6 naxatw-col-span-12';
    default:
      return 'naxatw-col-span-12';
  }
};
function ChartContainer<T>({
  header,
  legend,
  xLabel,
  yLabel,
  className,
  data,
  type,
  chartTitle,
  subTitle,
  hasDownloadBtn,
  scrollable = false,
  fillWithType = false,
  fill,
  hasHeader = true,
  hasFullScreen = true,
  switchTabData,
  // title = '',
  sankeyChartData = {
    nodeColors: [],
  },
  height,
  currencyConvert,
  xLabelClassname,
  barSize,
  needLegend,
  chartId,
  showPound,
  hasDetails,
  hoverTitle,
  yLabelClassNames,
  yLabelWrapperClassNames,
  showYAxisIntOnly,
  wrapXAxis = false,
  // exportMode,
  isNestedChart,
  isExport,
  chartWrapperStyles = '',
  nestedChartContent,
  onChartClick,
  legendLabels,
  hasTableData,
}: ICustomChartContainerProps<T>) {
  const dispatch = useDispatch();

  const showLegendDetails = useTypedSelector(
    state => state.mainDashboard?.charts?.showLegendDetails,
  );

  const nestedChartId = useTypedSelector(
    state => state.mainDashboard.charts.nestedCharts.nestedChartId,
  );

  const componentRef = useRef(null);
  const chart = getChartOfType(type);
  const fills = fill || (fillWithType ? getChartFillOfType(type) : ChartFills);

  // will cleanup chart details
  useEffect(() => {
    return () => {
      dispatch(setChartStates({ key: 'chartDetails', value: null }));
      dispatch(setChartStates({ key: 'showLegendDetails', value: false }));
    };
  }, [dispatch]);

  return (
    <RoundedContainer
      ref={componentRef}
      className={cn(
        'chart-container naxatw-relative naxatw-grid naxatw-h-full naxatw-w-full naxatw-grid-cols-12 naxatw-grid-rows-[auto_1fr] naxatw-border-none naxatw-bg-white md:naxatw-order-2',
        className,
      )}
      style={{ rowGap: '0px' }}
    >
      {/* ** ***  **** ********** Top Header ********* **** *** ** */}
      {hasHeader && header && (
        <div className="head  naxatw-col-span-12 naxatw-h-fit">
          <div className="cover">
            {header({
              chartTitle,
              hasDownloadBtn,
              downloadComponentRef: componentRef,
              legend,
              xLabel,
              yLabel,
              data,
              type,
              fill: fills,
              hasFullScreen,
              switchTabData,
              subTitle,
              chartId,
              showPound,
              hoverTitle,
              hasDetails,
              isExport,
              barSize,
              nestedChartContent,
              onChartClick,
              legendLabels,
              hasTableData,
            })}
          </div>
        </div>
      )}
      {
        // nested chart content
        showLegendDetails && nestedChartContent ? (
          <FlexColumn className="naxatw-col-span-12 naxatw-gap-3 naxatw-px-4 naxatw-pt-6">
            <NestedChartContentHeader />
            <div className="naxatw-px-6">{nestedChartContent}</div>
          </FlexColumn>
        ) : hasTableData ? (
          <CustomTableData data={data || []} chartTitle={chartTitle} />
        ) : (
          //* ** ***  **** ********** Main Char Content ********* **** *** ** */
          <>
            {isEmpty(data) ? (
              <div className="naxatw-col-span-12">
                <NoChartDataComponent />
              </div>
            ) : (
              <>
                {(hasDetails || nestedChartContent) && <ChartInfoBanner />}
                {/* ** *** Y label if present *** ** */}
                {yLabel && !showLegendDetails && (
                  <div
                    className={cn(
                      `y-label naxatw-absolute naxatw-left-0 naxatw-top-0 naxatw-flex naxatw-h-full naxatw-w-12 naxatw-items-center naxatw-justify-start`,
                      yLabelWrapperClassNames,
                    )}
                  >
                    <p
                      className={` naxatw-origin-center -naxatw-rotate-90 naxatw-whitespace-nowrap ${yLabelClassNames} ${isExport ? 'fs-xs-medium  !naxatw-text-black' : 'naxatw-body-caption naxatw-text-matt-100 '}`}
                      // override `yLabelClassNames` these properties which causing the legend up-side-down when exporting
                      // this issue arises when the css 'writing-mode property' is used because html canvas doesn't support the 'writing-mode' which also limitation of 'html2canvas' package
                      style={
                        yLabelClassNames
                          ? {
                              transform: 'rotate(-90deg)',
                              writingMode: 'initial',
                            }
                          : {}
                      }
                    >
                      {yLabel}
                    </p>
                  </div>
                )}

                <div
                  className={cn(
                    `card main-chart  naxatw-relative naxatw-col-span-12 naxatw-h-full  naxatw-content-center naxatw-overflow-y-hidden naxatw-px-8 naxatw-py-5 naxatw-transition-[width] naxatw-duration-300 naxatw-ease-in ${chartWrapperStyles}`,
                    getClassNamesForChartItself(type),
                    scrollable && 'scrollbar naxatw-overflow-auto',
                  )}
                >
                  {/* ----------- Back Btn For Nested Charts */}
                  {(isNestedChart && nestedChartId) || showLegendDetails ? (
                    <IconButton
                      name="arrow_back"
                      iconClassName="naxatw-text-[#0B2E62] !naxatw-text-[1.25rem]"
                      className="naxatw-absolute naxatw-top-[10px] naxatw-rounded-full !naxatw-font-normal naxatw-leading-6 naxatw-duration-200 hover:naxatw-bg-primary-200"
                      onClick={() => {
                        dispatch(
                          setNestedChartStates({
                            key: 'nestedChartId',
                            value: null,
                          }),
                        );
                        dispatch(
                          setChartStates({ key: 'chartDetails', value: null }),
                        );
                        dispatch(
                          setChartStates({
                            key: 'showLegendDetails',
                            value: false,
                          }),
                        );
                      }}
                    />
                  ) : null}
                  {chart &&
                    !showLegendDetails &&
                    chart({
                      data,
                      fills,
                      scrollable,
                      sankeyChartData,
                      height,
                      currencyConvert,
                      barSize,
                      needLegend,
                      chartId,
                      showPound,
                      hasDetails,
                      hoverTitle,
                      showYAxisIntOnly,
                      wrapXAxis,
                      // exportMode,
                      // title,
                      isNestedChart,
                      onChartClick,
                      isExport,
                    })}
                  {/* ** *** X label if present *** ** */}
                  {xLabel && !showLegendDetails && (
                    <div
                      className={`x-label naxatw-col-span-12 naxatw-flex naxatw-items-center naxatw-justify-center ${isExport ? 'naxatw-py-4' : 'naxatw-py-4'}`}
                    >
                      <p
                        className={` ${xLabelClassname} ${isExport ? 'fs-xs-medium !naxatw-text-black' : 'naxatw-body-caption naxatw-text-matt-100'} `}
                      >
                        {xLabel}
                      </p>
                    </div>
                  )}
                </div>

                {/* ** ***  **** **********  Chart Legend ********* **** *** ** */}
                {legend && (
                  <div
                    className={`naxatw-col-span-12 naxatw-grid naxatw-place-items-center naxatw-py-6 xl:naxatw-col-span-6   ${showLegendDetails ? 'scrollbar naxatw-col-span-12 naxatw-overflow-y-auto' : ''} ${type === 'bar' && '!naxatw-col-span-12 -naxatw-mt-6 !naxatw-flex naxatw-justify-center naxatw-pb-8'}`}
                  >
                    {legend({
                      data,
                      type,
                      fills,
                      chartId,
                      showPound,
                      hasDetails,
                      isNestedChart,
                      legendLabels,
                    })}
                  </div>
                )}
              </>
            )}
          </>
        )
      }
    </RoundedContainer>
  );
}
export default hasErrorBoundary(ChartContainer);
