/* eslint-disable no-unused-vars */
import Skeleton from '@Components/RadixComponents/Skeleton';
import { v4 as uuidv4 } from 'uuid';

const EvidenceCardSk = ({ noOfRows = 3 }: { noOfRows?: number }) => {
  return (
    <div className="evi-card-sk naxatw-flex naxatw-items-center naxatw-gap-2 ">
      {Array.from({ length: noOfRows }).map(___ => (
        <div
          className=" naxatw-rounded-xl naxatw-border-primary-200 naxatw-bg-white naxatw-px-4 naxatw-py-3 naxatw-shadow-sm"
          key={uuidv4()}
        >
          <Skeleton className="!naxatw-h-[2rem] !naxatw-w-[10rem]" />
        </div>
      ))}
    </div>
  );
};

export default EvidenceCardSk;
