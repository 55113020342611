import { useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTypedSelector } from '@Store/hooks';
import ChartRenderer from '@Components/common/Charts/ChartRenderer';
// import { viewByOptionsSectorsCard } from '@Constants/mainDashboard';
import { getPartnersByProgrammeProject } from '@Services/provincialProfile';
// import { setPartnersSelectedTab } from '@Store/actions/provincialProfile';

export default function Partners({ isExport = false }: { isExport?: boolean }) {
  const { provinceId } = useParams();
  const [searchParams] = useSearchParams();
  const municipalityCode = searchParams.get('municipality');
  const downloadComponentRef = useRef<any>(null);
  const partner = useTypedSelector(
    state => state.provincialProfile.chartSwitchTab.partner,
  );
  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);

  const chartData = {
    id: `top-partners-by-budget-allocation-province-${provinceId}-${partner}`,
    name: 'top-partners-by-project-programme',
    queryFn: getPartnersByProgrammeProject,
    params: {
      province: provinceId,
      by: partner === 'Programme' ? 'program' : partner.toLocaleLowerCase(),
      project_status: partner,
      status: filterBy,
      municipality: municipalityCode,
    },
    title: `Top Partners by ${partner}`,
    hasDownloadBtn: true,
    chartType: 'verticalBar',
    hasHeader: true,
    fill: ['#B7E7E4'],
    xLabel: `${partner}`,
    yLabel: 'Partners',
    height: isExport ? '11rem' : '20rem',
    currencyConvert: true,
    // switchTabData: {
    //   switchTabOptions: viewByOptionsSectorsCard,
    //   dispatchAction: setPartnersSelectedTab,
    //   activeLabel: partner,
    // },
    hoverTitle: `${partner} Count`,
    chartWrapperStyles: isExport
      ? '!naxatw-pr-4 !naxatw-pt-5 !naxatw-pl-1'
      : '',
    isExport,
    xLabelClassname: isExport ? 'fs-xs-medium' : '',
    yLabelClassNames: isExport ? 'fs-xs-medium' : '',
    yLabelWrapperClassNames: isExport ? '!naxatw-left-[-5px]' : '',
    barSize: 45,
  };

  return (
    <div
      ref={downloadComponentRef}
      className={`naxatw-h-full ${isExport ? '' : 'naxatw-max-h-[28.375rem] naxatw-min-h-[28rem]'}`}
    >
      <ChartRenderer data={chartData} />
    </div>
  );
}
