/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { animated, useSpring } from '@react-spring/web';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import ErrorMessage from '@Components/common/ErrorMessage';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import getInputElement from '@Components/common/FormComponent/GetInputElement';
import { FormControl } from '@Components/common/FormUI';
import IconButton from '@Components/common/IconButton';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import updateFormFields from '@Constants/FormConstants/updateForm';
import { zodResolver } from '@hookform/resolvers/zod';
import validateSchemaUpdateForm from '@Validations/federalInfoUpdate';
import {
  getFederalInfoUpdateById,
  getChoiceTypes,
  patchFederalInfoUpdate,
  postFederalInfoUpdate,
} from '@Services/federalInfoUpdate';
import { getProvinceList } from '@Services/mainDashboard';
import { prepareDataForDropdown } from '@Utils/index';
import Skeleton from '@Components/RadixComponents/Skeleton';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { includeProvinces } from '@Constants/updates';

type Props = {
  onClose: () => void;
};

type FormState = {
  summary: string;
  date: string;
  province: null | number;
  federal_type: string;
};

const formDefaultValue: FormState = {
  summary: '',
  date: '',
  province: null,
  federal_type: '',
};

function UpdateAddForm({ onClose }: Props) {
  const { slugId } = useParams();
  const [hasAnimated, setHasAnimated] = useState(false);
  const queryClient = useQueryClient();

  const {
    formState: { errors },
    register,
    getValues,
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { dirtyFields, isSubmitting, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: formDefaultValue,
    resolver: zodResolver(validateSchemaUpdateForm),
  });

  const formProps = {
    register,
    control,
    getValues,
    setValue,
    errors,
    dirtyFields,
    reset,
  };

  const fedTypeWatch = watch('federal_type');

  //! ---------- DropDown Options starts

  // type dropdown options
  const { data: federalTypes } = useQuery({
    queryKey: ['federal-types'],
    queryFn: () => getChoiceTypes({ type: 'federal_type' }),
    select: res => {
      if (!res?.data) return null;
      const fedOptions = prepareDataForDropdown(res?.data, 'name');
      return fedOptions;
    },
  });

  const { data: provinceList } = useQuery({
    queryKey: ['province-list'],
    queryFn: () => getProvinceList({}),
    enabled: fedTypeWatch === 'Province',
    select: res => {
      if (!res?.data) return null;
      const provinces = prepareDataForDropdown(res?.data, 'name')?.filter(
        (pro: Record<string, any>) => includeProvinces.includes(pro.label),
      );
      return provinces;
    },
  });

  //! ---------- DropDown Options ends

  // get federal info data on edit and set the form fields state
  const { isLoading: isFederalDataFetching } = useQuery({
    queryKey: ['federal-info', 'federal-record-edit', provinceList],
    queryFn: () => getFederalInfoUpdateById(slugId || ''),
    select: res => res?.data || {},
    onSuccess: (data: Record<string, any>) => {
      if (!data[0]) return null;
      const responseData = data[0];
      Object.keys(responseData).forEach(key => {
        if (key === 'province__name') {
          const matchedProvinceName = provinceList?.find(
            province => province.label === responseData[key],
          )?.id;
          setValue('province', matchedProvinceName);
        } else {
          setValue(key as keyof FormState, responseData[key]);
        }
      });

      return null;
    },
    onError: () => {
      toast.error('Failed to get a data');
    },
    enabled: !!slugId,
  });

  // post contact
  const { mutateAsync: postUpdateData, isLoading: isPostLoading } = useMutation(
    {
      mutationKey: ['post-update'],
      mutationFn: async (payloadData: Record<string, any>) =>
        postFederalInfoUpdate(payloadData),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['federal-info-list'],
        });
        toast.success('Updates added Succesfully');
        onClose();
      },
    },
  );

  // patch contact
  const { mutateAsync: patchUpdateData } = useMutation({
    mutationKey: ['patch-contact'],
    mutationFn: async (payloadData: Record<string, any>) =>
      patchFederalInfoUpdate(Number(slugId), payloadData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['federal-info-list'],
      });
      toast.success('Updates edited Succesfully');
      onClose();
    },
  });

  const dropdownOptionsList = {
    federal_type: federalTypes || [],
    province: provinceList || [],
  };

  const needEdit = !!(isFederalDataFetching && slugId);
  const isSubmitBtnEnable = isSubmitting || isPostLoading || needEdit;

  const spring = useSpring({
    from: { scale: 0 },
    to: { scale: 1 },
    config: { duration: 100 },
    onRest: () => {
      if (!hasAnimated) {
        setHasAnimated(true);
      }
    },
  });

  const getDirtyFieldValues = () => {
    const allValues = getValues();
    const dirtyValues: any = {};
    Object.keys(allValues).forEach((key: string) => {
      if (dirtyFields[key as keyof typeof dirtyFields]) {
        dirtyValues[key] = allValues[key as keyof typeof dirtyFields];
      }
    });

    return dirtyValues;
  };

  const formSubmit = async () => {
    try {
      if (slugId) {
        if (isDirty === false) {
          onClose();
          return;
        }
        const editFields = getDirtyFieldValues();
        await patchUpdateData(editFields);
      } else {
        const allValues = getValues();
        await postUpdateData(allValues);
      }
    } catch (error) {
      toast.error('Something Went Wrong');
    }
  };
  useEffect(() => {
    setHasAnimated(false);
  }, []);

  return (
    <div className="naxatw-flex naxatw-h-full naxatw-w-full naxatw-items-center naxatw-justify-center">
      <animated.div
        className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-rounded-2xl naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 sm:naxatw-w-[34.75rem]"
        style={{
          transform: spring?.scale?.to(scale => `scale(${scale})`),
        }}
      >
        <div className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-rounded-2xl naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 ">
          <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-light">
            <BreadCrumb
              heading={`Update / ${!slugId ? 'Form' : 'Edit'}`}
              overlayStatus={() => onClose()}
            />
            <IconButton
              name="close"
              className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
              iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
              onClick={() => onClose()}
            />
          </div>

          <form onSubmit={handleSubmit(formSubmit)}>
            <FlexColumn className="scrollbar naxatw-h-[calc(100dvh-18rem)] naxatw-overflow-y-auto naxatw-p-6">
              {needEdit ? (
                <FlexColumn className="naxatw-gap-5">
                  {Array.from({ length: updateFormFields.length - 1 }).map(
                    (_, idx) => (
                      <Skeleton
                        key={idx}
                        className="naxatw-h-12 naxatw-w-full"
                      />
                    ),
                  )}
                  <Skeleton className="naxatw-h-28 naxatw-w-full" />
                </FlexColumn>
              ) : (
                updateFormFields.map(field => {
                  const { id } = field;
                  const isVisible =
                    id !== 'province' || fedTypeWatch === 'Province';

                  if (!isVisible) return null;

                  return (
                    <FlexRow key={field.id} className="naxatw-gap-x-5">
                      <FormControl className="form-control naxatw-mb-5 naxatw-w-full naxatw-gap-[0.5rem]">
                        <InputLabel
                          label={field.label}
                          astric={field.required}
                          id={field.id}
                        />
                        {getInputElement(
                          {
                            ...(field as any),
                          },
                          /* @ts-ignore */
                          formProps,
                          dropdownOptionsList[
                            field?.id as keyof typeof dropdownOptionsList
                          ],
                        )}
                        {
                          /* @ts-ignore */
                          errors[id]?.message && (
                            <ErrorMessage
                              /* @ts-ignore */
                              message={errors[field?.id]?.message}
                            />
                          )
                        }
                      </FormControl>
                    </FlexRow>
                  );
                })
              )}
            </FlexColumn>

            <div className="naxatw-flex naxatw-justify-center naxatw-py-5 naxatw-shadow-formshadow">
              <Button
                size="sm"
                variant="primary"
                className="naxatw-px-4 naxatw-py-2"
                type="submit"
                isLoading={isSubmitBtnEnable}
                disabled={isSubmitBtnEnable}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </animated.div>
    </div>
  );
}
export default hasErrorBoundary(UpdateAddForm);
