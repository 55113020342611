/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState } from 'react';
import AdminHeader from '@Components/common/AdminHeader';
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { deleteVideoTutorial, getVideoTutorial } from '@Services/tutorials';
import { useSpring, animated } from '@react-spring/web';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import TutorialsHeaderSwitchTab from '../TutorialsHeader';
import VideosRegistrationForm from './VideosRegistrationForm';

const VideosTutorial = () => {
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [idToDelete, setIdToDelete] = useState<string>('');
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const videosDataColumns = [
    {
      header: 'S.N',
      accessorKey: '',
      cell: ({ row }: Record<string, any>) => {
        // eslint-disable-next-line
        return row?.index + 1;
      },
    },
    {
      header: 'TITLE',
      accessorKey: 'title',
      size: 200,
    },
    {
      header: 'DESCRIPTION',
      accessorKey: 'description',
      cell: ({ row }: any) => {
        return (
          <div className="naxatw-leading-normal ">
            {row?.original?.description}
          </div>
        );
      },
      size: 500,
    },
    {
      header: '',
      accessorKey: 'icon',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }: any) => {
        // eslint-disable-next-line no-console
        const cellId = row?.original?.id;
        return (
          <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-mt-2 naxatw-cursor-pointer naxatw-justify-end naxatw-gap-4">
            <ToolTip
              name="edit"
              message="Edit"
              className="naxatw-text-icon-md naxatw-text-matt-100 hover:naxatw-text-calm-teal"
              iconClick={() => {
                navigate(`/data-bank/tutorials/videos/edit/${cellId}`);
                setShowRegistrationForm(true);
              }}
            />
            <ToolTip
              name="delete"
              message="Delete"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-text-red-500"
              iconClick={() => {
                setOpenDeleteConfirmation(true);
                setIdToDelete(cellId);
              }}
            />
          </FlexRow>
        );
      },
    },
  ];

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  const {
    mutate: deleteVideoTutorialData,
    isError,
    isLoading,
    error,
  } = useMutation({
    mutationFn: () => deleteVideoTutorial(idToDelete),
    onSuccess: () => {
      toast.success('Video deleted successfully');
      queryClient.invalidateQueries(['videos-table-data']);
      setOpenDeleteConfirmation(false);
    },
    onError: () => {
      toast.error('Failed to delete video');
    },
  });

  useEffect(() => {
    if (pathname.includes('edit') || pathname.includes('add')) {
      setShowRegistrationForm(true);
    } else {
      setShowRegistrationForm(false);
    }
  }, [pathname]);

  return (
    <>
      <div className="naxatw-space-y-6">
        <AdminHeader
          title="Tutorials"
          onAddNewClick={() => {
            setShowRegistrationForm(true);
            navigate('/data-bank/tutorials/videos/add');
          }}
          needSearchBar={false}
          hasBulkUpload={false}
          registerButtonText="Add Tutorial"
        />
        <TutorialsHeaderSwitchTab />
        <animated.div
          style={{ ...springs }}
          className="naxatw-h-[calc(100vh-18rem)] naxatw-w-full naxatw-overflow-hidden lg:naxatw-h-[calc(100vh-15.6rem)]"
        >
          <DataTable
            columns={videosDataColumns}
            queryFn={getVideoTutorial}
            queryKey="videos-table-data"
            searchInput=""
            sortByKey
          />
        </animated.div>
      </div>
      {showRegistrationForm && (
        <Portal>
          <VideosRegistrationForm
            onClose={() => {
              navigate('/data-bank/tutorials/videos');
              setShowRegistrationForm(false);
            }}
          />
        </Portal>
      )}
      {openDeleteConfirmation && (
        <Portal>
          <DeleteConfirmationOverlay
            onClose={() => setOpenDeleteConfirmation(false)}
            onDelete={() => deleteVideoTutorialData()}
            isError={isError}
            isLoading={isLoading}
            error={error}
          />
        </Portal>
      )}
    </>
  );
};

export default VideosTutorial;
