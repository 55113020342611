import DefaultCard from '@Components/ProgrammeDetails/Cards/Default';

type Props = {
  markerName: string;
  markers: string[];
  className?: string;
  isExport?: boolean;
};
const MarkerCard = ({
  markerName,
  markers,
  className,
  isExport = false,
}: Props) => {
  return (
    <DefaultCard
      className={`naxatw-border ${isExport ? 'naxatw-h-full naxatw-border-pdf-border' : 'naxatw-border-[#D6D6D5] naxatw-py-4'}`}
      isExport={isExport}
    >
      <div className="naxatw-flex naxatw-gap-3">
        <div className={`naxatw-flex naxatw-items-center  ${className}`}>
          <span
            className={`${isExport ? 'fs-sm-medium' : 'naxatw-body-sm naxatw-text-matt-200'}`}
          >
            {markerName}
          </span>
        </div>
        <div className="naxatw-w-[1px] naxatw-bg-[#E3E4E3]" />
        <ul>
          {markers?.map((marker: string) => (
            <li
              key={marker}
              className={` naxatw-my-2 naxatw-flex naxatw-gap-2   ${isExport ? 'fs-sm-medium' : 'naxatw-body-caption naxatw-text-matt-100'}`}
            >
              <i className="naxatw-mt-[0.4rem] naxatw-inline-block naxatw-h-1 naxatw-min-w-1 naxatw-rounded-full naxatw-bg-matt-100" />
              <span>{marker}</span>
            </li>
          ))}
        </ul>
      </div>
    </DefaultCard>
  );
};

export default MarkerCard;
