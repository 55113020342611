import { Fragment } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import ContentBox from '@Components/common/ContentBox';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { generateReportListStakeholder } from '@Services/report';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';

function transformStakeholders(data: any[]) {
  const result: any[] = [];

  data?.forEach(stakeholder => {
    stakeholder.stakeholders?.forEach((levelObj: any) => {
      Object?.keys(levelObj)?.forEach(level => {
        const content = levelObj[level]?.map((categoryObj: any) => {
          const category = Object?.keys(categoryObj)[0];
          const items = categoryObj[category];
          return { category, items };
        });

        result.push({
          level:
            level?.toLowerCase() === 'national level'
              ? 'National'
              : 'Sub National',
          content,
        });
      });
    });
  });

  // sort by 'level' (Sub National -> National)
  return result.sort((x, y) => (x.level > y.level ? -1 : 1));
}

function StakeholderDetails() {
  const { programmeId } = useParams();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('componentId');
  const reqParams = projectId
    ? { project: projectId }
    : { program: programmeId };

  const { data, isLoading } = useQuery({
    queryKey: ['stakeolderData', programmeId, projectId],
    queryFn: async () => generateReportListStakeholder(reqParams),
    select: res => transformStakeholders(res.data),
  });

  return (
    <ContentBox title="Stakeholders Detail">
      <div className="scrollbar naxatw-overflow-auto lg:naxatw-max-h-[60dvh]">
        {data?.length === 0 && !isLoading && (
          <FlexRow className="naxatw-min-h-96 naxatw-items-center">
            <NoChartDataComponent />
          </FlexRow>
        )}
        {isLoading ? (
          <FlexColumn className="naxatw-gap-2">
            {Array(4)
              .fill(0)
              .map((_, idx) => (
                <Skeleton
                  className="naxatw-h-[3rem] naxatw-w-full naxatw-rounded-lg"
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                />
              ))}
          </FlexColumn>
        ) : (
          <div className="naxatw-grid naxatw-h-full naxatw-gap-4 naxatw-px-6 naxatw-py-5 lg:naxatw-grid-cols-2">
            {data?.map((stakeholder: any) => (
              <FlexColumn className="naxatw-gap-2" key={stakeholder?.level}>
                <p className="naxatw-table-head  naxatw-text-primary-700">
                  {stakeholder?.level}
                </p>
                <FlexColumn className="naxatw-w-full naxatw-gap-1 naxatw-rounded-lg naxatw-border naxatw-bg-white naxatw-p-3">
                  {stakeholder?.content?.map((level: any, index: number) => {
                    const isLastIndex =
                      index === stakeholder.content.length - 1;
                    return (
                      <Fragment key={level?.category}>
                        <FlexColumn className="naxatw-gap-[6px]">
                          <p className="naxatw-body-btn naxatw-text-matt-200">
                            {level?.category}
                          </p>
                          <FlexColumn className="naxatw-gap-[0.62rem]">
                            {level?.items?.map((subStakeholders: any) => {
                              return (
                                <FlexRow
                                  className="naxatw-gap-[0.62rem]"
                                  key={subStakeholders.province}
                                >
                                  {subStakeholders?.province && (
                                    <>
                                      <p className="naxatw-body-sm naxatw-text-primary-700">
                                        {subStakeholders?.province}
                                      </p>
                                      <div className="naxatw-h-[1rem] naxatw-w-[1px] naxatw-bg-secondary-300" />
                                    </>
                                  )}
                                  {subStakeholders?.names?.map(
                                    (items: any, itemsIndex: number) => {
                                      const isLastSubIndex =
                                        itemsIndex ===
                                        subStakeholders.names.length - 1;
                                      return (
                                        <FlexRow
                                          className="naxatw-gap-[0.62rem]"
                                          key={items}
                                        >
                                          <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-text-matt-200">
                                            {items}
                                          </p>
                                          <div
                                            className={` ${isLastSubIndex ? 'naxatw-hidden' : 'naxatw-block'} naxatw-h-[1rem] naxatw-w-[1px] naxatw-bg-secondary-300 `}
                                          />
                                        </FlexRow>
                                      );
                                    },
                                  )}
                                </FlexRow>
                              );
                            })}
                          </FlexColumn>
                        </FlexColumn>
                        <div
                          className={` ${isLastIndex ? 'naxatw-hidden' : 'naxatw-mb-4 naxatw-block'} naxatw-h-[1px] naxatw-w-full naxatw-bg-neutral-200`}
                        />
                      </Fragment>
                    );
                  })}
                </FlexColumn>
              </FlexColumn>
            ))}
          </div>
        )}
      </div>
    </ContentBox>
  );
}

export default hasErrorBoundary(StakeholderDetails);
