/* eslint-disable camelcase */
import { Controller, useFormContext } from 'react-hook-form';
import ErrorMessage from '@Components/common/ErrorMessage';
import { FormControl, Input } from '@Components/common/FormUI';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn } from '@Components/common/Layouts';
import FileUpload from '@Components/common/FormUI/Upload';
import isEmpty from '@Utils/isEmpty';

const UploadDocuments = () => {
  const {
    formState: { errors },
    register,
    getValues,
    control,
    setValue,
    watch,
  } = useFormContext();

  const formProps = {
    register,
    control,
    getValues,
    setValue,
    errors,
    watch,
  };

  return (
    <FlexColumn className="document-details naxatw-mb-5 naxatw-w-full naxatw-space-y-5">
      {/* -----------> Tags */}
      <FormControl>
        <InputLabel label="Tags" className="naxatw-mb-1" />
        <Input
          type="text"
          placeholder="Enter tags for the document & if multiple tags, seperate with comma"
          {...register('tags')}
        />
      </FormControl>
      {/* -----------> links */}
      <FormControl>
        <InputLabel
          label="Links"
          className="naxatw-mb-1"
          tooltipMessage="A document or a link to the document is required"
        />
        <Input
          type="text"
          placeholder="Enter Document Links"
          {...register('document_link', {
            pattern: {
              value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
              message: 'Please enter a valid URL.',
            },
          })}
        />
        {errors?.document_link?.message && (
          <ErrorMessage
            message={errors?.document_link?.message as string}
            className="naxatw-mt-1"
          />
        )}
      </FormControl>
      {/* -----------> documents */}
      <FormControl>
        <InputLabel label="Upload Documents" className="naxatw-mb-1" astric />
        <Controller
          control={control}
          name="documents"
          rules={{
            validate: doc => {
              const { document_link } = getValues();
              if (!document_link && isEmpty(doc)) {
                return 'Either links or documents are required.';
              }
              return true;
            },
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <FileUpload
                placeholder="Please upload Documents. (PDF, XLS, DOCS, CSV File) ( Less than 50MB )"
                multiple
                data={value}
                name="documents"
                // fileAccept="*"
                fileAccept=".pdf, .xls, .xlsx, .doc, .docx, .txt, .csv"
                onChange={() => onChange(getValues?.()?.documents)}
                maxSize={50}
                {...formProps}
              />
            );
          }}
        />
        {errors?.documents?.message && (
          <ErrorMessage
            message={errors?.documents?.message as string}
            className="naxatw-mt-1"
          />
        )}
      </FormControl>
    </FlexColumn>
  );
};

export default hasErrorBoundary(UploadDocuments);
