import { useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
// import DropDown from '@Components/common/DropDown';
import ErrorMessage from '@Components/common/ErrorMessage';
import { FormControl, Input } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import ToolTip from '@Components/RadixComponents/ToolTip';
import isEmpty from '@Utils/isEmpty';
import { GoalsFormValidationSchemaProps } from '@Validations/GoalsForm';
import PortalTemplate from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import { deleteGoalLocation } from '@Services/goals';
import { useMutation, useQueryClient } from '@tanstack/react-query';
// import { getFiscalYearOptions } from '@Constants/FormConstants/goalsForm';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import NoGoal from './NoGoal';

const Goal = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [goalIdToDelete, setGoalIdToDelete] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();
  const {
    control,
    setValue,
    setError,
    formState: { errors },
  } = useFormContext<GoalsFormValidationSchemaProps>();

  const watchGoalsList = useWatch({
    control,
    name: 'goals_lists',
  });

  // const fYearsOptions = getFiscalYearOptions(5);

  const handleAddGoal = (e: any) => {
    e.preventDefault();
    if (inputRef.current?.value?.trim() === '') {
      setError('goals_lists', {
        message: 'Enter Goal to Save',
      });
      return;
    }
    const newValue = inputRef.current?.value;
    const isGoalAlreadyPresent = watchGoalsList.some(
      goal => goal.name === newValue,
    );
    if (isGoalAlreadyPresent) {
      setError('goals_lists', {
        message: 'No Duplicate Goal Allowed.',
      });
      return;
    }
    setValue('goals_lists', [...watchGoalsList, { id: '', name: newValue }]);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  // delete goal location
  const {
    mutateAsync: deleteGoalLocationData,
    isLoading: isdeleteGoalLocationLoading,
    isError,
  } = useMutation({
    mutationFn: (id: any) => deleteGoalLocation(id),
    onSuccess: () => {
      setGoalIdToDelete(null);
      queryClient.invalidateQueries({
        queryKey: ['get-goal-location-id'],
      });
    },
  });

  return (
    <FlexColumn className="naxatw-h-full naxatw-w-full">
      <div className={`${isEmpty(watchGoalsList) ? 'lg:naxatw-h-1/2' : ''}`}>
        {
          // <FormControl className="naxatw-w-full naxatw-gap-[0.5rem]">
          //   <InputLabel
          //     label="Fiscal Year"
          //     tooltipMessage="Fiscal Year"
          //     id="fiscal_year_date"
          //   />
          //   <Controller
          //     control={control}
          //     name="fiscal_year_date"
          //     render={({ field: { value, onChange } }) => (
          //       <DropDown
          //         placeholder="Choose"
          //         options={fYearsOptions || []}
          //         value={value}
          //         choose="value"
          //         onChange={onChange}
          //         enableSearchbar={false}
          //       />
          //     )}
          //   />
          //   <>
          //     {
          //       /* @ts-ignore */
          //       errors?.fiscal_year_date?.message && (
          //         <ErrorMessage
          //           /* @ts-ignore */
          //           message={errors?.fiscal_year_date?.message}
          //         />
          //       )
          //     }
          //   </>
          // </FormControl>
        }

        {/* goal list */}
        <FormControl className="naxatw-mt-5 naxatw-w-full naxatw-gap-[0.5rem]">
          <InputLabel label="Goal" tooltipMessage="Goal" id="Goal" />
          <div className="naxatw-flex naxatw-items-center naxatw-gap-3">
            <Input
              type="text"
              placeholder="Enter Goal and Save"
              ref={inputRef}
            />
            <Button variant="secondary" onClick={handleAddGoal}>
              Save
            </Button>
          </div>
          <>
            {errors?.goals_lists?.message && (
              <ErrorMessage
                message={errors?.goals_lists?.message}
                className="naxatw-pl-1"
              />
            )}
          </>
        </FormControl>

        <div className="naxatw-mt-4">
          <InputLabel
            label="Province Priority Goals"
            tooltipMessage="Province Priority Goals"
          />
        </div>
      </div>
      {isEmpty(watchGoalsList) ? (
        <NoGoal />
      ) : (
        <div className="naxatw-mt-1 naxatw-rounded-lg naxatw-bg-primary-100 naxatw-p-3">
          {watchGoalsList?.map((item: Record<string, any>) => (
            <FlexRow
              className="naxatw-mb-1 naxatw-items-center naxatw-justify-between naxatw-gap-1 last:naxatw-mb-0"
              key={item.id}
            >
              <p className="naxatw-body-caption naxatw-w-full naxatw-resize-none naxatw-rounded-sm naxatw-p-[2px] naxatw-px-2 !naxatw-leading-normal naxatw-text-matt-100 naxatw-outline-none">
                {item.name}
              </p>
              <FlexRow className="naxatw-w-fit naxatw-items-center naxatw-gap-4">
                <ToolTip
                  name="close"
                  message="Delete"
                  className="naxatw-h-fit naxatw-px-1 naxatw-py-2 naxatw-text-base naxatw-text-matt-200 naxatw-duration-200 hover:naxatw-text-red-500"
                  iconClick={() => {
                    if (item.id) {
                      setShowDeleteModal(true);
                      setGoalIdToDelete(item.id);
                    } else {
                      setShowDeleteModal(true);
                      setGoalIdToDelete(item.name);
                    }
                  }}
                />
              </FlexRow>
            </FlexRow>
          ))}

          {showDeleteModal && goalIdToDelete ? (
            <PortalTemplate>
              <DeleteConfirmationOverlay
                onClose={() => setShowDeleteModal(false)}
                onDelete={() => {
                  if (typeof goalIdToDelete === 'number') {
                    // if goal id is coming from api
                    deleteGoalLocationData(goalIdToDelete);
                  } else {
                    const updatedList = watchGoalsList.filter(
                      goal => !(goal.id === '' && goal.name === goalIdToDelete),
                    );
                    setValue('goals_lists', updatedList);
                  }
                  setShowDeleteModal(false);
                }}
                isLoading={isdeleteGoalLocationLoading}
                isError={isError}
              />
            </PortalTemplate>
          ) : null}
        </div>
      )}
    </FlexColumn>
  );
};

export default hasErrorBoundary(Goal);
