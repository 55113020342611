import Icon from '@Components/common/Icon';
import { cn } from '@Utils/index';
import { ReactNode } from 'react';
import DefaultCard from '../Default';

type Props = {
  title?: string;
  subTitle?: string;
  iconName?: string;
  children: ReactNode;
  containerClassName?: string;
  headerClassName?: string;
  isExport?: boolean;
};

export default function SegmentCard({
  title,
  subTitle,
  iconName,
  children,
  containerClassName,
  headerClassName,
  isExport = false,
}: Props) {
  return (
    <DefaultCard
      className={cn(
        `naxatw-flex naxatw-flex-col  naxatw-rounded-lg ${isExport ? 'naxatw-gap-3' : 'naxatw-gap-4'}`,
        containerClassName,
      )}
      isExport={isExport}
    >
      {title || iconName ? (
        <div
          className={cn(
            `naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-text-primary-700`,
            headerClassName,
          )}
        >
          {iconName && (
            <Icon
              name={iconName}
              className={
                isExport
                  ? 'naxatw-overflow-hidden naxatw-rounded-[50%] naxatw-bg-primary-100 naxatw-p-1 naxatw-text-[1rem] !naxatw-text-calm-teal'
                  : 'naxatw-text-[1.5625rem]'
              }
            />
          )}
          <div className="naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-x-3">
            {title && (
              <span
                className={` ${isExport ? 'fs-sm-semibold naxatw-text-royal-navy' : 'naxatw-subtitle-lg naxatw-font-bold naxatw-text-primary-700'}`}
              >
                {title}
              </span>
            )}
            {subTitle && (
              <span className="naxatw-body-caption naxatw-flex naxatw-font-bold naxatw-text-matt-200">
                {subTitle}
              </span>
            )}
          </div>
        </div>
      ) : null}
      <div className="segment-body">{children}</div>
    </DefaultCard>
  );
}
