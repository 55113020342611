import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

interface ProgrammeState {
  report: Record<string, any>;
}

const initialState: ProgrammeState = {
  report: {},
};

const setFilterState: CaseReducer<ProgrammeState, PayloadAction<any>> = (
  state,
  action,
) => {
  return {
    ...state,
    report: {
      ...state.report,
      filterParams: { ...state.report.filterParams, ...action.payload },
    },
  };
};

const clearFilterState: CaseReducer<
  ProgrammeState,
  PayloadAction<Partial<ProgrammeState>>
> = state => {
  return {
    ...state,
    report: {
      filterParams: null,
      area: '',
      start_date_1: '',
      start_date_2: '',
      end_date_1: '',
      end_date_2: '',
    },
  };
};

const programmeSlice = createSlice({
  name: 'programme',
  initialState,
  reducers: {
    setFilterState,
    clearFilterState,
  },
});

export { programmeSlice };

export default programmeSlice.reducer;
