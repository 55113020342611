import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Icon from '@Components/common/Icon';
import { deleteIndividualList } from '@Store/actions/pdfExport';
import { cn } from '@Utils/index';
import { useDispatch } from 'react-redux';
import ExportRoundedContainer from '../ExportRoundedContainer';
import ExportBtn from '../ExportHeader/ExportBtn';

interface IGroupsProps {
  iconName: string;
  title: string;
  className?: string;
  dataLists: Record<string, any>[];
  exportLists?: any;
  exportTitles?: Record<string, any>;
}

const CommonGroups = ({
  iconName,
  title,
  className,
  dataLists,
  exportLists,
  exportTitles,
}: IGroupsProps) => {
  const dispatch = useDispatch();

  return (
    <ExportRoundedContainer className={cn('naxatw-h-full', className)}>
      <div className="header naxatw-flex naxatw-items-center naxatw-gap-1 naxatw-px-3 naxatw-py-2">
        <Icon
          name={iconName}
          className="!naxatw-text-[14px] !naxatw-text-royal-navy"
        />
        <p className="fs-sm-bold naxatw-text-royal-navy">{title}</p>
      </div>
      <div className="body-content naxatw-grid naxatw-auto-cols-fr naxatw-grid-flow-col  naxatw-gap-2 naxatw-px-3 naxatw-py-2">
        {dataLists?.map(list => {
          const isDataIncludes =
            Array.isArray(exportLists) && exportLists.includes(list.id);

          if (!isDataIncludes) return null;

          return (
            <div key={list.id} className="naxatw-group naxatw-relative">
              <p className="fs-sm-medium !naxatw-text-black before:naxatw-mr-1 before:naxatw-text-black before:naxatw-content-['•'] ">
                {list.label}
              </p>
              <ExportBtn
                size="sm"
                btnStyles="!naxatw-right-0 !naxatw-top-0"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: exportTitles?.page,
                      section: exportTitles?.section,
                      key: 'datalists',
                      value: list.id,
                    }),
                  )
                }
              />
            </div>
          );
        })}
      </div>
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(CommonGroups);
