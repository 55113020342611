import { useState } from 'react';
import DataTable from '@Components/common/DataTable';
import ModalContainer from '@Components/common/FormUI/ModalContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { contactsTableColumns } from '@Constants/contacts';
import { getContacts } from '@Services/contacts';
import { apiURL } from '@Services/index';
import { useParams } from 'react-router-dom';

interface IContactModalProps {
  onClose: () => void;
}

const ContactsModal = ({ onClose }: IContactModalProps) => {
  const { provinceId } = useParams();

  const [searchTerm, setSearchTerm] = useState('');

  const downloadContactsList = () => {
    const url = `${apiURL}/contact/?is_export=True&province=${provinceId}${searchTerm && `&search=${searchTerm}`}`;
    const a = document.createElement('a');
    a.href = url;
    a.click();
  };

  return (
    <ModalContainer
      hasSearchOption
      hasDownloadOption
      title="Contact Information"
      className="naxatw-h-full naxatw-max-h-[94dvh] sm:naxatw-w-[96dvw] sm:naxatw-max-w-full"
      headerClassName="!naxatw-shadow-formshadow"
      onHandleClose={onClose}
      onHandleDownload={downloadContactsList}
      onHandleSearch={setSearchTerm}
    >
      <div className="naxatw-px-4 naxatw-py-[30px] lg:naxatw-px-10">
        <DataTable
          queryFn={getContacts}
          queryKey="contact-tabledata"
          queryFnParams={{ province: provinceId }}
          columns={contactsTableColumns}
          className="naxatw-max-h-[calc(100dvh-28dvh)] naxatw-border-0 [&>thead>tr>th]:!naxatw-bg-white [&>thead]:naxatw-shadow-[0_1px_#EAECF0]"
          searchInput={searchTerm}
          sortByKey
        />
      </div>
    </ModalContainer>
  );
};

export default hasErrorBoundary(ContactsModal);
