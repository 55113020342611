/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import useRouteParamQuery from '@Hooks/useRouteParamQuery';
import { Button } from '@Components/RadixComponents/Button';
import ToolTip from '@Components/RadixComponents/ToolTip';
import SwitchTab from '@Components/common/FormUI/SwitchTab';
import { mappedStatusParams, statusOptions } from '@Constants/filters';
import { setFilterBy } from '@Store/actions/provincialProfile';
import ToolTipContainer from '@Components/common/ToolTipContainer';
import Icon from '@Components/common/Icon';

interface TopHeaderProps {
  provinceName: string;
  municipalityName?: string;
  onHandleClick?: () => void;
}

let parentPageFilterByValue: string | null = null;

const TopHeader = ({
  provinceName,
  municipalityName,
  onHandleClick,
}: TopHeaderProps) => {
  const { provinceId } = useParams();
  // const scrollPosition = useScrollPosition();
  const getQueryParams = useRouteParamQuery();
  const municipalityCode = getQueryParams.get('municipality');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useTypedDispatch();
  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);
  const navigatefromPage = location?.state?.from;

  // preserve parent page filter
  useEffect(() => {
    if (municipalityCode && parentPageFilterByValue === null) {
      parentPageFilterByValue = filterBy;
      dispatch(setFilterBy('Ongoing'));
      return;
    }
    if (!municipalityCode && parentPageFilterByValue !== null) {
      dispatch(setFilterBy(parentPageFilterByValue));
      parentPageFilterByValue = null;
    }
  }, [municipalityCode, dispatch, filterBy]);

  return (
    <div
      // className={`${scrollPosition > 0 ? 'naxatw-fixed naxatw-top-[3.9rem] naxatw-px-6 naxatw-py-4 xl:naxatw-px-0' : 'naxatw-relative'} naxatw-left-0 naxatw-z-[99] naxatw-w-full naxatw-bg-primary-100 naxatw-pb-5 lg:naxatw-max-w-[90rem] xl:naxatw-left-auto`}

      className="naxatw-sticky naxatw-top-[63px] naxatw-z-20  naxatw-w-full naxatw-bg-primary-100 naxatw-pb-5 naxatw-pt-5"
    >
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between">
        <div className="naxatw-flex naxatw-items-center">
          <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
            <ToolTip
              name="arrow_back"
              className="naxatw-flex naxatw-items-center naxatw-text-xl naxatw-text-[#0B2E62]"
              triggerClassName="!naxatw-size-[2rem] naxatw-rounded-full naxatw-transition-colors naxatw-ease-in hover:naxatw-bg-primary-200"
              iconClick={() =>
                navigate(
                  navigatefromPage === 'dashboard-map'
                    ? '/dashboard/map'
                    : municipalityCode
                      ? `/explore-by/geography/${provinceId}`
                      : '/explore-by/geography/',
                )
              }
              message="Back"
            />
            <h5 className="naxatw-text-lg naxatw-font-medium naxatw-text-[#0B2E62] md:naxatw-text-xl">
              {provinceName} {municipalityName && `/${municipalityName}`}
            </h5>
          </div>
        </div>

        {/* ----------------> Switcher  */}
        <div className="naxatw-flex naxatw-items-center naxatw-gap-4">
          <SwitchTab
            title=""
            activeLabel="All"
            activeValue={
              filterBy === 'Ongoing' ? 'On-Going' : filterBy || 'All'
            }
            options={statusOptions}
            wrapperClassName="!naxatw-cursor-pointer !naxatw-gap-0  !naxatw-items-start"
            defaultBg={false}
            defaultBehaviour={false}
            className="naxatw-bg-white naxatw-text-sm naxatw-leading-5 naxatw-text-[#484848]"
            titleClassName="naxatw-text-base naxatw-font-medium naxatw-tracking-[0.00625rem] naxatw-text-matt-100"
            onChange={status =>
              dispatch(setFilterBy(mappedStatusParams[status]))
            }
          />

          {/* ----------------> Export Button */}
          <ToolTipContainer side="left" message="Download Portfolio Profile">
            <Button
              variant="secondary"
              color="primary"
              className="naxatw-text-sm"
              onClick={onHandleClick}
            >
              <Icon
                name="download"
                className="naxatw-flex naxatw-items-center naxatw-text-xl naxatw-text-secondary-500"
              />
              Profile
            </Button>
          </ToolTipContainer>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
