/* eslint-disable no-unused-vars */
import { useRef } from 'react';
import ExportHeader from '../ExportHeader';

interface IDescriptionProps {
  onHandleClose: () => void;
  btnStyles?: string;
  setHasDescription?: (value: boolean) => void;
}

const ExportDescription = ({
  onHandleClose,
  btnStyles,
  setHasDescription,
}: IDescriptionProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const onHandleInput = () => {
    if (contentRef.current) {
      if (setHasDescription) {
        setHasDescription(contentRef.current.innerText.trim().length > 0);
      }
    }
  };

  return (
    <div className="desc-container naxatw-relative">
      <div
        className="pdf-desc-textarea naxa naxatw-min-h-16 naxatw-w-full naxatw-resize-y naxatw-overflow-hidden naxatw-rounded-lg naxatw-border naxatw-border-pdf-border naxatw-p-2 naxatw-text-[12px] naxatw-text-black naxatw-outline-none focus:naxatw-border-grey-200"
        role="textbox"
        contentEditable
        ref={contentRef}
        onInput={onHandleInput}
      />
      <ExportHeader
        onHandleDelete={onHandleClose}
        btnStyles={btnStyles}
        className="!naxatw-w-[15%]"
      />
    </div>
  );
};

export default ExportDescription;
