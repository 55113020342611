import { api, authenticated, fastApi } from '.';

export const getFederalListCount = (params: Record<string, any>) =>
  authenticated(fastApi).get('/dashboard/federal_wise_list/', { params });

export const getProvincialCardCounts = (params: Record<string, any>) =>
  authenticated(fastApi).get('/infographics/province_wise_counts/', { params });

export const getProvinceData = (provinceId: any) =>
  api.get(`/province/${provinceId}/`);

export const getBbox = (params: Record<string, any>) =>
  api.get('/federal-extent/', { params });

export const getFederalWiseCount = (params: Record<string, any>) =>
  authenticated(fastApi).get('/dashboard/federal_wise_count/', { params });

export const getMunicipalityListByCode = (id: string) =>
  authenticated(api).get(`/municipality/${id}/`);

export const getTopSectors = (params: Record<string, any>) =>
  authenticated(fastApi).get('/infographics/sector_budget_by_location/', {
    params,
  });

export const getTopPartners = (params: Record<string, any>) =>
  authenticated(fastApi).get('/infographics/partner_budget_by_location/', {
    params,
  });

export const getMunicipalityList = (params: Record<string, any>) =>
  authenticated(api).get('/municipality/', { params });

export const getComparisonData = (params: Record<string, any>) =>
  authenticated(api).get('/compare-contextual-indicator/', { params });

export const getMunicipalityListById = (id: number) => {
  return authenticated(api).get(`/municipality/${id}/`);
};
