import { useQuery } from '@tanstack/react-query';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { useTypedSelector } from '@Store/hooks';
import { mapBudgetExpenditureTitle } from '@Constants/mainDashboard';
import ChartHeader from '../ChartHeader';
import ChartLegend from '../ChartLegend';
import ChartContainer from '../ChartContainer';

interface IChartRendererProps {
  data: Record<string, any>;
}

const ChartRenderer = ({ data }: IChartRendererProps) => {
  const {
    id,
    title,
    chartType,
    queryFn,
    yLabel = '',
    params,
    xLabel = '',
    name = '',
    hasFullScreen = true,
    switchTabData,
    sankeyChartData,
    className,
    height,
    // if you want to convert x-axis to curreny pass true, works in vertical barchart
    currencyConvert,
    xLabelClassname,
    barSize,
    needLegend,
    subTitle,
    chartId,
    showPound = false,
    hasDetails = false,
    hoverTitle,
    yLabelClassNames,
    yLabelWrapperClassNames,
    showYAxisIntOnly = false,
    hasHeader = true,
    fill = ['#B7E7E4'],
    wrapXAxis,
    // exportMode = false,
    isNestedChart,
    isExport = false,
    chartWrapperStyles,
    queryKey,
    nestedChartContent,
    onChartClick,
    legendLabels,
    legend,
    hasTableData = false,
  } = data;

  const nestedChartId = useTypedSelector(
    state => state.mainDashboard.charts.nestedCharts.nestedChartId,
  );

  const {
    data: chartData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [id, queryFn, params, queryKey],
    queryFn: () => queryFn(params),
    select: res => {
      const response = res?.data;
      // if (id === 'project-status') {
      //   return response?.map((status: Record<string, any>) => ({
      //     name: status?.program_status,
      //     value: status?.count,
      //   }));
      // }
      // if (id === 'Geographical Location vs Programs') {
      //   return response?.map((status: Record<string, any>) => ({
      //     name: status?.name,
      //     value: status?.count,
      //   }));
      // }
      // if (id === 'budget-province') {
      //   return response?.map((status: Record<string, any>) => ({
      //     name: status?.name,
      //     value: status?.budget,
      //   }));
      // }
      // if (id === 'budget-sector') {
      //   return response?.map((status: Record<string, any>) => ({
      //     name: status?.name,
      //     size: status?.budget,
      //   }));
      // }
      if (
        name === 'top-sectors-by-budget-allocation' ||
        name === 'top-partners-by-budget-allocation'
      ) {
        return response?.map((sector: Record<string, any>) => ({
          name: sector?.name,
          value: sector?.budget,
          fullName: sector?.name,
        }));
      }
      // if (id === 'top-programmes-by-budget-allocation') {
      //   return response?.map((sector: Record<string, any>) => ({
      //     name: sector?.abbreviation ? sector?.abbreviation : sector?.name,
      //     value: sector?.budget,
      //     fullName: sector?.name,
      //   }));
      // }
      // if (id === 'provincial-profile-status') {
      //   return response?.map((status: Record<string, any>) => ({
      //     name: status?.program_status,
      //     value: status?.count,
      //   }));
      // }
      if (id === 'top-five-sector-trend-component') {
        return response?.map((sector: Record<string, any>) => ({
          name: sector?.name,
          size: sector?.count,
        }));
      }
      if (id === 'top-five-sector-trend-programme') {
        return response?.map((sector: Record<string, any>) => ({
          name: sector?.name,
          size: sector?.count,
        }));
      }
      if (
        name === 'top-partners-by-project-programme' ||
        name === 'top-sectors-by-programme-project'
      ) {
        return response?.map((partner: Record<string, any>) => ({
          name: partner?.name,
          value: partner?.count,
        }));
      }
      if (
        id === 'stakeholders-engaged-in-province' ||
        id === 'stakeholders-engaged-in-federal' ||
        id === 'key-dps-provincial-profile' ||
        id === 'keystakeholders-provincial-profile'
      ) {
        return response;
      }
      if (id === 'programme-details-report') {
        return response[0]?.project?.map((programme: Record<string, any>) => {
          return {
            name: programme?.project_name,
            value: programme?.project_budget,
          };
        });
      }
      if (id === 'programme-vs-projects') {
        const chartResponseData = nestedChartId ? response?.projects : response;

        return chartResponseData.map((programme: Record<string, any>) => ({
          name: programme.name,
          value: nestedChartId ? programme.budget : programme.total_budget,
          percentage: programme.percentage,
          ...(nestedChartId ? {} : { id: programme.id }),
        }));
      }

      // stakholder data for 'All' tab
      if (id === 'stakeholder-project-all-chart') {
        return response?.[0]?.stakeholders?.map(
          (stakeholder: {
            national: number;
            sub_national: number;
            type: string;
          }) => ({
            value1: stakeholder?.national,
            value2: stakeholder?.sub_national,
            name: stakeholder?.type,
          }),
        );
      }

      // stakholder data for 'Government' tab
      if (id === 'stakeholder-project-government-chart') {
        return response?.[0]?.stakeholders?.map(
          (stakeholder: {
            value: number;
            stakeholder_type: string;
            data_by_province: Record<string, any>[];
          }) => ({
            name: stakeholder?.stakeholder_type,
            value: stakeholder?.value,
            data: stakeholder?.data_by_province,
          }),
        );
      }

      if (id === 'project-group-campaign-goal') {
        return response.map((project: Record<string, string | number>) => ({
          name: project?.campaign_goal || project?.marker,
          value: project.count,
        }));
      }

      if (id === 'expenditure-budget-overview') {
        return response.map(
          (expenditure: {
            _id: string;
            percentage: number;
            value: number;
          }) => ({
            // eslint-disable-next-line no-underscore-dangle
            name: mapBudgetExpenditureTitle[expenditure._id],
            value: expenditure.value,
            percentage: expenditure.percentage,
          }),
        );
      }

      if (id === 'project-agreement-modality-Chart') {
        return response.map(
          (project: {
            project__aggrement_modality__name: string;
            count: number;
            percentage?: number;
          }) => ({
            name: project.project__aggrement_modality__name,
            value: project.count,
            percentage: project?.percentage,
          }),
        );
      }

      return response;
    },
  });

  if (isLoading || isFetching) {
    return (
      <div className="naxatw-p-5">
        <Skeleton
          className="naxatw-bg-primary-50 naxatw-h-full
         naxatw-w-full naxatw-shadow-sm"
        />
      </div>
    );
  }
  function renderChart() {
    switch (chartType) {
      case 'donut':
        return (
          <ChartContainer
            className="!naxatw-gap-0"
            type="donut"
            chartTitle={title}
            hasDownloadBtn
            header={ChartHeader}
            fillWithType
            legend={ChartLegend}
            data={chartData || []}
            hasFullScreen={hasFullScreen}
            switchTabData={switchTabData}
            hasDetails={hasDetails}
            showPound={showPound}
            isNestedChart={isNestedChart}
            isExport={isExport}
            barSize={barSize}
            fill={fill}
            chartWrapperStyles={chartWrapperStyles}
            hasTableData={hasTableData}
          />
        );
      case 'horizontalBar':
        return (
          <ChartContainer
            className="!naxatw-gap-0"
            type="horizontalBar"
            chartTitle={title}
            subTitle={subTitle}
            header={ChartHeader}
            hasDownloadBtn
            data={chartData || []}
            hasFullScreen={hasFullScreen}
            switchTabData={switchTabData}
            height={height}
            currencyConvert={currencyConvert}
            xLabelClassname={xLabelClassname}
            hasDetails={hasDetails}
            isExport={isExport}
            chartWrapperStyles={chartWrapperStyles}
          />
        );
      case 'bar':
        return (
          <ChartContainer
            className="!naxatw-gap-0"
            type="bar"
            yLabel={yLabel}
            chartTitle={title}
            subTitle={subTitle}
            header={ChartHeader}
            hasDownloadBtn
            hasHeader={hasHeader}
            data={chartData || []}
            fillWithType
            fill={fill}
            hasFullScreen={hasFullScreen}
            switchTabData={switchTabData}
            xLabel={xLabel}
            height={height}
            xLabelClassname={xLabelClassname}
            barSize={barSize}
            needLegend={needLegend}
            hoverTitle={hoverTitle}
            yLabelClassNames={yLabelClassNames}
            yLabelWrapperClassNames={yLabelWrapperClassNames}
            showYAxisIntOnly={showYAxisIntOnly}
            wrapXAxis={wrapXAxis}
            hasDetails={hasDetails}
            isExport={isExport}
            nestedChartContent={nestedChartContent}
            onChartClick={onChartClick}
            chartWrapperStyles={chartWrapperStyles}
            legend={legend}
            legendLabels={legendLabels}
          />
        );
      case 'line':
        return (
          <ChartContainer
            className="!naxatw-gap-0"
            type="line"
            yLabel={yLabel}
            chartTitle={title}
            subTitle={subTitle}
            header={ChartHeader}
            hasDownloadBtn
            data={chartData || []}
            fillWithType
            legend={ChartLegend}
            hasFullScreen={hasFullScreen}
            switchTabData={switchTabData}
            xLabelClassname={xLabelClassname}
            hasDetails={hasDetails}
            isExport={isExport}
            chartWrapperStyles={chartWrapperStyles}
          />
        );
      case 'treeMap':
        return (
          <ChartContainer
            className="!naxatw-gap-0"
            type="treeMap"
            yLabel={yLabel}
            chartTitle={title}
            subTitle={subTitle}
            header={ChartHeader}
            hasDownloadBtn
            data={chartData || []}
            fillWithType
            hasFullScreen={hasFullScreen}
            switchTabData={switchTabData}
            xLabelClassname={xLabelClassname}
            hasDetails={hasDetails}
            isExport={isExport}
          />
        );
      case 'verticalBar':
        return (
          <ChartContainer
            header={ChartHeader}
            type="verticalBar"
            data={chartData || []}
            chartTitle={title}
            subTitle={subTitle}
            hasDownloadBtn
            hasHeader={hasHeader}
            fill={fill}
            xLabel={xLabel}
            yLabel={yLabel}
            hasFullScreen={hasFullScreen}
            switchTabData={switchTabData}
            height={height}
            currencyConvert={currencyConvert}
            xLabelClassname={xLabelClassname}
            hoverTitle={hoverTitle}
            yLabelClassNames={yLabelClassNames}
            // exportMode={exportMode}
            hasDetails={hasDetails}
            yLabelWrapperClassNames={yLabelWrapperClassNames}
            isExport={isExport}
            chartWrapperStyles={chartWrapperStyles}
            barSize={barSize}
          />
        );
      case 'sankeyChart':
        return (
          <ChartContainer
            header={ChartHeader}
            type="sankeyChart"
            data={chartData || []}
            chartTitle={title}
            subTitle={subTitle}
            hasDownloadBtn
            hasHeader
            fill={['#B7E7E4']}
            xLabel={xLabel}
            yLabel={yLabel}
            hasFullScreen={hasFullScreen}
            switchTabData={switchTabData}
            sankeyChartData={sankeyChartData}
            className={className}
            height={height}
            xLabelClassname={xLabelClassname}
            hasDetails={hasDetails}
          />
        );
      case 'stackedBarChart':
        return (
          <ChartContainer
            header={ChartHeader}
            type="stackedBarChart"
            data={chartData || []}
            chartTitle={title}
            subTitle={subTitle}
            hasDownloadBtn
            hasHeader
            fill={['#B7E7E4']}
            xLabel={xLabel}
            yLabel={yLabel}
            hasFullScreen={hasFullScreen}
            switchTabData={switchTabData}
            height={height}
            xLabelClassname={xLabelClassname}
            hasDetails={hasDetails}
            isExport={isExport}
          />
        );
      case 'pieChart':
        return (
          <ChartContainer
            header={ChartHeader}
            type="pieChart"
            data={chartData || []}
            chartTitle={title}
            chartId={chartId}
            subTitle={subTitle}
            showPound={showPound}
            hasDownloadBtn
            hasHeader
            legend={ChartLegend}
            hasFullScreen={hasFullScreen}
            switchTabData={switchTabData}
            height={height}
            hasDetails={hasDetails}
            isExport={isExport}
            fill={fill}
            chartWrapperStyles={chartWrapperStyles}
          />
        );

      case 'tableData':
        return <p>hahah</p>;

      default:
        return <></>;
    }
  }

  return renderChart();
};

export default ChartRenderer;
