import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import StakeholderChart from '@Components/ProvincialProfile/ChartSection/StakeholderChart';
import { deletePdfSection } from '@Store/actions/pdfExport';
import { useDispatch } from 'react-redux';

const PdfStakeholderChart = () => {
  const dispatch = useDispatch();

  return (
    <ExportRoundedContainer className="province-stakeholder-chart naxatw-relative">
      <ExportHeader
        headerTitle="Stakeholders Type vs Project Count"
        className="!naxatw-border-b-border-pdf !naxatw-border-b !naxatw-p-3"
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: 'provincialProfile',
              section: 'stakeholderChart',
            }),
          )
        }
        btnStyles="!naxatw-top-[-10px]"
      />
      <StakeholderChart isExport />
    </ExportRoundedContainer>
  );
};

export default PdfStakeholderChart;
