import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { signInUser } from '@Services/authentication';
import { useForm } from 'react-hook-form';
import InfoDialog from '@Components/common/InfoDialog';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import InputLabel from '@Components/common/InputLabel';
import { FormControl, Input } from '@Components/common/FormUI';
import { Button } from '@Components/RadixComponents/Button';
import Icon from '@Components/common/Icon';
import ErrorMessage from '@Components/common/ErrorMessage';
// import getCookie from '@Utils/cookieUtils';

const initialState = {
  username: '',
  password: '',
};

const Login = () => {
  const navigate = useNavigate();

  const [pwVisibility, setPwVisibility] = useState(false);
  const { mutate, isError, error, isLoading } = useMutation({
    mutationFn: signInUser,
    onSuccess: (res: any) => {
      // if (process.env.NODE_ENV === 'development') {
      localStorage.setItem('token', res.data.token);
      navigate('/');
    },
    // if (getCookie('csrftoken')) {
    //   navigate('/');
    // }
    // },
  });

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    defaultValues: initialState,
    mode: 'onChange',
  });

  const onSubmit = (data: typeof initialState) => {
    mutate({ username: data?.username?.trim(), password: data?.password });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="naxatw-flex naxatw-flex-col naxatw-gap-4"
    >
      <h6 className="naxatw-mb-6 naxatw-text-xl naxatw-font-medium naxatw-text-black">
        Login to your account
      </h6>
      <FlexColumn className="naxatw-gap-4">
        {isError && (
          <InfoDialog status="error">
            <span className="naxatw-text-sm naxatw-font-semibold">
              {/* @ts-ignore */}
              {error?.response?.data?.message || 'Invalid username or password'}
            </span>
          </InfoDialog>
        )}
        <FormControl>
          <InputLabel label="Username" />
          <Input
            id="username"
            placeholder="Enter your Username"
            type="text"
            className="naxatw-mt-2"
            {...register('username', {
              required: true,
              pattern: {
                value: /^[^\s].*$/,
                message: 'Username cannot contain spaces',
              },
            })}
          />
          {errors?.username?.message ? (
            <ErrorMessage
              message={errors?.username?.message}
              className="naxatw-mt-1"
            />
          ) : null}
        </FormControl>
        <FormControl className="naxatw-relative">
          <InputLabel label="Password" />
          <Input
            id="password"
            placeholder="Enter your Password"
            type={pwVisibility ? 'text' : 'password'}
            className="naxatw-mt-2 naxatw-pr-10"
            {...register('password', {
              required: true,
              pattern: {
                value: /^[^\s].*$/,
                message: 'Password cannot contain spaces',
              },
            })}
          />
          <button
            className="naxatw-absolute naxatw-right-4 naxatw-top-[56%] naxatw-flex naxatw-select-none"
            type="button"
            onClick={() => setPwVisibility(!pwVisibility)}
          >
            <Icon
              name={pwVisibility ? 'visibility' : 'visibility_off'}
              className="naxatw-text-xl"
              tabIndex={-1}
            />
          </button>
          {errors?.password?.message ? (
            <ErrorMessage
              message={errors?.password?.message}
              className="naxatw-mt-1"
            />
          ) : null}
        </FormControl>
        <Button
          type="button"
          variant="link"
          className="naxatw-ml-auto naxatw-w-fit !naxatw-p-0 naxatw-font-medium"
          onClick={() => {
            navigate('/forgot-password');
          }}
        >
          <p className="naxatw-underline">Forgot password?</p>
        </Button>
      </FlexColumn>
      <FlexColumn gap={2}>
        <Button type="submit" isLoading={isLoading}>
          Login
        </Button>
        <FlexRow className="naxatw-items-center naxatw-gap-2">
          <p className="naxatw-text-[0.75rem] naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-matt-100">
            Don&apos;t Have an Account?{' '}
          </p>
          <Button
            type="button"
            variant="link"
            className="naxatw-w-fit !naxatw-p-0 naxatw-font-medium"
            onClick={() => {
              navigate('/sign-up');
            }}
          >
            <p className="naxatw-underline">Sign up</p>
          </Button>
        </FlexRow>
      </FlexColumn>
    </form>
  );
};

export default Login;
