import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import Metric from '@Components/common/Card/Metric';
import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportBtn from '@Components/common/CommonExport/ExportHeader/ExportBtn';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import SegmentCard from '@Components/ProgrammeDetails/Cards/Segment';
import ProjectBudget from '@Components/ProgrammeDetails/Charts/ProjectBudget';
import StatusBar from '@Components/ProgrammeDetails/StatusBar';
import WorkingArea from '@Components/ProvincialProfile/ExportPdf/PortfolioSummary/WorkingArea';
import {
  deleteIndividualList,
  deletePdfSection,
  resetPdfSection,
} from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { convertToCurrencySystem } from '@Utils/index';

const PdfSummary = ({
  programmeCount,
}: {
  programmeCount: Record<string, any>;
}) => {
  const dispatch = useDispatch();

  const showResetButton = useTypedSelector(
    state => state.pdfExportSlice.programmeDetails.summary.showResetButton,
  );

  const cardsData = useTypedSelector(
    state => state.pdfExportSlice.programmeDetails.summary.datalists,
  );

  const {
    allocated_budget: budget,
    project_count: projectCount,
    first_tier_partner_count: partnerCount,
    stakeholder_count: stakeholderCount,
    sector_count: sectorCount,
    marker_count: markerCount,
    district_count: districtCount,
    municipality_count: munCount,
    province_count: provinceCount,
  } = programmeCount;

  const isFirstRowActive =
    cardsData.includes('project') ||
    cardsData.includes('partner') ||
    cardsData.includes('stakeholder');

  const isSecondRowActive =
    cardsData.includes('working-areas') ||
    cardsData.includes('working-areas-chart');

  const isThirdRowActive =
    cardsData.includes('marker') ||
    cardsData.includes('sector') ||
    cardsData.includes('budget') ||
    cardsData.includes('budget-chart');

  return (
    <ExportRoundedContainer className="pdf-details-summary">
      <ExportHeader
        headerTitle="Portfolio Summary"
        className="!naxatw-p-2"
        isStyleAbsolute={false}
        showResetButton={showResetButton}
        onHandleReset={() => {
          dispatch(
            resetPdfSection({
              page: 'programmeDetails',
              section: 'summary',
            }),
          );
        }}
        onHandleDelete={() => {
          dispatch(
            deletePdfSection({
              page: 'programmeDetails',
              section: 'summary',
            }),
          );
        }}
      />
      <div className="summary-content  naxatw-bg-primary-200 naxatw-p-2">
        <div className="naxatw-grid naxatw-grid-cols-1 naxatw-gap-y-2">
          {isFirstRowActive ? (
            <div className="top-charts naxatw-grid  naxatw-auto-cols-[1fr] naxatw-grid-flow-col naxatw-gap-2">
              {cardsData.includes('project') && (
                <motion.div
                  className="naxatw-group naxatw-relative"
                  layout
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <Metric
                    label="Project"
                    iconName="folder_open"
                    metricCount={projectCount}
                    className="!naxatw-p-2"
                    isExport
                  />
                  <ExportBtn
                    size="sm"
                    onHandleClose={() =>
                      dispatch(
                        deleteIndividualList({
                          page: 'programmeDetails',
                          section: 'summary',
                          key: 'datalists',
                          value: 'project',
                        }),
                      )
                    }
                  />
                </motion.div>
              )}

              {cardsData.includes('partner') && (
                <motion.div
                  className="naxatw-group naxatw-relative"
                  layout
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <Metric
                    label="First Tier Partner"
                    iconName="handshake"
                    metricCount={partnerCount}
                    className="!naxatw-p-2"
                    isExport
                  />
                  <ExportBtn
                    size="sm"
                    onHandleClose={() =>
                      dispatch(
                        deleteIndividualList({
                          page: 'programmeDetails',
                          section: 'summary',
                          key: 'datalists',
                          value: 'partner',
                        }),
                      )
                    }
                  />
                </motion.div>
              )}

              {cardsData.includes('stakeholder') && (
                <motion.div
                  className="naxatw-group naxatw-relative"
                  layout
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <Metric
                    label="Stakeholders"
                    iconName="groups"
                    metricCount={stakeholderCount}
                    className="!naxatw-p-2"
                    isExport
                  />
                  <ExportBtn
                    size="sm"
                    onHandleClose={() =>
                      dispatch(
                        deleteIndividualList({
                          page: 'programmeDetails',
                          section: 'summary',
                          key: 'datalists',
                          value: 'stakeholder',
                        }),
                      )
                    }
                  />
                </motion.div>
              )}
            </div>
          ) : null}

          {isSecondRowActive ? (
            <div className="workingareas naxatw-grid naxatw-grid-flow-col naxatw-gap-2">
              {cardsData.includes('working-areas') && (
                <motion.div
                  className="naxatw-group naxatw-relative"
                  layout
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <WorkingArea
                    isExport
                    districtCount={districtCount}
                    municipalityCount={munCount}
                    provinceCount={provinceCount}
                    labelClassName="!naxatw-border-b !naxatw-border-b-pdf-border !naxatw-pb-3 !naxatw-w-full !naxatw-justify-center"
                    className="!naxatw-col-span-2 !naxatw-h-full !naxatw-flex-col !naxatw-justify-center !naxatw-gap-0"
                    textCountWrapperStyles="!naxatw-pt-3"
                  />
                  <ExportBtn
                    size="sm"
                    onHandleClose={() =>
                      dispatch(
                        deleteIndividualList({
                          page: 'programmeDetails',
                          section: 'summary',
                          key: 'datalists',
                          value: 'working-areas',
                        }),
                      )
                    }
                  />
                </motion.div>
              )}

              {cardsData.includes('working-areas-chart') && (
                <motion.div
                  className=" naxatw-group naxatw-relative"
                  layout
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <SegmentCard
                    title="Timeline"
                    iconName="timeline"
                    containerClassName=""
                    isExport
                  >
                    <StatusBar isExport />
                  </SegmentCard>
                  <ExportBtn
                    size="sm"
                    onHandleClose={() =>
                      dispatch(
                        deleteIndividualList({
                          page: 'programmeDetails',
                          section: 'summary',
                          key: 'datalists',
                          value: 'working-areas-chart',
                        }),
                      )
                    }
                  />
                </motion.div>
              )}
            </div>
          ) : null}

          {isThirdRowActive ? (
            <div className="naxatw-grid naxatw-grid-flow-col naxatw-gap-x-2">
              <div
                className={`naxatw-flex  naxatw-flex-col naxatw-gap-y-2 ${cardsData.includes('budget-chart') ? 'naxatw-h-[240px]' : ''}`}
              >
                {cardsData.includes('marker') && (
                  <motion.div
                    className="naxatw-group naxatw-relative  naxatw-flex-1"
                    layout
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Metric
                      label="Markers"
                      iconName="donut_small"
                      metricCount={markerCount}
                      className="!naxatw-h-full  !naxatw-p-2"
                      isExport
                    />
                    <ExportBtn
                      size="sm"
                      onHandleClose={() =>
                        dispatch(
                          deleteIndividualList({
                            page: 'programmeDetails',
                            section: 'summary',
                            key: 'datalists',
                            value: 'marker',
                          }),
                        )
                      }
                    />
                  </motion.div>
                )}

                {cardsData.includes('sector') && (
                  <motion.div
                    className="naxatw-group naxatw-relative  naxatw-min-w-[210px] naxatw-flex-1"
                    layout
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Metric
                      isExport
                      label="Sectors"
                      iconName="category"
                      metricCount={sectorCount}
                      className="!naxatw-h-full  !naxatw-p-2"
                    />
                    <ExportBtn
                      size="sm"
                      onHandleClose={() =>
                        dispatch(
                          deleteIndividualList({
                            page: 'programmeDetails',
                            section: 'summary',
                            key: 'datalists',
                            value: 'sector',
                          }),
                        )
                      }
                    />
                  </motion.div>
                )}
                {cardsData.includes('budget') && (
                  <motion.div
                    className="naxatw-group naxatw-relative  naxatw-flex-1"
                    layout
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Metric
                      isExport
                      label="Allocated Budget"
                      iconName="currency_pound"
                      metricCount={convertToCurrencySystem(budget)}
                      className="!naxatw-h-full  !naxatw-p-2"
                    />
                    <ExportBtn
                      size="sm"
                      onHandleClose={() =>
                        dispatch(
                          deleteIndividualList({
                            page: 'programmeDetails',
                            section: 'summary',
                            key: 'datalists',
                            value: 'budget',
                          }),
                        )
                      }
                    />
                  </motion.div>
                )}
              </div>
              {cardsData.includes('budget-chart') && (
                <motion.div
                  className="naxatw-group naxatw-relative naxatw-col-start-2 naxatw-row-start-1 naxatw-row-end-4"
                  layout
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <ProjectBudget isExport />
                  <ExportBtn
                    size="sm"
                    btnStyles="!naxatw-top-[12px] !naxatw-right-[7px]"
                    onHandleClose={() =>
                      dispatch(
                        deleteIndividualList({
                          page: 'programmeDetails',
                          section: 'summary',
                          key: 'datalists',
                          value: 'budget-chart',
                        }),
                      )
                    }
                  />
                </motion.div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(PdfSummary);
