import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import ChartRenderer from '@Components/common/Charts/ChartRenderer';
import { getProgrammeBudgets } from '@Services/provincialProfile';

function ProjectBudgetChart({ isExport }: { isExport?: boolean }) {
  const downloadComponentRef = useRef<HTMLDivElement | null>(null);
  const { programmeId } = useParams();
  const chartData = {
    queryFn: getProgrammeBudgets,
    params: { program: programmeId },
    id: `programme budget`,
    title: `Project by Budget`,
    hasDownloadBtn: true,
    chartType: 'bar',
    hasHeader: true,
    fill: ['#B7E7E4'],
    yLabel: `Budget`,
    xLabel: 'Project',
    name: 'project-budget',
    height: isExport ? '130px' : '22rem',
    barSize: isExport ? 40 : 60,
    showYAxisIntOnly: true,
    wrapXAxis: !isExport,
    isExport: !!isExport,
    yLabelClassNames: isExport ? 'fs-xs-medium' : '',
    xLabelClassNames: isExport ? 'fs-xs-medium' : '',
    chartWrapperStyles: isExport ? '!naxatw-pb-0' : '',
  };

  return (
    <div
      ref={downloadComponentRef}
      className={`naxatw-h-full naxatw-rounded-xl naxatw-bg-white ${isExport ? '' : 'naxatw-max-h-[43rem] naxatw-min-h-[32rem]'}`}
    >
      <ChartRenderer data={chartData} />
    </div>
  );
}
export default hasErrorBoundary(ProjectBudgetChart);
