import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import HeaderSwicthTab from '@Components/common/HeaderSwicthTab';
import Searchbar from '@Components/common/SearchBar';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import { setPublicSearchText } from '@Store/actions/knowledgeRepository';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { knowledgeLibrarytabOptions } from '@Constants/mainDashboard';
import { useQuery } from '@tanstack/react-query';
import {
  getKnowledgeRepositoryDocumentsCount,
  getKnowRepoConCount,
  getKnowRepoEviCount,
} from '@Services/knowledgeRepository';
import { prefixWithZero } from '@Utils/index';
import { FlexColumn } from '@Components/common/Layouts';
import isEmpty from '@Utils/isEmpty';

function joinParams(params: Array<any>) {
  return params?.join(',');
}
const HeaderSection = () => {
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState(pathname || '');
  const dispatch = useTypedDispatch();
  const searchText = useTypedSelector(
    state => state.knowledgeRepository.publicSearchText,
  );

  const [value, handleChange] = useDebouncedInput({
    ms: 400,
    init: searchText,
    onChange: debouncedEvent => {
      dispatch(setPublicSearchText(debouncedEvent.target.value.trim()));
    },
  });

  const programmaticParams = useTypedSelector(
    state => state.knowledgeRepository.programmaticFilterParams,
  );

  const contextualParams = useTypedSelector(
    state => state.knowledgeRepository.contextualFilterParams,
  );

  const evidenceAgendaFilterParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams,
  );

  //! programmatic count
  const { data: programmaticCount } = useQuery({
    queryKey: [
      'programmtatic-count',
      programmaticParams?.program,
      programmaticParams?.sector,
      programmaticParams?.startDate,
      programmaticParams?.endDate,
      programmaticParams?.file_type,
    ],
    queryFn: () =>
      getKnowledgeRepositoryDocumentsCount({
        program: joinParams(programmaticParams?.program),
        sector: joinParams(programmaticParams?.sector),
        start_date: programmaticParams?.startDate,
        end_date: programmaticParams?.startDate,
        file_type: joinParams(programmaticParams?.file_type),
      }),
    select: res => {
      if (!res?.data) return null;
      return res?.data?.count || 0;
    },
  });

  //! contextual count
  const { data: contextualCount } = useQuery({
    queryKey: [
      'contextual-count',
      contextualParams?.program,
      contextualParams?.sector,
      contextualParams?.startDate,
      contextualParams?.endDate,
      contextualParams?.file_type,
      contextualParams?.province,
    ],
    queryFn: () =>
      getKnowRepoConCount({
        program: joinParams(contextualParams?.program),
        sector: joinParams(contextualParams?.sector),
        start_date: contextualParams?.startDate,
        end_date: contextualParams?.endDate,
        file_type: contextualParams?.file_type
          ? joinParams(contextualParams?.file_type)
          : '',
        province: joinParams(contextualParams?.province),
      }),
    select: res => {
      if (!res?.data) return null;
      return res?.data?.count || 0;
    },
  });

  //! evidence count
  const { data: evidenceAgendCount } = useQuery({
    queryKey: ['evidence-agenda-count', evidenceAgendaFilterParams],
    queryFn: async () =>
      getKnowRepoEviCount({
        program: joinParams(evidenceAgendaFilterParams?.program),
        start_date: evidenceAgendaFilterParams?.startDate,
        end_date: evidenceAgendaFilterParams?.endDate,
        file_type: joinParams(evidenceAgendaFilterParams?.file_type),
        team_membership: joinParams(evidenceAgendaFilterParams?.teamOwnership),
        geographic_focus: joinParams(
          evidenceAgendaFilterParams?.geographicFocus,
        ),
        theme: evidenceAgendaFilterParams?.themes
          ?.map((theme: Record<string, any>) => theme.id)
          .join(','),
        evidence_agenda_status: joinParams(
          evidenceAgendaFilterParams?.evidenceStatus,
        ),
        // category: evidenceAgendaFilterParams?.category?.join(','),
        sub_category: evidenceAgendaFilterParams?.subCategory?.join(','),
      }),
    select: res => {
      if (!res?.data) return null;
      return res?.data?.count || 0;
    },
  });

  const overallCount: Record<string, number> = {
    ...(programmaticCount
      ? { 'Programmatic Information': programmaticCount }
      : { 'Programmatic Information': 0 }),
    ...(contextualCount
      ? { 'Contextual Information': contextualCount }
      : { 'Contextual Information': 0 }),
    ...(evidenceAgendCount
      ? { 'Evidence Agenda': evidenceAgendCount }
      : { 'Evidence Agenda': 0 }),
  };

  return (
    <FlexColumn className="naxatw-gap-2">
      <div className="naxatw-grid naxatw-w-full naxatw-gap-5 lg:!naxatw-grid-cols-[3.3fr_1fr]">
        <HeaderSwicthTab
          headerOptions={knowledgeLibrarytabOptions.map(
            (tabItem: Record<string, any>) => ({
              ...tabItem,
              label: `${tabItem.label} ${!isEmpty(overallCount) ? `(${prefixWithZero(overallCount[tabItem.label])})` : ''}`,
            }),
          )}
          activeTab={activeTab}
          onChange={(path: string) => {
            setActiveTab(path);
          }}
        />

        <Searchbar
          value={value}
          onChange={handleChange}
          className="!naxatw-w-full"
        />
      </div>
    </FlexColumn>
  );
};

export default hasErrorBoundary(HeaderSection);
