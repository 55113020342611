import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import { FormControl, Input } from '@Components/common/FormUI';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import InputLabel from '@Components/common/InputLabel';
import { Button } from '@Components/RadixComponents/Button';
import { useForm } from 'react-hook-form';
import ErrorMessage from '@Components/common/ErrorMessage';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getCategoryById,
  patchCategory,
  postCategory,
} from '@Services/category';
import { toast } from 'react-toastify';
import { propTypeClose } from '@Constants/Types/FormTypes/userSecurity';

const CateforyForm = ({ onClose }: propTypeClose) => {
  const { catId } = useParams();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitting, errors },
  } = useForm({
    defaultValues: { name: '' },
    mode: 'onChange',
  });

  // get cate if edit
  const { isFetching: isCatLoading } = useQuery({
    queryKey: ['category-by-id', catId],
    enabled: !!catId,
    queryFn: () => getCategoryById(Number(catId)),
    select: responseData => {
      if (!responseData?.data) return null;
      return responseData.data;
    },
    onError: () => toast.error('Error Occured!. Please try again.'),
    onSuccess: catData => {
      if (!catData) return;
      Object.keys(catData).forEach((key: any) => {
        setValue(key, catData[key]);
      });
    },
  });

  // post category
  const { mutateAsync: postCategoryData, isLoading: isCategoryLoading } =
    useMutation({
      mutationKey: ['post-category'],
      mutationFn: async (payloadData: Record<string, any>) =>
        postCategory(payloadData),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['category-tabledata'],
        });
        toast.success('Category added Succesfully');
        onClose();
      },
    });

  // patch category
  const { mutateAsync: patchCategoryData, isLoading: isCategoryPatching } =
    useMutation({
      mutationKey: ['patch-category'],
      mutationFn: async (payloadData: Record<string, any>) =>
        patchCategory(Number(catId), payloadData),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['category-tabledata'],
        });
        toast.success('Category updated Succesfully');
        onClose();
      },
    });

  const hadleFormSubmit = async (submittedData: Record<string, any>) => {
    try {
      if (catId) {
        if (isDirty === false) {
          onClose();
          return;
        }

        await patchCategoryData({ name: submittedData.name });
      } else {
        await postCategoryData(submittedData);
      }
    } catch (error: any) {
      const caughtError = error?.response?.data?.message;
      toast.error(caughtError || 'Something Went Wrong');
    }
  };

  return (
    <div className="form-category">
      <form onSubmit={handleSubmit(hadleFormSubmit)}>
        <div className='form-outer naxatw-shadow-light" naxatw-px-7 naxatw-py-5'>
          {catId && isCatLoading ? (
            <FormSkeleton numRows={1} className="naxatw-w-full" />
          ) : (
            <div className="form-inner">
              <FormControl>
                <InputLabel label="Category" astric className="naxatw-mb-2" />
                <Input
                  placeholder="Enter Category"
                  {...register('name', {
                    required: 'Category is required',
                    minLength: {
                      value: 1,
                      message: 'Category is required',
                    },
                    maxLength: {
                      value: 200,
                      message: 'Category must be no longer than 200 characters',
                    },
                  })}
                />
                {errors?.name?.message && (
                  <ErrorMessage
                    message={errors?.name?.message as string}
                    className="naxatw-mt-1"
                  />
                )}
              </FormControl>
            </div>
          )}{' '}
        </div>

        <div className="naxatw-flex naxatw-justify-center naxatw-py-5 naxatw-shadow-formshadow">
          <Button
            size="normal"
            variant="primary"
            className="naxatw-px-4 naxatw-py-2"
            type="submit"
            isLoading={isSubmitting || isCategoryLoading || isCategoryPatching}
            disabled={
              isSubmitting ||
              isCategoryLoading ||
              isCatLoading ||
              isCategoryPatching
            }
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default hasErrorBoundary(CateforyForm);
