import CardInfo from '@Components/common/CommonExport/CardInfo';
import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportBtn from '@Components/common/CommonExport/ExportHeader/ExportBtn';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import {
  deleteIndividualList,
  deletePdfSection,
  resetPdfSection,
} from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { parseFormattedNumber } from '@Utils/index';
import { useDispatch } from 'react-redux';

const PdfPopulation = ({
  provinceDetails,
}: {
  provinceDetails: Record<string, any>;
}) => {
  const dispatch = useDispatch();

  const showResetButton = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.population.showResetButton,
  );

  const cardsData = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.population.datalists,
  );
  const provinceData = provinceDetails;

  const conInfo = [
    {
      id: 'capital',
      label: 'Capital',
      value: provinceData?.capital || '-',
    },
    {
      id: 'provincial-assembly',
      label: 'Provincial Assembly',
      value: provinceData?.assembly || '-',
    },
    {
      id: 'chief-minister',
      label: 'Chief minister',
      value: provinceData?.incumbent || '-',
    },
    {
      id: 'principal-secretary',
      label: 'Principal Secretary',
      value: provinceData?.secretary || '-',
    },
    {
      id: 'speaker',
      label: 'Speaker',
      value: provinceData?.spokeperson || '-',
    },
    {
      id: 'no-of-ministries',
      label: 'No of ministries',
      value: provinceData?.no_of_ministries || '-',
    },
  ];

  const isPopulationVisible = cardsData.includes('population');

  return (
    <div className="pdf-population-container naxatw-relative">
      <ExportHeader
        headerTitle="Description"
        showResetButton={showResetButton}
        isStyleAbsolute={false}
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: 'provincialProfile',
              section: 'population',
            }),
          )
        }
        onHandleReset={() =>
          dispatch(
            resetPdfSection({
              page: 'provincialProfile',
              section: 'population',
            }),
          )
        }
      />
      <ExportRoundedContainer className="population-data naxatw-relative  naxatw-p-3 ">
        {isPopulationVisible && (
          <div className="top-content naxatw-group naxatw-border-b naxatw-border-b-pdf-border  naxatw-pb-4  naxatw-text-center">
            <p className="fs-md-bold naxatw-mb-1 naxatw-uppercase naxatw-tracking-[-0.015rem] naxatw-text-primary-700 ">
              total population
            </p>
            <p className="naxatw-mb-1">
              {provinceData?.total_population || '-'}
            </p>
            <p className="fs-sm-medium">
              {provinceData?.total_population
                ? (
                    (parseFormattedNumber(provinceData.total_population) /
                      29164578) *
                    100
                  ).toFixed(2)
                : '_'}
              % Share of National Population
            </p>
            <ExportBtn
              size="sm"
              onHandleClose={() =>
                dispatch(
                  deleteIndividualList({
                    page: 'provincialProfile',
                    section: 'population',
                    key: 'datalists',
                    value: 'population',
                  }),
                )
              }
            />
          </div>
        )}

        <div
          className={`${isPopulationVisible ? 'naxatw-mt-4' : ''} bottom-content naxatw-relative `}
        >
          <div className="naxatw-grid naxatw-grid-cols-3 naxatw-gap-4">
            {conInfo.map(({ label, value, id }) =>
              cardsData.includes(id) ? (
                <div
                  key={id}
                  className="card-container naxatw-group naxatw-relative"
                >
                  <CardInfo label={label} value={value} />
                  <ExportBtn
                    size="sm"
                    onHandleClose={() =>
                      dispatch(
                        deleteIndividualList({
                          page: 'provincialProfile',
                          section: 'population',
                          key: 'datalists',
                          value: id,
                        }),
                      )
                    }
                  />
                </div>
              ) : null,
            )}
          </div>
        </div>
      </ExportRoundedContainer>
    </div>
  );
};

export default hasErrorBoundary(PdfPopulation);
