import { useQuery } from '@tanstack/react-query';
import { Controller, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import DropDown from '@Components/common/DropDown';
import { FormControl } from '@Components/common/FormUI';
import TiptapEditor from '@Components/common/FormUI/TipTapEditor';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn } from '@Components/common/Layouts';
import { getThematicFieldOptions } from '@Services/knowledgeRepository';
import { prepareDataForDropdown } from '@Utils/index';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';

const DocumentDetails = () => {
  const { control } = useFormContext();

  //! ----------> thematic dropdown
  const { data: thematicDropdown } = useQuery({
    queryKey: ['knowledgerepo-thematicfield-options'],
    queryFn: () => getThematicFieldOptions(),
    onError: () => toast.error('Failed to fetch thematic field options'),
    select: data => prepareDataForDropdown(data?.data || [], 'name'),
  });

  return (
    <FlexColumn className="dcocument-details naxatw-mb-5 naxatw-w-full naxatw-space-y-5">
      {/* ------------> Summary */}
      <FormControl>
        <InputLabel label="Summary" className="naxatw-mb-1" />
        <Controller
          control={control}
          name="summary"
          render={({ field: { value, onChange } }) => {
            return <TiptapEditor bindvalue={value} onChange={onChange} />;
          }}
        />
      </FormControl>
      {/* ------------> thematic Field */}
      <FormControl>
        <InputLabel label="Thematic Field" className="naxatw-mb-1" />
        <Controller
          control={control}
          name="thematic_field"
          defaultValue=""
          render={({ field: { value, onChange } }) => (
            <DropDown
              placeholder="Thematic Field"
              className="naxatw-w-full"
              options={thematicDropdown || []}
              value={value}
              onChange={onChange}
              multiple
              checkBox
            />
          )}
        />
      </FormControl>
    </FlexColumn>
  );
};

export default hasErrorBoundary(DocumentDetails);
