import { useLocation } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import CardSection from './CardSection';
import HeaderSection from './HeaderSection';
import KnowledgeLibFilters from './Filters/knowledgeLibFilters';
import ContexttualFilters from './Filters/ContexttualFilters';
import ContextualCardSection from './ContextualCardSection';
import EvidenceAgendaFilters from './Filters/EvidenceAgendaFilters';
import EvidenceAgendaCardSection from './EvidenceAgendaCardSection';

const getFilterCopmonentByPathName = (pathname: string) => {
  if (pathname.includes('contextual')) {
    return <ContexttualFilters />;
  }
  if (pathname.includes('evidence-agenda')) {
    return <EvidenceAgendaFilters />;
  }
  return <KnowledgeLibFilters />;
};

const getKnowCardByPathName = (pathname: string) => {
  if (pathname.includes('contextual')) {
    return <ContextualCardSection />;
  }
  if (pathname.includes('evidence-agenda')) {
    return <EvidenceAgendaCardSection />;
  }
  return <CardSection />;
};

const KnowledgeRepository = () => {
  const { pathname } = useLocation();

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  return (
    <animated.div
      style={{ ...springs }}
      className="naxatw-mx-auto naxatw-h-full naxatw-max-w-[90rem] naxatw-bg-primary-100  naxatw-pb-[2.375rem] naxatw-pt-5"
    >
      <div className="naxatw-mx-auto naxatw-w-11/12">
        <HeaderSection />
        <div className="naxatw-mt-4 naxatw-grid naxatw-w-full  naxatw-gap-5 lg:naxatw-h-[calc(100vh-188px)]  lg:!naxatw-grid-cols-[3.3fr_1fr]">
          {getKnowCardByPathName(pathname)}
          {getFilterCopmonentByPathName(pathname)}
        </div>
      </div>
    </animated.div>
  );
};

export default hasErrorBoundary(KnowledgeRepository);
