/* eslint-disable import/prefer-default-export */
export const datePickerStyles = {
  root: 'naxatw-min-w-[15rem]',
  months: 'naxatw-flex naxatw-relative naxatw-justify-center',
  month: 'naxatw-space-y-4 !naxatw-ml-0 naxatw-w-full',
  weekdays:
    'naxatw-flex naxatw-justify-around naxatw-w-full [&>th]:naxatw-font-medium naxatw-text-gray-500 naxatw-text-sm',
  caption_label: 'naxatw-hidden',
  nav: 'naxatw-space-x-1 naxatw-flex naxatw-items-center naxatw-absolut naxatw-top-[2px] naxatw-absolute naxatw-w-full naxatw-justify-between naxatw-pointer-events-none',
  month_grid:
    'naxatw-w-full naxatw-p-2 naxatw-bg-white naxatw-rounded-lg naxatw-mt-4',
  day: 'naxatw-rounded-lg naxatw-text-gray-900 !naxatw-font-semibold hover:naxatw-bg-gray-100 naxatw-text-sm naxatw-overflow-hidden naxatw-leading-9 aria-selected:!naxatw-bg-primary-600 naxatw-text-center naxatw-w-full naxatw-cursor-pointer naxatw-max-h-9',
  day_button:
    'naxatw-w-full naxatw-font-semibold disabled:naxatw-text-grey-500 naxatw-border-0',
  today:
    'naxatw-bg-white naxatw-text-red-500 naxatw-font-bold naxatw-rounded-lg hover:naxatw-bg-primary-600 hover:naxatw-text-white',
  selected: 'naxatw-text-white naxatw-p-0',
  outside:
    'naxatw-text-gray-400 !naxatw-font-light naxatw-pointer-events-none naxatw-opacity-0',
  week: '!naxatw-bg-transparent naxatw-flex',
  dropdowns:
    'naxatw-flex naxatw-align-center naxatw-justify-center naxatw-gap-4 !naxatw-accent-matt-100 [&>span>select]:naxatw-rounded-xl [&>span>select]:naxatw-px-1',
  dropdown: 'naxatw-text-sm naxatw-font-semibold naxatw-text-matt-100',
  chevron:
    'naxatw-fill-matt-100 naxatw-h-[20px] naxatw-w-[20px] naxatw-pointer-events-auto hover:naxatw-bg-gray-200 naxatw-p-1 naxatw-rounded-full',
};
