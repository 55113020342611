/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Icon from '@Components/common/Icon';
import { useTypedSelector, useTypedDispatch } from '@Store/hooks';
import useOutsideClick from '@Hooks/useOutsideClick';
import { handleOverlayLayerCheckbox } from '@Store/actions/mainDashboard';
import { useMemo } from 'react';

export default function OverlayLayers({
  isPdfExport = false,
}: {
  isPdfExport?: boolean;
}) {
  const dispatch = useTypedDispatch();
  const [toggleRef, toggle, handleToggle] = useOutsideClick();

  const overlayLayerList = useTypedSelector(
    state => state.mainDashboard.overlayLayerList,
  );

  const filteredLayerList = useMemo(() => {
    const { pathname } = window.location;
    if (pathname?.split('/')?.includes('indicators')) return overlayLayerList;
    return overlayLayerList?.filter(layer => layer?.geometry_type === 'Point');
  }, [overlayLayerList]);

  return (
    <div
      ref={toggleRef}
      onClick={handleToggle}
      title="Overlay"
      className={`no-print-area naxatw-cursor-pointer naxatw-border-b naxatw-border-solid naxatw-border-r-grey-600 naxatw-p-2 naxatw-pb-[0.2rem] hover:naxatw-bg-grey-200 ${toggle ? 'naxatw-bg-grey-200' : 'naxatw-bg-white'}`}
    >
      <Icon name="layers" className="naxatw-text-[#212121]" />
      {toggle && (
        <div
          className={`${isPdfExport ? 'naxatw-left-[2.5rem]  naxatw-top-0 naxatw-min-w-[12rem]' : ' naxatw-left-[3.2rem] naxatw-top-[2.5rem] naxatw-min-w-[10rem]'} naxatw-absolute   naxatw-z-[100] naxatw-gap-4 naxatw-rounded-[4px] naxatw-border-[1px] naxatw-border-[#EAECF0] naxatw-bg-white naxatw-p-3 naxatw-text-[#475467]`}
        >
          {filteredLayerList?.map(data => (
            <div
              key={data.id}
              className="naxatw-flex naxatw-items-center naxatw-justify-start naxatw-gap-[0.62rem] "
            >
              <input
                id={data.layer__name_en}
                type="checkbox"
                checked={data.checked}
                onChange={() => dispatch(handleOverlayLayerCheckbox(data.id))}
              />
              <label
                className="naxatw-whitespace-nowrap naxatw-text-[0.875rem]"
                htmlFor={data.layer__name_en}
              >
                {data.layer__name_en}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
