import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSpring, animated } from '@react-spring/web';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useRouteParamQuery from '@Hooks/useRouteParamQuery';
import {
  getProvinceData,
  getMunicipalityListByCode,
} from '@Services/portfolioProfile';
import { setProvincialProfileStates } from '@Store/actions/provincialProfile';
import { FlexColumn } from '@Components/common/Layouts';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { cn } from '@Utils/index';
import Sectors from './ChartSection/Sectors';
import ProgrammeDetails from './ChartSection/ProgrammeDetails';
import ProvincialPriorities from './ChartSection/ProvincialPriorities';
import Description from './Description';
import TopHeader from './TopHeader';
import Partners from './ChartSection/Partners';
import MapSection from './MapSection';
import ProvincialProfileMeterices from './Mertices';
import StakeHoldersTable from './StakeholderTable';
import UpdateContent from './Description/UpdateContent';

import './profile.css';

function ProvincialProfile() {
  const { provinceId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getQueryParams = useRouteParamQuery();
  const [municipalityCode, setMunicipalityCode] = useState(
    getQueryParams.get('municipality') || '',
  );

  const [screenshot, setScreenshot] = useState(false);

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  const { data: provinceData } = useQuery({
    queryKey: ['province-name', provinceId],
    queryFn: async () => getProvinceData(provinceId),
    select: (res: any) => res.data,
    enabled: !!provinceId,
  });

  const {
    name: provinceName,
    centroid_lat: provinceLat,
    centroid_lon: provinceLon,
  } = provinceData || {};

  const { data: municipalityData } = useQuery({
    queryKey: ['getMunicipality', municipalityCode],
    queryFn: async () =>
      getMunicipalityListByCode(municipalityCode).then(res => res.data),
    enabled: !!municipalityCode,
  });

  const { name: municipalityName } = municipalityData || {};

  useEffect(() => {
    const code = getQueryParams.get('municipality') || '';
    setMunicipalityCode(code);
  }, [getQueryParams]);

  // set and reset province name
  useEffect(() => {
    if (provinceName) {
      dispatch(setProvincialProfileStates({ provinceName }));
    }

    return () => {
      dispatch(setProvincialProfileStates({ provinceName: '' }));
    };
  }, [dispatch, provinceName]);

  return (
    <animated.div style={{ ...springs }}>
      <TopHeader
        provinceName={provinceName || ''}
        municipalityName={municipalityName || ''}
        onHandleClick={() => {
          if (municipalityCode) {
            setScreenshot(true);
          } else {
            navigate(`/explore-by/geography/${provinceId}/export`);
          }
        }}
      />
      <FlexColumn className="naxatw-w-full naxatw-gap-5 naxatw-pb-5">
        <section className="map-section naxatw-grid naxatw-grid-cols-1 naxatw-gap-5 lg:naxatw-grid-cols-[1fr_2fr]">
          <FlexColumn className="naxatw-gap-4">
            <ProvincialProfileMeterices />
            {municipalityCode && (
              <Description activeProvinceName={provinceData || {}} />
            )}
          </FlexColumn>
          <MapSection
            lat={provinceLat}
            lon={provinceLon}
            screenshot={screenshot}
          />
        </section>

        {!municipalityCode && (
          <section className="update-section naxatw-grid naxatw-grid-cols-1 naxatw-gap-5 lg:naxatw-grid-cols-[1fr_2fr]">
            <Description activeProvinceName={provinceData || {}} />
            {municipalityCode ? <Sectors /> : <UpdateContent />}
          </section>
        )}
        <ProgrammeDetails />

        <section
          className={cn(
            'naxatw-grid naxatw-grid-cols-1 naxatw-gap-5 lg:naxatw-grid-cols-[1fr_2fr]',
            municipalityCode && 'lg:naxatw-grid-cols-1',
          )}
        >
          <Partners />
          <Sectors />
        </section>

        {!municipalityCode && <StakeHoldersTable />}
        {!municipalityCode && <ProvincialPriorities />}
      </FlexColumn>
    </animated.div>
  );
}
export default hasErrorBoundary(ProvincialProfile);
