/* eslint-disable no-unused-vars */
import { ReactNode, useState } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useTypedDispatch } from '@Store/hooks';
import ToolTip from '@Components/RadixComponents/ToolTip';
import SwitchTab from '@Components/common/FormUI/SwitchTab';
import { nodeColors } from '@Constants/SankeyChartData';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import CaptureComponent from '../CaptureComponent';
import FullChart from '../FullChart';
import { ILegendProps } from '../types';

export interface ISwitchTabDataProps {
  switchTabOptions?: any;
  dispatchAction?: ActionCreatorWithPayload<any, string>;
  activeLabel?: string;
  switchBoxTitle?: string;
  objectKey?: string;
}

export interface IChartHeaderProps {
  chartTitle: string;
  hasDownloadBtn?: boolean;
  downloadComponentRef: React.RefObject<any>;
  legend?: (props: ILegendProps<any>) => JSX.Element;
  xLabel?: string;
  yLabel?: string;
  data: any[];
  type: any;
  fill?: string[];
  hasFullScreen?: boolean;
  switchTabData?: ISwitchTabDataProps;
  subTitle?: string;
  chartId?: string;
  showPound?: boolean;
  hoverTitle?: string;
  hasDetails?: boolean;
  hasChart?: boolean;
  noChartContent?: JSX.Element;
  isExport?: boolean;
  barSize?: number;
  nestedChartContent?: ReactNode;
  onChartClick?: (option: unknown) => void;
  legendLabels?: string[];
  hasTableData?: boolean;
}

export default function ChartHeader({
  chartTitle,
  hasDownloadBtn,
  downloadComponentRef,
  legend,
  xLabel,
  yLabel,
  data,
  type,
  fill,
  hasFullScreen = true,
  switchTabData,
  subTitle,
  chartId,
  showPound,
  hoverTitle,
  hasDetails = false,
  hasChart = true,
  noChartContent,
  isExport,
  barSize,
  nestedChartContent,
  onChartClick,
  legendLabels,
  hasTableData,
}: IChartHeaderProps) {
  const [fullScreen, setFullScreen] = useState(false);
  const dispatch = useTypedDispatch();
  const {
    switchTabOptions,
    dispatchAction,
    activeLabel,
    switchBoxTitle,
    objectKey,
  } = switchTabData || {};
  return (
    <div
      className={`naxatw-flex naxatw-items-center naxatw-justify-between naxatw-border-b naxatw-border-solid  ${isExport ? '!naxatw-border-b-pdf-border  naxatw-p-3' : 'naxatw-border-b-primary-200 naxatw-px-6 naxatw-py-[0.9rem]'}`}
    >
      {subTitle ? (
        <FlexColumn className="naxatw-gap-1">
          <p className="naxatw-text-base naxatw-font-bold naxatw-text-matt-200">
            {chartTitle}
          </p>
        </FlexColumn>
      ) : (
        <p
          className={` ${isExport ? 'fs-sm-bold naxatw-text-primary-700' : 'naxatw-subtitle-lg naxatw-text-matt-100'} `}
        >
          {chartTitle}
        </p>
      )}
      <div className="naxatw-flex naxatw-items-center naxatw-justify-end naxatw-gap-5">
        {switchTabOptions && isExport === false && (
          <div
            className={` ${subTitle ? 'naxatw-scale-[0.9]' : 'naxatw-scale-100'} naxatw-cursor-pointer`}
          >
            <SwitchTab
              options={switchTabOptions}
              activeLabel={switchTabOptions[0].label}
              activeValue={activeLabel}
              title={switchBoxTitle || 'View By'}
              defaultBg={false}
              onChange={value => {
                if (dispatchAction) {
                  dispatch(
                    dispatchAction(
                      !objectKey ? value : { key: objectKey, value },
                    ),
                  );
                }
              }}
            />
          </div>
        )}
        <FlexRow className="actions naxatw-items-center naxatw-gap-x-2">
          {hasFullScreen && isExport === false && (
            <FlexRow className="naxatw-group naxatw-cursor-pointer naxatw-items-center naxatw-justify-center naxatw-overflow-hidden naxatw-rounded-[50%] naxatw-p-[0.475rem] naxatw-transition-colors naxatw-duration-200 naxatw-ease-in hover:naxatw-bg-primary-200">
              <ToolTip
                name="open_in_full"
                message="View full screen"
                className="!naxatw-text-[19px] naxatw-text-matt-100 group-hover:naxatw-text-secondary-500"
                messageStyle="naxatw-font-normal"
                iconClick={() => setFullScreen(true)}
              />
            </FlexRow>
          )}
          {hasDownloadBtn && isExport === false && (
            <FlexRow className="naxatw-group naxatw-cursor-pointer naxatw-items-center naxatw-justify-center naxatw-overflow-hidden naxatw-rounded-[50%] naxatw-p-[0.475rem] naxatw-transition-colors naxatw-duration-200 naxatw-ease-in hover:naxatw-bg-primary-200">
              <ToolTip
                name="download"
                message="Download chart"
                className="!naxatw-text-xl  naxatw-text-matt-100 naxatw-duration-200 group-hover:naxatw-text-secondary-500"
                messageStyle="naxatw-font-normal"
                iconClick={() =>
                  CaptureComponent({
                    componentRef: downloadComponentRef,
                    captureName: chartTitle,
                  })
                }
              />
            </FlexRow>
          )}
        </FlexRow>
      </div>
      {hasFullScreen && fullScreen && (
        <FullChart
          fill={fill}
          showFullScreen={fullScreen}
          data={data}
          legend={legend}
          xLabel={xLabel}
          yLabel={yLabel}
          type={type}
          chartTitle={chartTitle}
          onClose={() => setFullScreen(false)}
          chartId={chartId}
          showPound={showPound}
          sankeyChartData={{
            title1: 'Programme',
            title2: 'Working Areas',
            title3: 'Stakeholders',
            nodeColors,
          }}
          hoverTitle={hoverTitle}
          hasDetails={hasDetails}
          hasChart={hasChart}
          noChartContent={noChartContent}
          barSize={barSize}
          nestedChartContent={nestedChartContent}
          onChartClick={onChartClick}
          legendLabels={legendLabels}
          hasTableData={hasTableData}
        />
      )}
    </div>
  );
}
