import { cn } from '@Utils/index';
import ExportBtn from './ExportBtn';

interface IExportHeaderProps {
  onHandleReset?: () => void;
  showResetButton?: boolean;
  onHandleDelete?: () => void;
  className?: string;
  iconStyles?: string;
  size?: string;
  innerClassName?: string;
  headerTitle?: string;
  headerStyles?: string;
  disableCloseBtn?: boolean;
  isStyleAbsolute?: boolean;
  btnStyles?: string;
  subTitle?: string;
  noTitleWithResetBtn?: boolean;
}

const ExportHeader = ({
  onHandleDelete,
  onHandleReset,
  showResetButton,
  className,
  iconStyles,
  innerClassName,
  headerTitle,
  headerStyles,
  disableCloseBtn = false,
  size = 'md',
  isStyleAbsolute,
  btnStyles,
  subTitle = '',
  noTitleWithResetBtn = false,
}: IExportHeaderProps) => {
  return (
    <div
      className={cn(
        `export-buttons-container naxatw-group  naxatw-flex naxatw-items-center  ${headerTitle ? 'naxatw-relative' : 'naxatw-absolute naxatw-right-0 naxatw-top-0 naxatw-z-50 naxatw-h-full naxatw-w-full'}`,
        className,
      )}
      style={{
        justifyContent: headerTitle ? 'space-between' : 'flex-end',
        paddingBottom: headerTitle ? '0.5rem' : '',
      }}
    >
      {headerTitle && (
        <p className={cn('fs-sm-bold naxatw-text-primary-700', headerStyles)}>
          {headerTitle}

          {subTitle ? (
            <span className="fs-sm-semibold naxatw-mt-1 naxatw-block !naxatw-text-[#000]">
              {subTitle}
            </span>
          ) : null}
        </p>
      )}

      <div
        className={cn(
          `${headerTitle ? '' : 'naxatw-h-full naxatw-w-full'} naxatw-relative naxatw-flex naxatw-items-center naxatw-justify-end naxatw-gap-2`,
          innerClassName,
        )}
      >
        {!disableCloseBtn && (
          <ExportBtn
            iconStyles={iconStyles}
            btnStyles={
              noTitleWithResetBtn
                ? `!naxatw-right-[25px] ${btnStyles}`
                : btnStyles
            }
            size={size}
            onHandleClose={onHandleDelete}
            isStyleAbsolute={isStyleAbsolute}
          />
        )}

        {showResetButton && (
          <ExportBtn
            size={size}
            name="restart_alt"
            iconStyles={iconStyles}
            onHandleClose={onHandleReset}
            isStyleAbsolute={isStyleAbsolute}
          />
        )}
      </div>
    </div>
  );
};

export default ExportHeader;
