/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react';
import Portal from '@Components/common/Layouts/Portal';
import { motion } from 'framer-motion';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Icon from '@Components/common/Icon';
import { Button } from '@Components/RadixComponents/Button';
import { useForm, FieldValues } from 'react-hook-form';
import ErrorMessage from '@Components/common/ErrorMessage';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import Skeleton from '@Components/RadixComponents/Skeleton';
import isEmpty from '@Utils/isEmpty';
import { useLocation } from 'react-router-dom';
import { getDocumentShareApiByPathname } from '@Constants/knowledgeRepository';
import EmailsBox from './EmailsBox';
import { Input } from '../../../common/FormUI';
import InputLabel from '../../../common/InputLabel';

interface IDocumentShareModalProps {
  id: any;
  isShareModalOpen?: boolean;
  handleClose: () => void;
  knowledgeRepDoc?: string;
  isDocumentLinkOnlyPreset?: boolean;
}

const DocumentShareModal = ({
  id,
  isShareModalOpen,
  handleClose,
  knowledgeRepDoc,
  isDocumentLinkOnlyPreset,
}: IDocumentShareModalProps) => {
  const { pathname } = useLocation();
  const [emails, setEmails] = useState<string>('');
  const [checkValidation, setCheckValidation] = useState(false);
  const [isDocLinkCopied, setIsDocLinkCopied] = useState<boolean>(false);

  const initialValues: FieldValues = {
    subject: '',
    description: '',
    emails: [],
  };
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    clearErrors,
    getValues,
    setError,
    watch,
  } = useForm({
    defaultValues: initialValues,
  });

  const emailsArray = getValues('emails');

  const handleEmailDelete = (emailIndex: any) => {
    const emailsValues = watch('emails');
    const updatedEmails = emailsValues.filter(
      (_: any, index: number) => index !== emailIndex,
    );
    setValue('emails', updatedEmails);
  };

  const { mutateAsync: shareDocumentInfo, isLoading } = useMutation({
    mutationFn: (data: any) => {
      const getPostShareDocument = getDocumentShareApiByPathname(pathname);
      return getPostShareDocument(data);
    },
    onSuccess: () => {
      handleClose();
      toast.success('Document info shared successfully');
    },
    onError: () => toast.error('Failed to share document info'),
  });

  const handleFormSubmission = () => {
    const { emails: emailx, ...formData } = getValues();
    if (emailx.length === 0) {
      return setError('emails', {
        type: 'manual',
        message: 'Please enter at least one email address',
      });
    }
    return shareDocumentInfo({
      ...formData,
      emails: emailx.join(','),
      id,
    });
  };

  const handleKeyDown = (e: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (e.key === ',' || e.key === 'Enter') {
      e.preventDefault();
      const trimmedEmail = emails.trim();

      if (trimmedEmail.length > 40) {
        setError('emails', {
          type: 'manual',
          message: 'Email address cannot be longer than 40 characters',
        });
        return;
      }
      if (!emailRegex.test(trimmedEmail)) {
        setError('emails', {
          type: 'manual',
          message: 'Please enter a valid email address',
        });
      } else {
        clearErrors('emails');
        setValue('emails', [...emailsArray, trimmedEmail]);
        setEmails('');
      }
    }
  };

  const scaleTranstion = {
    open: { transform: 'scale(1)' },
    closed: { transform: 'scale(0)' },
  };

  useEffect(() => {
    if (checkValidation) {
      if (isEmpty(emailsArray)) {
        setError('emails', {
          type: 'manual',
          message: 'Please enter at least one email address',
        });
      }
    }
  }, [checkValidation, setError, emails]);

  useEffect(() => {
    if (emails) {
      clearErrors('emails');
    }
  }, [emails]);

  return (
    <Portal>
      <div className="naxatw-relative naxatw-h-screen naxatw-w-full">
        <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2  naxatw-w-[90%] naxatw-translate-x-[-50%] naxatw-translate-y-[-50%] naxatw-overflow-hidden naxatw-rounded-[1.25rem] sm:naxatw-max-w-[34.75rem] lg:naxatw-w-1/2 xl:naxatw-w-2/5">
          <motion.div
            className="naxatw-h-full naxatw-bg-white"
            animate={isShareModalOpen ? 'open' : 'closed'}
            variants={scaleTranstion}
          >
            <form onSubmit={handleSubmit(handleFormSubmission)}>
              <FlexRow className="naxatw-w-full naxatw-items-center naxatw-justify-between naxatw-border-b naxatw-px-7 naxatw-py-5 naxatw-shadow-lg">
                <p className="naxatw-text-xl naxatw-font-medium naxatw-leading-normal naxatw-text-matt-100">
                  Share Document
                </p>

                <Icon
                  name="close"
                  className="hover:naxatw-text-grey-900 naxatw-cursor-pointer naxatw-rounded-lg naxatw-p-2 naxatw-text-gray-600 hover:naxatw-bg-grey-200"
                  onClick={() => handleClose()}
                />
              </FlexRow>

              <FlexColumn className="scrollbar  naxatw-gap-5 naxatw-overflow-y-auto naxatw-px-5 naxatw-py-7">
                <FlexColumn className="naxatw-w-full ">
                  <InputLabel label="Subject" astric className="naxatw-mb-1" />
                  <Input
                    className="naxatw-rounded-lg naxatw-text-sm naxatw-leading-[1.125rem] naxatw-text-[#545454]"
                    placeholder="Add Subject"
                    {...register('subject', {
                      required: 'Subject is required',
                      validate: value => {
                        if (!value.trim()) {
                          return 'Subject cannot be empty or just spaces';
                        }
                        return true;
                      },
                    })}
                  />
                  <ErrorMessage
                    className="naxatw-mt-1"
                    message={(errors?.subject?.message as string) || ''}
                  />
                </FlexColumn>
                <FlexColumn className="naxatw-w-full">
                  <InputLabel label="Description" className="naxatw-mb-1" />
                  <textarea
                    className="naxatw-flex naxatw-resize-none  naxatw-rounded-lg naxatw-border naxatw-border-grey-300
            naxatw-bg-transparent  naxatw-p-3 naxatw-text-sm naxatw-leading-[1.125rem]  naxatw-text-[#545454]
             naxatw-outline-primary-400 placeholder:naxatw-text-grey-400 hover:naxatw-border-primary-400 focus:naxatw-border-primary-400 focus:naxatw-bg-transparent focus:naxatw-outline-none"
                    placeholder="Enter Description"
                    rows={3}
                    {...register('description')}
                  />
                  <ErrorMessage
                    message={(errors?.description?.message as string) || ''}
                  />
                </FlexColumn>
                <FlexColumn className="naxatw-w-full">
                  <FlexRow className="naxatw-items-center">
                    <InputLabel
                      label="Emails"
                      astric
                      tooltipMessage="Add emails separated by comma or press enter"
                      className="naxatw-mb-1"
                    />
                  </FlexRow>

                  <Input
                    className="naxatw-rounded-lg naxatw-text-sm naxatw-leading-[1.125rem] naxatw-text-[#545454]"
                    placeholder="Enter Email and Press Enter to Add"
                    onChange={e => setEmails(e.target.value)}
                    onKeyDown={e => handleKeyDown(e)}
                    value={emails}
                  />
                  <ErrorMessage
                    message={(errors?.emails?.message as string) || ''}
                    className="naxatw-mt-1"
                  />
                  <FlexRow className="naxatw-mt-2 naxatw-w-full naxatw-flex-wrap naxatw-gap-2">
                    {emailsArray?.map((email: any, index: any) => (
                      <EmailsBox
                        email={email}
                        removeEmail={() => handleEmailDelete(index)}
                        key={email}
                      />
                    ))}
                  </FlexRow>
                </FlexColumn>
                {/* knowledge Document link */}

                {isDocumentLinkOnlyPreset ? null : (
                  <div className="naxatw-flex naxatw-items-center naxatw-gap-4">
                    {knowledgeRepDoc ? (
                      <div
                        className="naxatw-mt-2 naxatw-flex naxatw-items-center naxatw-gap-2"
                        onClick={() => {
                          setIsDocLinkCopied(true);
                          navigator.clipboard.writeText(knowledgeRepDoc);
                          setTimeout(() => setIsDocLinkCopied(false), 2000);
                        }}
                        onKeyDown={() => {}}
                      >
                        <span className="naxatw-cursor-pointer naxatw-pl-1 naxatw-text-[0.9rem] naxatw-text-secondary-500">
                          Click here to copy document link
                        </span>

                        <Icon
                          name={isDocLinkCopied ? 'done' : 'content_copy'}
                          className="naxatw-flex naxatw-text-lg naxatw-text-secondary-500"
                        />
                        <span className="naxatw-text-[0.9rem] naxatw-text-secondary-500">
                          {isDocLinkCopied ? 'Copied' : null}
                        </span>
                      </div>
                    ) : (
                      <Skeleton className="naxatw-h-8 naxatw-w-72" />
                    )}
                  </div>
                )}
              </FlexColumn>

              <div className="naxatw-flex naxatw-justify-center naxatw-py-5 naxatw-shadow-formshadow">
                <Button
                  size="sm"
                  variant="primary"
                  className="!naxatw-p-4"
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                  onClick={() => setCheckValidation(true)}
                >
                  Share
                </Button>
              </div>
            </form>
          </motion.div>
        </div>
      </div>
    </Portal>
  );
};

export default DocumentShareModal;
