import { useState } from 'react';
import useRouteParamQuery from '@Hooks/useRouteParamQuery';
import Skeleton from '@Components/RadixComponents/Skeleton';

import {
  descriptionData,
  mapWikikpediaByProvince,
} from '@Constants/exploreByGeography';

interface IDescritptionCardProps {
  cardHeadersData: Record<string, any>;
  isLoading?: boolean;
  activeProvinceName?: Record<string, any>;
}

type SkeletonProps = {
  count?: number;
  className?: string;
  containerClassName?: string;
};

function SkeletonText({
  count = 4,
  className,
  containerClassName,
}: SkeletonProps) {
  const renderSkeleton = Array(count)
    .fill(0)
    .map((_, idx) => (
      <Skeleton
        // eslint-disable-next-line
        key={idx}
        className={`naxatw-h-3 ${idx < count - 1 ? 'naxatw-w-full' : 'naxatw-w-8'} ${className || ''}`}
      />
    ));

  return (
    <div
      className={`naxatw-flex naxatw-flex-col naxatw-gap-2 ${containerClassName}`}
    >
      {renderSkeleton}
    </div>
  );
}

export default function DescriptionCard({
  cardHeadersData,
  isLoading,
  activeProvinceName,
}: IDescritptionCardProps) {
  const getQueryParams = useRouteParamQuery();
  const [isSeeMoreToggled, setIsSeeMoreToggled] = useState(false);

  const getMunicipality = getQueryParams.get('municipality') || null;
  const wikipediaUrl = activeProvinceName
    ? mapWikikpediaByProvince[activeProvinceName?.name]
    : {};

  function sliceString(description: string) {
    const maxLength = 230;
    if (description.length <= maxLength) return description;

    // Check if there's a space before or at the maxLength
    const slicePoint = description?.lastIndexOf(' ', maxLength);

    // If a space exists, slice at that point; otherwise, slice at maxLength
    return slicePoint !== -1
      ? description?.slice(0, slicePoint)
      : description?.slice(0, maxLength);
  }

  return (
    <>
      {isLoading ? (
        <div className="naxatw-grid naxatw-gap-6">
          <SkeletonText count={8} />
          <SkeletonText count={4} />
          <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-4">
            <SkeletonText count={2} />
            <SkeletonText count={2} />
          </div>
          <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-4">
            <SkeletonText count={2} />
            <SkeletonText count={2} />
          </div>
          <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-4">
            <SkeletonText count={2} />
            <SkeletonText count={2} />
          </div>
        </div>
      ) : (
        <div className=" naxatw-w-full">
          {!getMunicipality && (
            <div className="naxatw-flex naxatw-flex-col naxatw-gap-8">
              <article className="naxatw-text-[14px] naxatw-text-[#484848]">
                <p className="naxatw-text-ellipsis naxatw-text-[14px] naxatw-text-[#484848]">
                  {cardHeadersData?.description?.length ? (
                    <p className="naxatw-text-justify naxatw-text-[0.875rem]">
                      {isSeeMoreToggled
                        ? cardHeadersData.description
                        : sliceString(cardHeadersData.description)}
                      <button
                        type="button"
                        className="!naxatw-border-0 !naxatw-bg-transparent naxatw-px-1 naxatw-text-[0.875rem] naxatw-font-bold naxatw-leading-[1.25rem] naxatw-text-[#41588F]"
                        onClick={() => setIsSeeMoreToggled(!isSeeMoreToggled)}
                      >
                        {isSeeMoreToggled ? ' See Less' : ' See More'}
                      </button>
                    </p>
                  ) : (
                    'N/A'
                  )}
                </p>

                {isSeeMoreToggled && (
                  <>
                    <br />
                    <a
                      className="naxatw-cursor-pointer naxatw-text-sm naxatw-font-bold naxatw-leading-normal naxatw-text-[#06367D] naxatw-underline hover:naxatw-text-[#63589e]"
                      href={wikipediaUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      More Information
                    </a>
                  </>
                )}
              </article>
              <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-x-4 naxatw-gap-y-5">
                {descriptionData?.data.map((cardHeaders: any) => (
                  <div
                    className=" naxatw-flex naxatw-flex-[1_0_0] naxatw-flex-col naxatw-gap-2"
                    key={cardHeaders.name}
                  >
                    <p className=" naxatw-text-sm naxatw-text-[#475467]">
                      {cardHeaders.name}
                    </p>
                    <p className=" naxatw-text-sm naxatw-font-medium naxatw-text-[#06367D]">
                      {`${cardHeadersData[cardHeaders.value] || 'N/A'}`}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {getMunicipality && (
            <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-y-8">
              <div className=" naxatw-flex naxatw-flex-[1_0_0] naxatw-flex-col naxatw-gap-2">
                <p className=" naxatw-text-sm naxatw-text-[#475467]">Mayor</p>
                <p className=" naxatw-text-base naxatw-font-medium naxatw-text-[#06367D]">
                  Jokh Bahadur Mahar
                </p>
              </div>
              <div className=" naxatw-flex naxatw-flex-[1_0_0] naxatw-flex-col naxatw-gap-2">
                <p className=" naxatw-text-sm naxatw-text-[#475467]">
                  Deputy Mayor
                </p>
                <p className=" naxatw-text-base naxatw-font-medium naxatw-text-[#06367D]">
                  Mahadev Pantha
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
