/* eslint-disable import/prefer-default-export */
import { api, authenticated } from '.';

export const postMapLayers = (data: any) => {
  return authenticated(api).post(`/vectorlayer/`, data);
};

export const getMapLayers = (params?: Record<string, any>) => {
  return authenticated(api).get('/vectorlayer/', { params });
};

export const getIndividualMapLayer = (id: any) => {
  return authenticated(api).get(`/vectorlayer/${id}/`);
};

export const patchMapLayer = (params: Record<string, any>) => {
  const { id, ...restData } = params;
  return authenticated(api).patch(`/vectorlayer/${id}/`, restData);
};

export const deleteMapLayer = (id: number) => {
  return authenticated(api).delete(`/vectorlayer/${id}/`);
};

export const postLayerStyle = (data: any) => {
  return authenticated(api).post('/vectorstyle/', data);
};

export const patchLayerStyle = (data: any) => {
  const { id, ...restData } = data;
  return authenticated(api).patch(`/vectorstyle/${id}/`, restData);
};
