import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import HeaderSwicthTab from '@Components/common/HeaderSwicthTab';
import Searchbar from '@Components/common/SearchBar';
import { Button } from '@Components/RadixComponents/Button';
import {
  // contextualTab,
  getKnowledgeRepoTabByPermission,
  getKnowRepoAddTextName,
  // knowledgeRepoFormTypeInformation,
} from '@Constants/FormConstants/knowledgeRepoConstants';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import { useTypedSelector } from '@Store/hooks';
import { useLocation } from 'react-router-dom';
import { setknowRepoState } from '@Store/actions/dataBankForms';
import { useDispatch } from 'react-redux';

interface IKnowledgeRepoHeaderProps {
  // contextualFormUser?: boolean;
  activeTab?: string;
  onTabChange?: any;
  onAddNewClick?: any;
  needAddButton?: boolean;
}

const KnowledgeRepoHeader = ({
  // contextualFormUser,
  activeTab,
  onTabChange,
  onAddNewClick,
  needAddButton = false,
}: IKnowledgeRepoHeaderProps) => {
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const userGroupPermission = useTypedSelector(
    state => state?.common?.userGroupPermission,
  );

  const searchTerm = useTypedSelector(
    state => state.databankforms.knowledgeRepository.searchTerm,
  );

  const [value, handleChange] = useDebouncedInput({
    ms: 400,
    init: searchTerm,
    onChange: debouncedEvent => {
      dispatch(
        setknowRepoState({ searchTerm: debouncedEvent.target.value.trim() }),
      );
    },
  });

  return (
    <div className=" know-repo-header naxatw-flex naxatw-w-full naxatw-flex-wrap naxatw-items-center naxatw-justify-between naxatw-gap-4 naxatw-px-4">
      {/* {userGroupPermission?.includes('Can view knowledge repository') ? ( */}
      <HeaderSwicthTab
        // headerOptions={
        //   contextualFormUser
        //     ? contextualTab
        //     : knowledgeRepoFormTypeInformation || []
        // }
        headerOptions={
          userGroupPermission
            ? getKnowledgeRepoTabByPermission(userGroupPermission) || []
            : []
        }
        activeTab={activeTab || ''}
        onChange={onTabChange}
      />
      {/* ) : null} */}
      <div className="naxatw-flex naxatw-items-center naxatw-gap-4">
        <Searchbar
          placeholder="Search"
          value={value}
          onChange={handleChange}
          className="naxatw-table-body"
          wrapperStyle="md:naxatw-min-w-[17rem]"
        />
        {needAddButton ? (
          <Button onClick={onAddNewClick}>
            <div className="naxatw-flex naxatw-items-center naxatw-justify-center naxatw-gap-2">
              <span className="material-symbols-outlined naxatw-text-[1.5rem]">
                add
              </span>

              <p className="naxatw-whitespace-nowrap naxatw-text-[0.875rem] naxatw-font-[600]">
                {getKnowRepoAddTextName(pathname)}
              </p>
            </div>
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default hasErrorBoundary(KnowledgeRepoHeader);
