/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface IHeaderSwitchTabProps {
  headerOptions: Record<string, any>;
  activeTab?: string;
  onChange?: any;
}

const HeaderSwicthTab = ({
  headerOptions,
  activeTab,
  onChange,
}: IHeaderSwitchTabProps) => {
  const [activeLabel, setActiveLabel] = useState(activeTab);

  return (
    <div className="naxatw-mt-2 naxatw-flex naxatw-items-center naxatw-gap-2">
      {headerOptions.map((header: Record<string, any>) => (
        <div
          onClick={() => {
            setActiveLabel(header.path);
            if (onChange) {
              onChange(header.path);
            }
          }}
          key={header.id}
          className="naxatw-h-[2.6875rem] naxatw-cursor-pointer naxatw-px-2"
        >
          <Link to={`${header.path}`}>
            {' '}
            <h5
              className={`naxatw-whitespace-nowrap naxatw-border-b-2 naxatw-text-[0.875rem] naxatw-duration-200 hover:naxatw-text-[#0B2E62] ${
                header.activePaths?.includes(activeLabel)
                  ? ' naxatw-border-[#0B2E62] naxatw-py-0 naxatw-text-[#0B2E62]'
                  : 'naxatw-border-transparent naxatw-text-gray-500'
              }`}
            >
              {header.label}
            </h5>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default HeaderSwicthTab;
