/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getProvinceData } from '@Services/portfolioProfile';
import ExportDescription from '@Components/common/CommonExport/Description';
import usePrint from '@Hooks/usePrint';
import { useTypedSelector } from '@Store/hooks';
import ExportSidebar from '@Components/common/CommonExport/ExportSidebar';
import { getKeysByFilter } from '@Constants/PdfContent';
import { deletePdfSection, resetOverallPage } from '@Store/actions/pdfExport';
import { useDispatch } from 'react-redux';
import ExportLogo from '@Components/common/CommonExport/ExportLogo';
import ExportLoader from '@Components/common/CommonExport/ExportLoader';
import { getProvincialSummary } from '@Services/goals';
import { getProvincialCountData } from '@Services/provincialProfile';
import { useEffect, useState } from 'react';
import PortfolioSummary from './PortfolioSummary';
import PdfContentextaul from './PdfContextual';
import PdfMap from './PdfMap';
import PdfWorkingAreas from './PdfWorkingAreas';
import PdfProritiesProfile from './PdfProritiesProfile';
import PdfProgramme from './PdfProgramme';
import ProvinceImage from './ProvinceImage';
import PdfPopulation from './PdfPopulation';
import PdfStakeholderChart from './PdfStakeholderChart';
import PdfSectorChart from './PdfSectorChart';
import PdfPartnerChart from './PdfSectorChart/PdfPartnerChart';
import PdfStakeHolder from './PdfStakeHolder';

const ExportPdf = () => {
  const [hasDescription, setHasDescription] = useState(false);
  const { provinceId } = useParams();
  const navigate = useNavigate();

  const { data: provinceData, isLoading } = useQuery({
    queryKey: ['provinceData-pdf', provinceId],
    queryFn: async () => getProvinceData(provinceId),
    select: res => res?.data,
  });

  const { pdfContentRef, reactToPrintFn } = usePrint({
    title: provinceData && `${provinceData?.name || '-'} Profile`,
  });
  const dispatch = useDispatch();

  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);

  const showProvincialSummary = useTypedSelector(
    state =>
      state.pdfExportSlice.provincialProfile.provincialSummary.showSection,
  );

  const showPopulation = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.population.showSection,
  );

  const showContextual = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.contextual.showSection,
  );

  const showPriorities = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.priorities.showSection,
  );

  const showMap = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.map.showSection,
  );

  const showWorkingAreas = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.workingArea.showSection,
  );

  const showProgramTable = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.programTable.showSection,
  );

  const showImage = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.provinceImage.showSection,
  );

  const showDescriptionSection = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.description.showSection,
  );

  const showStakeholderChart = useTypedSelector(
    state =>
      state.pdfExportSlice.provincialProfile.stakeholderChart.showSection,
  );

  const showSectorChart = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.sectorChart.showSection,
  );

  const showStakholderTable = useTypedSelector(
    state =>
      state.pdfExportSlice.provincialProfile.stakeholderTable.showSection,
  );

  const showPartnerChart = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.partnerChart.showSection,
  );

  const { data: provinceDetails, isLoading: provinceDetailsLoading } = useQuery(
    {
      queryKey: ['province-data-name-pdf', provinceId],
      queryFn: async () => getProvincialSummary(provinceId),
      select: (res: any) => {
        if (!res?.data[0]) return null;
        return res.data[0];
      },
      enabled: !!provinceId,
    },
  );

  const { data: provinceCount, isLoading: provinceCountLoading } = useQuery({
    queryKey: ['province-data-pdf', provinceId],
    queryFn: async () =>
      getProvincialCountData({ province: provinceId, status: filterBy }),
    enabled: !!provinceId,
    select: res => {
      if (!res.data) return null;
      return res.data;
    },
  });

  const showResetButton = Object.entries(
    useTypedSelector(state => state.pdfExportSlice.provincialProfile),
  )?.some(([_, secValue]) => !secValue.showSection);

  const overallDataLoading =
    isLoading || provinceCountLoading || provinceDetailsLoading;

  // cleanup function
  useEffect(() => {
    return () => {
      dispatch(
        resetOverallPage({
          page: 'provincialProfile',
        }),
      );
    };
  }, []);

  return (
    <div className="export-pdf-container">
      {/* ************* SIDEBAR */}

      <ExportSidebar
        heading={`${provinceData?.name} Profile`}
        onHandleBack={() => navigate(`/explore-by/geography/${provinceId}`)}
        reactToPrintFn={reactToPrintFn}
        onHandleReset={() =>
          dispatch(
            resetOverallPage({
              page: 'provincialProfile',
            }),
          )
        }
        showPageResetButton={showResetButton}
      />
      <div className="pdf-content-wrapper scrollbar naxatw-h-[calc(100vh-143px)] naxatw-overflow-y-auto">
        {/* ************ Main Pdf Content Starts **************** */}
        <div
          className="naxatw-relative naxatw-mx-auto naxatw-mb-16 naxatw-w-[210mm] naxatw-bg-white"
          ref={pdfContentRef}
          id="print-content"
        >
          {overallDataLoading ? (
            <ExportLoader />
          ) : (
            <div
              className="pdf-content-container naxatw-flex naxatw-flex-col naxatw-space-y-5 naxatw-px-5 naxatw-pb-5 !naxatw-text-[#000]"
              ref={pdfContentRef}
              id="print-content"
            >
              <ExportLogo />
              <div className="main-heading">
                <h5 className="naxatw-mb-2">
                  {`${provinceData?.name || '-'} Profile`}
                </h5>
                <p className="fs-md-semiold naxatw-text-primary-700">
                  {`BEK’s Current Concentration of ${getKeysByFilter[filterBy]} Programme/Projects `}
                </p>
              </div>

              {showProvincialSummary || showImage ? (
                <div
                  className={`${showImage ? 'naxatw-grid-cols-[2fr_1fr]' : 'naxatw-grid-cols-[1fr]'} naxatw-grid naxatw-gap-2`}
                >
                  {showProvincialSummary && (
                    <PortfolioSummary provinceCount={provinceCount || {}} />
                  )}
                  {showImage && (
                    <ProvinceImage provinceData={provinceData || ''} />
                  )}
                </div>
              ) : null}

              {showDescriptionSection && (
                <ExportDescription
                  setHasDescription={setHasDescription}
                  onHandleClose={() =>
                    dispatch(
                      deletePdfSection({
                        page: 'provincialProfile',
                        section: 'description',
                      }),
                    )
                  }
                />
              )}

              {showPopulation && (
                <PdfPopulation provinceDetails={provinceDetails || {}} />
              )}

              {showContextual && (
                <PdfContentextaul
                  provinceDetails={provinceDetails || {}}
                  provinceName={provinceData?.name || ''}
                />
              )}
              {showPriorities && <PdfProritiesProfile />}
              {showMap && <PdfMap provinceData={provinceData || {}} />}
              {showWorkingAreas && <PdfWorkingAreas />}

              {showSectorChart && <PdfSectorChart />}

              {showPartnerChart && <PdfPartnerChart />}

              {showStakeholderChart && <PdfStakeholderChart />}

              {showStakholderTable && <PdfStakeHolder />}

              {showProgramTable && (
                <PdfProgramme provinceCount={provinceCount || {}} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default hasErrorBoundary(ExportPdf);
