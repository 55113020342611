import { Link } from 'react-router-dom';
import { ColumnSort } from '@tanstack/react-table';
import { useQuery } from '@tanstack/react-query';
import { useTypedSelector } from '@Store/hooks';
import StatusChips from '@Components/common/StatusChips';
import { mappedStatus } from '@Constants/mainDashboard';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { useState } from 'react';
import useSortingConfig from '@Hooks/useSortingConfig';
import {
  mapSortbyParamValue,
  reverseSortbyParamValue,
} from '@Constants/programmeDetails';
import { getProgrammeDetails } from '@Services/provincialProfile';
import NestedDataTable from '../NestedDataTable';

const columns = [
  {
    header: 'PROGRAMME',
    accessorKey: 'programs',
    headerId: 'program',
    className: 'naxatw-w-[15rem]',
  },
  {
    header: 'PROJECT',
    accessorKey: 'project_name',
    headerId: 'project',
    className: 'naxatw-w-[15rem]',
  },
  {
    header: 'FIRST TIER PARTNER',
    accessorKey: 'first_tier_partner',
    className: 'naxatw-w-[15rem]',
  },
  {
    header: 'SECTOR',
    accessorKey: 'sector',
    className: 'naxatw-w-[15rem]',
  },
  {
    header: 'WORKING PROVINCE',
    accessorKey: 'working_province',
    headerId: 'working_area',
    className: 'naxatw-w-[15rem]',
  },
  {
    header: 'BUDGET ALLOCATED (£)',
    accessorKey: 'budget',
    cell: ({ childRowData }: Record<string, any>) => {
      return (
        <div>
          {childRowData?.budget ? childRowData?.budget?.toLocaleString() : '-'}
        </div>
      );
    },
    className: 'naxatw-w-[15rem]',
  },

  {
    header: 'CAMPAIGN GOALS',
    accessorKey: 'campaign_goal',
    className: 'naxatw-w-[15rem]',
  },
  {
    header: 'STATUS',
    accessorKey: 'project_status',
    headerId: 'project_status',
    className: 'naxatw-w-[6rem]',

    cell: ({ childRowData }: Record<string, any>) => {
      return (
        <div className="naxatw-mr-auto naxatw-py-4">
          {childRowData?.project_status ? (
            <StatusChips
              label={childRowData.project_status || ''}
              status={mappedStatus[childRowData.project_status] || ''}
            />
          ) : (
            '-'
          )}
        </div>
      );
    },
  },
  {
    header: '',
    accessorKey: '',
    cell: ({ parentRowData, childRowData }: Record<string, any>) => {
      return (
        <Link
          to={`/explore-by/programmes/${parentRowData?.program_id}/?componentId=${childRowData?.project_id}`}
        >
          <ToolTip
            name="open_in_new"
            message="Project Details"
            className="naxatw-rounded-full naxatw-p-1 naxatw-text-xl naxatw-text-primary-600 naxatw-duration-300 hover:naxatw-bg-primary-200"
            iconClick={() => {
              window.open(
                `/explore-by/programmes/${parentRowData?.program_id}/?componentId=${childRowData?.project_id}`,
                '_blank',
              );
            }}
          />
        </Link>
      );
    },
  },
];

interface IProgrammeTable {
  searchTerm?: string;
  isFilterActive?: boolean;
  applyGlobalFilter?: boolean;
  className?: string;
}

const ProgrammeTable = ({
  searchTerm,
  isFilterActive,
  applyGlobalFilter,
  className,
}: IProgrammeTable) => {
  const statusFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );

  const filterFields = useTypedSelector(
    state => state.programme.report?.filterParams,
  );

  const [sorting, setSorting] = useState<ColumnSort>({ id: '', desc: false });

  const { sortBy, sortOrderKey, sortDir } = useSortingConfig(
    sorting?.id,
    sorting.desc,
  );

  // Header Filters table header

  const programmeFilter = filterFields?.program || [];
  const FirstTierPartnerFilter = filterFields?.first_tier_partner || [];
  const projectFilter = filterFields?.project || [];
  const sectorFilter = filterFields?.sector || [];
  const provinceFilter = filterFields?.province || [];
  const campaignGoalFilter = filterFields?.campaign_goal || [];
  const budgetFilter = filterFields?.budget || [];

  // global filter
  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const componentFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );

  const subMarkersParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );
  const provinceFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.province,
  );

  const filterDateRange = startDate && endDate ? `${startDate}-${endDate}` : '';

  const commonQueryKeys = [
    'programme-details-table-data',
    statusFilterParams,
    searchTerm || '',
    sortBy,
    sortDir,
  ];

  const queryKeysForHeaderFilterOnly = [
    ...commonQueryKeys,
    // Header filters
    projectFilter,
    programmeFilter,
    FirstTierPartnerFilter,
    sectorFilter,
    provinceFilter,
    campaignGoalFilter,
    budgetFilter,
  ];

  const queryKeysForGlobalFilterOnly = [
    ...commonQueryKeys,
    // global Filter
    programmeFilterParams,
    componentFilterParams,
    firstTierPartnerParams,
    sectorGroupParams,
    markerGroupParams,
    subMarkersParams,
    provinceFilterParams,
    filterDateRange,
  ];

  const commonQueryParams = {
    status: statusFilterParams,
    search_term: searchTerm ? searchTerm?.trim() : '',
    [sortOrderKey]: sortDir,
    sort_by: sortBy,
  };

  const queryParamsForHeaderFilterOnly = {
    ...commonQueryParams,
    program: programmeFilter?.join(','),
    project: projectFilter?.join(','), // project
    first_tier_partner: FirstTierPartnerFilter?.join(','),
    sector: sectorFilter?.join(','),
    province: provinceFilter?.join(','),
    goal: campaignGoalFilter?.join(','),
    budget: budgetFilter?.join(','),
  };

  const queryParamsForGlobalFilterOnly = {
    ...commonQueryParams,
    program: programmeFilterParams?.join(','),
    project: componentFilterParams?.join(','),
    first_tier_partner: firstTierPartnerParams?.join(','),
    sector: sectorGroupParams?.join(','),
    marker: markerGroupParams?.join(','),
    marker_category: subMarkersParams?.join(','),
    province: provinceFilterParams?.join(','),
    start_date: startDate,
    end_date: endDate,
  };

  const { data: programmeDetails, isLoading: programmeDetailsIsLoading } =
    useQuery({
      queryKey: applyGlobalFilter
        ? queryKeysForGlobalFilterOnly
        : queryKeysForHeaderFilterOnly,
      queryFn: async () =>
        getProgrammeDetails(
          applyGlobalFilter
            ? queryParamsForGlobalFilterOnly
            : queryParamsForHeaderFilterOnly,
        ),
      select: res => res.data,
    });

  return (
    <NestedDataTable
      columns={columns}
      data={programmeDetails}
      mainDataKey="programs"
      nestingDataKey="data"
      isFilterActive={isFilterActive}
      isLoading={programmeDetailsIsLoading}
      sortingState={{ ...sorting, id: reverseSortbyParamValue(sorting.id) }}
      onSorting={opt =>
        setSorting({ id: mapSortbyParamValue(opt?.id), desc: opt.desc })
      }
      className={className}
    />
  );
};

export default ProgrammeTable;
