import Icon from '@Components/common/Icon';
import IconButton from '@Components/common/IconButton';
import { FlexRow } from '@Components/common/Layouts';
import { cn } from '@Utils/index';
import { useState } from 'react';

export default function ChartInfoBanner({ className }: { className?: string }) {
  const [showDetailPopup, setShowDetailPopup] = useState(true);

  return (
    <FlexRow
      className={cn(
        'naxatw-absolute naxatw-right-4 naxatw-top-20 naxatw-z-10 naxatw-items-center naxatw-gap-1 naxatw-rounded-lg naxatw-border naxatw-border-primary-200 naxatw-bg-white naxatw-p-3 naxatw-shadow',
        !showDetailPopup && 'naxatw-hidden',
        className,
      )}
    >
      <Icon name="info" className="naxatw-text-base naxatw-text-matt-100" />
      <span className="naxatw-mr-5 naxatw-text-sm naxatw-text-matt-200">
        Click on chart to view detail
      </span>

      <IconButton
        name="close"
        className="!naxatw-h-auto !naxatw-w-auto naxatw-rounded-[50%] naxatw-p-1 hover:naxatw-bg-primary-200"
        iconClassName="naxatw-text-sm "
        onClick={() => setShowDetailPopup(false)}
      />
    </FlexRow>
  );
}
