import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { deletePdfSection } from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useDispatch } from 'react-redux';

const PalikaImage = ({ palikaImage }: { palikaImage: any }) => {
  const dispatch = useDispatch();

  const showSummary = useTypedSelector(
    state => state.pdfExportSlice.palikaDetails.summary.showSection,
  );

  return (
    <ExportRoundedContainer
      className={`palika-image-container naxatw-relative  ${showSummary ? 'naxatw-basis-[35%]' : 'naxatw-h-[300px] naxatw-basis-full'} `}
    >
      <ExportHeader
        onHandleDelete={() => {
          dispatch(
            deletePdfSection({
              page: 'palikaDetails',
              section: 'palikaImage',
            }),
          );
        }}
      />
      <img
        src={palikaImage}
        alt="palika"
        className="naxatw-h-full naxatw-w-full naxatw-scale-[2] naxatw-object-cover"
      />
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(PalikaImage);
