/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { z } from 'zod';

export enum knowledgeRepoFormTypeEnum {
  BasicDetails = 'basicDetails',
  DocumentDetails = 'documentDetails',
  UploadDocuments = 'uploadDocuments',
}

export const KnowledgeRepoBasicDetailValidationSchema = z
  .object({
    title: z
      .string()
      .trim()
      .min(1, 'Title is Required')
      .max(250, 'Name cannot exceed 250 characters.')
      .refine(val => !/([!@#$%^&*()_+=[\]{};':"\\|,.<>/?])\1{5,}/.test(val), {
        message:
          'Title should not contain more than 5 consecutive special characters',
      }),
    file_type: z.string().trim().min(1, 'File Type is Required'),
    associate_program_project: z.record(z.any()).optional(),
    published_date: z.string().trim().min(1, 'Published date is Required'),
    other_program_project: z.string().trim().nullable().optional(),
  })
  .refine(
    data => {
      const hasParentOther =
        data.associate_program_project &&
        data.associate_program_project.parent_other === true;
      if (hasParentOther && data.other_program_project === '') {
        return false;
      }
      return true;
    },
    {
      message: 'Other Program Name is Required',
      path: ['other_program_project'],
    },
  );

export const KnowRepoContextualBasicDetailValidationSchema = z
  .object({
    title: z.string().trim().min(1, 'Title is Required'),
    file_type: z.string().trim().min(1, 'File type is Required'),
    associate_program_project: z.record(z.any()).optional(),
    published_date: z.string().trim().min(1, 'Published date is Required'),
    other_program_project: z.string().trim().nullable().optional(),
  })
  .refine(
    data => {
      const hasParentOther =
        data.associate_program_project &&
        data.associate_program_project.parent_other === true;
      if (hasParentOther && data.other_program_project === '') {
        return false;
      }
      return true;
    },
    {
      message: 'Other Program Name is Required',
      path: ['other_program_project'],
    },
  );
