/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import { Input } from '@Components/common/FormUI';
import ErrorMessage from '@Components/common/ErrorMessage';
import FileUpload from '@Components/common/FormUI/Upload';
import IconButton from '@Components/common/IconButton';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
// import { zodResolver } from '@hookform/resolvers/zod';
import {
  getVideoTutorialById,
  patchVideoTutorial,
  postVideoTutorial,
} from '@Services/tutorials';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// import { VideosTutorialFormValidationSchema } from '@Validations/Tutorials';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import isEmpty from '@Utils/isEmpty';

interface IVideoRegistrationFormProps {
  onClose: () => void;
}

const VideosRegistrationForm = ({ onClose }: IVideoRegistrationFormProps) => {
  const { tutId } = useParams();
  const queryClient = useQueryClient();
  const initialValues: FieldValues = {
    title: '',
    description: '',
    video: [],
    thumbnail: null,
  };

  const {
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    setValue,
    register,
    control,
    reset,
    watch,
    getValues,
  } = useForm({
    defaultValues: initialValues,
    // resolver: zodResolver(VideosTutorialFormValidationSchema),
  });

  const { mutate: postVideo, isLoading: videoIsUploading } = useMutation({
    mutationFn: (data: FieldValues) => postVideoTutorial(data),
    onSuccess: () => {
      toast.success('Video added successfully');
      queryClient.invalidateQueries(['videos-table-data']);
      onClose();
    },
    onError: () => {
      toast.error('Failed to add video');
    },
  });

  const { mutate: patchVideo, isLoading: videoIsPatching } = useMutation({
    mutationFn: (data: FieldValues) => patchVideoTutorial(data),
    onSuccess: () => {
      toast.success('Tutorial Updated successfully');
      queryClient.invalidateQueries(['videos-table-data']);
      onClose();
    },
    onError: () => {
      toast.error('Failed to update tutorial');
    },
  });

  const { isLoading: isVideoDataLoading } = useQuery({
    queryKey: ['video-tutorial-by-id', tutId],
    queryFn: () => getVideoTutorialById(tutId || ''),
    select: res => res?.data,
    enabled: !!tutId,
    onSuccess: response => {
      if (response) {
        Object.keys(response).forEach((key: string) => {
          const value = response[key];

          if (key === 'video' || (key === 'thumbnail' && value)) {
            const finalDocuments = [{ file: value, id: Number(tutId) }];
            setValue(key, finalDocuments);
          } else {
            setValue(key, value);
          }
        });
      }
    },
  });

  const thumbnail = watch('thumbnail');

  const patchVideoForm = async (updatedForm: Record<string, any>) => {
    const finalUpdateData = {
      id: tutId,
      ...updatedForm,
      ...(updatedForm?.thumbnail
        ? isEmpty(updatedForm.thumbnail)
          ? { thumbnail: '' }
          : { thumbnail: updatedForm.thumbnail[0]?.file }
        : {}),
      ...(updatedForm?.video ? { file: updatedForm.video[0]?.file } : {}),
    };

    patchVideo(finalUpdateData);
  };

  const getDirtyFieldValues = () => {
    const allValues = getValues();
    const dirtyValues: any = {};
    Object.keys(allValues).forEach((key: string) => {
      if (dirtyFields[key as keyof typeof dirtyFields]) {
        dirtyValues[key] = allValues[key as keyof typeof dirtyFields];
      }
    });

    return dirtyValues;
  };

  const handleFormSubmit = async () => {
    if (tutId) {
      if (isDirty === false) {
        onClose();
        return;
      }
      const editFields = getDirtyFieldValues();
      await patchVideoForm(editFields);
      return;
    }

    const { video, ...restValues } = getValues();

    const videoBianry = video[0]?.file;
    const thumbnailBinary = thumbnail?.[0]?.file;

    postVideo({
      ...restValues,
      video: videoBianry,
      ...(thumbnail ? { thumbnail: thumbnailBinary } : {}),
    });
  };

  const overallPatching = videoIsUploading || videoIsPatching;

  return (
    <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-w-full naxatw-translate-x-[-50%] naxatw-translate-y-[calc(-50%+31.5px)] naxatw-items-center naxatw-justify-center sm:naxatw-w-[34.75rem]">
      <div className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-rounded-2xl naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 ">
        <div className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-justify-between naxatw-px-7 naxatw-py-5 naxatw-shadow-light">
          <BreadCrumb
            heading={`Tutorials/ ${tutId ? 'Edit' : 'Add'}`}
            overlayStatus={() => onClose()}
          />
          <IconButton
            name="close"
            className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
            iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
            onClick={() => onClose()}
          />
        </div>
        <div className="naxatw-flex naxatw-w-full naxatw-gap-3 ">
          <div className="naxatw-w-full">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              {tutId && isVideoDataLoading ? (
                <div className="naxatw-mx-auto naxatw-w-11/12">
                  <FormSkeleton numRows={6} className="naxatw-w-full" />
                </div>
              ) : (
                <div className="naxatw-py-5 naxatw-pl-6 naxatw-pr-4">
                  <FlexColumn className="scrollbar naxatw-h-[calc(100vh-20.8rem)] naxatw-w-full naxatw-overflow-y-scroll naxatw-pr-2">
                    <FlexColumn className="naxatw-w-full naxatw-gap-1">
                      <InputLabel label="Title" astric />
                      <Input
                        type="text"
                        placeholder="Title"
                        className="!naxatw-w-full"
                        {...register('title', {
                          required: 'Title is Required',
                          minLength: {
                            value: 1,
                            message: 'Title must be at least 1 character long',
                          },
                          maxLength: {
                            value: 255,
                            message:
                              'Title must be at most 255 characters long',
                          },
                        })}
                      />
                      {errors?.title?.message && (
                        <ErrorMessage
                          message={
                            typeof errors?.title?.message === 'string'
                              ? errors.title.message
                              : ''
                          }
                        />
                      )}
                    </FlexColumn>
                    <FlexColumn className="naxatw-w-full naxatw-gap-1 naxatw-pt-5">
                      <InputLabel label="Description" />
                      <Input
                        type="text"
                        placeholder="Description"
                        className="!naxatw-w-full"
                        {...register('description')}
                      />
                      {errors?.description?.message && (
                        <ErrorMessage
                          message={
                            typeof errors?.description?.message === 'string'
                              ? errors.description.message
                              : ''
                          }
                        />
                      )}
                    </FlexColumn>
                    <FlexColumn className="naxatw-w-full  naxatw-gap-1 naxatw-pt-5">
                      <InputLabel label="Thumbnail" />
                      <Controller
                        control={control}
                        name="thumbnail"
                        render={({ field: { value, onChange } }) => {
                          return (
                            <FileUpload
                              name="thumbnail"
                              register={register}
                              setValue={setValue}
                              data={value}
                              multiple={false}
                              fileAccept="image/*"
                              onChange={() =>
                                onChange(getValues?.()?.thumbnail)
                              }
                              placeholder="Please upload an image (jpeg, jpg, etc.)"
                              control={control}
                            />
                          );
                        }}
                      />{' '}
                    </FlexColumn>
                    <FlexColumn className="naxatw-w-full  naxatw-gap-1 naxatw-pt-5">
                      <InputLabel label="Video" astric />
                      {/* <FileUpload
                        name="video"
                        register={register}
                        setValue={setValue}
                        data={videoData?.video}
                        multiple={false}
                        fileAccept="video/*"
                        placeholder="Please upload a video (mp4, avi, etc). Video size must be less than 100MB."
                        control={control}
                      /> */}
                      <Controller
                        control={control}
                        name="video"
                        rules={{
                          validate: () => {
                            const { video } = getValues();
                            if (isEmpty(video)) {
                              return 'Video is required';
                            }
                            return true;
                          },
                        }}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <FileUpload
                              name="video"
                              data={value}
                              onChange={() => onChange(getValues?.()?.video)}
                              multiple={false}
                              fileAccept="video/*"
                              placeholder="Please upload a video (mp4, avi, etc). Video size must be less than 100MB."
                              maxSize={100}
                              control={control}
                              register={register}
                              setValue={setValue}
                            />
                          );
                        }}
                      />
                      {errors?.video?.message && (
                        <ErrorMessage
                          message={
                            typeof errors?.video?.message === 'string'
                              ? errors.video.message
                              : ''
                          }
                        />
                      )}
                    </FlexColumn>
                  </FlexColumn>
                </div>
              )}

              <div className=" naxatw-flex naxatw-justify-center naxatw-py-5 naxatw-shadow-formshadow">
                <Button
                  size="normal"
                  type="submit"
                  variant="primary"
                  className="naxatw-w-fit naxatw-px-4"
                  isLoading={overallPatching}
                  disabled={
                    tutId
                      ? overallPatching || isVideoDataLoading
                      : overallPatching
                  }
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideosRegistrationForm;
