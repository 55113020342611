import { useParams } from 'react-router-dom';
import Metric from '@Components/common/Card/Metric';
import Icon from '@Components/common/Icon';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Skeleton from '@Components/RadixComponents/Skeleton';
import useOverallCountData from '@Hooks/getOverallCountData';
import useRouteParamQuery from '@Hooks/useRouteParamQuery';
import { useTypedSelector } from '@Store/hooks';

interface printable {
  print?: boolean;
}

function CloseButton() {
  return <p style={{ height: '4px', width: '4px' }}>X</p>;
}
export default function ProvincialProfileMeterices(props: printable) {
  const { provinceId } = useParams();
  const getQueryParams = useRouteParamQuery();
  const municipalityCode = getQueryParams.get('municipality');
  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);

  const { data, isLoading } = useOverallCountData(
    municipalityCode
      ? {
          province: provinceId,
          municipality: municipalityCode,
          status: filterBy,
        }
      : { province: provinceId, status: filterBy },
  );

  const btnClose = props.print && <CloseButton />;

  return (
    <div className="naxatw-grid naxatw-grid-cols-1 naxatw-gap-4">
      <div className="naxatw-grid naxatw-grid-cols-1 naxatw-gap-4 sm:naxatw-grid-cols-2">
        <div>
          {btnClose}
          <Metric
            label="Programmes"
            iconName="folder_open"
            metricCount={data?.program_count}
            isLoading={isLoading}
            className="!naxatw-text-primary-700"
          />
        </div>
        <div>
          {btnClose}
          {/* Optional else part */}
          <Metric
            label="Projects"
            iconName="article"
            metricCount={data?.project_count}
            isLoading={isLoading}
          />
        </div>
        <div>
          {btnClose}
          <Metric
            label="Sectors"
            iconName="category"
            metricCount={data?.sector_count}
            isLoading={isLoading}
          />
        </div>
        <div>
          {btnClose}
          <Metric
            label="Markers"
            iconName="donut_small"
            metricCount={data?.marker_count}
            isLoading={isLoading}
          />
        </div>
      </div>

      {!municipalityCode &&
        (isLoading ? (
          <FlexRow className="naxatw-items-start naxatw-gap-3 naxatw-rounded-xl naxatw-border naxatw-border-gray-100 naxatw-bg-gray-50 naxatw-p-3">
            <Skeleton className="naxatw-h-12 naxatw-w-12 naxatw-rounded-full" />
            <FlexColumn className="naxatw-flex-1 naxatw-gap-3">
              <Skeleton className="naxatw-h-4 naxatw-w-6/12" />
              <FlexRow className="naxatw-mt-6 naxatw-max-w-40 naxatw-justify-between">
                <Skeleton className="naxatw-h-5 naxatw-w-14" />
                <Skeleton className="naxatw-h-5 naxatw-w-14" />
              </FlexRow>
            </FlexColumn>
          </FlexRow>
        ) : (
          <div>
            {btnClose}
            <FlexRow className="naxatw-gap-3 naxatw-rounded-xl naxatw-border naxatw-border-gray-200 naxatw-bg-white naxatw-p-3 naxatw-text-primary-700">
              <Icon
                name="location_on"
                className="naxatw-cursor-default !naxatw-items-baseline naxatw-rounded-full  naxatw-bg-primary-100 naxatw-p-3 naxatw-text-calm-teal"
                style={{ alignItems: 'flex-start' }}
              />
              <article className="naxatw-min-w-0 naxatw-max-w-[18rem] naxatw-flex-1 naxatw-pt-3">
                <p className="naxatw-font-semibold">Working Areas</p>
                <FlexRow className="naxatw-mt-3 naxatw-justify-between naxatw-py-3">
                  <div className="naxatw-text-primary-700">
                    <b className="naxatw-mr-[10px] naxatw-text-xl">
                      {data?.district_count || 0}
                    </b>
                    District
                  </div>
                  <div className="naxatw-text-primary-700">
                    <b className="naxatw-mr-[10px] naxatw-text-xl">
                      {data?.municipality_count || 0}
                    </b>
                    Municipality
                  </div>
                </FlexRow>
              </article>
            </FlexRow>
          </div>
        ))}
      <div>
        {btnClose}
        <Metric
          label="First Tier Partner"
          iconName="handshake"
          metricCount={data?.first_tier_partner_count}
          isLoading={isLoading}
        />
      </div>
      {!municipalityCode && (
        <div>
          {btnClose}
          <Metric
            label="Stakeholders"
            iconName="groups"
            metricCount={data?.stakeholder_count}
            isLoading={isLoading}
          />
        </div>
      )}
    </div>
  );
}
