import { useEffect, useState } from 'react';
import AdminHeader from '@Components/common/AdminHeader';
import { useSpring, animated } from '@react-spring/web';
import { useLocation, useNavigate } from 'react-router-dom';
import Portal from '@Components/common/Layouts/Portal';
import { useTypedSelector } from '@Store/hooks';
import ContactTable from './ContactTable';
import ContactForm from './ContactForm';

const Contacts = () => {
  const navigate = useNavigate();
  const [toggleForm, setToggleForm] = useState<boolean>(false);
  const { pathname } = useLocation();
  const userGroupPermission = useTypedSelector(
    state => state?.common?.userGroupPermission,
  );

  useEffect(() => {
    if (pathname.includes('edit') || pathname.includes('add')) {
      setToggleForm(true);
    } else {
      setToggleForm(false);
    }
  }, [pathname]);

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  return (
    <animated.div
      className="naxatw-flex naxatw-h-full naxatw-flex-col naxatw-space-y-6"
      style={{ ...springs }}
    >
      <AdminHeader
        title="Contacts"
        onAddNewClick={() => {
          navigate('/data-bank/contact/add');
        }}
        registerButtonText="Add New Contacts"
        needAddNewButton={
          userGroupPermission?.includes('Can add contact') ?? false
        }
      />
      {userGroupPermission?.includes('Can view contact') ? (
        <ContactTable />
      ) : null}
      {toggleForm && (
        <Portal>
          <ContactForm onClose={() => navigate('/data-bank/contacts')} />
        </Portal>
      )}
    </animated.div>
  );
};

export default Contacts;
