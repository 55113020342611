import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import CustomDonutChart from '@Components/common/Charts/DonutChart';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import ContentBox from '@Components/common/ContentBox';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { getProjectStatus } from '@Services/provincialProfile';

const chartColors = ['#719ED7', '#B7E7E4'];

function ProgrammeProjctStatusChart({
  isExport = false,
}: {
  isExport?: boolean;
}) {
  const { programmeId } = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ['program'],
    queryFn: () => getProjectStatus({ program: programmeId }),
    select: res =>
      res.data?.map((record: Record<string, any>) => ({
        name: record?.status,
        value: record?.count,
      })) || [],
  });

  return (
    <div className="status-project naxatw-h-full [&>*]:!naxatw-h-full">
      <ContentBox
        title="Status of Project"
        className={`naxatw-h-full naxatw-overflow-hidden naxatw-border  ${isExport ? '!naxatw-rounded-lg !naxatw-border-pdf-border' : ''}`}
        headerClassName={
          isExport
            ? '!naxatw-border-b-pdf-border'
            : ' !naxatw-border-b !naxatw-border-b-primary-200'
        }
        isPdfExport={isExport}
      >
        {isLoading ? (
          <FlexColumn gap={4} className="naxatw-items-center naxatw-px-5">
            <Skeleton className="naxatw-h-[12rem] naxatw-w-[12rem] naxatw-rounded-full" />
            <FlexColumn className="naxatw-w-full" gap={3}>
              <FlexRow className="naxatw-w-full naxatw-justify-between">
                <Skeleton className="naxatw-h-4 naxatw-w-24" />
                <Skeleton className="naxatw-h-4 naxatw-w-6" />
              </FlexRow>
              <FlexRow className="naxatw-w-full naxatw-justify-between">
                <Skeleton className="naxatw-h-4 naxatw-w-24" />
                <Skeleton className="naxatw-h-4 naxatw-w-6" />
              </FlexRow>
            </FlexColumn>
          </FlexColumn>
        ) : (
          <FlexColumn className="naxatw-h-full naxatw-items-center naxatw-justify-center naxatw-gap-6 naxatw-pb-4">
            {!isLoading && data?.length === 0 ? (
              <NoChartDataComponent />
            ) : (
              <>
                <div className="naxatw-w-full naxatw-max-w-[11rem]">
                  <CustomDonutChart
                    data={data}
                    fills={chartColors}
                    maxHeight={182}
                    minHeight={182}
                    innerRadius={30}
                  />
                </div>
                <ul className="naxatw-w-full naxatw-max-w-[9rem] naxatw-grid-cols-2 naxatw-gap-x-6">
                  {data?.map(
                    (record: { name: string; value: number }, idx: number) => (
                      <li
                        key={record?.name}
                        className="naxatw-body-caption naxatw-flex naxatw-justify-between naxatw-py-1 naxatw-text-gray-800"
                      >
                        <span
                          className={`naxatw-flex naxatw-items-center naxatw-gap-2 ${isExport ? 'fs-xs-medium' : ''} `}
                        >
                          <span
                            className={`${isExport ? 'naxatw-size-2' : 'naxatw-size-3 '} naxatw-inline-block naxatw-rounded-[0.25rem] `}
                            style={{ background: chartColors[idx] }}
                          />
                          {record?.name}
                        </span>
                        <strong
                          className={`${isExport ? 'fs-xs-bold !naxatw-text-black ' : 'naxatw-font-bold'} `}
                        >
                          {record?.value}
                        </strong>
                      </li>
                    ),
                  )}
                </ul>
              </>
            )}
          </FlexColumn>
        )}
      </ContentBox>
    </div>
  );
}
export default hasErrorBoundary(ProgrammeProjctStatusChart);
