/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState, useCallback, useEffect } from 'react';
import { Editor } from '@tiptap/react';
import { TooltipWrapper } from '@Components/RadixComponents/ToolTip';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '@Components/RadixComponents/DropDownMenu';
import { DropdownMenuContent } from '@radix-ui/react-dropdown-menu';
import Input from '@Components/common/FormUI/Input';
import TipTapExtensionContainer from './TipTapExtenstionContainer';

type ToolbarProps = {
  editor: Editor | null;
};

export default function Toolbar({ editor }: ToolbarProps) {
  const [imageURL, setImageURL] = useState('');
  const [linkText, setLinkText] = useState('');
  const [linkDropdownOpen, setLinkDropdownOpen] = useState(false);
  const [imageDropdownOpen, setImageDropdownOpen] = useState(false);

  // set active extension styles
  const isEditorActive = (editorItem: string) => {
    if (editor?.isActive(editorItem)) {
      return 'naxatw-text-primary-700 naxatw-bg-secondary-200';
    }
    return '';
  };

  // add image to editor
  const addImage = useCallback(() => {
    if (imageURL) {
      editor?.chain().focus().setImage({ src: imageURL }).run();
    }
    setImageURL('');
    setImageDropdownOpen(false);
  }, [editor, imageURL]);

  const setLink = useCallback(() => {
    const url = linkText;
    // cancelled
    if (url === null) {
      return;
    }
    // empty
    if (url === '') {
      editor?.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }
    // update link
    editor
      ?.chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url })
      .run();
    setLinkText('');
    setLinkDropdownOpen(false);
  }, [editor, linkText]);

  // prevent form submission
  // when link dropdown is opened and user hit "Enter" key
  useEffect(() => {
    function handlePreventFormSubmit(eve: KeyboardEvent) {
      if (eve.key === 'Enter') {
        if (linkDropdownOpen) {
          eve?.preventDefault();
          setLink();
        }
      }
    }
    window.addEventListener('keypress', handlePreventFormSubmit);
    return () => {
      window.removeEventListener('keypress', handlePreventFormSubmit);
    };
  }, [linkDropdownOpen, setLink]);

  return (
    <div className="naxatw-flex naxatw-justify-between naxatw-border-b-[1px] naxatw-border-gray-300 naxatw-px-2">
      <div className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-justify-between naxatw-py-1">
        <div className="naxatw-flex naxatw-flex-wrap naxatw-gap-2 ">
          {/* Heading */}
          <DropdownMenu>
            <DropdownMenuTrigger
              className={`extension-btn naxatw-grid naxatw-w-7 naxatw-cursor-pointer naxatw-place-items-center naxatw-overflow-hidden naxatw-rounded hover:naxatw-bg-secondary-200  ${isEditorActive(
                'heading',
              )}`}
            >
              <TooltipWrapper tooltipMessage="Heading">
                <div
                  className={` naxatw-flex naxatw-h-[24px] naxatw-w-[24px] naxatw-items-center naxatw-justify-center  naxatw-text-xl naxatw-text-gray-800`}
                >
                  <strong className="naxatw-text-base">H</strong>
                </div>
              </TooltipWrapper>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="custom-box-shadow naxatw-z-50 naxatw-flex naxatw-gap-1 naxatw-rounded naxatw-border naxatw-border-gray-300 naxatw-bg-white"
              sideOffset={6}
              align="start"
            >
              <DropdownMenuItem className="!naxatw-px-0 !naxatw-py-0 !naxatw-text-[0.875rem] hover:!naxatw-bg-transparent">
                <TooltipWrapper
                  tooltipMessage="Heading 1"
                  triggerStyleClassName="extension-btn hover:naxatw-bg-secondary-200 naxatw-cursor-pointer   naxatw-duration-200 naxatw-transition-colors naxatw-ease-in"
                >
                  <p
                    onClick={() =>
                      editor?.chain().focus().toggleHeading({ level: 1 }).run()
                    }
                    className={`naxatw-px-[0.375rem] naxatw-py-1 ${
                      editor?.isActive('heading', { level: 1 })
                        ? 'naxatw-bg-secondary-200'
                        : ''
                    } `}
                  >
                    H<sub>1</sub>
                  </p>
                </TooltipWrapper>
                <TooltipWrapper
                  tooltipMessage="Heading 2"
                  triggerStyleClassName="extension-btn hover:naxatw-bg-secondary-200 naxatw-cursor-pointer   naxatw-duration-200 naxatw-transition-colors naxatw-ease-in"
                >
                  <p
                    onClick={() =>
                      editor?.chain().focus().toggleHeading({ level: 2 }).run()
                    }
                    className={`naxatw-px-[0.375rem] naxatw-py-1   ${
                      editor?.isActive('heading', { level: 2 })
                        ? 'naxatw-bg-secondary-200'
                        : ''
                    }`}
                  >
                    H<sub>2</sub>
                  </p>
                </TooltipWrapper>
                <TooltipWrapper
                  tooltipMessage="Heading 3"
                  disabled
                  triggerStyleClassName="extension-btn hover:naxatw-bg-secondary-200 naxatw-cursor-pointer   naxatw-duration-200 naxatw-transition-colors naxatw-ease-in"
                >
                  <p
                    onClick={() =>
                      editor?.chain().focus().toggleHeading({ level: 3 }).run()
                    }
                    className={`naxatw-px-[0.375rem] naxatw-py-1   ${
                      editor?.isActive('heading', { level: 3 })
                        ? 'naxatw-bg-secondary-200'
                        : ''
                    } `}
                  >
                    H<sub>3</sub>
                  </p>
                </TooltipWrapper>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          {/* bold */}
          <TipTapExtensionContainer
            tooltipMessage="Bold"
            icon="format_bold"
            wrapperClassName={`${isEditorActive('bold')}`}
            onClick={() => editor?.chain().focus().toggleBold().run()}
          />

          {/* italics */}
          <TipTapExtensionContainer
            tooltipMessage="Italic"
            icon="format_italic"
            wrapperClassName={`${isEditorActive('italic')}`}
            onClick={() => editor?.chain().focus().toggleItalic().run()}
          />

          {/* strike-through */}
          <TipTapExtensionContainer
            tooltipMessage="Strike Through"
            icon="strikethrough_s"
            wrapperClassName={`${isEditorActive('strike')}`}
            onClick={() => editor?.chain().focus().toggleStrike().run()}
          />

          {/* orderred-list */}
          <TipTapExtensionContainer
            tooltipMessage="Ordered List"
            icon="format_list_numbered"
            onClick={() => editor?.chain().focus().toggleOrderedList().run()}
            wrapperClassName={`${isEditorActive('orderedList')}`}
          />

          {/* bullet-list */}
          <TipTapExtensionContainer
            tooltipMessage="Bullet List"
            icon="format_list_bulleted"
            onClick={() => editor?.chain().focus().toggleBulletList().run()}
            wrapperClassName={`${isEditorActive('bulletList')}`}
          />

          {/* code-block */}
          {/* <TipTapExtensionContainer
            tooltipMessage="Code"
            icon="code"
            onClick={() => editor?.chain().focus().toggleCodeBlock().run()}
            className={`${isEditorActive('codeBlock')}`}
          /> */}

          {/* quoute */}
          <TipTapExtensionContainer
            tooltipMessage="Quote"
            icon="format_quote"
            onClick={() => editor?.chain().focus().toggleBlockquote().run()}
            iconType="material-icons"
            wrapperClassName={`${isEditorActive('blockquote')}`}
          />

          {/* horizontal-ruler */}
          <TipTapExtensionContainer
            tooltipMessage="Horizontal Ruler"
            icon="horizontal_rule"
            onClick={() => editor?.chain().focus().setHorizontalRule().run()}
          />

          {/* link */}
          <DropdownMenu
            open={linkDropdownOpen}
            onOpenChange={state => {
              setLinkDropdownOpen(state);
              if (!state) {
                setLinkText('');
              }
            }}
          >
            <DropdownMenuTrigger>
              <TipTapExtensionContainer
                tooltipMessage="Link"
                icon="link"
                wrapperClassName="naxatw-h-full"
              />
            </DropdownMenuTrigger>
            <div className="dropmenu-content-container naxatw-z-[50]">
              <DropdownMenuContent sideOffset={6}>
                <div className="custom-box-shadow naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-rounded naxatw-border naxatw-border-gray-300 naxatw-bg-white naxatw-p-1">
                  <Input
                    placeholder="Paste Link URL"
                    value={linkText}
                    type="text"
                    onChange={e => {
                      setLinkText(e.target.value);
                    }}
                    className="!naxatw-h-8 !naxatw-rounded !naxatw-p-2"
                  />

                  <button
                    type="button"
                    onClick={setLink}
                    className="naxatw-flex naxatw-h-8 naxatw-items-center naxatw-justify-center naxatw-overflow-hidden naxatw-rounded naxatw-bg-secondary-500 naxatw-px-4 naxatw-py-1 naxatw-text-[0.875rem]  naxatw-text-white naxatw-transition-colors naxatw-duration-200 naxatw-ease-in hover:naxatw-bg-primary-600"
                  >
                    Add
                  </button>
                </div>
              </DropdownMenuContent>
            </div>
          </DropdownMenu>

          {/* image */}
          <DropdownMenu
            open={imageDropdownOpen}
            onOpenChange={state => setImageDropdownOpen(state)}
          >
            <DropdownMenuTrigger>
              <TipTapExtensionContainer
                tooltipMessage="Image"
                icon="add_photo_alternate"
                wrapperClassName="naxatw-h-full"
              />
            </DropdownMenuTrigger>
            <div className="dropmenu-content-container naxatw-z-[50]">
              <DropdownMenuContent sideOffset={6} align="end">
                <div className="custom-box-shadow naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-rounded naxatw-border naxatw-border-gray-300 naxatw-bg-white naxatw-p-1">
                  <Input
                    type="text"
                    placeholder="Paste Image URL"
                    onChange={e => {
                      setImageURL(e.target.value);
                    }}
                    value={imageURL}
                    className="!naxatw-h-8 !naxatw-rounded"
                  />
                  <button
                    type="button"
                    onClick={addImage}
                    className="naxatw-flex naxatw-h-8 naxatw-items-center naxatw-justify-center naxatw-rounded naxatw-bg-secondary-500 naxatw-px-4 naxatw-py-1 naxatw-text-[0.875rem]  naxatw-text-white naxatw-transition-colors naxatw-duration-200 naxatw-ease-in hover:naxatw-bg-primary-600"
                  >
                    Insert
                  </button>
                </div>
              </DropdownMenuContent>
            </div>
          </DropdownMenu>
        </div>

        {/* redo / undo */}
        <div className="redo-undo naxatw-flex naxatw-items-center naxatw-gap-2">
          <TipTapExtensionContainer
            tooltipMessage="Undo"
            icon="undo"
            onClick={() => editor?.chain().focus().undo().run()}
          />

          <TipTapExtensionContainer
            tooltipMessage="Redo"
            icon="redo"
            onClick={() => editor?.chain().focus().redo().run()}
          />
        </div>
      </div>
    </div>
  );
}
