import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import MapSection from '@Components/ProgrammeDetails/MapSection/Map';
import { deletePdfSection } from '@Store/actions/pdfExport';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const PdfMap = () => {
  const { projectId } = useParams();

  const dispatch = useDispatch();

  return (
    <div className="pdf-map-container naxatw-relative">
      <ExportHeader
        headerTitle="Programme count by Palikas"
        isStyleAbsolute={false}
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: projectId ? 'projectDetails' : 'programmeDetails',
              section: 'map',
            }),
          )
        }
      />
      <ExportRoundedContainer className="naxatw-h-[35rem]">
        <MapSection lat={28.3949} lon={84.124} zoom={6} isExport />
      </ExportRoundedContainer>
    </div>
  );
};

export default hasErrorBoundary(PdfMap);
