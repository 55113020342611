/* eslint-disable no-underscore-dangle */
import CustomDonutChart from '@Components/common/Charts/DonutChart';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import ReportChartLegend from '@Components/common/CommonExport/ReportChartLegend';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import {
  mapBudgetExpenditureColors,
  mapBudgetExpenditureTitle,
} from '@Constants/mainDashboard';
import { getBudgetExpenditureTypeChart } from '@Services/mainDashboard';
import { deletePdfSection } from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import isEmpty from '@Utils/isEmpty';
import { useDispatch } from 'react-redux';

const ReportTypesChart = ({
  title,
  type,
}: {
  title: string;
  type?: string;
}) => {
  const dispatch = useDispatch();

  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const projectFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );

  const subMarkersParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );

  const statusFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );

  const provinceFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.province,
  );

  const { data: chartData } = useQuery({
    queryKey: ['number-of-types-report', title],
    queryFn: () =>
      getBudgetExpenditureTypeChart({
        by: type === 'expenditure' ? 'expenditure_type' : 'budget_type',
        program: programmeFilterParams?.join(','),
        project: projectFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        start_date: startDate,
        end_date: endDate,
        status: statusFilterParams,
        province: provinceFilterParams?.join(','),
      }),
    select: (res: any) => {
      if (!res?.data) return [];
      const response = res.data;
      if (isEmpty(response)) return [];

      return response.map((typeItem: Record<string, any>) => ({
        name: mapBudgetExpenditureTitle[typeItem._id],
        value: typeItem?.value,
        percentage: typeItem?.percentage,
      }));
    },
  });

  return (
    <ExportRoundedContainer
      style={{
        pageBreakInside: 'avoid',
      }}
    >
      <ExportHeader
        headerTitle={title}
        isStyleAbsolute={false}
        className="!naxatw-border-b naxatw-border-b-pdf-border !naxatw-p-2"
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: 'bek',
              section:
                type === 'budget' ? 'budgetPieChart' : 'expenditurePieChart',
            }),
          )
        }
      />
      {chartData && isEmpty(chartData) ? (
        <NoChartDataComponent
          isExport
          className="!naxatw-h-[280px]"
          iconClassName="naxatw-h-[50%]"
        />
      ) : (
        <div className="naxatw-relative naxatw-flex naxatw-flex-col naxatw-items-center  naxatw-gap-3 naxatw-p-3">
          <CustomDonutChart
            data={chartData || []}
            fills={Object.values(mapBudgetExpenditureColors)}
            showPound
            width={`${type === 'budget' ? '60%' : '60%'}`}
            minHeight={250}
            maxHeight={250}
            innerRadius={40}
          />
          <ReportChartLegend
            data={chartData || []}
            fills={Object.values(mapBudgetExpenditureColors)}
            showPound
            className="naxatw-my-4 !naxatw-w-[60%]"
          />
        </div>
      )}
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(ReportTypesChart);
