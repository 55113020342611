/* eslint-disable import/prefer-default-export */
export const feedbackFormInputFeilds = [
  {
    label: 'Name',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter your name',
    id: 'name',
    name: 'name',
    isVisible: true,
    required: false,
  },
  {
    label: 'Email',
    inputType: 'text',
    type: 'input',
    placeholder: 'Enter your email',
    id: 'email',
    name: 'email',
    isVisible: true,
    required: false,
  },
  {
    label: 'Feedback Type',
    inputType: 'text',
    type: 'select',
    placeholder: 'Select feedback type',
    id: 'feedback_type',
    name: 'feedback_type',
    required: true,
    visible: true,
    options: ['Complaint', 'Suggestion', 'Query'],
  },
  {
    label: 'Add an Attachment',
    inputType: 'file',
    type: 'file-upload',
    placeholder: 'Please upload Documents ( Less than 50MB )',
    id: 'attachment',
    name: 'attachment',
    isVisible: true,
    required: false,
    fileAccept: '.jpg, .jpeg, .png',
  },
  {
    label: 'Message',
    inputType: 'textArea',
    type: 'textArea',
    placeholder: 'Write your message here',
    id: 'message',
    name: 'message',
    isVisible: true,
    required: true,
    className: 'naxatw-h-40 naxatw-resize-none',
  },
];
