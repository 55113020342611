// @TODO:
//   - Fix warning when selecting months between tabs
//   - Reset tab (should be 'Start Date' tabe selected) once the picker close
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@radix-ui/react-popover';
import Icon from '@Components/common/Icon';
import { FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import { cn, getMonthId, monthNames } from '@Utils/index';

type Props = {
  startDate?: string;
  endDate?: string;
  placeholder?: string;
  // eslint-disable-next-line
  onChange?: (date: {
    start: string | undefined;
    end: string | undefined;
  }) => void;
};

type PickerToggleButtonProps = {
  label: string;
  value?: string;
  isActive: boolean;
  onClick: () => void;
};

function PickerToggleButton({
  label,
  value,
  onClick,
  isActive = false,
}: PickerToggleButtonProps) {
  return (
    <button
      className={cn(
        'naxatw-flex-1 naxatw-text-nowrap naxatw-rounded-md naxatw-bg-transparent naxatw-px-4 naxatw-py-2 naxatw-text-sm naxatw-font-semibold naxatw-text-gray-600 hover:naxatw-bg-gray-100',
        isActive && '!naxatw-bg-gray-300',
      )}
      type="button"
      onClick={onClick}
    >
      {value ? format(new Date(value), 'MMMM, yyyy') : label}
    </button>
  );
}

function MonthYearPicker({
  date,
  onPick,
}: {
  date: string | undefined;
  // eslint-disable-next-line
  onPick: (dateValue: string) => void;
}) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = monthNames[currentDate.getMonth()];

  const [isOpen, setIsOpen] = useState(false);
  const [view, setView] = useState<'months' | 'years'>('years');
  const [selectedYear, setSelectedYear] = useState<number | undefined>(
    undefined,
  );
  const [selectedMonth, setSelectedMonth] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    if (date) {
      const [year, month] = date.split('-');
      setSelectedYear(Number(year));
      setSelectedMonth(monthNames[parseInt(month, 10) - 1]);
    }
  }, [date]);

  function handleChangeDate(
    year: number | undefined,
    month: string | undefined,
  ) {
    if (!month || !year) return;

    const formattedDateString = `${year}-${String(getMonthId[month]).padStart(2, '0')}`;
    if (onPick) {
      onPick(formattedDateString);
    }
  }

  return (
    <div>
      <div className="naxatw-flex naxatw-cursor-pointer naxatw-items-center naxatw-justify-between naxatw-pb-2">
        <button
          onClick={() => {
            setView(view === 'years' ? 'months' : 'years');
            setIsOpen(prev => !prev);
          }}
          className="naxatw-text-lg naxatw-font-semibold"
          type="button"
        >
          <div className="naxatw-flex naxatw-items-center naxatw-gap-1">
            <p className="naxatw-label naxatw-text-matt-100">
              {selectedMonth || monthNames[currentDate.getMonth()]}
            </p>
            <p className="naxatw-label naxatw-text-matt-100">
              {selectedYear || currentDate.getFullYear()}
            </p>
            <div className="naxatw-grid naxatw-place-items-center hover:naxatw-rounded-[50%] hover:naxatw-bg-primary-200">
              <Icon
                type="material-icons"
                name="arrow_drop_down"
                className={`${isOpen ? '' : 'naxatw-rotate-180'} naxatw-flex  naxatw-p-1 !naxatw-text-xl naxatw-text-matt-200 naxatw-transition-colors  naxatw-duration-200 naxatw-ease-in `}
              />
            </div>
          </div>
        </button>
      </div>
      <div className="scrollbar naxatw-h-[172px] naxatw-overflow-y-auto">
        {view === 'months' ? (
          <div className="naxatw-grid naxatw-grid-cols-3 naxatw-gap-2 naxatw-pr-1">
            {Array.from({ length: 12 }, (_, i) => {
              const month = monthNames[i];
              return (
                <button
                  key={i}
                  className={cn(
                    `naxatw-cursor-pointer naxatw-rounded-3xl naxatw-px-1 naxatw-py-2  naxatw-text-center naxatw-text-[14px] naxatw-text-matt-100 naxatw-transition-colors naxatw-duration-200 naxatw-ease-in`,
                    month === selectedMonth
                      ? 'active-month naxatw-bg-secondary-500 naxatw-text-white hover:naxatw-bg-secondary-500'
                      : 'hover:naxatw-bg-primary-200',
                    month === currentMonth &&
                      !selectedMonth &&
                      'naxatw-bg-primary-200',
                  )}
                  onClick={() => {
                    if (month === selectedMonth) {
                      setSelectedMonth(undefined);
                      onPick('');
                    } else {
                      handleChangeDate(selectedYear, month);
                      setSelectedMonth(() => {
                        return month;
                      });
                    }
                  }}
                  type="button"
                >
                  {month}
                </button>
              );
            })}
          </div>
        ) : (
          <div className="naxatw-grid naxatw-grid-cols-4 naxatw-gap-2 naxatw-pr-1">
            {Array.from(
              { length: currentYear - 2020 + 1 },
              (_, i) => 2020 + i,
            ).map(year => (
              <button
                key={year}
                className={`${year === currentYear && !selectedYear ? 'naxatw-bg-primary-200' : ''} ${Number(year) === Number(selectedYear) ? 'naxatw-bg-secondary-500 naxatw-text-white hover:naxatw-bg-secondary-500' : ' hover:naxatw-bg-primary-200'} naxatw-cursor-pointer naxatw-rounded-3xl naxatw-p-1 naxatw-px-1 naxatw-py-2 naxatw-text-center naxatw-text-[14px] naxatw-transition-colors naxatw-duration-200 naxatw-ease-in `}
                onClick={() => {
                  if (year === selectedYear) {
                    setSelectedMonth(undefined);
                    setSelectedYear(undefined);
                    onPick('');
                  } else {
                    setSelectedYear(() => {
                      handleChangeDate(year, selectedMonth);
                      return year;
                    });
                  }
                  setView('months');
                }}
                type="button"
              >
                {year}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default function MultipleMonthYearDatePicker({
  startDate,
  endDate,
  placeholder,
  onChange,
}: Props) {
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<'start' | 'end'>('start');

  const handleSelectTab = (tab: typeof selectedTab) => setSelectedTab(tab);
  const isShowStartDate = selectedStartDate?.split('-').length === 2;
  const isShowEndDate = selectedEndDate?.split('-').length === 2;

  useEffect(() => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  }, [startDate, endDate]);

  useEffect(() => {
    if ((selectedStartDate || selectedEndDate) && onChange) {
      onChange({ start: selectedStartDate, end: selectedEndDate });
    }
    // eslint-disable-next-line
  }, [selectedStartDate, selectedEndDate]);

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="dropDown"
          size="drop-lg"
          className={cn(
            'naxatw-group naxatw-flex naxatw-w-full naxatw-min-w-[12rem]  naxatw-items-center naxatw-justify-between naxatw-gap-2 naxatw-duration-200 hover:naxatw-border-secondary-500',
          )}
        >
          <div className="naxatw-flex naxatw-items-center">
            <CalendarIcon className="naxatw-mr-2 naxatw-h-4 naxatw-w-4 naxatw-duration-200 group-hover:naxatw-text-secondary-500" />
            <span className="naxatw-text-nowrap !naxatw-font-normal naxatw-text-matt-200">
              {isShowStartDate || isShowEndDate ? (
                <div>
                  {selectedStartDate} - {selectedEndDate}
                </div>
              ) : (
                placeholder || 'Select Date Range'
              )}
            </span>
          </div>
          <Icon
            name="expand_more"
            className={`${isOpen ? 'naxatw-rotate-180' : ''} naxatw-flex naxatw-h-6 naxatw-w-6 naxatw-text-matt-200 naxatw-transition-transform naxatw-duration-200  naxatw-ease-in group-hover:naxatw-text-secondary-500`}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="naxatw-rounded-md naxatw-bg-white naxatw-p-3 naxatw-shadow">
        <FlexRow className="naxatw-mb-3 naxatw-bg-gray-50">
          <PickerToggleButton
            label="Start Date"
            value={selectedStartDate}
            isActive={selectedTab === 'start'}
            onClick={() => handleSelectTab('start')}
          />
          <PickerToggleButton
            label="End Date"
            value={selectedEndDate}
            isActive={selectedTab === 'end'}
            onClick={() => handleSelectTab('end')}
          />
        </FlexRow>
        {selectedTab === 'start' && (
          <MonthYearPicker
            onPick={date => setSelectedStartDate(date)}
            date={selectedStartDate}
          />
        )}
        {selectedTab === 'end' && (
          <MonthYearPicker
            onPick={date => setSelectedEndDate(date)}
            date={selectedEndDate}
          />
        )}
      </PopoverContent>
    </Popover>
  );
}
