import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { useDispatch } from 'react-redux';
import { deleteIndividualList } from '@Store/actions/pdfExport';
import ExportBtn from '../ExportHeader/ExportBtn';
import ExportRoundedContainer from '../ExportRoundedContainer';

const TitleWithContent = ({
  isExport = false,
  dataLists,
  exportLists,
  exportTitle,
}: {
  isExport?: boolean;
  dataLists: Record<string, any>[];
  exportLists?: Record<string, any>[];
  exportTitle?: Record<string, any>;
}) => {
  const dispatch = useDispatch();

  return (
    <ExportRoundedContainer className="title-with-content  naxatw-py-3">
      {dataLists?.map(listItem => {
        if (isExport) {
          const isListItemIncludes =
            Array.isArray(exportLists) && exportLists.includes(listItem.id);

          if (!isListItemIncludes) return null;
        }

        return (
          <div
            className="list-item-container naxatw-group naxatw-relative naxatw-grid naxatw-grid-cols-[2fr_1fr] naxatw-gap-5 naxatw-overflow-hidden naxatw-border-b naxatw-border-b-grey-200 naxatw-px-3 naxatw-py-2 last:naxatw-border-b-0"
            key={listItem.id}
          >
            <p className="fs-sm-medium">{listItem.title}</p>
            <div className="content-container naxatw-gap naxatw-flex naxatw-items-center">
              {listItem.content.map((con: Record<string, any>) => {
                return (
                  <div
                    className="content-item-container naxatw-border-r naxatw-border-r-matt-200 naxatw-pl-1  naxatw-pr-1 first:naxatw-pl-0 last:naxatw-border-r-0 last:naxatw-pr-0"
                    key={con.id}
                  >
                    <p className="fs-sm-semibold naxatw-text-primary-700">
                      {con.name}
                    </p>
                  </div>
                );
              })}
            </div>
            {isExport ? (
              <ExportBtn
                size="sm"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: exportTitle?.page,
                      section: exportTitle?.section,
                      key: 'datalists',
                      value: listItem.id,
                    }),
                  )
                }
              />
            ) : null}
          </div>
        );
      })}
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(TitleWithContent);
