/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useRef, useEffect } from 'react';
import SearchIcon from '@Assets/icons/searchIcon.svg';

interface ISearchbarProps {
  className?: string;
  placeholder?: string;
  value: string | number;
  onChange: (value: any) => void;
  isSmall?: boolean;
  isFocus?: boolean;
  onClick?: () => void;
  onBlur?: () => void;
  required?: boolean;
  wrapperStyle?: string;
}

export default function Searchbar({
  className,
  placeholder = 'Search',
  value,
  onChange,
  isSmall,
  isFocus,
  onClick,
  onBlur,
  wrapperStyle,
  required = true, // in default required is true
}: ISearchbarProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleClearSearch = () => {
    onChange({ target: { value: '' } });
    inputRef?.current?.focus();
  };

  useEffect(() => {
    if (!isFocus) return;
    inputRef?.current?.focus();
  }, [isFocus]);

  return (
    <div
      className={`naxatw-flex naxatw-w-full naxatw-items-center ${
        isSmall ? 'naxatw-h-10' : 'naxatw-h-12'
      } ${wrapperStyle} `}
    >
      <label htmlFor="simple-search" className="naxatw-sr-only">
        {placeholder}
      </label>
      <div className="naxatw-relative naxatw-w-full">
        <div className="naxatw-pointer-events-none naxatw-absolute naxatw-inset-y-0 naxatw-left-0 naxatw-flex naxatw-items-center naxatw-pl-3">
          <img
            src={SearchIcon}
            alt="Search Icon"
            className="naxatw-h-5 naxatw-w-5"
          />
        </div>
        {value && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            className={`naxatw-absolute naxatw-right-2 ${
              isSmall ? 'naxatw-top-1.5' : 'naxatw-top-[0.563rem]'
            } naxatw-flex 
        naxatw-cursor-pointer naxatw-items-center naxatw-justify-center naxatw-rounded-full naxatw-bg-gray-200 naxatw-p-1 naxatw-duration-200  hover:naxatw-bg-gray-300`}
            onClick={handleClearSearch}
          >
            <span className="material-symbols-outlined naxatw-text-[14px] naxatw-text-grey-800">
              close
            </span>
          </div>
        )}

        <input
          ref={inputRef}
          type="text"
          className={`naxatw-block naxatw-w-full naxatw-rounded-lg   naxatw-border naxatw-border-grey-600 naxatw-pl-[35px] naxatw-pr-[28px]  naxatw-text-[13px]
            naxatw-font-normal naxatw-text-gray-500 naxatw-transition-colors placeholder:naxatw-font-normal 
            placeholder:naxatw-text-gray-500 hover:naxatw-border-secondary-500 focus:naxatw-border-[#484848] focus:naxatw-outline-none focus-visible:naxatw-border-secondary-500  ${
              isSmall
                ? 'naxatw-h-[36px] naxatw-py-2'
                : 'naxatw-h-[40px] naxatw-py-3'
            }
            ${className}`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onClick={onClick}
          onBlur={onBlur}
          required={required}
        />
      </div>
    </div>
  );
}
