import React, { useState } from 'react';
import {
  Arrow,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@radix-ui/react-tooltip';
import { cn } from '@Utils/index';

interface ITooltipContainerProps {
  children: React.ReactNode;
  message: string;
  arrowFill?: string;
  durationDelay?: number;
  side?: 'right' | 'top' | 'bottom' | 'left';
  className?: string;
  isDisabled?: boolean;
  messageStyle?: string;
}

function ToolTipContainer({
  children,
  message,
  arrowFill = '#417EC9',
  durationDelay = 100,
  side = 'right',
  className = '',
  isDisabled = false,
  messageStyle,
}: ITooltipContainerProps) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (open: boolean) => {
    if (!isDisabled) {
      setIsOpen(open);
    }
  };

  return (
    <TooltipProvider delayDuration={durationDelay}>
      <Tooltip open={isOpen} onOpenChange={handleOpenChange}>
        <TooltipTrigger className={`tooltip-wrapper ${className}`}>
          {children}
        </TooltipTrigger>

        <TooltipContent side={side} className="naxatw-z-50">
          <div
            className={cn(
              'message naxatw-rounded-md naxatw-bg-[#417EC9] naxatw-px-3 naxatw-py-2 naxatw-text-xs naxatw-font-semibold naxatw-text-white',
              messageStyle,
            )}
          >
            {message}
          </div>
          <Arrow className="TooltipArrow rounded" style={{ fill: arrowFill }} />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default ToolTipContainer;
