import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import VerticalBarChart from '@Components/common/Charts/VerticalBarChart';
import ChartXAxis from '@Components/common/CommonExport/Chart/ChartXAxis';
import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { getTopPartnerByBudget } from '@Services/mainDashboard';
import { deletePdfSection } from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import isEmpty from '@Utils/isEmpty';
import { useDispatch } from 'react-redux';

const ReportPartner = () => {
  const dispatch = useDispatch();
  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const projectFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );

  const subMarkersParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );

  const statusFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );

  const provinceFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.province,
  );

  const { data: chartData } = useQuery({
    queryKey: ['partner-budget-pdf'],
    queryFn: () =>
      getTopPartnerByBudget({
        program: programmeFilterParams?.join(','),
        project: projectFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        start_date: startDate,
        end_date: endDate,
        status: statusFilterParams,
        province: provinceFilterParams?.join(','),
      }),
    select: (res: any) => {
      if (!res?.data) return [];
      const response = res.data;
      if (isEmpty(response)) return [];
      return response?.map((partner: Record<string, any>) => ({
        name: partner?.name,
        value: partner?.budget,
        fullName: partner?.name,
      }));
    },
  });

  return (
    <ExportRoundedContainer
      style={{
        pageBreakInside: 'avoid',
      }}
    >
      <ExportHeader
        headerTitle="Top 5 Implementing Partners by Budget Allocated"
        isStyleAbsolute={false}
        className="!naxatw-border-b naxatw-border-b-pdf-border !naxatw-p-2"
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: 'bek',
              section: 'partnersChart',
            }),
          )
        }
      />
      {chartData && isEmpty(chartData) ? (
        <NoChartDataComponent
          isExport
          className="!naxatw-h-[280px]"
          iconClassName="naxatw-h-[50%]"
        />
      ) : (
        <div className="naxatw-relative naxatw-p-3">
          <p className="fs-xs-medium naxatw-absolute naxatw-left-[24px] naxatw-top-[40%] naxatw-origin-center -naxatw-translate-x-2/4 -naxatw-rotate-90 naxatw-capitalize">
            partners
          </p>
          <VerticalBarChart data={chartData || []} height="15rem" isExport />
          <ChartXAxis name="Budget" />
        </div>
      )}
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(ReportPartner);
