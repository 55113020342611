export const sidebarWidth = '80px';

export const nothing = '';

export const exploreCardData = [
  {
    id: 'main-dashboard',
    title: 'BEK Footprint',
    description:
      'BEK portfolio details: the who, what, where, when, and which stakeholders (5Ws).',
    link: '/dashboard/map',
  },
  {
    id: 'portfolio-profiles',
    title: 'Portfolio Profiles',
    description:
      'Quick access to province, contextual, and programme profiles.',
    link: '/explore-by/geography',
  },
  {
    id: 'knowledge-library',
    title: 'Knowledge Library',
    description:
      'One-stop-shop for programme reference materials to inform learning and improve programming.',
    link: '/knowledge-library',
  },
  {
    id: 'data-bank',
    title: 'Data Bank',
    description:
      "Heart of system's data management - unified platform to create, update, and remove data.",
    link: '/data-bank/report',
  },
];

export const namesWithoutTooltip = ['Others'];

export const pathnameWithOthers = [
  '/data-bank/sectors/sector',
  '/data-bank/sectors/sub-sector',
  '/data-bank/markers/markers-categories',
  '/data-bank/markers/markers-values',
  '/data-bank/indicators',
  '/data-bank/user-security/user-management',
  '/data-bank/user-security/role-management',
  '/data-bank/map-layers',
  '/data-bank/activity-logs',
  '/data-bank/fun-trivia',
  '/data-bank/feedback',
  '/data-bank/tutorials/videos',
  '/data-bank/tutorials/manual',
  '/data-bank/tutorials/faqs',
  '/data-bank/category',
  '/data-bank/sub-category',
];

export const excludeVerticalTopPathNames: string[] = [
  'fun-trivia',
  'user-security',
  'map-layers',
  'markers',
  'sectors',
  'partners',
  'knowledge-repository',
  'programmes',
  'project',
  'contacts',
  'category',
  'manual',
  'tutorials',
];

export const excludeTooltipKeys = [
  'project_status',
  'icon',
  'icons',
  'pdfIcon',
  'status',
  'message',
  'attachment',
];

export const excludeSortColumnNames = ['project_status', 'icons', 'icon', ''];
export const alignedEndHedaerkeys = ['project_status'];
