/* eslint-disable react/no-unstable-nested-components */
import DataTable from '@Components/common/DataTable';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { FlexRow } from '@Components/common/Layouts';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { deleteSubCategory, getSubCategory } from '@Services/category';
import { useTypedSelector } from '@Store/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const SubCategoryTable = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [catIDToDelete, setCatIDToDelete] = useState<string>('');

  const searchTerm = useTypedSelector(
    state => state.common.adminSearchState?.['sub-category'],
  );

  const {
    mutate: deleteSubCategoryData,
    isError,
    error,
    isLoading,
  } = useMutation({
    mutationFn: () => deleteSubCategory(+catIDToDelete),
    onSuccess: () => {
      toast.success('Sub Category deleted successfully');
      setOpenDeleteConfirmation(false);
      queryClient.invalidateQueries({
        queryKey: ['subcategory-tabledata'],
      });
    },
  });

  const columns = [
    {
      header: 'S.N',
      accessorKey: '',
      cell: ({ row }: Record<string, any>) => {
        return (row?.index ?? -1) + 1;
      },
    },
    { header: 'SUB CATEGORY', accessorKey: 'name' },
    { header: 'CATEGORY', accessorKey: 'category__name' },
    {
      header: '',
      accessorKey: 'icon',
      cell: ({ row }: any) => {
        const cellId = row?.original?.id;
        return (
          <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-cursor-pointer naxatw-justify-end naxatw-gap-4">
            <ToolTip
              name="edit"
              message="Edit"
              className="naxatw-text-icon-md naxatw-text-matt-100  hover:naxatw-text-calm-teal"
              iconClick={() =>
                navigate(`/data-bank/sub-category/edit/${cellId}`)
              }
            />

            <ToolTip
              name="delete"
              message="Delete"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-text-red-500"
              iconClick={() => {
                setOpenDeleteConfirmation(prev => !prev);
                setCatIDToDelete(cellId);
              }}
            />
          </FlexRow>
        );
      },
    },
  ];
  return (
    <div className="naxatw-h-[calc(100%-8rem)] naxatw-w-full sm:naxatw-h-[calc(100%-4.475rem)] lg:naxatw-h-[calc(100%-4.5rem)]">
      <DataTable
        columns={columns}
        queryKey="subcategory-tabledata"
        queryFn={getSubCategory}
        isPaginated
        initialState={{
          paginationState: {
            pageIndex: 0,
            pageSize: 25,
          },
        }}
        searchInput={searchTerm || ''}
        sortByKey
      />
      {openDeleteConfirmation && (
        <Portal>
          <DeleteConfirmationOverlay
            onClose={() => setOpenDeleteConfirmation(false)}
            onDelete={() => deleteSubCategoryData()}
            isError={isError}
            isLoading={isLoading}
            error={error}
          />
        </Portal>
      )}
    </div>
  );
};

export default hasErrorBoundary(SubCategoryTable);
