import Icon from '@Components/common/Icon';
import { cn } from '@Utils/index';

type Props = {
  icon: string;
  text?: string;
  highlightText?: string;
  textStyle?: string;
  highlightTextStyles?: string;
};
const Detail = ({
  icon,
  text,
  highlightText,
  textStyle,
  highlightTextStyles,
}: Props) => {
  return (
    <div className="detail-container naxatw-flex naxatw-items-center naxatw-gap-1">
      <Icon
        name={icon}
        className="!naxatw-text-xl naxatw-text-matt-200"
        type="material-icons"
      />
      <p
        className={cn(
          'naxatw-body-caption naxatw-text-[0.875rem] naxatw-capitalize naxatw-leading-5 naxatw-text-matt-100',
          textStyle,
        )}
      >
        {text}
        {highlightText && (
          <span
            className={cn(
              'naxatw-body-btn naxatw-text-matt-100',
              highlightTextStyles,
            )}
          >
            {' '}
            {highlightText}
          </span>
        )}
      </p>
    </div>
  );
};

export default Detail;
