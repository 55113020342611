import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

interface IUsePrintProps {
  title: string;
  hasDescription?: boolean;
}

const usePrint = ({ title, hasDescription }: IUsePrintProps) => {
  const pdfContentRef = useRef<HTMLDivElement>(null);

  const defaultPageStyle = `
   @page {
    size: auto;
    margin: 5mm 4mm 6mm 4mm;
    @bottom-right {
        content: counter(page) '/' counter(pages);
        padding-bottom: 10mm; 
        font-size: 9px; 
        color: #000;
        z-index: 900;
      }
  }
 
  .no-print-area {
      display: none !important;
  }

   ${
     hasDescription
       ? `
    .pdf-desc-textarea {
     border: none !important;
        resize: none !important
       
    }
  `
       : ` .desc-container {
        display: none !important;
      
    }`
   }
`;

  const reactToPrintFn = useReactToPrint({
    contentRef: pdfContentRef,
    pageStyle: defaultPageStyle,
    documentTitle: title,
  });

  return {
    reactToPrintFn,
    pdfContentRef,
  };
};

export default usePrint;
