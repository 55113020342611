/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { format, parse } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import DataTable from '@Components/common/DataTable';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { FlexRow } from '@Components/common/Layouts';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import ToolTip from '@Components/RadixComponents/ToolTip';
import {
  deleteFederalInfoUpdateById,
  getFederalInfoUpdates,
} from '@Services/federalInfoUpdate';
import { useTypedSelector } from '@Store/hooks';
import isEmpty from '@Utils/isEmpty';

const columns = [
  {
    header: 'S.N',
    accessorKey: '',
    cell: ({ row }: Record<string, any>) => row?.index + 1,
    size: 24,
  },
  {
    header: 'Update Location',
    accessorKey: 'federal_type',
    cell: ({ row }: Record<string, any>) => {
      const levelType =
        row?.original?.federal_type === 'Province'
          ? row?.original?.province__name
          : row?.original?.federal_type;
      return <div>{levelType || '-'}</div>;
    },
  },

  {
    header: 'Date',
    accessorKey: 'date',
    cell: ({ row }: Record<string, any>) => {
      const date = row?.original?.date;
      return (
        <div>
          {date
            ? format(parse(date, 'yyyy-MM', new Date()), 'MMMM, yyyy')
            : '-'}
        </div>
      );
    },
  },

  {
    header: 'Created At',
    accessorKey: 'date_created__date',
    cell: ({ row }: Record<string, any>) => {
      const date = row?.original?.date_created__date;
      return (
        <div>
          {date
            ? format(parse(date, 'yyyy-MM-dd', new Date()), 'MMMM d, yyyy')
            : '-'}
        </div>
      );
    },
  },
];

const queryKey = 'federal-info-list';

function UpdateTable() {
  const navigate = useNavigate();
  const userProgrammes = useTypedSelector(
    state => state.common.userProfile?.program,
  );

  const groupName = useTypedSelector(
    state => state.common.userProfile?.group_name,
  );

  const userGroupPermission = useTypedSelector(
    state => state?.common?.userGroupPermission,
  );

  const [isShowConfirmationBox, setIsShowConfirmationBox] = useState(false);
  const [idToDelete, setIdToDelete] = useState('');

  const queryClient = useQueryClient();

  const { mutateAsync: mutateDeleteUpdate } = useMutation({
    mutationFn: () => deleteFederalInfoUpdateById(idToDelete),
    onError: (err: any) => {
      toast.error(
        err?.message || 'Something went wrong when deleting the data',
      );
    },
    onSuccess: () => {
      toast.success('Update deleted Successfully');
      queryClient.invalidateQueries({
        queryKey: [queryKey],
      });
    },
    onMutate: () => {
      setIsShowConfirmationBox(false);
    },
  });

  const searchTerm = useTypedSelector(
    state => state.common.adminSearchState?.['federal-info-update'] || '',
  );

  return (
    <div className="naxatw-h-[calc(100%-10rem)] naxatw-w-full sm:naxatw-h-[calc(100%-6.875rem)] lg:naxatw-h-[calc(100%-4.5rem)]">
      <DataTable
        columns={columns.concat([
          {
            header: '',
            accessorKey: 'icon',
            size: 24,
            // eslint-disable-next-line react/no-unstable-nested-components
            cell: ({ row }: any) => {
              const cellId = row?.original?.id;

              return (
                <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-mt-4 naxatw-h-full  naxatw-cursor-pointer naxatw-justify-end naxatw-gap-5">
                  {
                    // @TODO: configure authorization
                    userGroupPermission?.includes(
                      'Can change federal info update',
                    ) ? (
                      <div className="naxatw-cursor-not-allowed">
                        <ToolTip
                          name="edit"
                          message="Edit"
                          className="naxatw-text-icon-md naxatw-text-matt-200 naxatw-duration-200 hover:naxatw-text-green-500"
                          iconClick={() => {
                            navigate(
                              `/data-bank/federal-info-update/edit/${cellId}`,
                            );
                          }}
                          triggerClassName={
                            groupName === 'System Admin'
                              ? ''
                              : userProgrammes && isEmpty(userProgrammes)
                                ? ''
                                : userProgrammes &&
                                    !userProgrammes.includes(cellId)
                                  ? '!naxatw-pointer-events-none'
                                  : ''
                          }
                        />
                      </div>
                    ) : null
                  }
                  {
                    // @TODO: configure authorization
                    userGroupPermission?.includes(
                      'Can delete federal info update',
                    ) ? (
                      <div className="naxatw-cursor-not-allowed">
                        <ToolTip
                          name="delete"
                          message="Delete"
                          className="naxatw-text-icon-md naxatw-text-matt-200 naxatw-duration-200 hover:naxatw-text-red-500"
                          triggerClassName={
                            groupName === 'System Admin'
                              ? ''
                              : userProgrammes && isEmpty(userProgrammes)
                                ? ''
                                : userProgrammes &&
                                    !userProgrammes.includes(cellId)
                                  ? '!naxatw-pointer-events-none'
                                  : ''
                          }
                          iconClick={() => {
                            setIsShowConfirmationBox(prev => !prev);
                            setIdToDelete(cellId);
                          }}
                        />
                      </div>
                    ) : null
                  }
                </FlexRow>
              );
            },
          },
        ])}
        queryFn={getFederalInfoUpdates}
        queryKey={queryKey}
        searchInput={searchTerm}
        sortByKey
      />
      {isShowConfirmationBox && (
        <Portal>
          <DeleteConfirmationOverlay
            onClose={() => setIsShowConfirmationBox(false)}
            onDelete={() => mutateDeleteUpdate()}
          />
        </Portal>
      )}
    </div>
  );
}

export default hasErrorBoundary(UpdateTable);
