import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import SegmentCard from '@Components/ProgrammeDetails/Cards/Segment';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { getProvincialCountData } from '@Services/provincialProfile';

type Counts = {
  province_count: number;
  district_count: number;
  municipality_count: number;
};

function WorkingAreasCount() {
  const { programmeId } = useParams();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('componentId');
  const { data, isLoading } = useQuery<any, any, Counts, any>({
    queryKey: ['programmeList', 'programmeCount', programmeId, projectId],
    queryFn: async () =>
      getProvincialCountData({
        [projectId ? 'project' : 'program']: projectId || programmeId,
      }),
    select: res => res.data,
  });

  const {
    district_count: district,
    province_count: province,
    municipality_count: municipality,
  } = data || {};

  const hasNoData = !(district || province || municipality);

  return (
    <SegmentCard title="Working Areas" iconName="place">
      {isLoading ? (
        <>
          <Skeleton className="naxatw-h-4" />
        </>
      ) : (
        <div className="naxatw-text-base naxatw-font-semibold naxatw-leading-[1.36rem] naxatw-text-[#0B2E62]">
          {hasNoData ? (
            <span className="naxatw-text-sm naxatw-text-gray-600">
              No Data Available
            </span>
          ) : (
            <>
              {province ? `${province} Provinces` : ''}
              {district ? `, ${district} Districts` : ''}
              {municipality ? ` and ${municipality} Palikas` : ''}
            </>
          )}
        </div>
      )}
    </SegmentCard>
  );
}
export default hasErrorBoundary(WorkingAreasCount);
