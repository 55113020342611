/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import { Input } from '@Components/common/FormUI';
import ErrorMessage from '@Components/common/ErrorMessage';
import FileUpload from '@Components/common/FormUI/Upload';
import IconButton from '@Components/common/IconButton';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import {
  getUserManualById,
  patchUserManual,
  postUserManual,
} from '@Services/tutorials';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import isEmpty from '@Utils/isEmpty';
// import { userManualFormValidationSchema } from '@Validations/Tutorials';

interface IUserManualRegistrationForm {
  onClose: () => void;
}

const initialValues: FieldValues = {
  id: '',
  title: '',
  description: '',
  thumbnail: '',
  file: [],
};

const UserManualRegistrationForm = ({
  onClose,
}: IUserManualRegistrationForm) => {
  const { manualId } = useParams();
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    setValue,
    register,
    control,
    watch,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    // resolver: zodResolver(userManualFormValidationSchema),
  });

  const { mutate: postUserManualData, isLoading: userManualIsUploading } =
    useMutation({
      mutationFn: (data: FieldValues) => postUserManual(data),
      onSuccess: () => {
        toast.success('User manual added successfully');
        queryClient.invalidateQueries(['user-manual-table-data']);
        onClose();
      },
      onError: (error: any) => {
        const caughtedError = error?.response?.data?.thumbnail[0];
        toast.error(caughtedError || 'Failed to user manual');
      },
    });

  const { mutate: patchUserManualData, isLoading: userManualIsPatching } =
    useMutation({
      mutationFn: (data: FieldValues) => patchUserManual(data),
      onSuccess: () => {
        toast.success('User Manual Updated successfully');
        queryClient.invalidateQueries(['user-manual-table-data']);
        onClose();
      },
      onError: () => {
        toast.error('Failed to update user manual');
      },
    });

  const { isLoading: isUserManualDataLoading } = useQuery({
    queryKey: ['user-manual-data', manualId],
    queryFn: () => getUserManualById(manualId || ''),
    select: res => res?.data,
    enabled: !!manualId,
    onSuccess: response => {
      if (response) {
        Object.keys(response).forEach((key: string) => {
          const value = response[key];

          if (key === 'file' || (key === 'thumbnail' && value)) {
            const finalDocuments = [{ file: value, id: Number(manualId) }];
            setValue(key, finalDocuments);
          } else {
            setValue(key, value);
          }
        });
      }
    },
  });

  const thumbnail = watch('thumbnail');
  const watchDescription = watch('description');

  const patchManualForm = async (updatedForm: Record<string, any>) => {
    const finalUpdateData = {
      id: manualId,
      ...updatedForm,
      ...(updatedForm?.thumbnail
        ? isEmpty(updatedForm.thumbnail)
          ? { thumbnail: '' }
          : { thumbnail: updatedForm.thumbnail[0]?.file }
        : {}),
      ...(updatedForm?.file ? { file: updatedForm.file[0]?.file } : {}),
    };

    patchUserManualData(finalUpdateData);
  };

  const getDirtyFieldValues = () => {
    const allValues = getValues();
    const dirtyValues: any = {};
    Object.keys(allValues).forEach((key: string) => {
      if (dirtyFields[key as keyof typeof dirtyFields]) {
        dirtyValues[key] = allValues[key as keyof typeof dirtyFields];
      }
    });

    return dirtyValues;
  };
  const handleFormSubmit = async () => {
    if (manualId) {
      if (isDirty === false) {
        onClose();
        return;
      }
      const editFields = getDirtyFieldValues();
      await patchManualForm(editFields);
      return;
    }

    const { file, ...restValues } = getValues();

    const documentBianry = file[0]?.file;
    const thumbnailBinary = thumbnail?.[0]?.file;

    postUserManualData({
      ...restValues,
      file: documentBianry,
      ...(thumbnail ? { thumbnail: thumbnailBinary } : {}),
    });
  };

  const overallFormSubmitLoading =
    userManualIsPatching || userManualIsUploading;

  return (
    <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-w-full naxatw-translate-x-[-50%] naxatw-translate-y-[calc(-50%+31.5px)] naxatw-items-center naxatw-justify-center sm:naxatw-w-[34.75rem]">
      <div className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-rounded-2xl naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 ">
        <div className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-justify-between naxatw-px-7 naxatw-py-5 naxatw-shadow-light">
          <BreadCrumb
            heading={`User Manual / ${manualId ? 'Edit' : 'Add'}`}
            overlayStatus={() => onClose()}
          />
          <IconButton
            name="close"
            className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
            iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
            onClick={() => onClose()}
          />
        </div>
        <div className="naxatw-flex naxatw-w-full naxatw-gap-3 ">
          <div className="naxatw-w-full">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              {manualId && isUserManualDataLoading ? (
                <div className="naxatw-mx-auto naxatw-w-11/12">
                  <FormSkeleton numRows={4} className="naxatw-w-full" />
                </div>
              ) : (
                <div className="naxatw-py-5 naxatw-pl-6 naxatw-pr-4">
                  <FlexColumn className="scrollbar naxatw-h-[calc(100vh-20.8rem)] naxatw-w-full  naxatw-overflow-y-scroll naxatw-pr-2">
                    <FlexColumn className="naxatw-w-full naxatw-gap-1">
                      <InputLabel label="Title" astric />
                      <Input
                        type="text"
                        placeholder="Title"
                        className="!naxatw-w-full"
                        {...register('title', {
                          required: 'Title is Required',
                          minLength: {
                            value: 1,
                            message: 'Title must be at least 1 character long',
                          },
                          maxLength: {
                            value: 255,
                            message:
                              'Title must be at most 255 characters long',
                          },
                        })}
                      />

                      {errors?.title?.message ? (
                        <ErrorMessage
                          message={
                            typeof errors?.title?.message === 'string'
                              ? errors.title.message
                              : ''
                          }
                        />
                      ) : null}
                    </FlexColumn>
                    <FlexColumn className="naxatw-w-full  naxatw-gap-1 naxatw-pt-5">
                      <InputLabel
                        label="Description"
                        tooltipMessage="Add description upto 100 characters"
                      />
                      <textarea
                        placeholder="Description"
                        className="naxatw-input naxatw-h-[6rem] !naxatw-w-full naxatw-resize-none naxatw-text-sm"
                        value={watchDescription}
                        onChange={e => {
                          if (e.target.value.length <= 130) {
                            setValue('description', e.target.value); // Only set value if under limit
                          }
                        }}
                      />
                      {errors?.description?.message ? (
                        <ErrorMessage
                          message={
                            typeof errors?.description?.message === 'string'
                              ? errors.description.message
                              : ''
                          }
                        />
                      ) : null}
                    </FlexColumn>
                    <FlexColumn className="naxatw-w-full  naxatw-gap-1 naxatw-pt-5">
                      <InputLabel label="Thumbnail" />
                      <Controller
                        control={control}
                        name="thumbnail"
                        render={({ field: { value, onChange } }) => {
                          return (
                            <FileUpload
                              name="thumbnail"
                              register={register}
                              setValue={setValue}
                              data={value}
                              multiple={false}
                              fileAccept="image/*"
                              onChange={() =>
                                onChange(getValues?.()?.thumbnail)
                              }
                              placeholder="Please upload an image (jpeg, jpg, etc.)"
                              control={control}
                            />
                          );
                        }}
                      />{' '}
                    </FlexColumn>
                    <FlexColumn className="naxatw-w-full naxatw-gap-1 naxatw-pt-5">
                      <InputLabel label="Document" astric />
                      <Controller
                        control={control}
                        name="file"
                        rules={{
                          validate: () => {
                            const { file } = getValues();
                            if (isEmpty(file)) {
                              return 'Document is required';
                            }
                            return true;
                          },
                        }}
                        render={({ field: { value, onChange } }) => {
                          return (
                            <FileUpload
                              name="file"
                              data={value}
                              onChange={() => onChange(getValues?.()?.file)}
                              multiple={false}
                              fileAccept=".pdf, .xls, .xlsx, .doc, .docx, .txt, .csv"
                              placeholder="Please upload a PDF or DOC file"
                              maxSize={50}
                              control={control}
                              register={register}
                              setValue={setValue}
                            />
                          );
                        }}
                      />

                      {errors?.file?.message ? (
                        <ErrorMessage message={errors.file.message as string} />
                      ) : null}
                    </FlexColumn>
                  </FlexColumn>
                </div>
              )}

              <div className=" naxatw-flex naxatw-justify-center naxatw-py-5 naxatw-shadow-formshadow">
                <Button
                  size="normal"
                  type="submit"
                  variant="primary"
                  className="naxatw-w-fit naxatw-px-4"
                  isLoading={userManualIsUploading || userManualIsPatching}
                  disabled={
                    manualId
                      ? isUserManualDataLoading || overallFormSubmitLoading
                      : overallFormSubmitLoading
                  }
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManualRegistrationForm;
