import { authenticated, api } from '.';

// -------> Category
export const postCategory = (payload: Record<string, any>) => {
  return authenticated(api).post('/category/', payload);
};

export const getCategory = (params: Record<string, any>) => {
  return authenticated(api).get('/category/', { params });
};

export const getCategoryById = (id: number) => {
  return authenticated(api).get(`/category/${id}/`);
};

export const patchCategory = (id: number, payload: Record<string, any>) => {
  return authenticated(api).patch(`/category/${id}/`, payload);
};

export const deleteCategory = (id: number) => {
  return authenticated(api).delete(`/category/${id}/`);
};

// ---------->  Sub Category
export const postSubCategory = (payload: Record<string, any>) => {
  return authenticated(api).post('/sub-category/', payload);
};

export const getSubCategory = (params: Record<string, any>) => {
  return authenticated(api).get('/sub-category/', { params });
};

export const getSubCategoryById = (id: number) => {
  return authenticated(api).get(`/sub-category/${id}/`);
};

export const patchSubCategory = (id: number, payload: Record<string, any>) => {
  return authenticated(api).patch(`/sub-category/${id}/`, payload);
};

export const deleteSubCategory = (id: number) => {
  return authenticated(api).delete(`/sub-category/${id}/`);
};
