interface IFormContainerProps {
  children: React.ReactNode;
}

const FormContainer = ({ children }: IFormContainerProps) => {
  return (
    <div className="form-container naxatw-w-full naxatw-overflow-hidden naxatw-rounded-xl naxatw-bg-white md:naxatw-h-full md:naxatw-w-[34.75rem] md:naxatw-min-w-[34.75rem] md:naxatw-max-w-[34.75rem] 2xl:naxatw-w-[40rem] 2xl:naxatw-max-w-[40rem]">
      {children}
    </div>
  );
};

export default FormContainer;
