/* eslint-disable no-param-reassign */
import axios, { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';
// import getCookie from '@Utils/cookieUtils';

const { API_URL_V1, FAST_API } = process.env;

export const fastApiURL = FAST_API;
export const apiURL = API_URL_V1;

export const api = axios.create({
  baseURL: API_URL_V1,
  timeout: 5 * 60 * 1000,
  headers: {
    accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
});

export const fastApi = axios.create({
  baseURL: FAST_API,
  timeout: 5 * 60 * 1000,
  headers: {
    accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  },
});

// export const authenticated = (apiInstance: AxiosInstance) => {
//   const newApiInstance = axios.create({
//     ...apiInstance.defaults,
//   });

//   if (process.env.NODE_ENV === 'development') {
//     const token = localStorage.getItem('token');
//     if (token) {
//       newApiInstance.defaults.headers.common.Authorization = `Token ${token}`;
//     }
//   } else {
//     // Set CSRF token for relevant request methods
//     newApiInstance.defaults.headers.post['X-CSRFToken'] =
//       getCookie('csrftoken');
//     newApiInstance.defaults.headers.patch['X-CSRFToken'] =
//       getCookie('csrftoken');
//     newApiInstance.defaults.headers.delete['X-CSRFToken'] =
//       getCookie('csrftoken');
//     // Ensure credentials are included in requests
//     newApiInstance.defaults.withCredentials = true;
//   }
//   return newApiInstance;
// };

api.interceptors.request.use(
  request => {
    return request;
  },
  error => {
    return Promise.reject(error);
  },
);

// Redirect to login if status is 401 which is unauthorized
api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      window.location.href = '/login';
      toast.error('Session Expired. Please Re-login.');
      localStorage.removeItem('token');
    }

    return Promise.reject(error);
  },
);

fastApi.interceptors.request.use(
  request => {
    return request;
  },
  error => {
    return Promise.reject(error);
  },
);

// Redirect to login if status is 401 which is unauthorized
fastApi.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      window.location.href = '/login';
      toast.error('Session Expired. Please Re-login.');
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  },
);

export const authenticated = (apiInstance: AxiosInstance) => {
  const token = localStorage.getItem('token');
  if (!token) return apiInstance;
  if (process.env.NODE_ENV === 'development') {
    apiInstance.defaults.headers.common.Authorization = `Token ${token}`;
  } else {
    apiInstance.defaults.headers.common.Authorization = `Token ${token}`;
    apiInstance.defaults.withCredentials = false;
  }
  return apiInstance;
};
