import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import AdminHeader from '@Components/common/AdminHeader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ModalContainer from '@Components/common/FormUI/ModalContainer';
import CategoryTable from './CategoryTable';
import CategoryForm from './CategoryForm';

const Category = () => {
  const navigate = useNavigate();
  const { catId } = useParams();
  const { pathname } = useLocation();
  const [toggleForm, setToggleForm] = useState<boolean>(false);

  useEffect(() => {
    if (pathname.includes('edit') || pathname.includes('add')) {
      setToggleForm(true);
    } else {
      setToggleForm(false);
    }
  }, [pathname]);
  return (
    <div className="category-form  naxatw-h-full  naxatw-space-y-6">
      <AdminHeader
        title="Agenda Category"
        registerButtonText="Add Category"
        onAddNewClick={() => navigate('/data-bank/category/add')}
      />

      {toggleForm && (
        <ModalContainer
          title={`${catId ? 'Edit' : 'Add'} Category`}
          onHandleClose={() => navigate('/data-bank/category')}
        >
          <CategoryForm onClose={() => navigate('/data-bank/category')} />
        </ModalContainer>
      )}

      <div className="naxatw-h-full">
        <CategoryTable />
      </div>
    </div>
  );
};

export default hasErrorBoundary(Category);
