import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Metric from '@Components/common/Card/Metric';
import {
  getComponentDetailCount,
  getProgrammeDataByProgrammeId,
} from '@Services/provincialProfile';
import { convertToCurrencySystem } from '@Utils/index';

function Metrices() {
  const { programmeId } = useParams();
  const [searchParams] = useSearchParams();
  const componentId = searchParams.get('componentId');

  const { data: programmeDataList, isLoading: isProgrammeLoading } = useQuery({
    queryKey: ['programmeList', programmeId, componentId],
    queryFn: async () =>
      getProgrammeDataByProgrammeId({
        program: programmeId,
        project: componentId,
      }),
    // enabled: !!provinceId || !!searchedText,
    select: res => res.data[0],
    enabled: !!programmeId,
  });

  const { data: componentDataList, isLoading: isComponentDataLoading } =
    useQuery({
      queryKey: ['componentList', programmeId, componentId],
      queryFn: async () =>
        getComponentDetailCount({
          program: programmeId,
          project: componentId,
        }),
      enabled: !!componentId,
      select: res => res.data[0],
    });

  return (
    <div
      className={`naxatw-grid naxatw-grid-cols-1 naxatw-gap-5 sm:naxatw-grid-cols-2 ${componentId ? 'lg:naxatw-grid-cols-3' : 'lg:naxatw-grid-cols-4'}`}
    >
      <>
        {!componentId && (
          <Metric
            label="Project"
            metricCount={
              componentDataList?.project_count ||
              programmeDataList?.project_count
            }
            iconName="folder_open"
            isLoading={
              componentId ? isComponentDataLoading : isProgrammeLoading
            }
          />
        )}
        <Metric
          label="Sectors"
          metricCount={
            componentDataList?.sector_count || programmeDataList?.sector_count
          }
          iconName="category"
          isLoading={isProgrammeLoading}
        />
        <Metric
          label="Total Allocated Budget"
          metricCount={convertToCurrencySystem(
            componentDataList?.allocated_budget ||
              programmeDataList?.allocated_budget,
          )}
          iconName="currency_pound"
          isLoading={isProgrammeLoading}
        />
        <Metric
          label="Markers"
          metricCount={
            componentDataList?.marker_count || programmeDataList?.marker_count
          }
          iconName="donut_small"
          isLoading={isProgrammeLoading}
        />
      </>
    </div>
  );
}
export default hasErrorBoundary(Metrices);
