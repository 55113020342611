import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import ChartContainer from '@Components/common/Charts/ChartContainer';
import ContentBox from '@Components/common/ContentBox';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { FlexColumn } from '@Components/common/Layouts';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { getAgreementModality } from '@Services/provincialProfile';
import { getRandomNumber } from '@Utils/index';

function AgreementModalityChart({ isExport }: { isExport?: boolean }) {
  const { programmeId } = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ['agreement-modality', programmeId],
    queryFn: () => getAgreementModality({ program: programmeId }),
    select: res => {
      if (!res?.data) return null;
      return res.data.map((record: Record<string, any>) => ({
        name: record?.project__aggrement_modality__name,
        value: record?.count,
      }));
    },
  });

  return (
    <ContentBox
      title="Agreement Modality by No. of Projects"
      headerClassName={`${isExport ? '!naxatw-border-b-pdf-border' : '!naxatw-border-b !naxatw-border-b-primary-200'} `}
      className={`${isExport ? '!naxatw-border-pdf-border' : ''} naxatw-h-full`}
      isPdfExport={isExport}
    >
      <FlexColumn className="naxatw-min-h-[250px] naxatw-justify-center naxatw-px-4">
        {isLoading ? (
          <FlexColumn className="naxatw-mb-6 naxatw-gap-6">
            {Array(5)
              .fill(0)
              .map((_, idx) => (
                <Skeleton
                  className="naxatw-h-8 naxatw-w-full"
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  style={{ width: `${getRandomNumber(28, 86)}%` }}
                />
              ))}
          </FlexColumn>
        ) : (
          <ChartContainer
            title=""
            chartTitle=""
            // eslint-disable-next-line
            header={() => <></>}
            data={data || []}
            type="verticalBar"
            xLabel="Number of Projects"
            yLabel="Agreement Modality"
            hoverTitle="Projects"
            yLabelClassNames="!naxatw-left-[-2.6rem] naxatw-relative"
            height="200px"
            className="naxatw-gap-0"
            fill={['#B7E7E4']}
            barSize={48}
            isExport={isExport}
          />
        )}
      </FlexColumn>
    </ContentBox>
  );
}

export default hasErrorBoundary(AgreementModalityChart);
