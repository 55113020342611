import Metric from '@Components/common/Card/Metric';
import { useTypedSelector } from '@Store/hooks';
import { convertToCurrencySystem } from '@Utils/index';
import { useQuery } from '@tanstack/react-query';
import {
  getProjectByProvince,
  getProvincialCountData,
} from '@Services/provincialProfile';
import {
  excludeProvinces,
  getMappedXNames,
  projectProvinces,
} from '@Constants/mainDashboard';
import WorkingAreas from './WorkingAreas';
import ProjectChart from './ProjectChart';
import ProgrammeChart from './ProgrammeChart';

const DataSummaryContent = () => {
  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const projectFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );

  const subMarkersParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );

  const provinceFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.province,
  );

  const statusFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );

  const showFilterColumn = useTypedSelector(
    state => state.mainDashboard.showFilterColumn,
  );

  const filterDateRange = startDate && endDate ? `${startDate}-${endDate}` : '';

  const { data: chartsData } = useQuery({
    queryKey: [
      'get-project-by-province',
      statusFilterParams,
      programmeFilterParams,
      projectFilterParams,
      firstTierPartnerParams,
      sectorGroupParams,
      markerGroupParams,
      subMarkersParams,
      provinceFilterParams,
      filterDateRange,
    ],
    queryFn: () =>
      getProjectByProvince({
        status: statusFilterParams,
        program: programmeFilterParams?.join(','),
        project: projectFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        province: provinceFilterParams?.join(','),
        start_date: startDate,
        end_date: endDate,
      }),
    select: res => {
      if (!res?.data) return [];
      const chartData = res.data;

      const finalData = chartData.map((chartItem: Record<string, any>) => ({
        name: getMappedXNames[chartItem.name] || chartItem.name,
        value: chartItem.count,
      }));

      return finalData;
    },
  });

  const { data: overallCount, isLoading: isDataLoading } = useQuery({
    queryKey: [
      'overall-count-charts',
      statusFilterParams,
      programmeFilterParams,
      projectFilterParams,
      firstTierPartnerParams,
      sectorGroupParams,
      markerGroupParams,
      subMarkersParams,
      provinceFilterParams,
      filterDateRange,
    ],
    queryFn: async () =>
      getProvincialCountData({
        status: statusFilterParams,
        program: programmeFilterParams?.join(','),
        project: projectFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        province: provinceFilterParams?.join(','),
        start_date: startDate,
        end_date: endDate,
      }),
    select: res => {
      if (!res?.data) return null;
      return res.data;
    },
  });

  const {
    first_tier_partner_count: partners,
    allocated_budget: budget,
    program_count: programCount,
    project_count: projectCount,
    marker_count: markers,
    sector_count: sectors,
    stakeholder_count: stakeholdersCount,
  } = overallCount || {};

  return (
    <div className="data-summary-content  naxatw-bg-[#F4F7FE] naxatw-p-5 lg:naxatw-h-[calc(100%-3.8rem)]  ">
      <div className="naxatw-h-full">
        <div className="naxatw-grid naxatw-gap-3  lg:naxatw-grid-cols-4">
          <Metric
            label="Programme"
            iconName="list_alt"
            metricCount={programCount}
            isLoading={isDataLoading}
            className={`${showFilterColumn ? '!naxatw-px-3' : '!naxatw-px-4'} naxatw-row-end-1 `}
            countStyles="!naxatw-text-[1.25rem] !naxatw-leading-normal"
            labelClassNames="!naxatw-text-[0.875rem] !naxatw-leading-normal"
            iconClassName={`${showFilterColumn ? '!naxatw-p-2' : ''}`}
          />
          <Metric
            label="Project"
            iconName="folder_open"
            metricCount={projectCount}
            isLoading={isDataLoading}
            className={`${showFilterColumn ? '!naxatw-px-3' : '!naxatw-px-4'}`}
            countStyles="!naxatw-text-[1.25rem] !naxatw-leading-normal"
            labelClassNames="!naxatw-text-[0.875rem] !naxatw-leading-normal"
            iconClassName={`${showFilterColumn ? '!naxatw-p-2' : ''}`}
          />
          <Metric
            label="First Tier Partner"
            iconName="handshake"
            metricCount={partners}
            isLoading={isDataLoading}
            className={`${showFilterColumn ? '!naxatw-px-3' : '!naxatw-px-4'}`}
            countStyles="!naxatw-text-[1.25rem] !naxatw-leading-normal"
            labelClassNames="!naxatw-text-[0.875rem] !naxatw-leading-normal"
            iconClassName={`${showFilterColumn ? '!naxatw-p-2' : ''}`}
          />
          <Metric
            label="Stakeholder"
            iconName="groups"
            metricCount={stakeholdersCount}
            isLoading={isDataLoading}
            className={`${showFilterColumn ? '!naxatw-px-3' : '!naxatw-px-4'}`}
            countStyles="!naxatw-text-[1.25rem] !naxatw-leading-normal"
            labelClassNames="!naxatw-text-[0.875rem] !naxatw-leading-normal"
            iconClassName={`${showFilterColumn ? '!naxatw-p-2' : ''}`}
          />
        </div>

        <div className="naxatw-mt-4 naxatw-grid naxatw-h-[calc(100%-6rem)] naxatw-gap-3 lg:naxatw-grid-cols-[1fr_1.5fr_1.2fr] ">
          <WorkingAreas
            className="!naxatw-col-span-2 !naxatw-h-full"
            overallCount={overallCount || []}
          />
          <ProjectChart
            className="naxatw-row-[span_4]"
            chartsData={
              chartsData?.filter((province: Record<string, any>) =>
                projectProvinces.includes(province.name),
              ) || []
            }
          />

          <Metric
            label="Sectors"
            iconName="category"
            metricCount={sectors}
            isLoading={isDataLoading}
            className={`${showFilterColumn ? '!naxatw-px-3' : '!naxatw-px-4'} naxatw-row-[2]`}
            countStyles="!naxatw-text-[1.25rem] !naxatw-leading-normal"
            labelClassNames="!naxatw-text-[0.875rem] !naxatw-leading-normal"
            iconClassName={`${showFilterColumn ? '!naxatw-p-2' : ''}`}
          />
          <ProgrammeChart
            chartsData={
              chartsData?.filter(
                (province: Record<string, any>) =>
                  !excludeProvinces.includes(province.name),
              ) || []
            }
            className="naxatw-row-[span_3]"
          />
          <Metric
            label="Markers"
            iconName="donut_small"
            metricCount={markers}
            isLoading={isDataLoading}
            className={`${showFilterColumn ? '!naxatw-px-3' : '!naxatw-px-4'} naxatw-row-start-3`}
            countStyles="!naxatw-text-[1.25rem] !naxatw-leading-normal"
            labelClassNames="!naxatw-text-[0.875rem] !naxatw-leading-normal"
            iconClassName={`${showFilterColumn ? '!naxatw-p-2' : ''}`}
          />
          <Metric
            label="Allocated Budget"
            iconName="currency_pound"
            metricCount={convertToCurrencySystem(budget)}
            isLoading={isDataLoading}
            className={`${showFilterColumn ? '!naxatw-px-3' : '!naxatw-px-4'} naxatw-row-start-4`}
            countStyles="!naxatw-text-[1.25rem] !naxatw-leading-normal"
            labelClassNames="!naxatw-text-[0.875rem] !naxatw-leading-normal"
            iconClassName={`${showFilterColumn ? '!naxatw-p-2' : ''}`}
          />
        </div>
      </div>
    </div>
  );
};

export default DataSummaryContent;
