import * as React from 'react';
import { cn } from '@Utils/index';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Arrow,
} from '@radix-ui/react-tooltip';
import { excludeTooltipKeys } from '@Constants/index';

const TableTooltip = ({ content }: { content: React.ReactNode }) => {
  return (
    <>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <span className="naxatw-line-clamp-1 naxatw-py-4 naxatw-text-left naxatw-leading-4">
              {content}
            </span>
          </TooltipTrigger>
          <TooltipContent
            sideOffset={0}
            className="naxatw-z-50  naxatw-min-w-[150px] naxatw-max-w-[40rem] naxatw-text-center"
          >
            <div className="message naxatw-rounded-sm naxatw-bg-[#417EC9] naxatw-px-3 naxatw-py-2 naxatw-text-xs naxatw-font-medium naxatw-text-white">
              {content}
            </div>
            <Arrow
              className="TooltipArrow naxatw-rounded"
              style={{ fill: '#417EC9' }}
            />
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </>
  );
};

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement>
>(({ className, ...props }, ref) => (
  <div
    className={cn(
      'scrollbar naxatw-h-fit naxatw-max-h-[calc(100vh-10rem)] naxatw-w-full naxatw-overflow-x-auto naxatw-rounded-xl naxatw-border naxatw-border-[#EAECF0]',
      className,
    )}
  >
    <table
      ref={ref}
      className={cn(
        'naxatw-relative naxatw-w-full naxatw-caption-bottom naxatw-text-sm',
        className,
      )}
      {...props}
    />
  </div>
));
Table.displayName = 'Table';

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn(
      '[&_tr]:border-b naxatw-body-sm naxatw-sticky naxatw-top-0 naxatw-z-10 naxatw-truncate',
      className,
    )}
    {...props}
  />
));
TableHeader.displayName = 'TableHeader';

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn('[&_tr:last-child]:border-0', className)}
    {...props}
  />
));
TableBody.displayName = 'TableBody';

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement>
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn(
      'naxatw-text-primary-foreground naxatw-bg-primary-600 naxatw-font-medium',
      className,
    )}
    {...props}
  />
));
TableFooter.displayName = 'TableFooter';

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement>
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      'data-[state=selected]:naxatw-bg-muted naxatw-border-b naxatw-border-[#EAECF0] naxatw-transition-colors first:!naxatw-border-b-[1px] last:naxatw-border-b-[0px] hover:naxatw-bg-blue-50',
      className,
    )}
    {...props}
  />
));
TableRow.displayName = 'TableRow';

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      'naxatw-table-head naxatw-bg-white naxatw-py-3 naxatw-pl-0 naxatw-font-semibold [&:has([role=checkbox])]:naxatw-pr-0',
      className,
    )}
    {...props}
  />
));
TableHead.displayName = 'TableHead';

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement>
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      'naxatw-table-body naxatw-bg-white naxatw-pl-3 naxatw-capitalize xl:naxatw-pl-6 [&:has([role=checkbox])]:naxatw-pr-0',
      className,
    )}
    {...props}
  >
    {/* @ts-ignore */}
    {!excludeTooltipKeys?.includes(props?.columnId) ? (
      <TableTooltip content={props?.children} />
    ) : (
      <>{props.children}</>
    )}
  </td>
));

TableCell.displayName = 'TableCell';

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement>
>(({ className, ...props }, ref) => (
  <caption ref={ref} className={cn('naxatw-mt-4 ', className)} {...props} />
));
TableCaption.displayName = 'TableCaption';

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
};
