import Explore from '@Views/Explore';
import LandingPage from '@Views/Landingpage';
import MainDashboardPage from '@Views/MainDashboard';
import Login from '@Views/Login';
import AdminPanel from '@Views/AdminPanel';
import ExploreByGeographyPage from '@Views/ExploreByGeography';
import ProvincePage from '@Views/ProvincePage';
import ExploreByProgrammePage from '@Views/ExploreByProgramme';
import ExploreByIndicatorsPage from '@Views/ExploreByIndicators';
import ProgrammeDetailsPage from '@Views/ProgrammeDetails';
import KnowledgeRepositoryPage from '@Views/KnowledgeRepository';
import TutorialPage from '@Views/Tutorial';

import ExportPdf from '@Components/ProvincialProfile/ExportPdf';
import UpdatesPage from '@Views/UpdaatesPage/UpdatesPage';
import ExportProgrammePdf from '@Components/ProgrammeDetails/ExportProgrammePdf';
import ExportProjectPdf from '@Components/ProgrammeDetails/ExportProjectPdf';
import ExportReport from '@Components/MainDashboard/ExportReport';
import ReportMuniclipality from '@Components/ProvincialProfile/ReportMuniclipality';
import { IRoute } from './types';

const appRoutes: IRoute[] = [
  {
    path: '/login',
    name: 'Landing',
    component: Login,
    authenticated: false,
  },
  {
    path: '/sign-up',
    name: 'Landing',
    component: Login,
    authenticated: false,
  },
  {
    path: '/sign-up/verify-email',
    name: 'Landing',
    component: Login,
    authenticated: false,
  },
  {
    path: '/sign-up/verify-fcdo-email',
    name: 'Landing',
    component: Login,
    authenticated: false,
  },
  {
    path: '/forgot-password',
    name: 'Landing',
    component: Login,
    authenticated: false,
  },
  {
    path: '/reset-password/:uid/:token',
    name: 'Landing',
    component: Login,
    authenticated: false,
  },
  {
    path: '/',
    name: 'Explore ',
    component: Explore,
    authenticated: true,
  },
  {
    path: '/Landingpage',
    name: 'Landingpage ',
    component: LandingPage,
    authenticated: false,
  },
  {
    path: '/dashboard/map',
    name: 'MainDashboardPage',
    component: MainDashboardPage,
    authenticated: true,
  },
  {
    path: '/dashboard/table',
    name: 'MainDashboardPage',
    component: MainDashboardPage,
    authenticated: true,
  },
  {
    path: '/dashboard/chart',
    name: 'MainDashboardPage',
    component: MainDashboardPage,
    authenticated: true,
  },
  {
    path: '/data-bank/*',
    name: 'DataBank',
    component: AdminPanel,
    authenticated: true,
  },
  {
    path: '/explore-by/geography',
    name: 'ExploreByGeography',
    component: ExploreByGeographyPage,
    authenticated: true,
  },
  {
    path: '/explore-by/geography/:provinceId',
    name: 'ExploreByGeographyLumbini',
    component: ProvincePage,
    authenticated: true,
  },
  {
    path: '/explore-by/geography/:provinceId/export',
    name: 'ExploreByGeography Export Pdf',
    component: ExportPdf,
    authenticated: true,
  },
  {
    path: '/explore-by/programmes',
    name: 'ExploreByProgramme',
    component: ExploreByProgrammePage,
    authenticated: true,
  },
  {
    path: '/explore-by/programmes/:programmeId',
    name: 'Programme Details',
    component: ProgrammeDetailsPage,
    authenticated: true,
  },
  {
    path: '/explore-by/programmes/:programmeId/export',
    name: 'Programme Details Export',
    component: ExportProgrammePdf,
    authenticated: true,
  },
  {
    path: '/explore-by/programmes/:programmeId/projectId/:projectId/export',
    name: 'Project Details Export',
    component: ExportProjectPdf,
    authenticated: true,
  },
  {
    path: '/explore-by/geography/:provinceId/municipality/:municipalityId/export',
    name: 'Municiplaity Details Export ',
    component: ReportMuniclipality,
    authenticated: true,
  },
  {
    path: '/explore-by/indicators',
    name: 'ExploreByIndicators',
    component: ExploreByIndicatorsPage,
    authenticated: true,
  },
  {
    path: '/knowledge-library',
    name: 'Knowledge Library',
    component: KnowledgeRepositoryPage,
    authenticated: true,
    // permission: 'Can view knowledge repository',
  },
  {
    path: '/knowledge-library/contextual',
    name: 'Knowledge Library',
    component: KnowledgeRepositoryPage,
    authenticated: true,
    // permission: 'Can view knowledge repository',
  },
  {
    path: '/knowledge-library/evidence-agenda',
    name: 'Knowledge Library',
    component: KnowledgeRepositoryPage,
    authenticated: true,
  },
  {
    path: '/dvs-tutorial',
    name: 'DVS Tutorial',
    component: TutorialPage,
    authenticated: true,
  },
  {
    path: '/dvs-tutorial/:tutorialType',
    name: 'DVS Tutorial',
    component: TutorialPage,
    authenticated: true,
  },
  {
    path: '/updates',
    name: 'Updates Page',
    component: UpdatesPage,
    authenticated: true,
  },
  {
    path: '/update/:updateId',
    name: 'Updates Page',
    component: UpdatesPage,
    authenticated: true,
  },
  {
    path: '/update/share/:updateId',
    name: 'Updates Share Page',
    component: UpdatesPage,
    authenticated: true,
  },
  {
    path: '/dashboard/map/export',
    name: 'Dashboard Export Pdf',
    component: ExportReport,
    authenticated: true,
  },
  {
    path: '/dashboard/table/export',
    name: 'Dashboard Export Pdf',
    component: ExportReport,
    authenticated: true,
  },
  {
    path: '/dashboard/chart/export',
    name: 'Dashboard Export Pdf',
    component: ExportReport,
    authenticated: true,
  },
];

export default appRoutes;
