/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useTypedSelector } from '@Store/hooks';
import Checkbox from '@Components/common/FormUI/CheckBox';
import Icon from '@Components/common/Icon';
import { ChevronDown } from 'lucide-react';
import {
  getFilterEviFileList,
  getFilterEviThemeList,
  getFilterEviProgrammeList,
} from '@Services/publicKnowledgeRepository';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@Components/RadixComponents/Accordion';
import isEmpty from '@Utils/isEmpty';

import {
  postEviAgendaFilterParams,
  resetEvidenceAgendaFilterState,
  setKnowledgeLibraryDateRangeState,
  setKnowledgeLibraryStates,
  toggleknowledgeRepositoryFilterState,
} from '@Store/actions/knowledgeRepository';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import {
  convertEvidenceKnowledgeLibraryFilterData,
  updateFilterState,
} from '@Utils/convertFilterData';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import MultipleDatePicker from '@Components/common/FormUI/MultipleDatePicker/MultipleDatePicker';
import { categories, excludeFilterItems } from '@Constants/filters';
import FilterSearchCategory from '@Components/MainDashboard/Filters/FilterSearchCategory';
import {
  getEvidenceAgendaStatus,
  getEviSubCatFilters,
  getGeographicFocus,
  getTeamMembership,
} from '@Services/knowledgeRepository';
import KnowLibSk from '../KnowLibSk';

const EvidenceAgendaFilters = () => {
  const dispatch = useDispatch();

  const isInitialFilterStateFetch = useRef(true);

  const [searchFilterTerm, setSearchFilterTerm] = useState('');
  // const [seacrhSector, setSearchSector] = useState('');
  const [searchTheme, setSearchTheme] = useState('');
  // const [searchTeamownership, setSearchTeamownershp] = useState('');
  const [programmeFilterCount, setProgrammeFilterCount] = useState(0);
  const [sectorFilterCount, setSectorFilterCount] = useState(0);
  const [activeAccordion, setActiveAccordion] = useState(1);

  const mappedFilterCount: Record<string, any> = {
    Programme: programmeFilterCount,
    Sector: sectorFilterCount,
  };

  const filterState = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterState,
  );

  const programmeFilterParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.program,
  );

  const projectFilterParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.project,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.sector,
  );

  const startDateParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.startDate,
  );

  const endDateParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.startDate,
  );

  const fileTypeParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.file_type,
  );

  const themeFilterParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.themes,
  );

  const geographicFocusFilterParams = useTypedSelector(
    state =>
      state.knowledgeRepository.evidenceAgendaFilterParams.geographicFocus,
  );

  const teamOwnershipFilterParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.teamOwnership,
  );

  const statusFilterParams = useTypedSelector(
    state =>
      state.knowledgeRepository.evidenceAgendaFilterParams.evidenceStatus,
  );

  const [searchFilter, handleFilterChange] = useDebouncedInput({
    ms: 400,
    init: searchFilterTerm,
    onChange: debouncedEvent =>
      setSearchFilterTerm(debouncedEvent.target.value),
  });

  // const [searchSectorTerm, handleSectorChange] = useDebouncedInput({
  //   ms: 400,
  //   init: seacrhSector,
  //   onChange: debouncedEvent => setSearchSector(debouncedEvent.target.value),
  // });

  const [searchThemeTerm, handleSearchTheme] = useDebouncedInput({
    ms: 400,
    init: searchTheme,
    onChange: debouncedEvent => setSearchTheme(debouncedEvent.target.value),
  });

  // const [searchTeamownershipTerm, handleTeamownershipSearchTerm] =
  //   useDebouncedInput({
  //     ms: 400,
  //     init: searchTeamownership,
  //     onChange: debouncedEvent =>
  //       setSearchTeamownershp(debouncedEvent.target.value),
  //   });

  // const [statusSearchTerm, handleStatusSearchTerm] = useDebouncedInput({
  //   ms: 400,
  //   init: searchStatus,
  //   onChange: debouncedEvent => setSearchStatus(debouncedEvent.target.value),
  // });

  // check single filter count
  const checkSingleFilter = useCallback(
    (category: string) => {
      const result = filterState?.find(
        (filterItem: Record<string, any>) => filterItem.category === category,
      );
      const checkedCount =
        result?.subcategories.filter((i: any) => i.checked).length || 0;
      switch (category) {
        case 'Programme':
          setProgrammeFilterCount(checkedCount);
          break;
        case 'Sector':
          setSectorFilterCount(checkedCount);
          break;
        default:
          break;
      }
    },
    [filterState],
  );

  // cetegoriaze `gesi` and none-gesi themes id
  const gesiThemesId = themeFilterParams
    ?.filter((theme: Record<string, any>) => theme.isGes)
    .map((theme: Record<string, any>) => theme.value);

  const otherThemesId = themeFilterParams
    ?.filter((theme: Record<string, any>) => !theme.isGes)
    .map((theme: Record<string, any>) => theme.value);

  // api calls
  // Programme
  const { data: programData, isSuccess: isProgramSuccess } = useQuery({
    queryKey: [
      'get-repo-evi-filter-program-list',
      searchFilter,
      sectorGroupParams,
      startDateParams,
      endDateParams,
      fileTypeParams,
      teamOwnershipFilterParams,
      geographicFocusFilterParams,
      gesiThemesId,
      otherThemesId,
    ],
    queryFn: () =>
      getFilterEviProgrammeList({
        program: searchFilter.trim(),
        sector_id: sectorGroupParams?.join(','),
        start_date: startDateParams,
        end_date: endDateParams,
        file_type: fileTypeParams?.join(','),
        geographic_focus: geographicFocusFilterParams?.join(','),
        team_membership: teamOwnershipFilterParams?.join(','),
        gesi: gesiThemesId?.join(','),
        agenda_evidence: otherThemesId?.join(','),
        evidence_agenda_status: statusFilterParams?.join(','),
      }),
    select: res => res?.data,
  });

  // Sector
  // const { data: sectorData, isSuccess: isSectorSuccess } = useQuery({
  //   queryKey: [
  //     'get-filter-evi-sector-list',
  //     searchSectorTerm,
  //     programmeFilterParams,
  //     projectFilterParams,
  //     startDateParams,
  //     endDateParams,
  //     fileTypeParams,
  //     geographicFocusFilterParams,
  //     teamOwnershipFilterParams,
  //     gesiThemesId,
  //     otherThemesId,
  //     statusFilterParams,
  //   ],
  //   queryFn: () =>
  //     getFilterEviSectorList({
  //       sector: searchSectorTerm.trim(),
  //       program_id: programmeFilterParams?.join(','),
  //       project_id: activeAccordion === 2 ? '' : projectFilterParams?.join(','),
  //       start_date: startDateParams,
  //       end_date: endDateParams,
  //       file_type: fileTypeParams?.join(','),
  //       geographic_focus: geographicFocusFilterParams?.join(','),
  //       team_membership: teamOwnershipFilterParams?.join(','),
  //       gesi: gesiThemesId?.join(','),
  //       agenda_evidence: otherThemesId?.join(','),
  //       knowledge_repo_status: statusFilterParams?.join(','),
  //     }),
  //   select: res => res?.data,
  // });

  // file type
  const { data: fileTypeData, isSuccess: isFileTypeSuccess } = useQuery({
    queryKey: [
      'get-repo-evi-file-type',
      programmeFilterParams,
      projectFilterParams,
      sectorGroupParams,
      startDateParams,
      endDateParams,
      geographicFocusFilterParams,
      teamOwnershipFilterParams,
      gesiThemesId,
      otherThemesId,
      statusFilterParams,
    ],
    queryFn: () =>
      getFilterEviFileList({
        program_id: programmeFilterParams?.join(','),
        project_id: activeAccordion === 3 ? '' : projectFilterParams?.join(','),
        sector_id: sectorGroupParams?.join(','),
        start_date: startDateParams,
        end_date: endDateParams,
        geographic_focus: geographicFocusFilterParams?.join(','),
        team_membership: teamOwnershipFilterParams?.join(','),
        gesi: gesiThemesId?.join(','),
        agenda_evidence: otherThemesId?.join(','),
        evidence_agenda_status: statusFilterParams?.join(','),
      }),
    select: res => res?.data,
  });

  // fetch Theme filter options
  const { data: themes, isSuccess: isThemeFetchSuccess } = useQuery({
    queryKey: [
      'get-evidence-agenda-filter-theme',
      programmeFilterParams,
      fileTypeParams,
      sectorGroupParams,
      startDateParams,
      endDateParams,
      searchThemeTerm,
      geographicFocusFilterParams,
      statusFilterParams,
      teamOwnershipFilterParams,
    ],
    queryFn: () =>
      getFilterEviThemeList({
        program: programmeFilterParams?.join(','),
        start_date: startDateParams,
        end_date: endDateParams,
        search: searchThemeTerm.trim(),
        file_type: fileTypeParams.join(','),
        geographic_focus: geographicFocusFilterParams?.join(','),
        team_membership: teamOwnershipFilterParams?.join(','),
        evidence_agenda_status: statusFilterParams?.join(','),
      }),
    select: res => res?.data,
  });

  // fetch Team membership filter options
  const { data: teamOwnership, isSuccess: isTeamownershipFetchSuccess } =
    useQuery({
      queryKey: ['evi-ownership-filter'],
      queryFn: () =>
        getTeamMembership({
          type: 'team_membership',
        }),
      select: res => res?.data,
    });

  // fetch Geographic focus filter options
  const { data: geographicFocus, isSuccess: isGeographicFocusFetchSuccess } =
    useQuery({
      queryKey: [
        'evi-geographic-focus-filter',
        programmeFilterParams,
        sectorGroupParams,
        startDateParams,
        endDateParams,
        searchThemeTerm,
        teamOwnershipFilterParams,
        statusFilterParams,
        gesiThemesId,
        otherThemesId,
      ],
      queryFn: () =>
        getGeographicFocus({
          program: programmeFilterParams?.join(','),
          sector_id: sectorGroupParams?.join(','),
          start_date: startDateParams,
          end_date: endDateParams,
          search: searchThemeTerm.trim(),
          team_membership: teamOwnershipFilterParams?.join(','),
          gesi: gesiThemesId?.join(','),
          agenda_evidence: otherThemesId?.join(','),
          evidence_agenda_status: statusFilterParams?.join(','),
        }),
      select: res => res?.data,
    });

  // fetch Avidence agenda status options
  const { data: statusData, isSuccess: isStatusFetchSuccess } = useQuery({
    queryKey: ['evi-status-filter'],
    queryFn: () =>
      getEvidenceAgendaStatus({
        type: 'evidence_agenda_status',
      }),
    select: res => res?.data,
  });

  // fetch Category filter options
  const { data: categoryData, isSuccess: isCategoryDataSuccess } = useQuery({
    queryKey: [
      'get-evidence-agenda-filter-category',
      programmeFilterParams,
      fileTypeParams,
      sectorGroupParams,
      startDateParams,
      endDateParams,
      searchThemeTerm,
      geographicFocusFilterParams,
      statusFilterParams,
      teamOwnershipFilterParams,
    ],
    queryFn: () =>
      getEviSubCatFilters({
        program: programmeFilterParams?.join(','),
        start_date: startDateParams,
        end_date: endDateParams,
        search: searchThemeTerm.trim(),
        file_type: fileTypeParams.join(','),
        geographic_focus: geographicFocusFilterParams?.join(','),
        team_membership: teamOwnershipFilterParams?.join(','),
        evidence_agenda_status: statusFilterParams?.join(','),
        gesi: gesiThemesId?.join(','),
        agenda_evidence: otherThemesId?.join(','),
      }),
    select: res => res?.data,
  });

  useEffect(() => {
    if (
      isProgramSuccess &&
      isFileTypeSuccess &&
      isThemeFetchSuccess &&
      isTeamownershipFetchSuccess &&
      isGeographicFocusFetchSuccess &&
      isStatusFetchSuccess &&
      isCategoryDataSuccess
    ) {
      const responseObject = {
        program: [...programData],
        file_type: [...fileTypeData],
        themes,
        teams: teamOwnership,
        geographicFocus,
        evidenceStatus: statusData,
        category: categoryData,
      };
      const filterData =
        convertEvidenceKnowledgeLibraryFilterData(responseObject);
      if (isInitialFilterStateFetch.current) {
        dispatch(
          setKnowledgeLibraryStates({
            key: 'evidenceAgendaFilterState',
            value: filterData,
          }),
        );
        isInitialFilterStateFetch.current = false;
        return;
      }
      const updatedFilter = updateFilterState(filterData, filterState || []);
      dispatch(
        setKnowledgeLibraryStates({
          key: 'evidenceAgendaFilterState',
          value: updatedFilter,
        }),
      );
    }
  }, [
    isProgramSuccess,
    isFileTypeSuccess,
    isThemeFetchSuccess,
    isTeamownershipFetchSuccess,
    isGeographicFocusFetchSuccess,
    isStatusFetchSuccess,
    programData,
    fileTypeData,
    themes,
    teamOwnership,
    geographicFocus,
    statusData,
    categoryData,
  ]);

  useEffect(() => {
    categories.forEach(category => checkSingleFilter(category));
  }, [filterState, checkSingleFilter]);

  useEffect(() => {
    if (isEmpty(filterState as [])) return;
    dispatch(postEviAgendaFilterParams());
  }, [filterState, dispatch]);

  // reset filter status and set dafault switcher active tab
  // useEffect(() => {
  //   return () => {
  //     dispatch(resetFilterState());
  //     dispatch(setStatusFilterState('Ongoing'));
  //   };
  // }, []);

  return (
    <div className="filter-col naxatw-h-full naxatw-min-w-[15rem] naxatw-rounded-[0.8rem] naxatw-border-[1.124px] naxatw-border-solid naxatw-border-primary-200 naxatw-bg-white naxatw-shadow-xs">
      {/* ------------------ filter header  --------------------> */}
      <div className="filter-header naxatw-flex naxatw-items-center naxatw-justify-between naxatw-border-b naxatw-border-solid naxatw-border-b-[#E0E0E0] naxatw-px-3 naxatw-py-2">
        <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
          <p className="naxatw-text-base naxatw-font-medium naxatw-tracking-[0.00625rem] naxatw-text-gray-800">
            All Filters
          </p>
        </div>

        <button
          type="button"
          className="naxatw-group naxatw-flex naxatw-items-center naxatw-gap-1 naxatw-px-4 naxatw-py-2"
          onClick={() => {
            dispatch(resetEvidenceAgendaFilterState());
          }}
        >
          <Icon
            name="restart_alt"
            className="naxatw-text-secondary-500 naxatw-duration-300 group-hover:naxatw-text-primary-700"
          />

          <span className="naxatw-mb-1 naxatw-text-[0.875rem] naxatw-font-medium naxatw-leading-[0] naxatw-text-secondary-500 naxatw-duration-300 group-hover:naxatw-text-primary-700">
            Clear All
          </span>
        </button>
      </div>
      {/* ------------------ filter body  --------------------> */}

      <div className="filter-body scrollbar naxatw-max-h-[calc(100dvh-250px)] naxatw-overflow-y-scroll naxatw-px-4 naxatw-py-3">
        <div className="naxatw-mb-4">
          <MultipleDatePicker
            startDate={startDateParams}
            setStartDate={(date: Date) =>
              dispatch(
                setKnowledgeLibraryDateRangeState({
                  key: 'evidenceAgendaFilterParams',
                  type: 'startDate',
                  date,
                }),
              )
            }
            endDate={endDateParams}
            setEndDate={(date: Date) =>
              dispatch(
                setKnowledgeLibraryDateRangeState({
                  key: 'evidenceAgendaFilterParams',
                  type: 'endDate',
                  date,
                }),
              )
            }
            clearDateRange={() =>
              dispatch(
                setKnowledgeLibraryDateRangeState({
                  key: 'evidenceAgendaFilterParams',
                  type: 'clear',
                }),
              )
            }
            enableButton
          />
        </div>
        {filterState ? (
          <Accordion type="single" defaultValue="item-1" collapsible>
            {filterState?.map((filterData: Record<string, any>) => {
              return (
                <AccordionItem
                  value={`item-${filterData?.id}`}
                  key={`${filterData?.id}-id`}
                  className="naxatw-border-b-[#E0E0E0] naxatw-py-3 first:naxatw-pt-0"
                >
                  <AccordionTrigger
                    className="naxatw-h-[1.5rem] !naxatw-py-0 hover:!naxatw-no-underline"
                    onClick={() => setActiveAccordion(filterData.id)}
                  >
                    <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
                      <p className="naxatw-text-base naxatw-font-medium naxatw-leading-[0] naxatw-tracking-[0.00625rem] naxatw-text-matt-100">
                        {filterData?.category}
                      </p>
                      {mappedFilterCount[filterData?.category] >= 1 && (
                        <div className="naxatw-flex naxatw-h-5 naxatw-w-5 naxatw-items-center naxatw-justify-center naxatw-rounded-[100px] naxatw-bg-[#F1F1F1] naxatw-p-[1px]">
                          <p className="naxatw-tooltip !naxatw-text-matt-100">
                            {mappedFilterCount[filterData?.category]}
                          </p>
                        </div>
                      )}
                    </div>

                    <ChevronDown
                      className="naxatw-h-4 naxatw-w-4 naxatw-shrink-0 naxatw-transition-transform naxatw-duration-200"
                      style={{
                        strokeWidth: '3px',
                      }}
                    />
                  </AccordionTrigger>
                  <AccordionContent className="acc-content">
                    {filterData?.category === 'Programme' && (
                      <FilterSearchCategory
                        category={`Search ${filterData?.category}`}
                        value={searchFilter}
                        onChange={handleFilterChange}
                      />
                    )}
                    {filterData?.category === 'Theme' && (
                      <FilterSearchCategory
                        category={`Search ${filterData?.category}`}
                        value={searchThemeTerm}
                        onChange={handleSearchTheme}
                      />
                    )}
                    {/* {filterData?.category === 'Team Ownership' && (
                      <FilterSearchCategory
                        category={`Search ${filterData?.category}`}
                        value={searchTeamownershipTerm}
                        onChange={handleTeamownershipSearchTerm}
                      />
                    )} */}
                    {/* {filterData?.category === 'Status' && (
                    <FilterSearchCategory
                      category={`Search ${filterData?.category}`}
                      value={statusSearchTerm}
                      onChange={handleStatusSearchTerm}
                    />
                  )} */}
                    <div className="subcategories-container scrollbar naxatw-max-h-[175px] naxatw-overflow-y-auto">
                      {filterData?.subcategories ? (
                        isEmpty(filterData.subcategories) ? (
                          <p className="naxatw-pt-2 naxatw-text-center naxatw-text-xs naxatw-text-matt-100">
                            No Data Found
                          </p>
                        ) : (
                          <Accordion
                            type="single"
                            collapsible
                            className="naxatw-mr-1"
                          >
                            {filterData?.subcategories?.map(
                              (subCatData: Record<string, any>) => (
                                <AccordionItem
                                  value={`sub-item-${subCatData?.id}`}
                                  key={subCatData?.key || subCatData?.id}
                                  className="!naxatw-border-none naxatw-py-[6px] naxatw-pl-3 first:naxatw-mt-2"
                                >
                                  <AccordionTrigger className="naxatw-justify-between !naxatw-py-0 hover:!naxatw-no-underline">
                                    <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
                                      <Checkbox
                                        className="filter-checkbox"
                                        checked={subCatData?.checked}
                                        onChange={() => {
                                          dispatch(
                                            toggleknowledgeRepositoryFilterState(
                                              {
                                                categoryId:
                                                  subCatData?.categoryId,
                                                subcategoryId: subCatData?.id,
                                                key: 'evidenceAgendaFilterState',
                                              },
                                            ),
                                          );
                                        }}
                                      />
                                      <p
                                        className="naxatw-line-clamp-1 naxatw-text-[0.875rem] naxatw-font-normal naxatw-leading-5 naxatw-text-matt-100"
                                        title={subCatData?.subcategory}
                                      >
                                        {subCatData?.subcategory}
                                      </p>
                                    </div>
                                    {excludeFilterItems?.includes(
                                      filterData.category,
                                    ) ? null : (
                                      <ChevronDown
                                        className="naxatw-h-4 naxatw-w-4 naxatw-shrink-0 naxatw-transition-transform naxatw-duration-200"
                                        style={{
                                          strokeWidth: '2px',
                                        }}
                                      />
                                    )}
                                  </AccordionTrigger>

                                  {!isEmpty(subCatData?.filterItems) ? (
                                    <AccordionContent className="naxatw-mt-2 naxatw-gap-1">
                                      {subCatData?.filterItems?.map(
                                        (filterItem: Record<string, any>) => {
                                          return (
                                            <div
                                              key={filterItem?.filter}
                                              className="naxatw-mb-1 naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-pl-[1.625rem] last:naxatw-mb-0"
                                            >
                                              <Checkbox
                                                className="filter-checkbox"
                                                checked={filterItem?.checked}
                                                onChange={() => {
                                                  dispatch(
                                                    toggleknowledgeRepositoryFilterState(
                                                      {
                                                        categoryId:
                                                          subCatData?.categoryId,
                                                        subcategoryId:
                                                          subCatData?.id,
                                                        filterId:
                                                          filterItem?.id,
                                                        key: 'evidenceAgendaFilterState',
                                                      },
                                                    ),
                                                  );
                                                }}
                                              />
                                              <p>{filterItem?.filter}</p>
                                            </div>
                                          );
                                        },
                                      )}
                                    </AccordionContent>
                                  ) : null}
                                </AccordionItem>
                              ),
                            )}
                          </Accordion>
                        )
                      ) : null}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              );
            })}
          </Accordion>
        ) : (
          <KnowLibSk noOfRows={6} />
        )}
      </div>
    </div>
  );
};

export default hasErrorBoundary(EvidenceAgendaFilters);
