import ChartHeader from '@Components/common/Charts/ChartHeader';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { useRef } from 'react';
import DataSummaryContent from './DataSummaryContent';

const ChartDataSummary = () => {
  const dataSummaryRef = useRef(null);

  return (
    <div
      className="chart-data-summary-container naxatw-h-full  naxatw-overflow-hidden "
      ref={dataSummaryRef}
    >
      <ChartHeader
        chartTitle="Portfolio Summary"
        hasDownloadBtn
        hasFullScreen
        downloadComponentRef={dataSummaryRef}
        data={[]}
        type={undefined}
        hasChart={false}
        noChartContent={<DataSummaryContent />}
        isExport={false}
      />
      <DataSummaryContent />
    </div>
  );
};

export default hasErrorBoundary(ChartDataSummary);
