/* eslint-disable no-nested-ternary */
import { ReactNode, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { useTypedDispatch } from '@Store/hooks';
// import useScrollPosition from '@Hooks/useScrollPosition';
import { setStatus } from '@Store/actions/programmeDetails';
import {
  getProgrammeDataByProgrammeId,
  getComponentDetailCount,
} from '@Services/provincialProfile';
import TopHeader from './TopHeader';

function TopHeaderWrapper({ children }: { children: ReactNode }) {
  // const scrollPosition = useScrollPosition();
  return (
    <div
      //   className={`${scrollPosition > 0 ? 'naxatw-fixed naxatw-top-[6.6vh] naxatw-px-5 naxatw-pt-10' : 'naxatw-relative'} naxatw-left-[50%] naxatw-z-[45] naxatw-w-full naxatw-max-w-[80rem] naxatw-translate-x-[-50%] naxatw-bg-[#F4F7FE] naxatw-pb-5 naxatw-transition-[padding] lg:naxatw-max-w-[90rem] 2xl:naxatw-pr-0`}
      //
      className="programme-header  naxatw-sticky naxatw-top-[63px] naxatw-z-[20] naxatw-bg-primary-100 naxatw-py-5"
    >
      {children}
    </div>
  );
}
const ProgrammeDetailsHeader = () => {
  const { programmeId } = useParams();
  const [searchParams] = useSearchParams();
  const componentId = searchParams.get('componentId');

  const { data: programmeDataList, isLoading: programmeDataIsLoading } =
    useQuery({
      queryKey: ['programmeList', programmeId],
      queryFn: async () =>
        getProgrammeDataByProgrammeId({ program: programmeId }),
      // enabled: !!provinceId || !!searchedText,
      select: res => res.data[0],
      enabled: !!programmeId,
    });

  const { data: componentDataList, isLoading: componentDataIsLoading } =
    useQuery({
      queryKey: ['componentList', programmeId, componentId],
      queryFn: async () =>
        getComponentDetailCount({
          program: programmeId,
          project: componentId,
        }),
      enabled: !!componentId,
      select: res => res.data[0],
    });

  const dispatch = useTypedDispatch();
  const status = componentId
    ? componentDataList?.project_status
    : programmeDataList?.program_status;

  useEffect(() => {
    if (status) {
      dispatch(setStatus(status));
    }

    // reset status if component unmount
    return () => {
      dispatch(setStatus(''));
    };
  }, [status, dispatch]);
  return (
    <>
      {programmeDataIsLoading || (componentId && componentDataIsLoading) ? (
        <Skeleton className="naxatw-h-[4rem] naxatw-w-full" />
      ) : (
        <TopHeaderWrapper>
          <TopHeader
            title={
              !componentId
                ? programmeDataList?.program_name || "Programme's Name"
                : componentDataList?.project_name || "Projects's Name"
            }
            code={
              !componentId
                ? programmeDataList?.program_code || programmeId
                : componentDataList?.project_code || componentId
            }
            status={status}
          />
        </TopHeaderWrapper>
      )}
    </>
  );
};

export default ProgrammeDetailsHeader;
