import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Sectors from '@Components/ProvincialProfile/ChartSection/Sectors';
import { deletePdfSection } from '@Store/actions/pdfExport';
import { useDispatch } from 'react-redux';

const PdfSectorChart = () => {
  const dispatch = useDispatch();
  return (
    <ExportRoundedContainer className="province-sector-chart naxatw-relative">
      <ExportHeader
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: 'provincialProfile',
              section: 'sectorChart',
            }),
          )
        }
        className="!naxatw-right-[5px] !naxatw-top-[5px]"
      />
      <Sectors isExport />
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(PdfSectorChart);
