import { useState } from 'react';
import ToolTip from '@Components/RadixComponents/ToolTip';
import FullScreen from '@Components/common/FullScreen';

interface ChartCardHeaderProps {
  title: string;
  needFullScreen?: boolean;
  iconClick?: any;
  headerContent?: React.ReactNode;
  content?: React.ReactNode;
  subHeaderContent?: React.ReactNode;
  exportName?: string;
  isExportable?: boolean;
  isPdfExport?: boolean;
  className?: string;
}

const ChartCardHeader = ({
  title,
  needFullScreen = true,
  iconClick,
  content,
  headerContent,
  subHeaderContent,
  exportName,
  isExportable = true,
  isPdfExport,
  className,
}: ChartCardHeaderProps) => {
  const [fullScreen, setFullScreen] = useState(false);

  return (
    <div
      className={`header-container naxatw-flex naxatw-items-center naxatw-justify-between naxatw-gap-4 ${isPdfExport ? 'naxatw-p-2' : 'naxatw-px-[1.5rem] naxatw-py-[1.25rem]'} ${className} `}
    >
      <p
        className={` ${isPdfExport ? 'fs-sm-bold naxatw-text-primary-700' : 'naxatw-text-sm naxatw-font-bold naxatw-text-matt-100 md:naxatw-text-[1.125rem]'}`}
      >
        {title}
      </p>

      {!isPdfExport && (
        <div className="actions naxatw-flex naxatw-h-fit naxatw-items-center naxatw-gap-4">
          {subHeaderContent}
          <ToolTip
            name="open_in_full"
            className={`!naxatw-text-xl naxatw-text-primary-300 ${
              needFullScreen ? 'naxatw-block' : 'naxatw-hidden'
            }`}
            message="Full Screen"
            iconClick={() => setFullScreen(true)}
          />
          {!isPdfExport && isExportable && (
            <ToolTip
              name="download"
              className="naxatw-rounded-[50%] naxatw-p-1 naxatw-text-primary-300 hover:naxatw-bg-gray-100 group-hover:naxatw-text-secondary-500"
              message="Download"
              iconClick={iconClick}
            />
          )}
        </div>
      )}

      <FullScreen
        isShow={fullScreen}
        onToggle={() => setFullScreen(false)}
        headerContent={headerContent}
        downloadName={exportName}
        onDownload={iconClick}
        isDownloadable
      >
        {content}
      </FullScreen>
    </div>
  );
};

export default ChartCardHeader;
