import TextCountCard from '@Components/common/Card/TextCountCard';
import Icon from '@Components/common/Icon';
import { cn } from '@Utils/index';

const WorkingArea = ({
  className,
  districtCount,
  municipalityCount,
  provinceCount,
  isExport = false,
  labelClassName,
  textCountWrapperStyles,
  iconClassName,
}: {
  className?: string;
  districtCount: number;
  municipalityCount: number;
  isExport?: boolean;
  provinceCount?: number;
  labelClassName?: string;
  textCountWrapperStyles?: string;
  iconClassName?: string;
}) => {
  return (
    <div
      className={cn(
        'working-area naxatw-flex naxatw-h-full naxatw-items-center naxatw-gap-8 naxatw-rounded-xl naxatw-border naxatw-border-gray-200 naxatw-bg-white naxatw-p-4',
        className,
      )}
    >
      <div
        className={cn(
          'working-areas-inner naxatw-flex naxatw-items-center naxatw-gap-2',
          labelClassName,
        )}
      >
        <Icon
          name="place"
          className={`naxatw-rounded-full  naxatw-bg-[#F4F7FE]  naxatw-text-calm-teal ${isExport ? 'naxatw-p-1 !naxatw-text-[0.75rem]' : `${iconClassName}`}`}
        />
        <p
          className={`naxatw-text-center naxatw-text-primary-700 ${isExport ? 'fs-sm-semibold' : 'naxatw-text-[0.875rem] naxatw-font-semibold naxatw-leading-5 '} naxatw-whitespace-nowrap`}
        >
          Working Areas
        </p>
      </div>
      <div
        className={cn(
          'naxatw-flex naxatw-items-center naxatw-gap-8',
          textCountWrapperStyles,
        )}
      >
        <TextCountCard
          label="Province"
          count={provinceCount}
          isExport={isExport}
        />

        <TextCountCard
          label="District"
          count={districtCount}
          isExport={isExport}
        />
        <TextCountCard
          label="Municipality"
          count={municipalityCount}
          isExport={isExport}
        />
      </div>
    </div>
  );
};

export default WorkingArea;
