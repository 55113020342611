/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@Components/RadixComponents/Button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverArrow,
} from '@Components/RadixComponents/Popover';
import Icon from '@Components/common/Icon';
import { getReportFilterKeys } from '@Services/report';
import Searchbar from '@Components/common/SearchBar';
import { useParams, useSearchParams } from 'react-router-dom';
import isEmpty from '@Utils/isEmpty';
import Checkbox from '@Components/common/FormUI/CheckBox';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import CheckBoxSkeleton from '@Components/common/FormComponent/CheckBoxSkeleton';
import { useDispatch } from 'react-redux';
import { setFilterState, clearFilterState } from '@Store/actions/programme';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import { useTypedSelector } from '@Store/hooks';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import {
  filterKeysWithCommas,
  mappedFilterFieldsKeys,
  mappedFilterKeys,
  mappedFilterParmas,
  nationalLevel,
  singleFilterkeys,
} from '@Constants/FormConstants/reports';

const ProgramFilterPopOver = ({
  onClick,
  headerId,
}: {
  onClick: () => void;
  headerId: string;
}) => {
  const dispatch = useDispatch();
  const { provinceId } = useParams();
  const [searchFilterParams] = useSearchParams();
  const searchFilterCategory = searchFilterParams.get('filtercategory') || '';
  const isEnabled = searchFilterParams.get('filter') === 'true';
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState<any>('');
  const [searchText, handleSearchText] = useDebouncedInput({
    ms: 400,
    init: searchTerm,
    onChange: debouncedEvent => setSearchTerm(debouncedEvent.target.value),
  });
  const [selectedFilterData, setSelectedFilterData] = useState<
    (string | number)[]
  >([]);

  const filterFields = useTypedSelector(
    state => state.programme.report?.filterParams,
  );
  const statusFilterParams = useTypedSelector(state =>
    provinceId
      ? state.provincialProfile.filterBy
      : state.mainDashboard.filterParams.status,
  );

  const { data: listedData, isFetching: fetchingFilterListData } = useQuery({
    queryKey: [
      'get-filter-key-programme',
      searchFilterCategory,
      searchTerm,
      filterFields,
      statusFilterParams,
      provinceId,
    ],
    queryFn: () =>
      getReportFilterKeys({
        search: searchText.trim(),
        key: `${mappedFilterKeys[searchFilterCategory]}` || '',
        project_id:
          searchFilterCategory !== 'project'
            ? filterFields?.project
              ? filterFields.project.join(',')
              : ''
            : '',
        program_id:
          searchFilterCategory !== 'program'
            ? filterFields?.program
              ? filterFields?.program.join(',')
              : ''
            : '',
        first_tier_partner_id:
          searchFilterCategory !== 'first_tier_partner'
            ? filterFields?.first_tier_partner
              ? filterFields?.first_tier_partner.join(',')
              : ''
            : '',
        sector_id:
          searchFilterCategory !== 'sector'
            ? filterFields?.sector
              ? filterFields?.sector.join(',')
              : ''
            : '',
        province_id:
          provinceId ||
          (searchFilterCategory !== 'working_area'
            ? filterFields?.province
              ? filterFields?.province.join(',')
              : ''
            : ''),
        goal:
          searchFilterCategory !== 'campaign_goal'
            ? filterFields?.campaign_goal
              ? filterFields?.campaign_goal.join(',')
              : ''
            : '',
        budget:
          searchFilterCategory !== 'budget'
            ? filterFields?.budget
              ? filterFields?.budget.join(',')
              : ''
            : '',
        project_status: statusFilterParams,
      }),
    enabled: isEnabled && !!searchFilterCategory,
    select: res => {
      if (!res?.data) return [];
      const responseData = res?.data || [];
      const manipulatedData = responseData?.map((responseItem: any) => {
        if (singleFilterkeys?.includes(searchFilterCategory)) {
          return {
            id: responseItem,
            name: filterKeysWithCommas.includes(searchFilterCategory)
              ? responseItem.toLocaleString()
              : responseItem,
          };
        }

        return {
          id:
            searchFilterCategory === 'campaign_goal'
              ? responseItem?.project__project_campaign_goals__campaign_goals
              : responseItem[
                  `${mappedFilterFieldsKeys[searchFilterCategory]}id`
                ],
          name: responseItem[
            `${mappedFilterFieldsKeys[searchFilterCategory]}name`
          ],
        };
      });

      if (!provinceId && searchFilterCategory === 'working_area') {
        return [...manipulatedData, nationalLevel];
      }

      return manipulatedData;
    },
  });

  const handleFilterChange = (list: Record<string, any>) => {
    const selectedId = list.id;
    setSelectedFilterData(prev => {
      if (prev.includes(selectedId)) {
        return prev.filter(id => id !== selectedId);
      }
      return [...prev, selectedId];
    });
  };

  const handleSelectAll = () => {
    if (selectedFilterData?.length === listedData?.length) {
      setSelectedFilterData([]);
    } else {
      const allIds = listedData?.map((list: Record<string, any>) => list.id);
      setSelectedFilterData(allIds);
    }
  };

  useEffect(() => {
    if (filterFields) {
      const selectedData =
        filterFields[mappedFilterParmas[searchFilterCategory]] || [];
      const finalData = [...selectedData]?.filter(
        filterItem => filterItem !== '',
      );
      setSelectedFilterData(finalData);
    }
  }, [filterFields, searchFilterCategory]);

  const isFiltered = filterFields?.[mappedFilterParmas[headerId]];

  return (
    <div className="report-filter-wrapper" onClick={onClick}>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger className="naxatw-flex">
          <Icon
            name="filter_alt"
            type={isFiltered && 'material-icons'}
            className={`naxatw-text-xl naxatw-leading-5 naxatw-text-matt-100 ${isFiltered ? 'naxatw-text-secondary-500' : ''}`}
          />
        </PopoverTrigger>
        <PopoverContent
          className="popover-content-wrapper !naxatw-w-[20rem] !naxatw-bg-white  !naxatw-p-0"
          align="center"
          sideOffset={12}
        >
          <div className="popover-content-container naxatw-h-full naxatw-w-full">
            {/* listed data */}
            <div className="lists-wrapper">
              <div className="lists-wrapper-inner naxatw-h-full naxatw-w-full naxatw-px-5 !naxatw-pt-5 naxatw-pb-0">
                <div className="select-all-clear-btn naxatw-flex naxatw-items-center naxatw-justify-between naxatw-pb-3 naxatw-pl-1 naxatw-pt-2">
                  <Checkbox
                    label="Select all"
                    labelClassName="naxatw-text-matt-200"
                    onChange={handleSelectAll}
                    checked={
                      selectedFilterData?.length === listedData?.length &&
                      listedData?.length > 0
                    }
                  />
                  {(filterFields?.[searchFilterCategory] ||
                    !isEmpty(selectedFilterData)) && (
                    <span
                      className="naxatw-cursor-pointer naxatw-text-[0.875rem] naxatw-font-semibold naxatw-text-[#417EC9]"
                      onClick={() => {
                        if (filterFields) {
                          dispatch(clearFilterState({}));
                        }
                        setSelectedFilterData([]);
                      }}
                    >
                      Clear
                    </span>
                  )}
                </div>
                <Searchbar
                  value={searchText}
                  onChange={handleSearchText}
                  className="searchbar"
                  isSmall
                />
                {fetchingFilterListData ? (
                  <CheckBoxSkeleton numRows={3} />
                ) : isEmpty(listedData) ? (
                  <NoChartDataComponent className="naxatw-p-8" />
                ) : (
                  <div className="list-container scrollbar naxatw-my-1 naxatw-max-h-[15rem] naxatw-overflow-x-auto naxatw-pl-1">
                    <div className="lists-items">
                      {listedData?.map((listItem: Record<string, any>) => (
                        <Checkbox
                          label={listItem.name}
                          key={listItem.id}
                          mainWrapperStyles="naxatw-py-2 last:naxatw-pb-4"
                          labelClassName="naxatw-body-sm naxatw-truncate naxatw-text-matt-200"
                          onChange={() => handleFilterChange(listItem)}
                          name={listItem.name}
                          checked={selectedFilterData?.includes(listItem.id)}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="buttons naxatw-flex naxatw-items-center naxatw-justify-center naxatw-gap-3 naxatw-px-6 naxatw-py-3 naxatw-shadow-formshadow">
                <Button
                  variant="secondary"
                  onClick={() => setIsOpen(false)}
                  className="!naxatw-h-9 !naxatw-px-4 !naxatw-py-2"
                >
                  Cancel
                </Button>
                <Button
                  onClick={e => {
                    e.preventDefault();
                    if (isEmpty(listedData)) return;
                    if (provinceId) {
                      dispatch(
                        setFilterState({
                          [mappedFilterParmas[searchFilterCategory]]:
                            selectedFilterData,
                        }),
                      );
                    } else if (searchFilterCategory === 'working_area') {
                      //   if (selectedFilterData?.includes(nationalLevel?.name)) {
                      //     dispatch(setAreaFilterState(nationalLevel?.name));
                      //   }
                      dispatch(
                        setFilterState({
                          [mappedFilterParmas[searchFilterCategory]]:
                            selectedFilterData?.filter(
                              filterItem => filterItem !== nationalLevel?.name,
                            ),
                        }),
                      );
                    } else {
                      dispatch(
                        setFilterState({
                          [mappedFilterParmas[searchFilterCategory]]:
                            selectedFilterData,
                        }),
                      );
                    }

                    setIsOpen(false);
                  }}
                  className="!naxatw-h-9 !naxatw-px-4 !naxatw-py-2"
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
          <PopoverArrow />
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default hasErrorBoundary(ProgramFilterPopOver);
