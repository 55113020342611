import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  ModalContentsType,
  PromptDialogContentsType,
} from '@Constants/modalContents';

export type SearchTermTypes = {
  programmes?: string;
  partners?: string;
  projects?: string;
  'partner-reporting'?: string;
  'sectors/sector'?: string;
  'sectors/sub-sector'?: string;
  'markers/markers-categories'?: string;
  'markers/markers-values'?: string;
  'knowledge-repository'?: string;
  indicators?: string;
  'user-security/user-management'?: string;
  'user-security/role-management'?: string;
  'project-data'?: string;
  report?: string;
  'map-layers'?: string;
  'fun-trivia'?: string;
  'federal-info-update'?: string;
  contacts?: string;
  category?: string;
  'sub-category'?: string;
};

interface CommonState {
  palikaProfile: Record<string, any> | null;
  showModal: boolean;
  modalContent: ModalContentsType;
  showPromptDialog: boolean;
  promptDialogContent: PromptDialogContentsType;
  collapseSidebar: boolean;
  adminSearchState: SearchTermTypes;
  userProfile: Record<string, any>;
  userProfileError: null;
  userGroupPermission: string[];
  isGroupPermissionSuccess: boolean;
  userGroupPermissionError: null;
}

const initialState: CommonState = {
  palikaProfile: null,
  showModal: false,
  modalContent: null,
  showPromptDialog: false,
  promptDialogContent: null,
  collapseSidebar: false,
  adminSearchState: {
    programmes: '',
  },
  userProfile: {},
  userProfileError: null,
  userGroupPermission: [],
  isGroupPermissionSuccess: false,
  userGroupPermissionError: null,
};

const setCommonState: CaseReducer<
  CommonState,
  PayloadAction<Partial<CommonState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const setAdminSearchState: CaseReducer<
  CommonState,
  PayloadAction<Record<string, any>>
> = (state, action) => ({
  ...state,
  adminSearchState: {
    [action.payload.key]: action.payload.value,
  },
});

const toggleModal: CaseReducer<
  CommonState,
  PayloadAction<ModalContentsType | undefined>
> = (state, action) => ({
  ...state,
  showModal: !!action.payload,
  modalContent: action.payload || state.modalContent,
});

const setModalContent: CaseReducer<
  CommonState,
  PayloadAction<ModalContentsType>
> = (state, action) => ({
  ...state,
  modalContent: action.payload || null,
});

const togglePromptDialog: CaseReducer<
  CommonState,
  PayloadAction<PromptDialogContentsType | undefined>
> = (state, action) => ({
  ...state,
  showPromptDialog: !!action.payload || !state.showPromptDialog,
  promptDialogContent: action.payload || state.promptDialogContent,
});

const setPromptDialogContent: CaseReducer<
  CommonState,
  PayloadAction<PromptDialogContentsType>
> = (state, action) => ({
  ...state,
  promptDialogContent: action.payload || null,
});

const setCollapseSidebar: CaseReducer<CommonState, PayloadAction<boolean>> = (
  state,
  action,
) => ({
  ...state,
  collapseSidebar: action.payload,
});

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setCommonState,
    toggleModal,
    setModalContent,
    togglePromptDialog,
    setPromptDialogContent,
    setCollapseSidebar,
    setAdminSearchState,
  },
});

export { commonSlice };

export default commonSlice.reducer;
