import { ILegendItemProps } from '../types';

export default function LegendItem({ color, name }: ILegendItemProps) {
  return (
    <button
      type="button"
      className="naxatw-flex naxatw-items-center naxatw-justify-center naxatw-gap-x-2"
    >
      <span
        className="naxatw-h-3 naxatw-w-3 naxatw-rounded"
        style={{
          background: color,
        }}
      />
      <p className="naxatw-body-caption naxatw-text-gray-800">{name}</p>
    </button>
  );
}
