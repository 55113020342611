import WorkingArea from '@Components/ProvincialProfile/ExportPdf/PortfolioSummary/WorkingArea';
import Metric from '@Components/common/Card/Metric';
import { motion } from 'framer-motion';
import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportBtn from '@Components/common/CommonExport/ExportHeader/ExportBtn';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import SegmentCard from '@Components/ProgrammeDetails/Cards/Segment';
import {
  deleteIndividualList,
  deletePdfSection,
  resetPdfSection,
} from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { convertToCurrencySystem } from '@Utils/index';
import { useDispatch } from 'react-redux';
import StatusBar from '@Components/ProgrammeDetails/StatusBar';
import ProjectDetailCard from './ProjectDetailCard';

const PdfProjectSummary = ({
  projectCount,
  projectDetailsData,
}: {
  projectCount: Record<string, any>;
  projectDetailsData?: Record<string, any>;
}) => {
  const dispatch = useDispatch();

  const showResetButton = useTypedSelector(
    state => state.pdfExportSlice.projectDetails.summary.showResetButton,
  );

  const cardsData = useTypedSelector(
    state => state.pdfExportSlice.projectDetails.summary.datalists,
  );

  const {
    sector_count: sectorCount,
    marker_count: markerCount,
    allocated_budget: budget,
    district_count: districtCount,
    province_count: provinceCount,
    municipality_count: municipalityCount,
  } = projectCount || {};

  const isFirstRowActive =
    cardsData.includes('sector') ||
    cardsData.includes('marker') ||
    cardsData.includes('budget');

  const isSecondRowActive = cardsData.includes('working-areas');

  const isThirdRowActive = cardsData.includes('working-areas-timeline');

  const isProjecDetailsCardVisible = cardsData.includes('project-details');

  return (
    <ExportRoundedContainer className="">
      <ExportHeader
        headerTitle="Portfolio Summary"
        className="!naxatw-p-2"
        isStyleAbsolute={false}
        showResetButton={showResetButton}
        onHandleReset={() => {
          dispatch(
            resetPdfSection({
              page: 'projectDetails',
              section: 'summary',
            }),
          );
        }}
        onHandleDelete={() => {
          dispatch(
            deletePdfSection({
              page: 'projectDetails',
              section: 'summary',
            }),
          );
        }}
      />
      <div className="summary-content  naxatw-bg-primary-200 naxatw-p-2">
        <div className="naxatw-grid naxatw-grid-cols-1 naxatw-gap-y-2">
          {isFirstRowActive ? (
            <div className="top-charts naxatw-grid  naxatw-auto-cols-[1fr] naxatw-grid-flow-col naxatw-gap-2">
              {cardsData.includes('sector') && (
                <motion.div
                  className="naxatw-group naxatw-relative"
                  layout
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <Metric
                    label="Sectors"
                    iconName="category"
                    metricCount={sectorCount}
                    className="!naxatw-p-2"
                    isExport
                  />
                  <ExportBtn
                    size="sm"
                    onHandleClose={() =>
                      dispatch(
                        deleteIndividualList({
                          page: 'projectDetails',
                          section: 'summary',
                          key: 'datalists',
                          value: 'sector',
                        }),
                      )
                    }
                  />
                </motion.div>
              )}

              {cardsData.includes('marker') && (
                <motion.div
                  className="naxatw-group naxatw-relative"
                  layout
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <Metric
                    label="Markers"
                    iconName="donut_small"
                    metricCount={markerCount}
                    className="!naxatw-p-2"
                    isExport
                  />
                  <ExportBtn
                    size="sm"
                    onHandleClose={() =>
                      dispatch(
                        deleteIndividualList({
                          page: 'projectDetails',
                          section: 'summary',
                          key: 'datalists',
                          value: 'marker',
                        }),
                      )
                    }
                  />
                </motion.div>
              )}

              {cardsData.includes('budget') && (
                <motion.div
                  className="naxatw-group naxatw-relative"
                  layout
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <Metric
                    label="Total Allocated Budget"
                    iconName="currency_pound"
                    metricCount={convertToCurrencySystem(budget)}
                    className="!naxatw-p-2"
                    isExport
                  />
                  <ExportBtn
                    size="sm"
                    onHandleClose={() =>
                      dispatch(
                        deleteIndividualList({
                          page: 'projectDetails',
                          section: 'summary',
                          key: 'datalists',
                          value: 'budget',
                        }),
                      )
                    }
                  />
                </motion.div>
              )}
            </div>
          ) : null}

          <div className="naxatw-flex naxatw-gap-2">
            {isSecondRowActive ? (
              <div
                className={`workingareas naxatw-grid  naxatw-grid-flow-col naxatw-gap-2 ${isProjecDetailsCardVisible ? 'naxatw-basis-[32.5%]' : 'naxatw-basis-full'}`}
              >
                {cardsData.includes('working-areas') && (
                  <motion.div
                    className="naxatw-group naxatw-relative"
                    layout
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <WorkingArea
                      isExport
                      districtCount={districtCount}
                      municipalityCount={municipalityCount}
                      provinceCount={provinceCount}
                      labelClassName="!naxatw-border-b !naxatw-border-b-pdf-border !naxatw-pb-3 !naxatw-w-full !naxatw-justify-center"
                      className="!naxatw-col-span-2 !naxatw-h-full !naxatw-flex-col !naxatw-justify-center !naxatw-gap-0"
                      textCountWrapperStyles="!naxatw-pt-3"
                    />
                    <ExportBtn
                      size="sm"
                      onHandleClose={() =>
                        dispatch(
                          deleteIndividualList({
                            page: 'projectDetails',
                            section: 'summary',
                            key: 'datalists',
                            value: 'working-areas',
                          }),
                        )
                      }
                    />
                  </motion.div>
                )}
              </div>
            ) : null}

            {isProjecDetailsCardVisible ? (
              <div
                className={`${isSecondRowActive ? 'naxatw-basis-[67.5%]' : 'naxatw-basis-full'} project-deatils-card naxatw-h-full naxatw-gap-2`}
              >
                {cardsData.includes('project-details') && (
                  <motion.div
                    className="naxatw-group naxatw-relative naxatw-h-full"
                    layout
                    animate={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <ProjectDetailCard
                      projectDetailsData={projectDetailsData || {}}
                    />
                    <ExportBtn
                      size="sm"
                      onHandleClose={() =>
                        dispatch(
                          deleteIndividualList({
                            page: 'projectDetails',
                            section: 'summary',
                            key: 'datalists',
                            value: 'project-details',
                          }),
                        )
                      }
                    />
                  </motion.div>
                )}
              </div>
            ) : null}
          </div>

          {isThirdRowActive && cardsData.includes('working-areas-timeline') && (
            <motion.div
              className="naxatw-group naxatw-relative"
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <SegmentCard title="Timeline" iconName="timeline" isExport>
                <StatusBar isExport />
              </SegmentCard>
              <ExportBtn
                size="sm"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: 'projectDetails',
                      section: 'summary',
                      key: 'datalists',
                      value: 'working-areas-timeline',
                    }),
                  )
                }
              />
            </motion.div>
          )}
        </div>
      </div>
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(PdfProjectSummary);
