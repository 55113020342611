import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import TableLists from '@Components/common/CommonExport/TableLists';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { getProgrammeDetailsByProvince } from '@Services/provincialProfile';
import {
  deletePdfSection,
  resetPdfSection,
  setOriginalDataLists,
} from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import isEmpty from '@Utils/isEmpty';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const PdfProjectTable = () => {
  const { programmeId } = useParams();
  const dispatch = useDispatch();

  const showResetButton = useTypedSelector(
    state => state.pdfExportSlice.programmeDetails.projectTable.showResetButton,
  );

  const dataLists = useTypedSelector(
    state => state.pdfExportSlice.programmeDetails.projectTable.datalists,
  );

  const { data: projectDetails } = useQuery({
    queryKey: ['programme-details-table-data-pdf', programmeId],
    queryFn: async () =>
      getProgrammeDetailsByProvince({
        program: programmeId,
      }),
    select: res => {
      if (!res?.data) return [];
      const projects = res?.data[0]?.data;
      const manipulatedData = projects.map((pro: Record<string, any>) => ({
        id: pro.project_id,
        listOne: pro.project_name,
        listTwo: pro.first_tier_partner,
        listThree: pro.budget,
      }));

      return manipulatedData;
    },
    onSuccess: resposne => {
      if (isEmpty(resposne)) return;
      dispatch(
        setOriginalDataLists({
          page: 'programmeDetails',
          section: 'projectTable',
          value: resposne,
        }),
      );
    },
  });

  return (
    <ExportRoundedContainer
      className="pdf-project-table"
      style={{
        pageBreakInside: 'avoid',
      }}
    >
      <div className="pdf-programme-table-container">
        <ExportHeader
          className="!naxatw-border-b !naxatw-border-b-pdf-border  !naxatw-p-3"
          headerTitle="Projects Details"
          isStyleAbsolute={false}
          showResetButton={showResetButton}
          onHandleDelete={() =>
            dispatch(
              deletePdfSection({
                page: 'programmeDetails',
                section: 'projectTable',
              }),
            )
          }
          onHandleReset={() =>
            dispatch(
              resetPdfSection({
                page: 'programmeDetails',
                section: 'projectTable',
              }),
            )
          }
        />
        <TableLists
          dataLists={projectDetails || []}
          headerTitles={{
            firstColumn: 'Project',
            secondColumn: 'Partner',
            thirdColumn: 'Budget',
          }}
          isExport
          exportTitles={{
            page: 'programmeDetails',
            section: 'projectTable',
          }}
          exportLists={dataLists || []}
        />
      </div>
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(PdfProjectTable);
