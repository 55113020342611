import { FlexColumn } from '@Components/common/Layouts';
import Skeleton from '@Components/RadixComponents/Skeleton';

const UpdateDetailSk = () => {
  return (
    <FlexColumn gap={10} className="update-details-sk">
      <div className="naxatw-flex naxatw-items-center naxatw-gap-4">
        <Skeleton className="naxatw-h-5 naxatw-w-40" />
        <Skeleton className="naxatw-h-5 naxatw-w-40" />
        <Skeleton className="naxatw-h-5 naxatw-w-40" />
        <Skeleton className="naxatw-h-5 naxatw-w-40" />
      </div>
      <div>
        <Skeleton className="naxatw-mb-10 naxatw-h-12 naxatw-w-[22rem]" />

        <Skeleton className="naxatw-h-[400px] naxatw-w-full" />
      </div>
    </FlexColumn>
  );
};

export default UpdateDetailSk;
