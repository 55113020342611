import { api, authenticated, fastApi } from '.';

export const getBbox = (params: Record<string, any>) =>
  api.get('/federal-extent/', { params });

export const getFederalWiseCount = (params: Record<string, any>) =>
  authenticated(fastApi).get('/infographics/federal_wise_count/', { params });

export const getProvincialCountData = (params: Record<string, any>) =>
  authenticated(api).get('/overall_count/', { params });

export const getProgrammeDetails = (params: Record<string, any>) =>
  authenticated(fastApi).get('/dashboard/program-details-by-province/', {
    params,
  });

export const getProgrammeDetailsByProvince = (params: Record<string, any>) =>
  authenticated(fastApi).get('/dashboard/program-details-by-province/', {
    params,
  });

export const getProgrammeStakeholderSankeyData = (
  params: Record<string, any>,
) =>
  authenticated(fastApi).get('/infographics/program-stakeholder-sankey/', {
    params,
  });

export const getSectorDpSankeyData = (params: Record<string, any>) =>
  authenticated(fastApi).get('/infographics/sector-dp-sankey/', { params });

export const getProvincialPrioritiesHeaderData = (
  params: Record<string, any>,
) => api.get('program-by-province/', { params });

export const getProvincialPrioritiesGoalsData = (params: Record<string, any>) =>
  api.get('provincial-priorities/', { params });

export const getIndicatorsByProgrammeData = (params: Record<string, any>) =>
  authenticated(fastApi).get('/infographics/indicator-by-program/', { params });

export const getPartnersByBudgetUsingProvince = (params: Record<string, any>) =>
  authenticated(fastApi).get('/dashboard/top-partner-budget/', { params });

export const getSectorsByBudgetUsingProvince = (params: Record<string, any>) =>
  authenticated(fastApi).get('/infographics/top-sector-by-budget/', { params });

export const getProgrammeDataByProgrammeId = (params: Record<string, any>) =>
  authenticated(fastApi).get('/infographics/program-detail-page/', { params });

export const getProgrammeProgressDataByProgrammeId = (
  params: Record<string, any>,
) => authenticated(api).get('/program-progress/', { params });

export const getProgrammeProgressDataByComponentId = (
  params: Record<string, any>,
) => authenticated(api).get('/project-progress/', { params });

export const getSectorWiseCountData = (params: Record<string, any>) =>
  authenticated(fastApi).get('/infographics/project-wise-sector/', {
    params,
  });

export const getComponentDetailCount = (params: Record<string, any>) =>
  authenticated(fastApi).get('/infographics/project-detail-count/', {
    params,
  });

export const getCampaignGoals = (params: Record<string, any>) =>
  authenticated(api).get('/get-campaign-goals-list', { params });

export const getProvincialProfileReport = (params: Record<string, any>) =>
  authenticated(fastApi).get('/core/generate-province-report/', { params });

export const downloadTableData = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/dashboard/program-details-by-province/', {
    params,
    responseType: 'blob',
  });
};

export const getPartnersByProgrammeProject = (params: Record<string, any>) =>
  authenticated(fastApi).get('/infographics/top-partner-program-project/', {
    params,
  });

export const getStakeHoldersByProvinceId = (
  provinceID: number | string,
  params?: Record<string, any>,
) =>
  authenticated(fastApi).get(
    `/core/stakeholders-by-province/?province=${provinceID}`,
    { params },
  );

export const downloadStakeholdersTableData = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/core/stakeholders-by-province/', {
    params,
    responseType: 'blob',
  });
};

export const getUpdateByProvincialId = (params: Record<string, any>) =>
  authenticated(api).get('/federal-info-update/', {
    params,
  });

export const getProgrammeBudgets = (params: Record<string, any>) =>
  authenticated(fastApi).get(`/core/project-by-budget/`, {
    params,
  });

export const getProjectStatus = (params?: Record<string, any>) =>
  authenticated(fastApi).get('/core/project-status/', { params });

export const getAgreementModality = (params?: Record<string, any>) =>
  authenticated(api).get('/agreement-modality-by-project/', { params });

export const getStakeholdersByProgramProject = (params?: Record<string, any>) =>
  authenticated(fastApi).get('/core/stakeholders-by-program-project/', {
    params,
  });

export const getStakeholderChartDataByProvince = (
  provinceId: string | number,
  params: Record<string, any>,
) =>
  authenticated(fastApi).get(
    `/core/province-wise-stakholder-chart/?province=${provinceId}`,
    { params },
  );

export const getStakeholderChartDataByProgramProject = (
  params: Record<string, any>,
) =>
  authenticated(fastApi).get('/core/program-project-stakeholder-chart/', {
    params,
  });

export const getFederalMuncipalityDetailList = (params: Record<string, any>) =>
  authenticated(fastApi).get('/dashboard/federal-wise-table-details/', {
    params,
  });

export const getWorkingAreasByProgram = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/infographics/working-area-by-program/', {
    params,
  });
};

export const getWorkingAreaByProject = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/infographics/working-area-by-project/', {
    params,
  });
};

export const getProjectByProvince = (params: Record<string, any>) => {
  return authenticated(fastApi).get('/dashboard/federal_wise_count/', {
    params,
  });
};
