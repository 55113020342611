import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import EvidenceCards from './EvidenceCards';
import CategoryChart from './CategoryChart';

function EvidenceSection() {
  return (
    <div className="evidence-agenda-section">
      <EvidenceCards />
      <CategoryChart />
    </div>
  );
}
export default hasErrorBoundary(EvidenceSection);
