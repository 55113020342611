import { z } from 'zod';

export const UserManagementValidationSchema = z
  .object({
    id: z.number().optional().nullable(),
    first_name: z
      .string()
      .trim()
      .min(1, 'First Name is Required')
      .max(250, 'First Name cannot be more than 250 characters'),
    last_name: z
      .string()
      .trim()
      .min(1, 'Last Name is Required')
      .max(250, 'Last Name cannot be more than 250 characters'),
    email: z
      .string()
      .trim()
      .min(1, 'Email is Required')
      .email('Invalid email')
      .max(250, 'Email cannot be more than 250 characters'),

    username: z
      .string()
      .trim()
      .min(1, 'Username is Required')
      .max(250, 'Username cannot be more than 250 characters')
      .refine(value => !/\s/.test(value), {
        message: 'Username cannot contain spaces between characters',
      }),
    password: z
      .string()
      .min(8, 'Min 8 characters required')
      .max(250, 'Password cannot be more than 250 characters')
      .regex(/[a-zA-Z]/, 'At least one alphabet required')
      .regex(/[0-9]/, 'At least one Number required')
      .regex(
        /[!@#$%^&*(),.?":{}|<>]/,
        'At least one Special character required',
      ),
    confirm_password: z.string().trim().min(1, 'Confirm Password is Required'),
    program_project: z.any().optional().nullable(),
    role: z.number({ message: 'Role is Required' }),
  })
  .refine(
    data => {
      if (!data.id) {
        if ((data.password?.trim()?.length as number) < 6) {
          return false;
        }
      }
      return true;
    },
    {
      message: 'Password must be greater than 5 characters.',
      path: ['password'],
    },
  )
  .refine(
    data => {
      if (data.password === data.confirm_password) {
        return true;
      }
      return false;
    },
    {
      message: 'Password and confirm password does not match',
      path: ['confirm_password'],
    },
  );

export const UserEditManagementValidationSchema = z
  .object({
    id: z.number().optional().nullable(),
    first_name: z.string().trim().min(1, 'First Name is Required'),
    last_name: z.string().trim().min(1, 'Last Name is Required'),
    email: z.string().email('Invalid email'),
    username: z.string().trim().min(1, 'Username is Required'),
    password: z.string().optional().nullable(),
    confirm_password: z.string().optional().nullable(),
    program_project: z.any().optional().nullable(),
    role: z.number({ message: 'Role is Required' }),
  })
  .refine(
    data => {
      if (!data.id) {
        if ((data.password?.trim()?.length as number) < 6) {
          return false;
        }
      }
      return true;
    },
    {
      message: 'Password must be greater than 5 characters.',
      path: ['password'],
    },
  )
  .refine(
    data => {
      if (data.password === data.confirm_password) {
        return true;
      }
      return false;
    },
    {
      message: 'Password and confirm password does not match',
      path: ['confirm_password'],
    },
  );

export type UserManagementValidationSchemaProps = z.infer<
  typeof UserManagementValidationSchema & {
    [key: string]: any;
  }
>;
