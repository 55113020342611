/* eslint-disable import/prefer-default-export */
import { z } from 'zod';

const ErrCharacterOnly = 'Only alphabetic characters are allowed';

const characterOnlyRegex = /^[a-zA-Z]+$/;

export const SignupFormValidationSchema = z
  .object({
    first_name: z
      .string()
      .trim()
      .min(1, 'First name is required')
      .max(50, 'First name must not be greater than 50 characters')
      .regex(characterOnlyRegex, ErrCharacterOnly),
    last_name: z
      .string()
      .trim()
      .min(1, 'Last name is required')
      .max(50, 'Last name must not be greater than 50 characters')
      .regex(characterOnlyRegex, ErrCharacterOnly),
    email: z
      .string()
      .trim()
      .min(1, 'Enter your email address')
      .email('Invalid email address'),
    username: z
      .string()
      .trim()
      .min(5, { message: 'Min 5 characters' })
      .max(20, { message: 'Max 20 characters' }),

    // role: z.number().min(1, 'Role is Required'),
    password: z
      .string()
      .min(8, 'Min 8 characters Password is Required')
      .max(250, 'Password cannot be more than 250 characters')
      .refine(value => value.trim() !== '', {
        message: 'Password cannot contain spaces',
      })
      .refine(value => !/\s/.test(value), {
        message: 'Password cannot contain spaces between characters',
      })
      .refine(value => /[a-zA-Z]/.test(value), {
        message: 'At least one alphabet Required',
      })
      .refine(value => /[0-9]/.test(value), {
        message: 'At least one Number Required',
      })
      .refine(value => /[!@#$%^&*(),.?":{}|<>]/.test(value), {
        message: 'At least one Special character required',
      }),
    confirmPassword: z.string().min(1, 'Confirm Password Required'),
  })
  .refine(data => data.password === data.confirmPassword, {
    message: 'Passwords must match',
    path: ['confirmPassword'],
  });
