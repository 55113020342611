/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Tab = {
  selectedTab: Record<string, any>;
};
const initialState: Tab = {
  selectedTab: {
    sectors: 'Project',
    stakeHolders: 'Stakeholders Type',
    govermentStakeHolders: 'Project',
    budgetExpenditureType: 'Expenditure Type',
    stakeholderByProgramProject: 'All',
    projectCampaignGoal: 'Groups',
    agreementModality: 'Chart',
  },
};
export const setMainDashboardChartTabs = createSlice({
  name: 'provincialProfileSlice',
  initialState,
  reducers: {
    setSectorsSelectedTab: (state, action: PayloadAction<string>) => {
      state.selectedTab.sectors = action.payload;
    },

    setStakeHoldersSelectedTab: (state, action: PayloadAction<string>) => {
      state.selectedTab.stakeHolders = action.payload;
    },

    setGovernmentStakeHoldersSelectedTab: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.selectedTab.govermentStakeHolders = action.payload;
    },
    setBudgetExpenditureSelectedTab: (state, action: PayloadAction<string>) => {
      state.selectedTab.budgetExpenditureType = action.payload;
    },

    setStakeholderByProgramProjectSelectedTab: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.selectedTab.stakeholderByProgramProject = action.payload;
    },

    setAgreementModalitySelectedTab: (state, action: PayloadAction<string>) => {
      state.selectedTab.agreementModality = action.payload;
    },

    setProjectCampaignGoalSelectedTab: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.selectedTab.projectCampaignGoal = action.payload;
    },
  },
});

export default setMainDashboardChartTabs.reducer;
