import isEmpty from './isEmpty';

const getCheckboxCheckedIds = (
  values: Record<string, any>,
): {
  checkedChildIds: number[];
  checkedParentIds: number[];
} => {
  const allKeys = Object.keys(values);
  // const parentKeys = allKeys.filter(key => key.startsWith('parent_'));
  const parentKeys = allKeys.filter(
    key => key.startsWith('parent_') && !key.includes('parent_other'),
  );

  const childKeys = allKeys.filter(key => key.startsWith('child_'));

  const checkedChildKeys = childKeys.filter(key => values?.[key]);
  const checkedParentKeys = parentKeys.filter(key => values?.[key]);

  return {
    checkedChildIds: checkedChildKeys.map(key => Number(key.split('_')[1])),
    checkedParentIds: checkedParentKeys.map(key => Number(key.split('_')[1])),
  };
};

export const getCheckboxParsedValue = (
  checkedIds: number[],
  list: any,
  optionsKey: string = 'agendas',
): Record<string, any> => {
  const allChecked = list?.[optionsKey].length === checkedIds;
  if (allChecked) {
    return list?.[optionsKey].reduce(
      (accumulator: any, listItem: any) => {
        return { ...accumulator, [`child_${listItem.id}`]: true };
      },
      { [`parent_${list?.id}`]: true },
    );
  }
  if (!checkedIds.length) {
    return list?.[optionsKey].reduce(
      (accumulator: any, listItem: any) => {
        return { ...accumulator, [`child_${listItem.id}`]: false };
      },
      { [`parent_${list?.id}`]: false },
    );
  }
  return list?.[optionsKey].reduce(
    (accumulator: any, listItem: any) => {
      if (checkedIds.includes(listItem.id)) {
        return { ...accumulator, [`child_${listItem.id}`]: true };
      }
      return { ...accumulator, [`child_${listItem.id}`]: false };
    },
    { [`parent_${list?.id}`]: true },
  );
};

export default getCheckboxCheckedIds;

export const getCheckboxChildrenCheckedId = (
  values: Record<string, any>,
): number | string => {
  if (isEmpty(values)) return '';
  const childId = Object.entries(values)
    .filter(([key, value]) => key.startsWith('child') && value === true)
    .map(([key]) => parseInt(key.split('_')[1], 10))[0];

  return childId ?? '';
};

export const setChildrenCheckbox = (
  checkboxId: number,
): { [key: string]: boolean } => {
  if (!checkboxId) return {};
  return { [`child_${checkboxId}`]: true };
};

export const setSingleParentChildCheckbox = (
  childId: number,
  parentId: number,
): string | { [key: string]: boolean } => {
  if (!childId || !parentId) {
    return '';
  }

  return {
    [`parent_${parentId}`]: true,
    [`child_${childId}`]: true,
  };
};
