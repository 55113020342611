/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useNavigate, useParams } from 'react-router-dom';
import Detail from '@Components/Updates/UpdateDetails/Detail/Detail';
import { format } from 'date-fns';
import UpdateShareModal from '@Components/Updates/UpdateShareModal';
import { useState } from 'react';
import { FlexColumn } from '@Components/common/Layouts';
import { cn } from '@Utils/index';

const ListCard = ({
  federal_type: type,
  created_by__username: username,
  id,
  province__name: proName,
  date_created__date: createdDate,
  date,
  index,
}: Record<string, any>) => {
  const navigate = useNavigate();
  const { updateId } = useParams();
  const [showShareModal, setShowShareModal] = useState(false);

  const isActiveCard = updateId ? Number(updateId) === id : index === 0;

  return (
    <div
      className={cn(
        `naxatw-cursor-pointer naxatw-rounded naxatw-border-l-[4px] naxatw-bg-white naxatw-px-6 naxatw-py-4 naxatw-transition-colors`,
        isActiveCard
          ? 'naxatw-border-[#41588F]'
          : 'naxatw-border-transparent hover:!naxatw-border-gray-400 ',
      )}
      onClick={() => navigate(`/update/${id}`)}
    >
      <p className="naxatw-mb-3 naxatw-truncate naxatw-text-base naxatw-font-medium naxatw-leading-5 naxatw-text-[#0B2E62]">
        Update of {date ? format(new Date(date), 'MMMM yyyy') : null}
      </p>

      <FlexColumn className="detail-container naxatw-flex naxatw-justify-between naxatw-gap-1">
        <Detail
          icon="event"
          text={date ? format(new Date(createdDate), 'MMMM dd, yyyy') : '-'}
        />
        <Detail icon="person" text={username} />
        <Detail
          icon="place"
          text={type === 'Federal' ? type : proName || '-'}
        />
      </FlexColumn>
      {showShareModal && id === updateId && (
        <UpdateShareModal
          onHandleClose={() => {
            setShowShareModal(false);
            navigate(`/update/${updateId}`);
          }}
          updateId={id}
        />
      )}
    </div>
  );
};

export default ListCard;
