import type { ComponentProps } from 'react';
import StatusChips from '@Components/common/StatusChips';
import {
  getKnowConRepoDocuemntLink,
  getKnowEviRepoDocuemntLink,
  getKnowRepoDocuemntLink,
  getPublicKnowledgeRepositoryConData,
  getPublicKnowledgeRepositoryData,
  getPublicKnowledgeRepositoryEviData,
} from '@Services/publicKnowledgeRepository';

import pdfSvg from '@Assets/images/pdf.svg';
import docSvg from '@Assets/images/doc.svg';
import textSvg from '@Assets/images/text.svg';
import xlsSvg from '@Assets/images/sheets.svg';
import csvSvg from '@Assets/images/csv.svg';
import {
  shareKnowledgeConRepo,
  shareKnowledgeEviRepo,
} from '@Services/knowledgeRepository';

type Status = ComponentProps<typeof StatusChips>['status'];

export const mapKnowledgeRepositoryStatus: Record<string, Status> = {
  Ongoing: 'info',
  Completed: 'success',
  Draft: 'pending',
};

export const getPublicKnowledgeRepositoryDataByTab = (tab: string) => {
  if (tab.includes('contextual')) {
    return getPublicKnowledgeRepositoryConData;
  }
  if (tab.includes('evidence-agenda')) {
    return getPublicKnowledgeRepositoryEviData;
  }
  return getPublicKnowledgeRepositoryData;
};

export function checkIfEmpty(content: string) {
  return content.replace(/<[^>]*>/g, '').trim() === '';
}

export function getFileLogo(fileType: string) {
  switch (fileType) {
    case 'pdf':
      return pdfSvg;
    case 'doc':
      return docSvg;
    case 'docx':
      return docSvg;
    case 'txt':
      return textSvg;
    case 'xls':
      return xlsSvg;
    case 'xlsx':
      return xlsSvg;
    case 'csv':
      return csvSvg;
    default:
      return pdfSvg;
  }
}

export const getDocumentIdApiByPathname = (pathname: string) => {
  if (pathname?.includes('contextual')) {
    return getKnowConRepoDocuemntLink;
  }
  if (pathname?.includes('evidence-agenda')) {
    return getKnowEviRepoDocuemntLink;
  }
  return getKnowRepoDocuemntLink;
};

export const getDocumentShareApiByPathname = (pathname: string) => {
  if (pathname?.includes('contextual')) {
    return shareKnowledgeConRepo;
  }
  if (pathname?.includes('evidence-agenda')) {
    return shareKnowledgeEviRepo;
  }
  return getKnowRepoDocuemntLink;
};
