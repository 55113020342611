import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { getUpdateList } from '@Services/federalInfoUpdate';
import { useQuery } from '@tanstack/react-query';
import { useTypedSelector } from '@Store/hooks';
import { mapFederalType } from '@Constants/updates';
import { useRef } from 'react';
import isEmpty from '@Utils/isEmpty';
import { setUpdateState } from '@Store/actions/update';
import { useDispatch } from 'react-redux';
import UpdatesHeader from './UpdatesHeader/UpdatesHeader';
import UpdateDetails from './UpdateDetails/UpdateDetails';
import UpdateLists from './UpdateLists/UpdateLists';

const Updates = () => {
  const dispatch = useDispatch();
  const previousDataRef = useRef<Record<string, any>[]>([]);

  const provinceFilterTerm = useTypedSelector(
    state => state.UpdateSlice.filterTerms.provinceFilterTerm,
  );

  const fedTypeFilterTerm = useTypedSelector(
    state => state.UpdateSlice.filterTerms.fedTypeFilterTerm,
  );

  const updateTypeFilterTerm = useTypedSelector(
    state => state.UpdateSlice.filterTerms.updateTypeFilterTerm,
  );

  const startDate = useTypedSelector(
    state => state.UpdateSlice.filterTerms.start_date,
  );

  const endDate = useTypedSelector(
    state => state.UpdateSlice.filterTerms.end_date,
  );

  const combineDate = startDate && endDate;
  const federalType = mapFederalType[fedTypeFilterTerm];

  const { data: updateLists, isLoading: listLoading } = useQuery(
    [
      'get-update-list',
      provinceFilterTerm,
      federalType,
      updateTypeFilterTerm,
      combineDate,
    ],
    async () =>
      getUpdateList({
        province: provinceFilterTerm,
        federal_type: federalType,
        update_type: updateTypeFilterTerm,
        start_date: startDate,
        end_date: endDate,
      }),
    {
      refetchInterval: 600000,
      select: res => res?.data?.results || [],
      onSuccess: newData => {
        if (Array.isArray(newData) && !isEmpty(newData)) {
          const previousDataLength = previousDataRef.current
            ? previousDataRef.current.length
            : 0;

          previousDataRef.current = newData;

          if (newData.length !== previousDataLength) {
            dispatch(setUpdateState({ isNewUpdateFetched: true }));
          } else {
            dispatch(setUpdateState({ isNewUpdateFetched: false }));
          }
        }
      },
    },
  );

  return (
    <div className="updates-container naxatw-h-full naxatw-p-5">
      <UpdatesHeader />

      {/* ------> update body */}
      <div className="updates-body naxatw-grid naxatw-gap-4 naxatw-overflow-hidden lg:naxatw-h-[calc(100vh-160px)] lg:naxatw-grid-cols-[4fr_1fr]">
        <UpdateDetails isLoading={listLoading} updateList={updateLists?.[0]} />
        <UpdateLists updateLists={updateLists || []} isLoading={listLoading} />
      </div>
    </div>
  );
};

export default hasErrorBoundary(Updates);
