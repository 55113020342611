/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useInfiniteQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import useIntersectionObserver from '@Hooks/useIntersectionObserver';
import { useTypedSelector } from '@Store/hooks';
import Skeleton from '@Components/RadixComponents/Skeleton';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import isEmpty from '@Utils/isEmpty';
import { FlexColumn } from '@Components/common/Layouts';
import { cn } from '@Utils/index';
import { getPublicKnowledgeRepositoryData } from '@Services/publicKnowledgeRepository';
import KnowledgeCard from './KnowledgeCard';

const CardSection = () => {
  const [isIntersecting, _, viewRef] = useIntersectionObserver();

  const searchText = useTypedSelector(
    state => state.knowledgeRepository.publicSearchText,
  );

  const programParams = useTypedSelector(
    state => state.knowledgeRepository.programmaticFilterParams.program,
  );

  const projectParams = useTypedSelector(
    state => state.knowledgeRepository.programmaticFilterParams.project,
  );

  const sectorParams = useTypedSelector(
    state => state.knowledgeRepository.programmaticFilterParams.sector,
  );

  const startDateParams = useTypedSelector(
    state => state.knowledgeRepository.programmaticFilterParams.startDate,
  );

  const endDateParams = useTypedSelector(
    state => state.knowledgeRepository.programmaticFilterParams.endDate,
  );

  const fileTypeParams = useTypedSelector(
    state => state.knowledgeRepository.programmaticFilterParams.file_type,
  );

  const {
    data: cardData,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    isError,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [
      'programmatic-knowledge-repository-card-data',
      searchText,
      programParams,
      projectParams,
      sectorParams,
      startDateParams,
      endDateParams,
      fileTypeParams,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await getPublicKnowledgeRepositoryData({
        page: pageParam,
        page_size: 5,
        search: searchText,
        thematic_field: sectorParams?.join(','),
        program: programParams?.join(','),
        project: projectParams?.join(','),
        start_date: startDateParams,
        end_date: endDateParams,
        file_type: fileTypeParams?.join(','),
      });
      return res?.data;
    },
    getNextPageParam: lastPage => {
      return lastPage?.next_page ?? undefined;
    },
    select: response => {
      return response?.pages.reduce((acc, page) => {
        return [...acc, ...(page.results || [])];
      }, []);
    },
  });

  if (isError) {
    toast.error('Something went wrong');
  }

  useEffect(() => {
    if (isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [isIntersecting, fetchNextPage, hasNextPage]);

  const knowledgeRepoData: any = cardData || [];

  return (
    <FlexColumn className="scrollbar naxatw-relative naxatw-overflow-y-auto naxatw-rounded-xl">
      {isLoading ? (
        <div className="naxatw-flex naxatw-flex-col naxatw-gap-5 naxatw-bg-white naxatw-p-5">
          {Array.from({ length: 2 }).map(() => (
            <Skeleton key={uuid()} className="naxatw-h-[14rem] naxatw-w-full" />
          ))}
        </div>
      ) : (
        <div className="naxatw-h-full naxatw-bg-white">
          {isEmpty(knowledgeRepoData) ? (
            <div className="no-chart naxatw-flex naxatw-h-full naxatw-w-full naxatw-items-center naxatw-justify-center naxatw-py-5">
              <NoChartDataComponent messageStyles="!naxatw-text-base" />
            </div>
          ) : (
            <div className="repo-card-container">
              {knowledgeRepoData?.map(
                (repository: Record<string, any>, index: number) => (
                  <KnowledgeCard
                    key={repository?.id}
                    repository={repository}
                    className={cn(
                      'naxatw-bg-white',
                      index === knowledgeRepoData.length - 1
                        ? ''
                        : 'naxatw-border-b naxatw-border-b-[#D7D7D7]',
                    )}
                  />
                ),
              )}
              {/* view ref */}
              <div
                ref={viewRef}
                className="naxatw-pointer-events-none naxatw-bottom-0 naxatw-h-1 naxatw-w-full naxatw-bg-white"
              />
              {isFetchingNextPage && (
                <Skeleton className="naxatw-h-[14rem] naxatw-w-full" />
              )}
            </div>
          )}
        </div>
      )}
    </FlexColumn>
  );
};

export default hasErrorBoundary(CardSection);
