import CommonCampaignGoals from '@Components/common/CommonExport/CommonCampaignGoals';
import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import { getCampaignGoals } from '@Services/provincialProfile';
import {
  deletePdfSection,
  resetPdfSection,
  setOriginalDataLists,
} from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const PdfCampaignGoals = () => {
  const { programmeId, projectId } = useParams();
  const dispatch = useDispatch();

  const pageType = projectId ? 'projectDetails' : 'programmeDetails';

  const dataLists = useTypedSelector(state =>
    projectId
      ? state.pdfExportSlice.projectDetails.goals.datalists
      : state.pdfExportSlice.programmeDetails.goals.datalists,
  );

  const showResetButton = useTypedSelector(state =>
    projectId
      ? state.pdfExportSlice.projectDetails.goals.showResetButton
      : state.pdfExportSlice.programmeDetails.goals.showResetButton,
  );

  const { data } = useQuery({
    queryKey: ['campaignGoals-pdf', programmeId, projectId],
    queryFn: async () =>
      getCampaignGoals(
        projectId ? { project_id: projectId } : { program_id: programmeId },
      ),
    enabled: projectId ? !!projectId : !!programmeId,
    select: res => {
      if (!res?.data) return null;
      const goals = res.data;
      const finalData = goals.map((goal: Record<string, any>) => {
        const [label, ...rest] = goal.campaign_goals__name.split(': ');
        return {
          id: goal.campaign_goals__id,
          label,
          value: rest.join(': '),
        };
      });

      return finalData;
    },

    onSuccess: response => {
      if (!response) return null;
      dispatch(
        setOriginalDataLists({
          page: pageType,
          section: 'goals',
          value: response,
        }),
      );
      return null;
    },
  });

  return (
    <div className="pdf-goals-container naxatw-relative">
      <ExportHeader
        className="!naxatw-max-h-[34px]"
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: pageType,
              section: 'goals',
            }),
          )
        }
        onHandleReset={() => {
          dispatch(
            resetPdfSection({
              page: pageType,
              section: 'goals',
            }),
          );
        }}
        noTitleWithResetBtn={showResetButton}
        showResetButton={showResetButton}
      />
      <CommonCampaignGoals
        dataLists={data || []}
        iconName="extension"
        title="Campaign Goals"
        exportLists={dataLists || []}
        exportTitles={{
          page: pageType,
          section: 'goals',
        }}
      />
    </div>
  );
};

export default PdfCampaignGoals;
