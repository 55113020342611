import { FormFieldProps } from '@Constants/interface/FormInterface';
import { IMultiStepTitleKnowledgeRepo } from '@Constants/Types/FormTypes/knowledgeRepository';
import {
  deleteKnowledgeRepository,
  deleteknowRepoCon,
  deleteknowRepoEvi,
  getKnowRepoConTableData,
  getKnowRepoEviTableData,
  getKnowRepoProTableData,
} from '@Services/knowledgeRepository';

export type knowledgeRepoDocumentResponseType = {
  file: string;
  id: number;
  knowledge_repository: number;
};

export const knowledgeRepoFormTypeInformation = [
  {
    label: 'Programmatic Information',
    id: 1,
    path: '/data-bank/knowledge-repository',
    activePaths: [
      '/data-bank/knowledge-repository',
      '/data-bank/knowledge-repository/add',
      '/data-bank/knowledge-repository/edit',
    ],
  },
  {
    label: 'Contextual Information',
    id: 2,
    path: '/data-bank/knowledge-repository/contextual',
    activePaths: [
      '/data-bank/knowledge-repository/contextual',
      '/data-bank/knowledge-repository/contextual/add',
      '/data-bank/knowledge-repository/contextual/edit',
    ],
  },
  {
    label: 'Evidence Agenda',
    id: 3,
    path: '/data-bank/knowledge-repository/evidence-agenda',
    activePaths: [
      '/data-bank/knowledge-repository/evidence-agenda',
      '/data-bank/knowledge-repository/evidence-agenda/add',
      '/data-bank/knowledge-repository/evidence-agenda/edit',
    ],
  },
];

export const includeProvinces = [
  'Madhesh Province',
  'Lumbini Province',
  'Karnali Province',
];

// --------> changes from 15th Jan / 2025
export const knowledgeRepoMultiStepTitle: IMultiStepTitleKnowledgeRepo[] = [
  { id: 1, name: 'Basic Details', value: 'basicDetails' },
  {
    id: 2,
    name: 'Document Details',
    value: 'documentDetails',
  },
  { id: 3, name: 'Upload Documents', value: 'uploadDocuments' },
];

export function getActiveRepo(pathname: string) {
  if (pathname?.includes('contextual')) {
    return { is_contextual_indicator: true };
  }
  if (pathname?.includes('evidence-agenda')) {
    return {
      is_contextual_indicator: false,
      is_evidence_agenda: true,
    };
  }
  return { is_contextual_indicator: false, is_evidence_agenda: false };
}

export const excludeAgendaFormName = ['GESI', 'Geographic Focus'];

export function getActiveRepoTab(activeTab: string) {
  if (activeTab?.includes('contextual')) {
    return 'contextual';
  }
  if (activeTab?.includes('evidence-agenda')) {
    return 'evidence-agenda';
  }
  return '';
}

export function getActiveTableData(activeTab: string) {
  if (activeTab?.includes('contextual')) {
    return { is_contextual_indicator: true, is_evidence_agenda: false };
  }
  if (activeTab?.includes('evidence-agenda')) {
    return {
      is_contextual_indicator: false,
      is_evidence_agenda: true,
    };
  }
  return { is_contextual_indicator: false, is_evidence_agenda: false };
}

export const isPublicOptions = [
  {
    label: 'External',
    value: 'true',
  },
  {
    label: 'Internal',
    value: 'false',
  },
];

export function rediretKnowledgePath(activeTab: string, role: string) {
  if (activeTab?.includes('contextual') || role === 'PEF User') {
    return '/data-bank/knowledge-repository/contextual';
  }
  if (activeTab?.includes('evidence-agenda')) {
    return '/data-bank/knowledge-repository/evidence-agenda';
  }
  return '/data-bank/knowledge-repository';
}

export function navigateToKnowRepoAddPath(activeTab: string) {
  if (activeTab?.includes('contextual')) {
    return '/data-bank/knowledge-repository/contextual/add';
  }
  if (activeTab?.includes('evidence-agenda')) {
    return '/data-bank/knowledge-repository/evidence-agenda/add';
  }
  return '/data-bank/knowledge-repository/add';
}

export const showContextualRoles = ['PEF User'];

//! New Changes from 13th Feb 2025
export const getKnowRepoAddTextName = (pathname: string) => {
  if (pathname.includes('contextual')) {
    return 'Contextual Information';
  }
  if (pathname.includes('evidence-agenda')) {
    return 'Evidence Agenda';
  }
  return 'Programmatic Information';
};

export const programmaticKnowRepoFormFields: FormFieldProps[] = [
  {
    label: 'Title',
    inputType: 'text',
    type: 'input',
    placeholder: 'Title',
    id: 'title',
    name: 'title',
    isVisible: true,
    required: true,
  },
  {
    label: 'Type of Document',
    inputType: 'text',
    type: 'select',
    placeholder: 'Type of Document',
    id: 'file_type',
    name: 'file_type',
    required: true,
    choose: 'value',
  },
  {
    label: 'Associated Programme / Project',
    inputType: 'text',
    type: 'accordion-select',
    placeholder: 'Associated Programme / Project',
    id: 'associate_program_project',
    name: 'associate_program_project',
    subGroupName: 'subCategories',
    enableSingleParent: true,
  },
  {
    label: 'Others',
    inputType: 'text',
    type: 'input',
    placeholder: 'Other Programmes',
    id: 'other_program_project',
    name: 'other_program_project',
    required: true,
    isVisible: false,
  },
  {
    label: 'Published or Finalised Date',
    inputType: 'text',
    type: 'datePicker',
    placeholder: 'Label',
    id: 'published_date',
    name: 'published_date',
    disabledDays: '',
    isVisible: true,
    required: true,
  },
];

export const disableValidationForms = ['documentDetails', 'uploadDocuments'];

export const evidenceAgendaKnowRepoFormFields: FormFieldProps[] = [
  {
    label: 'Title',
    inputType: 'text',
    type: 'input',
    placeholder: 'Title',
    id: 'title',
    name: 'title',
    isVisible: true,
    required: true,
  },
  {
    label: 'Category / Sub Category',
    inputType: 'text',
    placeholder: 'Category',
    id: 'sub_category',
    name: 'sub_category',
    type: 'accordion-select',
    subGroupName: 'subCategories',
    parentName: 'Category',
    childName: 'Sub - Category',
    isVisible: true,
    selectSingleChild: true,
    multiple: false,
  },
  // {
  //   label: 'Sub - Category',
  //   inputType: 'text',
  //   type: 'select',
  //   placeholder: 'Sub - Category',
  //   id: 'sub_category',
  //   name: 'sub_category',
  //   isVisible: false,
  // },
  {
    label: 'Type of Document',
    inputType: 'text',
    type: 'select',
    placeholder: 'Type of Document',
    id: 'file_type',
    name: 'file_type',
    required: true,
    choose: 'value',
  },
  {
    label: 'Associated Programme / Project',
    inputType: 'text',
    type: 'accordion-select',
    placeholder: 'Associated Programme / Project',
    id: 'associate_program_project',
    name: 'associate_program_project',
    subGroupName: 'subCategories',
    enableSingleParent: true,
  },
  {
    label: 'Others',
    inputType: 'text',
    type: 'input',
    placeholder: 'Other Programmes',
    id: 'other_program_project',
    name: 'other_program_project',
    required: true,
    isVisible: false,
  },
  {
    label: 'Published or Finalised Date',
    inputType: 'text',
    type: 'datePicker',
    placeholder: 'Label',
    id: 'published_date',
    name: 'published_date',
    disabledDays: '',
    isVisible: true,
    required: true,
  },
];

export const getKnowledgeRepoTabByPermission = (allPermissions: string[]) => {
  const finalTab = [
    ...(allPermissions?.includes('Can view knowledge repository')
      ? [
          {
            label: 'Programmatic Information',
            id: 1,
            path: '/data-bank/knowledge-repository',
            activePaths: [
              '/data-bank/knowledge-repository',
              '/data-bank/knowledge-repository/add',
              '/data-bank/knowledge-repository/edit',
            ],
          },
        ]
      : []),
    ...(allPermissions?.includes('Can view contextual information')
      ? [
          {
            label: 'Contextual Information',
            id: 2,
            path: '/data-bank/knowledge-repository/contextual',
            activePaths: [
              '/data-bank/knowledge-repository/contextual',
              '/data-bank/knowledge-repository/contextual/add',
              '/data-bank/knowledge-repository/contextual/edit',
            ],
          },
        ]
      : []),
    ...(allPermissions?.includes('Can view evidence agenda')
      ? [
          {
            label: 'Evidence Agenda',
            id: 3,
            path: '/data-bank/knowledge-repository/evidence-agenda',
            activePaths: [
              '/data-bank/knowledge-repository/evidence-agenda',
              '/data-bank/knowledge-repository/evidence-agenda/add',
              '/data-bank/knowledge-repository/evidence-agenda/edit',
            ],
          },
        ]
      : []),
  ];

  return finalTab;
};

export const getPermissionByPathname = (pathname: string) => {
  if (pathname.includes('contextual')) {
    return 'Can add contextual information';
  }
  if (pathname.includes('evidence-agenda')) {
    return 'Can add evidence agenda';
  }
  return 'Can add knowledge repository';
};

export const getPermissionEditByPathname = (pathname: string) => {
  if (pathname.includes('contextual')) {
    return 'Can change contextual information';
  }
  if (pathname.includes('evidence-agenda')) {
    return 'Can change evidence agenda';
  }
  return 'Can change knowledge repository';
};

export const getPermissionDeleteByPathname = (pathname: string) => {
  if (pathname.includes('contextual')) {
    return 'Can delete contextual information';
  }
  if (pathname.includes('evidence-agenda')) {
    return 'Can delete evidence agenda';
  }
  return 'Can delete knowledge repository';
};

export const getPermissionViewByPathname = (pathname: string) => {
  if (pathname.includes('contextual')) {
    return 'Can view contextual information';
  }
  if (pathname.includes('evidence-agenda')) {
    return 'Can view evidence agenda';
  }
  return 'Can view knowledge repository';
};

export const contextualTab = [
  {
    label: 'Contextual Information',
    id: 1,
    path: '/data-bank/knowledge-repository/contextual',
    activePaths: [
      '/data-bank/knowledge-repository/contextual',
      '/data-bank/knowledge-repository/contextual/add',
      '/data-bank/knowledge-repository/contextual/edit',
    ],
  },
];

//! new changes after ashish  made changes in backend
export const getKnowRepoTableDataByPathname = (pathname: string) => {
  if (pathname?.includes('contextual')) {
    return getKnowRepoConTableData;
  }
  if (pathname?.includes('evidence-agenda')) {
    return getKnowRepoEviTableData;
  }
  return getKnowRepoProTableData;
};

export const getKnowRepoDeleteApiByPathname = (pathname: string) => {
  if (pathname?.includes('contextual')) {
    return deleteknowRepoCon;
  }
  if (pathname?.includes('evidence-agenda')) {
    return deleteknowRepoEvi;
  }
  return deleteKnowledgeRepository;
};

export const getKnowRepoQuerykeyByPathname = (pathname: string) => {
  if (pathname?.includes('contextual')) {
    return 'knowledge-repository-con-tabledata';
  }
  if (pathname?.includes('evidence-agenda')) {
    return 'knowledge-repository-evi-tabledata';
  }
  return 'knowledge-repository-tabledata';
};

export const getKnowRepoDelMessage = (pathname: string) => {
  if (pathname?.includes('contextual')) {
    return 'Contextual Information deleted sucessfully';
  }
  if (pathname?.includes('evidence-agenda')) {
    return 'Evidence Agenda deleted successfully';
  }
  return 'Programmatic Information deleted successfully';
};
