import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Icon from '@Components/common/Icon';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { cn, prefixWithZero } from '@Utils/index';

type MetricProps = {
  label: string;
  metricCount: number | string;
  iconName: string;
  className?: string;
  isLoading?: boolean;
  iconClassName?: string;
  isExport?: boolean;
  countStyles?: string;
  labelClassNames?: string;
};

function MetricSkeleton() {
  return (
    <FlexRow className="naxatw-items-center naxatw-gap-3 naxatw-rounded-xl naxatw-border naxatw-border-gray-100 naxatw-bg-gray-50 naxatw-p-3">
      <Skeleton className="naxatw-h-12 naxatw-w-12 naxatw-rounded-full" />
      <FlexColumn className="naxatw-flex-1 naxatw-gap-3">
        <Skeleton className="naxatw-h-4 naxatw-w-6/12" />
        <Skeleton className="naxatw-h-5 naxatw-w-8" />
      </FlexColumn>
    </FlexRow>
  );
}

function MetricCard({
  label,
  metricCount,
  iconName,
  className,
  iconClassName,
  isLoading,
  isExport = false,
  countStyles,
  labelClassNames = '',
}: MetricProps) {
  if (isLoading) return <MetricSkeleton />;

  return (
    <FlexRow
      className={cn(
        `naxatw-items-center naxatw-gap-3 naxatw-rounded-xl naxatw-border naxatw-border-gray-200 naxatw-bg-white naxatw-p-3 naxatw-text-primary-700 `,
        className,
      )}
    >
      <Icon
        name={iconName}
        className={cn(
          `icon naxatw-cursor-default naxatw-rounded-full naxatw-bg-primary-100  naxatw-text-calm-teal ${isExport ? 'naxatw-rounded-[50%] naxatw-bg-primary-100 naxatw-p-1 naxatw-text-base' : 'naxatw-p-3'}`,
          iconClassName,
        )}
      />
      <article className="naxatw-min-w-0 naxatw-flex-1 ">
        <p
          className={`${isExport ? 'fs-sm-semibold' : `naxatw-text-base naxatw-font-semibold  naxatw-text-primary-700 ${labelClassNames}`} naxatw-mb-1 naxatw-whitespace-nowrap`}
        >
          {label}
        </p>
        <h5 className={`${isExport ? 'fs-md-semibold' : ''} ${countStyles}`}>
          {typeof metricCount === 'number'
            ? prefixWithZero(Number(metricCount)) || 0
            : metricCount}
        </h5>
      </article>
    </FlexRow>
  );
}

export default hasErrorBoundary(MetricCard);
