import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SwitchTab from '@Components/common/FormUI/SwitchTab';
import { FlexRow } from '@Components/common/Layouts';
import { cn } from '@Utils/index';
import Map from './Map';
import Table from './Table';

interface MapSectionProps {
  lat: number;
  lon: number;
  zoom?: number;
  className?: string;
  isPdfExport?: boolean;
  screenshot?: boolean;
}

const viewAsOptions = [{ label: 'map' }, { label: 'table' }];

export default function MapSection({
  lat,
  lon,
  zoom,
  className,
  isPdfExport = false,
  screenshot,
}: MapSectionProps) {
  const [activeSwicthIcon, setActiveSwicthIcon] = useState('map');
  const [searchParams] = useSearchParams();

  const isMunicipalityDetail = !!searchParams.get('municipality');

  return (
    <div
      className={cn(
        // min-height is need for map render in smaller screen becuase it can't calculate height from it's closest sibling while in column layout
        'map-section naxatw-relative naxatw-h-full naxatw-max-h-[33.25rem] naxatw-min-h-[33.25rem] naxatw-w-full naxatw-overflow-hidden naxatw-rounded-xl naxatw-bg-white',
        className,
        // due to max-height,
        activeSwicthIcon === 'map' && 'naxatw-max-h-full',
      )}
    >
      <div className="naxatw-h-full naxatw-w-full naxatw-overflow-hidden naxatw-border-primary-200 naxatw-bg-white naxatw-shadow-xs">
        <FlexRow className="naxatw-pointer-events-none naxatw-absolute naxatw-top-[1.4rem] naxatw-z-[10] naxatw-w-full naxatw-items-center naxatw-justify-between naxatw-overflow-hidden naxatw-rounded-lg naxatw-px-5">
          <span
            className={cn(
              'naxatw-subtitle-bg naxatw-font-bold',
              activeSwicthIcon !== 'table' ? 'naxatw-invisible' : '',
            )}
          >
            Programme Details
          </span>
          {!isPdfExport && !isMunicipalityDetail && (
            <SwitchTab
              title="VIEW AS"
              options={viewAsOptions}
              activeLabel="map"
              defaultBg={false}
              defaultBehaviour={false}
              className="naxatw-pointer-events-auto naxatw-text-sm naxatw-leading-5 naxatw-text-[#484848]"
              titleClassName="naxatw-text-sm"
              onChange={label => {
                setActiveSwicthIcon(label);
              }}
            />
          )}
        </FlexRow>
        {activeSwicthIcon === 'map' ? (
          <Map
            lat={lat}
            lon={lon}
            zoom={zoom}
            isPdfExport={isPdfExport}
            screenshot={screenshot}
          />
        ) : (
          <Table />
        )}
      </div>
    </div>
  );
}
