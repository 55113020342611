import Skeleton from '@Components/RadixComponents/Skeleton';

const UpdateContentSk = () => {
  return (
    <div className="update-content-sk naxatw-p-4">
      <Skeleton className="naxatw-mb-4 naxatw-h-10 naxatw-w-[50%]" />
      <Skeleton className="naxatw-mb-4 naxatw-h-10 naxatw-w-full" />
      <Skeleton className="naxatw-max-h-full naxatw-w-full" />
    </div>
  );
};

export default UpdateContentSk;
