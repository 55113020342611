/* eslint-disable no-param-reassign */
import { useQuery } from '@tanstack/react-query';
import { prepareDataForDropdown } from '@Utils/index';
import { useFormContext, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import ErrorMessage from '@Components/common/ErrorMessage';
import getInputElement from '@Components/common/FormComponent/GetInputElement';
import { FormControl } from '@Components/common/FormUI';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn } from '@Components/common/Layouts';
import { programmaticKnowRepoFormFields } from '@Constants/FormConstants/knowledgeRepoConstants';
import { FormFieldProps } from '@Constants/interface/FormInterface';
import {
  getChoiceDropdownList,
  getProgrammeProjectsDropDown,
} from '@Services/knowledgeRepository';
import isEmpty from '@Utils/isEmpty';

const BasicDetails = () => {
  const {
    formState: { errors },
    register,
    getValues,
    control,
    setValue,
    watch,
  } = useFormContext();

  const formProps = {
    register,
    control,
    getValues,
    setValue,
    errors,
    watch,
  };

  const [programProject] = useWatch({
    control,
    name: ['associate_program_project'],
  });

  const { data: documentTypeDropDown } = useQuery({
    queryKey: ['knowledgerepo-filetype-options'],
    queryFn: () => getChoiceDropdownList('file_type'),

    select: data => {
      const response = data?.data;
      const updatedData = prepareDataForDropdown(response, 'name');
      return updatedData;
    },
    onError: () => toast.error('Failed to fetch file type options'),
  });

  const { data: programProjectDropDown } = useQuery({
    queryKey: ['get-project-programme-list'],
    queryFn: () => getProgrammeProjectsDropDown(),
    select: res => {
      if (!res?.data) return [];

      const programProjectDropDownOptions = res.data.map(
        (programData: Record<string, any>) => ({
          id: programData.program__id,
          name: programData.program__name,
          label: programData.program__name,
          subCategories: programData.subcategories.map(
            (subcategory: Record<string, any>) => ({
              id: subcategory.id,
              label: subcategory.label,
            }),
          ),
        }),
      );

      return [
        ...programProjectDropDownOptions,
        {
          id: 'other',
          name: 'Other',
          label: 'Other',
          subCategories: [],
        },
      ];
    },
  });

  const dropDownOptions: any = {
    associate_program_project: programProjectDropDown || [],
    file_type: documentTypeDropDown || [],
  };

  return (
    <FlexColumn className="naxatw-mb-5 naxatw-w-full naxatw-space-y-5">
      {programmaticKnowRepoFormFields?.map((field: FormFieldProps) => {
        const { id } = field;
        if (id === 'other_program_project') {
          if (!isEmpty(programProject)) {
            Object.entries(programProject).forEach(([key, value]) => {
              if (key === 'parent_other' && value === true) {
                field.isVisible = true;
              } else {
                field.isVisible = false;
              }
            });
          }
        }
        if (field.isVisible === false) return null;
        return (
          <FormControl className="naxatw-w-full naxatw-gap-[0.5rem]" key={id}>
            <InputLabel
              label={field.label}
              astric={field.required}
              id={field.id}
            />

            {getInputElement(
              {
                ...field,
                id: `${field.id}`,
              },
              formProps,
              dropDownOptions?.[id] || [],
            )}
            {errors[id] && (
              <ErrorMessage message={errors[id]?.message as string} />
            )}
          </FormControl>
        );
      })}
    </FlexColumn>
  );
};

export default hasErrorBoundary(BasicDetails);
