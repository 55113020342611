/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import html2pdf from 'html2pdf.js';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Icon from '@Components/common/Icon';
import { FlexRow } from '@Components/common/Layouts';
import { getUpdateByProvincialId } from '@Services/provincialProfile';
import isEmpty from '@Utils/isEmpty';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import { useEffect, useRef, useState } from 'react';
import ChartCardHeader from '@Components/ProvincialProfile/ChartSection/Header';
import UpdateContentSk from './UpdateContentSk';
import FullUpdateContent from './FullUpdateContent';

// type Update = {
//   id: number;
//   title: string;
//   federal_type: string;
//   summary: string;
//   update_type: string;
//   date: string;
//   created_by__username: string;
//   province__name: string;
// };

// const MAX_STR_LENG = 1290;

// function sliceString(description: string) {
//   if (description?.length <= MAX_STR_LENG) return description;
//   // Check if there's a space before or at the maxLength
//   const slicePoint = description?.lastIndexOf(' ', MAX_STR_LENG);
//   // If a space exists, slice at that point; otherwise, slice at maxLength
//   return slicePoint !== -1
//     ? description?.slice(0, slicePoint)?.trimEnd()
//     : description?.slice(0, MAX_STR_LENG)?.trimEnd();
// }

// function createSeeMoreBtn() {
//   const seeMoreBtn = document.createElement('button');
//   seeMoreBtn.setAttribute(
//     'class',
//     'naxatw-text-sm naxatw-font-semibold naxatw-text-primary-700 update-see-more-btn',
//   );
//   seeMoreBtn.innerText = ' ... See more';
//   return seeMoreBtn;
// }

const handleDownloadPdf = (element: HTMLElement) => {
  const updateSummaryContent = document.querySelector(
    '.update-summary-content',
  ) as HTMLElement;

  if (updateSummaryContent) {
    const style = document.createElement('style');
    document.head.appendChild(style);

    style.sheet?.insertRule(
      '.update-summary-content { overflow: visible !important; height: auto !important; }',
    );

    updateSummaryContent.style.height = 'auto';

    if (element) {
      const opt = {
        margin: 0.25,
        filename: 'Updates.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: {
          scale: 2,
          useCORS: true,
        },
        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'portrait',
        },
        pagebreak: {
          mode: ['css', 'avoid-all'],
          avoid: '.no-page-break',
        },
      };

      const images = element.querySelectorAll('img');
      let imagesLoaded = 0;

      images.forEach(img => {
        if (img.complete) {
          imagesLoaded++;
        } else {
          img.onload = () => {
            imagesLoaded++;
            if (imagesLoaded === images.length) {
              // Generate PDF only after images are fully loaded
              html2pdf()
                .from(element)
                .set(opt)
                .save()
                .then(() => {
                  updateSummaryContent.style.height = '32.3rem';
                  style.sheet?.insertRule(
                    '.update-summary-content { overflow: hidden !important; height: 32.3rem !important; }',
                  );
                  document.head.removeChild(style);
                });
            }
          };
        }
      });

      if (images.length === imagesLoaded) {
        html2pdf()
          .from(element)
          .set(opt)
          .save()
          .then(() => {
            updateSummaryContent.style.height = '32.3rem';
            style.sheet?.insertRule(
              '.update-summary-content { overflow: hidden !important; height: 32.3rem !important; }',
            );
            document.head.removeChild(style);
          });
      }
    }
  }
};

function Header({
  updateDate,
  createdBy,
  // title,
}: {
  updateDate: string | null;
  createdBy: string;
  // title: string;
}) {
  return (
    <>
      <FlexRow className="naxatw-mb-5 naxatw-gap-5">
        <FlexRow className="naxatw-items-center naxatw-gap-2">
          <Icon
            name="event"
            className="naxatw-cursor-default naxatw-text-matt-200"
          />
          <span className="naxatw-subtitle-sm naxatw-text-matt-100">
            {updateDate}
          </span>
        </FlexRow>
        <FlexRow className="naxatw-items-center naxatw-gap-2">
          <Icon
            name="person"
            className="material-symbols-outlined naxatw-cursor-default naxatw-text-matt-200"
            type="material-icons"
          />
          <span className="naxatw-subtitle-sm naxatw-text-matt-100">
            {createdBy || 'No Author'}
          </span>
        </FlexRow>
      </FlexRow>
      {/* <h2 className="naxatw-text-xl naxatw-font-bold naxatw-text-matt-200">
        {title}
      </h2> */}
    </>
  );
}

function UpdateContent() {
  const { provinceId } = useParams();
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const parentRef = useRef<HTMLDivElement | null>(null);
  const childRef = useRef<HTMLDivElement | null>(null);

  const { data: updateData, isLoading: isUpdateDataLoading } = useQuery({
    queryKey: ['get-update-data'],
    queryFn: () =>
      getUpdateByProvincialId({
        province: provinceId,
      }),
    select: res => {
      if (!res?.data?.results[0]) return [];
      return res.data.results[0];
    },
  });

  const updateDate =
    updateData?.date && format(new Date(updateData?.date), 'MMMM, yyyy');

  // function handleToggleModal() {
  //   setShowModal(prev => !prev);
  // }

  // dynamically add `See more` text at the last element of the container
  // useLayoutEffect(() => {
  //   if (contentRef?.current && data?.summary) {
  //     if (data?.summary?.length < MAX_STR_LENG) return;

  //     const isAlreadyExist = !!contentRef?.current?.querySelector(
  //       '.update-see-more-btn',
  //     );
  //     if (isAlreadyExist) return;

  //     const lastElement = contentRef?.current?.lastElementChild;
  //     const seeMoreBtn = createSeeMoreBtn();
  //     seeMoreBtn.onclick = handleToggleModal;

  //     if (lastElement) {
  //       lastElement.appendChild(seeMoreBtn);
  //     } else {
  //       contentRef?.current?.appendChild(seeMoreBtn);
  //     }
  //   }
  // }, [data?.summary]);

  // Function to check if child element is overflowing
  const checkOverflow = () => {
    if (parentRef.current && childRef.current) {
      const parentHeight = parentRef.current.clientHeight;
      const childHeight = childRef.current.scrollHeight;

      const isOverflowingx = childHeight > parentHeight;
      setIsOverflowing(isOverflowingx);
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [updateData?.summary]);

  return (
    <div className="updates-wrapper naxatw-relative naxatw-h-full naxatw-rounded-xl naxatw-border naxatw-border-primary-200 naxatw-bg-white naxatw-shadow-xs">
      {isUpdateDataLoading ? (
        <UpdateContentSk />
      ) : isEmpty(updateData as Object) ? (
        <NoChartDataComponent message="No Update Available" />
      ) : (
        <div className="update-content-wrapper">
          <div className="update-header naxatw-border-b naxatw-border-b-primary-200">
            <ChartCardHeader
              title={`Update of ${updateDate}`}
              needFullScreen={false}
              iconClick={() =>
                handleDownloadPdf(parentRef.current as HTMLElement)
              }
            />
          </div>
          <div
            className="update-summary-content scrollbar naxatw-overflow-hidden naxatw-p-5"
            ref={parentRef}
            style={{
              overflow: 'clip',
              height: '32.3rem',
            }}
          >
            <Header
              // title={updateData?.title!}
              updateDate={
                updateData?.date_created__date
                  ? format(
                      new Date(updateData?.date_created__date),
                      'MMMM dd, yyyy',
                    )
                  : '-'
              }
              createdBy={updateData?.created_by__username}
            />
            <div
              className="naxatw-subtitle-sm tiptap naxatw-leading-normal naxatw-text-matt-200"
              ref={childRef}
            >
              {updateData?.summary ? parse(updateData.summary) : null}
            </div>
          </div>
          <div className="naxatw-h-[40px]" />
          {isOverflowing ? (
            <button
              type="button"
              className="naxatw-absolute naxatw-bottom-[12px] naxatw-left-[20px] naxatw-text-[0.75rem] naxatw-font-bold naxatw-leading-[1.25rem] naxatw-text-[#41588F] hover:naxatw-text-[#41588F] hover:naxatw-underline"
              onClick={() => {
                setShowUpdateModal(true);
              }}
            >
              See More
            </button>
          ) : null}
        </div>
      )}
      {/* Full Update Content Modal */}
      {showUpdateModal ? (
        <FullUpdateContent
          title={`Update of ${updateDate}`}
          data={updateData || {}}
          onHandleClose={() => setShowUpdateModal(false)}
        />
      ) : null}
    </div>
  );
}

export default hasErrorBoundary(UpdateContent);
