/* eslint-disable camelcase */
import Skeleton from '@Components/RadixComponents/Skeleton';
import { getProvincialCountData } from '@Services/provincialProfile';
import { setMainDashboardStates } from '@Store/actions/mainDashboard';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import { convertToCurrencySystem } from '@Utils/index';
import { useDispatch } from 'react-redux';

const getTextByStatus = {
  Ongoing: 'Operating',
  Completed: 'Operated',
  '': 'Operated/Operating',
};

const InfoHeader = () => {
  const dispatch = useDispatch();
  const statusFilterParams = useTypedSelector(
    state =>
      state.mainDashboard.filterParams.status as keyof typeof getTextByStatus,
  );

  const { data: provinceData, isLoading } = useQuery({
    queryKey: ['dashboard-info-header', statusFilterParams],
    queryFn: async () =>
      getProvincialCountData({
        status: statusFilterParams,
      }),
    select: res => {
      if (!res?.data) return null;
      return res.data;
    },
    onSuccess: response => {
      if (!response) return;
      dispatch(
        setMainDashboardStates({
          overallCount: {
            ...response,
            isOverallCountSucess: true,
            isOverallCountLoading: false,
          },
        }),
      );
    },
    onError: () => {
      dispatch(
        setMainDashboardStates({
          overallCount: {
            isOverallCountSucess: false,
            isOverallCountLoading: false,
          },
        }),
      );
    },
  });

  const {
    program_count,
    project_count,
    sector_count,
    province_count,
    municipality_count,
    allocated_budget,
    district_count,
  } = provinceData || {};

  return (
    <div className="info-wrapper">
      {isLoading ? (
        <Skeleton className="naxatw-h-[4rem] naxatw-w-full" />
      ) : (
        <div className="information-container naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-2 naxatw-px-1 naxatw-py-[0.625rem] naxatw-text-[0.875rem] naxatw-font-medium naxatw-text-matt-200 sm:naxatw-gap-0">
          {/* --- province --- */}
          <p>
            <span className="naxatw-text-sm naxatw-font-semibold naxatw-text-[#0B2E62]">
              BEK {statusFilterParams === 'Ongoing' ? 'is' : ''}
            </span>{' '}
            {getTextByStatus[statusFilterParams]} in{' '}
            <span className="naxatw-text-sm naxatw-font-semibold naxatw-text-[#0B2E62]">
              {province_count || 0} Provinces,
            </span>{' '}
            <span className="naxatw-text-sm naxatw-font-semibold naxatw-text-[#0B2E62]">
              {district_count || 0} Districts
            </span>{' '}
            &{' '}
            <span className="naxatw-text-sm naxatw-font-semibold naxatw-text-[#0B2E62]">
              {' '}
              {municipality_count || 0} Palikas{' '}
            </span>
          </p>

          <span className="naxatw-mx-2 naxatw-hidden naxatw-h-[20px] naxatw-w-[1px] naxatw-bg-[#000] sm:naxatw-inline-block" />
          {/* --- programme,component & sector --- */}
          <p>
            <span className="naxatw-text-sm naxatw-font-semibold naxatw-text-[#0B2E62]">
              {program_count || 0} Programmes
            </span>{' '}
            with{' '}
            <span className="naxatw-text-sm naxatw-font-semibold naxatw-text-[#0B2E62]">
              {project_count || 0} Projects{' '}
            </span>
            across{' '}
            <span className="naxatw-text-sm naxatw-font-semibold naxatw-text-[#0B2E62]">
              {sector_count || 0} Sectors
            </span>
          </p>
          <span className="naxatw-mx-2 naxatw-hidden naxatw-h-[20px] naxatw-w-[1px] naxatw-bg-[#000] sm:naxatw-inline-block" />
          {/* --- budget --- */}
          <p className="naxatw-text-sm">
            Allocated budget: &#163;{' '}
            <span className="naxatw-text-sm naxatw-font-semibold naxatw-text-[#0B2E62]">
              {convertToCurrencySystem(allocated_budget) || 0}
            </span>
          </p>
        </div>
      )}
    </div>
  );
};

export default InfoHeader;
