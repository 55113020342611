import { Button } from '@Components/RadixComponents/Button';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { mappedStatus } from '@Constants/mainDashboard';
import StatusChips from '@Components/common/StatusChips';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ToolTipContainer from '@Components/common/ToolTipContainer';
import Icon from '@Components/common/Icon';

interface ITopHeaderProps {
  title: string;
  code?: string;
  status?: string;
}

const TopHeader = ({ title, status, code }: ITopHeaderProps) => {
  const { programmeId } = useParams();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('componentId');
  const navigate = useNavigate();

  return (
    <div className="programme-deatils-page-container naxatw-flex naxatw-items-center naxatw-justify-between naxatw-gap-6">
      <div className="naxatw-flex naxatw-items-center naxatw-gap-2 naxatw-text-xl naxatw-font-medium naxatw-text-royal-navy">
        <ToolTip
          name="arrow_back"
          message="Back to Programmes"
          className="naxatw-flex naxatw-items-center naxatw-text-xl naxatw-text-royal-navy"
          triggerClassName="!naxatw-size-[2rem] naxatw-rounded-full naxatw-transition-colors naxatw-ease-in hover:naxatw-bg-primary-200"
          iconClick={() =>
            navigate(
              projectId
                ? `/explore-by/programmes/${programmeId}`
                : '/explore-by/programmes/',
            )
          }
        />
        <p className="naxatw-text-sm naxatw-font-medium naxatw-text-royal-navy md:naxatw-text-base lg:naxatw-text-xl">
          {title}
          {code && <span> (CODE-{code})</span>}
        </p>
        {status && (
          <StatusChips status={mappedStatus[`${status}`]} label={status} />
        )}
      </div>
      <ToolTipContainer side="left" message="Download Portfolio Profile">
        <Button
          variant="secondary"
          color="primary"
          className="naxatw-text-sm"
          onClick={() =>
            navigate(
              projectId
                ? `/explore-by/programmes/${programmeId}/projectId/${projectId}/export`
                : `/explore-by/programmes/${programmeId}/export/`,
            )
          }
        >
          <Icon
            name="download"
            className="naxatw-flex naxatw-items-center naxatw-text-xl naxatw-text-secondary-500"
          />
          Profile
        </Button>
      </ToolTipContainer>
    </div>
  );
};

export default TopHeader;
