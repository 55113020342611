/* eslint-disable react/no-unstable-nested-components */
import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTypedSelector } from '@Store/hooks';
import ToolTip from '@Components/RadixComponents/ToolTip';
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import {
  getActiveRepoTab,
  // getActiveTableData,
  getKnowRepoDeleteApiByPathname,
  getKnowRepoDelMessage,
  getKnowRepoQuerykeyByPathname,
  getKnowRepoTableDataByPathname,
  getPermissionDeleteByPathname,
  getPermissionEditByPathname,
} from '@Constants/FormConstants/knowledgeRepoConstants';

const KnowledgeRepositoryTable = ({
  activeTab,
  contextualFormUser,
}: {
  activeTab: string;
  contextualFormUser: boolean;
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [iDToDelete, setIDToDelete] = useState<string>('');

  const userGroupPermission = useTypedSelector(
    state => state?.common?.userGroupPermission,
  );

  const searchTerm = useTypedSelector(
    state => state.databankforms.knowledgeRepository.searchTerm,
  );

  const activeRepoTab = getActiveRepoTab(activeTab);

  const {
    mutate: deleteKnowledgeRepositoryData,
    isError,
    error,
    isLoading,
  } = useMutation({
    mutationFn: () => {
      const deleteApi = getKnowRepoDeleteApiByPathname(pathname);
      return deleteApi(+iDToDelete);
    },
    onSuccess: () => {
      toast.success(getKnowRepoDelMessage(pathname));
      setOpenDeleteConfirmation(false);
      queryClient.invalidateQueries({
        queryKey: [getKnowRepoQuerykeyByPathname(pathname)],
      });
    },
  });

  const columns = [
    {
      header: 'DOCUMENT NAME',
      accessorKey: 'title',
      size: 90,
    },
    {
      header: 'DOCUMENT TYPE',
      accessorKey: 'file_type',
    },
    // {
    //   header: 'ASSOCIATED PROGRAMME',
    //   accessorKey: 'program',
    //   cell: ({ column }: any) => {
    //     // Check if the accessorKey is 'program'
    //     if (column.id === 'program') {
    //       return <p>ok</p>;
    //     }

    //     // Fallback: If it's not 'program', process the data (just for other cases, if needed)
    //     return <div>No Program Data</div>;
    //   },
    // },
    // ...(activeTab.includes('contextual')
    //   ? [
    //       {
    //         header: 'PROVINCE',
    //         accessorKey: 'province__name',
    //       },
    //     ]
    //   : []),
    {
      header: 'DATE',
      accessorKey: 'published_date',
    },
    {
      header: '',
      accessorKey: 'icon',
      cell: ({ row }: any) => {
        const cellId = row?.original?.id;

        return (
          <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-cursor-pointer naxatw-justify-end naxatw-gap-4">
            {userGroupPermission?.includes(
              getPermissionEditByPathname(pathname),
            ) ? (
              <ToolTip
                name="edit"
                message="Edit"
                className="naxatw-text-icon-md naxatw-text-matt-100 hover:naxatw-text-calm-teal"
                iconClick={() => {
                  if (activeRepoTab === '') {
                    navigate(`/data-bank/knowledge-repository/edit/${cellId}`);
                  } else {
                    navigate(
                      `/data-bank/knowledge-repository/${activeRepoTab}/edit/${cellId}`,
                    );
                  }
                }}
              />
            ) : null}

            {userGroupPermission?.includes(
              getPermissionDeleteByPathname(pathname),
            ) ? (
              <ToolTip
                name="delete"
                message="Delete"
                className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-text-red-500"
                iconClick={() => {
                  setOpenDeleteConfirmation(prev => !prev);
                  setIDToDelete(cellId);
                }}
              />
            ) : null}
          </FlexRow>
        );
      },
    },
  ];

  return (
    <div
      className={`naxatw-h-[calc(100%-11rem)] naxatw-w-full  ${contextualFormUser ? 'lg:naxatw-h-[calc(100%-6rem)]' : 'lg:naxatw-h-[calc(100%-4.7rem)]'}`}
    >
      <DataTable
        columns={columns || []}
        queryKey={getKnowRepoQuerykeyByPathname(pathname)}
        isPaginated
        queryFn={getKnowRepoTableDataByPathname(pathname)}
        initialState={{
          paginationState: {
            pageIndex: 0,
            pageSize: 25,
          },
        }}
        searchInput={searchTerm || ''}
        sortByKey
        onRowClick={() => {}}
      />
      {openDeleteConfirmation && (
        <Portal>
          <DeleteConfirmationOverlay
            onClose={() => setOpenDeleteConfirmation(false)}
            onDelete={() => deleteKnowledgeRepositoryData()}
            isError={isError}
            isLoading={isLoading}
            error={error}
          />
        </Portal>
      )}
    </div>
  );
};

export default hasErrorBoundary(KnowledgeRepositoryTable);
