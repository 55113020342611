import ToolTip from '@Components/RadixComponents/ToolTip';
import {
  getPermissionAddByTypeProps,
  getPermissionChangeByTypeProps,
} from '@Constants/FormConstants/goalsForm';
import { useTypedSelector } from '@Store/hooks';
import { ReactNode } from 'react';

interface ProvincialCommonCardProps {
  title: string;
  redirect: () => void;
  children: ReactNode;
  className?: string;
  type?: string | undefined;
}

const ProvincialCommonCard = ({
  type,
  title,
  redirect,
  children,
  className,
}: ProvincialCommonCardProps) => {
  const userGroupPermission = useTypedSelector(
    state => state?.common?.userGroupPermission,
  );

  return (
    <div
      className={`provincial-card naxatw-h-full naxatw-overflow-hidden naxatw-rounded-xl naxatw-bg-white naxatw-px-3 naxatw-pb-6 naxatw-pt-3 ${className}`}
    >
      <div className="card-header naxatw-mb-5 naxatw-flex naxatw-items-center naxatw-justify-between">
        <p className="naxatw-text-xl naxatw-font-medium naxatw-text-matt-200">
          {title}
        </p>
        {userGroupPermission?.includes(getPermissionAddByTypeProps(type)) ||
        userGroupPermission?.includes(getPermissionChangeByTypeProps(type)) ? (
          <ToolTip
            name="edit"
            message="Edit"
            className="naxatw-text-icon-md naxatw-flex naxatw-h-9 naxatw-w-9 !naxatw-cursor-pointer naxatw-items-center naxatw-justify-center naxatw-rounded-[50%] naxatw-text-matt-200 naxatw-transition naxatw-duration-200 hover:naxatw-bg-gray-100"
            iconClick={redirect || {}}
          />
        ) : null}
      </div>
      <div className="card-body scrollbar naxatw-h-[calc(100%-3rem)] naxatw-overflow-y-scroll">
        {children}
      </div>
    </div>
  );
};

export default ProvincialCommonCard;
