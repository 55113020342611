import ExploreByProgramme from '@Components/ExploreByProgramme';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';

const ExploreByProgrammePage = () => {
  return (
    <div className="naxatw-h-[calc(100vh-63px)] naxatw-overflow-x-hidden naxatw-bg-[#F4F7FE]">
      <ExploreByProgramme />
    </div>
  );
};

export default hasErrorBoundary(ExploreByProgrammePage);
