// import Metric from '@Components/common/Card/Metric';
// import { programmeStatusFills } from '@Components/common/Charts/constants';
// import CustomStackedChart from '@Components/common/Charts/CustomStackedChart';
// import { getProgrammeStatus } from '@Services/mainDashboard';
// import { useTypedSelector } from '@Store/hooks';
// import { useQuery } from '@tanstack/react-query';
// import { getWidthPercentageByValue } from '@Utils/index';

import CustomBarChart from '@Components/common/Charts/BarChart';
import { BarChartFills } from '@Components/common/Charts/constants';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import { cn } from '@Utils/index';
import isEmpty from '@Utils/isEmpty';

const ProgrammeChart = ({
  chartsData,
  isExport = false,
  className,
}: {
  chartsData: Record<string, any>[];
  className?: string;
  isExport?: boolean;
}) => {
  // const overallCount = useTypedSelector(
  //   state => state.mainDashboard.overallCount,
  // );

  // const { data: programmeStatusData, isLoading } = useQuery({
  //   queryKey: ['get-programme-status'],
  //   queryFn: () => getProgrammeStatus(),
  //   select: res => {
  //     if (!res?.data) return null;
  //     const programStatus = res.data;
  //     const finalProgramStatus = programStatus.map(
  //       (status: Record<string, any>, index: number) => ({
  //         id: status.status,
  //         name: status.status,
  //         color: programmeStatusFills[index],
  //         width: getWidthPercentageByValue(programStatus)[index],
  //         value: status.count,
  //       }),
  //     );

  //     return finalProgramStatus;
  //   },
  // });

  // const { program_count: programCount } = overallCount || {};

  return (
    <div
      className={cn(
        'programme-chart naxatw-rounded-xl naxatw-border naxatw-border-primary-200 naxatw-bg-white naxatw-px-4 naxatw-py-3 naxatw-shadow-xs',
        className,
      )}
    >
      {/* <Metric
        iconName="list_alt"
        label="Programmes"
        metricCount={programCount}
        className="!naxatw-rounded-[0px] !naxatw-border-none !naxatw-p-0 !naxatw-shadow-none"
      />

      <CustomStackedChart
        chartData={programmeStatusData || []}
        isLoading={isLoading}
      /> */}
      <div className="naxatw-mb-3">
        <p
          className={` ${isExport ? 'fs-sm-semibold' : 'naxatw-text-[0.875rem] naxatw-font-medium naxatw-text-primary-700'}`}
        >
          No. of Projects by Governance Sphere
        </p>
        <p
          className={`${isExport ? 'fs-xs-medium' : 'naxatw-text-[0.625rem] naxatw-text-matt-100'} `}
        >
          Same Project May Have Multiple Geographical Scope
        </p>
      </div>
      <div
        className={`${isExport ? 'naxatw-h-[calc(100%-64px)]' : 'naxatw-h-[calc(100%-3.75rem)]'}`}
      >
        {isEmpty(chartsData) ? (
          <NoChartDataComponent messageStyles="naxatw-text-[0.875rem]" />
        ) : (
          <div className="naxatw-flex naxatw-h-full naxatw-items-center">
            <CustomBarChart
              data={chartsData || []}
              height={isExport ? '100%' : '12rem'}
              wrapXAxis
              fills={BarChartFills}
              isExport={isExport}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgrammeChart;
