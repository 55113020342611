import dvsLogo from '@Assets/images/dvs-logo-new.svg';

const ExportLogo = () => {
  return (
    <div className="pdf-logo-container naxatw-py-2">
      <img src={dvsLogo} alt="dvs-logo" />
    </div>
  );
};

export default ExportLogo;
