/* eslint-disable react/no-danger */
import { FlexColumn } from '@Components/common/Layouts';
import { useQuery } from '@tanstack/react-query';
import { getUpdateById } from '@Services/federalInfoUpdate';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Icon from '@Components/common/Icon';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import Detail from './Detail/Detail';
import UpdateDetailSk from './UpdateDetailSk/UpdateDetailSk';
import UpdateShareModal from '../UpdateShareModal';

const UpdateDetails = ({
  updateList,
  isLoading,
}: {
  updateList: Record<string, any>;
  isLoading: boolean;
}) => {
  const { updateId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showShareModal, setShowShareModal] = useState(false);
  const { data: updateDetail, isLoading: updateLoading } = useQuery({
    queryKey: ['get-update-detail', updateId],
    queryFn: () => getUpdateById(updateId || updateList?.id),
    // enabled: !!updateId,
    select: res => {
      if (!res?.data?.[0]) return null;
      return res?.data?.[0];
    },
  });

  const {
    date_created__date: createdDate,
    created_by__username: username,
    federal_type: fedType,
    province__name: proName,
    title,
    summary,
    id,
  } = updateDetail || updateList || {};

  useEffect(() => {
    if (pathname.includes('share')) {
      setShowShareModal(true);
    }
  }, [pathname]);

  return (
    <div className="naxatw-update-details naxatw-rounded-lg naxatw-bg-white naxatw-p-5">
      {isLoading || updateLoading ? (
        <UpdateDetailSk />
      ) : (
        <>
          {!updateList ? (
            <NoChartDataComponent />
          ) : (
            <FlexColumn gap={10}>
              <div className="details-container naxatw-flex naxatw-items-center naxatw-gap-4">
                <div className="details-inner naxatw-flex naxatw-flex-1 naxatw-items-center naxatw-gap-8">
                  <Detail
                    icon="event"
                    text="Uploaded on"
                    highlightText={
                      createdDate
                        ? format(new Date(createdDate), 'MMMM dd, yyyy')
                        : '-'
                    }
                    textStyle="!naxatw-text-sm !naxatw-font-normal"
                  />
                  <Detail
                    icon="person"
                    text="By"
                    highlightText={username || '-'}
                    textStyle="!naxatw-text-sm !naxatw-font-normal"
                  />
                  <Detail
                    icon="place"
                    textStyle="!naxatw-text-sm !naxatw-font-normal"
                    highlightText={fedType === 'Federal' ? null : proName}
                    text={fedType === 'Federal' ? fedType : 'Province'}
                  />
                </div>
                <Icon
                  name="share"
                  className="!naxatw-text-xl naxatw-text-[#417EC9]"
                  onClick={() => {
                    setShowShareModal(true);
                    navigate(`/update/share/${id}`);
                  }}
                />
              </div>
              <div className="detail scrollbar naxatw-overflow-y-auto lg:naxatw-h-[calc(100vh-16.0625rem)]">
                {title && (
                  <h4 className="naxatw-mb-10 naxatw-font-medium naxatw-leading-[2.5rem] naxatw-text-[#667085]">
                    {title}
                  </h4>
                )}
                <div
                  className="tiptap naxatw-text-base naxatw-font-medium naxatw-tracking-[0.00625rem] naxatw-text-[#667085]"
                  dangerouslySetInnerHTML={{ __html: summary }}
                />
              </div>
            </FlexColumn>
          )}
          {showShareModal && (
            <UpdateShareModal
              onHandleClose={() => {
                setShowShareModal(false);
                navigate(`/update/${updateId}`);
              }}
              updateId={id}
            />
          )}
        </>
      )}
    </div>
  );
};

export default UpdateDetails;
