import { Input } from '@Components/common/FormUI';
import { cn } from '@Utils/index';
import { ComponentPropsWithRef } from 'react';

type Props = {
  pickerClassName?: string;
  // eslint-disable-next-line
} & ComponentPropsWithRef<'input'>;

const MAX_DATE = `${new Date().getFullYear() + 10}-01-31`;
export default function SingleDatePicker({
  className,
  pickerClassName,
  disabled,
  ...restProps
}: Props) {
  return (
    <Input
      className={cn(
        `naxatw-block naxatw-rounded naxatw-border naxatw-pr-1`,
        pickerClassName,
        disabled &&
          'naxatw-cursor-not-allowed naxatw-border-gray-200 naxatw-text-gray-400',
      )}
      disabled={disabled}
      max={MAX_DATE}
      {...restProps}
      // set 'required' value to be `false` to prevent browsers from showing native 'Please fill out this field' popup
      required={false}
      type="date"
    />
  );
}
