import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import DropDown from '@Components/common/DropDown';
import ErrorMessage from '@Components/common/ErrorMessage';
import { FormControl } from '@Components/common/FormUI';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn } from '@Components/common/Layouts';
import PortalTemplate from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { deleteGoalProgram, getProgrammeDropDown } from '@Services/goals';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';

const AssignGoals = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [programmeToDelete, setProgrammeToDelete] = useState<any>(null);
  const [goalToDelete, setGoalToDelete] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const goalsLists = getValues('goals_lists');
  const watchProgrammeList = watch('programme_list');

  // delete goal program
  const {
    mutateAsync: deleteGoalProgramData,
    isLoading: isdeleteGoalProgramLoading,
    isError,
  } = useMutation({
    mutationFn: (id: number) => deleteGoalProgram(id),
    onSuccess: () => {
      setProgrammeToDelete(null);
      queryClient.invalidateQueries({
        queryKey: ['get-goal-location-id'],
      });
    },
  });

  // get programme list
  const { data: programmeDropdown } = useQuery({
    queryKey: ['get-programme-list'],
    queryFn: () => getProgrammeDropDown(),
    select: (data: Record<string, any>) =>
      data?.data.map((options: Record<string, any>) => ({
        id: options?.id,
        label: options?.abbreviation,
        value: options?.id,
      })),
  });

  return (
    <FlexColumn gap={10} className="naxatw-w-full">
      {goalsLists?.map((item: Record<string, any>, index: number) => {
        return (
          <div className="goals-item" key={item.id}>
            <FormControl
              className="naxatw-w-full naxatw-gap-[0.5rem]"
              key="province"
            >
              <p className="naxatw-table-body !naxatw-leading-normal naxatw-text-matt-100">
                {item.name}
              </p>
              <InputLabel
                label="Programme"
                tooltipMessage="Programme"
                id="programme"
                astric
              />
              <Controller
                control={control}
                name={`programme_list.${index}`}
                render={({ field: { value, onChange } }) => {
                  return (
                    <DropDown
                      placeholder="Choose programme"
                      options={programmeDropdown || []}
                      value={
                        Array.isArray(value)
                          ? value.map(program => program?.programId || program)
                          : value
                      }
                      onChange={onChange}
                      multiple
                    />
                  );
                }}
              />
            </FormControl>
            {watchProgrammeList[index] !== undefined ? (
              <div>
                <div className="naxatw-mt-2 naxatw-rounded-lg naxatw-bg-primary-100 naxatw-p-3">
                  {watchProgrammeList[index]?.map(
                    (programmeItem: Record<string, any>) => {
                      return (
                        <div key={programmeItem.programId}>
                          <div className="naxatw-flex naxatw-h-9 naxatw-items-center naxatw-justify-between">
                            <p className="naxatw-body-caption">
                              {programmeItem?.individualId
                                ? programmeDropdown?.find(
                                    (list: Record<string, any>) =>
                                      list.id === programmeItem.programId,
                                  )?.label
                                : programmeDropdown?.find(
                                    (list: Record<string, any>) =>
                                      list.id === programmeItem,
                                  )?.label}
                            </p>
                            <ToolTip
                              name="close"
                              message="Delete"
                              className="naxatw-px-1 naxatw-py-2 naxatw-text-base naxatw-text-matt-200 naxatw-duration-200 hover:naxatw-text-red-500"
                              iconClick={() => {
                                if (programmeItem?.individualId) {
                                  setShowDeleteModal(true);
                                  setProgrammeToDelete(
                                    programmeItem.individualId,
                                  );
                                  return;
                                }
                                setShowDeleteModal(true);
                                setGoalToDelete(item.name);
                                setProgrammeToDelete(programmeItem);
                              }}
                            />
                          </div>

                          <div>
                            {showDeleteModal &&
                            !programmeItem.individualId &&
                            programmeToDelete === programmeItem &&
                            goalToDelete === item.name ? (
                              <PortalTemplate>
                                <DeleteConfirmationOverlay
                                  onClose={() => setShowDeleteModal(false)}
                                  onDelete={() => {
                                    const filteredList = watchProgrammeList[
                                      index
                                    ]?.filter(
                                      (list: Record<string, any>) =>
                                        list !== programmeItem,
                                    );
                                    setValue(
                                      `programme_list.${index}`,
                                      filteredList,
                                    );
                                    setShowDeleteModal(false);
                                  }}
                                />
                              </PortalTemplate>
                            ) : null}
                          </div>
                          {showDeleteModal &&
                          programmeItem.individualId &&
                          programmeToDelete === programmeItem.individualId ? (
                            <PortalTemplate>
                              <DeleteConfirmationOverlay
                                onClose={() => setShowDeleteModal(false)}
                                onDelete={() => {
                                  deleteGoalProgramData(
                                    programmeItem.individualId,
                                  );
                                }}
                                isError={isError}
                                isLoading={isdeleteGoalProgramLoading}
                              />
                            </PortalTemplate>
                          ) : null}
                        </div>
                      );
                    },
                  )}
                </div>
              </div>
            ) : null}
            <>
              {
                /* @ts-ignore */
                errors?.programme_list?.[index]?.message && (
                  <ErrorMessage
                    /* @ts-ignore */
                    message={errors?.programme_list?.[index]?.message}
                    className="naxatw-pl-1 naxatw-pt-3"
                  />
                )
              }
            </>
          </div>
        );
      })}
    </FlexColumn>
  );
};

export default hasErrorBoundary(AssignGoals);
