import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportBtn from '@Components/common/CommonExport/ExportHeader/ExportBtn';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import {
  deleteIndividualList,
  deletePdfSection,
  resetPdfSection,
} from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useDispatch } from 'react-redux';

const ReportContextual = ({
  municipalitySummary,
  palikaName,
}: {
  municipalitySummary?: Record<string, any>;
  palikaName?: string;
}) => {
  const dispatch = useDispatch();
  const showResetButton = useTypedSelector(
    state => state.pdfExportSlice.palikaDetails.contextual.showResetButton,
  );

  const cardsData = useTypedSelector(
    state => state.pdfExportSlice.palikaDetails.contextual.datalists,
  );

  const conInfo = [
    {
      id: 'household-size',
      label: 'Average Household Size',
      value: municipalitySummary?.avg_household || '-',
      overall: 4.37,
    },
    {
      id: 'population-density',
      label: 'Population Density',
      value: municipalitySummary?.population_density
        ? `${municipalitySummary?.population_density}km`
        : '-',
      overall: '216/km',
      type: 'km',
    },
    {
      id: 'male',
      label: 'Male',
      value: municipalitySummary?.male ? `${municipalitySummary?.male}%` : '-',
      overall: '48.98%',
    },
    {
      id: 'female',
      label: 'Female',
      value: municipalitySummary?.female
        ? `${municipalitySummary?.female}%`
        : '-',
      overall: '51.02%',
    },
    {
      id: 'absentee-abroad',
      label: 'Absentee Abroad',
      value: municipalitySummary?.absentee_abroad
        ? `${municipalitySummary?.absentee_abroad}%`
        : '-',
      overall: '23.4%',
    },
    {
      id: 'literacy_rate',
      label: 'Literacy Rate',
      value: municipalitySummary?.literacy_rate
        ? `${municipalitySummary?.literacy_rate}%`
        : '-',
      overall: '76.3%',
    },
    {
      id: 'female_headed_household',
      label: 'Female Headed Household',
      value: municipalitySummary?.female_household
        ? `${municipalitySummary?.female_household}%`
        : '-',
      overall: '31.55%',
    },
    {
      id: 'economically-active-population',
      label: 'Economically Active Population',
      value: municipalitySummary?.economically_active
        ? `${municipalitySummary?.economically_active}%`
        : '-',
      overall: '65.5%',
    },
    {
      id: 'disability',
      label: 'Disability',
      value: municipalitySummary?.disability
        ? `${municipalitySummary.disability}%`
        : '-',
      overall: '2.2%',
    },
  ];

  return (
    <div className="pdf-population-container naxatw-relative">
      <ExportHeader
        headerTitle="Contextual Information "
        showResetButton={showResetButton}
        isStyleAbsolute={false}
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: 'palikaDetails',
              section: 'contextual',
            }),
          )
        }
        onHandleReset={() =>
          dispatch(
            resetPdfSection({
              page: 'palikaDetails',
              section: 'contextual',
            }),
          )
        }
      />
      <ExportRoundedContainer className="population-data naxatw-relative  naxatw-p-3 ">
        <div className="bottom-content naxatw-relative">
          <div className="province-characteristics naxatw-grid naxatw-grid-cols-3 naxatw-border-b naxatw-border-b-grey-200 naxatw-pb-2">
            <p className="fs-sm-semibold naxatw-uppercase">characteristics</p>
            <p className="fs-sm-semibold naxatw-justify-self-center naxatw-uppercase">
              {palikaName}
            </p>
            <p className="fs-sm-semibold naxatw-justify-self-center naxatw-uppercase">
              Nepal
            </p>
          </div>
          <div className="">
            {conInfo.map(info => {
              if (cardsData.includes(info.id)) {
                return (
                  <div key={info.id} className="naxatw-group naxatw-relative ">
                    <div className="province-characteristics  naxatw-grid naxatw-grid-cols-3 naxatw-border-b naxatw-border-b-grey-200 naxatw-py-2">
                      <p className="fs-sm-medium">{info.label}</p>
                      <p className="fs-sm-medium naxatw-justify-self-center ">
                        {info.value} {info.type === 'km' ? <sup>2</sup> : null}
                      </p>
                      <p className="fs-sm-medium naxatw-justify-self-center ">
                        {info.overall}{' '}
                        {info.type === 'km' ? <sup>2</sup> : null}
                      </p>
                    </div>
                    <ExportBtn
                      size="sm"
                      className="naxatw-right-0 naxatw-top-[8px]"
                      onHandleClose={() =>
                        dispatch(
                          deleteIndividualList({
                            page: 'palikaDetails',
                            section: 'contextual',
                            key: 'datalists',
                            value: info.id,
                          }),
                        )
                      }
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </ExportRoundedContainer>
    </div>
  );
};

export default hasErrorBoundary(ReportContextual);
