import { ReactNode } from 'react';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import IconButton from '@Components/common/IconButton';
import Portal from '@Components/common/Layouts/Portal';
import { FlexRow } from '@Components/common/Layouts';
import Searchbar from '@Components/common/SearchBar';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import { cn } from '@Utils/index';

interface IModalContainerProps {
  children: ReactNode;
  title: string;
  onHandleClose: () => void;
  className?: string;
  searchTerm?: string;
  hasDownloadOption?: boolean;
  hasSearchOption?: boolean;
  onHandleDownload?: () => void;
  // eslint-disable-next-line no-unused-vars
  onHandleSearch?: (searchTerm: string) => void;
  breadCrumbClasName?: string;
  modalContentClassName?: string;
  headerClassName?: string;
}

const ModalContainer = ({
  children,
  title,
  onHandleClose,
  className,
  hasSearchOption,
  searchTerm = '',
  onHandleSearch,
  hasDownloadOption,
  onHandleDownload,
  breadCrumbClasName,
  modalContentClassName,
  headerClassName,
}: IModalContainerProps) => {
  const [debouncedSearchTerm, onHandleDebounceSearch] = useDebouncedInput({
    init: searchTerm,
    onChange: debouncedEve =>
      onHandleSearch && onHandleSearch(debouncedEve.target.value),
  });
  return (
    <Portal>
      <div
        className={cn(
          'form-modal-container',
          'naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-w-[90%] -naxatw-translate-x-1/2 naxatw-translate-y-[calc(-50%)] naxatw-overflow-hidden naxatw-rounded-lg naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 sm:naxatw-max-w-[34.75rem]',
          className,
        )}
      >
        {/* *************** Top Header ***************** */}
        <div
          className={cn(
            'header-container  naxatw-flex naxatw-items-center naxatw-justify-between naxatw-px-7 naxatw-py-5 naxatw-shadow-light',
            headerClassName,
          )}
        >
          <BreadCrumb heading={title} titleClassName={breadCrumbClasName} />
          <div className="naxatw-flex naxatw-items-center naxatw-gap-1">
            <FlexRow className="naxatw-flex-1 naxatw-items-center naxatw-gap-5">
              {hasSearchOption && (
                <Searchbar
                  value={debouncedSearchTerm}
                  onChange={onHandleDebounceSearch}
                  className="naxatw-min-w-40"
                />
              )}
              {hasDownloadOption && (
                <IconButton
                  name="download"
                  type="material-symbols-outlined"
                  className="naxatw-group naxatw-min-w-10 naxatw-overflow-hidden naxatw-rounded-[50%] naxatw-transition-colors naxatw-duration-200 naxatw-ease-in hover:naxatw-bg-gray-100"
                  iconClassName="naxatw-text-[#757575] !naxatw-text-[20px] group-hover:naxatw-text-secondary-500"
                  onClick={onHandleDownload}
                />
              )}
            </FlexRow>
            <IconButton
              name="close"
              size="sm"
              className="naxatw-group naxatw-overflow-hidden naxatw-rounded-[50%] naxatw-transition-colors naxatw-duration-200 naxatw-ease-in hover:naxatw-bg-gray-100"
              iconClassName="naxatw-text-[#757575] !naxatw-text-[20px] group-hover:naxatw-text-secondary-500"
              onClick={onHandleClose}
            />
          </div>
        </div>

        {/* *************** Modal Content ***************** */}
        <div className={cn('modal-content-wrapper', modalContentClassName)}>
          {children}
        </div>
      </div>
    </Portal>
  );
};

export default hasErrorBoundary(ModalContainer);
