import { useState } from 'react';
import { ColumnSort } from '@tanstack/react-table';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@Components/RadixComponents/Button';
import ToolTip from '@Components/RadixComponents/ToolTip';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import NestedDataTable from '@Components/ExploreByProgramme/NestedDataTable';
import useSortingConfig from '@Hooks/useSortingConfig';
import {
  mapSortbyParamValue,
  reverseSortbyParamValue,
} from '@Constants/programmeDetails';
import {
  getProgrammeDetails,
  downloadTableData,
} from '@Services/provincialProfile';
import { convertToCurrencySystem } from '@Utils/index';
import DefaultCard from '../Cards/Default';
import './styles.css';

const columns = [
  {
    header: 'PROJECT',
    accessorKey: 'project_name',
    className: 'naxatw-max-w-[12rem] naxatw-min-w-[12rem]',
  },
  {
    header: 'FIRST TIER PARTNER',
    accessorKey: 'first_tier_partner',
    className: 'naxatw-max-w-[12rem] naxatw-min-w-[12rem]',
  },
  {
    header: 'SECTOR',
    accessorKey: 'sector',
    className: 'naxatw-max-w-[12rem] naxatw-min-w-[12rem]',
  },
  {
    header: 'WORKING PROVINCE',
    accessorKey: 'working_province',
    className: 'naxatw-max-w-[12rem] naxatw-min-w-[12rem]',
  },
  {
    header: 'MARKER',
    accessorKey: 'marker',
    className: 'naxatw-max-w-[12rem] naxatw-min-w-[12rem]',
  },
  {
    header: 'Budget ALLOCATED (£)',
    accessorKey: 'budget',
    cell: ({ childRowData }: Record<string, any>) => {
      return childRowData?.budget
        ? convertToCurrencySystem(childRowData?.budget) || '-'
        : '-';
    },
    className: 'naxatw-max-w-[12rem] naxatw-min-w-[12rem]',
  },
  {
    header: '',
    accessorKey: 'icon',
    size: 35,
    cell: ({ parentRowData, childRowData }: Record<string, any>) => {
      return (
        <Link
          to={`/explore-by/programmes/${parentRowData?.program_id}/?componentId=${childRowData?.project_id}`}
          onClick={() => window.scrollTo(0, 0)}
        >
          <ToolTip
            name="open_in_new"
            className="naxatw-rounded-full naxatw-p-1 naxatw-text-xl naxatw-text-primary-600 naxatw-duration-300 hover:naxatw-bg-primary-200"
            message="Project Details"
            preventDefault={false}
          />
        </Link>
      );
    },
  },
];

const ComponentsTable = () => {
  const { programmeId } = useParams();
  const [sorting, setSorting] = useState<ColumnSort>({ id: '', desc: false });

  const { sortBy, sortOrderKey, sortDir } = useSortingConfig(
    sorting?.id,
    sorting.desc,
  );

  const { data: programmeDetails, isLoading: programmeDetailsIsLoading } =
    useQuery({
      queryKey: ['programme-details-table-data', sortBy, sortDir],
      queryFn: async () =>
        getProgrammeDetails({
          program: programmeId,
          [sortOrderKey]: sortDir,
          sort_by: sortBy,
        }),
      select: res => res.data,
    });

  // download table
  const { refetch: downloadTableDetails } = useQuery({
    queryKey: ['programme-detail-table'],
    queryFn: async () =>
      downloadTableData({
        program: programmeId,
        is_export: true,
      }),
    enabled: false,
    onSuccess: response => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(response.data);
      a.download = `Project Details.csv`;
      a.click();
    },
  });

  // tansform data into nested data table format
  const data =
    programmeDetails &&
    programmeDetails[0]?.data?.map((detail: Record<string, any>) => ({
      project_name: detail?.project_name,
      program_id: programmeDetails[0]?.program_id,
      data: [{ ...detail, project_name: null }],
    }));

  return (
    <DefaultCard className="naxatw-rounded-xl !naxatw-p-0">
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-px-6 naxatw-py-[1.4rem]">
        <h5 className="naxatw-font-medium naxatw-leading-7 naxatw-tracking-[-0.045rem] naxatw-text-[#0B2E62]">
          Project Details
        </h5>
        <Button
          variant="secondary"
          onClick={() => {
            downloadTableDetails();
          }}
        >
          <ToolTip
            name="download"
            message="Download Data"
            className="naxatw-flex naxatw-items-center naxatw-text-xl naxatw-text-secondary-500"
          />
          Download Table Data
        </Button>
      </div>
      <div className="scrollbar naxatw-w-full naxatw-overflow-auto naxatw-py-3">
        <NestedDataTable
          columns={columns}
          data={data}
          className="project-table"
          mainDataKey="project_name"
          nestingDataKey="data"
          isLoading={programmeDetailsIsLoading}
          sortingState={{
            ...sorting,
            id: reverseSortbyParamValue(sorting.id),
          }}
          onSorting={opt =>
            setSorting({ id: mapSortbyParamValue(opt?.id), desc: opt.desc })
          }
          showExternalLink={false}
          isProjectTable
        />
      </div>
    </DefaultCard>
  );
};

export default hasErrorBoundary(ComponentsTable);
