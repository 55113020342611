import { useDispatch } from 'react-redux';
import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Partners from '@Components/ProvincialProfile/ChartSection/Partners';
import { deletePdfSection } from '@Store/actions/pdfExport';

const PdfPartnerCHart = () => {
  const dispatch = useDispatch();
  return (
    <ExportRoundedContainer className="province-partner-chart naxatw-relative ">
      <ExportHeader
        className="!naxatw-right-[5px] !naxatw-top-[5px]"
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: 'provincialProfile',
              section: 'partnerChart',
            }),
          )
        }
      />
      <Partners isExport />
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(PdfPartnerCHart);
