/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
import {
  mapGovernmentStakeHolderColors,
  mapGovernmentStakeHoldersTitle,
  mapBudgetExpenditureColors,
  mapBudgetExpenditureTitle,
} from '@Constants/mainDashboard';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';

interface IPieChartLegendItem {
  data?: Record<string, any>;
  chartId?: string;
  showPound?: boolean;
  className?: string;
  onClick?: any;
  fill?: string[] | string;
}

function getChartColor(chartId: string | undefined) {
  switch (chartId) {
    case 'budget-expenditure-type':
      return mapBudgetExpenditureColors;
    case 'government-stakeholders':
      return mapGovernmentStakeHolderColors;
    default:
      return [];
  }
}

function getChartTitle(chartId: string | undefined) {
  if (!chartId) return [];
  switch (chartId) {
    case 'budget-expenditure-type':
      return mapBudgetExpenditureTitle;
    case 'government-stakeholders':
      return mapGovernmentStakeHoldersTitle;
    default:
      return [];
  }
}

const PieChartLegendItem = ({
  data,
  chartId,
  showPound,
  className,
  onClick,
  fill,
}: IPieChartLegendItem) => {
  const titleMapping: any = getChartTitle(chartId);
  const colorMapping: any = getChartColor(chartId);
  return (
    <div
      className={`naxatw-mb-2 naxatw-flex naxatw-items-center naxatw-gap-12 last:naxatw-mb-0 ${className}`}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <div className="naxatw-flex naxatw-flex-1 naxatw-items-center naxatw-gap-2">
        <div
          className="naxatw-h-[0.75rem] naxatw-w-[0.75rem] naxatw-rounded-[0.25rem]"
          style={{
            backgroundColor:
              colorMapping[
                chartId === 'government-stakeholders'
                  ? data?.stakeholder_type
                  : data?._id
              ] ||
              fill ||
              'gray',
          }}
        />
        <p className="naxatw-body-sm naxatw-mr-6 naxatw-text-grey-800">
          {titleMapping[
            chartId === 'government-stakeholders'
              ? data?.stakeholder_type
              : data?._id
          ] ||
            data?.name ||
            ''}
        </p>
      </div>
      <p className="naxatw-mb-4 naxatw-mr-8 naxatw-text-xs naxatw-font-medium naxatw-tracking-[-0.015rem] naxatw-text-grey-800 md:naxatw-mb-0">
        {showPound ? '£' : ''}&nbsp;&nbsp;
        {data?.value ? Number(data?.value).toLocaleString() : '-'}
      </p>
    </div>
  );
};

export default hasErrorBoundary(PieChartLegendItem);
