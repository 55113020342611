/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unstable-nested-components */
import ToolTip from '@Components/RadixComponents/ToolTip';
import { getReportList } from '@Services/report';
import { useTypedSelector } from '@Store/hooks';
import { useNavigate } from 'react-router-dom';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import ReportDataTable from '../ReportDataTable/ReportDataTable';

const ReportTable = () => {
  const searchTerm = useTypedSelector(
    state => state.common.adminSearchState?.report,
  );

  const filterFields = useTypedSelector(
    state => state.databankforms?.report?.filterParams,
  );

  const areaFilterField = useTypedSelector(
    state => state.databankforms?.report?.area,
  );

  const navigate = useNavigate();

  const columns = [
    { header: 'PROJECT', accessorKey: 'project' },
    { header: 'PROGRAMME', accessorKey: 'program' },
    { header: 'FIRST TIER PARTNER', accessorKey: 'first_tier_partner' },
    { header: 'SECTOR', accessorKey: 'sector' },
    {
      header: 'MARKER',
      accessorKey: 'marker',
      size: 400,
      cell: (info: any) => info.getValue(),
    },
    { header: 'STAKEHOLDER', accessorKey: 'stakeholder' },
    { header: 'WORKING AREA', accessorKey: 'working_area' },
    {
      header: 'Budget Allocated (£)',
      accessorKey: 'budget',
      cell: ({ row }: any) =>
        row?.original?.budget?.toLocaleString('en-us') || '-',
    },
    { header: 'START DATE', accessorKey: 'start_date' },
    { header: 'END DATE', accessorKey: 'end_date' },
  ];

  return (
    <div className="naxatw-h-[calc(100%-9.8rem)] sm:naxatw-h-[calc(100%-7rem)] lg:naxatw-h-[calc(100%-4.5rem)]">
      <ReportDataTable
        columns={[
          {
            header: 'Report',
            accessorKey: 'pdfIcon',
            cell: ({ row }) => {
              const projectId = row?.original?.project_id;
              const programmId = row?.original?.program_id;

              return (
                <div className="naxatw-mr-auto naxatw-py-4">
                  <ToolTip
                    name="picture_as_pdf"
                    type="material-icons"
                    className="naxatw-rounded-full naxatw-pr-4 naxatw-text-xl naxatw-text-primary-600 naxatw-duration-300 hover:naxatw-bg-primary-200"
                    message="Project Details"
                    preventDefault
                    iconClick={() => {
                      navigate(
                        `/explore-by/programmes/${programmId}/projectId/${projectId}/export`,
                        {
                          state: { from: 'reports-page' },
                        },
                      );
                    }}
                  />
                </div>
              );
            },
          },
          ...columns,
        ]}
        queryKey="programmes11"
        queryFn={getReportList}
        queryFnParams={{
          ...filterFields,
          area: areaFilterField,
          search: searchTerm,
        }}
        searchInput={searchTerm || ''}
      />
    </div>
  );
};

export default hasErrorBoundary(ReportTable);
