import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { changeUserPassword } from '@Services/userSecurity';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import { FormControl } from '@Components/common/FormUI';
import IconButton from '@Components/common/IconButton';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import { propTypeClose } from '@Constants/Types/FormTypes/userSecurity';
import PasswordInput from '@Components/common/FormUI/PasswordInput';

const initialState = {
  new_password: '',
  confirm_password: '',
};

const UserChangePasswordForm = ({ onClose }: propTypeClose) => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onChange',
    defaultValues: initialState,
  });

  const { mutate } = useMutation({
    mutationFn: changeUserPassword,
    onSuccess: () => {
      navigate('/data-bank/user-security/user-management');
      toast.success('Password changed successful.');
      onClose();
    },
    onError: () => {
      navigate('/data-bank/user-security/user-management');
      toast.error('Password changed Failed');
    },
  });

  const onSubmit = (data: Record<string, any>) =>
    mutate({ user_id: userId ? Number(userId) : '', ...data });

  return (
    <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-max-h-[90vh] naxatw-w-[90%] -naxatw-translate-x-1/2 naxatw-translate-y-[calc(-50%+31.5px)] naxatw-flex-col naxatw-overflow-hidden naxatw-rounded-lg naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 sm:naxatw-max-w-[34.75rem] ">
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-light">
        <BreadCrumb heading="Change Password" overlayStatus={() => onClose()} />
        <IconButton
          name="close"
          className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
          iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
          onClick={() => onClose()}
        />
      </div>
      <div className="change-password-form-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="naxatw-p-5">
            <FlexColumn className="naxatw-gap-5">
              {/* ------- New Password -------- */}
              <FormControl>
                <InputLabel label="New Password" astric />
                <PasswordInput
                  id="new_password"
                  placeholder="New Password"
                  className="naxatw-mt-2"
                  {...register('new_password', {
                    required: 'Password is Required',
                    validate: {
                      noWhitespace: value =>
                        value.trim() !== '' || 'Password cannot contain spaces',
                      noSpacesBetweenChars: value =>
                        !/\s/.test(value) ||
                        'Password cannot contain spaces between characters',
                      minLength: value =>
                        value.length >= 8 ||
                        'Min 8 characters Password is Required',
                      maxLength: value =>
                        value.length <= 250 ||
                        'Password cannot be more than 250 characters',
                      hasAlphabet: value =>
                        /[a-zA-Z]/.test(value) ||
                        'At least one alphabet Required',
                      hasNumber: value =>
                        /[0-9]/.test(value) || 'At least one Number Required',
                      hasSpecialChar: value =>
                        /[!@#$%^&*(),.?":{}|<>]/.test(value) ||
                        'At least one Special character required',
                    },
                  })}
                />
                {errors?.new_password && (
                  <span className="naxatyuw-text-sm naxatw-mt-2 naxatw-text-red-500">
                    {errors?.new_password?.message}
                  </span>
                )}
              </FormControl>
              {/* ------- Confirm New Password -------- */}
              <FormControl>
                <InputLabel label="Confirm Password" astric />
                <PasswordInput
                  id="confirm-password"
                  placeholder="Confirm Password"
                  className="naxatw-mt-2"
                  {...register('confirm_password', {
                    required: 'Confirm Password is Required',
                    validate: {
                      notMatched: value => {
                        const password = watch('new_password');
                        return value === password || 'Password do not match.';
                      },
                    },
                  })}
                />
                {errors?.confirm_password && (
                  <span className="naxatw-mt-2 naxatw-text-sm naxatw-text-red-500">
                    {errors?.confirm_password?.message}
                  </span>
                )}
              </FormControl>
            </FlexColumn>
          </div>
          <div className="naxatw-flex naxatw-justify-center naxatw-py-5 naxatw-shadow-formshadow">
            <Button
              size="normal"
              variant="primary"
              className="naxatw-px-4 naxatw-py-2"
              type="submit"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserChangePasswordForm;
