import { cn } from '@Utils/index';

const CardInfo = ({
  label,
  value,
  labelClassNames,
}: {
  label: string;
  value: string;
  labelClassNames?: string;
}) => {
  return (
    <div className="card-info">
      <p
        className={cn(
          `fs-sm-bold naxatw-mb-1 naxatw-uppercase`,
          labelClassNames,
        )}
      >
        {label}
      </p>
      <p className="fs-sm-medium">{value}</p>
    </div>
  );
};
export default CardInfo;
