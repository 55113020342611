import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { useNavigate } from 'react-router-dom';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import ProvincialCommonCard from '../ProvincialCommonCard';

interface ProvincialPrioritiesProps {
  goalsList: Record<string, any>;
  selectedLocationId: number;
  location: string;
}

const ProvincialPriorities = ({
  goalsList,
  selectedLocationId,
  location,
}: ProvincialPrioritiesProps) => {
  const navigate = useNavigate();

  const { goalslists: allPriorities } = goalsList;

  return (
    <ProvincialCommonCard
      title="Provincial Priorities"
      type="goal"
      redirect={() =>
        navigate(
          `/data-bank/provincial-profile/priorities/edit/${selectedLocationId}?location=${location}`,
        )
      }
    >
      {allPriorities ? (
        allPriorities?.map((list: Record<string, any>) => {
          return (
            <div
              className="naxatw-flex  naxatw-justify-between naxatw-gap-4 naxatw-border-b naxatw-border-solid naxatw-border-b-[#E3E4E3] naxatw-py-3 first:naxatw-pt-0 last:naxatw-border-b-0 last:naxatw-pb-0"
              key={list.id}
            >
              <p className=" naxatw-max-w-[70%] naxatw-text-[0.875rem] naxatw-font-medium naxatw-text-matt-200">
                {list?.goal}
              </p>
              <div className="naxatw-flex">
                {list?.programs.map(
                  (program: Record<string, any>, index: number) => (
                    <p
                      className="naxatw-text- naxatw-text-[0.875rem] naxatw-font-medium naxatw-text-matt-200"
                      key={program.program__id}
                    >
                      {program.program__abbreviation}
                      {index < list.programs.length - 1 && ', '}
                    </p>
                  ),
                )}
              </div>
            </div>
          );
        })
      ) : (
        <NoChartDataComponent
          message="Provincial Priorities are not yet available."
          messageStyles="naxatw-text-matt-200 naxatw-text-base"
        />
      )}
    </ProvincialCommonCard>
  );
};

export default hasErrorBoundary(ProvincialPriorities);
