/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { checkExceed, parseFormattedNumber } from '@Utils/index';
import { z } from 'zod';

export enum ComponentFormTypeEnum {
  BasicInfo = 'basicInfo',
  EstimatedBudgetAllocation = 'estimatedBudgetAllocation',
  Sectors = 'sectors',
  Marker = 'markers',
  CampaignGoals = 'campaignGoals',
  Indicator = 'indicators',
}

export const ComponentBasicFormValidationSchema = z
  .object({
    program: z.number({ message: 'Programme is Required' }),
    first_tier_partner: z.number({ message: 'First Tier Partner is Required' }),
    abbreviation: z
      .string()
      .trim()
      .optional()
      .nullable()
      .refine(
        value =>
          value === undefined ||
          value === null ||
          value.trim() === '' ||
          !value.includes(' '),
        {
          message: 'Abbreviation cannot contain space',
        },
      ),
    name: z
      .string()
      .trim()
      .min(1, 'Name is Required')
      .min(4, 'Name should be atleast 4 characters'),
    code: z.string().trim().min(1, 'Code is Required'),
    aggrement_modality: z.number({ message: 'Agreement Modality is Required' }),
    start_date: z.string().trim().min(1, 'Start date is Required'),
    end_date: z.string().trim().min(1, 'End date is Required'),
  })
  .superRefine(({ start_date, end_date }, ctx) => {
    if (!start_date || !end_date) return;

    const startDate = new Date(start_date);
    const endDate = new Date(end_date);

    // 1. Check if start_date and end_date are the same
    if (startDate.getTime() === endDate.getTime()) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Start date and End date cannot be the same.',
        path: ['end_date'],
      });
    }

    // 2. Check if end_date is after start_date
    if (endDate < startDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'End date must be after start date.',
        path: ['end_date'],
      });
    }
  });
// .refine(
//   data => {
//     if (data.start_date && data.end_date) {
//       return data.start_date <= data.end_date;
//     }
//     return true; // No error if one or both dates are missing
//   },
//   {
//     message: 'End date must be after start date.',
//     path: ['end_date'],
//   },
// )
// .refine(
//   data => {
//     if (data.start_date && !data.end_date) {
//       return false;
//     }
//     return true; // No error if one or both dates are missing
//   },
//   {
//     message: 'End date is required.',
//     path: ['end_date'],
//   },
// )
// .refine(
//   data => {
//     if (data.end_date && !data.start_date) {
//       return false;
//     }
//     return true; // No error if one or both dates are missing
//   },
//   {
//     message: 'Start date is Required',
//     path: ['start_date'],
//   },
// );

export type ComponentBasicFormValidationSchemaProps = z.infer<
  typeof ComponentBasicFormValidationSchema & {
    [key: string]: any; // Adding an index signature
  }
>;

export const ComponentSectorFormValidationSchema = z.object({
  sector: z.record(z.any()).refine(
    data => {
      const values = Object.values(data);
      return values.includes(true);
    },
    { message: 'Sector is Required' },
  ),
});

export type ComponentSectorFormValidationSchemaProps = z.infer<
  typeof ComponentSectorFormValidationSchema & {
    [key: string]: any; // Adding an index signature
  }
>;

export const ComponentMarkerFormValidationSchema = z.object({
  marker: z.record(z.any()).refine(
    data => {
      const values = Object.values(data);
      return values.includes(true);
    },
    { message: 'Marker is Required' },
  ),
});

export type ComponentMarkerFormValidationSchemaProps = z.infer<
  typeof ComponentSectorFormValidationSchema & {
    [key: string]: any; // Adding an index signature
  }
>;

// estimated - budget - allocation - schema;
export const ComponentEstimatedBudgetAllocationFormValidationSchema = z.object({
  estimated_budget: z
    .object({
      approved_budget: z.union([
        z
          .string({ message: 'Budget is Required' })
          .trim()
          .min(1, 'Budget is Required')
          .transform(val =>
            val === '' ? undefined : Number(parseFormattedNumber(val)),
          ),
        z.number(),
      ]),
      donor: z.any().optional(),
      is_donor: z.any().optional(),
      on_budget_on_treasury_amount: z.any().optional(),
      on_budget_off_treasury_amount: z.any().optional(),
      off_budget_on_treasury_amount: z.any().optional(),
      off_budget_off_treasury_amount: z.any().optional(),
      on_budget_on_treasury_percentage: z.any().optional(),
      on_budget_off_treasury_percentage: z.any().optional(),
      off_budget_on_treasury_percentage: z.any().optional(),
      off_budget_off_treasury_percentage: z.any().optional(),
      rdel_budget: z.any().optional(),
      cdel_budget: z.any().optional(),
      rdel_icf_budget: z.any().optional(),
      cdel_icf_budget: z.any().optional(),
      rdel_percentage: z.any().optional(),
      cdel_percentage: z.any().optional(),
      rdel_icf_percentage: z.any().optional(),
      cdel_icf_percentage: z.any().optional(),
    })
    .superRefine((data, ctx) => {
      const {
        approved_budget: budget,
        on_budget_on_treasury_amount: onBudOnTreAmt,
        on_budget_off_treasury_amount: onBudOffTreAmt,
        off_budget_on_treasury_amount: offBudOnTreAmt,
        off_budget_off_treasury_amount: offBudOffTreAmt,
        on_budget_on_treasury_percentage: onBudOnTrePer,
        on_budget_off_treasury_percentage: onBudOffTrePer,
        off_budget_on_treasury_percentage: offBudOnTrePer,
        off_budget_off_treasury_percentage: offBudOffTrePer,
        rdel_budget: rdelBudget,
        cdel_budget: cdelBudget,
        rdel_icf_budget: rdelIcfBudget,
        cdel_icf_budget: cdelIcfBudget,
        rdel_percentage: rdelPercentage,
        cdel_percentage: cdelPercentage,
        rdel_icf_percentage: rdelIcfPercentage,
        cdel_icf_percentage: cdelIcfPercentage,
      } = data;
      const totalPercentage =
        onBudOnTrePer + onBudOffTrePer + offBudOnTrePer + offBudOffTrePer;

      const totalExpenditurePercentage =
        rdelPercentage + cdelPercentage + rdelIcfPercentage + cdelIcfPercentage;
      const checkExceedAmount = checkExceed(
        budget,
        parseFormattedNumber(onBudOnTreAmt),
        parseFormattedNumber(onBudOffTreAmt),
        parseFormattedNumber(offBudOnTreAmt),
        parseFormattedNumber(offBudOffTreAmt),
      );

      const checkExceedExp = checkExceed(
        budget,
        parseFormattedNumber(rdelBudget),
        parseFormattedNumber(cdelBudget),
        parseFormattedNumber(rdelIcfBudget),
        parseFormattedNumber(cdelIcfBudget),
      );

      // budget and treasury budget  validation
      if (onBudOnTreAmt && checkExceedAmount) {
        ctx.addIssue({
          path: ['on_budget_on_treasury_amount'],
          message: 'Budget Exceeded Total Approved Budget',
          code: z.ZodIssueCode.custom,
        });
      }

      if (onBudOffTreAmt && checkExceedAmount) {
        ctx.addIssue({
          path: ['on_budget_off_treasury_amount'],
          message: 'Budget Exceeded Total Approved Budget',
          code: z.ZodIssueCode.custom,
        });
      }

      if (offBudOnTreAmt && checkExceedAmount) {
        ctx.addIssue({
          path: ['off_budget_on_treasury_amount'],
          message: 'Budget Exceeded Total Approved Budget',
          code: z.ZodIssueCode.custom,
        });
      }
      if (offBudOffTreAmt && checkExceedAmount) {
        ctx.addIssue({
          path: ['off_budget_off_treasury_amount'],
          message: 'Budget Exceeded Total Approved Budget',
          code: z.ZodIssueCode.custom,
        });
      }

      // budget treasury percentage validation
      if (totalPercentage > 100) {
        ctx.addIssue({
          path: ['on_budget_on_treasury_percentage'],
          message: '100 Percentage is exceeded',
          code: z.ZodIssueCode.custom,
        });
      }

      if (totalPercentage > 100) {
        ctx.addIssue({
          path: ['on_budget_off_treasury_percentage'],
          message: '100 Percentage is exceeded',
          code: z.ZodIssueCode.custom,
        });
      }

      if (totalPercentage > 100) {
        ctx.addIssue({
          path: ['off_budget_on_treasury_percentage'],
          message: '100 Percentage is exceeded',
          code: z.ZodIssueCode.custom,
        });
      }

      if (totalPercentage > 100) {
        ctx.addIssue({
          path: ['off_budget_off_treasury_percentage'],
          message: '100 Percentage is exceeded',
          code: z.ZodIssueCode.custom,
        });
      }

      // expenditure budget validation
      if (rdelBudget && checkExceedExp) {
        ctx.addIssue({
          path: ['rdel_budget'],
          message: 'Budget Exceeded Total Approved Budget',
          code: z.ZodIssueCode.custom,
        });
      }
      if (cdelBudget && checkExceedExp) {
        ctx.addIssue({
          path: ['cdel_budget'],
          message: 'Budget Exceeded Total Approved Budget',
          code: z.ZodIssueCode.custom,
        });
      }
      if (rdelIcfBudget && checkExceedExp) {
        ctx.addIssue({
          path: ['rdel_icf_budget'],
          message: 'Budget Exceeded Total Approved Budget',
          code: z.ZodIssueCode.custom,
        });
      }

      if (cdelIcfBudget && checkExceedExp) {
        ctx.addIssue({
          path: ['cdel_icf_budget'],
          message: 'Budget Exceeded Total Approved Budget',
          code: z.ZodIssueCode.custom,
        });
      }

      // expenditure percentage validation
      if (totalExpenditurePercentage > 100) {
        ctx.addIssue({
          path: ['rdel_percentage'],
          message: '100 Percentage is exceeded',
          code: z.ZodIssueCode.custom,
        });
      }

      if (totalExpenditurePercentage > 100) {
        ctx.addIssue({
          path: ['cdel_percentage'],
          message: '100 Percentage is exceeded',
          code: z.ZodIssueCode.custom,
        });
      }

      if (totalExpenditurePercentage > 100) {
        ctx.addIssue({
          path: ['cdel_icf_percentage'],
          message: '100 Percentage is exceeded',
          code: z.ZodIssueCode.custom,
        });
      }

      if (totalExpenditurePercentage > 100) {
        ctx.addIssue({
          path: ['rdel_icf_percentage'],
          message: '100 Percentage is exceeded',
          code: z.ZodIssueCode.custom,
        });
      }
    })
    .optional(),
});

export type ComponentEstimatedBudgetAllocationFormValidationSchemaProps =
  z.infer<
    typeof ComponentEstimatedBudgetAllocationFormValidationSchema & {
      [key: string]: any;
    }
  >;

// campaign-goals-schema
export const ComponentCampaignGoalsFormValidationSchema = z.object({
  campaignGoals: z.record(z.any()).optional(),
});

export type ComponentCampaignGoalsFormValidationSchemaProps = z.infer<
  typeof ComponentCampaignGoalsFormValidationSchema & {
    [key: string]: any;
  }
>;

// indicator-schema
export const ComponentIndicatorFormValidationSchema = z.object({
  indicators: z.any().optional(),
});

export type ComponentIndicatorFormValidationSchemaProps = z.infer<
  typeof ComponentIndicatorFormValidationSchema & {
    [key: string]: any;
  }
>;

export type ComponentFormValidationSchemaProps =
  ComponentBasicFormValidationSchemaProps &
    ComponentEstimatedBudgetAllocationFormValidationSchemaProps &
    ComponentSectorFormValidationSchemaProps &
    ComponentCampaignGoalsFormValidationSchemaProps &
    ComponentIndicatorFormValidationSchemaProps &
    ComponentMarkerFormValidationSchemaProps & {
      [key: string]: any; // Adding an index signature
    };

// Function to get the appropriate schema based on form type
export function getSchema(
  formType:
    | 'basicInfo'
    | 'estimatedBudgetAllocation'
    | 'sectors'
    | 'markers'
    | 'campaignGoals'
    | 'indicators',
) {
  switch (formType) {
    case ComponentFormTypeEnum.BasicInfo:
      return ComponentBasicFormValidationSchema;
    case ComponentFormTypeEnum.EstimatedBudgetAllocation:
      return ComponentEstimatedBudgetAllocationFormValidationSchema;
    case ComponentFormTypeEnum.Sectors:
      return ComponentSectorFormValidationSchema;
    case ComponentFormTypeEnum.Marker:
      return ComponentMarkerFormValidationSchema;
    case ComponentFormTypeEnum.CampaignGoals:
      return ComponentCampaignGoalsFormValidationSchema;
    case ComponentFormTypeEnum.Indicator:
      return ComponentIndicatorFormValidationSchema;
    default:
      throw new Error('Unknown form type');
  }
}
