import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { cn } from '@Utils/index';

interface IChartXAxisProps {
  className?: string;
  name: string;
  nameClassName?: string;
  doubleXAxis?: boolean;
  fills?: string[] | null;
  data?: string[];
}

const ChartXAxis = ({
  className,
  name,
  nameClassName,
  doubleXAxis,
  fills,
  data,
}: IChartXAxisProps) => {
  return (
    <div className={cn('x-axis-wrapper ', className)}>
      <p
        className={cn(
          'fs-xs-medium naxatw-mb-2 naxatw-mt-3 naxatw-text-center',
          nameClassName,
        )}
      >
        {' '}
        {name}{' '}
      </p>
      {doubleXAxis && (
        <div className="fs-sm-medium naxatw-my-3 naxatw-flex naxatw-items-center naxatw-justify-center naxatw-gap-2">
          {data?.map((chartLegendItem, index) => {
            return (
              <div
                key={chartLegendItem}
                className="naxatw-flex naxatw-items-center naxatw-gap-[0.35rem]"
              >
                <div
                  className="naxatw-size-2.5 naxatw-rounded"
                  style={{ background: fills?.[index] || '#000' }}
                />
                <span className="fs-xs-medium">{chartLegendItem}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default hasErrorBoundary(ChartXAxis);
