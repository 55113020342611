/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { animated, useSpring } from '@react-spring/web';
import AdminHeader from '@Components/common/AdminHeader';
import Portal from '@Components/common/Layouts/Portal';
import Modal from '@Components/common/Modal';
import StatusChips from '@Components/common/StatusChips';
import DataTable from '@Components/common/DataTable';
import { getFeedbackList } from '@Services/feedback';

const Feedback = () => {
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });
  const modalZoomSprings = useSpring({
    transform: isMessageModalOpen ? 'scale(1)' : 'scale(0)', // Scale up or down
    config: { tension: 200, friction: 20 }, // Adjust the animation timing
  });
  const feedbackFormcolumns = [
    {
      header: 'NAME',
      accessorKey: 'name',
    },
    {
      header: 'EMAIL',
      accessorKey: 'email',
    },
    {
      header: 'FEEDBACK TYPE',
      accessorKey: 'feedback_type',
    },
  ];
  return (
    <>
      <animated.div
        style={{ ...springs }}
        className="naxatw-flex naxatw-h-full naxatw-flex-col naxatw-space-y-6"
      >
        <AdminHeader
          title="Feedback"
          registerButtonText="Add"
          needSearchBar={false}
          needAddNewButton={false}
          // needAddNewButton={
          //   userGroupPermission?.includes('Can add sector') ?? false
          // }
        />
        <DataTable
          columns={[
            ...feedbackFormcolumns,
            {
              header: 'ATTACHMENT',
              accessorKey: 'attachment',
              cell: row => {
                return (
                  <div
                    className="naxatw-mt-2 naxatw-flex naxatw-w-full naxatw-justify-start naxatw-py-4"
                    role="button"
                    onClick={() => {
                      window.open(row?.row?.original.attachment, '_blank');
                    }}
                  >
                    {row?.row?.original.attachment?.length > 0 ? (
                      <StatusChips status="info" label="Attachment" />
                    ) : (
                      <p className="naxatw-text-sm">No Attachment</p>
                    )}
                  </div>
                );
              },
            },
            {
              header: 'MESSAGE',
              accessorKey: 'message',
              cell: row => {
                return (
                  <div
                    className="naxatw-mt-2 naxatw-flex naxatw-w-full naxatw-justify-start naxatw-py-2"
                    role="button"
                    onClick={() => {
                      setIsMessageModalOpen(true);
                      setFeedbackMessage(row?.row?.original.message);
                    }}
                  >
                    <p
                      className=" naxatw-line-clamp-1"
                      // title={row?.row?.original.project_name}
                    >
                      <span className="naxatw-text-xs naxatw-font-medium">
                        {row?.row?.original.message}
                      </span>
                    </p>
                  </div>
                );
              },
            },
          ]}
          queryFn={getFeedbackList}
          queryKey="feedback-tabledata"
          sortByKey
          isPaginated
          initialState={{
            paginationState: {
              pageIndex: 0,
              pageSize: 25,
            },
          }}
          searchInput=""
        />
      </animated.div>

      {isMessageModalOpen && (
        <Portal>
          <animated.div
            style={{ ...modalZoomSprings }}
            className="naxatw-fixed naxatw-left-0 naxatw-top-0 naxatw-flex naxatw-h-screen naxatw-w-screen naxatw-items-center naxatw-justify-center "
          >
            <Modal
              onClose={() => setIsMessageModalOpen(false)}
              title="Feedback"
              show={isMessageModalOpen}
              titleClassName="naxatw-text-2xl"
              childrenParentClassName="!naxatw-px-8 !naxatw-pb-8"
            >
              <p className="naxatw-text-justify naxatw-text-sm naxatw-font-medium">
                {feedbackMessage}
              </p>
            </Modal>
          </animated.div>
        </Portal>
      )}
    </>
  );
};

export default Feedback;
