import Metric from '@Components/common/Card/Metric';
import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportBtn from '@Components/common/CommonExport/ExportHeader/ExportBtn';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import ProgrammeChart from '@Components/MainDashboard/ChartSection/ChartDataSummary/DataSummaryContent/ProgrammeChart';
import ProjectChart from '@Components/MainDashboard/ChartSection/ChartDataSummary/DataSummaryContent/ProjectChart';
import WorkingAreas from '@Components/MainDashboard/ChartSection/ChartDataSummary/DataSummaryContent/WorkingAreas';
import {
  excludeProvinces,
  getMappedXNames,
  projectProvinces,
} from '@Constants/mainDashboard';
import { getProjectByProvince } from '@Services/provincialProfile';
import {
  deleteIndividualList,
  deletePdfSection,
  resetPdfSection,
} from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import { convertToCurrencySystem } from '@Utils/index';
import isEmpty from '@Utils/isEmpty';
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';

const ReportSummary = ({ overallCount }: any) => {
  const dispatch = useDispatch();

  const cardsData = useTypedSelector(
    state => state.pdfExportSlice.bek.summary.datalists,
  );

  const showResetButton = useTypedSelector(
    state => state.pdfExportSlice.bek.summary.showResetButton,
  );

  const statusFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );

  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const projectFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );

  const subMarkersParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );

  const provinceFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.province,
  );

  const { data: chartsData } = useQuery({
    queryKey: ['get-project-by-province-report'],
    queryFn: () =>
      getProjectByProvince({
        status: statusFilterParams,
        program: programmeFilterParams?.join(','),
        project: projectFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        province: provinceFilterParams?.join(','),
        start_date: startDate,
        end_date: endDate,
      }),
    select: res => {
      if (!res?.data) return [];
      const chartData = res.data;
      if (isEmpty(cardsData)) return [];
      const finalData = chartData.map((chartItem: Record<string, any>) => ({
        name: getMappedXNames[chartItem.name] || chartItem.name,
        value: chartItem.count,
      }));

      return finalData;
    },
  });

  const {
    first_tier_partner_count: partners,
    allocated_budget: budget,
    program_count: programCount,
    project_count: projectCount,
    marker_count: markers,
    sector_count: sectors,
    stakeholder_count: stakeholdersCount,
  } = overallCount || {};

  const isAreasVisible = cardsData.includes('working-areas');

  const isSecMarBudVisible =
    cardsData.includes('sector') ||
    cardsData.includes('marker') ||
    cardsData.includes('budget');

  const isGeoGraphVisible = cardsData.includes('geographic-chart');
  const isProjectChartVisible = cardsData.includes('project-chart');

  return (
    <ExportRoundedContainer
      style={{
        pageBreakInside: 'avoid',
      }}
    >
      <ExportHeader
        headerTitle="Portfolio Summary"
        isStyleAbsolute={false}
        showResetButton={showResetButton}
        onHandleReset={() =>
          dispatch(
            resetPdfSection({
              page: 'bek',
              section: 'summary',
            }),
          )
        }
        className="naxatw-border-b naxatw-border-b-pdf-border naxatw-p-2"
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: 'bek',
              section: 'summary',
            }),
          )
        }
      />
      <div className="main-content naxatw-bg-primary-200 naxatw-p-2">
        {/* first-row */}
        <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
          {cardsData.includes('programme') && (
            <motion.div
              className="naxatw-group naxatw-relative naxatw-flex-1"
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Metric
                label="Programme"
                iconName="list_alt"
                metricCount={programCount}
                isExport
              />
              <ExportBtn
                size="sm"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: 'bek',
                      section: 'summary',
                      key: 'datalists',
                      value: 'programme',
                    }),
                  )
                }
              />
            </motion.div>
          )}
          {cardsData.includes('project') && (
            <motion.div
              className="naxatw-group naxatw-relative naxatw-flex-1"
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Metric
                label="Project"
                iconName="folder_open"
                metricCount={projectCount}
                isExport
              />
              <ExportBtn
                size="sm"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: 'bek',
                      section: 'summary',
                      key: 'datalists',
                      value: 'project',
                    }),
                  )
                }
              />
            </motion.div>
          )}
          {cardsData.includes('partner') && (
            <motion.div
              className="naxatw-group naxatw-relative naxatw-flex-1"
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Metric
                label="First Tier Partner"
                iconName="handshake"
                metricCount={partners}
                isExport
              />
              <ExportBtn
                size="sm"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: 'bek',
                      section: 'summary',
                      key: 'datalists',
                      value: 'partner',
                    }),
                  )
                }
              />
            </motion.div>
          )}
          {cardsData.includes('stakeholders') && (
            <motion.div
              className="naxatw-group naxatw-relative naxatw-flex-1"
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Metric
                label="Stakeholders"
                iconName="groups"
                metricCount={stakeholdersCount}
                isExport
              />
              <ExportBtn
                size="sm"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: 'bek',
                      section: 'summary',
                      key: 'datalists',
                      value: 'stakeholders',
                    }),
                  )
                }
              />
            </motion.div>
          )}
        </div>

        {/* second-row */}
        <div
          className={`${isAreasVisible || isSecMarBudVisible || isGeoGraphVisible || isProjectChartVisible ? 'naxatw-mt-2' : ''} naxatw-flex  naxatw-gap-2`}
        >
          <motion.div
            className={` ${isAreasVisible || isGeoGraphVisible || isSecMarBudVisible ? 'naxatw-basis-[60%]' : ''} naxatw-flex-1 `}
            layout
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {isAreasVisible && (
              <div className="naxatw-group naxatw-relative">
                <WorkingAreas
                  className="!naxatw-h-full"
                  isExport
                  overallCount={overallCount}
                />
                <ExportBtn
                  size="sm"
                  onHandleClose={() =>
                    dispatch(
                      deleteIndividualList({
                        page: 'bek',
                        section: 'summary',
                        key: 'datalists',
                        value: 'working-areas',
                      }),
                    )
                  }
                />
              </div>
            )}

            <div
              className={`naxatw-flex naxatw-gap-2 ${isAreasVisible ? '' : 'naxatw-h-full'}`}
            >
              <motion.div
                className={` ${isAreasVisible ? 'naxatw-mt-2' : ''} naxat-h-full sec-mar-bud naxatw-flex naxatw-flex-1 ${isSecMarBudVisible ? 'naxatw-basis-[40%] ' : ''} naxatw-flex-col naxatw-gap-2`}
                layout
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                {cardsData.includes('sector') && (
                  <div className="naxatw-group naxatw-relative naxatw-flex-1">
                    <Metric
                      label="Sectors"
                      iconName="category"
                      metricCount={sectors}
                      isExport
                      className="naxatw-h-full"
                    />
                    <ExportBtn
                      size="sm"
                      onHandleClose={() =>
                        dispatch(
                          deleteIndividualList({
                            page: 'bek',
                            section: 'summary',
                            key: 'datalists',
                            value: 'sector',
                          }),
                        )
                      }
                    />
                  </div>
                )}
                {cardsData.includes('marker') && (
                  <div className="naxatw-group naxatw-relative naxatw-flex-1">
                    <Metric
                      label="Markers"
                      iconName="donut_small"
                      metricCount={markers}
                      className="naxatw-h-full"
                      isExport
                    />
                    <ExportBtn
                      size="sm"
                      onHandleClose={() =>
                        dispatch(
                          deleteIndividualList({
                            page: 'bek',
                            section: 'summary',
                            key: 'datalists',
                            value: 'marker',
                          }),
                        )
                      }
                    />
                  </div>
                )}
                {cardsData.includes('budget') && (
                  <div className="naxatw-group naxatw-relative naxatw-flex-1">
                    <Metric
                      label="Allocated Budget"
                      iconName="currency_pound"
                      isExport
                      className="naxatw-h-full"
                      metricCount={convertToCurrencySystem(budget)}
                    />
                    <ExportBtn
                      size="sm"
                      onHandleClose={() =>
                        dispatch(
                          deleteIndividualList({
                            page: 'bek',
                            section: 'summary',
                            key: 'datalists',
                            value: 'budget',
                          }),
                        )
                      }
                    />
                  </div>
                )}
              </motion.div>

              {isGeoGraphVisible && (
                <motion.div
                  className={` ${isAreasVisible ? 'naxatw-mt-2' : ''} naxatw-group naxatw-relative naxatw-min-h-[200px] naxatw-flex-1 ${isSecMarBudVisible ? 'naxatw-basis-[60%]' : 'naxatw-basis-[100%]'}`}
                  layout
                  animate={{ opacity: 1 }}
                  initial={{ opacity: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <ProgrammeChart
                    chartsData={
                      chartsData?.filter(
                        (province: Record<string, any>) =>
                          !excludeProvinces.includes(province.name),
                      ) || []
                    }
                    isExport
                    className="naxatw-h-full"
                  />
                  <ExportBtn
                    size="sm"
                    onHandleClose={() =>
                      dispatch(
                        deleteIndividualList({
                          page: 'bek',
                          section: 'summary',
                          key: 'datalists',
                          value: 'geographic-chart',
                        }),
                      )
                    }
                  />
                </motion.div>
              )}
            </div>
          </motion.div>

          {isProjectChartVisible && (
            <motion.div
              className={` ${isAreasVisible || isGeoGraphVisible || isSecMarBudVisible ? 'naxatw-basis-[40%] ' : 'naxatw-basis-full'} naxatw-group naxatw-relative `}
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <ProjectChart
                chartsData={
                  chartsData?.filter((province: Record<string, any>) =>
                    projectProvinces.includes(province.name),
                  ) || []
                }
                isExport
                className="!naxatw-h-full"
              />
              <ExportBtn
                size="sm"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: 'bek',
                      section: 'summary',
                      key: 'datalists',
                      value: 'project-chart',
                    }),
                  )
                }
              />
            </motion.div>
          )}
        </div>
      </div>
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(ReportSummary);
