import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportBtn from '@Components/common/CommonExport/ExportHeader/ExportBtn';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { getSectorWiseCountData } from '@Services/provincialProfile';
import {
  deleteIndividualList,
  deletePdfSection,
  resetPdfSection,
  setOriginalDataLists,
} from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import isEmpty from '@Utils/isEmpty';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const PdfSectors = () => {
  const { programmeId, projectId } = useParams();
  const dispatch = useDispatch();

  const pageType = projectId ? 'projectDetails' : 'programmeDetails';

  const sectorLists = useTypedSelector<readonly number[]>(state =>
    projectId
      ? state.pdfExportSlice.projectDetails.sectors.datalists
      : state.pdfExportSlice.programmeDetails.sectors.datalists,
  );

  const showResetButton = useTypedSelector(state =>
    projectId
      ? state.pdfExportSlice.projectDetails.sectors.showResetButton
      : state.pdfExportSlice.programmeDetails.sectors.showResetButton,
  );

  const { data: sectorsData } = useQuery({
    queryKey: ['sectors-data-pdf', projectId, programmeId],
    queryFn: async () =>
      getSectorWiseCountData({
        program: programmeId,
        ...(projectId ? { project: projectId } : {}),
      }),
    select: res => {
      if (!res?.data) return [];
      const responseData = res?.data;
      const finalData = responseData.map((sector: Record<string, any>) => ({
        id: sector.sector_id,
        label: sector.sector_name,
      }));

      return finalData;
    },
    onSuccess: response => {
      if (isEmpty(response)) return;
      dispatch(
        setOriginalDataLists({
          page: pageType,
          section: 'sectors',
          value: response,
        }),
      );
    },
  });

  return (
    <ExportRoundedContainer
      className="naxatw-p-3"
      style={{
        pageBreakInside: 'avoid',
      }}
    >
      <ExportHeader
        headerTitle="Sectors"
        isStyleAbsolute={false}
        showResetButton={showResetButton}
        onHandleDelete={() => {
          dispatch(
            deletePdfSection({
              page: pageType,
              section: 'sectors',
            }),
          );
        }}
        onHandleReset={() => {
          dispatch(
            resetPdfSection({
              page: pageType,
              section: 'sectors',
            }),
          );
        }}
      />
      {/* sectors */}
      <div className="sectors-container naxatw-flex naxatw-flex-wrap naxatw-items-center naxatw-gap-2">
        {sectorsData
          ?.filter((sector: Record<string, any>) =>
            sectorLists.includes(sector.id),
          )
          .map((sector: Record<string, any>) => (
            <div
              key={sector.id}
              className="fs-sm-medium naxarw-border-pdf-border naxatw-group naxatw-relative naxatw-rounded-[1.25rem] naxatw-border naxatw-px-2 naxatw-py-1 naxatw-text-black"
            >
              {sector.label}
              <ExportBtn
                size="sm"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: pageType,
                      section: 'sectors',
                      key: 'datalists',
                      value: sector.id,
                    }),
                  )
                }
                btnStyles="naxatw-translate-x-2/4 -naxatw-translate-y-2/4 !naxatw-top-0 !naxatw-right-0"
              />
            </div>
          ))}
      </div>
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(PdfSectors);
