import ExportButtons from '@Components/common/CommonExport/ExportHeader';
import ExportBtn from '@Components/common/CommonExport/ExportHeader/ExportBtn';
import { useTypedSelector } from '@Store/hooks';
import { useDispatch } from 'react-redux';
import {
  deleteIndividualList,
  deletePdfSection,
  resetPdfSection,
} from '@Store/actions/pdfExport';
import { contextualDataTwo } from '@Constants/PdfContent';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';

const PdfContentextaul = ({
  provinceName,
  provinceDetails,
}: {
  provinceName: string;
  provinceDetails: Record<string, any>;
}) => {
  const dispatch = useDispatch();

  const showResetButton = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.contextual.showResetButton,
  );

  const cardsData = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.contextual.datalists,
  );

  const provinceData = provinceDetails;

  const conInfoTwo = [
    {
      id: 'no-of-palika',
      label: 'No. of Palikas',
      value: provinceData?.no_of_palika || '-',
      overall: 753,
    },
    {
      id: 'no-of-district',
      label: 'No. of Districts',
      value: provinceData?.no_of_district || '-',
      overall: 77,
    },
    {
      id: 'household-size',
      label: 'Average Household Size',
      value: provinceData?.avg_household || '-',
      overall: 4.37,
    },
    {
      id: 'population-density',
      label: 'Population Density',
      value: `${provinceData?.population_density}/km` || '-',
      overall: '216/km',
      type: 'km',
    },
    {
      id: 'male',
      label: 'Male',
      value: `${provinceData?.male}%` || '-',
      overall: '48.98%',
    },
    {
      id: 'female',
      label: 'Female',
      value: `${provinceData?.female}%` || '-',
      overall: '50.19%',
    },
    {
      id: 'absentee-abroad',
      label: 'Absentee Abroad',
      value: `${provinceData?.absentee_abroad}%` || '',
      overall: '23%',
    },
  ];

  const isCharacterDataLeft = cardsData.some((info: string) =>
    contextualDataTwo.includes(info),
  );

  return (
    <div className="pdf-contextaul-container">
      <ExportButtons
        headerTitle="Contextual Information"
        isStyleAbsolute={false}
        showResetButton={showResetButton}
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: 'provincialProfile',
              section: 'contextual',
            }),
          )
        }
        onHandleReset={() => {
          dispatch(
            resetPdfSection({
              page: 'provincialProfile',
              section: 'contextual',
            }),
          );
        }}
      />

      {/* characteristics */}
      {isCharacterDataLeft && (
        <>
          <div className="province-characteristics naxatw-grid naxatw-grid-cols-3 naxatw-border-b naxatw-border-b-grey-200 naxatw-pb-2">
            <p className="fs-sm-semibold naxatw-uppercase">characteristics</p>
            <p className="fs-sm-semibold naxatw-justify-self-center naxatw-uppercase">
              {provinceName}
            </p>
            <p className="fs-sm-semibold naxatw-justify-self-center naxatw-uppercase">
              Nepal
            </p>
          </div>
          {conInfoTwo.map(info => {
            if (cardsData.includes(info.id)) {
              return (
                <div key={info.id} className="naxatw-group naxatw-relative ">
                  <div className="province-characteristics  naxatw-grid naxatw-grid-cols-3 naxatw-border-b naxatw-border-b-grey-200 naxatw-py-2">
                    <p className="fs-sm-medium">{info.label}</p>
                    <p className="fs-sm-medium naxatw-justify-self-center ">
                      {info.value} {info.type === 'km' ? <sup>2</sup> : null}
                    </p>
                    <p className="fs-sm-medium naxatw-justify-self-center ">
                      {info.overall} {info.type === 'km' ? <sup>2</sup> : null}
                    </p>
                  </div>
                  <ExportBtn
                    size="sm"
                    className="naxatw-right-0 naxatw-top-[8px]"
                    onHandleClose={() =>
                      dispatch(
                        deleteIndividualList({
                          page: 'provincialProfile',
                          section: 'contextual',
                          key: 'datalists',
                          value: info.id,
                        }),
                      )
                    }
                  />
                </div>
              );
            }
            return null;
          })}
        </>
      )}
    </div>
  );
};

export default hasErrorBoundary(PdfContentextaul);
