/* eslint-disable no-nested-ternary */
import { useMapLibreGLMap } from '@Components/common/MapLibreComponents';
import VectorTileLayer from '@Components/common/MapLibreComponents/Layers/VectorTileLayer';
import MapContainer from '@Components/common/MapLibreComponents/MapContainer';
import { defaultLayerStyle, fillByProvinceStyle } from '@Constants/map';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import MapTools from '@Components/common/MapTools';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { getExpression } from '@Constants/mainDashboard';
import switchTile from '@Utils/switchVectorTile';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  contextualIndicatorByFederal,
  getOverlayPopupData,
} from '@Services/contextualIndicators';
import { setActiveViewBy } from '@Store/actions/exploreByIndicators';
import { Map } from 'maplibre-gl';
import AsyncPopup from '@Components/common/MapLibreComponents/AsyncPopup';
import MapAdditionalTools from '@Components/MainDashboard/MapSection/MapAdditionalTools';
import BaseLayerSwitcher from '@Components/common/MapLibreComponents/BaseLayerSwitcher';
import {
  contextualIndicatorsOptions,
  indicatorsWithNonDecimalValues,
} from '@Constants/exploreByIndicators';
import OverlayLayerLegend from '@Components/common/MapLibreComponents/OverlayLayerLegend';
import NoDataAvailable from '@Components/MainDashboard/MapSection/Map/NodataAvailable';
import { getStyleByGeomType } from '@Utils/index';
import { getFederalInfoSource } from '@Services/indicators';
import {
  toggleOverlayPopup,
  setMainDashboardStates,
} from '@Store/actions/mainDashboard';
import { getBbox } from '@Services/provincialProfile';
import { getOverlayLayerList } from '@Services/mainDashboard';
import RangeSlider from '@Components/RadixComponents/RangelSlider';
import Legend, { StaticLegendForProvince } from './Legend';

const BASEURL = process.env.API_URL_V1;

const MapSection = ({ selectedProvince }: { selectedProvince: number }) => {
  const dispatch = useTypedDispatch();
  const queryClient = useQueryClient();
  const mapRef = useRef(null);
  const [showNoDataAvailable, setShowNoDataAvailable] = useState(false);
  const [fillOpacity, setFillOpacity] = useState(1);
  const activeViewBy = useTypedSelector(
    state => state.exploreByIndicators.map.activeViewBy,
  );
  const selectedIndicator = useTypedSelector(
    state => state.exploreByIndicators.selectedIndicator,
  );
  const overlayLayerList = useTypedSelector(
    state => state.mainDashboard.overlayLayerList,
  );
  const [hoveredFeature, setHoveredFeature] = useState<Record<
    string,
    any
  > | null>(null);
  const [selectedFeature, setSelectedFeature] = useState<Record<
    string,
    any
  > | null>(null);
  const [selectedBaseLayer, setSelectedBaseLayer] = useState('mapbox-light');

  const [selectedOverlayLayerId, setSelectedOverlayLayerId] = useState<
    number | null
  >(null);
  const [selectedOverlayFeatureId, setSelectedOverlayFeatureId] = useState<
    number | null
  >(null);
  const [overlayPopupData, setOverlayerPopupData] = useState<
    Record<string, any>[]
  >([]);

  const { map, isMapLoaded } = useMapLibreGLMap({
    mapOptions: {
      center: [84.1258, 28.3974],
      zoom: 6,
      minZoom: 6,
      maxZoom: 19,
      maxBounds: [
        [73.916016, 23.785345],
        [94.669189, 32.879587],
      ],
      preserveDrawingBuffer: true,
    },
  });

  const { data: countMapping, isLoading } = useQuery({
    queryKey: [
      'contextual-indicator-by-federal',
      activeViewBy,
      selectedIndicator,
      selectedProvince,
    ],
    queryFn: async () => {
      const commonParams = {
        indicator: selectedIndicator,
        by: activeViewBy,
      };
      // apply province filter only if the view by is municipality selected province value is valid
      return contextualIndicatorByFederal(
        activeViewBy === 'municipality' && selectedProvince
          ? { ...commonParams, province: selectedProvince }
          : commonParams,
      );
    },
    enabled: !!selectedIndicator,
    select: res => res.data,
  });

  const { data: dataSource } = useQuery({
    queryKey: ['contextual-indicator-by-federal-source', selectedIndicator],
    queryFn: async () => getFederalInfoSource({ type: selectedIndicator }),
    enabled: !!selectedIndicator,
    select: res =>
      res.data?.find((source: any) => source?.type === selectedIndicator),
  });

  // fetch layers and set them to state
  useQuery({
    queryKey: ['overlay-layers'],
    queryFn: getOverlayLayerList,
    select: res => res.data.data,
    onSuccess: res => {
      const modifiedResponse = res?.map((layer: Record<string, any>) => ({
        ...layer,
        checked: false,
      }));
      dispatch(setMainDashboardStates({ overlayLayerList: modifiedResponse }));
    },
    // enabled: overlayLayerList === null,
  });

  useQuery({
    queryKey: ['overlay-layer-popup', selectedOverlayLayerId],
    queryFn: () => getOverlayPopupData(selectedOverlayLayerId as number),
    select: res => res.data,
    onSuccess: res => {
      setOverlayerPopupData(res);
    },
    enabled: !!selectedOverlayLayerId,
  });

  const { data: extent } = useQuery({
    queryKey: ['province-extent', selectedProvince],
    queryFn: () => getBbox({ province: selectedProvince }),
    select: res => res.data?.extent,
    enabled: !!selectedProvince,
  });

  // code block to change the fill and outline on hover
  useEffect(() => {
    if (!map || !countMapping || !isMapLoaded) return;
    const fillData = indicatorsWithNonDecimalValues.includes(
      selectedIndicator || '',
    )
      ? getExpression(
          countMapping,
          selectedFeature?.id,
          '',
          selectedIndicator || '',
          `${activeViewBy}_id`,
          false,
        )
      : getExpression(
          countMapping,
          selectedFeature?.id,
          '',
          selectedIndicator || '',
          `${activeViewBy}_id`,
          true,
        );
    if (
      !map.getLayer(
        `vectorTile-${activeViewBy}-${selectedIndicator || 'no-indicators'}`,
      )
    )
      return;
    map.setPaintProperty(
      `vectorTile-${activeViewBy}-${selectedIndicator || 'no-indicators'}`,
      'fill-color',
      fillData,
    );

    // to set the outline to green of the map when hovered
    if (hoveredFeature?.isHovered) {
      map.setPaintProperty(
        `vectorTile-${activeViewBy}-${selectedIndicator || 'no-indicators'}-outline`,
        'line-color',
        ['match', ['get', 'id'], hoveredFeature?.id, '#DE0A26', '#808080'],
      );
      map.setPaintProperty(
        `vectorTile-${activeViewBy}-${selectedIndicator || 'no-indicators'}-outline`,
        'line-opacity',
        ['match', ['get', 'id'], hoveredFeature?.id, 1.5, 0.1],
      );
    } else {
      map.setPaintProperty(
        `vectorTile-${activeViewBy}-${selectedIndicator || 'no-indicators'}-outline`,
        'line-color',
        '#808080',
      );
    }
  }, [
    map,
    countMapping,
    activeViewBy,
    selectedIndicator,
    hoveredFeature,
    selectedFeature,
    isMapLoaded,
  ]);

  // change the fill opacity when changing the slider
  useEffect(() => {
    if (!map || !isMapLoaded) return;
    if (
      !map.getLayer(
        `vectorTile-${activeViewBy}-${selectedIndicator || 'no-indicators'}`,
      ) &&
      !map.getLayer(
        `vectorTile-${activeViewBy}-${selectedIndicator || 'no-indicators'}-outline`,
      )
    )
      return;
    map.setPaintProperty(
      `vectorTile-${activeViewBy}-${selectedIndicator || 'no-indicators'}`,
      'fill-opacity',
      fillOpacity,
    );
  }, [activeViewBy, selectedIndicator, fillOpacity, map, isMapLoaded]);

  // set back to view by province cause there is no fill for municipality if indicator is not selected
  useEffect(() => {
    setShowNoDataAvailable(false);
    if (selectedIndicator) return;
    dispatch(setActiveViewBy('province'));
  }, [selectedIndicator, dispatch]);

  useEffect(
    () => () => {
      queryClient.invalidateQueries({
        queryKey: ['overlay-layers'],
      });
      dispatch(toggleOverlayPopup(false));
    },
    [dispatch, queryClient],
  );

  const getPopupUI = useCallback(() => {
    const selectedOverlay = overlayPopupData?.filter(
      (feature: any) => feature?.attr_data__id === selectedOverlayFeatureId,
    );
    if (selectedOverlay.length)
      return (
        <div className="naxatw-bg-white">
          <div className="-naxatw-mt-2 naxatw-flex naxatw-items-center naxatw-justify-start naxatw-gap-1">
            <div className="naxatw-h-3 naxatw-w-3 naxatw-rounded-full naxatw-bg-[#5DB8B2]" />
            <h6 className="naxatw-text-[16px] naxatw-font-bold naxatw-text-[#5DB8B2]">
              {selectedOverlay[0]?.attr_data__name || 'No Data Found'}
            </h6>
          </div>
        </div>
      );
    if (!selectedIndicator || !countMapping || !selectedFeature?.id)
      return <div className="naxatw-text-gray-800">No Data Found</div>;
    const data = countMapping?.find(
      (count: any) => count?.[`${activeViewBy}_id`] === selectedFeature?.id,
    );

    return (
      <div className="naxatw-bg-white">
        <div className="naxatw-flex naxatw-items-center naxatw-justify-start naxatw-gap-1">
          <div className="naxatw-h-3 naxatw-w-3 naxatw-rounded-full naxatw-bg-[#5DB8B2]" />

          <h6 className="naxatw-text-[16px] naxatw-font-bold naxatw-text-[#5DB8B2]">
            {selectedFeature?.name || 'No Data Found'}
          </h6>
        </div>
        <div className="naxatw-flex naxatw-flex-col naxatw-py-3 naxatw-text-gray-600">
          <p className="naxatw-capitalize">
            {selectedIndicator?.replace(/_/g, ' ')} :{' '}
            {data?.[selectedIndicator]}
          </p>
        </div>
      </div>
    );
  }, [
    overlayPopupData,
    selectedIndicator,
    countMapping,
    selectedFeature?.id,
    selectedFeature?.name,
    selectedOverlayFeatureId,
    activeViewBy,
  ]);

  const getIndicatorLabelByValue = (value: string) => {
    return contextualIndicatorsOptions?.find(
      indicator => indicator.value === value,
    )?.label;
  };

  const isOverlayLayerApplied = useMemo(
    () => overlayLayerList?.some(layer => layer.checked),
    [overlayLayerList],
  );

  // show data not available if data is empty
  useEffect(() => {
    if (
      !map ||
      !isMapLoaded ||
      isLoading ||
      !countMapping ||
      !selectedIndicator
    )
      return () => {};
    if (countMapping?.length === 0) {
      setShowNoDataAvailable(true);
    } else {
      setShowNoDataAvailable(false);
    }

    const resetNoDataAvailable = setTimeout(() => {
      setShowNoDataAvailable(false);
    }, 2000);

    return () => {
      return clearTimeout(resetNoDataAvailable);
    };
  }, [map, isMapLoaded, isLoading, countMapping, selectedIndicator]);

  useEffect(() => {
    if (!map || !isMapLoaded || isLoading || !countMapping) return;
    if (extent) {
      map.fitBounds(extent, {
        padding: { top: 100, bottom: 60, left: 30, right: 30 },
      });
    } else {
      map.fitBounds([
        [73.916016, 23.785345],
        [94.669189, 32.879587],
      ]);
    }
  }, [map, isMapLoaded, isLoading, countMapping, extent]);

  return (
    <>
      <div style={{ width: '100%', height: '100%' }} ref={mapRef}>
        <MapContainer
          style={{ width: '100%', height: '100%' }}
          map={map}
          isMapLoaded={isMapLoaded}
        >
          <MapAdditionalTools
            mapRef={mapRef}
            isFetching={selectedIndicator ? isLoading : false}
            sourceIdToLoad={
              selectedIndicator
                ? `vectorTile-${activeViewBy}-${selectedIndicator}`
                : `vectorTile-${activeViewBy}-no-indicators`
            }
          />
          <BaseLayerSwitcher
            activeLayer={selectedBaseLayer}
            isMapLoaded={isMapLoaded as boolean}
            onBaseLayerChange={layerId => {
              setSelectedBaseLayer(layerId);
            }}
          />

          {overlayLayerList &&
            overlayLayerList?.map(layer => (
              <VectorTileLayer
                key={layer.id}
                id={`vectorTile-${layer.layer__name_en}-overlay`}
                isMapLoaded={isMapLoaded}
                url={`${BASEURL}/feature_collection_tile/{z}/{x}/{y}.pbf?feature=${layer.id}`}
                layerOptions={getStyleByGeomType(
                  layer?.geometry_type,
                  layer?.vector_layer_style__style_json,
                )}
                showLabel
                visibleOnMap={layer.checked}
              />
            ))}

          <VectorTileLayer
            id={`vectorTile-${activeViewBy}-${selectedIndicator || 'no-indicators'}`}
            isMapLoaded={isMapLoaded}
            url={`${BASEURL}/${switchTile(activeViewBy)}/{z}/{x}/{y}.pbf?${activeViewBy === 'municipality' && selectedProvince ? `province=${selectedProvince}` : ''}`}
            layerOptions={
              selectedIndicator ? defaultLayerStyle : fillByProvinceStyle
            }
            onHover={feature => {
              setHoveredFeature(feature);
            }}
            interactions={['select']}
            showOutline
            showNamePopup
          />

          <Legend
            heading={getIndicatorLabelByValue(selectedIndicator || '')}
            data={countMapping}
            countKey={selectedIndicator || ''}
            className={`${selectedIndicator ? '' : 'naxatw-hidden'}`}
            decimalSupport={
              !indicatorsWithNonDecimalValues.includes(selectedIndicator || '')
            }
          />
          <StaticLegendForProvince
            className={`${selectedIndicator ? 'naxatw-hidden' : ''}`}
          />

          {isOverlayLayerApplied && <OverlayLayerLegend />}

          <AsyncPopup
            map={map as Map}
            isMapLoaded={isMapLoaded}
            popupUI={getPopupUI}
            showPopup={clicked => {
              if (clicked?.source?.includes('no-indicators')) return false;
              return true;
            }}
            fetchPopupData={(properties: Record<string, any>) => {
              if (properties?.layer?.includes('overlay')) {
                setSelectedOverlayLayerId(properties?.feature_id);
                setSelectedOverlayFeatureId(properties?.id);
              } else {
                setSelectedFeature(properties);
              }
            }}
            hideButton
            onClose={() => {
              setSelectedFeature(null);
              setSelectedOverlayFeatureId(null);
              setSelectedOverlayLayerId(null);
              setOverlayerPopupData([]);
            }}
          />

          {showNoDataAvailable && (
            <NoDataAvailable
              hasCloseBtn
              onClose={() => setShowNoDataAvailable(false)}
              text={`No Data available for ${activeViewBy} level for this indicator`}
            />
          )}

          {dataSource?.source && (
            <div className="naxatw-absolute naxatw-bottom-0 naxatw-right-[85px] naxatw-z-20 naxatw-rounded-t-lg naxatw-bg-white naxatw-px-2 naxatw-text-xs naxatw-italic">
              Source: {dataSource?.source}
            </div>
          )}
          <div className="actions naxatw-absolute naxatw-bottom-5 naxatw-right-36 naxatw-z-10 naxatw-w-28">
            <RangeSlider
              className="naxatw-h-8"
              max={100}
              step={1}
              value={[fillOpacity * 100]}
              onValueChange={(value: number[]) =>
                setFillOpacity(value[0] / 100)
              }
            />
          </div>
          {/* --------------- map tools ---------------  */}
          <MapTools map={map} />
        </MapContainer>
      </div>
    </>
  );
};

export default hasErrorBoundary(MapSection);
