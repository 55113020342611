/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import FormStepsWrapper from '@Components/common/FormComponent/FormStepsWrapper';
import FormWrapper from '@Components/common/FormComponent/FormWrapper';
import {
  disableValidationForms,
  knowledgeRepoMultiStepTitle,
} from '@Constants/FormConstants/knowledgeRepoConstants';
import MultiStep from '@Components/common/FormComponent/Multistep';
import { knowledgeRepoFormType } from '@Constants/Types/FormTypes/knowledgeRepository';
import FormContainer from '@Components/common/FormComponent/FormContainer';
import {
  knowledgeRepoFormTypeEnum,
  KnowRepoContextualBasicDetailValidationSchema,
} from '@Validations/KnowledgeRepositoryForm';
import { Button } from '@Components/RadixComponents/Button';
import Icon from '@Components/common/Icon';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  postKnowRepoConData,
  getKnowRepoConById,
  postKnowRepoConDoc,
  postKnowRepConThematicField,
  postKnowRepoConProgrammes,
  postKnowRepoConProjects,
  deleteKnowRepoConThematicField,
  deleteKnowRepoConProgramme,
  deleteKnowRepoConProject,
  patchKnowRepoCon,
  deleteKnowRepoConDoc,
} from '@Services/knowledgeRepository';
import isEmpty from '@Utils/isEmpty';
import prepareFormData from '@Utils/prepareFormData';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import getCheckboxCheckedIds from '@Utils/getCheckedValues';
import BasicDetails from './ContextualBasicDetails';
import DocumentDetails from './DocumentDetails';
import UploadDocuments from './ContextualUploadDocuments';

const FormComponent = ({ type }: Record<string, any>) => {
  switch (type) {
    case 'basicDetails':
      return <BasicDetails />;
    case 'documentDetails':
      return <DocumentDetails />;
    case 'uploadDocuments':
      return <UploadDocuments />;
    default:
      return <BasicDetails />;
  }
};

const Contextual = () => {
  const { id: knowledgeRepoId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [formType, setFormType] =
    useState<knowledgeRepoFormType>('basicDetails');
  const [actualDetail, setActualDetail] = useState<Record<string, any>>({});

  const [completedForm, setCompletedForm] = useState<string[]>([]);

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      file_type: '',
      // is_contextual_indicator: true,
      associate_program_project: {},
      other_program_project: '',
      thematic_field: [],
      published_date: '',
      summary: '',
      tags: '',
      links: '',
      province: '',
      documents: [],
      documentToDelete: null,
    },
    resolver: disableValidationForms.includes(formType)
      ? undefined
      : zodResolver(KnowRepoContextualBasicDetailValidationSchema),
  });

  const {
    handleSubmit,
    getValues,
    reset,
    setValue,
    watch,
    formState: { dirtyFields, isDirty },
  } = formMethods;

  const documentToDelete = watch('documentToDelete');

  //! -------------------------------------------------------------> DELETE API STARTS
  // ------> Delete knowledge repository documents
  const {
    mutate: deleteKnowledgeRepositoryDocument,
    isError: isDeleteDocumentError,
    error: deleteDocumentError,
    isLoading: isDeleteDocumentLoading,
  } = useMutation({
    mutationFn: async () => {
      if (!documentToDelete) return;
      await deleteKnowRepoConDoc(+documentToDelete);
    },
    onSuccess: () => {
      const documentList = getValues('documents');
      const updatedDocuments = documentList?.filter(
        (document: Record<string, any>) => document?.id !== documentToDelete,
      );
      setValue('documents', updatedDocuments);
      toast.success('Document deleted successfully');
      setValue('documentToDelete', null);
    },
  });

  // delete project data
  const { mutateAsync: deleteProjectData } = useMutation({
    mutationFn: (id: number) => deleteKnowRepoConProject(id),
  });

  // delete programme  data
  const { mutateAsync: deleteProgrammeData } = useMutation({
    mutationFn: (id: number) => deleteKnowRepoConProgramme(id),
  });

  // delete thematic field  data
  const { mutateAsync: deleteKnowledgeRepositoryThematicFieldData } =
    useMutation({
      mutationFn: (id: number) => deleteKnowRepoConThematicField(Number(id)),
    });

  //! --------------------------------------------------------------------> DELETE API ENDS
  //! --------------------------------------------------------------------> POST API STARTS
  // -----------> Post knowledge repository
  const {
    mutateAsync: postKnowledgeRepositoryData,
    isLoading: isPostKnowledgeRepositoryDataLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowRepoConData(payloadData),
  });

  // -----------> Post knowledge repository documents
  const {
    mutateAsync: postKnowledgeRepositoryDocumentData,
    isLoading: isPostKnowledgeRepositoryDocumentLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowRepoConDoc(payloadData),
    onError: () => toast.error('Failed to attach documents. Try again'),
  });

  // ----------> Mutation for posting knowledge repository data for thematic fields
  const {
    mutateAsync: postKnowledgeRepositoryThematicFieldData,
    isLoading: isPostKnowledgeRepositoryThematicFieldLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowRepConThematicField(payloadData),
  });

  // ---------->  Mutation for posting knowledge repository data for programmes
  const {
    mutateAsync: postKnowledgeRepositoryProgrammesData,
    isLoading: isPostKnowledgeRepositoryProgrammesLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowRepoConProgrammes(payloadData),
  });

  // ---------->  Mutation for posting knowledge repository data for project
  const {
    mutateAsync: postKnowledgeRepositoryProjectsData,
    isLoading: isPostKnowledgeRepositoryProjectsLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowRepoConProjects(payloadData),
  });

  //! --------------------------------------------------------------------> POST API ENDS

  //! Patch knowledge repository
  const {
    mutateAsync: patchKnowledgeRepositoryData,
    isLoading: isPatchKnowledgeRepositoryDataLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      patchKnowRepoCon(Number(knowledgeRepoId), payloadData),
  });

  //! queries for edit****************************************************************
  const { isLoading: isKnowledgeRepoFetching } = useQuery({
    enabled: !!knowledgeRepoId,
    queryKey: ['knowledgerepo-contextual-form-data'],
    queryFn: () =>
      getKnowRepoConById({
        id: Number(knowledgeRepoId),
      }),
    onError: () =>
      toast.error('Failed to fetch knowledge repository form data'),
    select: responseData => {
      if (!responseData?.data?.results[0]) return [];
      return responseData?.data?.results[0];
    },
    onSuccess: data => {
      if (!data) return;
      // to compare with edited data
      setActualDetail(data);

      const {
        programs,
        thematic_field: thematicField,
        province: provinceName,
        other_program_project,
      } = data;

      Object.keys(data)?.forEach((key: any) => {
        if (key === 'documents') {
          const allDocuments = data[key];
          const finalDocuments = allDocuments?.map(
            (doc: Record<string, any>) => {
              return {
                file: doc.file,
                id: doc.id,
              };
            },
          );
          setValue('documents', finalDocuments);
        } else {
          setValue(key, data[key]);
        }
      });

      const setThematicFieldValues = thematicField?.reduce(
        (
          thematicData: Record<string, any>[],
          thematicItem: Record<string, any>,
        ) => {
          thematicData.push(thematicItem.thematic_field);
          return thematicData;
        },
        [],
      );

      const result = programs?.reduce(
        (acc: Record<string, any>, program: Record<string, any>) => {
          const parentId = program?.associate_program__id;
          if (parentId) {
            acc[`parent_${parentId}`] = true;
          }
          program.projects?.forEach((project: Record<string, any>) => {
            if (project?.id) {
              acc[`child_${project?.id}`] = true;
            }
          });

          return acc;
        },
        {},
      );

      if (!isEmpty(provinceName)) {
        setValue('province', provinceName?.id);
      }
      if (other_program_project) {
        setValue('associate_program_project', {
          ...result,
          ...{ parent_other: true },
        });
      } else {
        setValue('associate_program_project', result);
      }

      setValue('thematic_field', setThematicFieldValues);
    },
  });

  //! --------------------------------------------------------------------> HELPER FUNC STARTS

  // ------> Helper function to handle document uploads
  const postDocuments = async (
    documents: Record<string, any>[],
    id: string | undefined,
  ) => {
    const documentPromises = documents.map(document => {
      const formData = {
        file: document?.file,
        contextual_information: id,
      };
      return postKnowledgeRepositoryDocumentData(prepareFormData(formData));
    });

    await Promise.all(documentPromises);
  };

  // --------> Helper function to handle thematic field posts
  const postThematicFields = async (
    thematicFields: any[],
    id: string | undefined,
  ) => {
    const thematicFieldPromises = thematicFields.map(field => {
      return postKnowledgeRepositoryThematicFieldData({
        contextual_information: id,
        thematic_field: field,
      });
    });

    await Promise.all(thematicFieldPromises);
  };

  // --------> Helper function to handle associated prgrammes and projects posts
  const postAssociatedProjectsAndProgrammes = async (
    programmeProjectFormValues: Record<string, any>,
    id: string | undefined,
  ) => {
    const projectAndProgrammePromises = Object.entries(
      programmeProjectFormValues,
    ).map(async ([key, value]) => {
      if (key === 'parent_other') return; // Skip specific key
      if (key.startsWith('parent_')) {
        const programmeId = Number(key.split('_')[1]);
        if (programmeId && value) {
          await postKnowledgeRepositoryProgrammesData({
            contextual_information: id,
            associate_program: programmeId,
          });
        }
      }

      if (key.startsWith('child_')) {
        const projectId = Number(key.split('_')[1]);
        if (projectId && value) {
          await postKnowledgeRepositoryProjectsData({
            contextual_information: id,
            project: projectId,
          });
        }
      }
    });

    // Wait for all promises to complete
    await Promise.all(projectAndProgrammePromises);
  };

  //! --------------------------------------------------------------------> HELPER FUNC ENDS

  //! --------> Post Knowledge Repository
  const postKnowledgeRepo = async (submittedData: Record<string, any>) => {
    if (isEmpty(submittedData)) return;

    const {
      thematic_field: thematicfield,
      associate_program_project: programmeProjectFormValues,
      documents,
      ...restData
    } = submittedData;

    // post functions
    const responseknowRepo = await postKnowledgeRepositoryData({
      ...restData,
    });

    // //! -----------> post documents ***********************
    if (!isEmpty(documents)) {
      postDocuments(documents, responseknowRepo?.data?.id);
    }

    //! -----------> post thematic fields
    if (!isEmpty(thematicfield)) {
      postThematicFields(thematicfield, responseknowRepo?.data?.id);
    }

    //! ----------> post associated project and programmes
    if (!isEmpty(programmeProjectFormValues)) {
      postAssociatedProjectsAndProgrammes(
        programmeProjectFormValues,
        responseknowRepo?.data?.id,
      );
    }

    // posts end *****************
  };

  //! --------> Patch Knowledge Repository
  const patchKnowledgeRepo = async (formValues: Record<string, any>) => {
    if (isEmpty(formValues)) return;

    const {
      documents,
      associate_program_project: progProject,
      thematic_field: thematicField,
      ...values
    } = formValues;

    let otherValues: Record<string, any> = {};

    Object.entries(values).forEach(async ([key, value]) => {
      otherValues = {
        ...otherValues,
        [key]: value,
      };
    });

    if (dirtyFields.thematic_field) {
      const removedIds: number[] = [];
      const existingIds = actualDetail.thematic_field?.map((field: any) => {
        if (!thematicField.includes(field.thematic_field)) {
          removedIds.push(field.id);
        }
        return field.thematic_field;
      });
      const addedIds = thematicField.filter(
        (newValue: any) => !existingIds.includes(newValue),
      );

      // post thematic field
      const thematicFieldAddPromises = addedIds?.map(async (id: number) => {
        await postKnowledgeRepositoryThematicFieldData({
          contextual_information: knowledgeRepoId,
          thematic_field: id,
        });
      });

      // deleteThematic Field
      const thematicFieldRemovePromises = removedIds?.map(
        async (id: number) => {
          await deleteKnowledgeRepositoryThematicFieldData(id);
        },
      );

      await Promise.all([
        ...thematicFieldAddPromises,
        ...thematicFieldRemovePromises,
      ]);
    }

    if (dirtyFields.associate_program_project) {
      //! empty other_program_project if other checkbox is unchecked
      Object.entries(progProject).forEach(([key, value]) => {
        if (key === 'parent_other' && value === false) {
          otherValues.other_program_project = '';
        }
      });

      const { checkedChildIds, checkedParentIds } =
        getCheckboxCheckedIds(progProject);

      const programmeIdsToRemove: number[] = [];
      const projectIdsToRemove: number[] = [];

      const existingProgrammeIds: number[] = [];
      const existingProjectIds: number[] = [];

      actualDetail.programs.forEach((programme: any) => {
        if (!checkedParentIds.includes(programme?.id)) {
          programmeIdsToRemove.push(programme?.id);
        }
        existingProgrammeIds.push(programme?.id);

        if (programme?.projects?.length) {
          programme?.projects?.forEach((project: any) => {
            if (!checkedChildIds.includes(project.id)) {
              projectIdsToRemove.push(project.obj_id);
            }
            existingProjectIds.push(project.id);
          });
        }
      });

      const programmeIdsToAdd = checkedParentIds.filter(
        checkedId => !existingProgrammeIds.includes(checkedId),
      );
      const projectIDsToAdd = checkedChildIds.filter(
        checkedId => !existingProjectIds.includes(checkedId),
      );

      const programmeAddPromises = programmeIdsToAdd?.map(
        async (id: number) => {
          await postKnowledgeRepositoryProgrammesData({
            contextual_information: knowledgeRepoId,
            associate_program: id,
          });
        },
      );

      const projectAddPromises = projectIDsToAdd?.map(async (id: number) => {
        await postKnowledgeRepositoryProjectsData({
          contextual_information: knowledgeRepoId,
          project: id,
        });
      });

      const programmeRemovePromises = programmeIdsToRemove
        .filter(id => id) // Filter out falsy values
        .map(async (id: number) => {
          await deleteProgrammeData(id);
        });

      const projectRemovePromises = projectIdsToRemove
        .filter(id => id)
        .map(async (id: number) => {
          await deleteProjectData(id);
        });

      await Promise.all([
        ...programmeAddPromises,
        ...projectAddPromises,
        ...programmeRemovePromises,
        ...projectRemovePromises,
      ]);
    }

    const response = await patchKnowledgeRepositoryData({
      ...otherValues,
    });

    await Promise.all([response]);

    //  handle file added ****section
    if (!documents || (documents && documents?.length === 0)) return;
    const FileAddedPromises = documents?.map(
      async (document: Record<string, any>) => {
        if (document?.file instanceof File) {
          const documentFormData = {
            file: document?.file,
            contextual_information: knowledgeRepoId,
          };
          await postKnowledgeRepositoryDocumentData(
            prepareFormData(documentFormData),
          );
        }
      },
    );
    await Promise.all(FileAddedPromises);
    // handle file added*****section**end
  };

  const getDirtyFieldValues = () => {
    const allValues = getValues();
    const dirtyValues: any = {};
    Object.keys(allValues).forEach((key: string) => {
      if (dirtyFields[key as keyof typeof dirtyFields]) {
        dirtyValues[key] = allValues[key as keyof typeof dirtyFields];
      }
    });

    return dirtyValues;
  };

  //! -----------------> Handles Form Submit
  const handleFormSubmit = async () => {
    try {
      const allValues = getValues();
      const editFields = getDirtyFieldValues();
      if (knowledgeRepoId) {
        if (isDirty === false) {
          navigate('/data-bank/knowledge-repository/contextual');
          return;
        }
        await patchKnowledgeRepo(editFields);
      } else {
        await postKnowledgeRepo(allValues);
      }
      toast.success(
        knowledgeRepoId
          ? 'Contextual Information updated successfully'
          : 'Contextual Information added successfully ',
      );
      queryClient.invalidateQueries({
        queryKey: ['knowledge-repository-con-tabledata'],
      });
      reset();
      navigate('/data-bank/knowledge-repository/contextual');
    } catch (err: any) {
      const caughtedError = err?.response?.data?.error[0]?.details;
      toast.error(caughtedError || 'Error occured while saving!');
    }
  };

  // ----------> handles next button and submit data
  const handleNextFormType = async () => {
    switch (formType) {
      case 'basicDetails':
        setFormType(knowledgeRepoFormTypeEnum.DocumentDetails);
        setCompletedForm([...completedForm, 'basicDetails']);
        break;

      case 'documentDetails':
        setFormType(knowledgeRepoFormTypeEnum.UploadDocuments);
        setCompletedForm([...completedForm, 'documentDetails']);
        break;

      case 'uploadDocuments':
        handleFormSubmit();
        break;
      default:
        break;
    }
  };

  // --------> handles previous button
  const handleBack = () => {
    switch (formType) {
      case 'documentDetails':
        setFormType(knowledgeRepoFormTypeEnum.BasicDetails);
        setCompletedForm(
          completedForm.filter(filledForm => filledForm !== 'basicDetails'),
        );
        break;

      case 'uploadDocuments':
        setFormType('documentDetails');
        setCompletedForm(
          completedForm.filter(filledForm => filledForm !== 'documentDetails'),
        );
        break;
      default:
        break;
    }
  };

  return (
    <FormWrapper
      paramId={
        knowledgeRepoId
          ? 'Contextual Information Edit Form'
          : 'Contextual Information Form '
      }
      onHandleBack={() =>
        navigate('/data-bank/knowledge-repository/contextual')
      }
    >
      {/* ----------->   Form Steps */}
      <FormStepsWrapper>
        {knowledgeRepoMultiStepTitle?.map((step, index) => {
          return (
            <MultiStep
              key={step.id}
              id={step.id}
              title={step.name}
              position={index + 1}
              isactive={formType === step.value}
              iscompleted={completedForm.includes(step.value)}
              onClick={handleSubmit(() => {
                setFormType(step.value);
              })}
            />
          );
        })}
      </FormStepsWrapper>

      {/* ---------> Main Form Knowledge Repository */}

      <FormContainer>
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit(handleNextFormType)}
            className="naxatw-flex naxatw-h-full naxatw-flex-col"
          >
            <div className="naxatw-mr-[4px] naxatw-flex-1 naxatw-pt-5">
              {knowledgeRepoId && isKnowledgeRepoFetching ? (
                <div className="naxatw-px-6">
                  <FormSkeleton numRows={5} className="naxatw-w-full" />
                </div>
              ) : (
                <div className="basic-details-form-container scrollbar naxatw-h-[calc(100vh-440px)] naxatw-w-full naxatw-overflow-y-auto naxatw-px-10 lg:naxatw-h-[calc(100vh-290px)]">
                  <FormComponent type={formType} />
                </div>
              )}
            </div>

            <div
              className={`naxatw-flex naxatw-shadow-formshadow  ${
                formType === knowledgeRepoFormTypeEnum.BasicDetails
                  ? 'naxatw-justify-end'
                  : 'naxatw-justify-between'
              } naxatw-px-7 naxatw-py-5`}
            >
              {formType !== knowledgeRepoFormTypeEnum.BasicDetails ? (
                <Button
                  size="normal"
                  type="button"
                  onClick={() => {
                    handleBack();
                  }}
                  variant="link"
                >
                  <Icon name="arrow_back" />
                  Previous
                </Button>
              ) : null}
              <div className="naxatw-cursor-not-allowed">
                <Button
                  type="submit"
                  size="normal"
                  variant="primary"
                  className="disabled:!naxatw-cursor-not-allowed"
                  disabled={
                    isPostKnowledgeRepositoryDataLoading ||
                    isPostKnowledgeRepositoryDocumentLoading ||
                    isPostKnowledgeRepositoryThematicFieldLoading ||
                    isPostKnowledgeRepositoryProgrammesLoading ||
                    isPostKnowledgeRepositoryProjectsLoading ||
                    isPatchKnowledgeRepositoryDataLoading
                  }
                  isLoading={
                    isPostKnowledgeRepositoryDataLoading ||
                    isPostKnowledgeRepositoryDocumentLoading ||
                    isPostKnowledgeRepositoryThematicFieldLoading ||
                    isPostKnowledgeRepositoryProgrammesLoading ||
                    isPostKnowledgeRepositoryProjectsLoading ||
                    isPatchKnowledgeRepositoryDataLoading
                  }
                >
                  {formType === knowledgeRepoFormTypeEnum.UploadDocuments ? (
                    'Save'
                  ) : (
                    <>
                      Next
                      <Icon name="arrow_forward" />
                    </>
                  )}
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </FormContainer>
      {documentToDelete && (
        <Portal>
          <div className="naxatw-absolute naxatw-left-0 naxatw-top-0 naxatw-h-[100vh] naxatw-w-[100vw] naxatw-bg-black naxatw-opacity-30" />
          <DeleteConfirmationOverlay
            onClose={() => setValue('documentToDelete', null)}
            onDelete={() => deleteKnowledgeRepositoryDocument()}
            isError={isDeleteDocumentError}
            isLoading={isDeleteDocumentLoading}
            error={deleteDocumentError}
          />
        </Portal>
      )}
    </FormWrapper>
  );
};

export default hasErrorBoundary(Contextual);
