export const mapFederalType: Record<string, any> = {
  All: '',
  Provincial: 'Province',
  Federal: 'Federal',
};

export const fedOptions = [
  { label: 'All' },
  { label: 'Federal' },
  { label: 'Provincial' },
];

export const includeProvinces = [
  'Lumbini Province',
  'Madhesh Province',
  'Karnali Province',
];
