/* eslint-disable react/no-array-index-key */
import { FlexColumn } from '@Components/common/Layouts';
import Skeleton from '@Components/RadixComponents/Skeleton';
import React from 'react';

function ListCardSk() {
  return (
    <FlexColumn className="naxatw-gap-4 naxatw-overflow-hidden naxatw-rounded naxatw-bg-white naxatw-p-3">
      <Skeleton className="naxatw-h-5 naxatw-w-[70%]" />

      <FlexColumn className="naxatw-gap-4">
        <Skeleton className="naxatw-h-5 naxatw-w-[30%]" />
        <Skeleton className="naxatw-h-5 naxatw-w-[30%]" />
        <Skeleton className="naxatw-h-5 naxatw-w-[30%]" />
      </FlexColumn>
    </FlexColumn>
  );
}

const ListsSk = () => {
  return (
    <div className="list-sk">
      {Array.from({ length: 5 }).map((_, index) => (
        <React.Fragment key={index}>
          <ListCardSk />
        </React.Fragment>
      ))}
    </div>
  );
};

export default ListsSk;
