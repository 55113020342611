import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ExportButtons from '@Components/common/CommonExport/ExportHeader';
import HeadingWithList from '@Components/common/CommonExport/HeadingWithList';
import { getProvincialProfileReport } from '@Services/provincialProfile';
import { useTypedSelector } from '@Store/hooks';
import {
  deletePdfSection,
  resetPdfSection,
  setOriginalDataLists,
} from '@Store/actions/pdfExport';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';

const PdfWorkingAreas = () => {
  const { provinceId } = useParams();
  const dispatch = useDispatch();

  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);

  const workingAreasData = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.workingArea.datalists,
  );

  const showResetButton = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.workingArea.showResetButton,
  );

  const { data: reportListData } = useQuery({
    queryKey: ['reportList-pdf'],
    queryFn: async () =>
      getProvincialProfileReport({ province: provinceId, status: filterBy }),
    select: res => {
      if (!res?.data[0]) return null;
      const workingAreas = res.data[0]?.districts;
      const manipulatedData = workingAreas.map((area: Record<string, any>) => ({
        id: area.district_id,
        heading: area.district_name,
        categories: area.municipalities.map((muni: Record<string, any>) => ({
          id: muni.municipality_id,
          title: muni.municipality_name,
        })),
      }));
      return manipulatedData;
    },
    onSuccess: response => {
      if (!response) return null;
      dispatch(
        setOriginalDataLists({
          page: 'provincialProfile',
          section: 'workingArea',
          value: response,
        }),
      );
      return response;
    },
  });

  return (
    <div className="pdf-working-area-container">
      <ExportButtons
        headerTitle="Working Area"
        isStyleAbsolute={false}
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: 'provincialProfile',
              section: 'workingArea',
            }),
          )
        }
        onHandleReset={() => {
          dispatch(
            resetPdfSection({
              page: 'provincialProfile',
              section: 'workingArea',
            }),
          );
        }}
        showResetButton={showResetButton}
      />
      <HeadingWithList
        dataLists={reportListData || []}
        isExport
        exportLists={[...workingAreasData]}
        exportTitle={{ page: 'provincialProfile', section: 'workingArea' }}
      />
    </div>
  );
};

export default hasErrorBoundary(PdfWorkingAreas);
