/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import formatDate from '@Utils/formatDate';
import {
  getProgrammeProgressDataByProgrammeId,
  getProgrammeProgressDataByComponentId,
} from '@Services/provincialProfile';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { FlexColumn } from '@Components/common/Layouts';
import DefaultCard from '../Cards/Default';

const StatusBar = ({ isExport = false }: { isExport?: boolean }) => {
  const { programmeId, projectId } = useParams();
  const [searchParams] = useSearchParams();
  const componentId = searchParams.get('componentId');

  const { data: programmeProgressData, isLoading: programmeProgressIsLoading } =
    useQuery({
      queryKey: ['programme-progress-data', programmeId],
      queryFn: async () =>
        getProgrammeProgressDataByProgrammeId({ program: programmeId }),
      select: res => {
        if (!res?.data) return [];
        return res.data;
      },
    });

  const { data: componentProgressData } = useQuery({
    queryKey: [
      'programme-progress-data',
      programmeId,
      isExport ? projectId : componentId,
    ],
    queryFn: async () =>
      getProgrammeProgressDataByComponentId({
        program: programmeId,
        project: isExport ? projectId : componentId,
      }),
    enabled: !!componentId || !!projectId,
    select: res => {
      if (!res?.data) return [];
      return res.data;
    },
  });

  const { start_date, end_date, progress_percentage } = projectId
    ? componentProgressData || {}
    : componentId
      ? componentProgressData || {}
      : programmeProgressData || {};

  const formatStartDate = start_date ? formatDate(start_date) : '-';
  const formatEndDate = end_date ? formatDate(end_date) : '-';

  if (programmeProgressIsLoading)
    return <Skeleton className="naxatw-h-[10rem] naxatw-w-full" />;

  return (
    <DefaultCard className={`${isExport ? '!naxatw-p-0' : '!naxatw-p-0'}`}>
      <FlexColumn className="naxatw-gap-2 naxatw-bg-white">
        <h5
          className={`${isExport ? 'fs-sm-semibold' : 'naxatw-text-primary-700'}`}
        >
          {progress_percentage < 0 ? 0 : progress_percentage}%
        </h5>
        <div
          className={`naxatw-overflow-hidden naxatw-rounded-2xl naxatw-bg-[#EFEFEF] ${isExport ? 'naxatw-h-2' : 'naxatw-h-3'}`}
        >
          <div
            style={{
              width: `${progress_percentage < 0 ? 0 : progress_percentage || 0}%`,
            }}
            className={`naxatw-relative naxatw-h-3 ${progress_percentage === 100 ? 'naxatw-rounded-2xl' : 'naxatw-rounded-s-2xl'} naxatw-bg-[#08519C]`}
          />
        </div>
        <div className="naxatw-flex naxatw-justify-between naxatw-self-stretch">
          <span
            className={` ${isExport ? 'fs-sm-semibold naxatw-text-black' : 'naxatw-body-caption naxatw-text-matt-100'}`}
          >
            Start Date{' '}
            <strong
              className={`${isExport ? 'fs-sm-semibold naxatw-text-royal-navy' : 'naxatw-text-primary-700'} `}
            >
              {formatStartDate}
            </strong>
          </span>

          <span
            className={` ${isExport ? 'fs-sm-semibold naxatw-text-black' : 'naxatw-body-caption naxatw-text-matt-100'}`}
          >
            End Date{' '}
            <strong
              className={`${isExport ? 'fs-sm-semibold naxatw-text-royal-navy' : 'naxatw-text-primary-700'} `}
            >
              {formatEndDate}
            </strong>
          </span>
        </div>
      </FlexColumn>
    </DefaultCard>
  );
};

export default StatusBar;
