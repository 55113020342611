/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Image from '@tiptap/extension-image';
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Strike from '@tiptap/extension-strike';
import Heading from '@tiptap/extension-heading';
import Document from '@tiptap/extension-document';
import Link from '@tiptap/extension-link';
import Toolbar from './Toolbar';

type RichTextEditorProps = {
  bindvalue: string;
  onChange?: (content: string) => void;
  editable?: boolean;
  className?: string;
};

const extensions = [
  StarterKit,
  Document,
  ListItem,
  BulletList,
  OrderedList,
  Heading,
  HorizontalRule,
  Strike,
  Link.configure({
    validate: href => /^https?:\/\//.test(href),
  }),
  Image.configure({
    inline: true,
  }),
];

const TiptapEditor = ({
  bindvalue,
  onChange,
  editable = true,
  className,
}: RichTextEditorProps) => {
  const editor = useEditor({
    extensions,
    content: bindvalue,
    onUpdate: ({ editor: editorx }) => {
      if (onChange) {
        onChange(editorx.getHTML());
      }
    },
    editable,
  });

  // setting content
  useEffect(() => {
    if (editor && bindvalue && editor.isEmpty) {
      editor.commands.setContent(bindvalue);
    }
  }, [bindvalue, editor]);

  if (!editor) {
    return null;
  }

  return (
    <div
      className={`naxatw-rounded-md naxatw-border naxatw-border-gray-300 naxatw-bg-white ${className}`}
    >
      {editable && <Toolbar editor={editor} />}
      <EditorContent
        editor={editor}
        className={`${editable ? 'naxatw-min-h-[200px] naxatw-border-none naxatw-p-2' : 'naxatw-min-h-[150px]'}`}
      />
    </div>
  );
};

export default TiptapEditor;
