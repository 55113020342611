import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import Skeleton from '@Components/RadixComponents/Skeleton';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import { getProgramProjectDetails } from '@Services/program';
import SegmentCard from '../Cards/Segment';

type ProjectDetails = {
  aggrement_modality__name: string;
  code: string;
  first_tier_partner__name: string;
};

function DetailItem({
  label,
  value,
}: {
  label: string;
  value?: string | number;
}) {
  return (
    <div className="naxatw-body-sm naxatw-text-matt-200">
      {label}{' '}
      <span className="naxatw-body-btn naxatw-text-primary-700">
        {value || '-'}
      </span>
    </div>
  );
}

function ProgrammeProjectDetailCard() {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('componentId');

  const { data, isLoading } = useQuery({
    queryKey: ['project-project-details', projectId],
    queryFn: async () => getProgramProjectDetails({ project: projectId }),
    select: res =>
      Array.isArray(res.data) ? (res.data[0] as ProjectDetails) : null,
  });

  return (
    <SegmentCard
      title="Project Details"
      iconName="place"
      containerClassName="naxatw-h-full naxatw-border naxatw-overflow-hidden"
    >
      <div className="naxatw-flex naxatw-h-full naxatw-flex-col naxatw-items-start naxatw-gap-5">
        {isLoading ? (
          <FlexColumn className="naxatw-flex naxatw-w-full naxatw-gap-4">
            <Skeleton className="naxatw-h-4 naxatw-w-7/12" />
            <Skeleton className="naxatw-h-4 naxatw-w-4/12" />
            <Skeleton className="naxatw-h-4 naxatw-w-8/12" />
          </FlexColumn>
        ) : (
          <FlexRow className="naxatw-w-full naxatw-items-center">
            {!isLoading && !data ? (
              <NoChartDataComponent
                className="[&>div>img]:naxatw-w-6/12"
                messageStyles="naxatw-text-base"
              />
            ) : (
              <div className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-gap-4">
                <DetailItem
                  label="Agreement Modality"
                  value={data?.aggrement_modality__name}
                />
                <DetailItem label="Component Code" value={data?.code} />
                <DetailItem
                  label="First Tier Partner"
                  value={data?.first_tier_partner__name}
                />
              </div>
            )}
          </FlexRow>
        )}
      </div>
    </SegmentCard>
  );
}

export default ProgrammeProjectDetailCard;
