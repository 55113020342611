import { api, authenticated } from '.';

export const getSectorDropdownList = () =>
  authenticated(api).get('/sector-group-dropdown/');

export const getFileDropdownList = (params: Record<string, any>) =>
  authenticated(api).get('/choice/', { params });

export const getProgramDropdownList = () =>
  authenticated(api).get('/program-dropdown/');

export const getComponentDropdownList = () =>
  authenticated(api).get('/component-dropdown/');

export const getPublicKnowledgeRepositoryData = (params: Record<string, any>) =>
  authenticated(api).get('/knowledge-repository/', { params });

export const getPublicKnowledgeRepositoryConData = (
  params: Record<string, any>,
) => authenticated(api).get('/contextual-information/', { params });

export const getPublicKnowledgeRepositoryEviData = (
  params: Record<string, any>,
) => authenticated(api).get('/evidence-agenda/', { params });

//! new changes api for contextual filter list
export const getFilterConProgrammeList = (params: any) => {
  return authenticated(api).get(
    `/contextual-information-program-filter-list/`,
    {
      params,
    },
  );
};

export const getFilterConSectorList = (params: any) => {
  return authenticated(api).get(`/contextual-information-sector-filter-list/`, {
    params,
  });
};

export const getFilterConFileList = (params: any) => {
  return authenticated(api).get(
    `/contextual-information-file-type-filter-list/`,
    {
      params,
    },
  );
};

//! new changes api for evidence agenda filter list

export const getFilterEviProgrammeList = (params: any) => {
  return authenticated(api).get(`/evidence-agenda-program-filter-list/`, {
    params,
  });
};

export const getFilterEviSectorList = (params: any) => {
  return authenticated(api).get(`/evidence-agenda-sector-filter-list/`, {
    params,
  });
};

export const getFilterEviFileList = (params: any) => {
  return authenticated(api).get(`/evidence-agenda-file-type-filter-list/`, {
    params,
  });
};

export const getFilterEviThemeList = (params: any) => {
  return authenticated(api).get(`/evidence-agenda-theme-filter/`, {
    params,
  });
};

export const getCategoryKnowledgeLibChartList = (params: any) => {
  return authenticated(api).get(`/evidence-agenda-category-chart/`, {
    params,
  });
};

export const getEvidenceAgendaThemesList = (params: any) => {
  return authenticated(api).get(`/evidence-count/`, {
    params,
  });
};

export const getEvidenceAgendaFocusList = (params: any) => {
  return authenticated(api).get(`/geographic-count/`, {
    params,
  });
};

export const getKnowRepoDocuemntLink = (params: Record<string, any>) =>
  authenticated(api).get('/generate-document-url/', { params });

export const getKnowConRepoDocuemntLink = (params: Record<string, any>) =>
  authenticated(api).get('/generate-contextual-document-url/', { params });

export const getKnowEviRepoDocuemntLink = (params: Record<string, any>) =>
  authenticated(api).get('/generate-evidence-agenda-document-url/', { params });
