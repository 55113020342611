import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import { getIconByProvince } from '@Constants/PdfContent';
import { deletePdfSection } from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useDispatch } from 'react-redux';

const ProvinceImage = ({
  provinceData,
}: {
  provinceData: Record<string, any>;
}) => {
  const dispatch = useDispatch();
  const showProvincialSummary = useTypedSelector(
    state =>
      state.pdfExportSlice.provincialProfile.provincialSummary.showSection,
  );

  return (
    <ExportRoundedContainer
      className={`${showProvincialSummary ? 'naxatw-m-auto' : 'naxatw-mr-auto '} icon-container naxatw-relative naxatw-h-full  !naxatw-border-none naxatw-bg-primary-200`}
    >
      <ExportHeader
        onHandleDelete={() => {
          dispatch(
            deletePdfSection({
              page: 'provincialProfile',
              section: 'provinceImage',
            }),
          );
        }}
      />
      <img
        src={getIconByProvince(provinceData?.name)}
        alt="province-icon"
        className="naxatw-h-full naxatw-object-contain"
      />
    </ExportRoundedContainer>
  );
};

export default ProvinceImage;
