/* eslint-disable react/no-unstable-nested-components */
import { useState } from 'react';
import { toast } from 'react-toastify';
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { useTypedSelector } from '@Store/hooks';
import { useNavigate } from 'react-router-dom';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { getUsersGroups, deleteUsersGroups } from '@Services/userSecurity';

const RoleTable = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [roleIDToDelete, setRoleIDToDelete] = useState<number | null>(null);

  const searchTerm = useTypedSelector(
    state => state.common.adminSearchState?.['user-security/role-management'],
  );

  const {
    mutate: deleteRoles,
    isError,
    error,
    isLoading,
  } = useMutation({
    mutationFn: () => deleteUsersGroups({ group_id: roleIDToDelete }),
    onSuccess: () => {
      toast.success('Role deleted successfully');
      setOpenDeleteConfirmation(false);
      queryClient.invalidateQueries({
        queryKey: ['role-tabledata'],
      });
    },
  });

  const columns = [
    {
      header: 'S.N',
      accessorKey: '',
      cell: ({ row }: Record<string, any>) => {
        // eslint-disable-next-line
        return row?.index + 1;
      },
    },
    {
      header: 'NAME',
      accessorKey: 'name',
    },
    {
      header: '',
      accessorKey: 'icon',
      cell: ({ row }: any) => {
        const cellId = row?.original?.id;
        return (
          <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-cursor-pointer naxatw-justify-end naxatw-gap-4">
            <ToolTip
              name="edit"
              message="Edit"
              className="naxatw-text-icon-md naxatw-text-matt-100 hover:naxatw-text-calm-teal"
              iconClick={() =>
                navigate(
                  `/data-bank/user-security/role-management/edit/${cellId}`,
                )
              }
            />
            <ToolTip
              name="delete"
              message="Delete"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-text-red-500"
              iconClick={() => {
                setOpenDeleteConfirmation(prev => !prev);
                setRoleIDToDelete(cellId);
              }}
            />
          </FlexRow>
        );
      },
    },
  ];
  return (
    <div className="naxatw-h-[calc(100%-7rem)] naxatw-w-full lg:naxatw-h-[calc(100%-4.5rem)]">
      <DataTable
        columns={columns}
        queryKey="role-tabledata"
        queryFn={getUsersGroups}
        initialState={{
          paginationState: {
            pageIndex: 0,
            pageSize: 25,
          },
        }}
        searchInput={searchTerm || ''}
        sortByKey
      />
      {openDeleteConfirmation && (
        <Portal>
          <DeleteConfirmationOverlay
            onClose={() => setOpenDeleteConfirmation(false)}
            onDelete={() => deleteRoles()}
            isError={isError}
            isLoading={isLoading}
            error={error}
          />
        </Portal>
      )}
    </div>
  );
};

export default hasErrorBoundary(RoleTable);
