import { FormControl, Label } from '@Components/common/FormUI';
import Icon from '@Components/common/Icon';
import { FlexRow } from '@Components/common/Layouts';
import { MultipleDatePickerProps } from '@Constants/interface';
import { cn } from '@Utils/index';
import SingleDatePicker from '../SingleDatePicker';

type Props = Omit<MultipleDatePickerProps, 'clearDateRange'> & {
  className?: string;
  startDateLabel?: string;
  endDateLabel?: string;
  showOnIconClick?: boolean;
  onShowIconClick?: () => void;
  onClearDate?: () => void;
  hideCloseIcon?: boolean;
  dateWrapperStyles?: string;
  closeBtnStyles?: string;
};

function handleDateChange(value: string, onDateChange: Function) {
  if (onDateChange) {
    onDateChange(value);
  }
}

export default function MultipleDatePicker({
  className,
  dateIcon = 'event',
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  startDateLabel,
  endDateLabel,
  showOnIconClick = false,
  onShowIconClick,
  onClearDate,
  hideCloseIcon = false,
  dateWrapperStyles = '',
  closeBtnStyles = '',
}: Props) {
  return (
    <FlexRow
      className={cn(
        'native_multidate_picker naxatw-w-full naxatw-justify-end',
        className,
      )}
    >
      {!showOnIconClick && (
        <FlexRow className="naxatw-relative naxatw-flex-1 naxatw-gap-4">
          <FormControl
            className={`naxatw-flex-1 naxatw-gap-2 ${dateWrapperStyles}`}
          >
            <Label>{startDateLabel || 'Date From'}</Label>
            <SingleDatePicker
              name="start_date"
              value={startDate || ''}
              onChange={eve => {
                handleDateChange(eve?.target?.value, setStartDate);
              }}
            />
          </FormControl>
          <FormControl
            className={`naxatw-flex-1 naxatw-gap-2 ${dateWrapperStyles}`}
          >
            <Label>{endDateLabel || 'Date To'}</Label>
            <SingleDatePicker
              name="end_date"
              value={endDate || ''}
              onChange={eve => handleDateChange(eve?.target?.value, setEndDate)}
            />
          </FormControl>
          {!hideCloseIcon && (
            <button
              type="button"
              className={`naxatw-absolute naxatw-right-0 naxatw-top-[2px] naxatw-rounded-full naxatw-bg-gray-100 naxatw-p-[.1rem] hover:naxatw-bg-red-200 ${closeBtnStyles}`}
              title="Clear Date"
              onClick={onClearDate}
            >
              <Icon
                name="close"
                className="naxatw-text-xs naxatw-text-gray-500 group-hover:naxatw-text-secondary-500"
              />
            </button>
          )}
        </FlexRow>
      )}
      {showOnIconClick && (
        <>
          <button
            type="button"
            className="naxatw-flex naxatw-bg-transparent naxatw-text-gray-600 hover:naxatw-text-gray-700"
            onClick={onShowIconClick}
          >
            <Icon
              name={dateIcon}
              className="naxatw-flex naxatw-h-6 naxatw-w-6 naxatw-shrink-0 naxatw-items-center naxatw-justify-center naxatw-text-[24px] naxatw-font-light naxatw-leading-6 naxatw-text-matt-200 hover:naxatw-text-secondary-500"
            />
          </button>
        </>
      )}
    </FlexRow>
  );
}
