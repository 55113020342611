/* eslint-disable import/prefer-default-export */
import { z } from 'zod';

export const FeedbackFormValidationSchema = z.object({
  feedback_type: z.string().min(1, 'Feedback Type is Required'),
  email: z
    .string()
    .refine(val => val === '' || /\S+@\S+\.\S+/.test(val), {
      message: 'Invalid email format',
    })
    .optional(),
  message: z.string().min(1, 'Message is Required'),
});
