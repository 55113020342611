/* eslint-disable no-unused-vars */
import CustomBarChart from '@Components/common/Charts/BarChart';
import ChartXAxis from '@Components/common/CommonExport/Chart/ChartXAxis';
import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { stakeholderByfillColors } from '@Constants/programmeDetails';
import { getGovernmentStakeHoldersDataPro } from '@Services/mainDashboard';
import { getStakeholderChartDataByProgramProject } from '@Services/provincialProfile';
import { deletePdfSection, resetPdfSection } from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import isEmpty from '@Utils/isEmpty';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const PdfStakeholderChart = () => {
  const { projectId, programmeId } = useParams();
  const dispatch = useDispatch();
  const pageType = projectId ? 'projectDetails' : 'programmeDetails';

  const selectedTabCharts = useTypedSelector(
    state => state.programmeDetails.stakeholderByProjectSelectedTab,
  );

  const isAllTab = selectedTabCharts === 'All';

  const { data: chartData } = useQuery({
    queryKey: ['stakeholder-chart-report', programmeId, selectedTabCharts],
    queryFn: () =>
      isAllTab
        ? getStakeholderChartDataByProgramProject({
            program: programmeId,
            project: projectId,
          })
        : getGovernmentStakeHoldersDataPro({
            program: programmeId,
            project: projectId,
          }),
    select: res => {
      if (!res.data || !res.data[0]) return [];
      return res?.data?.[0]?.stakeholders?.map(
        (stakeholder: Record<string, any>) => {
          return isAllTab
            ? {
                value1: stakeholder?.national,
                value2: stakeholder?.sub_national,
                name: stakeholder?.type,
              }
            : {
                name: stakeholder?.stakeholder_type,
                value: stakeholder?.value,
              };
        },
      );
    },
  });

  if (chartData && isEmpty(chartData)) return null;

  const isSelectedChartAll = selectedTabCharts === 'All';

  return (
    <ExportRoundedContainer
      className="pdf-stakeholder-chart naxatw-relative"
      style={{
        pageBreakInside: 'avoid',
      }}
    >
      <ExportHeader
        isStyleAbsolute={false}
        headerTitle={
          projectId
            ? `${selectedTabCharts} Stakeholders`
            : `Number of ${selectedTabCharts} Stakeholders by Project`
        }
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: pageType,
              section: 'stakeholderChart',
            }),
          )
        }
        onHandleReset={() => {
          dispatch(
            resetPdfSection({
              page: pageType,
              section: 'stakeholderChart',
            }),
          );
        }}
        className="naxatw-border-b naxatw-border-b-pdf-border !naxatw-p-2"
      />

      <div className="stakeholder-chart-wrapper naxatw-py-3">
        <p className="fs-xs-medium naxatw-absolute naxatw-left-[24px] naxatw-top-[40%] naxatw-origin-center -naxatw-translate-x-2/4 -naxatw-rotate-90 naxatw-capitalize">
          project
        </p>
        <CustomBarChart
          data={chartData || []}
          height="16rem"
          barSize={30}
          hoverTitle={
            selectedTabCharts === 'All'
              ? ['National', 'Subnational']
              : undefined
          }
          fills={stakeholderByfillColors}
          wrapXAxis
          isExport
        />

        <ChartXAxis
          name="Stakeholder Type"
          doubleXAxis
          fills={stakeholderByfillColors}
          data={['National', 'Sub - National']}
        />
      </div>
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(PdfStakeholderChart);
