import ProvincialProfile from '@Components/ProvincialProfile';

const ProvincePage = () => {
  return (
    <div className="naxatw-mx-auto naxatw-w-full naxatw-max-w-[90rem]  naxatw-bg-[#F4F7FE] naxatw-px-6 xl:naxatw-px-0">
      <ProvincialProfile />
    </div>
  );
};

export default ProvincePage;
