/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Controller, useFormContext } from 'react-hook-form';
import ErrorMessage from '@Components/common/ErrorMessage';
import { FormControl, Input, RadioButton } from '@Components/common/FormUI';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn } from '@Components/common/Layouts';
import { isPublicOptions } from '@Constants/FormConstants/knowledgeRepoConstants';
import { getStatusOptions } from '@Services/knowledgeRepository';
import { prepareDataForDropdown } from '@Utils/index';
// import { getUserGroups } from '@Services/userSecurity';
import DropDown from '@Components/common/DropDown';
import FileUpload from '@Components/common/FormUI/Upload';
import isEmpty from '@Utils/isEmpty';

const UploadDocuments = () => {
  const {
    formState: { errors },
    register,
    getValues,
    control,
    setValue,
    watch,
  } = useFormContext();

  const formProps = {
    register,
    control,
    getValues,
    setValue,
    errors,
    watch,
  };

  // const [watchIsPublic] = useWatch({
  //   control,
  //   name: ['is_public'],
  // });

  const { data: statusOptions } = useQuery({
    queryKey: ['knowledgerepo-statusOptions'],
    queryFn: () =>
      getStatusOptions({
        type: 'evidence_agenda_status',
      }),
    select: data => prepareDataForDropdown(data?.data || [], 'name'),
    onError: () => toast.error('Failed to fetch Status Options'),
  });

  // const { data: userGroupsDropdown } = useQuery({
  //   queryKey: ['user-groups-dropdown'],
  //   queryFn: () => getUserGroups(),
  //   enabled: watchIsPublic === 'false',
  //   select: data => {
  //     const response = data?.data;
  //     return prepareDataForDropdown(response, 'name');
  //   },
  // });

  return (
    <FlexColumn className="document-details naxatw-mb-5 naxatw-w-full naxatw-space-y-5">
      {/* -----------> Status */}

      <FormControl className="naxatw-mt-5">
        <InputLabel label="Status" className="naxatw-mb-1" />
        <Controller
          control={control}
          name="evidence_agenda_status"
          defaultValue=""
          render={({ field: { value, onChange } }) => (
            <DropDown
              placeholder="Status"
              className="naxatw-w-full"
              options={statusOptions || []}
              value={value}
              choose="value"
              onChange={onChange}
            />
          )}
        />
      </FormControl>

      {/* -----------> Tags */}
      <FormControl>
        <InputLabel label="Tags" className="naxatw-mb-1" />
        <Input
          type="text"
          placeholder="Enter tags for the document & if multiple tags, seperate with comma"
          {...register('tags')}
        />
      </FormControl>

      {/* -----------> links */}
      <FormControl>
        <InputLabel
          label="Links"
          className="naxatw-mb-1"
          tooltipMessage="A document or a link to the document is required"
        />
        <Input
          type="text"
          placeholder="Enter Document Links"
          {...register('document_link', {
            pattern: {
              value: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
              message: 'Please enter a valid URL.',
            },
          })}
        />
        {errors?.document_link?.message && (
          <ErrorMessage
            message={errors?.document_link?.message as string}
            className="naxatw-mt-1"
          />
        )}
      </FormControl>

      {/* -----------> documents */}
      <FormControl>
        <InputLabel label="Upload Documents" className="naxatw-mb-1" astric />
        <Controller
          control={control}
          name="documents"
          rules={{
            validate: doc => {
              const { document_link } = getValues();
              if (!document_link && isEmpty(doc)) {
                return 'Either links or documents are required.';
              }
              return true;
            },
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <FileUpload
                placeholder="Please upload Documents. (PDF, XLS, DOCS, CSV File) ( Less than 50MB )"
                multiple
                data={value}
                name="documents"
                fileAccept=".pdf, .xls, .xlsx, .doc, .docx, .txt, .csv"
                onChange={() => onChange(getValues?.()?.documents)}
                maxSize={50}
                {...formProps}
              />
            );
          }}
        />
        {errors?.documents?.message && (
          <ErrorMessage
            message={errors?.documents?.message as string}
            className="naxatw-mt-1"
          />
        )}
      </FormControl>
      {/* -----------> is Evidence Agenda  From */}

      <FormControl>
        <InputLabel label="Evidence Agenda is" className="naxatw-mb-1" astric />
        <Controller
          control={control}
          name="is_public"
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: 'Evidence Agenda is Required',
            },
          }}
          render={({ field: { value, onChange } }) => (
            <RadioButton
              onChangeData={onChange}
              options={isPublicOptions || []}
              value={value}
            />
          )}
        />
        {errors?.is_public?.message && (
          <ErrorMessage
            message={errors?.is_public?.message as string}
            className="naxatw-mt-1"
          />
        )}
      </FormControl>

      {/* -----------> Role */}
      {/* {watchIsPublic === 'false' ? (
        <FormControl>
          <span className="naxatw-mb-1 naxatw-text-[14px] naxatw-leading-5 naxatw-text-matt-100">
            Document access to
          </span>
          <InputLabel label="Role" className="naxatw-mb-2" astric />
          <Controller
            control={control}
            name="role"
            defaultValue=""
            rules={
              watchIsPublic === 'false'
                ? {
                    required: {
                      value: true,
                      message: 'Role is Required',
                    },
                  }
                : {}
            }
            render={({ field: { value, onChange } }) => (
              <DropDown
                placeholder="Role"
                className="naxatw-w-full"
                options={userGroupsDropdown || []}
                value={value}
                onChange={onChange}
                multiple
              />
            )}
          />
          {errors?.role?.message && (
            <ErrorMessage
              message={errors?.role?.message as string}
              className="naxatw-mt-1"
            />
          )}
        </FormControl>
      ) : null} */}

      {/* to Contact */}
      <FormControl>
        <InputLabel label="Contact To" className="naxatw-mb-1" />
        <Input {...register('username')} placeholder="Enter Contact To" />
      </FormControl>

      {/* email */}
      <FormControl>
        <InputLabel label="Contact Email" className="naxatw-mb-1" />
        <Input
          {...register('email', {
            validate: value =>
              !value ||
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) ||
              'Invalid email format',
          })}
          placeholder="Enter Contact Email"
        />
        {errors?.email && (
          <ErrorMessage
            message={errors?.email?.message as string}
            className="naxatw-mt-1"
          />
        )}
      </FormControl>
    </FlexColumn>
  );
};

export default hasErrorBoundary(UploadDocuments);
