import lumbini from '@Assets/images/PDF/Lumbini-pdf.svg';
import madhesh from '@Assets/images/PDF/Madhesh-pdf.svg';
import karnali from '@Assets/images/PDF/Karnali-pdf.svg';

export function getIconByProvince(province: string): string {
  switch (province) {
    case 'Lumbini Province':
      return lumbini;

    case 'Madhesh Province':
      return madhesh;

    case 'Karnali Province':
      return karnali;

    default:
      return '❓';
  }
}

export const populationData = [
  'population',
  'capital',
  'provincial-assembly',
  'chief-minister',
  'principal-secretary',
  'speaker',
  'no-of-ministries',
];

export const contextualData = [
  'no-of-palika',
  'no-of-district',
  'household-size',
  'population-density',
  'male',
  'female',
  'absentee-abroad',
];

export const provinicialSummaryData = [
  'program',
  'project',
  'working-areas',
  'partner',
  'stakeholder',
  'marker',
  'sector',
];

export const contextualDataTwo = [
  'no-of-palika',
  'no-of-district',
  'household-size',
  'population-density',
  'male',
  'female',
  'absentee-abroad',
];

export const getKeysByFilter: Record<string, string> = {
  '': 'All',
  Ongoing: 'On-Going',
  Completed: 'Completed',
};

export const mapPdfMapCount: Record<string, string> = {
  project: 'Project',
  program: 'Programme',
  project_sector: 'Sector',
};

//  ------------------>   Programme Details Contents

export const programmeSummaryData = [
  'project',
  'working-areas',
  'working-areas-chart',
  'partner',
  'stakeholder',
  'marker',
  'sector',
  'budget',
  'budget-chart',
];

//  ------------------>   Project Details Contents

export const projectSummaryData = [
  'project-details',
  'working-areas',
  'working-areas-timeline',
  'marker',
  'sector',
  'budget',
];

//  ------------------>  Bek Contents

export const bekSummaryData = [
  'programme',
  'project',
  'partner',
  'stakeholders',
  'working-areas',
  'project-chart',
  'geographic-chart',
  'marker',
  'sector',
  'budget',
];

//  --------------- >  palika content

export const palikaSummaryData = [
  'programme',
  'project',
  'partner',
  'markers',
  'sectors',
];

export const palikaContextualData = [
  'household-size',
  'population-density',
  'male',
  'female',
  'absentee-abroad',
  'literacy_rate',
  'female_headed_household',
  'economically-active-population',
  'disability',
];
