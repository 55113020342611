import Icon from '@Components/common/Icon';
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  Arrow,
} from '@radix-ui/react-tooltip';
import { cn } from '@Utils/index';

interface IExternalFilterButton {
  isActive: boolean;
  onFilterButtonClick: () => void;
  filterCount: number;
  iconClassName?: string;
  wrapperClassName?: string;
  side?: 'right' | 'top' | 'bottom' | 'left';
}

const ExternalFilterButton = ({
  isActive,
  onFilterButtonClick,
  filterCount,
  iconClassName,
  wrapperClassName,
  side = 'bottom',
}: IExternalFilterButton) => {
  return (
    <div className="report-filter-btn-container">
      <TooltipProvider delayDuration={100}>
        <Tooltip>
          <TooltipTrigger className="naxatw-w-full">
            <div
              role="button"
              onKeyDown={() => {}}
              tabIndex={0}
              className="filter-btn naxatw-group naxatw-relative naxatw-cursor-pointer"
              onClick={() => {
                onFilterButtonClick();
              }}
            >
              <div
                className={cn(
                  `inner naxatw-rounded-lg naxatw-border naxatw-border-solid naxatw-border-[#D0D5DD] ${isActive ? 'naxatw-bg-secondary-500' : 'naxatw-bg-white'} naxatw-p-2 naxatw-shadow-xs naxatw-transition-colors naxatw-duration-200 naxatw-ease-in group-hover:naxatw-bg-secondary-500`,
                  wrapperClassName,
                )}
              >
                <Icon
                  name="filter_alt"
                  className={cn(
                    `${isActive ? 'naxatw-text-white' : 'naxatw-text-matt-100'} naxatw-transition-colors naxatw-duration-200 naxatw-ease-in group-hover:naxatw-text-white`,
                    iconClassName,
                  )}
                />
                {isActive && (
                  <span className="naxatw-absolute naxatw-right-0 naxatw-top-0 naxatw-flex naxatw-h-6 naxatw-w-6 -naxatw-translate-y-2/4 naxatw-translate-x-2/4 naxatw-items-center naxatw-justify-center naxatw-rounded-[6.25rem] naxatw-border-[3px] naxatw-border-solid naxatw-border-[#EAECF0] naxatw-bg-secondary-500 naxatw-p-[0.0625rem] naxatw-text-xs naxatw-font-bold naxatw-leading-4 naxatw-text-white">
                    {filterCount || 0}
                  </span>
                )}
              </div>
            </div>
          </TooltipTrigger>
          <TooltipContent side={side} className="!naxatw-z-[99]">
            <div className="message naxatw-z-[99999999999] naxatw-rounded-sm naxatw-bg-secondary-500 naxatw-px-3 naxatw-py-1 naxatw-text-xs naxatw-font-medium naxatw-text-white">
              {isActive ? 'Clear Filter' : 'Apply Filter'}
            </div>
            <Arrow
              className="TooltipArrow naxatw-rounded"
              style={{ fill: '#417EC9' }}
            />
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

export default ExternalFilterButton;
