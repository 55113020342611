export function convertFilterData(inputData: Record<string, any>) {
  return [
    {
      id: 1,
      category: 'Programme',
      subcategories: inputData.program.map((prog: Record<string, any>) => ({
        id: prog.program__id,
        categoryId: 1,
        subcategory: prog.program__name,
        checked: false,
        filterItems: prog.projects
          .filter(
            (pro: Record<string, any>) => pro.id !== null && pro.name !== null,
          )
          .map((comp: Record<string, any>) => ({
            id: comp.id,
            filter: comp.name,
            subcategoryId: prog.program__id,
            checked: false,
          })),
      })),
    },
    {
      id: 2,
      category: 'First Tier Partner',
      subcategories: inputData.first_tier_partner.map(
        (partner: Record<string, any>) => ({
          id: partner.project__first_tier_partner__id,
          categoryId: 2,
          subcategory: partner.project__first_tier_partner__name,
          checked: false,
        }),
      ),
    },
    {
      id: 3,
      category: 'Sector',
      subcategories: inputData.sector.map((sector: Record<string, any>) => ({
        id: sector.project__project_sector__sector__id,
        categoryId: 3,
        subcategory: sector.project__project_sector__sector__name,
        checked: false,
        filterItems: [],
      })),
    },

    {
      id: 4,
      category: 'Marker / Group',
      subcategories: inputData.marker.map((marker: Record<string, any>) => ({
        id: marker.id,
        categoryId: 4,
        subcategory: marker.name,
        checked: false,
        filterItems: marker.markers
          .filter(
            (submarker: Record<string, any>) =>
              submarker.id !== null && submarker.name !== null,
          )
          .map((submarker: Record<string, any>) => ({
            id: submarker.id,
            filter: submarker.name,
            subcategoryId: marker.id,
            checked: false,
          })),
      })),
    },
    {
      id: 5,
      category: 'Province',
      subcategories: inputData.province.map(
        (province: Record<string, any>) => ({
          id: province.province__id,
          categoryId: 5,
          subcategory: province.province__name,
          checked: false,
          filterItems: [],
        }),
      ),
    },
  ];
}

export function updateFilterState(
  fetchedState: Record<string, any>[],
  filterState: Record<string, any> | null,
) {
  return filterState?.map((currentCategory: Record<string, any>) => {
    const fetchedCategory = fetchedState.find(
      category => category.id === currentCategory.id,
    );

    if (!fetchedCategory) {
      return currentCategory;
    }
    const currentSubcategoryMap: Record<string, any> = {};
    currentCategory.subcategories?.forEach((subcategory: { id: string }) => {
      currentSubcategoryMap[subcategory.id] = subcategory;
    });

    const updatedSubcategories = fetchedCategory.subcategories
      .map((fetchedSubcategory: Record<string, any>) => {
        const existingSubcategory =
          currentSubcategoryMap[fetchedSubcategory.id];

        if (existingSubcategory) {
          return {
            ...existingSubcategory,
            filterItems: fetchedSubcategory.filterItems?.map(
              (fetchedFilter: Record<string, any>) => {
                const existingFilter = existingSubcategory?.filterItems.find(
                  (filter: Record<string, any>) =>
                    filter.id === fetchedFilter.id,
                );

                return (
                  existingFilter || {
                    ...fetchedFilter,
                    checked: !!existingSubcategory.checked,
                  }
                );
              },
            ),
          };
        }
        return { ...fetchedSubcategory };
      })
      ?.sort((a: Record<string, any>, b: Record<string, any>) => {
        return b.checked - a.checked;
      });

    return {
      ...currentCategory,
      subcategories: updatedSubcategories,
    };
  });
}

export function convertKnowledgeLibraryProgrammaticFilterData(
  inputData: Record<string, any>,
) {
  return [
    {
      id: 1,
      category: 'Thematic Field',
      subcategories: inputData.sector.map((sector: Record<string, any>) => ({
        id: sector.knowledge_repository_thematic_field__thematic_field__id,
        categoryId: 1,
        subcategory:
          sector.knowledge_repository_thematic_field__thematic_field__name,
        checked: false,
        filterItems: [],
      })),
    },
    {
      id: 2,
      category: 'File Type',
      subcategories: inputData.file_type.map(
        (fileType: Record<string, any>) => ({
          categoryId: 2,
          id: fileType.file_type,
          subcategory: fileType.file_type,
          cheked: false,
          filterItems: [],
        }),
      ),
    },
    {
      id: 3,
      category: 'Programme',
      subcategories: inputData?.program?.map((prog: Record<string, any>) => ({
        id: prog?.knowledge_repository_program__associate_program__id,
        categoryId: 3,
        subcategory:
          prog?.knowledge_repository_program__associate_program__name,
        checked: false,
        filterItems: prog.projects
          .filter(
            (pro: Record<string, any>) => pro.id !== null && pro.name !== null,
          )
          .map((comp: Record<string, any>) => ({
            id: comp.id,
            filter: comp.name,
            subcategoryId:
              prog?.knowledge_repository_program__associate_program__id,
            checked: false,
          })),
      })),
    },
  ];
}

//! contextual Filters
export function convertKnowledgeLibraryContextualFilterData(
  inputData: Record<string, any>,
) {
  return [
    {
      id: 1,
      category: 'Thematic Field',
      subcategories: inputData.sector.map((sector: Record<string, any>) => ({
        id: sector.contextual_information_thematic_field__thematic_field__id,
        categoryId: 1,
        subcategory:
          sector.contextual_information_thematic_field__thematic_field__name,
        checked: false,
        filterItems: [],
      })),
    },
    {
      id: 2,
      category: 'Province',
      subcategories: inputData?.province?.map((pro: Record<string, any>) => ({
        categoryId: 2,
        id: pro?.id,
        subcategory: pro?.name,
        cheked: false,
        filterItems: [],
      })),
    },
    {
      id: 3,
      category: 'File Type',
      subcategories: inputData.file_type.map(
        (fileType: Record<string, any>) => ({
          categoryId: 3,
          id: fileType.file_type,
          subcategory: fileType.file_type,
          cheked: false,
          filterItems: [],
        }),
      ),
    },
    {
      id: 4,
      category: 'Programme',
      subcategories: inputData?.program?.map((prog: Record<string, any>) => ({
        id: prog?.contextual_information_program__associate_program__id,
        categoryId: 4,
        subcategory:
          prog?.contextual_information_program__associate_program__name,
        checked: false,
        filterItems: prog.projects
          .filter(
            (pro: Record<string, any>) => pro.id !== null && pro.name !== null,
          )
          .map((comp: Record<string, any>) => ({
            id: comp.id,
            filter: comp.name,
            subcategoryId:
              prog?.contextual_information_program__associate_program__id,
            checked: false,
          })),
      })),
    },
  ];
}

//! evidence agenda filters
export function convertEvidenceKnowledgeLibraryFilterData(
  inputData: Record<string, any>,
) {
  return [
    {
      id: 1,
      category: 'Category',
      subcategories: inputData?.category?.map((cat: Record<string, any>) => ({
        id: cat?.id,
        categoryId: 1,
        subcategory: cat?.name,
        checked: false,
        filterItems: cat.sub_category
          .filter(
            (pro: Record<string, any>) => pro.id !== null && pro.name !== null,
          )
          .map((subCat: Record<string, any>) => ({
            id: subCat.id,
            filter: subCat.name,
            subcategoryId: cat?.id,
            checked: false,
          })),
      })),
    },
    {
      id: 2,
      category: 'Team Ownership',
      subcategories: inputData.teams?.map((team: Record<string, any>) => ({
        categoryId: 2,
        subcategory: team?.name,
        id: team?.name,
        checked: false,
      })),
    },
    {
      id: 3,
      category: 'Geographic Focus',
      subcategories: inputData.geographicFocus.map(
        (province: Record<string, any>) => ({
          categoryId: 3,
          id: province?.geographic_focus_evidence_agenda__geographic_focus,
          subcategory:
            province?.geographic_focus_evidence_agenda__geographic_focus__name,
          checked: false,
        }),
      ),
    },
    {
      id: 4,
      category: 'Theme',
      subcategories: inputData.themes.map(
        (theme: Record<string, string | number>) => ({
          categoryId: 4,
          subcategory:
            theme?.gesi_evidence_agenda__gesi_option__agenda_evidence_category__name ||
            theme?.knowledge_library_agenda_evidence__agenda_evidence__name,
          id:
            theme?.gesi_evidence_agenda__gesi_option__agenda_evidence_category ||
            theme?.knowledge_library_agenda_evidence__agenda_evidence,
          isGes:
            !!theme?.gesi_evidence_agenda__gesi_option__agenda_evidence_category__name,
          value:
            theme?.gesi_evidence_agenda__gesi_option__agenda_evidence_category ||
            theme?.knowledge_library_agenda_evidence__agenda_evidence,
          checked: false,
          filterItems: [],
        }),
      ),
    },
    {
      id: 5,
      category: 'Status',
      subcategories: inputData.evidenceStatus.map(
        (status: Record<string, any>) => ({
          categoryId: 5,
          id: status?.name,
          subcategory: status?.name,
          checked: false,
          filterItems: [],
        }),
      ),
    },
    {
      id: 6,
      category: 'Programme',
      subcategories: inputData?.program?.map((prog: Record<string, any>) => ({
        id: prog?.evidence_agenda_program__associate_program__id,
        categoryId: 6,
        subcategory: prog?.evidence_agenda_program__associate_program__name,
        checked: false,
        filterItems: prog.projects
          .filter(
            (pro: Record<string, any>) => pro.id !== null && pro.name !== null,
          )
          .map((comp: Record<string, any>) => ({
            id: comp.id,
            filter: comp.name,
            subcategoryId: prog?.evidence_agenda_program__associate_program__id,
            checked: false,
          })),
      })),
    },
    {
      id: 7,
      category: 'File Type',
      subcategories: inputData.file_type.map(
        (fileType: Record<string, any>) => ({
          categoryId: 7,
          id: fileType.file_type,
          subcategory: fileType.file_type,
          cheked: false,
          filterItems: [],
        }),
      ),
    },
  ];
}
