import DropDown from '@Components/common/DropDown';
import ErrorMessage from '@Components/common/ErrorMessage';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import { FormControl, Input } from '@Components/common/FormUI';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import InputLabel from '@Components/common/InputLabel';
import { Button } from '@Components/RadixComponents/Button';
import { propTypeClose } from '@Constants/Types/FormTypes/userSecurity';
import {
  getCategory,
  getSubCategoryById,
  patchSubCategory,
  postSubCategory,
} from '@Services/category';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { prepareDataForDropdown } from '@Utils/index';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const SubCategoryForm = ({ onClose }: propTypeClose) => {
  const { subCatId } = useParams();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    control,
    setValue,

    getValues,
    formState: { isDirty, isSubmitting, errors, dirtyFields },
  } = useForm({
    defaultValues: { name: '', category: '' },
    mode: 'onChange',
  });

  // category dropdown options
  const { data: categoriesOptions, isFetching: isOptionsLoading } = useQuery({
    queryKey: ['category-dropdonw-options'],
    queryFn: () => getCategory({}),
    select: response => {
      if (!response?.data?.results) return [];
      const subCatOptions = response.data.results;
      const finalOptions = prepareDataForDropdown(subCatOptions, 'name');
      return finalOptions;
    },
  });

  // get sub cat if edit
  const { isFetching: isCatLoading } = useQuery({
    queryKey: ['sub-category-by-id', subCatId],
    enabled: !!subCatId,
    queryFn: () => getSubCategoryById(Number(subCatId)),
    select: responseData => {
      if (!responseData?.data) return null;
      return responseData.data;
    },
    onError: () => toast.error('Error Occured!. Please try again.'),
    onSuccess: catData => {
      if (!catData) return;
      Object.keys(catData).forEach((key: any) => {
        setValue(key, catData[key]);
      });
    },
  });

  // post subcategory
  const { mutateAsync: postSubCategoryData, isLoading: isSubCategoryLoading } =
    useMutation({
      mutationKey: ['post-sub-category'],
      mutationFn: async (payloadData: Record<string, any>) =>
        postSubCategory(payloadData),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['subcategory-tabledata'],
        });
        toast.success('SubCategory added Succesfully');
        onClose();
      },
    });

  // patch category
  const {
    mutateAsync: patchSubCategoryData,
    isLoading: isSubCategoryPatching,
  } = useMutation({
    mutationKey: ['patch-sub-category'],
    mutationFn: async (payloadData: Record<string, any>) =>
      patchSubCategory(Number(subCatId), payloadData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['subcategory-tabledata'],
      });
      toast.success('Sub Category updated Succesfully');
      onClose();
    },
  });

  const getDirtyFieldValues = () => {
    const allValues = getValues();
    const dirtyValues: any = {};
    Object.keys(allValues).forEach((key: string) => {
      if (dirtyFields[key as keyof typeof dirtyFields]) {
        dirtyValues[key] = allValues[key as keyof typeof dirtyFields];
      }
    });

    return dirtyValues;
  };

  const patchSubCatData = async () => {
    if (isDirty === false) {
      onClose();
    }
    const editFields = getDirtyFieldValues();
    patchSubCategoryData(editFields);
  };

  const hadleFormSubmit = async (submittedData: Record<string, any>) => {
    try {
      if (subCatId) {
        await patchSubCatData();
      } else {
        await postSubCategoryData(submittedData);
      }
    } catch (err: any) {
      const caughtError = err?.response?.data?.message;
      toast.error(caughtError || 'Something Went Wrong');
    }
  };

  return (
    <div className="form-category">
      <form onSubmit={handleSubmit(hadleFormSubmit)}>
        <div className='form-outer naxatw-shadow-light" naxatw-px-7 naxatw-py-5'>
          {(subCatId && isCatLoading) || isOptionsLoading ? (
            <FormSkeleton numRows={2} className="naxatw-w-full" />
          ) : (
            <div className="form-inner">
              <FormControl>
                <InputLabel
                  label="Sub Category"
                  astric
                  className="naxatw-mb-2"
                />
                <Input
                  placeholder="Enter Sub Category"
                  {...register('name', {
                    required: 'Sub Category is required',
                    minLength: {
                      value: 1,
                      message: 'Sub Category is required',
                    },
                  })}
                />
                {errors?.name?.message && (
                  <ErrorMessage
                    message={errors?.name?.message as string}
                    className="naxatw-mt-1"
                  />
                )}
              </FormControl>
              <FormControl className="naxatw-mt-6">
                <InputLabel label="Category" astric className="naxatw-mb-2" />
                <Controller
                  control={control}
                  name="category"
                  rules={{
                    validate: subCatValue => {
                      if (!subCatValue) {
                        return 'Category is required';
                      }
                      return true;
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <DropDown
                      placeholder="Select Category"
                      className="naxatw-w-full"
                      options={categoriesOptions || []}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors?.category?.message && (
                  <ErrorMessage
                    message={errors?.category?.message as string}
                    className="naxatw-mt-1"
                  />
                )}
              </FormControl>
            </div>
          )}{' '}
        </div>

        <div className="naxatw-flex naxatw-justify-center naxatw-py-5 naxatw-shadow-formshadow">
          <Button
            size="normal"
            variant="primary"
            className="naxatw-px-4 naxatw-py-2"
            type="submit"
            isLoading={
              isSubmitting || isSubCategoryLoading || isSubCategoryPatching
            }
            disabled={
              isSubmitting ||
              isSubCategoryLoading ||
              isCatLoading ||
              isSubCategoryPatching
            }
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default hasErrorBoundary(SubCategoryForm);
