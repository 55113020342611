import React, { ReactElement } from 'react';
import { useTypedSelector } from '@Store/hooks';
import NoAccessComponent from '@Components/common/NoAccessComponent';
import isEmpty from '@Utils/isEmpty';

interface CheckPermissionRouteProps {
  children: ReactElement;
  permission: string;
  role: string[] | undefined;
}

const CheckPermissionRoute: React.FC<CheckPermissionRouteProps> = ({
  children,
  permission,
  role,
}) => {
  const allPermissions = useTypedSelector(
    state => state.common.userGroupPermission,
  );

  const isGroupPermissionSuccess = useTypedSelector(
    state => state.common.isGroupPermissionSuccess,
  );

  const hasPermission = Array.isArray(permission)
    ? permission.some(per => allPermissions?.includes(per))
    : allPermissions?.includes(permission);

  const userProfile = useTypedSelector(state => state.common.userProfile);

  if (!isEmpty(userProfile) && isGroupPermissionSuccess) {
    if (
      userProfile?.group_name === 'System Admin' ||
      userProfile?.group_name === 'DVS Wizard'
    ) {
      return children;
    }
    // Role Based Redirection
    if (role && role.includes(userProfile?.group_name)) {
      return <NoAccessComponent />;
    }
    if (hasPermission) {
      return children;
    }

    return <NoAccessComponent />;
  }

  return null;
};

export default CheckPermissionRoute;
