import { cn } from '@Utils/index';

interface ITextCountCardProps {
  label: string;
  count: number | undefined;
  className?: string;
  labelStyles?: string;
  countStyles?: string;
  isExport?: boolean;
}

const TextCountCard = ({
  label,
  count,
  className,
  countStyles,
  labelStyles,
  isExport,
}: ITextCountCardProps) => {
  return (
    <div
      className={cn(
        `text-count-card-container naxatw-flex naxatw-items-center  ${isExport ? 'naxatw-gap-[0.375rem]' : 'naxatw-gap-2'}`,
        className,
      )}
    >
      <p
        className={cn(
          `  ${isExport ? 'fs-md-semibold' : 'naxatw-text-[1.25rem] naxatw-font-medium  naxatw-leading-normal'}   naxatw-text-primary-700`,
          countStyles,
        )}
      >
        {count}
      </p>
      <p
        className={cn(
          `${isExport ? 'fs-sm-semibold' : 'naxatw-text-[0.875rem]  naxatw-font-semibold naxatw-leading-[1.25rem] '}  naxatw-text-primary-700`,
          labelStyles,
        )}
      >
        {label}
      </p>
    </div>
  );
};

export default TextCountCard;
