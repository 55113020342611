import React from 'react';
import BreadCrumb from '../BreadCrumb';

interface IFormWrapperProps {
  paramId: string;
  onHandleBack: () => void;
  children: React.ReactNode;
}

const FormWrapper = ({
  paramId,
  onHandleBack,
  children,
}: IFormWrapperProps) => {
  return (
    <div className="naxatw-flex naxatw-w-full naxatw-justify-center md:naxatw-h-[calc(100vh-8rem)] md:naxatw-gap-6">
      <div className="naxatw-flex naxatw-h-full naxatw-w-full naxatw-flex-col naxatw-space-y-6 md:naxatw-w-fit">
        <BreadCrumb heading={paramId} onBackClick={onHandleBack} />
        <div className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-gap-6 md:naxatw-h-[calc(100%-4rem)] lg:naxatw-flex-row">
          {children}
        </div>
      </div>
    </div>
  );
};

export default FormWrapper;
