import { api, authenticated } from '.';

export const getKnowledgeRepository = (params: Record<string, any>) => {
  return authenticated(api).get(`/knowledge-repository/`, { params });
};

export const getKnowledgeRepositoryById = (params: Record<string, any>) => {
  return authenticated(api).get(`/knowledge-repository/`, { params });
};

export const postKnowledgeRepository = (params: Record<string, any>) => {
  return authenticated(api).post(`/knowledge-repository/`, params);
};
export const postKnowledgeRepositoryProjects = (
  payload: Record<string, any>,
) => {
  return authenticated(api).post(`/knowledge-repository-project/`, payload);
};

export const postKnowledgeRepositoryProgrammes = (
  payload: Record<string, any>,
) => {
  return authenticated(api).post(`/knowledge-repository-program/`, payload);
};

export const postKnowledgeRepositoryThematicField = (
  payload: Record<string, any>,
) => {
  return authenticated(api).post(
    `/knowledge-repository-thematic-field/`,
    payload,
  );
};

export const patchKnowledgeRepository = (
  id: number,
  payload: Record<string, any>,
) => {
  return authenticated(api).patch(`/knowledge-repository/${id}/`, payload);
};

export const deleteKnowledgeRepository = (id: any) => {
  return authenticated(api).delete(`/knowledge-repository/${id}/`);
};

export const getKnowledgeRepositoryDocById = (id: string) => {
  return authenticated(api).get(
    `/knowledge-repository-document/?knowledge_repository=${id}`,
  );
};

export const postKnowledgeRepositoryDoc = (payload: Record<string, any>) => {
  return authenticated(api).post(`/knowledge-repository-document/`, payload);
};

export const deleteKnowledgeRepositoryDoc = (id: number) => {
  return authenticated(api).delete(`/knowledge-repository-document/${id}/`);
};

export const getProgrammeOptions = () => {
  return authenticated(api).get(`/program-dropdown/`);
};

export const getComponentOptions = () => {
  return authenticated(api).get(`/project-dropdown/`);
};

export const getThematicFieldOptions = () => {
  return authenticated(api).get(`/sector-dropdown/`);
};

export const getChoiceDropdownList = (type: string) => {
  return authenticated(api).get(`/choice/?type=${type}`);
};

export const getProgrammeProjectsDropDown = () => {
  return authenticated(api).get(`/project-by-program/`);
};

export const deleteKnowledgeRepositoryProgramme = (id: number) => {
  return authenticated(api).delete(`/knowledge-repository-program/${id}/`);
};

export const deleteKnowledgeRepositoryProject = (id: number) => {
  return authenticated(api).delete(`/knowledge-repository-project/${id}/`);
};

export const deleteKnowledgeRepositoryThematicField = (id: number) => {
  return authenticated(api).delete(
    `/knowledge-repository-thematic-field/${id}/`,
  );
};

// --------> new changes from 15th jan 2025
export const getTeamMembership = (params: Record<string, any>) => {
  return authenticated(api).get('/choice/', { params });
};

export const getStatusOptions = (params: Record<string, any>) => {
  return authenticated(api).get('/choice/', { params });
};

export const posKnowRepoAgendaEvidence = (payload: Record<string, any>) => {
  return authenticated(api).post(
    '/knowledge-repository-agenda-evidence',
    payload,
  );
};

export const patchKnowRepoAgendaEvidence = (
  payload: Record<string, any>,
  id: number,
) => {
  return authenticated(api).patch(
    `/knowledge-repository-agenda-evidence/${id}`,
    payload,
  );
};

export const postAgendaCategory = (payload: Record<string, any>) => {
  return authenticated(api).post(
    '/knowledge-repository-agenda-evidence/',
    payload,
  );
};

export const postAgendaCategoryGesi = (payload: Record<string, any>) => {
  return authenticated(api).post('/knowledge-repository-gesi/', payload);
};

export const postAgendaCategoryGeographicFocus = (
  payload: Record<string, any>,
) => {
  return authenticated(api).post(
    '/knowledge-repository-geographic-focus/',
    payload,
  );
};

export const deleteAgendaEvidenceRepo = (id: number) => {
  return authenticated(api).delete(
    `/knowledge-repository-agenda-evidence/${id}/`,
  );
};

export const postKnowRepoRole = (payload: Record<string, any>) => {
  return authenticated(api).post('/knowledge-repository-permission/', payload);
};

export const delKnowRepoRole = (id: number) => {
  return authenticated(api).delete(`/knowledge-repository-permission/${id}/`);
};

export const getEvidenceAgendaTheme = (params: Record<string, any>) =>
  authenticated(api).get('/evidence-agenda-theme/', { params });

export const getGeographicFocus = (params: Record<string, any>) =>
  authenticated(api).get('/geographic-focus/', { params });

export const getEvidenceAgendaStatus = (params: Record<string, any>) =>
  authenticated(api).get('/choice/', { params });

export const getKnowRepoProTableData = (params: Record<string, any>) => {
  return authenticated(api).get(`/knowledge-repo-info/`, { params });
};

//! new changes after ashish  made changes in backend

//! ------------------> Know Repo Contextual Api
export const getKnowRepoConTableData = (params: Record<string, any>) => {
  return authenticated(api).get('/contextual-information/', { params });
};

export const postKnowRepoConData = (payload: Record<string, any>) => {
  return authenticated(api).post('/contextual-information/', payload);
};

export const getKnowRepoConById = (params: Record<string, any>) => {
  return authenticated(api).get('/contextual-information/', { params });
};

export const deleteknowRepoCon = (id: number) => {
  return authenticated(api).delete(`/contextual-information/${id}/`);
};

export const postKnowRepoConDoc = (payload: Record<string, any>) => {
  return authenticated(api).post(`/contextual-information-document/`, payload);
};

export const postKnowRepConThematicField = (payload: Record<string, any>) => {
  return authenticated(api).post(
    `/contextual-information-thematic-field/`,
    payload,
  );
};

export const postKnowRepoConProgrammes = (payload: Record<string, any>) => {
  return authenticated(api).post(`/contextual-information-program/`, payload);
};

export const postKnowRepoConProjects = (payload: Record<string, any>) => {
  return authenticated(api).post(`/contextual-information-project/`, payload);
};

export const deleteKnowRepoConThematicField = (id: number) => {
  return authenticated(api).delete(
    `/contextual-information-thematic-field/${id}/`,
  );
};

export const patchKnowRepoCon = (id: number, payload: Record<string, any>) => {
  return authenticated(api).patch(`/contextual-information/${id}/`, payload);
};

export const deleteKnowRepoConDoc = (id: number) => {
  return authenticated(api).delete(`/contextual-information-document/${id}/`);
};

//! ------------------> Know Repo Evidence Agenda Api
export const getKnowRepoEviTableData = (params: Record<string, any>) => {
  return authenticated(api).get('/evidence-agenda/', { params });
};

export const postKnowRepoEviData = (payload: Record<string, any>) => {
  return authenticated(api).post('/evidence-agenda/', payload);
};

export const getKnowRepoEviById = (params: Record<string, any>) => {
  return authenticated(api).get('/evidence-agenda/', params);
};

export const deleteknowRepoEvi = (id: number) => {
  return authenticated(api).delete(`/evidence-agenda/${id}/`);
};

export const postKnowRepoEviDoc = (payload: Record<string, any>) => {
  return authenticated(api).post('/evidence-agenda-document/', payload);
};

// export const postKnowRepEviThematicField = (payload: Record<string, any>) => {
//   return authenticated(api).post(`/evidence-agenda-thematic-field/`, payload);
// };

export const postKnowRepoEviFocus = (payload: Record<string, any>) => {
  return authenticated(api).post(`/evidence-agenda-geographic-focus/`, payload);
};

export const postKnowRepoEviGesi = (payload: Record<string, any>) => {
  return authenticated(api).post('/evidence-agenda-theme/', payload);
};

export const deleteGesiRepo = (id: number) => {
  return authenticated(api).delete(`/evidence-agenda-theme/${id}/`);
};

export const postKnowRepoEviAgenda = (payload: Record<string, any>) => {
  return authenticated(api).post(
    '/knowledge-library-agenda-evidence/',
    payload,
  );
};

export const deleteKnowRepoEviAgenda = (id: number) => {
  return authenticated(api).delete(`/knowledge-library-agenda-evidence/${id}/`);
};

export const getGeographicFocusOptions = () => {
  return authenticated(api).get('/agenda-evidence-category/');
};

export const deleteKnoRepoEviDoc = (id: number) => {
  return authenticated(api).delete(`/evidence-agenda-document/${id}/`);
};

export const deleteGraphicalFocusRepo = (id: number) => {
  return authenticated(api).delete(`/evidence-agenda-geographic-focus/${id}/`);
};

export const patchKnoRepoEviData = (
  id: number,
  payload: Record<string, any>,
) => {
  return authenticated(api).patch(`/evidence-agenda/${id}/`, payload);
};

export const postKnowRepoEviProgrammes = (payload: Record<string, any>) => {
  return authenticated(api).post(`/evidence-agenda-program/`, payload);
};

//!  api for public know Repo

export const getKnowledgeRepositoryDocumentsCount = (
  params: Record<string, any>,
) => {
  return authenticated(api).get('/knowledge-repo-document-count/', { params });
};

export const getKnowRepoConCount = (params: Record<string, any>) => {
  return authenticated(api).get('/contextual-information-document-count/', {
    params,
  });
};

export const getKnowRepoEviCount = (params: Record<string, any>) => {
  return authenticated(api).get('/evidence-agenda-document-count/', {
    params,
  });
};

export const getKnowledgeRepositoryEvidenceSectorsCount = (
  params?: Record<string, any>,
) => {
  return authenticated(api).get('/geographic-focus/', { params });
};

export const getKnowledgeRepositoryEvidenceGeographicsCount = (
  params?: Record<string, any>,
) => {
  return authenticated(api).get('/geographic-count/', { params });
};

export const getEvidenceCategoryOptions = () => {
  return authenticated(api).get('/evidence-agenda-category/');
};

export const getEviSubCatFilters = (params: Record<string, any>) => {
  return authenticated(api).get('/evidence-agenda-category-filter/', {
    params,
  });
};

//! new api for associated program and project

export const postKnowRepoEviProject = (payload: Record<string, any>) => {
  return authenticated(api).post(`/evidence-agenda-project/`, payload);
};

export const deleteknowRepoEviProgram = (id: number) => {
  return authenticated(api).delete(`/evidence-agenda-program/${id}/`);
};

export const deleteknowRepoEviProject = (id: number) => {
  return authenticated(api).delete(`/evidence-agenda-project/${id}/`);
};

export const deleteKnowRepoConProgramme = (id: number) => {
  return authenticated(api).delete(`/contextual-information-program/${id}/`);
};

export const deleteKnowRepoConProject = (id: number) => {
  return authenticated(api).delete(`/contextual-information-project/${id}/`);
};

export const shareKnowledgeRepo = (payload: Record<string, any>) => {
  return authenticated(api).post('/share-knowledge-repository/', payload);
};

export const shareKnowledgeConRepo = (payload: Record<string, any>) => {
  return authenticated(api).post('/share-contextual-information/', payload);
};

export const shareKnowledgeEviRepo = (payload: Record<string, any>) => {
  return authenticated(api).post('/share-evidence-agenda/', payload);
};
