import IconButton from '@Components/common/IconButton';
import { FlexRow } from '@Components/common/Layouts';
import {
  setChartStates,
  setNestedChartStates,
} from '@Store/actions/mainDashboard';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';

function NestedChartContentHeader() {
  const dispatch = useTypedDispatch();

  const nestedChartContentTitle = useTypedSelector(
    state => state.mainDashboard?.charts?.chartDetails?.name,
  );
  const nestedDataLen = useTypedSelector(
    state => state.mainDashboard.charts?.chartDetails?.data?.length,
  );

  return (
    <FlexRow className="naxatw-items-center naxatw-gap-2">
      <IconButton
        name="arrow_back"
        iconClassName="naxatw-text-[#0B2E62] !naxatw-text-[1.25rem] hover:!naxatw-text-primary-600"
        className="naxatw-h-5 naxatw-w-5 naxatw-rounded-full !naxatw-font-normal naxatw-leading-6 naxatw-duration-200"
        onClick={() => {
          dispatch(
            setNestedChartStates({
              key: 'nestedChartId',
              value: null,
            }),
          );
          dispatch(
            setChartStates({
              key: 'chartDetails',
              value: null,
            }),
          );
          dispatch(
            setChartStates({
              key: 'showLegendDetails',
              value: false,
            }),
          );
        }}
      />
      <span className="naxatw-body-btn naxatw-text-primary-300">
        {nestedChartContentTitle} {nestedDataLen && `(${nestedDataLen})`}
      </span>
    </FlexRow>
  );
}

export default NestedChartContentHeader;
