import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import { FormControl } from '@Components/common/FormUI';
import IconButton from '@Components/common/IconButton';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import { contactsFormFields } from '@Constants/FormConstants/contacts';
import { propTypeClose } from '@Constants/Types/FormTypes/userSecurity';
import { getContactById, patchContact, postContact } from '@Services/contacts';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { contactValidationSchema } from '@Validations/ContactForm';
import { zodResolver } from '@hookform/resolvers/zod';
import ErrorMessage from '@Components/common/ErrorMessage';
import { getProvinceList } from '@Services/mainDashboard';
import { includeProvinces } from '@Constants/updates';
import { prepareDataForDropdown } from '@Utils/index';
import { getChoiceTypes } from '@Services/federalInfoUpdate';
import getInputElement from '@Components/common/FormComponent/GetInputElement';

const initialFormValues = {
  name: '',
  email: '',
  phone: '',
  office: '',
  designation: '',
  remarks: '',
  federal_type: 'Federal',
  province: null,
};

const ContactForm = ({ onClose }: propTypeClose) => {
  const { contactId } = useParams();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    reset,
    formState: { isSubmitting, dirtyFields, errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: initialFormValues,
    resolver: zodResolver(contactValidationSchema),
  });

  const formProps = {
    register,
    control,
    getValues,
    setValue,
    errors,
    dirtyFields,
    reset,
  };

  const fedTypeWatch = watch('federal_type');

  // get contact if edit
  const { isLoading: isContactLoading } = useQuery({
    queryKey: ['contact-by-id', contactId],
    enabled: !!contactId,
    queryFn: () => getContactById(Number(contactId)),
    select: responseData => {
      if (!responseData?.data) return null;
      return responseData.data;
    },
    onError: () => toast.error('Error Occured!. Please try again.'),
    onSuccess: contactData => {
      if (!contactData) return;
      Object.keys(contactData).forEach((key: any) => {
        setValue(key, contactData[key]);
      });
    },
  });

  // type dropdown options
  const { data: federalTypes } = useQuery({
    queryKey: ['federal-types'],
    queryFn: () => getChoiceTypes({ type: 'federal_type' }),
    select: res => {
      if (!res?.data) return null;
      const fedOptions = prepareDataForDropdown(res?.data, 'name');
      return fedOptions;
    },
  });

  // fetch province list if only province option is selected
  const { data: provinceList } = useQuery({
    queryKey: ['province-list'],
    queryFn: () => getProvinceList({}),
    enabled: fedTypeWatch === 'Province',
    select: res => {
      if (!res?.data) return null;
      const provinces = prepareDataForDropdown(res?.data, 'name')?.filter(
        (pro: Record<string, any>) => includeProvinces.includes(pro.label),
      );
      return provinces;
    },
  });

  // post contact
  const { mutateAsync: postContactData } = useMutation({
    mutationKey: ['post-contact'],
    mutationFn: async (payloadData: Record<string, any>) =>
      postContact(payloadData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['contact-tabledata'],
      });
      toast.success('Contact added Succesfully');
      onClose();
    },
  });

  // patch contact
  const { mutateAsync: patchContactData } = useMutation({
    mutationKey: ['patch-contact'],
    mutationFn: async (payloadData: Record<string, any>) =>
      patchContact(payloadData, Number(contactId)),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['contact-tabledata'],
      });
      toast.success('Contact edited Succesfully');
      onClose();
    },
  });

  const getDirtyFieldValues = () => {
    const allValues = getValues();
    const dirtyValues: any = {};
    Object.keys(allValues).forEach((key: string) => {
      if (dirtyFields[key as keyof typeof dirtyFields]) {
        dirtyValues[key] = allValues[key as keyof typeof dirtyFields];
      }
    });

    return dirtyValues;
  };

  const hadleFormSubmit = async () => {
    try {
      if (contactId) {
        const editFields = getDirtyFieldValues();
        await patchContactData(editFields);
      } else {
        await postContactData(getValues());
      }
    } catch (error: any) {
      const caughtError = error?.response?.data?.message;
      toast.error(caughtError || 'Something Went Wrong');
    }
  };

  const dropdownOptionsList = {
    federal_type: federalTypes || [],
    province: provinceList || [],
  };

  return (
    <div className="contact-form naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-max-h-[90vh] naxatw-w-[90%] -naxatw-translate-x-1/2 naxatw-translate-y-[calc(-50%+31.5px)] naxatw-flex-col naxatw-overflow-hidden naxatw-rounded-lg naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 sm:naxatw-max-w-[34.75rem] ">
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-light">
        <BreadCrumb
          heading={`Contacts / ${contactId ? 'Edit' : 'Add'}`}
          overlayStatus={() => onClose()}
        />
        <IconButton
          name="close"
          className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
          iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
          onClick={() => onClose()}
        />
      </div>
      <div className="naxatw-flex naxatw-w-full naxatw-gap-3">
        <div className="naxatw-w-full">
          <form onSubmit={handleSubmit(hadleFormSubmit)}>
            <FlexColumn className="scrollbar naxatw-h-[calc(100vh-20rem)] naxatw-w-full naxatw-overflow-y-scroll naxatw-px-5 naxatw-pr-2 naxatw-pt-6">
              {isContactLoading && contactId ? (
                <FormSkeleton numRows={5} className="naxatw-w-full" />
              ) : (
                contactsFormFields.map((formField: Record<string, any>) => {
                  const { id } = formField;
                  const isVisible =
                    id !== 'province' || fedTypeWatch === 'Province';

                  if (!isVisible) return undefined;

                  return (
                    <FlexRow key={formField.id}>
                      <FormControl className="naxatw-mb-5 naxatw-w-full naxatw-gap-[0.5rem]">
                        <InputLabel
                          label={formField.label}
                          id={formField.id}
                          astric={formField.required}
                        />
                        {getInputElement(
                          {
                            ...(formField as any),
                          },
                          /* @ts-ignore */
                          formProps,
                          dropdownOptionsList[
                            formField?.id as keyof typeof dropdownOptionsList
                          ],
                        )}
                        {errors?.[formField.id as keyof typeof errors] && (
                          <ErrorMessage
                            message={
                              errors?.[formField.id as keyof typeof errors]
                                ?.message as string
                            }
                          />
                        )}
                      </FormControl>
                    </FlexRow>
                  );
                })
              )}
            </FlexColumn>
            <div className="naxatw-flex naxatw-justify-center naxatw-py-5 naxatw-shadow-formshadow">
              <Button
                size="normal"
                variant="primary"
                className="naxatw-px-4 naxatw-py-2"
                type="submit"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default hasErrorBoundary(ContactForm);
