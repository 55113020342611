import ExportBtn from '@Components/common/CommonExport/ExportHeader/ExportBtn';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { deletePdfSection } from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { parseFormattedNumber } from '@Utils/index';
import { useDispatch } from 'react-redux';

const ReportPopulation = ({
  municipalitySummary,
}: {
  municipalitySummary: Record<string, any>;
}) => {
  const dispatch = useDispatch();

  const cardsData = useTypedSelector(
    state => state.pdfExportSlice.palikaDetails.population.datalists,
  );
  return (
    <ExportRoundedContainer className="population-data naxatw-relative  naxatw-p-3 ">
      {cardsData.includes('population') && (
        <div className="top-content naxatw-group naxatw-pb-4  naxatw-text-center">
          <p className="fs-md-bold naxatw-mb-1 naxatw-uppercase naxatw-tracking-[-0.015rem] naxatw-text-primary-700 ">
            total population
          </p>
          <p className="naxatw-mb-1">
            {municipalitySummary?.total_population
              ? Number(municipalitySummary.total_population).toLocaleString()
              : '-'}
          </p>
          <p className="fs-sm-medium">
            {municipalitySummary?.total_population
              ? `${(
                  (parseFormattedNumber(municipalitySummary.total_population) /
                    29164578) *
                  100
                ).toFixed(2)}% Share of National Population`
              : '- Share of National Population'}
          </p>

          <ExportBtn
            size="sm"
            onHandleClose={() =>
              dispatch(
                deletePdfSection({
                  page: 'palikaDetails',
                  section: 'population',
                }),
              )
            }
          />
        </div>
      )}
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(ReportPopulation);
