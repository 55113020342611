import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import TableLists from '@Components/common/CommonExport/TableLists';
import TableListTwo from '@Components/common/CommonExport/TableLists/TableListTwo';
import { getStakeHoldersByProvinceId } from '@Services/provincialProfile';
import {
  deletePdfSection,
  resetPdfSection,
  setOriginalDataLists,
} from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const PdfStakeHolder = () => {
  const { provinceId } = useParams();
  const dispatch = useDispatch();
  const filterBy = useTypedSelector(state => state.provincialProfile.filterBy);

  const dataLists = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.stakeholderTable.datalists,
  );
  const showResetButton = useTypedSelector(
    state =>
      state.pdfExportSlice.provincialProfile.stakeholderTable.showResetButton,
  );

  const { data: stakeholderData } = useQuery({
    queryKey: ['provincial-profile-stakholder-pdf', provinceId],
    queryFn: () =>
      getStakeHoldersByProvinceId(provinceId as string, {
        status: filterBy,
      }),
    select: res => {
      if (!res?.data) return null;
      const stakeholdersLists = res.data;

      let idCounter = 1;
      const finalLists: Record<string, any>[] = [];

      stakeholdersLists.forEach((stakeholder: Record<string, any>) => {
        stakeholder.projects.forEach((project: Record<string, any>) => {
          project.stakeholder_names.forEach((name: Record<string, any>) => {
            finalLists.push({
              id: idCounter++,
              listOne: stakeholder?.stakeholder_type,
              listTwo: project?.project_name,
              listThree: name,
            });
          });
        });
      });

      return finalLists;
    },
    onSuccess: response => {
      if (!response) return null;
      dispatch(
        setOriginalDataLists({
          page: 'provincialProfile',
          section: 'stakeholderTable',
          value: response,
        }),
      );

      return null;
    },
  });

  return (
    <ExportRoundedContainer>
      <div className="pdf-stakeholder-table-container">
        <ExportHeader
          showResetButton={showResetButton}
          isStyleAbsolute={false}
          headerTitle="StakeHolder Details"
          onHandleDelete={() =>
            dispatch(
              deletePdfSection({
                page: 'provincialProfile',
                section: 'stakeholderTable',
              }),
            )
          }
          className="!naxatw-border-b !naxatw-border-b-pdf-border !naxatw-p-2"
          onHandleReset={() =>
            dispatch(
              resetPdfSection({
                page: 'provincialProfile',
                section: 'stakeholderTable',
              }),
            )
          }
        />

        <TableLists
          dataLists={stakeholderData?.slice(0, 3) || []}
          headerTitles={{
            firstColumn: 'Stakeholder Type',
            secondColumn: 'Project',
            thirdColumn: 'Stakeholder Name',
          }}
          isExport
          exportTitles={{
            page: 'provincialProfile',
            section: 'stakeholderTable',
          }}
          exportLists={dataLists || []}
        />
      </div>

      {Array.isArray(stakeholderData) && stakeholderData.length > 3 && (
        <TableListTwo
          dataLists={stakeholderData.slice(2) || []}
          isExport
          exportTitles={{
            page: 'provincialProfile',
            section: 'stakeholderTable',
          }}
          exportLists={dataLists || []}
        />
      )}
    </ExportRoundedContainer>
  );
};

export default PdfStakeHolder;
