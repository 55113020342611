import React from 'react';

interface IFormStepsWrapperProps {
  children: React.ReactNode;
}

const FormStepsWrapper = ({ children }: IFormStepsWrapperProps) => {
  return (
    <div className="form-steps-wrapper naxatw-w-full naxatw-rounded-2xl naxatw-bg-white naxatw-px-6 naxatw-py-5 md:naxatw-h-fit md:naxatw-min-w-[34.75rem] md:naxatw-max-w-[34.75rem] lg:naxatw-h-full lg:naxatw-min-w-[18.75rem] 2xl:naxatw-w-[22.75rem] 2xl:naxatw-max-w-[22.75rem]">
      <div className="naxatw-flex naxatw-flex-row  naxatw-gap-10  lg:naxatw-flex-col">
        {children}
      </div>
    </div>
  );
};

export default FormStepsWrapper;
