/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@Store/hooks';
import { setNestedChartStates } from '@Store/actions/mainDashboard';
import clsx from 'clsx';
import { IDonutLegendItemProps } from '../types';

export default function DonutLegendItem({
  color,
  name,
  value,
  percentage,
  showPound,
  isNestedChart,
  id,
  isRoundedLegend = false,
  countStyles,
  labelStyles,
  legendWrapperStyles,
}: IDonutLegendItemProps) {
  const dispatch = useDispatch();
  const nestedChartId = useTypedSelector(
    state => state.mainDashboard.charts.nestedCharts.nestedChartId,
  );

  const isLegendClickable = nestedChartId === null && isNestedChart;

  return (
    <div
      className={clsx(
        `naxatw-flex naxatw-items-start naxatw-justify-between naxatw-gap-4 naxatw-text-sm naxatw-text-grey-800 ${isLegendClickable ? 'naxatw-cursor-pointer' : ''} `,
        legendWrapperStyles,
      )}
      onClick={() => {
        if (isLegendClickable) {
          dispatch(
            setNestedChartStates({
              key: 'nestedChartId',
              value: id,
            }),
          );
        }
      }}
    >
      <div
        className={`legend-box-name naxatw-flex naxatw-max-w-[300px] naxatw-flex-grow naxatw-gap-5  ${isRoundedLegend ? 'naxatw-items-center' : 'naxatw-items-start'} `}
      >
        <div
          className={`naxatw-my-[2px]  ${isRoundedLegend ? 'naxatw-h-2 naxatw-w-2 naxatw-rounded-[50%]' : 'naxatw-min-h-[16px] naxatw-min-w-[16px] naxatw-rounded'} `}
          style={{
            backgroundColor: color,
          }}
        />
        <div
          className={clsx(
            'name naxatw-button naxatw-line-clamp-1 naxatw-max-w-52 naxatw-text-start naxatw-text-matt-100',
            labelStyles,
          )}
          title={name}
        >
          {name}
        </div>
      </div>
      <div className="value-percentage naxatw-flex  naxatw-items-center naxatw-justify-end naxatw-gap-2 naxatw-font-bold">
        <div
          className={clsx(
            'naxatw-button  naxatw-whitespace-nowrap naxatw-text-center naxatw-text-matt-100',
            countStyles,
          )}
        >
          {showPound && '£'} {value ? value.toLocaleString() : '-'}&nbsp;{' '}
          {percentage ? `(${percentage?.toFixed(1)}%)` : null}
        </div>
      </div>
    </div>
  );
}
