import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import {
  getTopFiveSectorsByComponent,
  getTopFiveSectorsByProgramme,
} from '@Services/mainDashboard';
import { deletePdfSection } from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import CustumTreeMap from '@Components/common/Charts/CustomTreeMap';
import isEmpty from '@Utils/isEmpty';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';

const ReportFiveSectors = () => {
  const dispatch = useDispatch();

  const selectedTabOfCharts = useTypedSelector(
    state => state.setMainDashboardChartTabs.selectedTab,
  );

  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const projectFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );

  const subMarkersParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );

  const statusFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );

  const provinceFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.province,
  );

  const fetchSectorData =
    selectedTabOfCharts.sectors === 'Project'
      ? getTopFiveSectorsByComponent
      : getTopFiveSectorsByProgramme;

  const { data: chartData } = useQuery({
    queryKey: ['top-five-sector-trend-programme-report'],
    queryFn: () =>
      fetchSectorData({
        program: programmeFilterParams?.join(','),
        project: projectFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        start_date: startDate,
        end_date: endDate,
        status: statusFilterParams,
        province: provinceFilterParams?.join(','),
      }),
    select: (res: any) => {
      if (!res?.data) return [];
      const response = res.data;
      if (isEmpty(response)) return [];
      return response?.map((sector: Record<string, any>) => ({
        name: sector?.name,
        size: sector?.count,
      }));
    },
  });

  return (
    <ExportRoundedContainer
      style={{
        pageBreakInside: 'avoid',
      }}
    >
      <ExportHeader
        headerTitle={`Top 5 Sectors by Number of Associated ${
          selectedTabOfCharts.sectors
        }`}
        isStyleAbsolute={false}
        className="!naxatw-border-b naxatw-border-b-pdf-border !naxatw-p-2"
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: 'bek',
              section: 'fiveSector',
            }),
          )
        }
      />
      <div className="naxatw-p-1">
        {chartData && isEmpty(chartData) ? (
          <NoChartDataComponent
            isExport
            className="!naxatw-h-[280px]"
            iconClassName="naxatw-h-[50%]"
          />
        ) : (
          <CustumTreeMap data={chartData || []} height={300} />
        )}
      </div>
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(ReportFiveSectors);
