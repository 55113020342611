import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Map from '@Components/MainDashboard/MapSection/Map';
import { mapPdfMapCount } from '@Constants/PdfContent';
import { deletePdfSection } from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useDispatch } from 'react-redux';

const ReportMap = () => {
  const dispatch = useDispatch();
  const activeFilterOption = useTypedSelector(
    state => state.mainDashboard.map.activeFilterOption,
  );
  const activeViewBy = useTypedSelector(
    state => state.mainDashboard.map.activeViewBy,
  );

  return (
    <div
      className="report-map"
      style={{
        pageBreakInside: 'avoid',
      }}
    >
      <ExportHeader
        headerTitle={`${mapPdfMapCount[activeFilterOption]} Count by ${activeViewBy}`}
        isStyleAbsolute={false}
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: 'bek',
              section: 'map',
            }),
          )
        }
      />
      <ExportRoundedContainer className="naxatw-h-[30rem]">
        <Map isExport />
      </ExportRoundedContainer>
    </div>
  );
};

export default hasErrorBoundary(ReportMap);
