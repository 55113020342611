import Skeleton from '@Components/RadixComponents/Skeleton';
import { v4 as uuidv4 } from 'uuid';

const KnowLibSk = ({ noOfRows = 3 }: { noOfRows?: number }) => {
  return (
    <div className="know-lfilters-sk-container">
      {Array.from({ length: noOfRows }).map(() => (
        <div
          key={uuidv4()}
          className="sk-item naxatw-border-b naxatw-border-b-[#E0E0E0] naxatw-py-3"
        >
          <Skeleton className="naxatw-h-[25px] naxatw-w-full" />
        </div>
      ))}
    </div>
  );
};

export default KnowLibSk;
