// import DropDown from '@Components/common/DropDown';
import { useQuery } from '@tanstack/react-query';
import DropDown from '@Components/common/DropDown';
import SwitchTab from '@Components/common/FormUI/SwitchTab';
import { getProvinceList } from '@Services/components';
import { useTypedSelector } from '@Store/hooks';
import { useDispatch } from 'react-redux';
import { setUpdateFilterState, clearFilterState } from '@Store/actions/update';
import { fedOptions, includeProvinces } from '@Constants/updates';
import MultipleMonthYearDatePicker from '@Components/common/FormUI/MultipleMonthYearDatePicker';
import IconButton from '@Components/common/IconButton';

const UpdatesHeader = () => {
  const dispatch = useDispatch();

  const provinceFilterTerm = useTypedSelector(
    state => state.UpdateSlice.filterTerms.provinceFilterTerm,
  );

  const fedTypeFilterTerm = useTypedSelector(
    state => state.UpdateSlice.filterTerms.fedTypeFilterTerm,
  );

  const startDate = useTypedSelector(
    state => state.UpdateSlice.filterTerms.start_date,
  );

  const endDate = useTypedSelector(
    state => state.UpdateSlice.filterTerms.end_date,
  );

  const isShowClearBtn = provinceFilterTerm || startDate || endDate;

  // fetches province list
  const { data: provinceList } = useQuery({
    queryKey: ['get-province-list'],
    queryFn: () => getProvinceList(),
    enabled: fedTypeFilterTerm === 'Provincial',
    select: (response: Record<string, any>) => {
      if (!response?.data) return null;
      const provinceLists = response?.data;
      const filteredData = provinceLists
        .map((options: Record<string, any>) => ({
          id: options?.id,
          label: options?.name,
          value: options?.id,
        }))
        ?.filter((pro: Record<string, any>) =>
          includeProvinces.includes(pro.label),
        );

      return filteredData;
    },
  });

  return (
    <div className="updates-header naxatw-mb-4 naxatw-flex naxatw-justify-between">
      <p className="naxatw-text-[1.5rem] naxatw-font-medium naxatw-leading-[2.375rem] naxatw-tracking-[-0.03rem] naxatw-text-[#0B2E62]">
        Monthly PEF Updates
      </p>

      {/* Filters */}
      <div
        className="filters-containers naxatw-flex naxatw-items-center naxatw-gap-4"
        style={{ zIndex: 1 }}
      >
        {isShowClearBtn && (
          <IconButton
            name="restart_alt"
            className="naxatw-text-gray-500"
            onClick={() => dispatch(clearFilterState())}
          />
        )}
        <MultipleMonthYearDatePicker
          startDate={startDate}
          endDate={endDate}
          placeholder="Choose Date"
          onChange={date => {
            const { start, end } = date;
            dispatch(
              setUpdateFilterState({
                name: 'start_date',
                value: start,
              }),
            );
            dispatch(
              setUpdateFilterState({
                name: 'end_date',
                value: end,
              }),
            );
          }}
        />
        <div className="naxatw-flex naxatw-items-center naxatw-gap-2">
          {fedTypeFilterTerm === 'Provincial' ? (
            <DropDown
              options={provinceList || []}
              className="naxatw-min-w-[15.5rem]"
              choose="value"
              placeholder="Select Province"
              value={provinceFilterTerm}
              onChange={e => {
                dispatch(
                  setUpdateFilterState({
                    name: 'provinceFilterTerm',
                    value: e,
                  }),
                );
              }}
              enableSearchbar={false}
            />
          ) : null}
        </div>

        <SwitchTab
          activeValue={fedTypeFilterTerm}
          activeLabel={fedTypeFilterTerm}
          options={fedOptions || []}
          wrapperClassName="!naxatw-gap-[0.625rem] !naxatw-cursor-pointer"
          defaultBg={false}
          defaultBehaviour={false}
          className="!naxatw-bg-white naxatw-text-sm naxatw-leading-5 naxatw-text-[#484848]"
          titleClassName="naxatw-text-sm"
          onChange={event => {
            dispatch(
              setUpdateFilterState({
                name: 'fedTypeFilterTerm',
                value: event,
              }),
            );
            dispatch(
              setUpdateFilterState({
                name: 'provinceFilterTerm',
                value: '',
              }),
            );
          }}
        />
      </div>
    </div>
  );
};

export default UpdatesHeader;
