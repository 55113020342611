import CustomDonutChart from '@Components/common/Charts/DonutChart';
import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import ReportChartLegend from '@Components/common/CommonExport/ReportChartLegend';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { mapBudgetExpenditureColors } from '@Constants/mainDashboard';
import { getAgreementModality } from '@Services/provincialProfile';
import { deletePdfSection } from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import isEmpty from '@Utils/isEmpty';
import { useDispatch } from 'react-redux';

//  {
//     id: 'project-agreement-modality',
//     queryFn: getAgreementModality,
//     title: `Number of Project by Agreement Modality`,
//     chartType: 'donut',
//     params: {
//       program: programmeFilterParams?.join(','),
//       project: projectFilterParams?.join(','),
//       first_tier_partner: firstTierPartnerParams?.join(','),
//       sector: sectorGroupParams?.join(','),
//       marker: markerGroupParams?.join(','),
//       marker_category: subMarkersParams?.join(','),
//       start_date: startDate,
//       end_date: endDate,
//       status: statusFilterParams,
//     },
//     fill: Object.values(mapBudgetExpenditureColors),
//     showPound: false,
//   },

const ReportModality = () => {
  const dispatch = useDispatch();
  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const projectFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );

  const subMarkersParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );

  const statusFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );

  const provinceFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.province,
  );

  const { data: chartData } = useQuery({
    queryKey: ['modality-pdf'],
    queryFn: () =>
      getAgreementModality({
        program: programmeFilterParams?.join(','),
        project: projectFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        start_date: startDate,
        end_date: endDate,
        status: statusFilterParams,
        province: provinceFilterParams?.join(','),
      }),
    select: (res: any) => {
      if (!res?.data) return [];
      const response = res.data;
      if (isEmpty(response)) return [];

      return response.map(
        (project: {
          project__aggrement_modality__name: string;
          count: number;
          percentage?: number;
        }) => ({
          name: project?.project__aggrement_modality__name,
          value: project?.count,
          percentage: project?.percentage,
        }),
      );
    },
  });
  return (
    <ExportRoundedContainer
      style={{
        pageBreakInside: 'avoid',
      }}
    >
      <ExportHeader
        headerTitle="Number of Project by Agreement Modality"
        isStyleAbsolute={false}
        className="!naxatw-border-b naxatw-border-b-pdf-border !naxatw-p-2"
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: 'bek',
              section: 'modalityChart',
            }),
          )
        }
      />
      <div className="naxatw-relative naxatw-flex naxatw-items-center naxatw-gap-3 naxatw-p-3">
        <CustomDonutChart
          data={chartData || []}
          fills={Object.values(mapBudgetExpenditureColors)}
          innerRadius={50}
        />
        <ReportChartLegend
          data={chartData || []}
          fills={Object.values(mapBudgetExpenditureColors)}
        />
      </div>
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(ReportModality);
