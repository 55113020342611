import { knowledgeRepositorySlice } from '@Store/slices/knowledgeRepository';

export const {
  setDropdownOptionsList,
  setPublicFilterOptions,
  setPublicSearchText,
  setPublicSelectedDate,
  postProgrammaticFilterParams,
  setKnowledgeLibraryDateRangeState,
  resetProgrammaticState,
  setKnowledgeLibraryStates,
  postContextualFilterParams,
  toggleknowledgeRepositoryFilterState,
  resetContextualFilterState,
  postEviAgendaFilterParams,
  resetEvidenceAgendaFilterState,
} = knowledgeRepositorySlice.actions;
