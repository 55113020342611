import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

interface DataBankState {
  report: Record<string, any>;
  knowledgeRepository: Record<string, any>;
}

const initialState: DataBankState = {
  report: {
    filterParams: {
      province: null,
      start_date_1: '',
      start_date_2: '',
      end_date_1: '',
      end_date_2: '',
    },
    area: '',
  },
  knowledgeRepository: {
    searchTerm: '',
  },
};

const setAreaFilterState: CaseReducer<DataBankState, PayloadAction<any>> = (
  state,
  action,
) => {
  return {
    ...state,
    report: {
      filterParams: state.report.filterParams,
      area: action.payload,
    },
  };
};

const clearAreaFilterState: CaseReducer<
  DataBankState,
  PayloadAction<any>
> = state => {
  return {
    ...state,
    report: {
      filterParams: state.report.filterParams,
      area: '',
    },
  };
};

const setDaterangeFilterState: CaseReducer<
  DataBankState,
  PayloadAction<any>
> = (state, action) => {
  const { key, value } = action.payload;
  return {
    ...state,
    report: {
      ...state.report,
      filterParams: { ...state.report.filterParams, [key]: value },
    },
  };
};

const clearDateRangeFilterState: CaseReducer<
  DataBankState,
  PayloadAction<any>
> = (state, action) => {
  const dateType = action.payload.type;

  if (dateType === 'start_date') {
    return {
      ...state,
      report: {
        ...state.report,
        filterParams: {
          ...state.report.filterParams,
          start_date_1: '',
          start_date_2: '',
        },
      },
    };
  }
  if (dateType === 'end_date') {
    return {
      ...state,
      report: {
        ...state.report,
        filterParams: {
          ...state.report.filterParams,
          end_date_1: '',
          end_date_2: '',
        },
      },
    };
  }
  return {
    ...state,
  };
};

const setFilterState: CaseReducer<DataBankState, PayloadAction<any>> = (
  state,
  action,
) => {
  return {
    ...state,
    report: {
      ...state.report,
      filterParams: { ...state.report.filterParams, ...action.payload },
    },
  };
};

const clearFilterState: CaseReducer<
  DataBankState,
  PayloadAction<Record<string, any>>
> = (state, action) => {
  const filterCatName = action.payload.type;

  if (filterCatName === 'all') {
    return {
      ...state,
      report: {
        filterParams: null,
        area: '',
        start_date_1: '',
        start_date_2: '',
        end_date_1: '',
        end_date_2: '',
      },
    };
  }
  if (filterCatName === 'province') {
    return {
      ...state,
      report: {
        ...state.report,
        filterParams: { ...state.report.filterParams, province: '' },
        area: '',
      },
    };
  }

  return {
    ...state,
    report: {
      ...state.report,
      filterParams: { ...state.report.filterParams, [filterCatName]: '' },
    },
  };
};

const setknowRepoState: CaseReducer<DataBankState, PayloadAction<any>> = (
  state,
  action,
) => {
  return {
    ...state,
    knowledgeRepository: {
      ...state.knowledgeRepository,
      ...action.payload,
    },
  };
};

const dataBankSlice = createSlice({
  name: 'databankforms',
  initialState,
  reducers: {
    setFilterState,
    clearAreaFilterState,
    clearFilterState,
    setAreaFilterState,
    setDaterangeFilterState,
    clearDateRangeFilterState,
    setknowRepoState,
  },
});

export { dataBankSlice };

export default dataBankSlice.reducer;
