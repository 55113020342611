/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { useEffect, useState, useRef } from 'react';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Flex from '@Components/common/Layouts/Flex';
import MobileFrame from '@Assets/images/Mobile.svg';
import facebookIconFilled from '@Assets/icons/fb.svg';
import twitterIcon from '@Assets/icons/x.svg';
import facebookIcon from '@Assets/icons/facebook.svg';
import twitterIconFilled from '@Assets/icons/x_filled.svg';
// import instagramIcon from '@Assets/icons/instagram.svg';
// import instagramIconFilled from '@Assets/icons/instagram_filled.svg';
import {
  getRandomTriviaQuestion,
  checkCorrectAnswer,
} from '@Services/authentication';
import useOutsideClick from '@Hooks/useOutsideClick';
import { useQuery } from '@tanstack/react-query';
import Icon from '@Components/common/Icon';

const MobileQuestion = () => {
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [questionId, setQuestionId] = useState(null);
  const [selectedSocialLinks, setSelectedSocial] = useState('twitter');
  const [questionResult, setQuestionResult] = useState(null);
  const [mobileRef, toggleTrivia, setToggleTrivia] = useOutsideClick();
  const twitterRef = useRef(null);

  const { data: randomTriviaData } = useQuery({
    queryKey: ['get-random-trivia-questions'],
    queryFn: () => getRandomTriviaQuestion(),
    select: res => {
      const data = res?.data;
      return {
        id: data.id,
        question: data?.question,
        options: [
          { id: 'A', label: data.option_a },
          { id: 'B', label: data.option_b },
          { id: 'C', label: data.option_c },
        ],
      };
    },
  });

  // check correct answer
  const { isLoading: checkAnswerLoading } = useQuery({
    queryKey: ['check-correct-answer', selectedOption],
    queryFn: () => checkCorrectAnswer({ selectedOption, questionId }),
    enabled: selectedOption !== null && questionId !== null,
    select: res => {
      return res?.data;
    },
    onSuccess: response => {
      if (!response) return;
      setQuestionResult(response?.result);
    },
  });

  useEffect(() => {
    // Function to load the Twitter script
    const loadTwitterScript = () => {
      const script = document.createElement('script');
      script.src = 'https://platform.twitter.com/widgets.js';
      script.async = true;
      document.body.appendChild(script);
    };

    if ((window as any).twttr) {
      (window as any).twttr.widgets.load(twitterRef.current);
    } else {
      loadTwitterScript();
    }
  }, [selectedSocialLinks]);

  return (
    <FlexRow className="naxatw-max-w-[100vw] naxatw-justify-center">
      <div
        ref={mobileRef}
        className="naxatw-h-[135vh] naxatw-w-[21.25rem] sm:naxatw-h-[100vh] lg:naxatw-h-[100%]"
      >
        <FlexColumn
          className={`naxatw-h-[35%] naxatw-justify-end sm:naxatw-h-[40%] ${toggleTrivia ? 'exit-active' : 'enter-active naxatw-transition-all naxatw-duration-1000'}`}
        >
          <h4 className="naxatw-mb-2 naxatw-text-center naxatw-text-[1.25rem] naxatw-font-bold naxatw-uppercase naxatw-leading-[1.375rem] naxatw-tracking-[0.105rem] naxatw-text-[#417EC9] sm:naxatw-mb-4">
            Fun Trivia
          </h4>
          <h4 className="naxatw-mb-[1.375rem] naxatw-text-[0.875rem] naxatw-font-bold naxatw-leading-normal naxatw-tracking-[-0.0175rem] naxatw-text-black">
            {randomTriviaData?.question}
          </h4>
          <FlexColumn className="naxatw-gap-[1.125rem]">
            {randomTriviaData?.options?.map(option => (
              <div
                role="button"
                tabIndex={0}
                key={option.id}
                className={`naxatw-flex naxatw-w-full naxatw-cursor-pointer naxatw-items-center naxatw-gap-4 naxatw-rounded-lg naxatw-px-4 naxatw-py-2 sm:naxatw-py-3 ${
                  !checkAnswerLoading &&
                  selectedOption === option?.id &&
                  questionResult === 'correct'
                    ? 'naxatw-bg-[#16B182]'
                    : !checkAnswerLoading &&
                        selectedOption === option?.id &&
                        questionResult === 'Wrong'
                      ? 'naxatw-bg-[#F27732]'
                      : 'naxatw-bg-[#F4F3F6] hover:naxatw-bg-[#eeedf0]'
                }`}
                onClick={() => {
                  setQuestionResult(null);
                  setSelectedOption(option?.id || null);
                  setQuestionId(randomTriviaData?.id || null);
                }}
                onKeyDown={() => {}}
              >
                <Flex className="naxatw-h-7 naxatw-min-h-6 naxatw-w-7 naxatw-min-w-7 naxatw-items-center naxatw-justify-center naxatw-rounded-full naxatw-bg-white sm:naxatw-h-9 sm:naxatw-min-h-9 sm:naxatw-w-9 sm:naxatw-min-w-9">
                  <p className="naxatw-text-xs naxatw-font-bold naxatw-leading-normal">
                    {option.id}
                  </p>
                </Flex>
                <p
                  className={`naxatw-text-sm naxatw-font-bold naxatw-leading-normal sm:naxatw-text-sm ${'naxatw-text-black'}`}
                >
                  {option.label}
                </p>
                {selectedOption === option?.id &&
                questionResult === 'correct' ? (
                  <div className="naxatw-ml-auto">
                    <Icon
                      name="check_circle"
                      className="naxatw-text-white"
                      aria-hidden="true"
                      tabIndex={-1}
                    />
                  </div>
                ) : null}
                {selectedOption === option?.id && questionResult === 'Wrong' ? (
                  <div className="naxatw-ml-auto">
                    <Icon
                      name="cancel"
                      className="naxatw-text-white"
                      aria-hidden="true"
                      tabIndex={-1}
                    />
                  </div>
                ) : null}
              </div>
            ))}
          </FlexColumn>
        </FlexColumn>

        <div
          className={`${toggleTrivia ? 'naxatw-translate-y-[-35%] naxatw-transition-all naxatw-duration-1000 sm:naxatw-translate-y-[-40%] lg:naxatw-translate-y-[-60%]' : ' naxatw-transition-all naxatw-duration-1000'}`}
        >
          <FlexRow className="naxatw-mt-7 naxatw-justify-center naxatw-gap-[1.73rem]">
            <div
              onClick={() => setSelectedSocial('twitter')}
              role="button"
              onKeyDown={() => {}}
              tabIndex={0}
            >
              <img
                src={
                  selectedSocialLinks === 'twitter'
                    ? twitterIconFilled
                    : twitterIcon
                }
                alt="twitter-icon"
                className="naxatw-cursor-pointer"
                title="X"
              />
            </div>
            <div
              onClick={() => setSelectedSocial('facebook')}
              role="button"
              onKeyDown={() => {}}
              tabIndex={0}
            >
              <img
                src={
                  selectedSocialLinks === 'facebook'
                    ? facebookIconFilled
                    : facebookIcon
                }
                alt="facebook-icon"
                className="naxatw-cursor-pointer"
                title="facebook"
              />
            </div>

            {/* <div
            onClick={() => setSelectedSocial('instagram')}
            role="button"
            onKeyDown={() => {}}
          >
            <img
              src={
                selectedSocialLinks === 'instagram'
                  ? instagramIconFilled
                  : instagramIcon
              }
              alt="instagram-icon"
              className="naxatw-cursor-pointer"
              title="instagram"
            />
          </div> */}
          </FlexRow>
          <div
            className="naxatw-relative naxatw-mt-6"
            onMouseEnter={() => {
              if (!toggleTrivia) {
                setToggleTrivia();
              }
            }}
          >
            {/*
             * this buttun is purly for accessibility purpose
             * if user presses tab toggle the 'trivia'
             */}
            <button
              type="button"
              className="naxatw-absolute"
              onFocus={() => {
                if (!toggleTrivia) {
                  setToggleTrivia();
                }
              }}
            />
            <img
              src={MobileFrame}
              alt="mobile-frame"
              className="naxatw-z-[5000]"
            />
            <div className="naxatw-absolute naxatw-left-[1.35rem] naxatw-top-[3.1rem] naxatw-h-[38.438rem] naxatw-w-[18.75rem] naxatw-overflow-hidden naxatw-rounded-bl-[45px] naxatw-rounded-br-[50px]">
              {selectedSocialLinks === 'facebook' && (
                <iframe
                  title="Facebook BEK Iframe"
                  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fukinnepal%2F&tabs=timeline&width=295&height=800&small_header=true&app_id=776730922422337&adapt_container_width=true&show_facepile=false&appId=776730922422337&hide_cover=true"
                  style={{
                    outline: 'none',
                    height: '615px',
                    borderTopRightRadius: '18px',
                    borderTopLeftRadius: '8px',
                    borderBottomLeftRadius: '45px',
                  }}
                  allowFullScreen
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                  tabIndex={-1}
                  aria-hidden="true"
                  // target="_top"
                />
              )}
              {selectedSocialLinks === 'twitter' && (
                <div
                  ref={twitterRef}
                  className="naxatw-overflow-hidden naxatw-rounded-bl-[45px] naxatw-rounded-tl-[8px] naxatw-rounded-tr-[18px]"
                >
                  <a
                    className="twitter-timeline naxatw-rounded-[60px]"
                    data-theme="dark"
                    href="https://twitter.com/UKinNepal"
                    data-width="300"
                    data-height="615"
                  />
                </div>
              )}
              {selectedSocialLinks === 'instagram' && (
                <a
                  href="https://www.instagram.com/ukinnepal/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  Visit UK in Nepal on Instagram
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </FlexRow>
  );
};

export default MobileQuestion;
