/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import ExportDescription from '@Components/common/CommonExport/Description';
import ExportLoader from '@Components/common/CommonExport/ExportLoader';
import ExportLogo from '@Components/common/CommonExport/ExportLogo';
import ExportSidebar from '@Components/common/CommonExport/ExportSidebar';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import usePrint from '@Hooks/usePrint';
import { deletePdfSection, resetOverallPage } from '@Store/actions/pdfExport';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTypedSelector } from '@Store/hooks';
import { getProvincialCountData } from '@Services/provincialProfile';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Input } from '@Components/common/FormUI';
import ReportFilters from './ReportFilters';
import ReportMap from './ReportMap';
import ReportFiveSectors from './ReportCharts/ReportFiveSectors';
import ReportFiveProgramme from './ReportCharts/ReportFiveProgramme';
import ReportNumberofProject from './ReportCharts/ReportNumberofProject';
import ReportTypesChart from './ReportCharts/ReportTypesChart';
import ReportPartner from './ReportCharts/ReportPartner';
import ReportModality from './ReportCharts/ReportModality';
import ReportStakeholderByProject from './ReportCharts/ReportStakeholderByProject';
import ReportSummary from './ReportSummary';

const ExportReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [hasDescription, setHasDescription] = useState(false);
  const [reportTitle, setReportTitle] = useState('BEK Footprint');
  const { pdfContentRef, reactToPrintFn } = usePrint({
    title: reportTitle === '' ? 'BEK Footprint' : reportTitle,
    hasDescription,
  });

  const showFilterSection = useTypedSelector(
    state => state.pdfExportSlice.bek.filters.showSection,
  );

  const showMap = useTypedSelector(
    state => state.pdfExportSlice.bek.map.showSection,
  );

  const showFiveSectors = useTypedSelector(
    state => state.pdfExportSlice.bek.fiveSector.showSection,
  );
  const showFiveProgrammes = useTypedSelector(
    state => state.pdfExportSlice.bek.fiveProgrammes.showSection,
  );

  const showStakeholderByProjectGov = useTypedSelector(
    state => state.pdfExportSlice.bek.stakeholderByProjectGov.showSection,
  );

  const showStakeholderByProjectAll = useTypedSelector(
    state => state.pdfExportSlice.bek.stakeholderByProjectAll.showSection,
  );

  const showProjectByGroup = useTypedSelector(
    state => state.pdfExportSlice.bek.projectsByGroup.showSection,
  );

  const showProjectByGoals = useTypedSelector(
    state => state.pdfExportSlice.bek.projectsByGoals.showSection,
  );

  const showExpenditurePieChart = useTypedSelector(
    state => state.pdfExportSlice.bek.expenditurePieChart.showSection,
  );

  const showBudgetPieChart = useTypedSelector(
    state => state.pdfExportSlice.bek.budgetPieChart.showSection,
  );

  const showPartnerChart = useTypedSelector(
    state => state.pdfExportSlice.bek.partnersChart.showSection,
  );

  const showModalityChart = useTypedSelector(
    state => state.pdfExportSlice.bek.modalityChart.showSection,
  );

  const showSummary = useTypedSelector(
    state => state.pdfExportSlice.bek.summary.showSection,
  );

  const showDescription = useTypedSelector(
    state => state.pdfExportSlice.bek.description.showSection,
  );

  const statusFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );

  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const projectFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );

  const subMarkersParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );

  const provinceFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.province,
  );

  const showResetButton = Object.entries(
    useTypedSelector(state => state.pdfExportSlice.bek),
  )?.some(([_, secValue]) => !secValue.showSection);

  const { data: overallCount, isLoading: isDataLoading } = useQuery({
    queryKey: ['overall-count-report'],
    queryFn: async () =>
      getProvincialCountData({
        status: statusFilterParams,
        program: programmeFilterParams?.join(','),
        project: projectFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        province: provinceFilterParams?.join(','),
        start_date: startDate,
        end_date: endDate,
      }),
    select: res => {
      if (!res?.data) return null;
      return res.data;
    },
  });

  useEffect(() => {
    return () => {
      dispatch(resetOverallPage({ page: 'bek' }));
    };
  }, []);

  return (
    <div className="export-pdf-container">
      {/* ************* SIDEBAR */}

      <ExportSidebar
        heading={reportTitle}
        onHandleBack={() => navigate(pathname.replace('/export', ''))}
        reactToPrintFn={reactToPrintFn}
        onHandleReset={() =>
          dispatch(
            resetOverallPage({
              page: 'bek',
            }),
          )
        }
        showPageResetButton={showResetButton}
      />
      <div className="pdf-content-wrapper scrollbar naxatw-h-[calc(100vh-143px)] naxatw-overflow-y-auto">
        {/* ************ Main Pdf Content Starts **************** */}
        <div
          className="naxatw-relative naxatw-mx-auto naxatw-mb-16 naxatw-w-[210mm] naxatw-bg-white"
          ref={pdfContentRef}
          id="print-content"
        >
          {isDataLoading ? (
            <ExportLoader />
          ) : (
            <div
              className="pdf-content-container naxatw-flex naxatw-flex-col naxatw-space-y-5 naxatw-px-5 naxatw-pb-5 !naxatw-text-[#000]"
              ref={pdfContentRef}
              id="print-content"
            >
              <ExportLogo />
              <div className="main-heading">
                <Input
                  value={reportTitle}
                  onChange={e => setReportTitle(e.target.value)}
                  className="bek-title !naxatw-h-auto  !naxatw-p-2 !naxatw-text-[20px] naxatw-font-semibold naxatw-leading-normal naxatw-text-[#0b2e62] naxatw-transition-colors naxatw-duration-200 naxatw-ease-in hover:!naxatw-border-secondary-500"
                />
              </div>

              {showDescription && (
                <ExportDescription
                  setHasDescription={setHasDescription}
                  onHandleClose={() =>
                    dispatch(
                      deletePdfSection({
                        page: 'bek',
                        section: 'description',
                      }),
                    )
                  }
                />
              )}

              {showFilterSection && <ReportFilters />}
              {showMap && <ReportMap />}
              {showSummary && (
                <ReportSummary overallCount={overallCount || []} />
              )}
              {showFiveSectors && <ReportFiveSectors />}
              {showFiveProgrammes && <ReportFiveProgramme />}
              {showStakeholderByProjectAll && (
                <ReportStakeholderByProject
                  type="all"
                  title="Number of Stakeholders by Project"
                />
              )}

              {(showModalityChart || showStakeholderByProjectGov) && (
                <div className="number-by-projects-charts naxatw-grid naxatw-auto-cols-fr naxatw-grid-flow-col naxatw-gap-2">
                  {showStakeholderByProjectGov && (
                    <ReportStakeholderByProject
                      type="governement"
                      title="Number of Government Stakeholders by Project"
                    />
                  )}
                  {showModalityChart && <ReportModality />}
                </div>
              )}

              {(showPartnerChart || showProjectByGroup) && (
                <div className="number-by-projects-charts naxatw-grid naxatw-auto-cols-fr naxatw-grid-flow-col naxatw-gap-2">
                  {showPartnerChart && <ReportPartner />}
                  {showProjectByGroup && (
                    <ReportNumberofProject
                      title="Number of Project by Groups"
                      groupChart
                    />
                  )}
                </div>
              )}

              {(showExpenditurePieChart || showBudgetPieChart) && (
                <div className="number-by-projects-charts naxatw-grid naxatw-auto-cols-fr naxatw-grid-flow-col naxatw-gap-2">
                  {showExpenditurePieChart && (
                    <ReportTypesChart
                      title="Overview of  Expenditure Types"
                      type="expenditure"
                    />
                  )}
                  {showBudgetPieChart && (
                    <ReportTypesChart
                      title="Overview of  Budget Type"
                      type="budget"
                    />
                  )}
                </div>
              )}
              {showProjectByGoals && (
                <div className="number-by-projects-charts naxatw-grid naxatw-auto-cols-fr naxatw-grid-flow-col naxatw-gap-2">
                  {showProjectByGoals && (
                    <ReportNumberofProject title="Number of Project by Campaign Goals" />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default hasErrorBoundary(ExportReport);
