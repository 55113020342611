import { setMainDashboardChartTabs } from '@Store/slices/mainDashboardSwitchTab';

export const {
  setSectorsSelectedTab,
  setStakeHoldersSelectedTab,
  setGovernmentStakeHoldersSelectedTab,
  setBudgetExpenditureSelectedTab,
  setStakeholderByProgramProjectSelectedTab,
  setProjectCampaignGoalSelectedTab,
  setAgreementModalitySelectedTab,
} = setMainDashboardChartTabs.actions;
