/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { Button } from '@Components/RadixComponents/Button';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { useForm } from 'react-hook-form';
import InputLabel from '@Components/common/InputLabel';
import { FormControl, Input } from '@Components/common/FormUI';
import ErrorMessage from '@Components/common/ErrorMessage';
import ModalContainer from '@Components/common/FormUI/ModalContainer';
import { shareUpdateInfo } from '@Services/federalInfoUpdate';
import { useEffect, useState } from 'react';
import EmailsBox from '@Components/KnowledgeRepository/CardSection/ShareModal/EmailsBox';
import isEmpty from '@Utils/isEmpty';

interface IUpdateShareModalProps {
  onHandleClose: () => void;
  updateId: number | undefined | string;
}

const UpdateShareModal = ({
  onHandleClose,
  updateId,
}: IUpdateShareModalProps) => {
  const [emails, setEmails] = useState<string>('');
  const [checkValidation, setCheckValidation] = useState(false);
  const naviagte = useNavigate();

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    clearErrors,
    watch,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<{ subject: string; description: string; emails: string[] }>({
    defaultValues: {
      subject: '',
      description: '',
      emails: [],
    },
  });

  const watchEmails: string[] = getValues('emails');

  // share update
  const { mutateAsync: postShareData } = useMutation({
    mutationKey: ['post-update-share'],
    mutationFn: async (payload: Record<string, any>) =>
      shareUpdateInfo(payload),
    onSuccess: () => {
      toast.success('Udpdate Info Share Succesfully');
      onHandleClose();
      naviagte(`/update/${updateId}`);
    },
  });

  const handleEmailDelete = (emailIndex: any) => {
    const emailsValues = watch('emails');
    const updatedEmails = emailsValues.filter(
      (_: any, index: number) => index !== emailIndex,
    );
    setValue('emails', updatedEmails);
  };

  const handleKeyDown = (e: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (e.key === ',' || e.key === 'Enter') {
      e.preventDefault();
      const trimmedEmail = emails.trim();
      if (!emailRegex.test(trimmedEmail)) {
        setError('emails', {
          type: 'manual',
          message: 'Please enter a valid email address',
        });
      } else {
        clearErrors('emails');
        setValue('emails', [...watchEmails, trimmedEmail]);
        setEmails('');
      }
    }
  };

  const handleFormSubmission = async (): Promise<void> => {
    try {
      const { emails: allEmails, ...formData } = getValues();

      if (isEmpty(allEmails)) {
        setError('emails', {
          type: 'manual',
          message: 'Please enter at least one email address',
        });
        return;
      }

      const finalPostValues = {
        id: updateId,
        emails: allEmails?.join(','),
        ...formData,
      };

      await postShareData(finalPostValues);
      return;
    } catch (error: any) {
      const caughtError = error?.response?.data?.message;
      toast.error(caughtError || 'Something went wrong');
    }
  };

  useEffect(() => {
    if (checkValidation) {
      if (isEmpty(watchEmails)) {
        setError('emails', {
          type: 'manual',
          message: 'Please enter at least one email address',
        });
      }
    }
  }, [checkValidation, setError, emails]);

  useEffect(() => {
    if (emails) {
      clearErrors('emails');
    }
  }, [emails]);

  return (
    <ModalContainer title="Share Updates" onHandleClose={onHandleClose}>
      <form onSubmit={handleSubmit(handleFormSubmission)}>
        <div className="forms-field-container naxatw-px-5 naxatw-pt-6">
          {/* subject */}
          <FormControl className="naxatw-mb-5">
            <FlexColumn gap={1}>
              <InputLabel label="Subject" astric />
              <Input
                className="naxatw-text-sm  naxatw-text-[#545454]"
                placeholder="Add Subject"
                {...register('subject', {
                  required: 'Subject is required',
                  validate: value => {
                    if (!value.trim()) {
                      return 'Subject cannot be empty or just spaces';
                    }
                    return true;
                  },
                })}
              />
              {errors?.subject?.message && (
                <ErrorMessage message={errors?.subject?.message as string} />
              )}
            </FlexColumn>
          </FormControl>
          {/* description */}
          <FormControl className="naxatw-mb-5">
            <FlexColumn gap={1}>
              <InputLabel label="Description" />
              <textarea
                className="naxatw-resize-none  naxatw-rounded-lg naxatw-border naxatw-border-grey-300
            naxatw-bg-transparent  naxatw-p-3 naxatw-text-sm naxatw-leading-[1.125rem]  naxatw-text-[#545454]
             naxatw-outline-primary-400 placeholder:naxatw-text-grey-400 hover:naxatw-border-primary-400 focus:naxatw-border-primary-400 focus:naxatw-bg-transparent focus:naxatw-outline-none"
                placeholder="Enter Description"
                rows={3}
                {...register('description')}
              />
            </FlexColumn>
          </FormControl>

          {/* email */}
          <FormControl className="naxatw-mb-5">
            <FlexColumn gap={1}>
              <InputLabel label="Emails" astric />
              <Input
                className="naxatw-text-sm  naxatw-text-[#545454]"
                placeholder="Enter Email and Press Enter to Add"
                onChange={e => setEmails(e.target.value)}
                onKeyDown={e => handleKeyDown(e)}
                value={emails}
              />
              {errors?.emails?.message && (
                <ErrorMessage message={errors?.emails?.message as string} />
              )}
              <FlexRow className="naxatw-mt-2 naxatw-w-full naxatw-flex-wrap naxatw-gap-2">
                {watchEmails?.map((email: string, index: any) => (
                  <EmailsBox
                    email={email}
                    removeEmail={() => handleEmailDelete(index)}
                    key={email}
                  />
                ))}
              </FlexRow>
            </FlexColumn>
          </FormControl>
        </div>

        <div className="naxatw-flex naxatw-justify-center naxatw-py-5 naxatw-shadow-formshadow">
          <Button
            size="sm"
            variant="primary"
            className="!naxatw-p-4"
            type="submit"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={() => setCheckValidation(true)}
          >
            Share
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default hasErrorBoundary(UpdateShareModal);
