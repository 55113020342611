import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportBtn from '@Components/common/CommonExport/ExportHeader/ExportBtn';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import MarkerCard from '@Components/ProgrammeDetails/Markers/card';
import { getMarkers } from '@Services/program';
import {
  deleteIndividualList,
  deletePdfSection,
  resetPdfSection,
  setOriginalDataLists,
} from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import isEmpty from '@Utils/isEmpty';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const PdfMarkers = () => {
  const { programmeId, projectId } = useParams();
  const dispatch = useDispatch();

  const pageType = projectId ? 'projectDetails' : 'programmeDetails';

  const markerLists = useTypedSelector<readonly number[]>(state =>
    projectId
      ? state.pdfExportSlice.projectDetails.markers.datalists
      : state.pdfExportSlice.programmeDetails.markers.datalists,
  );

  const showResetButton = useTypedSelector(state =>
    projectId
      ? state.pdfExportSlice.projectDetails.markers.showResetButton
      : state.pdfExportSlice.programmeDetails.markers.showResetButton,
  );

  const { data: markersData } = useQuery({
    queryKey: ['markers-pdf', programmeId, projectId],
    queryFn: () =>
      getMarkers({
        ...(projectId ? { project: projectId } : { program: programmeId }),
      }),
    select: res => {
      if (!res?.data) return [];
      const markers = res.data;
      const manipulatedData = markers
        .filter(
          (marker: Record<string, any>) =>
            marker.marker_category !== 'Group Marker',
        )
        .map((marker: Record<string, any>) => ({
          id: marker.marker_category,
          markers: marker.marker_value,
          markerName: marker.marker_category,
        }));
      return manipulatedData;
    },
    onSuccess: response => {
      if (isEmpty(response)) return;
      dispatch(
        setOriginalDataLists({
          page: pageType,
          section: 'markers',
          value: response,
        }),
      );
    },
  });

  if (markersData && isEmpty(markersData)) return null;

  return (
    <div
      className="pdf-markers-container"
      style={{
        pageBreakInside: 'avoid',
      }}
    >
      <ExportHeader
        headerTitle="Markers"
        isStyleAbsolute={false}
        showResetButton={showResetButton}
        onHandleDelete={() => {
          dispatch(
            deletePdfSection({
              page: pageType,
              section: 'markers',
            }),
          );
        }}
        onHandleReset={() => {
          dispatch(
            resetPdfSection({
              page: pageType,
              section: 'markers',
            }),
          );
        }}
      />
      <div className="markers-con naxatw-grid naxatw-grid-cols-1 naxatw-gap-2  md:naxatw-grid-cols-2">
        {markersData
          ?.filter((marker: Record<string, any>) =>
            markerLists.includes(marker.id),
          )

          ?.map((marker: Record<string, any>) => {
            return (
              <div
                key={marker.id}
                className="naxatwh-full naxatw-group naxatw-relative"
              >
                <MarkerCard
                  markerName={marker.markerName}
                  markers={marker.markers}
                  isExport
                />
                <ExportBtn
                  size="sm"
                  onHandleClose={() =>
                    dispatch(
                      deleteIndividualList({
                        page: pageType,
                        section: 'markers',
                        key: 'datalists',
                        value: marker.id,
                      }),
                    )
                  }
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default hasErrorBoundary(PdfMarkers);
