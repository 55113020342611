/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import FormStepsWrapper from '@Components/common/FormComponent/FormStepsWrapper';
import FormWrapper from '@Components/common/FormComponent/FormWrapper';
import { knowledgeRepoMultiStepTitle } from '@Constants/FormConstants/knowledgeRepoConstants';
import MultiStep from '@Components/common/FormComponent/Multistep';
import { knowledgeRepoFormType } from '@Constants/Types/FormTypes/knowledgeRepository';
import FormContainer from '@Components/common/FormComponent/FormContainer';
import {
  KnowledgeRepoBasicDetailValidationSchema,
  knowledgeRepoFormTypeEnum,
} from '@Validations/KnowledgeRepositoryForm';
import { Button } from '@Components/RadixComponents/Button';
import Icon from '@Components/common/Icon';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  deleteAgendaEvidenceRepo,
  deleteGesiRepo,
  deleteGraphicalFocusRepo,
  postKnowRepoEviData,
  postKnowRepoEviDoc,
  postKnowRepoEviProgrammes,
  postKnowRepoEviFocus,
  postKnowRepoEviGesi,
  postKnowRepoEviAgenda,
  getKnowRepoEviById,
  deleteKnoRepoEviDoc,
  deleteKnowRepoEviAgenda,
  patchKnoRepoEviData,
  postKnowRepoEviProject,
  deleteknowRepoEviProgram,
  deleteknowRepoEviProject,
} from '@Services/knowledgeRepository';
import isEmpty from '@Utils/isEmpty';
import { convertBooleanToString, convertStringToBoolean } from '@Utils/index';
import prepareFormData from '@Utils/prepareFormData';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import getCheckboxCheckedIds, {
  getCheckboxChildrenCheckedId,
  setSingleParentChildCheckbox,
} from '@Utils/getCheckedValues';
import BasicDetails from './BasicDetails';
import DocumentDetails from './DocumentDetails';
import UploadDocuments from './UploadDocuments';

const FormComponent = ({ type }: Record<string, any>) => {
  switch (type) {
    case 'basicDetails':
      return <BasicDetails />;
    case 'documentDetails':
      return <DocumentDetails />;
    case 'uploadDocuments':
      return <UploadDocuments />;
    default:
      return <BasicDetails />;
  }
};

const EvidenceAgenda = () => {
  const { id: knowledgeRepoId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [formType, setFormType] =
    useState<knowledgeRepoFormType>('basicDetails');
  const [actualDetail, setActualDetail] = useState<Record<string, any>>({});
  const [completedForm, setCompletedForm] = useState<string[]>([]);

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      // is_contextual_indicator: false,
      // is_evidence_agenda: true,
      title: '',
      file_type: '',
      associate_program_project: {},
      other_program_project: '',
      published_date: '',
      summary: '',
      evidence_agenda_status: '',
      tags: '',
      links: '',
      documents: [],
      documentToDelete: null,
      agenda_evidence_graphical_focus: [],
      agenda_evidence_gesi: [],
      agenda_evidence: [],
      is_public: '',
      // role: null,
      // category: null,
      sub_category: {},
    },
    resolver:
      formType === 'documentDetails' || formType === 'uploadDocuments'
        ? undefined
        : zodResolver(KnowledgeRepoBasicDetailValidationSchema),
  });

  const {
    handleSubmit,
    getValues,
    reset,
    setValue,
    watch,
    formState: { dirtyFields, isDirty },
  } = formMethods;

  const documentToDelete = watch('documentToDelete');

  //! -------------------------------------------------------------> DELETE API STARTS
  // ------> Delete knowledge repository documents
  const {
    mutate: deleteKnowledgeRepositoryDocument,
    isError: isDeleteDocumentError,
    error: deleteDocumentError,
    isLoading: isDeleteDocumentLoading,
  } = useMutation({
    mutationFn: async () => {
      if (!documentToDelete) return;
      await deleteKnoRepoEviDoc(+documentToDelete);
    },
    onSuccess: () => {
      const documentList = getValues('documents');
      const updatedDocuments = documentList?.filter(
        (document: Record<string, any>) => document?.id !== documentToDelete,
      );
      setValue('documents', updatedDocuments);
      toast.success('Document deleted successfully');
      setValue('documentToDelete', null);
    },
  });

  // delete gesi Repo
  const { mutateAsync: deleteGesiRepoData, isLoading: isGesiDelLoading } =
    useMutation({
      mutationFn: (id: number) => deleteGesiRepo(id),
    });

  // delete geographic Repo
  const {
    mutateAsync: deleteGraphicalFocusRepoData,
    isLoading: isGeoDelLoading,
  } = useMutation({
    mutationFn: (id: number) => deleteGraphicalFocusRepo(id),
  });

  // delete agenda Evidence Repo
  const {
    mutateAsync: deleteAgendaEvidenceRepoData,
    isLoading: isAgendaDelLoading,
  } = useMutation({
    mutationFn: (id: number) => deleteAgendaEvidenceRepo(id),
  });

  // delete project data
  const { mutateAsync: deleteProjectData } = useMutation({
    mutationFn: (id: number) => deleteknowRepoEviProject(id),
  });

  // delete programme  data
  const { mutateAsync: deleteProgrammeData } = useMutation({
    mutationFn: (id: number) => deleteknowRepoEviProgram(id),
  });

  //  delete know Repo Role Id
  // const { mutateAsync: deleteKnowRepoRoleData } = useMutation({
  //   mutationFn: (id: number) => delKnowRepoRole(id),
  // });

  //! --------------------------------------------------------------------> DELETE API ENDS
  //! --------------------------------------------------------------------> POST API STARTS
  // -----------> Post knowledge repository
  const {
    mutateAsync: postKnowledgeRepositoryData,
    isLoading: isPostKnowledgeRepositoryDataLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowRepoEviData(payloadData),
  });

  // -----------> Post knowledge repository documents
  const {
    mutateAsync: postKnowledgeRepositoryDocumentData,
    isLoading: isPostKnowledgeRepositoryDocumentLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowRepoEviDoc(payloadData),
    onError: () => toast.error('Failed to attach documents. Try again'),
  });

  // ----------> Mutation for posting Evidence Agendas
  const {
    mutateAsync: postEvidenceAgendaData,
    isLoading: isPostEvidenceAgendaDataLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowRepoEviAgenda(payloadData),
  });

  // ----------> Mutation for posting GEsi Evidence Agenada
  const {
    mutateAsync: postGesiAgendaData,
    isLoading: isPostGesiEvidenceAgendaDataLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowRepoEviGesi(payloadData),
  });

  // ----------> Mutation for posting GEsi Evidence Agenada
  const {
    mutateAsync: postGeographicFocusAgendaData,
    isLoading: isPostGeographicFocusAgendaDataLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowRepoEviFocus(payloadData),
  });

  // ---------->  Mutation for posting knowledge repository data for programmes
  const {
    mutateAsync: postKnowledgeRepositoryProgrammesData,
    isLoading: isPostKnowledgeRepositoryProgrammesLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowRepoEviProgrammes(payloadData),
  });

  // ---------->  Mutation for posting knowledge repository data for project
  const {
    mutateAsync: postKnowledgeRepositoryProjectsData,
    isLoading: isPostKnowledgeRepositoryProjectsLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      postKnowRepoEviProject(payloadData),
  });

  // -----------> Mutation for posting knowledge repository role
  // const { mutateAsync: postKnowRepoRoleData, isLoading: isPostknowRepoRole } =
  //   useMutation({
  //     mutationFn: (payload: Record<string, any>) => postKnowRepoRole(payload),
  //   });

  //! --------------------------------------------------------------------> POST API ENDS

  //! Patch knowledge repository
  const {
    mutateAsync: patchKnowledgeRepositoryData,
    isLoading: isPatchKnowledgeRepositoryDataLoading,
  } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      patchKnoRepoEviData(Number(knowledgeRepoId), payloadData),
  });

  //! queries for edit****************************************************************
  const { isLoading: isKnowledgeRepoFetching } = useQuery({
    enabled: !!knowledgeRepoId,
    queryKey: ['knowledgerepo-form-data'],
    queryFn: () =>
      getKnowRepoEviById({
        id: knowledgeRepoId ? +knowledgeRepoId : '',
      }),
    onError: () =>
      toast.error('Failed to fetch knowledge repository form data'),
    select: responseData => responseData?.data?.results[0],
    onSuccess: data => {
      if (!data) return;
      // to compare with edited data
      setActualDetail(data);

      const {
        programs,
        // gesi,
        // evidence_agenda: evidenceAgenda,
        other_program_project,
      } = data;

      Object.keys(data)?.forEach((key: any) => {
        if (key === 'is_public') {
          const isPublic = convertBooleanToString(data[key]);
          setValue('is_public', isPublic);
        } else if (key === 'evidence_agenda') {
          setValue(
            'agenda_evidence',
            data.evidence_agenda?.map((agenda: any) => agenda.agenda_evidence),
          );
        } else if (key === 'gesi') {
          setValue(
            'agenda_evidence_gesi',
            data.gesi?.map((agenda: any) => agenda.gesi_option),
          );
        } else if (key === 'geographic_focus') {
          setValue(
            'agenda_evidence_graphical_focus',
            data.geographic_focus?.map(
              (agenda: any) => agenda.geographic_focus,
            ),
          );
        } else if (key === 'documents') {
          const allDocuments = data[key];
          const finalDocuments = allDocuments?.map(
            (doc: Record<string, any>) => {
              return {
                file: doc.file,
                id: doc.id,
              };
            },
          );
          setValue('documents', finalDocuments);
        } else if (key === 'sub_category' || key === 'category') {
          setValue(
            key,
            setSingleParentChildCheckbox(data.sub_category, data.category),
          );
        }

        // else if (key === 'role') {
        //   const roleData = data[key];
        //   if (isEmpty(roleData)) return;
        //   const roles = roleData?.map(
        //     (roleId: Record<string, any>) => roleId.role__id,
        //   );
        //   setValue('role', roles);
        // }
        else {
          setValue(key, data[key]);
        }
      });

      const result = programs?.reduce(
        (acc: Record<string, any>, program: Record<string, any>) => {
          const parentId = program?.associate_program__id;
          if (parentId) {
            acc[`parent_${parentId}`] = true;
          }

          program.projects?.forEach((project: Record<string, any>) => {
            if (project.id) {
              acc[`child_${project.id}`] = true;
            }
          });

          return acc;
        },
        {},
      );
      if (other_program_project) {
        setValue('associate_program_project', {
          ...result,
          ...{ parent_other: true },
        });
      } else {
        setValue('associate_program_project', result);
      }
    },
  });

  //! --------------------------------------------------------------------> HELPER FUNC STARTS

  // ------> Helper function to handle document uploads
  const postDocuments = async (
    documents: Record<string, any>[],
    id: string | undefined,
  ) => {
    const documentPromises = documents.map(document => {
      const formData = {
        file: document?.file,
        evidence_agenda: id,
      };
      return postKnowRepoEviDoc(prepareFormData(formData));
    });

    await Promise.all(documentPromises);
  };

  // --------> Helper function to handle associated prgrammes and projects posts
  const postAssociatedProjectsAndProgrammes = async (
    programmeProjectFormValues: Record<string, any>,
    id: string | undefined,
  ) => {
    const projectAndProgrammePromises = Object.entries(
      programmeProjectFormValues,
    ).map(async ([key, value]) => {
      if (key === 'parent_other') return; // Skip specific key
      if (key.startsWith('parent_')) {
        const programmeId = Number(key.split('_')[1]);
        if (programmeId && value) {
          await postKnowledgeRepositoryProgrammesData({
            evidence_agenda: id,
            associate_program: programmeId,
          });
        }
      }

      if (key.startsWith('child_')) {
        const projectId = Number(key.split('_')[1]);
        if (projectId && value) {
          await postKnowledgeRepositoryProjectsData({
            evidence_agenda: id,
            project: projectId,
          });
        }
      }
    });

    // Wait for all promises to complete
    await Promise.all(projectAndProgrammePromises);
  };

  //  helper to handle deleting related items (GESI, Graphical Focus, Evidence)
  const handleDeleteRelatedItems = async (
    actualDetails: any,
    deleteFn: Function,
  ) => {
    const deletePromises = actualDetails?.map(
      async (data: Record<string, any>) => {
        await deleteFn(data.id);
      },
    );
    await Promise.all(deletePromises);
  };

  //!  helper to post role and now removed as it is no longer needed
  // const postRole = async (roles: number[], id: string | undefined) => {
  //   const rolePromises = roles?.map(role => {
  //     const roleData = {
  //       role,
  //       knowledge_repository: id,
  //     };
  //     return postKnowRepoRoleData(roleData);
  //   });

  //   await Promise.all(rolePromises);
  // };

  //! --------------------------------------------------------------------> HELPER FUNC ENDS

  //! --------> Post Knowledge Repository
  const postKnowledgeRepo = async (submittedData: Record<string, any>) => {
    if (isEmpty(submittedData)) return;

    const {
      associate_program_project: programmeProjectFormValues,
      documents,
      agenda_evidence_graphical_focus: agendaEvidenceGeographicalFocus,
      agenda_evidence_gesi: agendaEvidenceGESI,
      agenda_evidence: agendaEvidenceOthers,
      is_public = 'false',
      role,
      sub_category: subCat,
      ...restData
    } = submittedData;
    const isPublic = convertStringToBoolean(is_public);

    // post functions
    const responseknowRepo = await postKnowledgeRepositoryData({
      is_public: isPublic,
      sub_category: getCheckboxChildrenCheckedId(subCat),
      ...restData,
    });

    // //! -----------> post documents ***********************
    if (!isEmpty(documents)) {
      postDocuments(documents, responseknowRepo?.data?.id);
    }

    //! ----------> post associated project and programmes
    if (!isEmpty(programmeProjectFormValues)) {
      postAssociatedProjectsAndProgrammes(
        programmeProjectFormValues,
        responseknowRepo?.data?.id,
      );
    }

    //! --------->  post agenda evidence
    // Other agendas
    agendaEvidenceOthers?.forEach(async (agenda: number) => {
      await postEvidenceAgendaData({
        evidence_agenda: responseknowRepo?.data?.id,
        agenda_evidence: agenda,
      });
    });

    //! ----------> post role if present and now removed as per new requirement
    // if (role) {
    //   if (isEmpty(role)) return;
    //   await postRole(role, responseknowRepo?.data?.id);
    // }

    agendaEvidenceGESI?.forEach(async (agenda: number) => {
      await postGesiAgendaData({
        evidence_agenda: responseknowRepo?.data?.id,
        gesi_option: agenda,
      });
    });

    agendaEvidenceGeographicalFocus?.forEach(async (agenda: number) => {
      await postGeographicFocusAgendaData({
        evidence_agenda: responseknowRepo?.data?.id,
        geographic_focus: agenda,
      });
    });
    // posts end *****************
  };

  //! --------> Patch Knowledge Repository
  const patchKnowledgeRepo = async (formValues: Record<string, any>) => {
    if (isEmpty(formValues)) return;

    const {
      documents,
      associate_program_project: progProject,
      thematic_field: thematicField,
      ...values
    } = formValues;

    let otherValues: Record<string, any> = {};

    Object.entries(values).forEach(async ([key, value]) => {
      if (key === 'agenda_evidence') {
        const removedIds: number[] = [];
        const existingIds = actualDetail?.evidence_agenda?.map(
          (agenda: any) => {
            if (!value.includes(agenda.agenda_evidence)) {
              removedIds.push(agenda.id);
            }
            return agenda.agenda_evidence;
          },
        );
        const addedIds = value?.filter(
          (newValue: any) => !existingIds?.includes(newValue),
        );

        // post new agenda added
        const agendaAddPromises = addedIds?.map(async (id: number) => {
          await postEvidenceAgendaData({
            evidence_agenda: knowledgeRepoId,
            agenda_evidence: id,
          });
        });

        // delete agenda
        const agendaDeletePromises = removedIds?.map(async (id: number) => {
          await deleteKnowRepoEviAgenda(id);
        });

        await Promise.all([...agendaAddPromises, agendaDeletePromises]);
      } else if (key === 'agenda_evidence_gesi') {
        const removedIds: number[] = [];
        const existingIds = actualDetail?.gesi?.map((agenda: any) => {
          if (!value?.includes(agenda.gesi_option)) {
            removedIds.push(agenda.id);
          }
          return agenda?.gesi_option;
        });

        const addedIds = value?.filter(
          (newValue: any) => !existingIds?.includes(newValue),
        );

        // post new agenda GESI added
        const agendaGESIAddPromises = addedIds?.map(async (id: number) => {
          await postGesiAgendaData({
            evidence_agenda: knowledgeRepoId,
            gesi_option: id,
          });
        });
        // delete GESI agendas
        const agendaDeletePromises = removedIds?.map(async (id: number) => {
          await deleteGesiRepoData(id);
        });

        await Promise.all([...agendaGESIAddPromises, ...agendaDeletePromises]);
        // ********************
      } else if (key === 'agenda_evidence_graphical_focus') {
        const removedIds: number[] = [];
        const existingIds = actualDetail?.geographic_focus?.map(
          (agenda: any) => {
            if (!value.includes(agenda.geographic_focus)) {
              removedIds.push(agenda.id);
            }
            return agenda.geographic_focus;
          },
        );

        const addedIds = value?.filter(
          (newValue: any) => !existingIds?.includes(newValue),
        );

        // post new agenda graphical Focus added
        const agendaGraphicalFocusAddPromises = addedIds?.map(
          async (id: number) => {
            await postGeographicFocusAgendaData({
              evidence_agenda: knowledgeRepoId,
              geographic_focus: id,
            });
          },
        );

        // delete Graphical Focus agendas
        const agendaGraphicalFocusDeletePromises = removedIds?.map(
          async (id: number) => {
            await deleteGraphicalFocusRepoData(id);
          },
        );
        await Promise.all([
          ...agendaGraphicalFocusAddPromises,
          ...agendaGraphicalFocusDeletePromises,
        ]);
      }
      //! is_public is removed now
      //  else if (key === 'is_public') {
      //   const newIsPublicValue = convertStringToBoolean(value);
      //   otherValues.is_public = newIsPublicValue;
      //   if (newIsPublicValue) {
      //     if (isEmpty(actualDetail?.role)) return;

      //     const roleIdToDel = actualDetail?.role?.map(
      //       (role: Record<string, any>) => role.id,
      //     );

      //     await Promise.all(
      //       roleIdToDel?.map(async (roleId: number) => {
      //         await deleteKnowRepoRoleData(roleId);
      //       }),
      //     );
      //   }
      // }
      //! role is removed as per new requirement
      // else if (key === 'role') {
      //   const previousRoleIds = actualDetail?.role.map(
      //     (roleId: Record<string, any>) => roleId.role__id,
      //   );
      //   const newPatchedRoleIds = formValues?.role;

      //   if (!isEmpty(newPatchedRoleIds)) {
      //     await Promise.all(
      //       newPatchedRoleIds.map(async (roleId: number) => {
      //         if (!previousRoleIds.includes(roleId)) {
      //           const roleData = {
      //             role: roleId,
      //             knowledge_repository: Number(knowledgeRepoId),
      //           };
      //           await postKnowRepoRoleData(roleData);
      //         }
      //         return null;
      //       }),
      //     );
      //   }

      //   if (!isEmpty(previousRoleIds)) {
      //     await Promise.all(
      //       previousRoleIds.map(async (roleId: number) => {
      //         const delRoleId = actualDetail?.role.find(
      //           (role: Record<string, any>) => role.role__id === roleId,
      //         )?.id;

      //         if (!newPatchedRoleIds.includes(roleId) && delRoleId) {
      //           await deleteKnowRepoRoleData(delRoleId);
      //         }
      //         return null;
      //       }),
      //     );
      //   }
      // }
      else {
        otherValues = {
          ...otherValues,
          [key]: value,
        };
      }
    });

    if (dirtyFields.associate_program_project) {
      //! empty other_program_project if other checkbox is unchecked
      Object.entries(progProject).forEach(([key, value]) => {
        if (key === 'parent_other' && value === false) {
          otherValues.other_program_project = '';
        }
      });

      const { checkedChildIds, checkedParentIds } =
        getCheckboxCheckedIds(progProject);

      const programmeIdsToRemove: number[] = [];
      const projectIdsToRemove: number[] = [];

      const existingProgrammeIds: number[] = [];
      const existingProjectIds: number[] = [];

      actualDetail.programs.forEach((programme: any) => {
        if (!checkedParentIds.includes(programme?.id)) {
          programmeIdsToRemove.push(programme?.id);
        }
        existingProgrammeIds.push(programme?.id);

        if (programme?.projects?.length) {
          programme?.projects?.forEach((project: any) => {
            if (!checkedChildIds.includes(project.id)) {
              projectIdsToRemove.push(project.obj_id);
            }
            existingProjectIds.push(project.id);
          });
        }
      });

      const programmeIdsToAdd = checkedParentIds.filter(
        checkedId => !existingProgrammeIds.includes(checkedId),
      );
      const projectIDsToAdd = checkedChildIds.filter(
        checkedId => !existingProjectIds.includes(checkedId),
      );

      const programmeAddPromises = programmeIdsToAdd?.map(
        async (id: number) => {
          await postKnowledgeRepositoryProgrammesData({
            evidence_agenda: knowledgeRepoId,
            associate_program: id,
          });
        },
      );

      const projectAddPromises = projectIDsToAdd?.map(async (id: number) => {
        await postKnowledgeRepositoryProjectsData({
          evidence_agenda: knowledgeRepoId,
          project: id,
        });
      });

      // console.log('programmeIdsToRemove', programmeIdsToRemove);
      // console.log('projectIdsToRemove', projectIdsToRemove);

      const programmeRemovePromises = programmeIdsToRemove
        .filter(id => id) // Filter out falsy values
        .map(async (id: number) => {
          await deleteProgrammeData(id);
        });

      const projectRemovePromises = projectIdsToRemove
        .filter(id => id)
        .map(async (id: number) => {
          await deleteProjectData(id);
        });

      await Promise.all([
        ...programmeAddPromises,
        ...projectAddPromises,
        ...programmeRemovePromises,
        ...projectRemovePromises,
      ]);
    }

    if (!isEmpty(otherValues)) {
      const finalPatchedData = {
        ...otherValues,
        ...(otherValues?.sub_category
          ? {
              sub_category: getCheckboxChildrenCheckedId(
                otherValues.sub_category,
              ),
            }
          : {}),
      };
      const response = await patchKnowledgeRepositoryData(finalPatchedData);
      await Promise.all([response]);
    }

    //  handle file added ****section
    if (!documents || (documents && documents?.length === 0)) return;
    const FileAddedPromises = documents?.map(
      async (document: Record<string, any>) => {
        if (document?.file instanceof File) {
          const documentFormData = {
            file: document?.file,
            evidence_agenda: knowledgeRepoId,
          };
          await postKnowledgeRepositoryDocumentData(
            prepareFormData(documentFormData),
          );
        }
      },
    );
    await Promise.all(FileAddedPromises);
    // handle file added*****section**end
  };

  const getDirtyFieldValues = () => {
    const allValues = getValues();
    const dirtyValues: any = {};
    Object.keys(allValues).forEach((key: string) => {
      if (dirtyFields[key as keyof typeof dirtyFields]) {
        dirtyValues[key] = allValues[key as keyof typeof dirtyFields];
      }
    });

    return dirtyValues;
  };

  //! -----------------> Handles Form Submit
  const handleFormSubmit = async () => {
    try {
      const allValues = getValues();
      const editFields = getDirtyFieldValues();
      if (knowledgeRepoId) {
        if (isDirty === false) {
          navigate('/data-bank/knowledge-repository/evidence-agenda');
          return;
        }
        await patchKnowledgeRepo(editFields);
      } else {
        await postKnowledgeRepo(allValues);
      }
      toast.success(
        knowledgeRepoId
          ? 'Evidence Agenda updated successfully'
          : 'Evidence Agenda added successfully ',
      );
      queryClient.invalidateQueries({
        queryKey: ['knowledge-repository-evi-tabledata'],
      });
      reset();
      navigate('/data-bank/knowledge-repository/evidence-agenda');
    } catch (err: any) {
      const caughtedError = err?.response?.data?.error[0]?.details;
      toast.error(caughtedError || 'Error occured while saving!');
    }
  };

  // ----------> handles next button and submit data
  const handleNextFormType = async () => {
    switch (formType) {
      case 'basicDetails':
        setFormType(knowledgeRepoFormTypeEnum.DocumentDetails);
        setCompletedForm([...completedForm, 'basicDetails']);
        break;

      case 'documentDetails':
        setFormType(knowledgeRepoFormTypeEnum.UploadDocuments);
        setCompletedForm([...completedForm, 'documentDetails']);
        break;

      case 'uploadDocuments':
        handleFormSubmit();
        break;
      default:
        break;
    }
  };

  // --------> handles previous button
  const handleBack = () => {
    switch (formType) {
      case 'documentDetails':
        setFormType(knowledgeRepoFormTypeEnum.BasicDetails);
        setCompletedForm(
          completedForm.filter(filledForm => filledForm !== 'basicDetails'),
        );
        break;

      case 'uploadDocuments':
        setFormType('documentDetails');
        setCompletedForm(
          completedForm.filter(filledForm => filledForm !== 'documentDetails'),
        );
        break;
      default:
        break;
    }
  };

  const overalLoading =
    isPostKnowledgeRepositoryDataLoading ||
    isPostKnowledgeRepositoryDocumentLoading ||
    isPostKnowledgeRepositoryProgrammesLoading ||
    isPostKnowledgeRepositoryProjectsLoading ||
    isPatchKnowledgeRepositoryDataLoading ||
    isGesiDelLoading ||
    isAgendaDelLoading ||
    isPostEvidenceAgendaDataLoading ||
    isPostGesiEvidenceAgendaDataLoading ||
    isPostGeographicFocusAgendaDataLoading ||
    isGeoDelLoading;

  return (
    <FormWrapper
      paramId={
        knowledgeRepoId ? 'Evidence Agenda Edit Form' : 'Evidence Agenda Form '
      }
      onHandleBack={() =>
        navigate('/data-bank/knowledge-repository/evidence-agenda')
      }
    >
      {/* ----------->   Form Steps */}
      <FormStepsWrapper>
        {knowledgeRepoMultiStepTitle?.map((step, index) => {
          return (
            <MultiStep
              key={step.id}
              id={step.id}
              title={step.name}
              position={index + 1}
              isactive={formType === step.value}
              iscompleted={completedForm.includes(step.value)}
              onClick={handleSubmit(() => {
                setFormType(step.value);
              })}
            />
          );
        })}
      </FormStepsWrapper>

      {/* ---------> Main Form Knowledge Repository */}

      <FormContainer>
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit(handleNextFormType)}
            className="naxatw-flex naxatw-h-full naxatw-flex-col"
          >
            <div className="naxatw-mr-[4px] naxatw-flex-1 naxatw-pt-5">
              {knowledgeRepoId && isKnowledgeRepoFetching ? (
                <div className="naxatw-px-6">
                  <FormSkeleton numRows={5} className="naxatw-w-full" />
                </div>
              ) : (
                <div className="basic-details-form-container scrollbar naxatw-h-[calc(100vh-440px)] naxatw-w-full naxatw-overflow-y-auto naxatw-px-10 lg:naxatw-h-[calc(100vh-290px)]">
                  <FormComponent type={formType} />
                </div>
              )}
            </div>

            <div
              className={`naxatw-flex naxatw-shadow-formshadow  ${
                formType === knowledgeRepoFormTypeEnum.BasicDetails
                  ? 'naxatw-justify-end'
                  : 'naxatw-justify-between'
              } naxatw-px-7 naxatw-py-5`}
            >
              {formType !== knowledgeRepoFormTypeEnum.BasicDetails ? (
                <Button
                  size="normal"
                  type="button"
                  onClick={() => {
                    handleBack();
                  }}
                  variant="link"
                >
                  <Icon name="arrow_back" />
                  Previous
                </Button>
              ) : null}
              <div className="naxatw-cursor-not-allowed">
                <Button
                  type="submit"
                  size="normal"
                  variant="primary"
                  className="disabled:!naxatw-cursor-not-allowed"
                  disabled={overalLoading}
                  isLoading={overalLoading}
                >
                  {formType === knowledgeRepoFormTypeEnum.UploadDocuments ? (
                    'Save'
                  ) : (
                    <>
                      Next
                      <Icon name="arrow_forward" />
                    </>
                  )}
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </FormContainer>
      {documentToDelete && (
        <Portal>
          <div className="naxatw-absolute naxatw-left-0 naxatw-top-0 naxatw-h-[100vh] naxatw-w-[100vw] naxatw-bg-black naxatw-opacity-30" />
          <DeleteConfirmationOverlay
            onClose={() => setValue('documentToDelete', null)}
            onDelete={() => deleteKnowledgeRepositoryDocument()}
            isError={isDeleteDocumentError}
            isLoading={isDeleteDocumentLoading}
            error={deleteDocumentError}
          />
        </Portal>
      )}
    </FormWrapper>
  );
};

export default hasErrorBoundary(EvidenceAgenda);
