import Icon from '@Components/common/Icon';
import IconButton from '@Components/common/IconButton';
import { Button } from '@Components/RadixComponents/Button';
import { cn } from '@Utils/index';

/* eslint-disable no-unused-vars */
interface IExportSidebarProps {
  heading: string;
  reactToPrintFn?: () => void;
  className?: string;
  onHandleReset?: () => void;
  onHandleBack: () => void;
  showPageResetButton?: boolean;
}

const ExportSidebar = ({
  reactToPrintFn,
  className,
  onHandleReset,
  heading,
  onHandleBack,
  showPageResetButton = true,
}: IExportSidebarProps) => {
  return (
    <div
      className={cn(
        'export-sidebar-container naxatw-flex naxatw-items-center naxatw-justify-between naxatw-gap-3 naxatw-p-5',
        className,
      )}
    >
      <div className="top-title naxatw-flex naxatw-items-center  naxatw-gap-2">
        <IconButton
          size="sm"
          name="arrow_back"
          iconClassName="naxatw-text-xl naxatw-text-royal-navy"
          className="naxatw-rounded-full naxatw-transition-colors naxatw-ease-in hover:naxatw-bg-primary-200"
          onClick={onHandleBack}
        />
        <p className="naxatw-whitespace-nowrap naxatw-text-xl naxatw-font-medium naxatw-text-royal-navy">
          {heading || ''}
        </p>
      </div>

      <div className="btns-containers naxatw-flex naxatw-items-center naxatw-gap-1">
        {showPageResetButton && (
          <Button
            variant="link"
            color="primary"
            className="!naxatw-text-[0.875rem] !naxatw-font-semibold"
            onClick={onHandleReset}
          >
            <Icon name="restart_alt" className="!naxatw-text-xl" />
            Reset
          </Button>
        )}

        <Button
          variant="secondary"
          color="primary"
          className="!naxatw-text-[0.875rem] !naxatw-font-semibold"
          onClick={reactToPrintFn}
        >
          <Icon name="download" className="!naxatw-text-xl" />
          Download Portfolio Profile
        </Button>
      </div>
    </div>
  );
};

export default ExportSidebar;
