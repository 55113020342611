import ToolTip from '@Components/RadixComponents/ToolTip';

interface IInputLabelProps {
  label: string | undefined;
  tooltipMessage?: string;
  astric?: boolean;
  id?: string;
  disabled?: boolean;
  className?: string;
  labelClassName?: string;
}

export default function InputLabel({
  label,
  tooltipMessage,
  astric,
  id,
  disabled,
  className,
  labelClassName = '',
}: IInputLabelProps) {
  return (
    <div
      className={`naxatw-flex naxatw-items-center naxatw-justify-start naxatw-gap-x-[0.375rem] ${className} ${
        disabled ? 'naxatw-text-grey-600' : ''
      }`}
    >
      <div className="naxatw-flex naxatw-items-center">
        <p id={id} className={`naxatw-label ${labelClassName}`}>
          {label}
        </p>
        {astric ? <span className="naxatw-text-red-600">&nbsp;*</span> : null}
      </div>
      <div className="naxatw-mt-[2px] naxatw-h-5 naxatw-w-5">
        {tooltipMessage ? (
          <ToolTip
            name="info"
            message={tooltipMessage || 'tooltip'}
            className="naxatw-text-lg naxatw-text-matt-200"
          />
        ) : null}
      </div>
    </div>
  );
}
