import { useQuery } from '@tanstack/react-query';
import { Controller, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import DropDown from '@Components/common/DropDown';
import { FormControl } from '@Components/common/FormUI';
import TiptapEditor from '@Components/common/FormUI/TipTapEditor';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn } from '@Components/common/Layouts';
import { getThematicFieldOptions } from '@Services/knowledgeRepository';
import { prepareDataForDropdown } from '@Utils/index';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { getProvinceList } from '@Services/common';
import { includeProvinces } from '@Constants/FormConstants/knowledgeRepoConstants';

import ErrorMessage from '@Components/common/ErrorMessage';

const DocumentDetails = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  //! ----------> thematic dropdown
  const { data: thematicDropdown } = useQuery({
    queryKey: ['knowledgerepo-thematicfield-options'],
    queryFn: () => getThematicFieldOptions(),
    onError: () => toast.error('Failed to fetch thematic field options'),
    select: data => prepareDataForDropdown(data?.data || [], 'name'),
  });

  //! ------------> province dropdown
  const { data: provinceList } = useQuery({
    queryKey: ['get-province-list'],
    queryFn: () => getProvinceList(),
    select: response => {
      const provinces = response?.data ?? [];
      const filteredProvinces = provinces.filter(
        (province: Record<string, any>) =>
          includeProvinces?.includes(province.name),
      );
      const finalProvinces = prepareDataForDropdown(filteredProvinces, 'name');
      return finalProvinces;
    },
  });

  return (
    <FlexColumn className="dcocument-details naxatw-mb-5 naxatw-w-full naxatw-space-y-5">
      {/* ------------> Summary */}
      <FormControl>
        <InputLabel label="Summary" className="naxatw-mb-1" />
        <Controller
          control={control}
          name="summary"
          render={({ field: { value, onChange } }) => {
            return <TiptapEditor bindvalue={value} onChange={onChange} />;
          }}
        />
      </FormControl>
      {/* ------------> thematic Field */}
      <FormControl>
        <InputLabel label="Thematic Field" className="naxatw-mb-1" />
        <Controller
          control={control}
          name="thematic_field"
          defaultValue=""
          render={({ field: { value, onChange } }) => (
            <DropDown
              placeholder="Thematic Field"
              className="naxatw-w-full"
              options={thematicDropdown || []}
              value={value}
              onChange={onChange}
              multiple
              checkBox
            />
          )}
        />
      </FormControl>

      {/* ------------> province */}

      <FormControl>
        <InputLabel label="Province" className="naxatw-mb-1" astric />
        <Controller
          control={control}
          name="province"
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: 'Province is required',
            },
          }}
          render={({ field: { value, onChange } }) => (
            <DropDown
              placeholder="Choose Province"
              className="naxatw-w-full"
              options={provinceList || []}
              value={value}
              onChange={onChange}
            />
          )}
        />

        {errors?.province?.message && (
          <ErrorMessage
            message={errors?.province?.message as string}
            className="naxatw-mt-1"
          />
        )}
      </FormControl>
    </FlexColumn>
  );
};

export default hasErrorBoundary(DocumentDetails);
