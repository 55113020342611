/* eslint-disable no-nested-ternary */
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { cn, convertToCurrencySystem } from '@Utils/index';

interface IReportChartLegrndProps {
  data: Record<string, any>[];
  fills: string[];
  className?: string;
  convertCurrency?: boolean;
  showPound?: boolean;
  isExport?: boolean;
  isRoundedLegend?: boolean;
}

const LegendItem = ({
  legendItem,
  color,
  convertCurrency,
  showPound,
  isExport,
  isRoundedLegend,
}: any) => {
  return (
    <div className="legend-item-wrapper naxatw-flex naxatw-items-center naxatw-justify-between naxatw-gap-3">
      <div>
        <div
          className={`${isExport ? 'naxatw-gap-1' : 'naxatw-gap-2'} naxatw-flex naxatw-items-center `}
        >
          <div
            style={{
              width: '10px',
              height: '10px',
              backgroundColor: color,
              borderRadius: isRoundedLegend ? '50%' : '20%',
            }}
          />
          <p
            className={`${isExport ? 'fs-xs-medium' : 'naxatw-text-[0.75rem] naxatw-font-medium naxatw-text-primary-700'} naxatw-whitespace-nowrap`}
          >
            {legendItem?.name}
          </p>
        </div>
      </div>
      <p
        className={`${isExport ? 'fs-xs-bold' : 'naxatw-text-[0.75rem] naxatw-font-bold naxatw-text-primary-700'} naxatw-whitespace-nowrap`}
      >
        {convertCurrency
          ? convertToCurrencySystem(legendItem.value)
          : showPound
            ? `£ ${convertToCurrencySystem(legendItem.value)}`
            : legendItem.value}
        &nbsp;{' '}
        {legendItem?.percentage
          ? `(${legendItem.percentage.toFixed(0)}%)`
          : null}
      </p>
    </div>
  );
};

const ReportChartLegend = ({
  data,
  fills,
  className,
  convertCurrency,
  showPound = false,
  isExport = true,
  isRoundedLegend = false,
}: IReportChartLegrndProps) => {
  return (
    <div className={cn('report-chart-legend', className)}>
      <div className="legend-wrapper naxatw-flex naxatw-flex-col naxatw-gap-1">
        {data.map((legendItem: any, index: number) => (
          <LegendItem
            key={legendItem.name}
            legendItem={legendItem}
            color={fills[index] || '#0088F8'}
            convertCurrency={convertCurrency}
            showPound={showPound}
            isExport={isExport}
            isRoundedLegend={isRoundedLegend}
          />
        ))}
      </div>
    </div>
  );
};

export default hasErrorBoundary(ReportChartLegend);
