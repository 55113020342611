import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { v4 as uuid } from 'uuid';

import Skeleton from '@Components/RadixComponents/Skeleton';
import { getCampaignGoals } from '@Services/provincialProfile';
import { FlexColumn } from '@Components/common/Layouts';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import SegmentCard from '../Cards/Segment';

const CampaignGoals = () => {
  const [searchParams] = useSearchParams();
  const { programmeId } = useParams();
  const projectId = searchParams.get('componentId');

  const { data, isLoading } = useQuery({
    queryKey: ['campaignGoals', projectId],
    queryFn: async () =>
      projectId
        ? getCampaignGoals({ project_id: projectId })
        : getCampaignGoals({ program_id: programmeId }),

    select: res => res.data,
  });

  return (
    <SegmentCard
      title="Campaign Goals"
      iconName="extension"
      headerClassName="naxatw-px-2"
      containerClassName="naxatw-h-full naxatw-border naxatw-overflow-hidden"
    >
      {!isLoading && data?.length === 0 && <NoChartDataComponent />}
      <div className="scrollbar naxatw-flex naxatw-h-full naxatw-flex-col naxatw-items-start naxatw-gap-5 naxatw-overflow-auto naxatw-px-2">
        {isLoading ? (
          <FlexColumn className="naxatw-flex naxatw-w-full naxatw-gap-4">
            <Skeleton className="naxatw-h-4 naxatw-w-5/12" />
            <Skeleton className="naxatw-h-4 naxatw-w-[4rem]" />
            <Skeleton className="naxatw-h-4 naxatw-w-5/12" />
          </FlexColumn>
        ) : (
          <div className="naxatw-flex naxatw-w-full naxatw-flex-col naxatw-gap-4">
            {data?.map((goal: Record<string, any>) => {
              return (
                <div
                  className="naxatw-flex naxatw-flex-col naxatw-gap-1"
                  key={uuid()}
                >
                  <span className="naxatw-body-btn naxatw-text-primary-700">
                    CG {goal?.campaign_goals__id}
                  </span>
                  <p className="naxatw-body-sm naxatw-text-matt-200 ">
                    {goal?.campaign_goals__name?.split(':')[1]}
                  </p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </SegmentCard>
  );
};

export default CampaignGoals;
