import { FormFieldProps } from '@Constants/interface/FormInterface';

const updateFormFields: FormFieldProps[] = [
  {
    label: 'Summary',
    inputType: 'textArea',
    type: 'tiptap-editor',
    placeholder: 'Summary of the file',
    id: 'summary',
    name: 'summary',
    isVisible: true,
    className: '!naxatw-h-[10rem]',
    required: true,
  },
  {
    label: 'Date',
    inputType: 'date',
    type: 'monthYearDatePicker',
    placeholder: 'Choose date',
    id: 'date',
    name: 'date',
    isVisible: true,
    required: true,
  },
  {
    label: 'Update Location',
    inputType: 'select',
    type: 'select',
    placeholder: 'Choose Update Location',
    id: 'federal_type',
    name: 'federal_type',
    isVisible: true,
    choose: 'value',
    required: true,
  },
  {
    label: 'Province',
    inputType: 'select',
    type: 'select',
    placeholder: 'Choose province',
    id: 'province',
    name: 'province',
    isVisible: false,
    required: true,
  },
];
export default updateFormFields;
