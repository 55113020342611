import hasErrorBoundary from '@Components/common/hasErrorBoundary';

const ExportHeading = ({ headerTitle }: { headerTitle: string }) => {
  return (
    <div className="main-heading">
      <h5 className="naxatw-mb-2">{headerTitle || '-'}</h5>
    </div>
  );
};

export default hasErrorBoundary(ExportHeading);
