/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { HtmlHTMLAttributes, useRef, useState } from 'react';
import Portal from '@Components/common/Layouts/Portal';
import Modal from '@Components/common/Modal';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import ToolTip from '@Components/RadixComponents/ToolTip';
import IconButton from '@Components/common/IconButton';
import { useTypedSelector } from '@Store/hooks';
import { cn } from '@Utils/index';
import { IChartHeaderProps } from '../ChartHeader';
import NoChartDataComponent from '../NoChartDataComponent';
import { IResankyChartProps } from '../SankeyChart';
import CaptureComponent from '../CaptureComponent';
import NestedChartContentHeader from '../ChartHeader/NestedChartContentHeader';
import { ChartFills } from '../constants';
import getChartOfType from '../utils/getChartOfType';
import getChartFillOfType from '../utils/getChartFillOfType';

import type { ChartTypes } from '../types';
import ChartInfoBanner from '../utils/ChartInfoBanner';
import CustomTableData from '../CustomTableData';

interface ICustomChartContainerProps<T>
  extends HtmlHTMLAttributes<HTMLDivElement>,
    Omit<IChartHeaderProps, 'downloadComponentRef'> {
  chartTitle: string;
  type: ChartTypes;
  scrollable?: boolean;
  fillWithType?: boolean;
  showFullScreen: boolean;
  onClose: () => void;
  sankeyChartData?: IResankyChartProps;
  hoverTitle?: string;
  hasChart?: boolean;
  noChartContent?: JSX.Element;
}

function FullChart<T>({
  legend,
  xLabel,
  yLabel,
  data,
  type,
  chartTitle,
  scrollable = false,
  fillWithType = false,
  fill,
  showFullScreen,
  onClose,
  sankeyChartData = {
    nodeColors: [],
  },
  chartId,
  showPound,
  hoverTitle,
  hasDetails,
  hasChart = true,
  noChartContent,
  barSize,
  nestedChartContent,
  onChartClick,
  legendLabels,
  hasTableData,
}: ICustomChartContainerProps<T>) {
  const [isHideCloseBtn, setIsHideCloseBtn] = useState(false);
  const chart = getChartOfType(type);
  const fills = fill || (fillWithType ? getChartFillOfType(type) : ChartFills);

  const showLegendDetails = useTypedSelector(
    state => state.mainDashboard.charts?.showLegendDetails,
  );

  const downloadRef = useRef<HTMLDivElement | null>(null);
  function handleDownload() {
    setIsHideCloseBtn(true);
    // wrapping the IO blocking task into macro task
    // this create chances to hide our modal close button
    setTimeout(() => {
      CaptureComponent({
        captureName: chartTitle,
        componentRef: downloadRef,
      });
      setIsHideCloseBtn(false);
    }, 0);
  }

  return (
    <Portal>
      <div className="naxatw-h-full" ref={downloadRef}>
        <Modal
          show={showFullScreen}
          className={`naxatw-z-[999]  naxatw-w-[98dvw] ${hasChart ? 'naxatw-h-[98dvh]' : 'naxatw-h-fit'}`}
          modalWrapperStyles="naxatw-h-full"
          childrenParentClassName={hasChart ? '' : '!naxatw-p-0'}
          subtitle=""
          title=""
          onClose={() => {}}
          hideCloseButton
          headerContent={
            <FlexRow className="naxatw-w-full naxatw-flex-1 naxatw-items-center naxatw-justify-between naxatw-gap-6 naxatw-pr-4 naxatw-pt-2">
              <h1 className="naxatw-top-6 naxatw-text-xl naxatw-font-bold naxatw-leading-6">
                {chartTitle}
              </h1>
              <FlexRow gap={4} className="naxatw-items-center">
                <FlexRow className="naxatw-group naxatw-cursor-pointer naxatw-items-center naxatw-justify-center naxatw-overflow-hidden naxatw-rounded-[50%] naxatw-p-2 naxatw-transition-colors naxatw-duration-200 naxatw-ease-in hover:naxatw-bg-primary-200">
                  <ToolTip
                    name="download"
                    message="Download chart"
                    className="actions !naxatw-text-2xl  naxatw-text-matt-100 group-hover:naxatw-text-secondary-500"
                    messageStyle="naxatw-font-normal"
                    iconClick={() => handleDownload()}
                  />
                </FlexRow>
                <IconButton
                  name="close"
                  className={`naxatw-group naxatw-rounded-full naxatw-p-1 !naxatw-text-xl  naxatw-text-matt-100 naxatw-duration-300 hover:naxatw-bg-primary-200 ${isHideCloseBtn ? 'naxatw-invisible' : ''}`}
                  onClick={onClose}
                  iconClassName="group-hover:naxatw-text-secondary-500 naxatw-transition-colors naxatw-duration-200 naxatw-ease-in"
                />
              </FlexRow>
            </FlexRow>
          }
        >
          {/* ******* Render Chart Details or Data Summary */}
          {hasChart ? (
            <FlexColumn
              className={`naxatw-h-full naxatw-w-full ${hasTableData ? '' : 'naxatw-justify-center '}`}
            >
              {data?.length === 0 ? (
                <div className="naxatw-col-span-12 naxatw-h-fit">
                  <NoChartDataComponent />
                </div>
              ) : hasTableData ? (
                <CustomTableData data={data || []} chartTitle={chartTitle} />
              ) : (
                <>
                  {(hasDetails || nestedChartContent) && <ChartInfoBanner />}
                  {yLabel && !(type === 'donut') ? (
                    <div
                      className={`y-label naxatw-absolute ${
                        type === 'verticalBar'
                          ? 'naxatw-left-[0.3rem]'
                          : 'naxatw-left-8'
                      } naxatw-top-0 naxatw-col-span-1 naxatw-flex naxatw-h-full naxatw-w-12 naxatw-items-center naxatw-justify-end`}
                    >
                      <p className="naxatw-origin-center -naxatw-rotate-90 naxatw-whitespace-nowrap naxatw-text-xs naxatw-font-medium naxatw-text-matt-100">
                        {yLabel}
                      </p>
                    </div>
                  ) : null}
                  <div className="naxatw-grid naxatw-h-full naxatw-grid-cols-12 naxatw-overflow-hidden">
                    <div
                      className={`card naxatw-h-full   ${
                        // eslint-disable-next-line no-nested-ternary
                        type === 'donut'
                          ? 'naxatw-flex  naxatw-h-full naxatw-items-center sm:naxatw-col-span-6 lg:naxatw-col-span-6'
                          : !yLabel
                            ? 'naxatw-h-full naxatw-overflow-y-hidden'
                            : ''
                      } ${
                        scrollable ? 'scrollbar naxatw-overflow-auto' : ''
                      } ${type === 'bar' ? 'naxatw-px-8' : 'naxatw-px-3'} ${hasDetails ? 'naxatw-col-span-7' : 'naxatw-col-span-12'} ${showLegendDetails ? '!naxatw-col-span-5' : ''}`}
                    >
                      {chart &&
                        chart({
                          data,
                          fills,
                          scrollable,
                          sankeyChartData,
                          chartId,
                          showPound,
                          hoverTitle,
                          showFullScreen,
                          hasDetails: true,
                          barSize,
                          onChartClick,
                        })}
                    </div>
                    {xLabel && !(type === 'donut') ? (
                      <div className="x-label naxatw-col-span-12 naxatw-mt-4 naxatw-flex naxatw-items-center naxatw-justify-center naxatw-pb-3">
                        <p className="naxatw-mr-2 naxatw-text-[12px] naxatw-font-[500] naxatw-text-[#475467]">
                          {xLabel}
                        </p>
                      </div>
                    ) : null}
                    {(legend || showLegendDetails) && (
                      <div
                        className={cn(
                          `legend naxatw-flex ${
                            type === 'donut'
                              ? 'naxatw-flex naxatw-items-center naxatw-justify-start  sm:naxatw-col-span-6 lg:naxatw-col-span-6'
                              : 'naxatw-mb-5'
                          } ${hasDetails ? 'naxatw-col-span-4' : 'naxatw-col-span-12'} ${showLegendDetails ? '!naxatw-col-span-7' : ''} naxatw-h-full naxatw-items-center`,
                          type === 'bar' && ' naxatw-justify-center',
                        )}
                      >
                        {legend &&
                          !showLegendDetails &&
                          legend({
                            data,
                            type,
                            fills,
                            chartId,
                            showPound,
                            hasDetails: true,
                            legendLabels,
                          })}
                        {showLegendDetails && (
                          <FlexColumn className="naxatw-col-span-12 naxatw-gap-3 naxatw-px-4 naxatw-pt-6">
                            <NestedChartContentHeader />
                            <div className="naxatw-px-6">
                              {nestedChartContent}
                            </div>
                          </FlexColumn>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}
            </FlexColumn>
          ) : (
            <div className="nochartcontent">{noChartContent}</div>
          )}
        </Modal>
      </div>
    </Portal>
  );
}

export default hasErrorBoundary(FullChart);
