import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import Skeleton from '@Components/RadixComponents/Skeleton';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import SegmentCard from '@Components/ProgrammeDetails/Cards/Segment';
import { FlexColumn } from '@Components/common/Layouts';
import {
  getWorkingAreaByProject,
  getWorkingAreasByProgram,
} from '@Services/provincialProfile';

type WorkingArea = {
  province: string;
  district: string;
  municipality: string;
};

const MAX_SLICE_LEN = 8;

function RenderList({
  list,
  isLoading,
}: {
  list: WorkingArea[];
  isLoading: boolean;
}) {
  const [renderAll, setRenderAll] = useState(false);
  const canSlice = list ? list?.length > MAX_SLICE_LEN : false;
  const workingAreas =
    canSlice && !renderAll ? list?.splice(0, MAX_SLICE_LEN) : list;

  return (
    <>
      {isLoading ? (
        <div className="naxatw-flex naxatw-flex-wrap naxatw-gap-2">
          {Array(10)
            .fill(0)
            .map((_, idx) => (
              <Skeleton
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                className="naxatw-h-9 naxatw-w-32 naxatw-px-3 naxatw-py-2"
              />
            ))}
        </div>
      ) : (
        <div className="naxatw-flex naxatw-flex-wrap naxatw-gap-2">
          {workingAreas?.map(
            area =>
              area.province !== null && (
                <span
                  className={`naxatw-table-body naxatw-min-w-0 naxatw-truncate naxatw-rounded-3xl naxatw-border naxatw-border-[#E3E4E3] naxatw-bg-white naxatw-px-3 naxatw-py-2 ${area === null ? 'naxatw-hidden' : 'naxatw-block'}`}
                  key={uuidv4()}
                >
                  <>
                    {area?.province}{' '}
                    {area?.district?.trim() && `/ ${area?.district}`}
                    {area?.municipality?.trim() && `/ ${area?.municipality}`}
                  </>
                </span>
              ),
          )}
          {canSlice && (
            <button
              type="button"
              className="naxatw-body-caption naxatw-border-0 naxatw-font-bold naxatw-text-primary-700"
              onClick={() => setRenderAll(prev => !prev)}
            >
              See {renderAll ? 'Less' : 'More'}
            </button>
          )}
        </div>
      )}
    </>
  );
}

function WorkingAreasList({ groupByStatus }: { groupByStatus?: boolean }) {
  const { programmeId } = useParams();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('componentId');

  const { data, isLoading } = useQuery<
    any,
    any,
    WorkingArea[] | { Ongoing?: WorkingArea[]; Completed?: WorkingArea[] },
    any
  >({
    queryKey: ['workingAreas', programmeId, projectId],
    queryFn: () =>
      groupByStatus
        ? getWorkingAreaByProject({ program: programmeId, project: projectId })
        : getWorkingAreasByProgram({ program: programmeId }),

    select: res => {
      return groupByStatus
        ? res.data[0]?.data?.reduce(
            (acc: Record<string, any[]>, workingAreas: Record<string, any>) => {
              return {
                ...acc,
                [workingAreas?.status]: workingAreas?.working_areas,
              };
            },
            {},
          )
        : res.data[0]?.data;
    },
  });

  if (!data && !isLoading) return null;

  return (
    <div className="[&>*]:naxatw-bg-transparent [&>*]:naxatw-px-0">
      <SegmentCard title="Working Areas" iconName="place">
        {groupByStatus && !Array.isArray(data) ? (
          <FlexColumn className="naxatw-gap-4">
            {data?.Ongoing && data?.Ongoing?.length > 0 && (
              <FlexColumn className="naxatw-gap-2">
                <span className="naxatw-text-[14px] naxatw-font-medium naxatw-text-matt-200">
                  ONGOING
                </span>
                <RenderList list={data?.Ongoing} isLoading={isLoading} />
              </FlexColumn>
            )}
            {data?.Completed && data?.Completed?.length > 0 && (
              <FlexColumn className="naxatw-gap-2">
                <span>COMPLETED</span>
                <RenderList list={data?.Completed} isLoading={isLoading} />
              </FlexColumn>
            )}
          </FlexColumn>
        ) : (
          <RenderList list={data as WorkingArea[]} isLoading={isLoading} />
        )}
      </SegmentCard>
    </div>
  );
}
export default hasErrorBoundary(WorkingAreasList);
