import Metric from '@Components/common/Card/Metric';
import ExportButtons from '@Components/common/CommonExport/ExportHeader';
import { useTypedSelector } from '@Store/hooks';
import ExportBtn from '@Components/common/CommonExport/ExportHeader/ExportBtn';
import { useDispatch } from 'react-redux';
import { motion } from 'framer-motion';
import {
  deleteIndividualList,
  deletePdfSection,
  resetPdfSection,
} from '@Store/actions/pdfExport';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import WorkingArea from './WorkingArea';

const PortfolioSummary = ({
  provinceCount,
}: {
  provinceCount: Record<string, any>;
}) => {
  const dispatch = useDispatch();

  const showReset = useTypedSelector(
    state =>
      state.pdfExportSlice.provincialProfile.provincialSummary.showResetButton,
  );

  const cardsData = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.provincialSummary.datalists,
  );

  const {
    program_count: programCount,
    project_count: projectCount,
    first_tier_partner_count: partnerCount,
    stakeholder_count: stakeholderCount,
    marker_count: markerCount,
    sector_count: sectorCount,
    district_count: disctrictCount,
    municipality_count: municipalityCount,
  } = provinceCount;

  const isAreasVisible = cardsData.includes('working-areas');

  return (
    <ExportRoundedContainer className="pdf-portfolio-summary-container">
      <ExportButtons
        headerTitle="Portfolio Summary"
        isStyleAbsolute={false}
        showResetButton={showReset}
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: 'provincialProfile',
              section: 'provincialSummary',
            }),
          )
        }
        onHandleReset={() => {
          dispatch(
            resetPdfSection({
              page: 'provincialProfile',
              section: 'provincialSummary',
            }),
          );
        }}
        className="!naxatw-border-b !naxatw-border-b-grey-200 !naxatw-p-2"
      />
      <div className="summary-content naxatw-gap-3 naxatw-bg-primary-200 naxatw-p-2">
        <div className="naxatw-flex naxatw-gap-2">
          {cardsData.includes('program') && (
            <motion.div
              className="naxatw-group naxatw-relative naxatw-flex-1"
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Metric
                label="Programme"
                iconName="list_alt"
                metricCount={programCount}
                className="!naxatw-p-2"
                isExport
              />
              <ExportBtn
                size="sm"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: 'provincialProfile',
                      section: 'provincialSummary',
                      key: 'datalists',
                      value: 'program',
                    }),
                  )
                }
              />
            </motion.div>
          )}

          {cardsData.includes('project') && (
            <motion.div
              className="naxatw-group naxatw-relative naxatw-flex-1"
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Metric
                label="Project"
                iconName="folder_open"
                metricCount={projectCount}
                className="!naxatw-p-2"
                isExport
              />
              <ExportBtn
                size="sm"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: 'provincialProfile',
                      section: 'provincialSummary',
                      key: 'datalists',
                      value: 'project',
                    }),
                  )
                }
              />
            </motion.div>
          )}

          {cardsData.includes('partner') && (
            <motion.div
              className="naxatw-group naxatw-relative naxatw-flex-1"
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Metric
                label="First Tier Partner"
                iconName="handshake"
                metricCount={partnerCount}
                className="!naxatw-p-2"
                isExport
              />
              <ExportBtn
                size="sm"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: 'provincialProfile',
                      section: 'provincialSummary',
                      key: 'datalists',
                      value: 'partner',
                    }),
                  )
                }
              />
            </motion.div>
          )}
        </div>

        {isAreasVisible && (
          <motion.div
            className="naxatw-group naxatw-relative naxatw-my-2 "
            layout
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <WorkingArea
              isExport
              districtCount={disctrictCount}
              municipalityCount={municipalityCount}
              className="!naxatw-p-2"
            />
            <ExportBtn
              size="sm"
              onHandleClose={() =>
                dispatch(
                  deleteIndividualList({
                    page: 'provincialProfile',
                    section: 'provincialSummary',
                    key: 'datalists',
                    value: 'working-areas',
                  }),
                )
              }
            />
          </motion.div>
        )}

        <div
          className={`naxatw-flex naxatw-gap-2 ${isAreasVisible ? '' : 'naxatw-mt-2'}`}
        >
          {cardsData.includes('stakeholder') && (
            <motion.div
              className="naxatw-group naxatw-relative naxatw-flex-1"
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Metric
                label="Stakeholders"
                iconName="groups"
                metricCount={stakeholderCount}
                className="!naxatw-p-2"
                isExport
              />
              <ExportBtn
                size="sm"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: 'provincialProfile',
                      section: 'provincialSummary',
                      key: 'datalists',
                      value: 'stakeholder',
                    }),
                  )
                }
              />
            </motion.div>
          )}

          {cardsData.includes('marker') && (
            <motion.div
              className="naxatw-group naxatw-relative naxatw-flex-1"
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Metric
                label="Markers"
                iconName="donut_small"
                metricCount={markerCount}
                className="!naxatw-p-2"
                isExport
              />
              <ExportBtn
                size="sm"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: 'provincialProfile',
                      section: 'provincialSummary',
                      key: 'datalists',
                      value: 'marker',
                    }),
                  )
                }
              />
            </motion.div>
          )}

          {cardsData.includes('sector') && (
            <motion.div
              className="naxatw-group naxatw-relative naxatw-flex-1"
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Metric
                isExport
                label="Sectors"
                iconName="category"
                metricCount={sectorCount}
                className="!naxatw-p-2"
              />
              <ExportBtn
                size="sm"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: 'provincialProfile',
                      section: 'provincialSummary',
                      key: 'datalists',
                      value: 'sector',
                    }),
                  )
                }
              />
            </motion.div>
          )}
        </div>
      </div>
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(PortfolioSummary);
