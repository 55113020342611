import {
  contextualData,
  provinicialSummaryData,
  populationData,
  programmeSummaryData,
  projectSummaryData,
  bekSummaryData,
  palikaSummaryData,
  palikaContextualData,
} from '@Constants/PdfContent';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getIds } from '@Utils/index';

const initialState = {
  provincialProfile: {
    provincialSummary: {
      showSection: true,
      showResetButton: false,
      datalists: provinicialSummaryData,
    },
    population: {
      showSection: true,
      showResetButton: false,
      datalists: populationData,
    },
    contextual: {
      showSection: true,
      showResetButton: false,
      datalists: contextualData,
    },

    updates: {
      showSection: true,
      showResetButton: true,
      datalists: [],
      originalLists: [],
    },
    map: {
      showSection: true,
    },
    workingArea: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },
    priorities: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },
    sectorChart: {
      showSection: true,
    },
    partnerChart: {
      showSection: true,
    },
    stakeholderTable: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },
    programTable: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },
    description: {
      showSection: true,
    },
    provinceImage: {
      showSection: true,
    },
    stakeholderChart: {
      showSection: true,
    },
  },
  programmeDetails: {
    summary: {
      showSection: true,
      showResetButton: false,
      datalists: programmeSummaryData,
    },
    goals: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },
    groups: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },
    statusChart: {
      showSection: true,
    },
    modalityChart: {
      showSection: true,
    },
    sectors: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },
    markers: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },
    projectTable: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },
    stakeholders: {
      showSection: true,
      showResetButton: false,
    },
    nationalStakeHolder: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },
    subNationalStakeHolder: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },
    stakeholderChart: {
      showSection: true,
    },
    map: {
      showSection: true,
    },
    workingAreas: {
      showSection: true,
      showResetButton: false,
    },
  },
  projectDetails: {
    summary: {
      showSection: true,
      showResetButton: false,
      datalists: projectSummaryData,
    },
    goals: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },
    groups: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },
    sectors: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },
    markers: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },

    nationalStakeHolder: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },
    subNationalStakeHolder: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },
    workingAreas: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },
    map: {
      showSection: true,
    },
    stakeholderChart: {
      showSection: true,
    },
    palikaImage: {
      showSection: true,
    },
  },
  bek: {
    filters: {
      showSection: true,
    },
    description: {
      showSection: true,
    },
    map: {
      showSection: true,
    },
    summary: {
      showSection: true,
      showResetButton: false,
      datalists: bekSummaryData,
    },
    fiveSector: {
      showSection: true,
    },
    fiveProgrammes: {
      showSection: true,
    },
    stakeholderByProjectGov: {
      showSection: true,
    },
    stakeholderByProjectAll: {
      showSection: true,
    },
    projectsByGroup: {
      showSection: true,
    },
    projectsByGoals: {
      showSection: true,
    },

    expenditurePieChart: {
      showSection: true,
    },
    budgetPieChart: {
      showSection: true,
    },
    modalityChart: {
      showSection: true,
    },
    partnersChart: {
      showSection: true,
    },
  },
  palikaDetails: {
    summary: {
      showSection: true,
      showResetButton: false,
      datalists: palikaSummaryData,
    },
    description: {
      showSection: true,
    },
    population: {
      showSection: true,
      showResetButton: false,
      datalists: ['population'],
    },
    contextual: {
      showSection: true,
      showResetButton: false,
      datalists: palikaContextualData,
    },
    sectorChart: {
      showSection: true,
    },
    partnerChart: {
      showSection: true,
    },
    palikaImage: {
      showSection: true,
    },
    programTable: {
      showSection: true,
      showResetButton: false,
      datalists: [],
      originalLists: [],
    },
  },
} as const;

type PageKeys = keyof typeof initialState;
type SectionKeys = keyof {
  [K in keyof typeof initialState as keyof (typeof initialState)[K]]: any;
};

// Generic function for delete action
const deletePdfSection = <T extends PageKeys>(
  state: typeof initialState,
  action: PayloadAction<{ page: T; section: SectionKeys }>,
) => {
  const { page, section } = action.payload;

  return {
    ...state,
    [page]: {
      ...state[page],
      [section]: {
        ...(state[page] as any)[section],
        showSection: false,
      },
    },
  };
};

//  set orignal data lists
const setOriginalDataLists = <T extends PageKeys>(
  state: typeof initialState,
  action: PayloadAction<{
    page: T;
    section: SectionKeys;
    value: Record<string, any>[];
  }>,
) => {
  const { page, section, value } = action.payload;

  const isOriginalListAvailable = (state?.[page] as any)?.[section as any]
    ?.originalLists;
  let dataListsIds;

  if (isOriginalListAvailable) {
    dataListsIds = getIds(value);
  }

  return {
    ...state,
    [page]: {
      ...state[page],
      [section]: {
        ...(state[page] as any)[section], // Avoid using `as any`
        datalists: isOriginalListAvailable
          ? dataListsIds
          : (state[page] as any)?.[section]?.cardsData || [],
        ...(isOriginalListAvailable ? { originalLists: value } : {}),
      },
    },
  };
};

// delete single id
const deleteIndividualList = <T extends PageKeys>(
  state: typeof initialState,
  action: PayloadAction<{
    page: T;
    section: SectionKeys;
    key: string;
    value: boolean | string | number;
  }>,
) => {
  const { page, section, key, value } = action.payload;

  const sectionData = (state[page] as any)[section] as any;

  const currentData = sectionData[key];

  if (!Array.isArray(currentData)) return state;
  const filteredData = currentData.filter(item => item !== value);
  const isLastCardRemoved = filteredData.length === 0;

  return {
    ...state,
    [page]: {
      ...state[page],
      [section]: {
        ...(state[page] as any)[section],
        [key]: filteredData,
        showResetButton: !isLastCardRemoved,
        showSection: !isLastCardRemoved,
      },
    },
  };
};

const resetPdfSection = <T extends PageKeys>(
  state: typeof initialState,
  action: PayloadAction<{ page: T; section: SectionKeys }>,
) => {
  const { page, section } = action.payload;

  const sectionName = (state[page] as any)[section];

  const isOriginalListAvailable = sectionName?.originalLists;

  let listsIds: string[] | undefined;

  if (isOriginalListAvailable) {
    listsIds = sectionName?.originalLists?.map(
      (list: Record<string, any>) => list.id,
    );
  }

  return {
    ...state,
    [page]: {
      ...state[page],
      [section]: {
        ...(state[page] as any)[section],
        datalists: isOriginalListAvailable
          ? listsIds
          : (initialState[page] as any)[section]?.datalists || [],
        ...(isOriginalListAvailable
          ? {
              originalLists: (state[page] as any)[section]?.originalLists || [],
            }
          : {}),
        showResetButton: false,
      },
    },
  };
};

const resetOverallPage = <T extends PageKeys>(
  state: typeof initialState,
  action: PayloadAction<{ page: T }>,
) => {
  const { page } = action.payload;

  return {
    ...state,
    [page]: Object.keys(state[page]).reduce(
      (acc, section) => {
        const sectionState = (state[page] as any)[section];
        const updatedSection: any = {
          ...(sectionState as any),
          showSection: true,
          showResetButton: false,
        };

        if (sectionState.originalLists) {
          updatedSection.originalLists = sectionState.originalLists;
          updatedSection.datalists = sectionState.originalLists.map(
            (list: Record<string, any>) => list.id,
          );
        } else {
          updatedSection.datalists = (initialState[page] as any)[
            section
          ].datalists;
        }

        /* @ts-ignore */
        acc[section] = updatedSection;

        return acc;
      },
      {} as (typeof state)[T],
    ),
  };
};

const pdfExportSlice = createSlice({
  name: 'pdfExport',
  initialState,
  reducers: {
    deletePdfSection,
    setOriginalDataLists,
    deleteIndividualList,
    resetPdfSection,
    resetOverallPage,
  },
});

export { pdfExportSlice };
export default pdfExportSlice.reducer;
