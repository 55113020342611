import { mainDashboardSlice } from '@Store/slices/mainDashboard';

export const {
  setMainDashboardStates,
  handleOverlayLayerCheckbox,
  setShowFilterColumn,
  setMapActiveViewBy,
  setLegendActiveFilter,
  toggleOverlayPopup,
  toggleFilterState,
  resetFilterState,
  setDateRangeState,
  postFilterParams,
  setFilterCount,
  setMapStates,
  setStatusFilterState,
  setChartStates,
  setNestedChartStates,
} = mainDashboardSlice.actions;
