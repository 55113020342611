/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
import React from 'react';
import { ResponsiveContainer, Tooltip, Treemap } from 'recharts';
import { getObjectWithMaxValue } from '@Utils/index';
import { IChartProps } from '../types';

type customTooltipType = {
  active?: boolean;
  payload?: Record<string, any>;
};

export function calculatePercentage(total: number, value: number): number {
  if (!total || !value) return 0;
  if (total === 0) {
    return 0;
  }
  return (value / total) * 100;
}

const getColorCodeByValuePercentage = (
  colorCodes: string[],
  value: number,
  maxValue: number,
) => {
  const percentage: number = calculatePercentage(maxValue, value);
  if (percentage > 80) return colorCodes[4];
  if (percentage >= 60) return colorCodes[3];
  if (percentage >= 40) return colorCodes[2];
  if (percentage >= 20) return colorCodes[1];
  return colorCodes[0];
};

const CustomTooltip = ({ active, payload }: customTooltipType) => {
  if (active && payload && payload.length) {
    const tooltipObject = payload[0].payload;
    return (
      <div className="custom-tooltip naxatw-relative naxatw-z-20 naxatw-rounded-xl naxatw-border-2 naxatw-bg-white naxatw-px-3 naxatw-py-2 naxatw-text-sm">
        <p className="label naxatw-font-bold">{tooltipObject?.name}</p>
        <p className="label naxatw-font-bold">Count: {tooltipObject?.size}</p>
      </div>
    );
  }

  return null;
};

export function CustomizedContent(prop: Record<string, any>) {
  const { depth, x, y, width, height, name, size, colors, root, area } = prop;

  const maxValue = getObjectWithMaxValue(root?.children || []);
  const colorCode = getColorCodeByValuePercentage(
    colors,
    size || 0,
    maxValue?.value || 0,
  );
  const fontSize = Math.round(area * 0.00013);
  const fontSizeCount = Math.round(area * 0.00015);

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: colorCode,
          stroke: '#fff',
          strokeWidth: 2 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10),
        }}
        rx={15}
        ry={15}
      />
      <foreignObject
        width={width}
        height={height}
        x={x + 5}
        y={y + 3}
        textAnchor="middle"
        fontSize={12}
        style={{ overflow: 'hidden' }}
      >
        <div className="naxatw-flex naxatw-h-full naxatw-flex-col naxatw-items-center naxatw-justify-center">
          <p
            className={`naxatw-text-wrap naxatw-break-words naxatw-text-center ${colorCode === '#7F9FCE' || colorCode === '#08519C' ? 'naxatw-text-white' : 'naxatw-text-black'} 
            `}
            title={name}
            style={{
              fontSize: fontSize > 12 ? `${fontSize}px` : '0.75rem',
            }}
          >
            {name}
          </p>
          <p
            className={`naxatw-text-wrap naxatw-font-bold ${colorCode === '#7F9FCE' || colorCode === '#08519C' ? 'naxatw-text-white' : 'naxatw-text-black'} 
            // naxatw-text-[${fontSize}px]
            `}
            style={{
              fontSize: fontSizeCount > 12 ? `${fontSizeCount}px` : '0.875rem',
            }}
          >
            {size}
          </p>
        </div>
      </foreignObject>
    </g>
  );
}

const COLORS = ['#E7F2FF', '#D5E2F5', '#B2C8E6', '#7F9FCE', '#08519C'];

export default function CustomTreeChart({ data, height }: IChartProps) {
  return (
    <ResponsiveContainer width="100%" minHeight={200} height={height || '100%'}>
      <Treemap
        width={730}
        height={250}
        data={data}
        dataKey="size"
        fill="D5E2F5"
        content={<CustomizedContent colors={COLORS} />}
      >
        <Tooltip content={<CustomTooltip />} />
      </Treemap>
    </ResponsiveContainer>
  );
}
