import { useTypedSelector } from '@Store/hooks';
import { useSpring, animated } from '@react-spring/web';
import { useLocation } from 'react-router-dom';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import ViewTab from './ViewTab';
import Filters from './Filters';
import InfoHeader from './InfoHeader';
import MapSection from './MapSection';
import TableSection from './TableSection';
import ChartSection from './ChartSection';

// fetch active section in dashboard
const getActiveSection = (sectionName: string): JSX.Element => {
  switch (sectionName) {
    case 'map':
      return <MapSection />;
    case 'table':
      return <TableSection />;
    case 'chart':
      return <ChartSection />;
    default:
      return <MapSection />;
  }
};

const MainDashboard = () => {
  const { pathname } = useLocation();

  const showFilterColumn = useTypedSelector(
    state => state.mainDashboard.showFilterColumn,
  );

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  return (
    <section className="naxatw-m-auto naxatw-w-full naxatw-max-w-full naxatw-overflow-y-hidden naxatw-px-6 naxatw-py-3 lg:naxatw-h-[calc(100vh-63px)] lg:naxatw-py-3">
      <animated.div
        style={{ ...springs }}
        className="naxatw-mx-auto naxatw-flex naxatw-h-full  naxatw-flex-col"
      >
        <div className="naxatw-mb-3 naxatw-flex naxatw-w-full naxatw-flex-col-reverse naxatw-items-start naxatw-justify-between naxatw-gap-y-3 lg:naxatw-flex-row">
          <InfoHeader />
          <ViewTab />
        </div>
        <div className="naxatw-relative naxatw-flex naxatw-flex-col naxatw-gap-y-10 naxatw-overflow-hidden lg:naxatw-flex-1 lg:naxatw-flex-row">
          <div
            className={`naxatw-h-full naxatw-w-full naxatw-duration-300 lg:naxatw-absolute lg:naxatw-w-[22%] ${showFilterColumn ? ' naxatw-block naxatw-h-fit lg:naxatw-right-0' : 'naxatw-hidden naxatw-h-0 lg:-naxatw-right-[500px] lg:naxatw-block'}`}
          >
            <Filters />
          </div>
          <div
            className={`${showFilterColumn ? 'lg:naxatw-w-[77%]' : 'lg:naxatw-w-full'}  naxatw-duration-300 lg:naxatw-h-full`}
          >
            {getActiveSection(pathname?.split('/')?.[2] || '')}
          </div>
        </div>
      </animated.div>
    </section>
  );
};

export default hasErrorBoundary(MainDashboard);
