/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { tutorialCardsData } from '@Constants/tutorial';
import { FlexColumn } from '@Components/common/Layouts';
import { useSpring, animated } from '@react-spring/web';
import Icon from '@Components/common/Icon';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@Components/RadixComponents/Accordion';
import useIntersectionObserver from '@Hooks/useIntersectionObserver';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getFAQ } from '@Services/tutorials';
import ExploreTutorialCard from './ExploreCard';

const Tutorial = () => {
  const [isIntersecting, rootRef, viewRef] = useIntersectionObserver();

  const [openItem, setOpenItem] = useState<string>('');

  const handleValueChange = (value: any) => {
    setOpenItem(value);
  };

  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  const {
    data: faqsCardDataResponse,
    isLoading: faqsDataIsLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['faq-data'],
    queryFn: async ({ pageParam = 1 }) => {
      const res = await getFAQ({
        page: pageParam,
        page_size: 10,
      });
      return res?.data;
    },
    getNextPageParam: lastPage => {
      return lastPage?.next_page ?? undefined;
    },
  });
  const faqsCardData = faqsCardDataResponse?.pages
    ?.map((faqData: any) => faqData.results)
    .flat();

  useEffect(() => {
    if (isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [isIntersecting, fetchNextPage, hasNextPage]);
  return (
    <animated.div style={{ ...springs }}>
      <div className="naxatw-pb-10x naxatw-m-auto naxatw-w-full naxatw-max-w-[90rem] naxatw-py-6">
        <div className="naxatw-mx-auto naxatw-w-11/12">
          <div className="naxatw-grid naxatw-gap-5  md:naxatw-grid-cols-[22rem_1fr]">
            <FlexColumn className="naxatw-gap-4">
              {tutorialCardsData.map(card => (
                <ExploreTutorialCard key={card.id} {...card} />
              ))}
            </FlexColumn>
            <FlexColumn className="naxatw-items-start naxatw-gap-10 naxatw-rounded-lg naxatw-bg-white naxatw-px-8 naxatw-py-10 naxatw-shadow-sm">
              <p className="naxatw-text-[1.5rem] naxatw-font-medium naxatw-leading-[1.75rem] naxatw-tracking-[-0.045rem] naxatw-text-primary-700">
                Frequently Asked Questions
              </p>
              {faqsDataIsLoading ? (
                <FlexColumn className="naxatw-w-full naxatw-gap-2">
                  {Array.from({ length: 8 }).map((_, index: number) => (
                    <Skeleton
                      className=" naxatw-h-[2.5rem] naxatw-w-full first:naxatw-mt-2"
                      key={index}
                    />
                  ))}
                </FlexColumn>
              ) : (
                <div
                  className="scrollbar naxatw-max-h-[28rem] naxatw-w-full naxatw-overflow-y-auto naxatw-pr-8 "
                  ref={rootRef}
                >
                  <Accordion
                    type="single"
                    className="naxatw-w-full"
                    collapsible
                    onValueChange={handleValueChange}
                  >
                    <div className="naxatw-w-full">
                      {faqsCardData?.map((question: any) => (
                        <AccordionItem
                          value={`question-${question.id}`}
                          key={question.id}
                          className="naxatw-flex naxatw-flex-col naxatw-gap-1 naxatw-py-3"
                          ref={viewRef}
                        >
                          <AccordionTrigger className="naxatw-flex naxatw-h-5 naxatw-justify-between hover:naxatw-no-underline">
                            <p
                              className={`naxatw-text-[1.125rem] naxatw-leading-normal ${openItem.includes(`question-${question.id}`) ? 'naxatw-font-bold' : 'naxatw-font-medium'}`}
                            >
                              {question.title}
                            </p>
                            <Icon
                              name={
                                openItem === `question-${question.id}`
                                  ? 'expand_less'
                                  : 'expand_more'
                              }
                            />
                          </AccordionTrigger>
                          <AccordionContent className="naxatw-w-[95%]">
                            {/* <p className="naxatw-text-base naxatw-font-normal naxatw-leading-[1.25rem]">
                              {question.description}
                            </p> */}
                            <div
                              className="editor-content-with-large-font !naxatw-text-base naxatw-font-normal naxatw-leading-[1.25rem]"
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: question.description,
                              }}
                            />
                          </AccordionContent>
                        </AccordionItem>
                      ))}
                    </div>
                  </Accordion>
                  {hasNextPage && (
                    <FlexColumn className="naxatw-w-full naxatw-gap-2">
                      {Array.from({ length: 3 }).map((_, index: number) => (
                        <Skeleton
                          className=" naxatw-h-8 naxatw-w-full first:naxatw-mt-2"
                          key={index}
                        />
                      ))}
                    </FlexColumn>
                  )}
                </div>
              )}
            </FlexColumn>
          </div>
        </div>
      </div>
    </animated.div>
  );
};

export default Tutorial;
