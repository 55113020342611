import CustomBarChart from '@Components/common/Charts/BarChart';
import { stakholderByProrgammeProjectFills } from '@Components/common/Charts/constants';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import ChartXAxis from '@Components/common/CommonExport/Chart/ChartXAxis';
import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { getGovernmentStakeHoldersDataPro } from '@Services/mainDashboard';
import { getStakeholderChartDataByProgramProject } from '@Services/provincialProfile';
import { deletePdfSection } from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useQuery } from '@tanstack/react-query';
import isEmpty from '@Utils/isEmpty';
import { useDispatch } from 'react-redux';

const ReportStakeholderByProject = ({
  title,
  type,
}: {
  title: string;
  type: string;
}) => {
  const dispatch = useDispatch();

  const isChartAll = type === 'all';

  const programmeFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.program,
  );

  const projectFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.component,
  );

  const firstTierPartnerParams = useTypedSelector(
    state => state.mainDashboard.filterParams.firstTierPartner,
  );

  const sectorGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.sector,
  );

  const markerGroupParams = useTypedSelector(
    state => state.mainDashboard.filterParams.markerGroup,
  );

  const subMarkersParams = useTypedSelector(
    state => state.mainDashboard.filterParams.subMarkers,
  );

  const startDate = useTypedSelector(
    state => state.mainDashboard.filterParams.startDate,
  );
  const endDate = useTypedSelector(
    state => state.mainDashboard.filterParams.endDate,
  );

  const statusFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );

  const provinceFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.province,
  );

  const getChartData = isChartAll
    ? getStakeholderChartDataByProgramProject
    : getGovernmentStakeHoldersDataPro;

  const { data: chartData } = useQuery({
    queryKey: ['project-by-stakeholder-report', type],
    queryFn: () =>
      getChartData({
        program: programmeFilterParams?.join(','),
        project: projectFilterParams?.join(','),
        first_tier_partner: firstTierPartnerParams?.join(','),
        sector: sectorGroupParams?.join(','),
        marker: markerGroupParams?.join(','),
        marker_category: subMarkersParams?.join(','),
        start_date: startDate,
        end_date: endDate,
        status: statusFilterParams,
        province: provinceFilterParams?.join(','),
      }),
    select: (res: any) => {
      if (!res?.data) return [];
      const response = res.data;
      if (isEmpty(response)) return [];
      if (isChartAll) {
        return response?.[0]?.stakeholders?.map(
          (stakeholder: {
            national: number;
            sub_national: number;
            type: string;
          }) => ({
            value1: stakeholder?.national,
            value2: stakeholder?.sub_national,
            name: stakeholder?.type,
          }),
        );
      }

      return response?.[0]?.stakeholders?.map(
        (stakeholder: {
          value: number;
          stakeholder_type: string;
          data_by_province: Record<string, any>[];
        }) => ({
          name: stakeholder?.stakeholder_type,
          value: stakeholder?.value,
          data: stakeholder?.data_by_province,
        }),
      );
    },
  });

  return (
    <ExportRoundedContainer
      style={{
        pageBreakInside: 'avoid',
      }}
    >
      <ExportHeader
        headerTitle={title}
        isStyleAbsolute={false}
        className="!naxatw-border-b naxatw-border-b-pdf-border !naxatw-p-2"
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: 'bek',
              section: isChartAll
                ? 'stakeholderByProjectAll'
                : 'stakeholderByProjectGov',
            }),
          )
        }
      />

      {chartData && isEmpty(chartData) ? (
        <NoChartDataComponent
          isExport
          className={
            isChartAll ? '!naxatw-h-[280px]' : '!naxatw-h-[calc(100%-40px)]'
          }
          iconClassName="naxatw-h-[50%]"
        />
      ) : (
        <div className="naxatw-relative naxatw-p-2">
          <p className="fs-xs-medium naxatw-absolute naxatw-left-[24px] naxatw-top-[40%] naxatw-origin-center -naxatw-translate-x-2/4 -naxatw-rotate-90 naxatw-capitalize">
            project
          </p>
          <CustomBarChart
            data={chartData || []}
            fills={
              isChartAll
                ? stakholderByProrgammeProjectFills
                : [stakholderByProrgammeProjectFills[1]]
            }
            height="15rem"
            wrapXAxis
            hoverTitle={isChartAll ? ['National', 'Subnational'] : 'Project'}
            isExport
          />
          <ChartXAxis
            doubleXAxis={!!isChartAll}
            fills={isChartAll ? stakholderByProrgammeProjectFills : null}
            data={isChartAll ? ['National', 'Subnational'] : []}
            name={
              isChartAll
                ? 'Type of Stakeholder'
                : 'Type of Government Stakeholder'
            }
          />
        </div>
      )}
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(ReportStakeholderByProject);
