/* eslint-disable no-unused-vars */
import BreadCrumb from '@Components/common/FormComponent/BreadCrumb';
import FormSkeleton from '@Components/common/FormComponent/FormSkeleton';
import { Input } from '@Components/common/FormUI';
import ErrorMessage from '@Components/common/ErrorMessage';
import TiptapEditor from '@Components/common/FormUI/TipTapEditor';
import IconButton from '@Components/common/IconButton';
import InputLabel from '@Components/common/InputLabel';
import { FlexColumn } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import { zodResolver } from '@hookform/resolvers/zod';
import { getFAQById, patchFAQ, postFAQ } from '@Services/tutorials';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { faqFormValidationSchema } from '@Validations/Tutorials';
import { useEffect } from 'react';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface IFAQRegistrationForm {
  onClose: () => void;
}

const FAQRegistrationForm = ({ onClose }: IFAQRegistrationForm) => {
  const { faqId } = useParams();
  const queryClient = useQueryClient();
  const initialValues: FieldValues = {
    title: '',
    description: '',
  };

  const {
    handleSubmit,
    formState: { errors, isDirty },
    register,
    control,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: zodResolver(faqFormValidationSchema),
  });

  const { mutate: postFAQdata, isLoading: faqIsUploading } = useMutation({
    mutationFn: (data: FieldValues) => postFAQ(data),
    onSuccess: () => {
      toast.success('FAQ added successfully');
      queryClient.invalidateQueries(['faq-table-data']);
      onClose();
    },
    onError: () => {
      toast.error('Failed to add FAQ');
    },
  });

  const { mutate: patchFAQdata, isLoading: faqIsPatching } = useMutation({
    mutationFn: (data: FieldValues) => patchFAQ(data),
    onSuccess: () => {
      toast.success('FAQ updated successfully');
      queryClient.invalidateQueries(['faq-table-data']);
      onClose();
    },
    onError: () => {
      toast.error('Failed to update FAQ');
    },
  });

  const { isLoading: isFAQdataLoading } = useQuery({
    queryKey: ['faq-data', faqId],
    queryFn: () => getFAQById(faqId || ''),
    select: res => res?.data,
    enabled: !!faqId,
    onSuccess: response => {
      if (response) {
        Object.keys(response).forEach((key: string) => {
          const value = response[key];
          setValue(key, value);
        });
      }
    },
  });

  function handleFormSubmit(data: FieldValues) {
    if (faqId) {
      if (isDirty === false) {
        onClose();
        return;
      }
      patchFAQdata({ ...data, id: faqId });
      return;
    }
    postFAQdata(data);
  }

  return (
    <div className="naxatw-absolute naxatw-left-1/2 naxatw-top-1/2 naxatw-flex naxatw-max-h-[90vh] naxatw-w-[90%] -naxatw-translate-x-1/2 -naxatw-translate-y-1/2 naxatw-flex-col naxatw-rounded-lg naxatw-border naxatw-border-gray-300 naxatw-bg-[#fff] naxatw-transition-all naxatw-duration-200 sm:naxatw-max-w-[34.75rem]">
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-self-stretch naxatw-px-7 naxatw-py-5 naxatw-shadow-[0px_2px_20px_4px_rgba(0,0,0,0.12)]">
        <BreadCrumb
          heading={`Question Answer / ${faqId ? 'Edit' : 'Add'}`}
          overlayStatus={() => onClose()}
        />
        <IconButton
          name="close"
          className="!naxatw-h-9 !naxatw-w-9 naxatw-gap-1 naxatw-rounded-lg hover:naxatw-bg-gray-100"
          iconClassName="naxatw-font-normal naxatw-text-[#757575] naxatw-text-[24px] naxatw-leading-[24px]"
          onClick={() => onClose()}
        />
      </div>

      <div className="naxatw-w-full">
        <FlexColumn className="naxatw-relative naxatw-h-full naxatw-w-full ">
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            {faqId && isFAQdataLoading ? (
              <div className="naxatw-px-6">
                <FormSkeleton className="!naxatw-w-full" numRows={3} />
              </div>
            ) : (
              <div className="faqs-form-inner naxatw-p-6">
                <FlexColumn className="naxatw-w-full  naxatw-gap-1">
                  <InputLabel label="Title" astric />
                  <Input
                    type="text"
                    placeholder="Title"
                    className="!naxatw-w-full"
                    {...register('title')}
                  />
                  {errors?.title?.message ? (
                    <ErrorMessage
                      message={
                        typeof errors?.title?.message === 'string'
                          ? errors.title.message
                          : ''
                      }
                    />
                  ) : null}
                </FlexColumn>
                <FlexColumn className="naxatw-w-full naxatw-gap-1 naxatw-pt-5">
                  <InputLabel label="Description" astric />
                  <Controller
                    control={control}
                    name="description"
                    render={({ field: { value, onChange } }) => {
                      return (
                        <TiptapEditor
                          bindvalue={value}
                          editable
                          onChange={onChange}
                          // isEditorEmpty={isEditorEmpty}
                        />
                      );
                    }}
                  />
                  {/* <Input
                  type="text"
                  placeholder="Description"
                  className="!naxatw-w-full"
                  {...register('description')}
                /> */}
                  {errors?.description?.message ? (
                    <ErrorMessage
                      message={
                        typeof errors?.description?.message === 'string'
                          ? errors.description.message
                          : ''
                      }
                    />
                  ) : null}
                </FlexColumn>
              </div>
            )}

            <div className="naxatw-py-5 naxatw-text-center naxatw-shadow-formshadow">
              <Button
                size="normal"
                type="submit"
                variant="primary"
                className="naxatw-w-fit naxatw-px-4"
                isLoading={faqIsPatching || faqIsUploading}
                disabled={faqIsPatching || faqIsUploading}
              >
                Save
              </Button>
            </div>
          </form>
        </FlexColumn>
      </div>
    </div>
  );
};

export default FAQRegistrationForm;
