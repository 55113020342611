/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */

import DvsLogo from '@Assets/images/dvsLogoWhite.svg';
import { useLocation } from 'react-router-dom';
import Login from '@Components/Login/LoginSection/Login';
import EmailImg from '@Assets/images/email.png';
import FcdoEmailValidation from '@Assets/images/fcdo-email-validation.png';
import ForgotPassword from '@Components/Login/LoginSection/ForgotPassword';
import ResetPassword from '@Components/Login/LoginSection/ResetPassword';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import SignUp from './Signup';
import VerifyEmail from './Signup/VerifyEmail';

const getLoginUI = (path: string) => {
  if (path === '/login') {
    return <Login />;
  }
  if (path === '/forgot-password') {
    return <ForgotPassword />;
  }
  if (path === '/sign-up') {
    return <SignUp />;
  }
  if (path.includes('/reset-password')) {
    return <ResetPassword />;
  }
  if (path === '/sign-up/verify-email') {
    return (
      <VerifyEmail
        image={EmailImg}
        title="Check Your Mail"
        description="We've sent a verification link to your email. Please check your
          inbox and verify."
      />
    );
  }
  if (path === '/sign-up/verify-fcdo-email') {
    return (
      <VerifyEmail
        image={FcdoEmailValidation}
        title="User Registration Guideline"
        description="Please note that users can only register using their FCDO email addresses. For registration with any other email, please contact the super admin to create your account."
      />
    );
  }
  // if(path.includes('/sign-up/verify-email')) {
  return <Login />;
};

const LoginSection = () => {
  const { pathname } = useLocation();

  return (
    <div className="naxatw-bg-secondary-500">
      <FlexColumn className="naxatw-mb-6 naxatw-gap-[2rem]">
        <div className="naxatw-pl-[1.25rem] naxatw-pt-[0.844rem] sm:naxatw-pl-[2.438rem]">
          <img
            src={DvsLogo}
            alt="BEK"
            className="naxatw-w-[7rem] sm:naxatw-w-[9.125rem]"
          />
        </div>
        {pathname === '/login' && (
          <p className="naxatw-text-center naxatw-text-3xl naxatw-font-bold naxatw-text-white">
            Welcome to Data Visualisation System
          </p>
        )}
      </FlexColumn>
      <FlexRow className="naxatw-h-[calc(100vh-5.344rem)] naxatw-items-start naxatw-justify-center ">
        <div className="naxatw-w-[90%] naxatw-rounded-lg naxatw-bg-white naxatw-px-6 naxatw-py-8   lg:naxatw-w-[24rem]">
          {getLoginUI(pathname)}
        </div>
      </FlexRow>
    </div>
  );
};

export default LoginSection;
