import Updates from '@Components/Updates';

const UpdatesPage = () => {
  return (
    <section className="updates-page-section naxatw-overflow-x-hidden">
      <Updates />
    </section>
  );
};

export default UpdatesPage;
