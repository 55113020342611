import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { useDispatch } from 'react-redux';
import { deleteIndividualList } from '@Store/actions/pdfExport';
import ExportBtn from '../ExportHeader/ExportBtn';
import ExportRoundedContainer from '../ExportRoundedContainer';

const HeadingWithList = ({
  dataLists,
  exportLists,
  isExport = false,
  exportTitle,
}: {
  dataLists: Record<string, any>[];
  exportLists?: Record<string, any>[] | number[];
  isExport?: boolean;
  exportTitle?: Record<string, any>;
}) => {
  const dispatch = useDispatch();

  return (
    <ExportRoundedContainer className="heading-with-list-container naxatw-space-y-3  naxatw-p-3">
      {dataLists.map(listItem => {
        if (isExport) {
          const isDataIncludes =
            Array.isArray(exportLists) && exportLists.includes(listItem.id);

          if (!isDataIncludes) return null;
        }
        return (
          <div
            className="list-item-container naxatw-group naxatw-relative"
            key={listItem.id}
          >
            <p className="fs-sm-semibold naxatw-mb-1">{listItem.heading}</p>
            <div className="naxatw-flex naxatw-flex-wrap naxatw-items-center">
              {listItem.categories.map((catItem: Record<string, any>) => (
                <div
                  key={catItem.id}
                  className="category-item-container naxatw-relative naxatw-px-2 after:naxatw-absolute  after:naxatw-right-0  after:naxatw-top-0 after:naxatw-h-full after:naxatw-w-[2px] after:naxatw-bg-grey-400 after:naxatw-content-[''] last:after:naxatw-hidden"
                >
                  <p className="fs-sm-medium naxatw-whitespace-nowrap ">
                    {catItem.title}
                  </p>
                  <div className="naxatw-mx-1 naxatw-w-[1px]" />
                </div>
              ))}
            </div>
            {isExport ? (
              <ExportBtn
                size="sm"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: exportTitle?.page,
                      section: exportTitle?.section,
                      key: 'datalists',
                      value: listItem.id,
                    }),
                  )
                }
              />
            ) : null}
          </div>
        );
      })}
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(HeadingWithList);
