import AdminHeader from '@Components/common/AdminHeader';
import Portal from '@Components/common/Layouts/Portal';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAdminSearchState } from '@Store/actions/common';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import UpdateTable from './UpdateTable';
import UpdateAddForm from './UpdateForm';

function FederalInfoUpdate() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [toggleForm, setToggleForm] = useState<boolean>(
    () => pathname?.includes('add') || pathname?.includes('edit') || false,
  );
  const userGroupPermission = useTypedSelector(
    state => state?.common?.userGroupPermission,
  );
  const dispatch = useTypedDispatch();

  useEffect(() => {
    return () => {
      dispatch(setAdminSearchState({ update: '' }));
    };
  }, [dispatch]);

  return (
    <div className="programme-form-container naxatw-h-full  naxatw-space-y-6">
      <AdminHeader
        title="Monthly Updates"
        onAddNewClick={() => {
          setToggleForm(true);
          navigate('/data-bank/federal-info-update/add');
        }}
        registerButtonText="Add"
        needAddNewButton={
          !!userGroupPermission?.includes('Can add federal info update')
        }
      />

      {userGroupPermission?.includes('Can view federal info update') ? (
        <UpdateTable />
      ) : null}

      {toggleForm && (
        <Portal>
          <UpdateAddForm
            onClose={() => {
              navigate('/data-bank/federal-info-update');
              setToggleForm(false);
            }}
          />
        </Portal>
      )}
    </div>
  );
}
export default hasErrorBoundary(FederalInfoUpdate);
