import ChartCardHeader from '@Components/ProvincialProfile/ChartSection/Header';
import { cn } from '@Utils/index';
import { ReactNode, useRef } from 'react';
import CaptureComponent from '../Charts/CaptureComponent';
import hasErrorBoundary from '../hasErrorBoundary';
import { FlexColumn, FlexRow } from '../Layouts';

type Props = {
  title?: string;
  headingContent?: ReactNode;
  headerClassName?: string;
  children?: ReactNode;
  className?: string;
  onExport?: () => void;
  enableFullScreen?: boolean;
  isExportable?: boolean;
  isPdfExport?: boolean;
};

function ContentBox({
  title = '',
  headingContent,
  children,
  className,
  onExport,
  enableFullScreen = true,
  headerClassName,
  isExportable = true,
  isPdfExport,
}: Props) {
  const contentRef = useRef<HTMLDivElement | null>(null);

  function handleExportComponent() {
    if (onExport) {
      onExport();
      return;
    }
    CaptureComponent({
      componentRef: contentRef,
      captureName: title || 'dvs_asset',
    });
  }

  return (
    <div ref={contentRef} className="naxatw-h-full">
      <FlexColumn
        className={cn(
          'naxatw-h-full naxatw-rounded-xl naxatw-border naxatw-border-primary-200 naxatw-bg-white naxatw-shadow-xs',
          className,
        )}
      >
        <div className={cn(`naxatw-border-b`, headerClassName)}>
          <ChartCardHeader
            title={title}
            content={children}
            headerContent={
              <FlexRow className="naxatw-flex-1 naxatw-items-center naxatw-justify-between naxatw-gap-5">
                <span className="naxatw-body-subttile-lg naxatw-font-bold">
                  {title}
                </span>
                {headingContent}
              </FlexRow>
            }
            subHeaderContent={headingContent}
            iconClick={() => handleExportComponent()}
            needFullScreen={enableFullScreen}
            isExportable={isExportable}
            isPdfExport={isPdfExport}
          />
        </div>
        {children}
      </FlexColumn>
    </div>
  );
}

export default hasErrorBoundary(ContentBox);
