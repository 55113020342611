/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import CustomBarChart from '@Components/common/Charts/BarChart';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { getCategoryKnowledgeLibChartList } from '@Services/publicKnowledgeRepository';
import { useQuery } from '@tanstack/react-query';
import isEmpty from '@Utils/isEmpty';
import Skeleton from '@Components/RadixComponents/Skeleton';
import ChartInfoBanner from '@Components/common/Charts/utils/ChartInfoBanner';
import { useTypedSelector } from '@Store/hooks';

const CategoryChart = () => {
  const [selectedSubId, setSelectedSubId] = useState('');

  const programParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.program,
  );

  const projectParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.project,
  );

  const startDateParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.startDate,
  );

  const endDateParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.endDate,
  );

  const fileTypeParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.file_type,
  );

  const teamMemershipParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.teamOwnership,
  );

  const geographicFocusParams = useTypedSelector(
    state =>
      state.knowledgeRepository.evidenceAgendaFilterParams.geographicFocus,
  );

  const themeParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.themes,
  );

  const evidenceStatusParams = useTypedSelector(
    state =>
      state.knowledgeRepository.evidenceAgendaFilterParams.evidenceStatus,
  );

  const categoryParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.category,
  );

  const subCategoryParams = useTypedSelector(
    state => state.knowledgeRepository.evidenceAgendaFilterParams.subCategory,
  );

  const { data: chartData, isLoading } = useQuery({
    queryKey: [
      'category-know-chart',
      selectedSubId,
      programParams,
      projectParams,
      startDateParams,
      endDateParams,
      fileTypeParams,
      teamMemershipParams,
      geographicFocusParams,
      themeParams,
      evidenceStatusParams,
      categoryParams,
      subCategoryParams,
    ],
    queryFn: () =>
      getCategoryKnowledgeLibChartList({
        program: programParams?.join(','),
        project: projectParams?.join(','),
        start_date: startDateParams,
        end_date: endDateParams,
        file_type: fileTypeParams?.join(','),
        team_membership: teamMemershipParams?.join(','),
        geographic_focus: geographicFocusParams?.join(','),
        theme: themeParams
          ?.map((theme: Record<string, any>) => theme.id)
          .join(','),
        evidence_agenda_status: evidenceStatusParams?.join(','),
        ...(selectedSubId ? { category_id: selectedSubId } : {}),
        category: categoryParams?.join(','),
        sub_categroy: subCategoryParams?.join(','),
      }),
    select: (res: any) => {
      if (!res?.data) return null;
      const response = res.data;

      return response.map((cat: Record<string, any>) => ({
        name: selectedSubId
          ? cat?.sub_category__name
          : cat?.sub_category__category__name,
        value: cat?.count,
        ...(selectedSubId ? {} : { id: cat?.sub_category__category__id }),
      }));
    },
  });

  const handleBarCellClickHandler = (catId: Record<string, any>) => {
    const categoryId = catId?.activePayload[0]?.payload?.id;
    setSelectedSubId(categoryId);
  };

  return (
    <div className="category-chart-container naxatw-mb-5">
      <div className="naxatw-mb-3 naxatw-flex naxatw-items-center naxatw-gap-2">
        {selectedSubId ? (
          <ToolTip
            name="arrow_back"
            className="naxatw-flex naxatw-items-center naxatw-text-xl naxatw-text-[#0B2E62]"
            triggerClassName="!naxatw-size-[2rem] naxatw-rounded-full naxatw-transition-colors naxatw-ease-in hover:naxatw-bg-primary-200"
            iconClick={() => setSelectedSubId('')}
            message="Back"
          />
        ) : null}

        <p className=" naxatw-text-base naxatw-font-medium naxatw-text-grey-800">
          {selectedSubId
            ? 'Number of Evidences by Sub Category'
            : 'Number of Evidences by Category'}
        </p>
      </div>
      <div className="cat-chart-outer  naxatw-rounded-lg naxatw-border naxatw-border-primary-200 naxatw-bg-white naxatw-py-5 naxatw-pr-3">
        {isLoading ? (
          <Skeleton className="naxatw-ml-4 !naxatw-h-[15rem]" />
        ) : isEmpty(chartData || []) ? (
          <NoChartDataComponent
            messageStyles="!naxatw-text-[0.875rem]"
            className="naxatw-my-3"
          />
        ) : (
          <div className="cat-chart-inner naxatw-relative">
            <ChartInfoBanner className="!naxatw-top-0" />
            <p className="naxatw-absolute naxatw-left-[20px] naxatw-top-[40%] naxatw-origin-center -naxatw-translate-x-2/4 -naxatw-rotate-90 naxatw-text-xs naxatw-font-medium naxatw-capitalize">
              count
            </p>
            <CustomBarChart
              data={chartData || []}
              fills={['#41588F']}
              height="15rem"
              isExport
              barSize={55}
              onChartClick={(catId: any) => handleBarCellClickHandler(catId)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default hasErrorBoundary(CategoryChart);
