import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import TitleWithContent from '@Components/common/CommonExport/TitleWithContent';
import { getProvincialPrioritiesGoalsData } from '@Services/provincialProfile';
import { useDispatch } from 'react-redux';
import {
  deletePdfSection,
  resetPdfSection,
  setOriginalDataLists,
} from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import isEmpty from '@Utils/isEmpty';

const PdfProritiesProfile = () => {
  const { provinceId } = useParams();
  const dispatch = useDispatch();

  const dataLists = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.priorities.datalists,
  );

  const showResetButton = useTypedSelector(
    state => state.pdfExportSlice.provincialProfile.priorities.showResetButton,
  );

  const { data: provincialPrioritiesGoalsData } = useQuery({
    queryKey: ['provincial-priorities-goals-data-pdf', provinceId],
    queryFn: async () =>
      getProvincialPrioritiesGoalsData({
        province: provinceId,
      }),
    select: res => {
      if (!res?.data) return null;
      const proData = res.data;

      const manipulatedProData = proData.map((pro: Record<string, any>) => ({
        id: pro.id,
        title: pro.goal,
        content: pro.program_abbreviation.map((con: Record<string, any>) => ({
          id: con,
          name: con,
        })),
      }));

      return manipulatedProData;
    },
    onSuccess: response => {
      if (!response) return null;
      dispatch(
        setOriginalDataLists({
          page: 'provincialProfile',
          section: 'priorities',
          value: response,
        }),
      );
      return null;
    },
  });

  if (provincialPrioritiesGoalsData && isEmpty(provincialPrioritiesGoalsData))
    return null;

  return (
    <div className="pdf-provincial-priorities-container">
      <ExportHeader
        headerTitle="Provincial Priorities Alignment with BEK Programme"
        isStyleAbsolute={false}
        onHandleDelete={() =>
          dispatch(
            deletePdfSection({
              page: 'provincialProfile',
              section: 'priorities',
            }),
          )
        }
        onHandleReset={() => {
          dispatch(
            resetPdfSection({
              page: 'provincialProfile',
              section: 'priorities',
            }),
          );
        }}
        showResetButton={showResetButton}
      />
      <TitleWithContent
        isExport
        dataLists={provincialPrioritiesGoalsData || []}
        exportLists={[...(dataLists || [])]}
        exportTitle={{ page: 'provincialProfile', section: 'priorities' }}
      />
    </div>
  );
};

export default PdfProritiesProfile;
