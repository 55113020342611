/* eslint-disable react/no-array-index-key */
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { useDispatch } from 'react-redux';
import { setNestedChartStates } from '@Store/actions/mainDashboard';
import { useTypedSelector } from '@Store/hooks';
import { IChartProps } from '../types';
// import ChartLegend from '../ChartLegend';

type DonutChartProps = {
  minHeight?: number;
  maxHeight?: number;
  innerRadius?: number;
  width?: string | number;
} & IChartProps;

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const cellName = payload[0]?.payload.name;
    const cellPercentage = payload[0]?.payload?.percentage;
    const cellValue = payload[0]?.payload.value;

    return (
      <div className="custom-tooltip naxatw-relative naxatw-z-20 naxatw-rounded-xl naxatw-border-2 naxatw-bg-white naxatw-px-3 naxatw-py-2 naxatw-text-sm">
        <p className="label naxatw-font-bold naxatw-text-matt-100">
          <span className="naxatw-font-normal">{cellName || '-'}</span> :{' '}
          <span>
            {cellValue ? `${cellValue?.toLocaleString()}` : '-'}&nbsp;{' '}
            {cellPercentage ? `(${cellPercentage?.toFixed(1)}%)` : null}
          </span>
        </p>
      </div>
    );
  }
  return null;
};

export default function CustomDonutChart({
  data,
  fills,
  minHeight,
  maxHeight,
  innerRadius,
  width,
  isNestedChart,
}: DonutChartProps) {
  const dispatch = useDispatch();
  const nestedChartId = useTypedSelector(
    state => state.mainDashboard.charts.nestedCharts.nestedChartId,
  );

  return (
    <ResponsiveContainer
      width={width || '100%'}
      minHeight={minHeight || 280}
      maxHeight={maxHeight || 280}
      debounce={1}
    >
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          fill="#8884d8"
          dataKey="value"
          outerRadius="100%"
          innerRadius={innerRadius || 60}
          paddingAngle={0}
          startAngle={90}
          endAngle={450}
        >
          {data?.map((chartData: Record<string, any>, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <Cell
              style={{ outline: 'none' }}
              key={`cell-${index}`}
              fill={fills && fills[index % fills.length]}
              className={
                isNestedChart && nestedChartId === null
                  ? '!naxatw-pointer-events-none naxatw-cursor-pointer lg:!naxatw-pointer-events-auto'
                  : ''
              }
              onClick={() => {
                if (isNestedChart && nestedChartId === null) {
                  dispatch(
                    setNestedChartStates({
                      key: 'nestedChartId',
                      value: chartData.id,
                    }),
                  );
                }
              }}
            />
          ))}
        </Pie>
        <Tooltip content={CustomTooltip} />
        {/* <ChartLegend data={data} type="donut" /> */}
      </PieChart>
    </ResponsiveContainer>
  );
}
