/* eslint-disable react/no-unstable-nested-components */
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { getDailyTrivia, deleteDailyTrivia } from '@Services/daillyTrivia';
import { useTypedSelector } from '@Store/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const DailyTriviaTable = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [triviaIDToDelete, setTriviaIDToDelete] = useState<number | null>(null);

  const searchTerm = useTypedSelector(
    state => state.common.adminSearchState?.['fun-trivia'],
  );

  const {
    mutate: deleteTrivia,
    isError,
    error,
    isLoading,
  } = useMutation({
    mutationFn: () => deleteDailyTrivia(triviaIDToDelete),
    onSuccess: () => {
      toast.success('Fun Trivia deleted successfully');
      setOpenDeleteConfirmation(false);
      queryClient.invalidateQueries({
        queryKey: ['trivia-tabledata'],
      });
    },
  });

  const columns = [
    {
      header: 'S.N',
      accessorKey: '',
      cell: ({ row }: Record<string, any>) => {
        // eslint-disable-next-line
        return row?.index + 1;
      },
      size: 100,
    },
    {
      header: 'QUESTIONS',
      accessorKey: 'question',
      size: 400,
    },
    {
      header: 'CORRECT ANSWER',
      accessorKey: 'correct_answer',
    },
    {
      header: '',
      accessorKey: 'icon',
      cell: ({ row }: any) => {
        const cellId = row?.original?.id;
        return (
          <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-cursor-pointer naxatw-justify-end naxatw-gap-4">
            <ToolTip
              name="edit"
              message="Edit"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200"
              iconClick={() => navigate(`/data-bank/fun-trivia/edit/${cellId}`)}
            />
            <ToolTip
              name="delete"
              message="Delete"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-text-red-500"
              iconClick={() => {
                setOpenDeleteConfirmation(prev => !prev);
                setTriviaIDToDelete(cellId);
              }}
            />
          </FlexRow>
        );
      },
    },
  ];

  return (
    <div className="naxatw-h-[calc(100%-7rem)] naxatw-w-full lg:naxatw-h-[calc(100%-4.5rem)]">
      <DataTable
        columns={columns}
        queryKey="trivia-tabledata"
        queryFn={getDailyTrivia || {}}
        initialState={{
          paginationState: {
            pageIndex: 0,
            pageSize: 25,
          },
        }}
        searchInput={searchTerm || ''}
        sortByKey
      />
      {openDeleteConfirmation && (
        <Portal>
          <DeleteConfirmationOverlay
            onClose={() => setOpenDeleteConfirmation(false)}
            onDelete={() => deleteTrivia()}
            isError={isError}
            isLoading={isLoading}
            error={error}
          />
        </Portal>
      )}
    </div>
  );
};

export default DailyTriviaTable;
