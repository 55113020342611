import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import CustomBarChart from '@Components/common/Charts/BarChart';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Skeleton from '@Components/RadixComponents/Skeleton';
import ContentBox from '@Components/common/ContentBox';
import SwitchTab from '@Components/common/FormUI/SwitchTab';
import { getStakeholderChartDataByProgramProject } from '@Services/provincialProfile';
import { getGovernmentStakeHoldersDataPro } from '@Services/mainDashboard';
import { cn } from '@Utils/index';
import NoChartDataComponent from '@Components/common/Charts/NoChartDataComponent';
import { useDispatch } from 'react-redux';
import { setStakeholderByProjectSelectedTab } from '@Store/actions/programmeDetails';
import { useTypedSelector } from '@Store/hooks';
import { stakeholderByfillColors } from '@Constants/programmeDetails';

const SwitchOptions = [
  { label: 'All', value: '' },
  { label: 'Government', value: 'government' },
];

function StakeholdeChart({ isExport = false }: { isExport?: boolean }) {
  const { programmeId } = useParams();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('componentId');
  const dispatch = useDispatch();

  const selectedTabCharts = useTypedSelector(
    state => state.programmeDetails.stakeholderByProjectSelectedTab,
  );
  const isAllTab = selectedTabCharts === 'All';

  const { data, isLoading } = useQuery({
    queryKey: [
      'programme-details',
      'project-details',
      'stakeholder-chart-data',
      programmeId,
      projectId,
      selectedTabCharts,
    ],
    queryFn: () =>
      isAllTab
        ? getStakeholderChartDataByProgramProject({
            program: programmeId,
            project: projectId,
          })
        : getGovernmentStakeHoldersDataPro({
            program: programmeId,
            project: projectId,
          }),
    select: res => {
      if (!res.data || !res.data[0]) {
        return [];
      }

      return res?.data?.[0]?.stakeholders?.map(
        (stakeholder: Record<string, any>) => {
          return isAllTab
            ? {
                value1: stakeholder?.national,
                value2: stakeholder?.sub_national,
                name: stakeholder?.type,
              }
            : {
                name: stakeholder?.stakeholder_type,
                value: stakeholder?.value,
              };
        },
      );
    },
  });

  return (
    <ContentBox
      title="Stakeholders"
      headingContent={
        <SwitchTab
          title="VIEW BY"
          activeValue={selectedTabCharts}
          activeLabel={selectedTabCharts}
          activeClassName="!naxatw-bg-[#833177] naxatw-text-white"
          options={SwitchOptions}
          onChange={label => {
            dispatch(setStakeholderByProjectSelectedTab(label));
          }}
        />
      }
      headerClassName={
        selectedTabCharts === 'Chart' ? 'naxatw-border-b-transparent' : ''
      }
    >
      <FlexColumn
        className={`${isExport ? 'naxatw-h-[22rem] naxatw-p-3' : 'naxatw-max-h-[32rem] naxatw-gap-8 naxatw-px-5 naxatw-pb-6 naxatw-pt-9'} naxatw-w-full`}
      >
        {isLoading ? (
          <FlexRow className="naxatw-h-full naxatw-min-h-80 naxatw-w-full naxatw-items-end naxatw-justify-between naxatw-border-b naxatw-pb-6">
            {Array(7)
              .fill(0)
              .map((_, idx) => (
                <FlexRow
                  className="naxatw-h-full naxatw-items-end naxatw-gap-2"
                  // eslint-disable-next-line
                  key={idx}
                >
                  <Skeleton
                    className="naxatw-w-10"
                    style={{
                      height: Math.random() * (400 - 32) + 32,
                    }}
                  />
                  <Skeleton
                    className="naxatw-w-10"
                    style={{
                      height: Math.random() * (400 - 32) + 32,
                    }}
                  />
                </FlexRow>
              ))}
          </FlexRow>
        ) : (
          <>
            {data?.length === 0 ? (
              <FlexColumn className="naxatw-min-h-[26rem] naxatw-items-center naxatw-justify-center">
                <NoChartDataComponent />
              </FlexColumn>
            ) : (
              <>
                <CustomBarChart
                  data={data}
                  height="360px"
                  barSize={40}
                  hoverTitle={
                    selectedTabCharts === 'All'
                      ? ['National', 'Subnational']
                      : undefined
                  }
                  xAxisLabel="Stakeholder Type"
                  yAxisLabel="Project"
                  fills={stakeholderByfillColors}
                  wrapXAxis
                  isExport={isExport}
                />
                <FlexColumn className="naxatw-items-center naxatw-justify-center naxatw-gap-2">
                  {isExport ? null : (
                    <div className="naxatw-body-btn">Stakeholder Type</div>
                  )}

                  <FlexRow
                    className={cn(
                      'naxatw-gap-5',
                      !isAllTab && 'naxatw-invisible',
                    )}
                  >
                    <FlexRow className="naxatw-items-center naxatw-gap-2">
                      <div
                        className="naxatw-h-3 naxatw-w-3 naxatw-rounded"
                        style={{ background: stakeholderByfillColors[0] }}
                      />
                      <span
                        className={` ${isExport ? 'fs-sm-medium' : 'naxatw-body-caption naxatw-text-[#484848]'}`}
                      >
                        National
                      </span>
                    </FlexRow>
                    <FlexRow className="naxatw-items-center naxatw-gap-2">
                      <div
                        className="naxatw-h-3 naxatw-w-3 naxatw-rounded"
                        style={{ background: stakeholderByfillColors[1] }}
                      />
                      <span
                        className={` ${isExport ? 'fs-sm-medium' : 'naxatw-body-caption naxatw-text-[#484848]'}`}
                      >
                        Sub - National
                      </span>
                    </FlexRow>
                  </FlexRow>
                </FlexColumn>
              </>
            )}
          </>
        )}
      </FlexColumn>
    </ContentBox>
  );
}

export default hasErrorBoundary(StakeholdeChart);
