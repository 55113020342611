import { number, object, string } from 'zod';

const validateSchemaUpdateForm = object({
  summary: string()
    .trim()
    .min(1, 'Summary is Required')
    .refine(summaryData => {
      // Trim the input string first to eliminate leading/trailing whitespace
      const trimmedData = summaryData.trim();

      // Check if the entire string is only an empty <p></p> tag
      if (trimmedData === '<p></p>') {
        return false; // This will trigger the validation error
      }

      // Count non-empty <p> tags
      const nonEmptyParagraphs = (
        trimmedData.match(/<p[^>]*>[^<]*<\/p>/gi) || []
      ).length;

      // Check for empty <p> tags (with or without whitespace)
      const emptyParagraphs = (trimmedData.match(/<p[^>]*>\s*<\/p>/gi) || [])
        .length;

      // If there's exactly one empty <p> tag and no non-empty <p> tags, fail validation
      return !(emptyParagraphs === 1 && nonEmptyParagraphs === 0);
    }, 'Summary is Required'),

  date: string().trim().min(1, 'Date is Required'),
  federal_type: string()?.trim().min(1, 'Update Location is Required'),
  province: number().nullable().optional(),
}).refine(
  data => {
    if (data.federal_type === 'Province' && !data.province) {
      return false;
    }
    return true;
  },
  {
    message: 'Province is required',
    path: ['province'],
  },
);

export default validateSchemaUpdateForm;
