/* eslint-disable react/no-unstable-nested-components */
import { useState } from 'react';
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import Portal from '@Components/common/Layouts/Portal';
import DeleteConfirmationOverlay from '@Components/common/PopupOverlays/DeleteConfirmationOverlay';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { getMapLayers, deleteMapLayer } from '@Services/mapLayers';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useTypedSelector } from '@Store/hooks';

const MapLayersTable = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [iDToDelete, setIDToDelete] = useState<string>('');
  const [confirmDelete, setConfirmDelete] = useState(false);

  const searchTerm = useTypedSelector(
    state => state.common.adminSearchState?.['map-layers'],
  );

  const {
    mutate: deleteMapLayerData,
    isError,
    error,
    isLoading,
  } = useMutation({
    mutationFn: () => deleteMapLayer(Number(iDToDelete)),
    onSuccess: () => {
      toast.success('Map Layer deleted successfully');
      setConfirmDelete(false);
      queryClient.invalidateQueries({
        queryKey: ['map-layer-tabledata'],
      });
    },
  });

  const columns = [
    {
      header: 'S.N',
      accessorKey: '',
      cell: ({ row }: Record<string, any>) => {
        // eslint-disable-next-line
        return row?.index + 1;
      },
    },
    {
      header: 'Name',
      accessorKey: 'name_en',
    },
    {
      header: 'VECTOR LAYER',
      accessorKey: 'type_of_layer',
    },
    {
      header: 'GEOMETRY TYPE',
      accessorKey: 'geometry_type',
    },
    {
      header: '',
      accessorKey: 'icons',
      cell: ({ row }: any) => {
        const cellId = row?.original?.id;

        return (
          <FlexRow className="naxatw-relative naxatw-mr-6 naxatw-cursor-pointer naxatw-justify-end naxatw-gap-4">
            <ToolTip
              name="edit"
              message="Edit"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200"
              iconClick={() => {
                navigate(`/data-bank/map-layers/edit/${cellId}`);
              }}
            />
            <ToolTip
              name="delete"
              message="Delete"
              className="naxatw-text-icon-md naxatw-text-matt-100 naxatw-duration-200 hover:naxatw-text-red-500"
              iconClick={() => {
                setConfirmDelete(prev => !prev);
                setIDToDelete(cellId);
              }}
            />
          </FlexRow>
        );
      },
    },
  ];

  return (
    <div className="map-layer-table-container">
      <DataTable
        columns={columns || []}
        queryKey="map-layer-tabledata"
        queryFn={params =>
          getMapLayers({
            ...params,
            sort_by:
              params?.sort_by === 'name_en'
                ? 'layer__name_en'
                : params?.sort_by,
            order:
              // eslint-disable-next-line no-nested-ternary
              params?.order === 'name_en'
                ? 'layer__name_en'
                : params?.order === '-name_en'
                  ? '-layer__name_en'
                  : params?.order,
            search: searchTerm || '',
          })
        }
        initialState={{
          paginationState: {
            pageIndex: 0,
            pageSize: 25,
          },
        }}
        searchInput={searchTerm || ''}
        sortByKey
      />

      {confirmDelete && (
        <Portal>
          <DeleteConfirmationOverlay
            onClose={() => setConfirmDelete(false)}
            onDelete={() => deleteMapLayerData()}
            isError={isError}
            isLoading={isLoading}
            error={error}
          />
        </Portal>
      )}
    </div>
  );
};

export default MapLayersTable;
