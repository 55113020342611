import { useSpring, animated } from '@react-spring/web';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { useState } from 'react';
import {
  getPermissionByPathname,
  getPermissionViewByPathname,
  navigateToKnowRepoAddPath,
  showContextualRoles,
} from '@Constants/FormConstants/knowledgeRepoConstants';
import { useTypedSelector } from '@Store/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import KnowledgeRepoHeader from './KnowledgeRepoHeader';
import KnowledgeRepositoryTable from './KnowledgeRepositoryTable';

const KnowledgeRepository = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(pathname);

  const userProfile = useTypedSelector(state => state?.common?.userProfile);

  const userGroupPermission = useTypedSelector(
    state => state?.common?.userGroupPermission,
  );

  const contextualFormUser = showContextualRoles.includes(
    userProfile?.group_name,
  );
  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });

  return (
    <animated.div
      style={{ ...springs }}
      className="naxatw-flex naxatw-h-full naxatw-flex-col naxatw-space-y-6"
    >
      <KnowledgeRepoHeader
        // contextualFormUser={contextualFormUser}
        activeTab={activeTab}
        onTabChange={(path: string) => setActiveTab(path)}
        needAddButton={
          !!userGroupPermission?.includes(getPermissionByPathname(pathname))
        }
        onAddNewClick={() => {
          navigate(`${navigateToKnowRepoAddPath(activeTab)}`);
        }}
      />
      {userGroupPermission?.includes(getPermissionViewByPathname(pathname)) ? (
        <KnowledgeRepositoryTable
          activeTab={activeTab}
          contextualFormUser={contextualFormUser}
        />
      ) : null}
    </animated.div>
  );
};

export default hasErrorBoundary(KnowledgeRepository);
