import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ProgrammeDetails = {
  selectedTab: Record<string, any>;
  status: string;
  stakeholderByProjectSelectedTab: string;
};
const initialState: ProgrammeDetails = {
  selectedTab: {
    programme: 'Province',
    component: 'Province',
  },
  status: '',
  stakeholderByProjectSelectedTab: 'All',
};

export const programmeDetails = createSlice({
  name: 'portfolioResultsSwitchTab',
  initialState,
  reducers: {
    setKeyStakeHoldersTabProgramme: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.selectedTab.programme = action.payload;
    },
    setKeyStakeHoldersTabComponent: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.selectedTab.component = action.payload;
    },
    setStatus: (state, action: PayloadAction<string>) => ({
      ...state,
      status: action.payload,
    }),

    setStakeholderByProjectSelectedTab: (
      state,
      action: PayloadAction<string>,
    ) => {
      // eslint-disable-next-line no-param-reassign
      state.stakeholderByProjectSelectedTab = action.payload;
    },
  },
});

export default programmeDetails.reducer;
