import ExportRoundedContainer from '@Components/common/CommonExport/ExportRoundedContainer';
import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import Icon from '@Components/common/Icon';

const ProjectDetailCard = ({
  projectDetailsData,
}: {
  projectDetailsData: Record<string, any>;
}) => {
  const {
    aggrement_modality__name: modality,
    code,
    first_tier_partner__name: partnerName,
  } = projectDetailsData;
  return (
    <ExportRoundedContainer className="naxatw-grid naxatw-h-full  naxatw-bg-white naxatw-p-2">
      <div className="naxatw-flex naxatw-items-center">
        <div className="naxatw-border-r naxatw-border-r-pdf-border naxatw-pr-3">
          <Icon
            name="folder_open"
            className="naxatw-mb-[0.35rem] naxatw-cursor-default naxatw-rounded-[50%]  naxatw-bg-primary-100  naxatw-p-1  naxatw-text-base  naxatw-text-calm-teal 
            "
          />
          <p className="fs-sm-medium naxatw-text-center">Project Details</p>
        </div>
        <div className="naxatw-flex naxatw-flex-col naxatw-gap-1 naxatw-pl-3">
          <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-2 naxatw-text-wrap">
            <p className="fs-sm-medium">Agreement Modality</p>
            <p className="fs-sm-semibold">{modality || '-'}</p>
          </div>
          <div className="naxatw-grid naxatw-grid-cols-2 naxatw-items-center naxatw-gap-2">
            <p className="fs-sm-medium">Component Code</p>
            <p className="fs-sm-semibold  naxatw-whitespace-nowrap">
              {code || '-'}
            </p>
          </div>
          <div className="naxatw-grid naxatw-grid-cols-2 naxatw-gap-2">
            <p className="fs-sm-medium">First Tier Partner</p>
            <p className="fs-sm-semibold">{partnerName || '-'}</p>
          </div>
        </div>
      </div>
    </ExportRoundedContainer>
  );
};

export default hasErrorBoundary(ProjectDetailCard);
