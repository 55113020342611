import ExportHeader from '@Components/common/CommonExport/ExportHeader';
import AgreementModality from '@Components/ProgrammeDetails/Charts/AgreementModality';
import ProjectStatus from '@Components/ProgrammeDetails/Charts/ProjectStatus';
import { deletePdfSection } from '@Store/actions/pdfExport';
import { useTypedSelector } from '@Store/hooks';
import { useDispatch } from 'react-redux';

const PdfCharts = () => {
  const showStatusChart = useTypedSelector(
    state => state.pdfExportSlice.programmeDetails.statusChart.showSection,
  );

  const showModalityChart = useTypedSelector(
    state => state.pdfExportSlice.programmeDetails.modalityChart.showSection,
  );
  const dispatch = useDispatch();

  return (
    <div
      className="pdf-charts-container naxatw naxatw-relative naxatw-grid naxatw-auto-cols-fr naxatw-grid-flow-col naxatw-gap-2"
      style={{
        pageBreakInside: 'avoid',
      }}
    >
      {showStatusChart && (
        <div className="status-chart naxatw-relative">
          <ExportHeader
            btnStyles="!naxatw-top-[7px] !naxatw-right-[7px]"
            onHandleDelete={() =>
              dispatch(
                deletePdfSection({
                  page: 'programmeDetails',
                  section: 'statusChart',
                }),
              )
            }
          />
          <ProjectStatus isExport />
        </div>
      )}
      {showModalityChart && (
        <div className="modality-chart naxatw-relative">
          <ExportHeader
            btnStyles="!naxatw-top-[7px] !naxatw-right-[7px]"
            onHandleDelete={() =>
              dispatch(
                deletePdfSection({
                  page: 'programmeDetails',
                  section: 'modalityChart',
                }),
              )
            }
          />
          <AgreementModality isExport />
        </div>
      )}
    </div>
  );
};

export default PdfCharts;
