import MainDashboard from '@Components/MainDashboard';

const MainDashboardPage = () => {
  return (
    <main className="naxatw-w-full naxatw-overflow-x-hidden">
      <MainDashboard />
    </main>
  );
};

export default MainDashboardPage;
