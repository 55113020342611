/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import SearchBar from '@Components/common/SearchBar';
import { useSpring, animated } from '@react-spring/web';
import { useQuery } from '@tanstack/react-query';
import { downloadTableData } from '@Services/provincialProfile';
import useDebouncedInput from '@Hooks/useDebouncedInput';
import { useSearchParams } from 'react-router-dom';
import SwitchTab from '@Components/common/FormUI/SwitchTab';
import {
  excludeALLmappedStatusParams,
  statusOptions,
  mappedStatusParams,
} from '@Constants/filters';
import { useTypedSelector } from '@Store/hooks';
import { useDispatch } from 'react-redux';
import { setStatusFilterState } from '@Store/actions/mainDashboard';
import { clearFilterState } from '@Store/actions/programme';
import ExploreHeader from '../common/ExploreHeaders';
// import ProgrammeTable from './Table';
import ProgrammeTable from './Table/ProgrammeTable';
import ExternalFilterButton from './ExternalFilterButton';

const ExploreByProgramme = () => {
  const dispatch = useDispatch();
  const [isDownload, setIsDownload] = useState(false);
  const [filterParams, setFilterParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState('');
  const springs = useSpring({
    from: { y: 100 },
    to: { y: 0 },
  });
  const statusFilterParams = useTypedSelector(
    state => state.mainDashboard.filterParams.status,
  );

  // download table
  const { refetch } = useQuery({
    queryKey: ['programme-details-table-data'],
    queryFn: async () =>
      downloadTableData({
        is_export: true,
      }),
    enabled: isDownload,
    onSuccess: response => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(response.data);
      a.download = `tableData.csv`;
      a.click();
    },
  });

  useEffect(() => {
    return () => {
      dispatch(setStatusFilterState('Ongoing'));
    };
  }, [dispatch]);

  const [searchProgramme, setSearchProgramme] = useDebouncedInput({
    ms: 600,
    init: searchTerm,
    onChange: debouncedEvent =>
      setSearchTerm(debouncedEvent.target.value.trim()),
  });
  const isReportFilterActive = filterParams.get('filter') === 'true';

  const filterFields = useTypedSelector(
    state => state.programme.report?.filterParams,
  );

  // clear state to prevent the value persisting issue
  useEffect(
    () => () => {
      dispatch(clearFilterState({}));
    },
    [dispatch],
  );

  return (
    <>
      <div className="naxatw-m-auto naxatw-w-full naxatw-max-w-[90rem] naxatw-py-7">
        <div className="naxatw-mx-auto naxatw-w-11/12">
          <div className="naxatw-mb-5 naxatw-flex naxatw-w-full naxatw-flex-wrap naxatw-justify-between">
            <ExploreHeader />
            <div className="naxatw-flex naxatw-items-center naxatw-gap-3 min-[320px]:naxatw-my-4 min-[320px]:naxatw-w-full min-[992px]:naxatw-my-0 min-[992px]:naxatw-w-[480px]">
              <SwitchTab
                options={statusOptions || []}
                activeLabel="On-Going"
                activeValue={
                  statusFilterParams
                    ? excludeALLmappedStatusParams[statusFilterParams]
                    : 'All'
                }
                wrapperClassName="!naxatw-cursor-pointer !naxatw-gap-0  !naxatw-items-start "
                defaultBg={false}
                defaultBehaviour={false}
                className="naxatw-bg-white naxatw-px-2 naxatw-py-1 naxatw-text-sm naxatw-leading-5"
                titleClassName="naxatw-text-base naxatw-font-medium naxatw-tracking-[0.00625rem] naxatw-text-matt-100"
                onChange={(label: string) => {
                  dispatch(setStatusFilterState(mappedStatusParams[label]));
                }}
              />
              <SearchBar
                value={searchProgramme}
                onChange={setSearchProgramme}
              />
              {/* <ToolTip
                name="download"
                message="Bulk Download"
                className="naxatw-rounded-lg naxatw-border naxatw-border-[#D0D5DD] naxatw-bg-white naxatw-p-2 !naxatw-text-2xl naxatw-text-matt-100"
                messageStyle="naxatw-font-normal"
                iconClick={() => {
                  setIsDownload(true);
                  refetch();
                  setIsDownload(false);
                }}
              /> */}

              <ExternalFilterButton
                isActive={isReportFilterActive}
                filterCount={Object.keys(filterFields || {})?.length}
                onFilterButtonClick={() => {
                  setFilterParams({ filter: String(!isReportFilterActive) });
                  if (isReportFilterActive) {
                    dispatch(clearFilterState({}));
                  }
                }}
              />
            </div>
          </div>
          <animated.div
            style={{ ...springs }}
            className="scrollbar naxatw-overflow-x-auto"
          >
            <div className="scrollbar naxatw-w-full naxatw-overflow-auto naxatw-rounded-md naxatw-bg-white lg:naxatw-max-h-[calc(100vh-187px)]">
              <ProgrammeTable
                searchTerm={searchProgramme}
                isFilterActive={isReportFilterActive}
                className=""
              />
            </div>
          </animated.div>
        </div>
      </div>
    </>
  );
};

export default ExploreByProgramme;
