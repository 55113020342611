import { TooltipWrapper } from '@Components/RadixComponents/ToolTip';
import Icon from '@Components/common/Icon';

interface ExtenstionProps {
  tooltipMessage: string;
  icon: string;
  onClick?: () => void;
  className?: string;
  iconType?: string;
  wrapperClassName?: string;
}

const TipTapExtensionContainer = ({
  tooltipMessage,
  icon,
  onClick,
  className,
  wrapperClassName,
  iconType = 'material-symbols-outlined',
}: ExtenstionProps) => {
  return (
    <TooltipWrapper
      tooltipMessage={tooltipMessage}
      disabled
      triggerStyleClassName={`extenion-wrapper naxatw-p-1 naxatw-rounded  naxatw-items-center naxatw-w-[28px] naxatw-overflow-hidden naxatw-flex hover:naxatw-bg-secondary-200 naxatw-cursor-pointer naxatw-justify-center naxatw-duration-200 naxatw-transition-all naxatw-ease-in ${wrapperClassName}`}
    >
      <Icon
        type={iconType}
        name={icon}
        onClick={onClick}
        className={`${className} naxatw-flex naxatw-h-full naxatw-w-full naxatw-items-center naxatw-justify-center !naxatw-text-xl naxatw-text-gray-800`}
      />
    </TooltipWrapper>
  );
};

export default TipTapExtensionContainer;
