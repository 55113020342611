import dvsLogo from '@Assets/images/dvs-logo-new.svg';

const ExportLoader = () => {
  return (
    <div className="pdf-loader-container naxatw-full naxatw-h-screen naxatw-px-5 naxatw-pb-5">
      <div className="pdf-logo-container naxatw-py-3">
        <img src={dvsLogo} alt="dvs-logo" />
      </div>
      <div className="naxatw-mt-[50px] naxatw-flex naxatw-justify-center">
        <div className="pdf-loader" />
      </div>{' '}
    </div>
  );
};

export default ExportLoader;
