import hasErrorBoundary from '@Components/common/hasErrorBoundary';
import { deleteIndividualList } from '@Store/actions/pdfExport';
import { useDispatch } from 'react-redux';
import ExportBtn from '../ExportHeader/ExportBtn';

interface ITableListsProps {
  headerTitles?: Record<string, any>;
  exportTitles?: Record<string, any>;
  isExport?: boolean;
  dataLists: Record<string, any>;
  exportLists?: Record<string, any>;
}

const TableLists = ({
  headerTitles,
  dataLists,
  isExport = false,
  exportTitles,
  exportLists,
}: ITableListsProps) => {
  const dispatch = useDispatch();

  return (
    <div className="table-lists" id="table-lists">
      {headerTitles && (
        <div className="naxatw-mx-2 naxatw-grid  naxatw-grid-cols-3 naxatw-gap-6  naxatw-border-b naxatw-border-b-pdf-border naxatw-py-2">
          <p className="fs-sm-semibold naxatw-uppercase">
            {headerTitles?.firstColumn}
          </p>
          <p className="fs-sm-semibold naxatw-uppercase ">
            {headerTitles?.secondColumn}
          </p>
          <p className="fs-sm-semibold naxatw-uppercase ">
            {headerTitles?.thirdColumn}
          </p>
        </div>
      )}

      {dataLists.map((list: Record<string, any>) => {
        if (isExport) {
          const isDataIncludes =
            Array.isArray(exportLists) && exportLists.includes(list.id);

          if (!isDataIncludes) return null;
        }

        return (
          <div
            key={list.id}
            className="naxatw- naxatw-group naxatw-relative naxatw-mx-2 naxatw-py-2  last:naxatw-border-b-0 odd:naxatw-bg-primary-200"
          >
            <div className="naxatw-grid naxatw-grid-cols-3 naxatw-gap-6">
              <p className="fs-sm-medium">{list.listOne}</p>
              <p className="fs-sm-medium">{list.listTwo}</p>
              <p className="fs-sm-medium">{list.listThree}</p>
            </div>

            {isExport ? (
              <ExportBtn
                size="sm"
                className="naxatw-right-0 naxatw-top-[8px]"
                onHandleClose={() =>
                  dispatch(
                    deleteIndividualList({
                      page: exportTitles?.page,
                      section: exportTitles?.section,
                      key: 'datalists',
                      value: list.id,
                    }),
                  )
                }
              />
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default hasErrorBoundary(TableLists);
